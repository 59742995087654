import { Dayjs } from "dayjs";
import axiosInstance from "../constants/axiosInstance";
interface IProps {
  storeId: string;
  assetId: string;
  amount: number;
  remarkID: string;
  unit: string;
  action: string;
  calculatedAt: moment.Moment | Dayjs;
  categoryItemId: string;
  storeCategoryName: string;
}

const updateStore = async ({
  storeId,
  assetId,
  amount,
  remarkID,
  unit,
  action,
  calculatedAt,
  categoryItemId,
  storeCategoryName,
}: IProps) => {
  try {
    const { data } = await axiosInstance().post(`/store/UpdateStore`, {
      assetId,
      storeId,
      amount: amount,
      calculated_at: calculatedAt,
      action: action,
      remarkId: remarkID,
      unit: unit,
      storeCategoryName,
      categoryItemId: categoryItemId,
    });

    return data;
  } catch (err) {
    throw err;
  }
};
export { updateStore };
