import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

interface ILoader {
  color?: string
}
const Loader = ({ color='#36A2C7' }: ILoader) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" height="80vh">
      <CircularProgress style={{ color }} />
    </Box>
  );
};

export default Loader;