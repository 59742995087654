import { useState, FC, useEffect } from 'react';
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { PLANT_CONFIG_DATA } from '../../constants/constants';
import { toast } from 'react-toastify';
import { otherSensorDataGenerator } from '../../utils/SensorTagGenerator';

interface AddEquipmentInsideEquipmentTypeProps {
  open: boolean;
  plantConfig: any;
  setPlantConfig: Function;
  handleClose: any;
  parentCategory: string;
  parentIndex: Number;
  TankOptions: Function;
  ValveOptions: Function;
  CoreEquipmentOptions: Function;
  HeaderOptions: Function;
  sensorPostFix: string;
}

const subEquipmentList = [
  'Tanks',
  'Core Equipments',
  'Pumps',
  'Flowmeter',
  'Valves',
  'Headers',
  'Blowers',
  'Other Sensors',
];

const AddEquipmentInsideEquipmentTypePopup: FC<AddEquipmentInsideEquipmentTypeProps> = ({
  open,
  plantConfig,
  setPlantConfig,
  handleClose,
  parentCategory,
  parentIndex,
  TankOptions,
  ValveOptions,
  CoreEquipmentOptions,
  HeaderOptions,
  sensorPostFix,
}) => {
  const [selectedEquipmentType, setSelectedEquipmentType] = useState<string>('');
  const [showSubTypeSelector, setShowSubTypeSelector] = useState<boolean>(false);
  const [subPopUpOptions, setsubPopUpOptions] = useState<string[]>([]);
  const [selectedEquipmentCategory, setSelectedEquipmentCategory] = useState<string>('');
  const handleEquipmentChange = (event: any) => {
    setSelectedEquipmentType(event.target.value as string);
    setSelectedEquipmentCategory('');
  };
  const handleEquipmentCategoryChange = (event: any) => {
    setSelectedEquipmentCategory(event.target.value as string);
  };

  const getEquipmentCount = (
    equipmentType: string,
    equipmentCategory: string,
    plantConfig: any
  ) => {
    console.log('from counter');
    console.log(equipmentType, equipmentCategory);
    let count = 0;
    let countArr: number[] = [];

    if (
      equipmentType === 'Tanks' ||
      equipmentType === 'Core Equipments' ||
      equipmentType === 'Other Sensors' ||
      equipmentType === 'Valves'
    ) {
      console.log('inside with subcategory iteration');
      let returnedCountArr = iterateAndReturnCountArrWithSubCategories(
        plantConfig[equipmentType],
        equipmentCategory
      );
      countArr = [...countArr, ...returnedCountArr];
    } else {
      let returnedCountArr = iterateAndReturnCountArrWithoutSubCategories(
        plantConfig[equipmentType]
      );
      countArr = [...countArr, ...returnedCountArr];
    }
    countArr = [
      ...countArr,
      ...loopOverAndFindCountFromSubEquipment(equipmentType, equipmentCategory, plantConfig),
    ];
    if (countArr.length === 0) {
      countArr.push(0);
    }
    count = Math.max(...countArr);
    return count;
  };

  const iterateAndReturnCountArrWithSubCategories = (
    selectedEquipmentArr: any,
    category: string
  ) => {
    console.log('inside iterateAndReturnCountArrWithSubCategories');
    console.log('category , selectedEquipmentArr', category, selectedEquipmentArr);
    let countArray: any = [];
    if (selectedEquipmentArr === undefined) {
      console.log('inside undefined');
      return countArray;
    }
    for (let index = 0; index < selectedEquipmentArr.length; index++) {
      if (selectedEquipmentArr[index]['name'] === category) {
        countArray.push(selectedEquipmentArr[index]['count']);
      }
    }
    console.log('countArray', countArray);
    return countArray;
  };

  const iterateAndReturnCountArrWithoutSubCategories = (selectedEquipmentArr: any) => {
    let countArray: any = [];
    if (selectedEquipmentArr === undefined) {
      return countArray;
    }
    for (let index = 0; index < selectedEquipmentArr.length; index++) {
      countArray.push(selectedEquipmentArr[index]['count']);
    }
    return countArray;
  };

  const loopOverAndFindCountFromSubEquipment = (
    equipmentType: string,
    equipmentCategory: string,
    plantConfig: any
  ) => {
    console.log('inside sub-equipment loop over');
    console.log('equipmentType , equipmentCategory', equipmentType, equipmentCategory);
    let configuredEquipments = Object.keys(plantConfig);
    let countArr: number[] = [];
    for (let configuredEquipment of configuredEquipments) {
      if (Object.prototype.toString.call(plantConfig[configuredEquipment]) == '[object Array]') {
        console.log('array found for ', configuredEquipment);
        console.log(plantConfig[configuredEquipment]);
        for (const singleEquipment of plantConfig[configuredEquipment]) {
          if (singleEquipment['subEquipments']) {
            console.log('sub equipment found');
            let subEquipmentConfig = singleEquipment['subEquipments'];
            console.log(subEquipmentConfig);
            if (
              equipmentType === 'Tanks' ||
              equipmentType === 'Core Equipments' ||
              equipmentType === 'Other Sensors' ||
              equipmentType === 'Valves'
            ) {
              let returnedCountArr = iterateAndReturnCountArrWithSubCategories(
                subEquipmentConfig[equipmentType],
                equipmentCategory
              );
              countArr = [...countArr, ...returnedCountArr];
            } else {
              let returnedCountArr = iterateAndReturnCountArrWithoutSubCategories(
                subEquipmentConfig[equipmentType]
              );
              countArr = [...countArr, ...returnedCountArr];
            }
          }
        }
      }
    }
    return countArr;
  };

  const handleCreate = () => {
    let plantConfigData: any = { ...plantConfig };
    //@ts-ignore
    if (!plantConfigData[parentCategory][parentIndex]['subEquipments']) {
      //@ts-ignore
      plantConfigData[parentCategory][parentIndex]['subEquipments'] = {
        Tanks: [],
        'Core Equipments': [],
        Pumps: [],
        Flowmeter: [],
        Valves: [],
        Headers: [],
        Blowers: [],
        'Other Sensors': [],
      };
    }
    if (
      selectedEquipmentType === '' ||
      selectedEquipmentType === ' ' ||
      selectedEquipmentType === null ||
      selectedEquipmentType === undefined
    ) {
      toast.error('Please Select a Sub-Equipment');
    } else if (
      selectedEquipmentType === 'Tanks' ||
      selectedEquipmentType === 'Core Equipments' ||
      selectedEquipmentType === 'Other Sensors' ||
      selectedEquipmentType === 'Valves'
    ) {
      if (selectedEquipmentCategory === '') {
        toast.error('Please Select a Sub-Equipment Category');
      } else {
        if (
          //@ts-ignore
          !plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType]
        ) {
          //@ts-ignore
          plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType] = [];
        }
        let counted = 0;
        counted = getEquipmentCount(
          selectedEquipmentType,
          selectedEquipmentCategory,
          plantConfigData
        );

        if (selectedEquipmentType === 'Tanks') {
          //@ts-ignore
          console.log(plantConfigData[parentCategory][parentIndex]);
          //@ts-ignore
          plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType].push(
            {
              id: selectedEquipmentCategory + (counted + 1),
              name: selectedEquipmentCategory,
              count: counted + 1,
              sensors: [],
              area: 0,
              height: 0,
              'free board': 0,
              material: '',
              drain: '',
              overflow: '',
              bypass: '',
              nickName: '',
              displayNode: true,
            }
          );
        } else if (selectedEquipmentType === 'Valves') {
          //@ts-ignore
          plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType].push(
            {
              id: selectedEquipmentCategory + (counted + 1),
              name: selectedEquipmentCategory,
              count: counted + 1,
              In: [],
              Out: [],
              sensors: [],
              nickName: '',
              displayNode: true,
            }
          );
        } else if (selectedEquipmentType === 'Other Sensors') {
          let equipmentConf = {
            id: selectedEquipmentCategory + (counted + 1),
            name: selectedEquipmentCategory,
            count: counted + 1,
            sensors: [],
            nickName: '',
            displayNode: true,
          };

          let newEquipmentConf = otherSensorDataGenerator(equipmentConf, sensorPostFix);
          //@ts-ignore
          plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType].push(
            newEquipmentConf
          );
        } else {
          //@ts-ignore
          plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType].push(
            {
              id: selectedEquipmentCategory + (counted + 1),
              name: selectedEquipmentCategory,
              count: counted + 1,
              sensors: [],
              nickName: '',
              displayNode: true,
            }
          );
        }
        setPlantConfig(plantConfigData);
        if (selectedEquipmentType === 'Tanks') {
          console.log('inside call for tank options');
          TankOptions(plantConfigData);
          ValveOptions(plantConfigData);
          CoreEquipmentOptions(plantConfigData);
        }
        if (selectedEquipmentType === 'Core Equipments') {
          CoreEquipmentOptions(plantConfigData);
        }
      }
    } else {
      if (
        //@ts-ignore
        !plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType]
      ) {
        //@ts-ignore
        plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType] = [];
      }
      let counted = getEquipmentCount(selectedEquipmentType, '', plantConfigData);
      if (selectedEquipmentType === 'Pumps') {
        //@ts-ignore
        plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType].push({
          count: counted + 1,
          sensors: [],
          In: [],
          Out: [],
          type: '',
          make: '',
          model: '',
          motorRating: '',
          nickName: '',
          id: 'Pump' + (counted + 1),
          displayNode: true,
        });
        setPlantConfig(plantConfigData);
        ValveOptions(plantConfigData);
      } else if (selectedEquipmentType === 'Flowmeter') {
        let plantConfigData = { ...plantConfig };
        //@ts-ignore
        plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType].push({
          id: 'Flowmeter' + (counted + 1),
          count: counted + 1,
          In: [],
          Out: [],
          sensors: [{ sensorName: 'Flow Status' }, { sensorName: 'Totalizer' }],
          nickName: '',
          displayNode: true,
        });
        setPlantConfig(plantConfigData);
      } else if (selectedEquipmentType === 'Headers') {
        let plantConfigData = { ...plantConfig };
        //@ts-ignore
        plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType].push({
          id: 'Header' + (counted + 1),
          count: counted + 1,
          sensors: [],
          Out: [],
          make: '',
          model: '',
          displayNode: true,
        });
        setPlantConfig(plantConfigData);
        HeaderOptions(plantConfigData);
      } else if (selectedEquipmentType === 'Blowers') {
        let plantConfigData = { ...plantConfig };
        //@ts-ignore
        plantConfigData[parentCategory][parentIndex]['subEquipments'][selectedEquipmentType].push({
          id: 'Blower' + (counted + 1),
          count: counted + 1,
          sensors: [],
          Out: [],
          make: '',
          model: '',
          nickName: '',
          displayNode: true,
        });
        setPlantConfig(plantConfigData);
      }
    }
    toast.success('Sub-Equipment Added Successfully');
    setSelectedEquipmentType('');
    setSelectedEquipmentCategory('');
    handleClose();
  };
  useEffect(() => {
    if (
      selectedEquipmentType === 'Tanks' ||
      selectedEquipmentType === 'Core Equipments' ||
      selectedEquipmentType === 'Valves'
    ) {
      setsubPopUpOptions(Object.keys(PLANT_CONFIG_DATA[selectedEquipmentType]));
      setShowSubTypeSelector(true);
    } else if (selectedEquipmentType === 'Other Sensors') {
      setsubPopUpOptions(PLANT_CONFIG_DATA['Other Sensors']);
      setShowSubTypeSelector(true);
    } else {
      setShowSubTypeSelector(false);
    }
  }, [selectedEquipmentType]);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add new sub-equipment</DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={2} m={3}>
          <FormControl fullWidth style={{ margin: 1, minWidth: 120 }}>
            <InputLabel id="equipment-label">Equipment</InputLabel>
            <Select
              value={selectedEquipmentType}
              onChange={handleEquipmentChange}
              fullWidth
              style={{ marginRight: 4 }}
              label="Equipment"
            >
              {subEquipmentList.map(subEquipment => (
                <MenuItem key={subEquipment} value={subEquipment}>
                  {subEquipment}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showSubTypeSelector && (
            <FormControl fullWidth style={{ margin: 1, minWidth: 120 }}>
              <InputLabel id="equipment-category">Category</InputLabel>
              <Select
                value={selectedEquipmentCategory}
                onChange={handleEquipmentCategoryChange}
                fullWidth
                label="Category"
                style={{ marginRight: 4 }}
              >
                {subPopUpOptions.map(subEquipmentType => (
                  <MenuItem key={subEquipmentType} value={subEquipmentType}>
                    {subEquipmentType}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCreate}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEquipmentInsideEquipmentTypePopup;
