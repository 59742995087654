import ReactPlayer from "react-player";
import {
  Grid,
  Box,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LayoutComponent from "../components/LayoutComponent";
import VideoJson from "../constants/VideoTutorial.json";

interface VideoJsonInterface {
  [section: string]: {
    videoName: string;
    videoUrl: string;
  }[];
}

export default function VideoTutorial() {
  const videoJson: VideoJsonInterface = VideoJson;
  console.log(VideoJson);
  const videoSections = Object.keys(VideoJson);

  return (
    <LayoutComponent>
      <Box m={2}>
        {videoSections.length
          ? videoSections.map((section) => {
              return (
                <Accordion key={section}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography variant="h6">{section}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {videoJson[section].map((videoDetail) => {
                      return (
                        <>
                          <Grid item xs={12} key={videoDetail.videoName}>
                            <Box p={2}>
                              <Paper elevation={10}>
                                <Typography variant="subtitle1">
                                  {videoDetail.videoName}
                                </Typography>

                                <ReactPlayer
                                  url={videoDetail.videoUrl}
                                  controls={true}
                                  width="100%"
                                />
                              </Paper>
                            </Box>
                          </Grid>
                        </>
                      );
                    })}
                  </AccordionDetails>
                </Accordion>
              );
            })
          : null}
      </Box>
    </LayoutComponent>
  );
}
