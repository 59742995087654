//@ts-nocheck
import { useEffect, useState } from 'react';
import { InventoryOptionButton2 } from '../styles/pages/InventoryOptionsStyle';
import dpTheme from '../styles/theme/dpTheme';
import { useNavigate, useParams } from 'react-router-dom';
import classes from '../styles/pages/Solution.module.css';
import SolutionDetail from './SolutionDetail';
import color from '../styles/color';
import {
  Tab,
  Tabs,
  Grid,
  Card,
  ThemeProvider,
  Typography,
  Stack,
  styled,
  Box,
  Button,
} from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import useIsDesktop from '../utilities/customHooks/useIsDesktop';
import { getSolutionList } from '../services/SolutionList';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import { getPlantsForUser } from '../services/tickets';

interface SolutionListInterface {
  solutionId: string;
  chemicalId: string;
  solutionName: string;
  solutionPending: string;
  concentrationOfSolution: string;
  createdOn: string;
  modifiedOn: string;
  lastUsedTime: string;
  lastUpdatedValue: string;
  dosingMechanism: string;
}
interface SelectedSolutionPropsInterface {
  solutionIdProp: string;
  solutionNameProp: string;
  solutionPendingProp: string;
  lastUpdatedValueProp: string;
  lastUsedTimeProp: string;
  plantIdProp: string;
  solutionUsageMechanism: 'manual' | 'automatic';
}

interface IAsset {
  plantId: string;
  plantName: string;
}
function Solution() {
  const navigate = useNavigate();
  const [solutionList, setSolutionList] = useState<SolutionListInterface[]>([]);
  const [selectedSolution, setSelectedSolution] = useState<SolutionListInterface>();
  const [clickedSolutionId, setClickedSolutionId] = useState<string>('');
  const [isSolutionClicked, setIsSolutionClicked] = useState<boolean>(false);
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isDesktop = useIsDesktop();
  const [assetList, setAssetList] = useState<IAsset[]>([]);
  const [selectedAsset, setSelectedAsset] = useState('');

  const userId = localStorage?.getItem('userId');

  const sendProps = !isMobile ? true : false;
  const [selectedSolutionProps, setSelectedSolutionProps] =
    useState<SelectedSolutionPropsInterface>();
  const [tabValue, setTabValue] = useState<'manual' | 'automatic'>('manual');
  const [reloadPage, setReloadPage] = useState(false);

  const queryParams = new URLSearchParams(location.search);
  const plantIdParam = queryParams.get('plantId');

  useEffect(() => {
    fetchAssetList();
    return () => {};
  }, []);

  useEffect(() => {
    if (selectedAsset) fetchSolutionList();

    return () => {};
  }, [selectedAsset]);

  useEffect(() => {
    if (sendProps && selectedSolution) {
      setSelectedSolutionProps({
        solutionIdProp: selectedSolution!.solutionId,
        solutionNameProp: selectedSolution!.solutionName,
        solutionPendingProp: selectedSolution!.solutionPending,
        lastUpdatedValueProp: selectedSolution!.lastUpdatedValue,
        lastUsedTimeProp: selectedSolution!.lastUsedTime,
        plantIdProp: selectedAsset,
        solutionUsageMechanism: tabValue,
        plantIdParam: plantIdParam,
      });
    }
  }, [sendProps, selectedSolution, tabValue]);

  const handleSelectedAssetChange = (event: React.SyntheticEvent, newAsset: string) => {
    setSelectedAsset(newAsset);
  };

  const fetchAssetList = async () => {
    let tempAssetList = await getPlantsForUser(userId!);
    if (plantIdParam) {
      tempAssetList = tempAssetList.filter(asset => asset.plantId == plantIdParam);
    }
    setAssetList(tempAssetList);
    setSelectedAsset(tempAssetList[0]?.plantId);
  };

  const fetchSolutionList = () => {
    getSolutionList(selectedAsset).then(data => {
      setSolutionList(data);
      if (data?.length > 0) {
        console.log('data', data);
        let manualDosingSolutions = data?.filter(solution => {
          if (solution.dosingMechanism === 'manual') {
            return solution;
          }
        });
        setIsSolutionClicked(true);
        setSelectedSolution(manualDosingSolutions[0]);
        setClickedSolutionId(manualDosingSolutions[0].solutionId);
      } else {
        setIsSolutionClicked(false);
      }
      console.log('solution data : ', data);
    });
  };

  const handleTabValueChange = (newValue: any) => {
    setTabValue(newValue);

    let firstSolutionOfThisTabValue = solutionList.find(solution => {
      if (solution.dosingMechanism === newValue) {
        return true;
      } else return false;
    });

    console.log('first solution', firstSolutionOfThisTabValue);

    if (firstSolutionOfThisTabValue) {
      setSelectedSolution(firstSolutionOfThisTabValue);
      setClickedSolutionId(firstSolutionOfThisTabValue.solutionId);
    }
  };
  const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
      backgroundColor: '#2196F3', //  indicator color
    },
    '& .MuiTab-textColorInherit': {
      color: '#000000', // Default text color for unselected tabs (black)
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: '#2196F3', //  text color for selected tab
    },
  });

  const VerticalStyledTabs = styled(Tabs)({
    flexDirection: 'column', // Display tabs vertically
    '& .MuiTabs-indicator': {
      display: 'none', // Hide the default indicator
    },
  });
  const StyledTabLabel = styled('div')(({ selected }: { selected: boolean }) => ({
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      bottom: selected ? '-10px' : '0', // Adjust position based on selected state
      left: '50%',
      transform: 'translateX(-50%)',
      width: '100%',
      borderBottom: selected ? '2px solid #2196F3' : 'none', // Customize indicator color
    },
  }));

  function truncateSolutionName(name, maxLength = 25) {
    if (name.length > maxLength) {
      return name.substring(0, maxLength) + '...'; // Truncate name and add ellipsis
    }
    return name;
  }

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent factoryResetContainer>
        <Box className={classes.SolutionPage}>
          <Box className={classes.SolutionNavigation}>
            <Box className={classes.BackButtonHeading}>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  minWidth: '50px',
                  height: '50px',
                  border: '1px solid #DEF2F2',
                  borderRadius: '20%',
                }}
              >
                <ArrowBackIcon fontSize="small" sx={{ color: '#999999', paddingLeft: '5px' }} />
              </Button>

              <Typography variant={'h2'} sx={{ color: '#1A3356' }}>
                Solutions
              </Typography>
            </Box>
            <Box className={classes.AssetTabs}>
              <StyledTabs
                value={selectedAsset}
                onChange={handleSelectedAssetChange}
                textColor="inherit"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto assets"
              >
                {assetList?.map(asset => (
                  <Tab
                    value={asset.plantId}
                    label={<Typography variant="h6">{asset?.plantName}</Typography>}
                  />
                ))}
              </StyledTabs>
            </Box>
          </Box>
          <Box className={classes.SolutionsWrapper}>
            <Box className={classes.SolutionsListContainer}>
              <Box className={classes.SolutionsTypeTabs}>
                <VerticalStyledTabs
                  sx={{
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    marginBottom: '20px',
                  }}
                  onChange={(e, newValue) => {
                    handleTabValueChange(newValue);
                  }}
                  orientation={isDesktop ? 'horizontal' : 'vertical'}
                  centered
                  variant="fullWidth"
                  textColor="inherit"
                  value={tabValue || 'manual'}
                >
                  <Tab
                    label={
                      <StyledTabLabel selected={tabValue === 'manual'}>
                        <Typography
                          variant="caption"
                          sx={{
                            color: tabValue === 'manual' ? '#2196F3' : '#000',
                          }}
                        >
                          Manually Updated Solutions
                        </Typography>
                      </StyledTabLabel>
                    }
                    value="manual"
                  />
                  <Tab
                    label={
                      <StyledTabLabel selected={tabValue === 'automatic'}>
                        <Typography
                          variant="caption"
                          sx={{
                            color: tabValue === 'automatic' ? '#2196F3' : '#000',
                          }}
                        >
                          Automatically Updated Solutions
                        </Typography>
                      </StyledTabLabel>
                    }
                    value="automatic"
                  />
                </VerticalStyledTabs>
              </Box>
              <Box className={classes.SolutionList}>
                {solutionList &&
                  solutionList.map(solution => {
                    const solutionId = solution.solutionId;
                    const highlightSolution =
                      !isMobile && isSolutionClicked && clickedSolutionId === solutionId;

                    if (solution.dosingMechanism === tabValue)
                      return (
                        <InventoryOptionButton2
                          fullWidth
                          key={solutionId}
                          id={solutionId}
                          theme={dpTheme}
                          textColor={highlightSolution ? color.dpWhite : color.dpGrey}
                          backgroundColor={highlightSolution ? '#4188C6' : color.dpWhite}
                          // disabled={
                          //   solution.dosingMechanism === "automatic"
                          //     ? true
                          //     : false
                          // }
                          onClick={e => {
                            setClickedSolutionId(e.target?.id);
                            setIsSolutionClicked(true);
                            setSelectedSolution(solution);

                            if (isMobile) {
                              navigate(`/SolutionDetail`, {
                                state: {
                                  solutionId: solution.solutionId,
                                  solutionName: solution.solutionName,
                                  solutionPending: solution.solutionPending,
                                  lastUsedTime: solution.lastUsedTime,
                                  lastUpdateValue: solution.lastUpdatedValue,
                                  solutionUsageMechanism: tabValue,
                                },
                              });
                            }
                          }}
                        >
                          <Typography>{solution.solutionName}</Typography>
                        </InventoryOptionButton2>
                      );
                  })}
              </Box>
            </Box>

            <Box className={isMobile ? null : classes.SolutionsListContainer}>
              {!isMobile && isSolutionClicked && <SolutionDetail {...selectedSolutionProps} />}
            </Box>
          </Box>
        </Box>
      </LayoutComponent>
    </ThemeProvider>
  );
}

export default Solution;
