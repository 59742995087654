export default function DeleteFilledIcon() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.66667 12.862C3.66667 13.5953 4.26667 14.1953 5 14.1953H10.3333C11.0667 14.1953 11.6667 13.5953 11.6667 12.862V4.86198H3.66667V12.862ZM12.3333 2.86198H10L9.33333 2.19531H6L5.33333 2.86198H3V4.19531H12.3333V2.86198Z"
        fill="#ACB3B9"
      />
    </svg>
  );
}
