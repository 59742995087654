interface timeUnit {
  timeInMinutes: number;
  displayName: string;
  shortHandName: string;
}
const TIME_UNITS: { [key: string]: timeUnit } = {
  minutes: {
    timeInMinutes: 1,
    displayName: "Minutes",
    shortHandName: "m",
  },
  hours: {
    timeInMinutes: 60,
    displayName: "Hours",
    shortHandName: "H",
  },
  days: {
    timeInMinutes: 1440,
    displayName: "Days",
    shortHandName: "D",
  },
  weeks: {
    timeInMinutes: 1440 * 7,
    displayName: "Weeks",
    shortHandName: "W",
  },
  months: {
    timeInMinutes: 1440 * 30,
    displayName: "Months",
    shortHandName: "M",
  },
};
function delay(delayInms: number) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(2);
    }, delayInms);
  });
}

export { TIME_UNITS, delay };
