import { FC, useEffect, useState } from 'react';
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import { getClonedPlantConfig } from '../../services/plantConfiguration';
import { getAllPlants } from '../../services/plant';

interface ClonePlantConfigProps {
  open: boolean;
  setOpen: Function;
  setPlantConfig: Function;
  plantId: string;
}

const ClonePlantConfigPopup: FC<ClonePlantConfigProps> = ({
  open,
  setOpen,
  setPlantConfig,
  plantId,
}) => {
  const [clonedPlantConfig, setClonedPlantConfig] = useState<any>({
    Tanks: [],
    'Core Equipments': [],
    Pumps: [],
    Flowmeter: [],
    Valves: [],
    Headers: [],
    Blowers: [],
    'Other Sensors': [],
  });
  const [plantList, setPlantList] = useState<any[]>([]);
  const [selectedPlant, setSelectedPlant] = useState<string>('');
  function handlePlantSelection(event: any) {
    // handle plant selection
    console.log(event.target.value);
    let existingPlantId = event.target.value as string;
    setSelectedPlant(existingPlantId);
  }
  function handleClose() {
    setOpen(false);
  }
  function handleCreate() {
    setOpen(false);
    setPlantConfig(clonedPlantConfig);
  }
  useEffect(() => {
    if (selectedPlant) {
      // fetch cloned plant config
      getClonedPlantConfig(plantId, selectedPlant).then(data => {
        setClonedPlantConfig(data.data);
      });
    }
  }, [selectedPlant]);

  useEffect(() => {
    getAllPlants().then((data: any) => {
      console.log(data);
      setPlantList(data);
    });
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select Pre-Existing plant to clone config from </DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={2} m={3}>
          <FormControl fullWidth style={{ margin: 1, minWidth: 120 }}>
            <InputLabel id="equipment-label">Plant</InputLabel>
            <Select
              value={selectedPlant}
              onChange={handlePlantSelection}
              fullWidth
              style={{ marginRight: 4 }}
              label="Equipment"
            >
              {plantList.map(plant => (
                <MenuItem key={plant.plantName} value={plant.id}>
                  {plant.plantName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleCreate}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ClonePlantConfigPopup;
