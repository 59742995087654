import { FormControl, ThemeProvider, Box, Stack } from "@mui/material";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router";
import dpTheme from "../../styles/theme/dpTheme";

const styles = {
  container: {
    minWidth: "300px",
  },
  formHeading: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#183650",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
    fontSize: "14px",
    color: "#999999",
  },
  gotoLink: {
    fontSize: "14px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

export default function PasswordErrorCheckEmail() {
  // let navigate = useNavigate();

  return (
    <ThemeProvider theme={dpTheme}>
      <Box sx={styles.container}>
        <FormControl fullWidth={true}>
          <Stack direction="column" alignItems="flex-start" gap={3}>
            <Box sx={styles.formHeading}>Password Reset</Box>

            <Box sx={styles.infoContainer}>
              <span>
                Password reset link has been sent. Please check your email{" "}
              </span>
            </Box>

            {/* <Button fullWidth variant="contained" onClick={resetStateToEmail}>
              Retry
            </Button> */}
            <Box sx={styles.gotoLink}>
              <Link to="/Login">Log In</Link>
            </Box>
          </Stack>
        </FormControl>
        <ToastContainer />
      </Box>
    </ThemeProvider>
  );
}
