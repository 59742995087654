import axiosInstance from "../constants/axiosInstance";

export const getAllStores = async (
  skip: number,
  limit: number,
  searchQuery: string
) => {
  try {
    const { data } = await axiosInstance().post(
      `/store/getAll?skip=${skip}&limit=${limit}&searchQuery=${searchQuery}`
    );
    return data?.data;
  } catch (err) {
    console.error("Error | getAllStores | ", err);
  }
};

export const getAllClients = async () => {
  try {
    const { data } = await axiosInstance().post(`/store/getAllClients`);
    return data?.data;
  } catch (err) {
    console.error("ERROR | getAllClients | ", err);
  }
};

export const getSelectedClientAssets = async (selectedClient: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/store/getSelectedClientAssets/${selectedClient}`
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | getSelectedClientAssets | ", err);
  }
};

interface ICreateStorePayload {
  name: string;
  assets: string[];
  clients: string[];
  archived?: boolean;
  storeAbbr?: string;
}

export const createStore = async (payload: ICreateStorePayload) => {
  try {
    const { data } = await axiosInstance().post("/store/create", payload);
    return data?.data;
  } catch (err) {
    console.error("Error | createStore | ", err);
    throw err;
  }
};

interface IUpdateStorePayload {
  name?: string;
  assets?: string[];
  clients?: string[];
  archived?: boolean;
  storeAbbr?: string;
}

export const updateStore = async (
  storeId: string,
  payload: IUpdateStorePayload
) => {
  try {
    const { data } = await axiosInstance().put(
      `/store/update/${storeId}`,
      payload
    );
    return data;
  } catch (err) {
    console.error("Error | updateStore | ", err);
  }
};

export const getAllStoresAssetData = async (userid: string) => {
  try{
    const { data } = await axiosInstance().post(`/store/getAll?clientId=${userid}`);
    return data;
  } catch(err){
    console.log("Error | getAllStoresAssetData | ", err)
  }
}
export const getStoreList = async (userId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/store/getStoreList?userId=${userId}`
    );
    return data.data;
  } catch (err) {
    console.error("ERROR | getStoreList | ", err);
    throw err;
  }
};

export const getStoreDetails = async (storeId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/store/getStoreDetails?storeId=${storeId}`
    );
    return data.data;
  } catch (err) {
    console.error("ERROR | getStoreDetails | ", err);
    throw err;
  }
};

export const getAbbrList = async (storeName: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/store/generateStoreAbbrList`,
      { storeName: storeName }
    );
    return data?.data;
  } catch (err) {
    console.error("Error | getAbbrList | ", err);
  }
};
export const verifyStoreAbbr = async (storeAbbr: string) => {
  try {
    const { data } = await axiosInstance().post(`/store/verifyStoreAbbr`, {
      storeAbbr: storeAbbr,
    });
    return data?.data;
  } catch (err) {
    console.error("Error | verifyStoreAbbr | ", err);
  }
};
