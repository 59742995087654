//@ts-nocheck
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Paper,
  TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete,
  Typography,
} from "@mui/material";

function ReceiverConfig({
  updateDirectValue,
  indexTask,
  updatePrimaryUser,
  task,
  userList,
  updateCCUserArrayVals,
  updateEscalationLevel,
  assignRole,
  escalationOptions,
}) {
  const renderPrimaryUserLabel = (User: any) => (
    <Box style={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
      <InputLabel id={"primaryUser" + indexTask}>{User.name}</InputLabel>
    </Box>
  );

  const emailAfterTimeList = [
    { val: 0, display: "never" },
    { val: 24, display: "24 Hours" },
    { val: 48, display: "48 Hours" },
    { val: 72, display: "72 Hours" },
  ];

  const renderEscalationLevels = () =>
    Object.keys(task?.["escalations"]).map((escalationLevel) => (
      <div style={{ padding: "10px" }} key={escalationLevel}>
        <Typography style={{ marginTop: "20px" }} variant="h6">
          {escalationLevel}
        </Typography>
        <Autocomplete
          id={`escalationGroup-${escalationLevel}`}
          options={escalationOptions}
          style={{ marginTop: "5px" }}
          value={task.escalations[escalationLevel].group}
          onChange={(event, newValue) => {
            updateEscalationLevel(escalationLevel, "group", newValue);
          }}
          renderInput={(params) => (
            <TextField
              required
              style={{ marginTop: "20px" }}
              {...params}
              variant="standard"
              label="Escalation Group"
            />
          )}
        />
        <TextField
          fullWidth
          required
          style={{ marginTop: "20px", marginBottom: "20px" }}
          value={task.escalations[escalationLevel].time}
          label="Escalation Time"
          onChange={(event) => {
            updateEscalationLevel(escalationLevel, "time", event.target.value);
          }}
        />
      </div>
    ));

  return (
    <>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        style={{ marginTop: "20px" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div
            style={{
              font: "Montserrat Bold",
              fontSize: 20,
            }}
          >
            Receiver Config
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Box m={1} style={{ width: "100%" }}>
            <Paper>
              <Box m={1} p={1}>
                <div
                  style={{
                    font: "Montserrat Bold",
                  }}
                >
                  <Checkbox
                    checked={task["emergency"]}
                    onChange={(e) => {
                      updateDirectValue("emergency", e.target.checked);
                    }}
                  />
                  Emergency
                </div>
                <InputLabel
                  id={"primaryUser" + indexTask}
                  style={{ marginTop: "15px" }}
                >
                  Primary User
                </InputLabel>
                <Select
                  id={"Primary User"}
                  key={"primary user" + indexTask}
                  value={task["primaryUser"] ? task["primaryUser"] : []}
                  onChange={(e) => updatePrimaryUser(e.target.value)}
                  renderValue={renderPrimaryUserLabel}
                  fullWidth
                >
                  {userList.map((user: any) => {
                    return (
                      <MenuItem value={user["_id"]}>{user["name"]}</MenuItem>
                    );
                  })}
                </Select>
                <InputLabel
                  id={"ccUsers" + indexTask}
                  style={{ marginTop: "15px" }}
                >
                  CC Users
                </InputLabel>

                <Autocomplete
                  multiple
                  id="tags-standard"
                  options={userList}
                  getOptionLabel={(option) => option?.name ?? ""}
                  value={task["ccUsers"] || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select a User"
                      placeholder="Add more users"
                    />
                  )}
                  onChange={(event: any, newValue: any) => {
                    updateCCUserArrayVals(newValue);
                  }}
                />
                <Autocomplete
                  id="tags-standard"
                  options={assignRole}
                  style={{
                    marginTop: "15px",
                    marginBottom: "15px",
                  }}
                  value={task["toUser"]}
                  onChange={(event, newValue) => {
                    updateDirectValue("toUser", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      variant="standard"
                      label="For Role"
                    />
                  )}
                />
                <Accordion
                  TransitionProps={{
                    unmountOnExit: true,
                  }}
                  style={{ marginTop: "15px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      style={{
                        font: "Montserrat Bold",
                        fontSize: 20,
                      }}
                    >
                      Escalation Levels
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box m={1} style={{ width: "100%" }}>
                      {/* <Paper> */}
                      <div
                        style={{
                          font: "Montserrat Bold",
                          fontSize: 20,
                        }}
                      >
                        {renderEscalationLevels()}
                        <div>
                          <Typography
                            style={{ margin: "20px", display: "inline-flex" }}
                          >
                            After escalation 3 send emails after
                          </Typography>
                          <Select
                            id={"emailSelction" + indexTask}
                            value={task["emailAfter"]}
                            key={task["emailAfter"] + indexTask}
                            onChange={(e) =>
                              updateDirectValue("emailAfter", e.target.value)
                            }
                            style={{ marginLeft: "30px" }}
                            label="Logical Operator"
                          >
                            {emailAfterTimeList.map((time) => {
                              return (
                                <MenuItem value={time.val}>
                                  {time.display}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </div>
                      </div>
                      {/* </Paper> */}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Paper>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default ReceiverConfig;
