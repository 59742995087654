import axiosInstance from '../constants/axiosInstance';

const getLayoutDiagramById = async (assetId: string) => {
  try {
    const { data } = await axiosInstance().post(`/layoutDiagrams/getLayoutDiagramById/${assetId}`);
    return data;
  } catch (err) {
    console.error(`ERROR | getLayoutDiagram`, err);
  }
};

const getLayoutDiagramsByFilters = async (filters: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/layoutDiagrams/getLayoutDiagramsByFilters`,
      filters
    );
    return data;
  } catch (err) {
    console.error(`ERROR | getLayoutDiagramsByFilters`, err);
  }
};

// const getSingleLayoutDiagramByFilters = async (filters: any) => {
//   try {
//     const { data } = await axiosInstance().post(
//       `/layoutDiagrams/getSingleLayoutDiagramByFilters`,
//       filters
//     );
//     return data;
//   } catch (err) {
//     console.error(`ERROR | getSingleLayoutDiagramByFilters`, err);
//   }
// };
const getSingleLayoutDiagramByFilters = async (filters: any, signal?: AbortSignal) => {
  try {
    if (signal) {
      const { data } = await axiosInstance().post(
        `/layoutDiagrams/getSingleLayoutDiagramByFilters`,
        filters,
        { signal }
      );
      return data;
    } else {
      const { data } = await axiosInstance().post(
        `/layoutDiagrams/getSingleLayoutDiagramByFilters`,
        filters
      );
      return data;
    }
  } catch (err) {
    //@ts-ignore
    if (axiosInstance.isCancel(err)) {
      //@ts-ignore
      console.log('Request canceled', err?.message);
    } else {
      console.error(`ERROR | getSingleLayoutDiagramByFilters`, err);
    }
  }
};

const updateLayoutDiagramById = async (id: any, layoutDiagram: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/layoutDiagrams/updateLayoutDiagram/${id}`,
      layoutDiagram
    );
    return data;
  } catch (err) {
    console.error(`ERROR | updateLayoutDiagramById`, err);
  }
};

const getChokePointConfigByLayoutId = async (layoutId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/layoutDiagrams/getChokePointConfigByLayoutId/${layoutId}`
    );
    return data;
  } catch (err) {}
};

const saveChokePointConfig = async (chokePointConfig: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/layoutDiagrams/saveChokePointConfig`,
      chokePointConfig
    );
    return data;
  } catch (err) {
    throw err;
  }
};

const getLayoutDiagramMetaDataForAssetId = async (assetId: string, signal?: AbortSignal) => {
  try {
    const { data } = await axiosInstance().post(
      `/layoutDiagrams/getLayoutDiagramMetaDataForAssetId/${assetId}`,
      { signal }
    );
    return data;
  } catch (err) {}
};

export {
  getLayoutDiagramById,
  getLayoutDiagramsByFilters,
  getSingleLayoutDiagramByFilters,
  updateLayoutDiagramById,
  getChokePointConfigByLayoutId,
  saveChokePointConfig,
  getLayoutDiagramMetaDataForAssetId,
};
