import { Box, Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { useEffect, useState } from 'react';
import { getSensorsForPlant } from '../../services/plantConfiguration';
import MultiSensorSelectorAndSearch from '../GeneralComponents/MultiSensorSearchAndSelector';

interface IProps {
  plant: any;
  plantId: any;
  plantIndex: number;
  plantConfig: any;
  setPlantConfig: any;
  handleShowOnDiagramCheck: Function;
}

function SinglePlantOutputConfigurator({
  plant,
  plantId,
  plantIndex,
  plantConfig,
  setPlantConfig,
  handleShowOnDiagramCheck,
}: IProps) {
  const [plantSensorList, setPlantSensorList] = useState<any[]>([]);
  const [configuredSensorList, setConfiguredSensorList] = useState<any[]>([]);
  useEffect(() => {
    if (plant['sensors']) {
      setConfiguredSensorList(plant['sensors']);
    }
  }, []);
  useEffect(() => {
    getSensorsForPlant(plantId!).then(data => {
      setPlantSensorList(data);
    });
  }, []);
  useEffect(() => {
    let newPlantConfig = { ...plantConfig };
    newPlantConfig['outputPlants'][plantIndex]['sensors'] = configuredSensorList;
    setPlantConfig(newPlantConfig);
  }, [configuredSensorList]);
  function deleteSensorFromList(index: number) {
    let oldConfiguredSensors = [...configuredSensorList];
    oldConfiguredSensors.splice(index, 1);
    setConfiguredSensorList(oldConfiguredSensors);
  }
  return (
    <>
      <div style={{ display: 'flex', margin: 'auto', alignContent: 'center' }}>
        <Grid style={{}} container spacing={2}>
          {/* Column 1 */}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              borderRight: '1px solid #E0E0E0',
              paddingRight: '16px',
              //   display: 'flex',
              //   flexDirection: 'column',
              //   alignItems: 'center',
              //   justifyContent: 'center',
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item style={{ width: '100%' }}>
                <MultiSensorSelectorAndSearch
                  sensorList={plantSensorList}
                  configuredSensors={configuredSensorList}
                  setConfiguredSensors={setConfiguredSensorList}
                  alwaysExpanded={true}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* Column 2 */}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              borderRight: 'none',
              // paddingLeft: "16px",
              //   display: 'flex',
              //   flexDirection: 'column',
              //   alignItems: 'center',
              //   justifyContent: 'center',
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Box mt={3}>
                  <FormControlLabel
                    sx={{ width: '100%', marginBottom: '10px' }}
                    control={
                      <Checkbox
                        checked={plant.displayNode}
                        // checked={check}
                        onChange={() => {
                          handleShowOnDiagramCheck('outputPlants', plantIndex, !plant.displayNode);
                        }}
                        name={'Show on diagram'}
                      />
                    }
                    label={'Show on diagram'}
                  />
                  <Typography variant="h5">Configured Sensors</Typography>
                  <Box>
                    {configuredSensorList.map((sensor: any, index: number) => {
                      return (
                        <Box
                          key={index}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <Typography>{sensor.sensorTag}</Typography>
                          <IconButton
                            onClick={() => {
                              deleteSensorFromList(index);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default SinglePlantOutputConfigurator;
