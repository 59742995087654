import { memo, useState } from 'react';
// import { Box, Grid, makeStyles, Paper } from "@mui/material";

// import { useNavigate } from "react-router";
import { useEffect } from 'react';
// import GeneralisedExpandedWidget from "./ExpandedGeneralisedWidget";
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import TableDWidget from './subComponents/tableDWidget';
import TableCWidget from './subComponents/tableCWidget';
import NumberWidgetPdfView from './subComponents/numberWidgetPdfView';
import GeneralisedGraphWidget from './subComponents/generalisedGraphWidget';
import axios from 'axios';

///// interfaces
// import {
//   // widgetDataInterface,
//   widgetStructureInterface,
// } from "../../Interfaces/widgetInterfaces";
// import { timeFrame } from "../../Interfaces/dashboardPageInterfaces";
import { getWidgetData } from '../../services/dashboardPage';
// import { GRANULARITY } from "../../constants/constants";

SwiperCore.use([Navigation, Pagination, Autoplay]);

//   ChartJS.register(
//     LinearScale,
//     CategoryScale,
//     BarElement,
//     PointElement,
//     LineElement,
//     Legend,
//   );

// interface generalisedWidgetProps {
//   widgetStructure: widgetStructureInterface;
//   controller?: any;
//   timeFrame: timeFrame | null;
//   refresh: boolean;
//   plantId: string;
// }
const GeneralisedWidgetForPdfPage = memo((props: any) => {
  // const add530HoursInTimeFrame = (timeFrame: any | null) => {
  //   if (!timeFrame)
  //     return {
  //       startDate: new Date(),
  //       endDate: new Date(),
  //       granularity: GRANULARITY[1].value,
  //     };
  //   const newTimeFrame = {
  //     startDate: new Date(
  //       timeFrame?.startDate?.getTime() + 1000 * 60 * 60 * 5.5
  //     ).toISOString(),
  //     endDate: new Date(
  //       timeFrame?.endDate?.getTime() + 1000 * 60 * 60 * 5.5
  //     ).toISOString(),
  //   };

  //   return {
  //     ...newTimeFrame,
  //     granularity: timeFrame.granularity,
  //   };
  // };
  //@ts-ignore
  const [expandedViewPopup, setExpandedViewPopup] = useState<boolean>(false);
  const {
    widgetStructure,
    timeFrame: timeFrame2,
    plantId,
    controller,
    refresh,
    widgetId,
    handleChildChange,
    background,
  } = props;
  const [widgetData, setWidgetData] = useState<any>(null);
  // const [refresh2, setRefresh2] = useState(false);
  const [loading, setLoading] = useState(false);
  //@ts-ignore
  const [isError, setIsError] = useState(false);

  const [dataSent, setDataSent] = useState(false);

  useEffect(() => {
    // let timeFrame = add530HoursInTimeFrame(timeFrame2);
    setIsError(false);
    setLoading(true);

    const fetchWidgetData = async () => {
      try {
        const widgetData = await getWidgetData({
          plantId,
          widgetId,
          parent: 'dashboard',
          ...timeFrame2,
        });
        setWidgetData(widgetData);
        if (!dataSent) {
          handleChildChange(widgetData);
          setDataSent(true);
        }
        setLoading(false);
      } catch (err) {
        if (axios.isCancel(err)) {
        } else {
          setIsError(true);
        }
      }
    };

    fetchWidgetData();

    return function cleanup() {
      controller.abort();
    };
  }, [controller, widgetStructure, refresh, dataSent]);

  return (
    <>
      {widgetStructure.widgetType === 'graph' && widgetData !== null ? (
        <GeneralisedGraphWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          setExpandedViewPopup={setExpandedViewPopup}
          isLoading={loading}
          headerType="normal"
          enableZoom={true}
          viewEveryNthTick={1}
          ticksColor="light"
        />
      ) : null}

      {widgetStructure.widgetType === 'tableC' && widgetData !== null ? (
        <TableCWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          setExpandedViewPopup={setExpandedViewPopup}
          isLoading={loading}
          headerType="expanded"
        />
      ) : null}
      {widgetStructure.widgetType === 'tableD' && widgetData !== null ? (
        <TableDWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          setExpandedViewPopup={setExpandedViewPopup}
          isLoading={loading}
          headerType="expanded"
          showLinkIcon={false}
          linkOnWidget={''}
        />
      ) : null}

      {widgetStructure.widgetType === 'number' && widgetData !== null ? (
        <NumberWidgetPdfView
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          isLoading={loading}
          background={background}
        />
      ) : null}

      {/* {expandedViewPopup ? (
        <GeneralisedExpandedWidget
          widgetDataForGenWidget={widgetData}
          widgetStructure={widgetStructure}
          timeFrame={timeFrame}
          refresh={props.refresh}
          setIsOpen={setExpandedViewPopup}
        />
      ) : null} */}
    </>
  );
});

export default GeneralisedWidgetForPdfPage;
