import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';

import { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import CircularProgress from '@mui/material/CircularProgress';
import { getChemicalLogGridData } from '../../services/ChemicalLog';
import dpStyles from '../../styles/global/dpStyles.module.css';
import { ChemicalLogDataInterface, Filters } from '../../Interfaces/ChemicalLog';

import { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import { UNITS_INFO, UNITS_ALLOWED } from '../../utils/units.utils';
import useIsMobile from '../../utilities/customHooks/useIsMobile';

import classes from '../../styles/SolutionDetailsForChemical.module.css';
import TableHead from '@mui/material/TableHead';

import styles from '../../styles/pages/Store.module.css';

interface IProps {
  dateRange: DateRange<Dayjs>;
  storeId: string;
  asset: string;
  chemicalId: string;
  appliedFilters: Filters | undefined;
  filtersChanged: boolean;
  assetName: any;
  selectedAssetTab: any;
}

export default function ChemicalLogGrid({
  dateRange,
  storeId,
  asset,
  chemicalId,
  appliedFilters,
  filtersChanged,
  assetName,
  selectedAssetTab,
}: IProps) {
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const [chemicalLogGridData, setChemicalLogGridData] = useState<ChemicalLogDataInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const [hasNextPage, setHasNextPage] = useState(true);

  useEffect(() => {
    fetchChemicalLogGridData();
    return () => {};
  }, [filtersChanged, dateRange[0], dateRange[1], assetName]);

  const fetchChemicalLogGridData = () => {
    setLoading(true);
    const assetId = assetName === 'All Asset' ? assetName : selectedAssetTab._id;
    getChemicalLogGridData({
      storeId,
      asset: assetId,
      chemicalId,
      startDate: dateRange[0]?.toISOString(),
      endDate: dateRange[1]?.toISOString(),
      ...appliedFilters,
    }).then(data => {
      setChemicalLogGridData(data);
      if (data?.length < 10) {
        setHasNextPage(false);
      }
      setLoading(false);
    });
  };

  const loadMoreSolutions = async () => {
    let skip = chemicalLogGridData.length || 0;
    let limit = 10;
    getChemicalLogGridData({
      storeId,
      asset,
      chemicalId,
      skip,
      startDate: dateRange[0]?.toISOString(),
      endDate: dateRange[1]?.toISOString(),
      limit,
      ...appliedFilters,
    }).then(data => {
      setChemicalLogGridData([...chemicalLogGridData, ...data]);
      if (data?.length < 10) {
        setHasNextPage(false);
      }
    });
  };

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMoreSolutions,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: undefined,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 360px 0px',
  });

  return (
    <Box ref={rootRef} sx={{ width: '100%' }}>
      <TableContainer
        component={Paper}
        sx={{
          width: '100%',
          border: '1px solid #DAF1F1',
          boxShadow: 'none',
          overflowY: 'auto',
          maxHeight: '738px',

          '&::-webkit-scrollbar': {
            width: '0',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'transparent',
          },
          borderRadius: '8px',
        }}
      >
        <Table aria-label="simple table" sx={{ borderRadius: 'none !important' }}>
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: '#FFF',
              zIndex: 1,
            }}
          >
            <TableCell
              className={styles.StoreTableHeaderCellSolutionDetail}
              sx={{ width: isMobile ? '50%' : '30%' }}
            >
              <div className={styles.HeaderContent3}>Remarks</div>
            </TableCell>
            {isMobile === true ? (
              <TableCell
                align="center"
                className={styles.StoreTableHeaderCellSolutionDetail}
                sx={{ width: '80%' }}
              >
                <div className={styles.HeaderContent3}>Amount, Date & Time</div>
              </TableCell>
            ) : (
              <>
                <TableCell
                  align="center"
                  className={styles.StoreTableHeaderCellSolutionDetail}
                  sx={{ width: '19%' }}
                >
                  <div className={styles.HeaderContent3}>Amount</div>
                </TableCell>
                <TableCell
                  align="center"
                  className={styles.StoreTableHeaderCellSolutionDetail}
                  sx={{ width: '25%' }}
                >
                  <div className={styles.HeaderContent3}>Units</div>
                </TableCell>
                <TableCell
                  className={styles.StoreTableHeaderCellSolutionDetail}
                  sx={{ width: '25%' }}
                >
                  <div className={styles.HeaderContent3}>Date & Time</div>
                </TableCell>
              </>
            )}
          </TableHead>
          {loading ? (
            <TableBody>
              <TableRow>
                <TableCell sx={{ borderBottom: 'none' }} colSpan={isMobile ? 2 : 4}>
                  {loading && (
                    <Box className={classes.infiniteScrollerLoader} ref={sentryRef}>
                      <CircularProgress size="30px" color="inherit" />
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {chemicalLogGridData &&
                chemicalLogGridData.map(row => (
                  <TableRow
                    key={row.id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell sx={{ borderBottom: '1px solid #DEF2F2', width: '30%' }}>
                      {row.remark}
                    </TableCell>
                    {isMobile === true ? (
                      <TableCell
                        align="center"
                        sx={{ borderBottom: '1px solid #DEF2F2', width: '40%' }}
                      >
                        <div
                          className={
                            row.amount.type === 'ADD' ? dpStyles.CreditStyle : dpStyles.DebitStyle
                          }
                        >
                          <span>
                            {row.amount.type === 'ADD' ? '+' : '-'} {row.amount.value + ' '}
                          </span>
                          {UNITS_ALLOWED.includes(row.unit)
                            ? UNITS_INFO[row.unit]?.fullForm
                            : row.unit}
                        </div>
                        <Typography
                          variant="body2"
                          sx={{ marginTop: '7px', fontSize: '12px', whiteSpace: 'nowrap' }}
                        >
                          {row.dateAndTime}
                        </Typography>
                      </TableCell>
                    ) : (
                      <>
                        <TableCell
                          align="center"
                          sx={{ borderBottom: '1px solid #DEF2F2', width: '20%' }}
                        >
                          <div
                            className={
                              row.amount.type === 'ADD' ? dpStyles.CreditStyle : dpStyles.DebitStyle
                            }
                          >
                            <span>
                              {row.amount.type === 'ADD' ? '+' : '-'} {row.amount.value}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            borderBottom: '1px solid #DEF2F2',
                            width: '25%',
                          }}
                        >
                          {UNITS_ALLOWED.includes(row.unit)
                            ? UNITS_INFO[row.unit]?.fullForm
                            : row.unit}
                        </TableCell>
                        <TableCell
                          sx={{
                            borderBottom: '1px solid #DEF2F2',
                            whiteSpace: 'nowrap',
                            width: '25%',
                          }}
                        >
                          {row.dateAndTime}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))}
              {(loading || hasNextPage) && (
                <TableRow>
                  <TableCell sx={{ borderBottom: 'none' }} colSpan={isMobile ? 2 : 4}>
                    <Box className={classes.infiniteScrollerLoader} ref={sentryRef}>
                      <CircularProgress size="30px" color="inherit" />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
              {chemicalLogGridData.length === 0 && !(loading || hasNextPage) && (
                <TableRow>
                  <TableCell sx={{ borderBottom: 'none' }} colSpan={isMobile ? 2 : 4}>
                    <Box className={classes.infiniteScrollerLoader} ref={sentryRef}>
                      <p>No Details</p>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </Box>
  );
}
