import React from "react";
import { Stack, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TIME_UNITS } from "../../utils/time.utils";
import { filterGranularityOptionsBasedOnTimeRange } from "../../utils/dashboard.utils";

interface propsInterface {
  minAllowedGranularitySettings: any;
  changeWidgetMinAllowedGranularitySettings: Function;
  widgetClusterIndex: number;
  widgetIndex: number;
  minAllowedTimeRangeSettings: any;
}

export default function GranularitySettings(props: propsInterface) {
  const {
    minAllowedGranularitySettings,
    changeWidgetMinAllowedGranularitySettings,
    widgetClusterIndex,
    widgetIndex,
    minAllowedTimeRangeSettings,
  } = props;

  const reCalculateAndSetGranularityInMins = () => {
    let unit = minAllowedGranularitySettings["unit"];
    const granularityInMinsPerUnit: number = TIME_UNITS[unit].timeInMinutes;
    let unitMultiplier = minAllowedGranularitySettings.unitMultiplier;
    const newGranularityInMins = granularityInMinsPerUnit * unitMultiplier;
    changeWidgetMinAllowedGranularitySettings(
      widgetClusterIndex,
      widgetIndex,
      "granularityInMins",
      newGranularityInMins
    );
  };

  const isOptionDisabled = (option: any) => {
    let allowedGranularityOptions = filterGranularityOptionsBasedOnTimeRange(
      minAllowedTimeRangeSettings.timeRangeInMins
    );
    return !allowedGranularityOptions.includes(option);
  };

  return (
    <FormControl fullWidth>
      <Stack direction="row" alignItems="center" gap={2} sx={{ width: "100%" }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={minAllowedGranularitySettings?.unit}
          fullWidth
          onChange={(e) => {
            changeWidgetMinAllowedGranularitySettings(
              widgetClusterIndex,
              widgetIndex,
              "unit",
              e.target.value
            );
            reCalculateAndSetGranularityInMins();
          }}
        >
          {Object.keys(TIME_UNITS).map((timeUnit) => {
            return (
              <MenuItem value={timeUnit} disabled={isOptionDisabled(timeUnit)}>
                {TIME_UNITS[timeUnit].displayName}
              </MenuItem>
            );
          })}
        </Select>
        <TextField
          type="number"
          disabled
          value={minAllowedGranularitySettings?.unitMultiplier}
          variant="outlined"
          label="time"
          inputProps={{
            maxLength: 100,
            step: "1",
            min: 1,
          }}
          style={{ width: "100px" }}
          onChange={(
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            changeWidgetMinAllowedGranularitySettings(
              widgetClusterIndex,
              widgetIndex,
              "unitMultiplier",
              e.target.value
            );
            reCalculateAndSetGranularityInMins();
          }}
        />
      </Stack>
    </FormControl>
  );
}
