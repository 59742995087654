import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
const CustomDialog = ({ open, onClose, children }: any) => {
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const styles = {
    dialog: {
      padding: isMobile ? '10px' : '33px 30px', // Remove padding in mobile view
      borderRadius: '8px',
      maxWidth: 'inherit',
      overflowY: 'auto',
      margin: isMobile ? '0 10px' : 'auto',
      zIndex: +2147483647,
    },
    closeButton: {
      position: 'absolute',
      top: 6,
      right: 14,
      display: isMobile ? 'none' : 'flex', // Hide close button in mobile view
    },
  };
  return (
    <Dialog open={open} onClose={onClose} PaperProps={{ sx: styles.dialog }}>
      {!isMobile && ( // Conditionally render IconButton based on isMobile
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={styles.closeButton}
        >
          <CloseIcon />
        </IconButton>
      )}
      <Box>{children}</Box>
    </Dialog>
  );
};
export default CustomDialog;
