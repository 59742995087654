export default function SubfeatureIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '16px' }}
    >
      <path
        d="M5.64016 2.30274C6.38833 1.99284 7.19022 1.83333 8.00004 1.83333C8.80986 1.83333 9.61175 1.99284 10.3599 2.30274C11.1081 2.61265 11.7879 3.06688 12.3605 3.63951C12.9332 4.21214 13.3874 4.89194 13.6973 5.64012C14.0072 6.38829 14.1667 7.19018 14.1667 8C14.1667 9.6355 13.517 11.204 12.3605 12.3605C11.2041 13.517 9.63554 14.1667 8.00004 14.1667C7.19022 14.1667 6.38833 14.0072 5.64016 13.6973C4.89198 13.3874 4.21218 12.9331 3.63955 12.3605C2.48307 11.204 1.83337 9.6355 1.83337 8C1.83337 6.3645 2.48307 4.79598 3.63955 3.63951C4.21218 3.06688 4.89198 2.61265 5.64016 2.30274Z"
        fill="#DAE4F1"
        stroke="#193458"
      />
    </svg>
  );
}
