import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { OtherSensor } from '../../../Interfaces/PlantConfigurationInterface';
import { PlantConfigStyle } from '.././PlantConfigStyle';
import { styledBoxWithShadow } from '../../../styles/global/components/dpBox';
import DeleteConfirmation from '../../../globalComponents/dialogs/DeleteConfirmation';
import { useState } from 'react';
import DiagramSelectComponent from '.././subComponents/DiagramSelectComponent';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SensorConfigForOtherSensors from '../subComponents/SenorConfigForOtherSensors';
interface IProps {
  otherSensorData: OtherSensor[];
  RemoveElement: Function;
  updateValue: Function;
  updateOtherSensorTag: Function;
  updateOtherSensorNickname: Function;
  handleShowOnDiagramCheckForSubequipment: Function;
}

function OtherSensorsConfig({
  otherSensorData,
  RemoveElement,
  updateValue,
  updateOtherSensorTag,
  updateOtherSensorNickname,
  handleShowOnDiagramCheckForSubequipment,
}: IProps) {
  const [equipmentIndex, setEquipmentIndex] = useState<Number | null>(null);
  const [equipmentName, setEquipmentName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [equipment, setEquipment] = useState('');
  return (
    <>
      <Box m={1} p={2}>
        {otherSensorData.map((sensor, index) => {
          return (
            <>
              <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0.6rem',
                    }}
                  >
                    <Typography variant="h4">
                      {sensor.name} {sensor.count}
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionSummary>
                  <Box m={1} p={1} sx={styledBoxWithShadow}>
                    <Box m={1}>
                      <TextField
                        id={'age' + index}
                        label="Age in months"
                        value={sensor['age']}
                        type="Number"
                        onChange={e =>
                          updateValue({
                            category: 'Other Sensors',
                            index,
                            valuefor: 'age',
                            value: e.target.value,
                          })
                        }
                        inputProps={{
                          maxLength: 1000,
                          step: '1',
                        }}
                        style={PlantConfigStyle.equipmentTextField}
                      />
                    </Box>
                    <Box m={1} p={1}>
                      <TextField
                        value={sensor['nickName']}
                        variant="outlined"
                        label="Sensor NickName"
                        onChange={e => updateOtherSensorNickname(index, e.target.value)}
                        style={PlantConfigStyle.equipmentTextField}
                      />
                    </Box>
                    <DiagramSelectComponent
                      category="Other Sensors"
                      index={index}
                      updateValue={updateValue}
                      layoutDiagram={sensor['layoutDiagram']}
                    />
                    <>
                      {sensor['sensors'].length >= 1 ? (
                        <Box m={1}>
                          <FormLabel component="legend">
                            Sensor Tags for {sensor.name} {sensor.count}
                          </FormLabel>

                          {sensor['sensors'].map((sensor: any, sensorIndex: number) => {
                            return (
                              <SensorConfigForOtherSensors
                                index={index}
                                sensorIndex={sensorIndex}
                                sensor={sensor}
                                updateOtherSensorTag={updateOtherSensorTag}
                              />
                            );
                          })}
                        </Box>
                      ) : (
                        <></>
                      )}
                    </>
                    <div style={PlantConfigStyle.configFormWrapper}>
                      <Button
                        variant="contained"
                        style={PlantConfigStyle.deleteButton}
                        onClick={() => {
                          setShowDeleteModal(true);
                          setEquipmentIndex(index);
                          setEquipmentName(`${sensor.name} ${sensor.count}`);
                          setEquipment('Other Sensors');
                        }}
                      >
                        <DeleteIcon style={PlantConfigStyle.deleteIcon} />
                      </Button>
                    </div>
                  </Box>
                </AccordionSummary>
              </Accordion>
            </>
          );
        })}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={`You are deleting the ${equipmentName}`}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            RemoveElement(equipment, equipmentIndex);
            setProcessing(false);
            setShowDeleteModal(false);
          }}
        />
      </Box>
    </>
  );
}

export default OtherSensorsConfig;
