import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import { Filters } from '../../Interfaces/ChemicalLog';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useEffect, useState } from 'react';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import CloseIcon from '@mui/icons-material/Close';

interface IProps {
  assetId: string;
  chemicalName: string;
  chemicalId: string;
  chemicalLogFilters: Filters;
  setFiltersChanged: Function;
  setAppliedFilters: Function; /// this is to store a state of applied filters in parent component as well
  openFilters?: boolean;
  handleCloseFilters?: () => void;
}

function ChemicalLogFilters({
  assetId,
  chemicalName,
  chemicalId,
  chemicalLogFilters,
  // onApplyFilters,
  setFiltersChanged,
  setAppliedFilters,
  openFilters,
  handleCloseFilters,
}: IProps) {
  const [selectedRemarks, setSelectedRemarks] = useState<string[]>([]);
  const [selectedAmounts, setSelectedAmounts] = useState<string[]>([]);
  // const navigate = useNavigate();

  useEffect(() => {
    handleApplyFilters();
  }, [selectedRemarks, selectedAmounts]);

  const handleApplyFilters = () => {
    const selectedFilters = {
      remarks: selectedRemarks,
      consumptionType: selectedAmounts,
    };
    setAppliedFilters(selectedFilters);
    // onApplyFilters(selectedFilters);
    setFiltersChanged((prevState: any) => !prevState);
  };

  const handleRemarkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const remarkId = event.target.id;
    const isSelected = selectedRemarks.includes(remarkId);

    if (isSelected) {
      setSelectedRemarks(selectedRemarks.filter(selectedRemark => selectedRemark !== remarkId));
    } else {
      setSelectedRemarks([...selectedRemarks, remarkId]);
    }
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const amount = event.target.name;
    const isSelected = selectedAmounts.includes(amount);

    if (isSelected) {
      setSelectedAmounts(selectedAmounts.filter(selectedAmount => selectedAmount !== amount));
    } else {
      setSelectedAmounts([...selectedAmounts, amount]);
    }
  };
  const isMobile = useIsMobile({ breakpointValue: 'md' });
  const isMobile1 = useIsMobile({ breakpointValue: 'sm' });

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const handleRemarksAccordionClick = () => {
    setIsOpen1(!isOpen1);
  };

  const handleAmountAccordionClick = () => {
    setIsOpen2(!isOpen2);
  };

  return !isMobile1 ? (
    <Box
      style={{
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        paddingBottom: '4px',
        flexGrow: 1,
        maxHeight: '652px',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography
          variant="h5"
          sx={{
            padding: !isMobile ? '1.2rem 2rem' : '1rem',
            color: '#000000DD',
          }}
        >
          Filters
        </Typography>
      </Box>

      <Accordion
        sx={{
          paddingLeft: isMobile ? '.5rem' : '',
          padding: !isMobile ? '0.2rem 1.5rem' : '',
          borderRadius: 'none !important',
          boxShadow: 'none',
          marginTop: '1px !important',
          borderTop: isOpen1 ? '1px solid #0000001F' : null,
        }}
        onClick={handleRemarksAccordionClick}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography>Remarks:</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            overflowY: 'auto',
            maxHeight: '320px',
            '&::-webkit-scrollbar': {
              width: '0', // You can adjust this value to control the width of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent', // Hide the scrollbar thumb
            },
          }}
        >
          <FormGroup>
            {chemicalLogFilters.remarks.map(remarkData => (
              <FormControlLabel
                key={remarkData.remarkID}
                control={
                  <Checkbox
                    onChange={handleRemarkChange}
                    id={remarkData.remarkID}
                    name={remarkData.remark}
                    checked={selectedRemarks.includes(remarkData.remarkID)}
                  />
                }
                label={remarkData.remark}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          paddingLeft: isMobile ? '.5rem' : '',
          padding: !isMobile ? '0.2rem 1.5rem' : '',
          borderRadius: 'none !important',
          boxShadow: 'none',
          marginTop: '0px !important',
          borderTop: isOpen2 ? '1px solid #0000001F' : null,
        }}
        onClick={handleAmountAccordionClick}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography>Amount:</Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            overflowY: 'auto',
            maxHeight: '100px',
            '&::-webkit-scrollbar': {
              width: '0', // You can adjust this value to control the width of the scrollbar
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'transparent', // Hide the scrollbar thumb
            },
          }}
        >
          <FormGroup>
            {chemicalLogFilters.consumptionType.map((type, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    onChange={handleAmountChange}
                    name={type}
                    checked={selectedAmounts.includes(type)}
                  />
                }
                label={type}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  ) : (
    <Box
      style={{
        border: '1px solid #E0E0E0',
        borderRadius: '8px',
        paddingBottom: '4px',
        height: '652px',
        ...(isMobile1
          ? {
              position: 'absolute',
              zIndex: 2,
              backgroundColor: 'white',
              width: '85%',
              top: '2.5rem',
            }
          : {}),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '.8rem 0rem .8rem 2rem',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: '#000000DD',
          }}
        >
          Filters
        </Typography>

        <Button variant="text" onClick={handleCloseFilters}>
          <CloseIcon fontSize="small" sx={{ color: '#000000' }} />
        </Button>
      </Box>

      <Accordion
        sx={{
          padding: '0.2rem 1.5rem 0.2rem 1.5rem',
          borderRadius: 'none !important',
          boxShadow: 'none',
          marginTop: '1px !important',
          borderTop: isOpen1 ? '1px solid #0000001F' : null,
        }}
        onClick={handleRemarksAccordionClick}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />} sx={{ opacity: '1 !important' }}>
          <Typography>Remarks:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {chemicalLogFilters.remarks.map(remarkData => (
              <FormControlLabel
                key={remarkData.remarkID}
                control={
                  <Checkbox
                    onChange={handleRemarkChange}
                    id={remarkData.remarkID}
                    name={remarkData.remark}
                    checked={selectedRemarks.includes(remarkData.remarkID)}
                  />
                }
                label={remarkData.remark}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      <Accordion
        sx={{
          padding: '0.2rem 1.5rem 0.2rem 1.5rem',
          borderRadius: 'none !important',
          boxShadow: 'none',
          marginTop: '0px !important',
          borderTop: isOpen2 ? '1px solid #0000001F' : null,
        }}
        onClick={handleAmountAccordionClick}
      >
        <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
          <Typography>Amount:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormGroup>
            {chemicalLogFilters.consumptionType.map((type, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    onChange={handleAmountChange}
                    name={type}
                    checked={selectedAmounts.includes(type)}
                  />
                }
                label={type}
              />
            ))}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default ChemicalLogFilters;
