export default function DeleteIcon() {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_690_919" fill="white">
        <path d="M9.66536 0.666667H7.33203L6.66536 0H3.33203L2.66536 0.666667H0.332031V2H9.66536M0.998698 10.6667C0.998698 11.0203 1.13917 11.3594 1.38922 11.6095C1.63927 11.8595 1.97841 12 2.33203 12H7.66536C8.01899 12 8.35812 11.8595 8.60817 11.6095C8.85822 11.3594 8.9987 11.0203 8.9987 10.6667V2.66667H0.998698V10.6667Z" />
      </mask>
      <path
        d="M9.66536 0.666667H7.33203L6.66536 0H3.33203L2.66536 0.666667H0.332031V2H9.66536M0.998698 10.6667C0.998698 11.0203 1.13917 11.3594 1.38922 11.6095C1.63927 11.8595 1.97841 12 2.33203 12H7.66536C8.01899 12 8.35812 11.8595 8.60817 11.6095C8.85822 11.3594 8.9987 11.0203 8.9987 10.6667V2.66667H0.998698V10.6667Z"
        fill="#DAE4F1"
      />
      <path
        d="M7.33203 0.666667L6.62492 1.37377L6.91782 1.66667H7.33203V0.666667ZM6.66536 0L7.37247 -0.707107L7.07958 -1H6.66536V0ZM3.33203 0V-1H2.91782L2.62492 -0.707107L3.33203 0ZM2.66536 0.666667V1.66667H3.07958L3.37247 1.37377L2.66536 0.666667ZM0.332031 0.666667V-0.333333H-0.667969V0.666667H0.332031ZM0.332031 2H-0.667969V3H0.332031V2ZM0.998698 10.6667H-0.00130212H0.998698ZM8.9987 2.66667H9.9987V1.66667H8.9987V2.66667ZM0.998698 2.66667V1.66667H-0.00130212V2.66667H0.998698ZM9.66536 -0.333333H7.33203V1.66667H9.66536V-0.333333ZM8.03914 -0.0404401L7.37247 -0.707107L5.95826 0.707107L6.62492 1.37377L8.03914 -0.0404401ZM6.66536 -1H3.33203V1H6.66536V-1ZM2.62492 -0.707107L1.95826 -0.0404401L3.37247 1.37377L4.03914 0.707107L2.62492 -0.707107ZM2.66536 -0.333333H0.332031V1.66667H2.66536V-0.333333ZM-0.667969 0.666667V2H1.33203V0.666667H-0.667969ZM0.332031 3H9.66536V1H0.332031V3ZM-0.00130212 10.6667C-0.00130212 11.2855 0.24453 11.879 0.682115 12.3166L2.09633 10.9024C2.03382 10.8399 1.9987 10.7551 1.9987 10.6667H-0.00130212ZM0.682115 12.3166C1.1197 12.7542 1.71319 13 2.33203 13V11C2.24363 11 2.15884 10.9649 2.09633 10.9024L0.682115 12.3166ZM2.33203 13H7.66536V11H2.33203V13ZM7.66536 13C8.2842 13 8.8777 12.7542 9.31528 12.3166L7.90107 10.9024C7.83855 10.9649 7.75377 11 7.66536 11V13ZM9.31528 12.3166C9.75286 11.879 9.9987 11.2855 9.9987 10.6667H7.9987C7.9987 10.7551 7.96358 10.8399 7.90107 10.9024L9.31528 12.3166ZM9.9987 10.6667V2.66667H7.9987V10.6667H9.9987ZM8.9987 1.66667H0.998698V3.66667H8.9987V1.66667ZM-0.00130212 2.66667V10.6667H1.9987V2.66667H-0.00130212Z"
        fill="#193458"
        mask="url(#path-1-inside-1_690_919)"
      />
    </svg>
  );
}
