import { useState } from "react";
import {
  FormControl,
  TextField,
  CircularProgress,
  ThemeProvider,
  Button,
  Box,
  Stack,
} from "@mui/material";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
// import { useNavigate } from "react-router";
import dpTheme from "../../styles/theme/dpTheme";
import { resetPassword } from "../../services/Login";

const styles = {
  container: {
    minWidth: "300px",
  },
  formHeading: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#183650",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "5px",
    fontSize: "14px",
    color: "#999999",
  },
  gotoLink: {
    fontSize: "14px",
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
  },
};

interface EmailComponentProps {
  changeStateToCheckEmail: () => void;
}

export default function PasswordResetEmailComponent({
  changeStateToCheckEmail,
}: EmailComponentProps) {
  // let navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = () => {
    setLoading(true);
    resetPassword(email).then((data: any) => {
      console.log(data);
      setLoading(false);

      if (data.data.success) {
        changeStateToCheckEmail();
      } else {
        toast.error(data.data.message);
      }
    });
  };

  const validateAndSubmitInput = (e: any) => {
    // e.preventDefault();
    let isValidFormat = true;
    if (email.length === 0) {
      setEmailError("Field cannot be left blank");
      isValidFormat = false;
    } else if (validator.isEmail(email)) {
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email");
      isValidFormat = false;
    }
    if (isValidFormat) {
      handleSubmit();
    }
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <Box sx={styles.container}>
        <FormControl fullWidth={true}>
          <Stack direction="column" alignItems="flex-start" gap={3}>
            <Box sx={styles.formHeading}>Password Reset</Box>
            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              variant="outlined"
              InputProps={{ style: { fontFamily: "Montserrat" } }}
              error={emailError.length > 0 ? true : false}
              //Remove helperText if error not wanted as subtext and call toast("Error") instead in validateInput
              helperText={emailError.length > 0 ? emailError : null}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <Box sx={styles.infoContainer}>
              <ArrowCircleRightOutlinedIcon />
              <span>You will receive instructions over email</span>
            </Box>

            <Button
              fullWidth
              variant="contained"
              onClick={validateAndSubmitInput}
            >
              Reset
            </Button>
            <Box sx={styles.gotoLink}>
              <Link to="/Login">Log In</Link>
            </Box>
          </Stack>
        </FormControl>
        <ToastContainer />
        {loading ? (
          <Box sx={styles.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
    </ThemeProvider>
  );
}
