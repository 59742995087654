import axiosInstance from '../constants/axiosInstance';
import { formulaDataForPlant } from '../Interfaces/formulaConfigInterfaces';
import { formulaDataForWorkspace } from '../Interfaces/workspaceFormulaConfigInterface';

const checkAndSetFormulas = async (plantId: string, formulaDataForPlant: formulaDataForPlant) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/checkAndSetFormulas?id=${plantId}`,
      formulaDataForPlant
    );
    console.log(data);
    return data;
  } catch (err) {
    console.error('checkAndSetFormulas api Failed');
  }
};

const getCustomFormulasList = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getCustomFormulasList?id=${plantId}`
    );
    console.log(data);
    return data.data;
  } catch (err) {
    console.error('checkAndSetFormulas api Failed');
  }
};
const getCustomFormulaListForWorkspace = async (workspaceId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getCustomFormulasListForWorkspace?id=${workspaceId}`
    );
    console.log(data);
    return data.data;
  } catch (err) {
    console.error('checkAndSetFormulas api Failed');
  }
};
const checkAndSetWorkspaceFormulas = async (
  workspaceId: string,
  formulaDataForWorkspace: formulaDataForWorkspace
) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/checkAndSetWorkspaceFormulas?id=${workspaceId}`,
      formulaDataForWorkspace
    );
    console.log(data);
    return data;
  } catch (err) {
    console.error('checkAndSetFormulas api Failed');
  }
};

export {
  checkAndSetFormulas,
  getCustomFormulasList,
  getCustomFormulaListForWorkspace,
  checkAndSetWorkspaceFormulas,
};
