import { create } from "zustand";

type PlantLayoutStore = {
  plantId: string | null;
  nodes: any[];
  edges: any[];
  selectedNodes: any[];
  selectedSensors: null;
  nodesWithChokePoints: null;
  biDirectionalSensorsForPlant: any[];
  biSensorId: any;
  loadingStatus: any;
  biSensorStatus: any;
  showLockedPopup: any;
  showControlPrompt: any;
  equipmentNickName: any;
  biDirectionalInput: boolean | null;
  setNodes: (nodes: any[]) => void;
  setEdges: (edges: any[]) => void;
  setPlantId: (plantId: string) => void;
  setSelectedNodes: (selectedNodes: any) => void;
  setSelectedSensors: (selectedSensors: any) => void;
  setNodesWithChokePoints: (nodesWithChokePoints: any) => void;
  setBidirectionalSensorsForPlant: (biDirectionalSensorsForPlant: any) => void;
  setBiSensorId: (biSensorId: any) => void;
  setLoadingStatus: (loadingStatus: any) => void;
  setBiSensorStatus: (biSensorStatus: any) => void;
  setShowLockedPopup: (showLockedPopup: any) => void;
  setShowControlPrompt: (showControlPrompt: any) => void;
  setEquipmentNickName: (equipmentNickName: any) => void;
  setBiDirectionalInput: (biDirectionalInput: any) => void;
  resetStore: () => void;
};

const initialState = {
  plantId: null,
  layoutId: null,
  layoutName: null,
  nodes: [],
  edges: [],
  selectedNodes: [],
  selectedSensors: null,
  nodesWithChokePoints: null,
  biDirectionalSensorsForPlant: [],
  biSensorId: null,
  biSensorStatus: null,
  showLockedPopup: false,
  showControlPrompt: false,
  loadingStatus: false,
  equipmentNickName: null,
  biDirectionalInput: null,
};

export const usePlantLayoutStore = create<PlantLayoutStore>((set) => ({
  ...initialState,
  plantId: null,
  nodes: [],
  edges: [],
  selectedNodes: [],
  selectedSensors: null,
  nodesWithChokePoints: null,
  biDirectionalSensorsForPlant: [],
  biSensorId: null,
  biSensorStatus: null,
  showLockedPopup: false,
  showControlPrompt: false,
  loadingStatus: false,
  equipmentNickName: null,
  biDirectionalInput: null,
  setPlantId: (plantId) => set({ plantId }),
  setNodes: (nodes) => set({ nodes }),
  setEdges: (edges) => set({ edges }),
  setSelectedNodes: (selectedNodes) => set({ selectedNodes }),
  setSelectedSensors: (selectedSensors) => set({ selectedSensors }),
  setNodesWithChokePoints: (nodesWithChokePoints) =>
    set({ nodesWithChokePoints }),
  setBidirectionalSensorsForPlant: (biDirectionalSensorsForPlant) =>
    set({ biDirectionalSensorsForPlant }),
  setBiSensorId: (biSensorId) => set({ biSensorId }),
  setLoadingStatus: (loadingStatus) => set({ loadingStatus }),
  setBiSensorStatus: (biSensorStatus) => set({ biSensorStatus }),
  setShowLockedPopup: (showLockedPopup) => set({ showLockedPopup }),
  setShowControlPrompt: (showControlPrompt) => set({ showControlPrompt }),
  setEquipmentNickName: (equipmentNickName) => set({ equipmentNickName }),
  setBiDirectionalInput: (biDirectionalInput) => set({ biDirectionalInput }),
  resetStore: () => set({ ...initialState }), // Reset function
}));
