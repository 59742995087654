import { useState, useRef, useEffect } from 'react';
import { Box, Stack, TextField, IconButton } from '@mui/material';
import useOutsideClickDetector from '../../utilities/customHooks/useOutsideClickDetector';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const styles = {
  infoIcon: {
    fontSize: 18,
    color: '#999',
  },
  sensorListContainer: {
    height: '200px',
    overflowY: 'scroll',
    boxSizing: 'border-box',
    background: 'white',
  },
  sensorItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  searchTextField: {
    background: 'white',
    width: '100% !important',
  },
};

interface propsInterface {
  sensorList: any[];
  configuredSensors: any[];
  setConfiguredSensors: Function;
  alwaysExpanded: boolean;
}

function MultiSensorSelectorAndSearch(props: propsInterface) {
  // Sample list of sensors
  const { sensorList, configuredSensors, setConfiguredSensors, alwaysExpanded } = props;
  //   let keywordFilteredSensorList: any[] = sensorList;
  const [searchQuery, setSearchQuery] = useState('');
  const [showSensorlist, setShowSensorList] = useState<Boolean>(false);
  const metricSearchBarResultsDivRef = useRef(null);
  useOutsideClickDetector(metricSearchBarResultsDivRef, () => {
    if (!alwaysExpanded) {
      setShowSensorList(false);
    }
  });
  useEffect(() => {
    if (alwaysExpanded) {
      setShowSensorList(true);
    }
  }, [alwaysExpanded]);

  // Function to handle sensor selection and adding to the array
  const handleAddsensor = (sensor: any) => {
    let oldConfiguredSensors = [...configuredSensors];
    let sensorDataTuPush = {
      sensorTag: sensor.sensorTag,
      sensorNickName: sensor.sensorNickName ? sensor.sensorNickName : '',
      sensorId: sensor._id,
      display: sensor.display ?? false,
    };
    oldConfiguredSensors.push(sensorDataTuPush);
    setConfiguredSensors(oldConfiguredSensors);
  };

  // Function to handle sensor removal
  // const handleRemovesensor = (sensor: any) => {
  //   const updatedsensors = selectedsensors.filter((item) => item !== sensor);
  //   setSelectedsensors(updatedsensors);
  // };

  // Function to handle search query changes
  const handleSearchChange = (e: any) => {
    setSearchQuery(e.target.value);
  };

  // Filter the sensors based on the search query
  const filteredsensors = sensorList.filter(
    (sensor: any) =>
      sensor?.sensorTag?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      sensor?.sensorNickName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const checkIfSensorListHasSensorAdded = (sensor: any) => {
    let sensorExists = false;
    configuredSensors.forEach((configuredSensor: any) => {
      if (configuredSensor.sensorTag === sensor.sensorTag) {
        sensorExists = true;
      }
    });
    return sensorExists;
  };
  return (
    <Box
      borderRadius={1}
      sx={{
        overflow: 'hidden',
      }}
      ref={metricSearchBarResultsDivRef}
    >
      <TextField
        type="text"
        autoComplete="off"
        value={searchQuery}
        variant="outlined"
        fullWidth
        placeholder="Search and add sensors"
        // label="filter"
        inputProps={{
          maxLength: 50,
          step: '0.01',
        }}
        sx={styles.searchTextField}
        onChange={handleSearchChange}
        onFocus={() => {
          setShowSensorList(true);
        }}
        onBlur={() => {}}
      />
      {sensorList?.length > 0 && showSensorlist && filteredsensors.length > 0 && (
        <Stack sx={styles.sensorListContainer}>
          {filteredsensors.map((sensor: any, index: number) => (
            <Box key={index} p={1} sx={styles.sensorItem}>
              <Stack direction="row" alignItems="center" gap={1}>
                <span>
                  {sensor.sensorNickName && sensor.sensorNickName != ''
                    ? sensor.sensorNickName
                    : sensor.sensorTag}
                </span>
                {sensor.sensorNickName && (
                  <Tooltip title={sensor.sensorTag}>
                    <InfoOutlinedIcon sx={styles.infoIcon} />
                  </Tooltip>
                )}
              </Stack>
              {checkIfSensorListHasSensorAdded(sensor) ? (
                <IconButton onClick={() => {}}>
                  <CheckIcon fontSize="small" />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleAddsensor(sensor)}>
                  <AddIcon fontSize="small" />
                </IconButton>
              )}
            </Box>
          ))}
        </Stack>
      )}
      {sensorList?.length === 0 && <p>No sensorExists</p>}
    </Box>
  );
}

export default MultiSensorSelectorAndSearch;
