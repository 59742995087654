import axiosInstance from "../constants/axiosInstance";

const getRemarks = async (category: string, action: string) => {
  try {
    console.log(category, action, "1234");
    const { data } = await axiosInstance().get(
      `/InventoryManagement/InventoryRemarks?categoryId=${category}&action=${action}`
    );
    console.log("remarksdata", data.data);
    return data.data;
  } catch (err) {
    console.error("ERROR | getRemarks | ", err);
  }
};

export { getRemarks };
