//@ts-nocheck
import { useEffect, useState } from "react";
import axiosInstance from "../constants/axiosInstance";
import { Button, Box, ThemeProvider, Typography } from "@mui/material";
import LayoutComponent from "../components/LayoutComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import AddWorkForcePopup from "../components/AddWorkForcePopup/AddWorkForcePopup";
import AddOperatorGroup from "../components/AddOperatorGroup/AddOperatorGroup";
import { ToastContainer, toast } from "react-toastify";
import { getWorkForce, deleteUser } from "../services/users";
import dpTheme from "../styles/theme/dpTheme";
import { PrimaryButton } from "../styles/global/components/dpButtons";
import {
  WorkForceInterface,
  OGworkForceInterface,
} from "../Interfaces/workForceInterface";
import { Classes } from "../styles/heading+addButton";

export default function WorkForce() {
  const [clientWorkForce, setClientWorkForce] = useState<WorkForceInterface[]>(
    []
  );
  const [spWorkForce, setSpWorkForce] = useState<WorkForceInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [addUser, setAddUser] = useState<boolean>(false);
  const [forWho, setForWho] = useState<string>("");
  const [edit, setEdit] = useState<boolean>(false);
  const [userid, setUserId] = useState<string>("");
  const [addOperatorGroup, setAddOperatorGroup] = useState<boolean>(false);
  const [ogWorkForce, setOgWorkForce] = useState<OGworkForceInterface[]>([]);

  useEffect(() => {
    refresh();
  }, []);

  const DeleteUser = (id: string, count: number) => {
    console.log(count);
    if (count > 0) {
      toast("Cannot Delete user with Plants Assigned");
    } else {
      deleteUser(id).then((response) => {
        refresh();
      });
    }
  };

  const refresh = async () => {
    setEdit(false);
    setAddUser(false);
    setAddOperatorGroup(false);
    let userId = localStorage.getItem("userId");
    await fetchOG(userId);
    await fetchClientWorkForce();
    await fetchspWorkForce();
    setLoading(false);
  };

  const fetchClientWorkForce = async () => {
    console.log("Fetching Clien WF");
    let userId = localStorage.getItem("userId");
    if (userId == null) {
      getWorkForce("Client").then((workforce) => {
        console.log("data.workforce", workforce);
        if (workforce != null && workforce != null)
          setClientWorkForce(workforce);
      });
    } else {
      getWorkForce("Client", userId).then((workforce) => {
        console.log("data.workforce", workforce);
        if (workforce != null && workforce != null)
          setClientWorkForce(workforce);
      });
    }

    console.log();
  };

  const fetchspWorkForce = async () => {
    getWorkForce("Service Provider").then((workforce) => {
      console.log("data.workforce for sp", workforce);
      if (workforce != null && workforce != null) setSpWorkForce(workforce);
    });
  };

  const fetchOG = async (userId) => {
    await axiosInstance()
      .get(
        "/users/getUsersByRoleForUserId?role=Operator Group&userId=" + userId
      )
      .then(({ data }) => {
        console.log("fetchOG: ", data);
        setOgWorkForce(data.users);
      });
  };

  const columnsClient = [
    {
      field: "name",
      headerName: "Name",
      width: 350,
    },
    {
      field: "email",
      headerName: "Email",
      width: 350,
    },
    {
      field: "role",
      headerName: "Role",
      width: 350,
    },
    {
      field: "forName",
      headerName: "Client",
      width: 350,
    },
    {
      field: "count",
      headerName: "Plants",
      width: 200,
    },
    {
      field: "userStatus",
      headerName: "User Status",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Button
              onClick={() => {
                setUserId(params.row.id);
                setEdit(true);
                setForWho("Client");
                setAddUser(true);
              }}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => {
                DeleteUser(params.row.id, params.row.count);
              }}
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
      width: 300,
    },
  ];

  const columnsSP = [
    {
      field: "name",
      headerName: "Name",
      width: 350,
    },
    {
      field: "email",
      headerName: "Email",
      width: 350,
    },
    {
      field: "role",
      headerName: "Role",
      width: 350,
    },
    {
      field: "forName",
      headerName: "Service Provider",
      width: 350,
    },
    {
      field: "count",
      headerName: "Plants",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Button
              onClick={() => {
                setUserId(params.row.id);
                setEdit(true);
                setForWho("Service Provider");
                setAddUser(true);
              }}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => {
                DeleteUser(params.row.id, params.row.count);
              }}
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
      width: 300,
    },
  ];

  const columnsOG = [
    {
      field: "name",
      headerName: "Name",
      width: 350,
    },
    {
      field: "email",
      headerName: "Email",
      width: 350,
    },
    {
      field: "operators",
      headerName: "Operators",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Button
              onClick={() => {
                setUserId(params.row.id);
                setEdit(true);
                setAddOperatorGroup(true);
              }}
            >
              <EditIcon />
            </Button>{" "}
            {/* this line added by me */}
            <Button
              onClick={() => {
                DeleteUser(params.row.id, params.row.count);
              }}
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
      width: 300,
    },
  ];
  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent>
        <Box m={1} p={1}>
          <div style={Classes.body}>
            <Typography variant="h1">Client WorkForce</Typography>
            <span style={{ float: "right" }}>
              <PrimaryButton
                onClick={() => {
                  setForWho("Client");
                  setEdit(false);
                  setAddUser(true);
                }}
              >
                <AddIcon />
                Add
              </PrimaryButton>
            </span>
          </div>
        </Box>
        <Box m={1} p={1}>
          <div style={{ height: 420 }}>
            <DataGrid
              rows={clientWorkForce}
              columns={columnsClient}
              loading={loading}
            />
          </div>
        </Box>

        <Box m={1} p={1}>
          <div style={Classes.body}>
            <Typography variant="h1">Service Provider WorkForce</Typography>
            <span style={{ float: "right" }}>
              <PrimaryButton
                onClick={() => {
                  setForWho("Service Provider");
                  setEdit(false);
                  setAddUser(true);
                }}
              >
                <AddIcon />
                Add
              </PrimaryButton>
            </span>
          </div>
        </Box>
        <Box m={1} p={1}>
          <div style={{ height: 420 }}>
            <DataGrid
              rows={spWorkForce}
              columns={columnsSP}
              loading={loading}
            />
          </div>
        </Box>

        <Box m={1} p={1}>
          <div style={Classes.body}>
            <Typography variant="h1">Operator Groups</Typography>
            <span style={{ float: "right" }}>
              <PrimaryButton
                onClick={() => {
                  setEdit(false);
                  // setAddUser(true);
                  setAddOperatorGroup(true);
                }}
              >
                <AddIcon />
                Add
              </PrimaryButton>
            </span>
          </div>
        </Box>

        <Box m={1} p={1}>
          <div style={{ height: 420 }}>
            <DataGrid
              rows={ogWorkForce}
              columns={columnsOG}
              loading={loading}
            />
          </div>
        </Box>

        {addUser ? (
          <AddWorkForcePopup
            handleClose={() => {
              setAddUser(false);
            }}
            edit={edit}
            forWho={forWho}
            refresh={refresh}
            userId={userid}
          />
        ) : null}
        {addOperatorGroup ? (
          <AddOperatorGroup
            handleClose={() => {
              setAddOperatorGroup(false);
            }}
            edit={edit}
            forWho={forWho}
            refresh={refresh}
            userId={userid}
          />
        ) : null}
        <ToastContainer />
      </LayoutComponent>
    </ThemeProvider>
  );
}
