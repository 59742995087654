import { FC } from "react";
import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { saveWorkspace } from "../../services/workspace";

// interface Plant {
//   plantId: string;
//   plantName: string;
//   // Add other fields as necessary
// }

interface WorkspaceOutputConfigProps {
  open: boolean;
  handleClose: () => void;
  plantList: any;
  workspaceConfig: any;
  handlePlantValueChange: Function;
}

const WorkspaceOutputConfig: FC<WorkspaceOutputConfigProps> = ({
  open,
  handleClose,
  plantList,
  workspaceConfig,
  handlePlantValueChange,
}) => {
  const navigate = useNavigate();
  const outputs = plantList.map((plant: any) => {
    return plant._id;
  });

  const handleSaveWorkspace = () => {
    saveWorkspace(workspaceConfig).then((data) => {
      console.log(data);
      toast("saved successfully");
      navigate(`/layoutVisualisationConfig/${data}`);
    });
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Workspace Output Configuration</DialogTitle>
      <DialogContent>
        {workspaceConfig.plants.map((plant: any, index: number) => (
          <Box key={index} sx={{ mb: 2 }}>
            <Typography variant="h6">{plant.plantName}</Typography>
            <Autocomplete
              multiple
              options={
                outputs.filter((output: any) => output !== plant.plantId) || []
              }
              value={plant.outputs}
              onChange={(event: any, newValue: any | null) => {
                handlePlantValueChange(index, "outputs", newValue);
              }}
              getOptionLabel={(option: any) => {
                let plant = plantList.find((plant: any) => {
                  return plant._id === option;
                });
                return plant?.plantName;
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Output" />
              )}
              // Handle selection change if needed
            />
          </Box>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={() => {
            handleSaveWorkspace();
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WorkspaceOutputConfig;
