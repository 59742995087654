import axiosInstance from "../constants/axiosInstance";

const addHmi = async (hmiName: string, plcIds: string[], plantId: string) => {
  try {
    const { data } = await axiosInstance().post(`/plantManagement/AddHmi`, {
      hmiName,
      plcIds,
      plantId,
    });
    console.log(data.message, "data");
    return data.message;
  } catch (err) {
    console.error(`ERROR | AddHmi`, err);
  }
};

const getHmiList = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/GetHmiList?plantId=${plantId}`
    );
    console.log("data.data.himArr", data.data.himArr);
    return data.data.hmiArr;
  } catch (err) {
    console.error(`ERROR | getHmi`, err);
  }
};

const deleteHmi = async (hmiId: string) => {
  try {
    const { data } = await axiosInstance().delete(
      `/plantManagement/DeleteHmi?hmiId=${hmiId}`
    );
    console.log(data);
    return data.message;
  } catch (err) {
    console.error(`ERROR | deleteHmi`, err);
  }
};
const getPlcList = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `plantManagement/getPlcList?id=${plantId}`
    );
    console.log("data.data.plcArr", data.data.plcArr);
    return data.data.plcArr;
  } catch (err) {
    console.error(`ERROR || GETPLCLIST`, err);
  }
};

export { addHmi, getHmiList, deleteHmi, getPlcList };
