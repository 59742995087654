//@ts-nocheck
import { Button, Typography, Box, Stack, IconButton } from '@mui/material';
import classes from '../../styles/LayoutVisualisationStyle.module.css';
import EquipmentSensors from './EquipmentSensors';
import SettingsIcon from '@mui/icons-material/Settings';
import ControlPrompt from '../../../BiDirectionalPopups/ControlPrompt';
import { useEffect, useRef, useState } from 'react';
import { checkBiDirectionalLoadingStatus } from '../../../../services/BiDirectional';
import { useParams } from 'react-router-dom';
import LockedPopup from '../../../BiDirectionalPopups/LockedPopup';
import { usePlantLayoutStore } from '../../../../store/PlantLayoutStore';
import { borderBottomRightRadius } from 'html2canvas/dist/types/css/property-descriptors/border-radius';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import componentIcon from '../../../../assets/icons/ComponentIcon.svg';
import useClientSideLayoutStore from '../../../../store/ClientSideLayoutStore';
// import { IconButton } from '../../../../styles/global/components/dpButtons';
// import Submersible from '../../../../assets/PlantLayoutDiagram/Submersible.svg';

const EquipmentNodeDiagram = ({
  id: equipmentId,
  biSensorId,
  biSensorStatus,
  LTSensor,
  LTSensorValue,
  OnOffSensorStatus,
  parent,
  equipmentName,
  equipmentImage,
  imageControlId,
  data,
  showAllSensors,
  setShowAllSensors,
  showLimitedSensors,
  showSettings,
  nodeData,
}: any) => {
  const isTank = equipmentId.toLowerCase().includes('tank') ? true : false;
  const EquipmentImageId = parent === 'Dialog' ? `svgDialog${equipmentId}` : `svg${equipmentId}`;
  const EquipmentSvgRef = useRef(null);
  const equipmentNickName = data.nickName || equipmentName;

  const { loadingCurrentLayoutDiagram, setLoadingCurrentLayoutDiagram } =
    useClientSideLayoutStore();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const [showDiagram, setShowDiagram] = useState(false);
  const {
    plantId,
    setBiSensorId,
    setBiSensorStatus,
    setShowLockedPopup,
    setShowControlPrompt,
    setEquipmentNickName,
  } = usePlantLayoutStore();

  const fetchBiDirectionalLoadingStatus = async () => {
    setBiSensorId(biSensorId);
    setBiSensorStatus(biSensorStatus);
    setEquipmentNickName(equipmentNickName);
    const tempLoadingStatus = await checkBiDirectionalLoadingStatus(plantId!);
    setLoadingStatus(tempLoadingStatus);
    if (tempLoadingStatus === true) {
      setShowLockedPopup(true);
    } else {
      setShowControlPrompt(true);
    }
  };

  useEffect(() => {
    try {
      // Submersible image control id: e76krfDAeol1
      // const imageControlId = "e76krfDAeol1";
      if (equipmentImage?.length > 1 && equipmentImage != 'STP') setShowDiagram(true);

      setTimeout(() => {
        const object = EquipmentSvgRef.current;
        const contentDocument = object ? object.contentDocument : null;
        const svg = contentDocument ? contentDocument.getElementById(imageControlId) : null;
        const script = svg ? contentDocument.querySelector('script') : null;

        if (object && contentDocument && svg && script) {
          if (isTank) {
            EquipmentSvgRef.current.contentDocument
              .getElementById(imageControlId)
              .svgatorPlayer.seek(LTSensorValue);
          } else {
            if (OnOffSensorStatus) {
              EquipmentSvgRef.current.contentDocument
                .getElementById(imageControlId)
                .svgatorPlayer.play();
            }
          }
        }
      }, 5000);
    } catch (err) {}
  }, [EquipmentSvgRef.current]);

  const handleNodeClick = async () => {
    console.log('inside ue 3 : ');
    setLoadingCurrentLayoutDiagram(true);
    if (data.componentId && data?.componentInside === true) {
      navigate(`/LayoutVisualisation/${data.componentId}`);
      setLoadingCurrentLayoutDiagram(false);
    } else {
      // toast.error('No layout diagram exists for this node');
      setLoadingCurrentLayoutDiagram(false);
    }
  };

  return (
    <Stack
      sx={{
        borderRadius: '8px',
        paddingTop: showSettings ? '2px' : showAllSensors ? '20px' : '10px',
        paddingBottom: showAllSensors ? '20px' : '10px',
        height: showAllSensors ? '100%' : showSettings ? '200px' : '190px',
        background: '#fff',
        boxSizing: 'border-box',
      }}
      justifyContent="space-between"
      flexDirection="column"
    >
      {showSettings && (
        <Box
          sx={{
            marginLeft: 'auto',
            // marginRight: '10px',
            // border: '1px solid blue',
            width: '17px',
            height: '17px',
            position: 'relative',
            top: showAllSensors ? 4 : 2,
            right: showAllSensors ? 7 : 5,
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <IconButton
            disabled={!data.sensors[0]?.hasOwnProperty('value')}
            onClick={event => {
              event.stopPropagation();
              fetchBiDirectionalLoadingStatus();
            }}
            sx={
              showAllSensors ? { width: '20px', height: '20px' } : { width: '17px', height: '17px' }
            }
          >
            <SettingsIcon
              sx={
                showAllSensors
                  ? {
                      width: '18px',
                      height: '18px',
                      color: '#000',
                      '& :hover': { cursor: 'pointer' },
                    }
                  : {
                      width: '15px',
                      height: '15px',
                      color: '#000',
                      '& :hover': { cursor: 'pointer' },
                    }
              }
            />
          </IconButton>
        </Box>
      )}
      <Stack
        direction="column"
        justifyContent="space-between"
        sx={{
          height: showSettings ? { xs: '95%', md: '90%' } : '100%',
          maxHeight: { xs: '100%', sm: '300px' },
          // border: '2px solid green',
          maxWidth: showAllSensors ? 'auto' : '470px',
          boxSizing: 'border-box',
        }}
        // gap={showAllSensors ? 0 : 2}
      >
        <Stack
          direction={showAllSensors ? { xs: 'column', sm: 'row' } : 'row'}
          justifyContent={
            showAllSensors ? { xs: 'flex-start', sm: 'space-between' } : 'space-between'
          }
          alignItems={showAllSensors ? { xs: 'center', sm: 'flex-start' } : 'flex-start'}
          gap={2}
          px={showAllSensors ? 3 : 2}
          sx={{
            height: showAllSensors ? { xs: '90%', sm: '60%' } : '100%',
            minHeight: showAllSensors ? '170px' : '100px',
            boxSizing: 'border-box',
            width: '100%',
            // border: '1px solid red',
          }}
          id="middlePart"
        >
          {showDiagram ? (
            <Box
              component="object"
              ref={EquipmentSvgRef}
              id={EquipmentImageId}
              type="image/svg+xml"
              data={equipmentImage}
              sx={{
                marginBottom: '10px',
                width: {
                  xs: showAllSensors ? '95%' : '200px',
                  sm: showAllSensors ? '250px' : '200px',
                },
                height: '90%',
                maxHeight: showAllSensors ? '150px' : '100px',
                '&:hover': { cursor: 'pointer' },
                pointerEvents: 'auto',
                // border: '5px solid black',
                // alignSelf: 'center',
              }}
            ></Box>
          ) : (
            <Box
              sx={{
                display: 'grid',
                placeItems: 'center',
                width: {
                  xs: showAllSensors ? '90%' : '180px',
                  sm: showAllSensors ? '200px' : '180px',
                },
                height: '90%',
                maxHeight: showAllSensors ? '150px' : '100px',
                border: '1px solid #999',
                borderRadius: '5px',
                marginBottom: '10px',
                alignSelf: 'center',
              }}
            >
              <Typography sx={{ fontSize: '14px', color: '#999' }}>No Visual Available</Typography>
            </Box>
          )}

          <Stack
            sx={{
              height: '90%',
              overflowY:
                data.sensors?.length > 5 ? (showAllSensors ? 'scroll' : 'no-scroll') : 'no-scroll',
              // border: '1px solid black',
              width: showAllSensors ? { xs: '100%', sm: '60%' } : '60%',
            }}
          >
            {showLimitedSensors &&
              data.sensors.slice(0, 3).map((sensor: any, index: number) => {
                return sensor.display ? (
                  <EquipmentSensors key={sensor.sensorId} sensor={sensor} LTSensor={LTSensor} />
                ) : null;
              })}
            {showAllSensors &&
              data.sensors.map((sensor: any, index: number) => {
                return sensor.display ? (
                  <EquipmentSensors key={sensor.sensorId} sensor={sensor} LTSensor={LTSensor} />
                ) : null;
              })}
          </Stack>
        </Stack>
        <Stack
          id="bottomSection"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          pr={2}
          sx={{
            width: '100%',
            boxSizing: 'border-box',
            // border: '1px solid blue'
          }}
        >
          <Stack direction="row" alignItems="center" gap={2} sx={{ marginTop: 'auto' }}>
            <Box
              sx={{
                background: '#CEE3F5',
                paddingLeft: '32px',
                paddingRight: '32px',
                paddingTop: '13px',
                paddingBottom: '13px',
                borderTopRightRadius: '90px',
                borderBottomRightRadius: '90px',
                color: '#1A3356',
                alignSelf: 'flex-start',
                minWidth: '100px',
                textAlign: 'center',
                wordBreak: 'break-all',
              }}
            >
              <Typography variant="h6">{equipmentNickName}</Typography>
            </Box>
            <Box
              sx={{
                border: '1px solid #1A335699',
                width: '29px',
                height: '29px',
                borderRadius: '83px',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <ToggleOnIcon
                sx={{ color: showSettings ? '#1A3356' : '#999', width: '20px', height: '20px' }}
              />
            </Box>
            {nodeData.componentInside && (
              <Box
                sx={{
                  border: '1px solid #1A335699',
                  width: '29px',
                  height: '29px',
                  borderRadius: '83px',
                  display: 'grid',
                  placeItems: 'center',
                }}
              >
                <img
                  src={componentIcon}
                  sx={{ color: showSettings ? '#1A3356' : '#999', width: '20px', height: '20px' }}
                />
              </Box>
            )}
          </Stack>
          {showLimitedSensors && (
            <>
              {data.sensors.length ? (
                <Button
                  sx={{
                    fontSize: '12px',
                    background: '#fff',
                    color: '#183650',
                  }}
                  // variant="contained"
                  onClick={event => {
                    event.stopPropagation();
                    setShowAllSensors(true);
                  }}
                >
                  <Stack direction="row" alignItems="center" gap={2}>
                    {data.sensors.length > 3 ? 'More Details' : 'Expand'}
                    <ArrowOutwardIcon sx={{ fontSize: '16px', color: '#183650' }} />
                  </Stack>
                </Button>
              ) : null}
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default EquipmentNodeDiagram;
