import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PLANT_CONFIG_DATA } from '../../constants/constants';
import { Headers } from '../../Interfaces/PlantConfigurationInterface';
import { PlantConfigStyle } from './PlantConfigStyle';
import { Autocomplete } from '@mui/material';
import { styledBoxWithShadow } from '../../styles/global/components/dpBox';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { useState } from 'react';
import DiagramSelectComponent from './subComponents/DiagramSelectComponent';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddEquipmentInsideEquipmentTypePopup from '../PlantConfigPopups/EquipmentInsideTypeSelector';
import SensorTagArray from './subComponents/SensorTagArray';
import SubEquipmentViewAndControl from './subEquipment/SubEquipmentViewAndControl';
import SettingsIcon from '@mui/icons-material/Settings';
import EqtInsideEqt from '../../assets/icons/EqtInsideEqt.svg';
interface IProps {
  headerData: Headers[];
  addHeaders: Function;
  RemoveElement: Function;
  updateValue: Function;
  updateSensorTagValue: Function;
  handleNickNameChange: Function;
  handleMultiSelect: Function;
  updateSensorList: Function;
  plantConfig: any;
  setPlantConfig: Function;
  TankOptions: Function;
  ValveOptions: Function;
  CoreEquipmentOptions: Function;
  HeaderOptions: Function;
  tankChoice: any;
  valveChoice: any;
  equipChoice: any;
  headerChoice: any;
  handleShowOnDiagramCheck: Function;
  sensorPostFix: string;
}

function HeaderConfig({
  headerData,
  addHeaders,
  RemoveElement,
  updateSensorTagValue,
  updateSensorList,
  handleMultiSelect,
  tankChoice,
  updateValue,
  handleNickNameChange,
  plantConfig,
  setPlantConfig,
  TankOptions,
  ValveOptions,
  CoreEquipmentOptions,
  HeaderOptions,
  valveChoice,
  equipChoice,
  headerChoice,
  handleShowOnDiagramCheck,
  sensorPostFix,
}: IProps) {
  const [equipmentIndex, setEquipmentIndex] = useState<Number | null>(null);
  const [equipmentName, setEquipmentName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [equipment, setEquipment] = useState('');
  const [showAddSubEquipment, setShowAddSubEquipment] = useState(false);
  function checkIfSubEquipmentHasData(subEquipments: any) {
    let hasData = false;
    let subEquipmentCategories = Object.keys(subEquipments);
    if (subEquipmentCategories.length > 0) {
      subEquipmentCategories.forEach(category => {
        if (subEquipments[category].length > 0) {
          hasData = true;
        }
      });
    } else {
      hasData = false;
    }
    return hasData;
  }
  return (
    <>
      <Box m={1} p={2}>
        {headerData.map((header: any, index) => {
          return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.6rem',
                  }}
                >
                  <Typography variant="h4">Header {header.count}</Typography>
                  <Box sx={{ marginLeft: 'auto' }}>
                    {header['subEquipments'] &&
                      checkIfSubEquipmentHasData(header['subEquipments']) && (
                        <img
                          src={EqtInsideEqt}
                          style={{ height: '20px' }}
                          alt="Equipment Inside Icon"
                        />
                      )}
                  </Box>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box m={1} p={1} sx={styledBoxWithShadow}>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Box m={1} p={1}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Choose Sensors</FormLabel>
                            <FormGroup>
                              {PLANT_CONFIG_DATA['Headers']['sensors'].map((sensor: any) => {
                                return (
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={header['sensors'].find(
                                          (availableSensor: any) =>
                                            availableSensor['sensorName'] === sensor
                                        )}
                                        onChange={() => {
                                          updateSensorList('Headers', index, sensor);
                                        }}
                                        name={sensor}
                                      />
                                    }
                                    label={sensor}
                                  />
                                );
                              })}
                            </FormGroup>
                            <FormHelperText>Choose Sensor in Header {header.count}</FormHelperText>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Box m={1} p={1}>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={tankChoice}
                            value={header['Out']}
                            onChange={(eveny, newValue) => {
                              handleMultiSelect('Headers', index, 'Out', newValue);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Choose Output Tanks"
                                placeholder="Output Tanks"
                                style={PlantConfigStyle.equipmentTextField}
                              />
                            )}
                          />
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'headerAge' + header['count']}
                            label="Age in months"
                            value={header['age']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Headers',
                                index,
                                valuefor: 'age',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 1000,
                              step: '1',
                            }}
                          />
                        </Box>
                        <Box m={1} p={1}>
                          <TextField
                            fullWidth
                            id={'model+MR' + header['count']}
                            label="Header NickName"
                            value={header['nickName']}
                            onChange={e =>
                              handleNickNameChange({
                                category: 'Headers',
                                index,
                                value: e.target.value,
                              })
                            }
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <DiagramSelectComponent
                          category="Headers"
                          index={index}
                          updateValue={updateValue}
                          layoutDiagram={header['layoutDiagram']}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <SensorTagArray
                          categoryName="Headers"
                          formHelperText={{
                            name: header.name,
                            count: header.count,
                          }}
                          sensorsTagData={header['sensors']}
                          componentIndex={index}
                          updateSensorTagValue={updateSensorTagValue}
                          updateValue={updateValue}
                          hmiData={header['hmiButtons']}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={header.displayNode}
                              // checked={check}
                              onChange={() => {
                                handleShowOnDiagramCheck('Headers', index, !header.displayNode);
                              }}
                              name={'Show on diagram'}
                            />
                          }
                          label={'Show on diagram'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={PlantConfigStyle.configFormWrapper}>
                    <Button
                      variant="contained"
                      style={PlantConfigStyle.deleteButton}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setEquipmentIndex(index);
                        setEquipmentName(`Header ${header.count}`);
                        setEquipment('Headers');
                      }}
                    >
                      <DeleteIcon style={PlantConfigStyle.deleteIcon} />
                    </Button>
                    <Button
                      variant="contained"
                      style={PlantConfigStyle.deleteButton}
                      onClick={() => {
                        setShowAddSubEquipment(true);
                        setEquipmentIndex(index);
                        setEquipmentName(`Header ${header.count}`);
                        setEquipment('Headers');
                      }}
                    >
                      <AddIcon style={PlantConfigStyle.deleteIcon} />
                    </Button>
                  </div>
                </Box>
                {header['subEquipments'] && checkIfSubEquipmentHasData(header['subEquipments']) && (
                  <>
                    <Box m={1} p={1}>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '2rem 0',
                        }}
                      >
                        <Typography variant="h4">Sub - Equipments</Typography>
                        <SettingsIcon style={{ paddingLeft: '1rem' }} />
                      </span>
                    </Box>
                    <SubEquipmentViewAndControl
                      parentEquipmentType="Headers"
                      parentEquipmentIndex={index}
                      subEquipments={header['subEquipments']}
                      plantConfig={plantConfig}
                      setplantConfig={setPlantConfig}
                      tankChoice={tankChoice}
                      valveChoice={valveChoice}
                      equipChoice={equipChoice}
                      headerChoice={headerChoice}
                      sensorPostFix={sensorPostFix}
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={`You are deleting the ${equipmentName}`}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            RemoveElement(equipment, equipmentIndex);
            setProcessing(false);
            setShowDeleteModal(false);
          }}
        />
        <AddEquipmentInsideEquipmentTypePopup
          open={showAddSubEquipment}
          handleClose={() => {
            setShowAddSubEquipment(false);
          }}
          plantConfig={plantConfig}
          setPlantConfig={setPlantConfig}
          parentCategory="Headers"
          parentIndex={equipmentIndex!}
          TankOptions={TankOptions}
          ValveOptions={ValveOptions}
          CoreEquipmentOptions={CoreEquipmentOptions}
          HeaderOptions={HeaderOptions}
          sensorPostFix={sensorPostFix}
        />
      </Box>
    </>
  );
}

export default HeaderConfig;
