import axiosInstance from "../constants/axiosInstance";

const getDIForm = async (plantId: string, formType: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/data-input/getDIForm?plantId=${plantId}&&formType=${formType}`
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | getDIForm | ", err);
  }
};

const updateDIForm = async (body: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/data-input/updateDIForm`,
      body
    );
    return data;
  } catch (err) {
    console.error("ERROR | updateDIForm | ", err);
  }
};

export { getDIForm, updateDIForm };
