import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ThemeProvider, Box, Grid, Button } from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import dpTheme from '../styles/theme/dpTheme';

import { getDataInputCategoryList } from '../services/DataInputs';

// @ subject to change upon integration
interface DataInputs {
  name: string;
  action: string;
  color: string;
}

export default function DataInputs() {
  const { plantId } = useParams<string>();
  const navigate = useNavigate();

  const [forms, setForms] = useState<DataInputs[]>();

  useEffect(() => {
    fetchDataInputs();
  }, []);

  const fetchDataInputs = () => {
    getDataInputCategoryList().then(data => {
      setForms(data);
    });
  };

  const handleClick = (e: any) => {
    if (e.target.value === 'Inventory') {
      navigate(`/InventoryOptions/${plantId}`);
    } else {
      navigate(`/data-inputv2/${plantId}`);
    }
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent plantId={plantId} factoryResetContainer={true}>
        <Box m={4}>
          <Grid container spacing={5} sx={{ height: '80vh' }}>
            {forms &&
              //@ts-ignore
              forms!.map(form => {
                if (form?.name !== 'Inventory') {
                  return (
                    <Grid item xs={12} sm={6} key={form.name}>
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={e => handleClick(e)}
                        data-name={form.name}
                        sx={{
                          backgroundColor: `${form.color}`,
                          color: 'white',
                          height: '100%',
                        }}
                        value={form.name}
                      >
                        {form.name}
                      </Button>
                    </Grid>
                  );
                }
              })}
          </Grid>
        </Box>
      </LayoutComponent>
    </ThemeProvider>
  );
}
