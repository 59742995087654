import { Stack, Typography, Box, Autocomplete, TextField, IconButton, Paper } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { getAbbrList, verifyStoreAbbr } from '../../services/stores';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

interface StoreAbbreviationSelectionInterface {
  storeName: string;
  abbr: string;
  setAbbr: Function;
  setAbbrError: Function;
  component: 'create' | 'edit';
}

function StoreAbbreviationSelection({
  storeName,
  abbr,
  setAbbr,
  setAbbrError,
  component,
}: StoreAbbreviationSelectionInterface) {
  const [abbrList, setAbbrList] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAbbr, setCurrentAbbr] = useState<string>('');
  const [initialAbbr, setInitialAbbr] = useState('');
  const [abbrHasErrors, setAbbrHasErrors] = useState(false);

  useEffect(() => {
    const upperCaseAbbr = abbr.toUpperCase();
    setInitialAbbr(upperCaseAbbr);
    setCurrentAbbr(upperCaseAbbr);

    if (storeName.length <= 3) {
      toast.error('Store name should be at least 4 characters long');
      return;
    }

    const fetchAbbreviations = async () => {
      try {
        const abbrData = await getAbbrList(storeName);
        let upperCaseAbbrData = abbrData.map((abbr: string) => abbr.toUpperCase());

        // If the current abbreviation is not empty and not in the fetched list, add it
        if (upperCaseAbbr && !upperCaseAbbrData.includes(upperCaseAbbr) && component === 'edit') {
          upperCaseAbbrData = [upperCaseAbbr, ...upperCaseAbbrData];
        }

        // Using a Set to ensure uniqueness
        const uniqueAbbrSet: any = new Set(upperCaseAbbrData);
        setAbbrList([...uniqueAbbrSet]);

        if (upperCaseAbbrData.length > 0) {
          setCurrentAbbr(upperCaseAbbrData[0]);
          setAbbr(upperCaseAbbrData[0]);
          setAbbrError(false);
          setAbbrHasErrors(false);
        }
      } catch (err) {
        console.error('Error fetching initial data:', err);
      }
    };

    fetchAbbreviations();
  }, [storeName]);

  const handleEditClick = () => {
    setIsEditing(true);
    setAbbrError(true);
  };

  const validateAbbr = async (value: string) => {
    // Check if the length is less than 3
    if (value.length < 3) {
      setAbbrError(true);
      setAbbrHasErrors(true);
      toast.error('Abbreviation must be at least 3 characters long');
      return false;
    }

    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    // Check if the value contains special characters
    if (!alphanumericRegex.test(value)) {
      setAbbrError(true);
      setAbbrHasErrors(true);
      toast.error('Abbreviation should not contain special characters');
      return false; // Return false to prevent further validation
    }

    // Proceed with uniqueness check only if the value has changed from the initial value
    if (initialAbbr !== value) {
      let { isUnique } = await verifyStoreAbbr(value);
      if (!isUnique) {
        setAbbrError(true);
        setAbbrHasErrors(true);
        toast.error('The abbreviation is not unique');
        return false;
      }
    }

    // If no errors are found
    setAbbrError(false);
    setAbbrHasErrors(false);
    return true;
  };

  const handleAbbrChange = (event: any, newValue: string | null) => {
    const upperCaseValue = newValue ? newValue.trim().toUpperCase() : '';
    if (upperCaseValue.length <= 6) {
      setCurrentAbbr(upperCaseValue);
    }
  };

  const finishEditing = async () => {
    setIsEditing(false);
    setAbbrError(false);
    setAbbrHasErrors(false);
    const validated = await validateAbbr(currentAbbr);
    if (validated) {
      setAbbr(currentAbbr);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.trim();
    setCurrentAbbr(inputValue.toUpperCase());
  };

  const handleBlur = async () => {
    await finishEditing();
  };

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      await finishEditing();
    }
  };

  const abbrBoxStyle = {
    borderBottom: currentAbbr !== '' ? '1px dashed' : 'none',
    cursor: 'pointer',
    color: abbrHasErrors ? 'red' : '#3DA3F5',
  };

  return (
    <Stack mt={2} direction="row" spacing={1}>
      <Typography variant="body1" sx={{ fontSize: '12px', color: '#999999' }}>
        Abbreviation for the store is
      </Typography>
      <Stack direction="row" spacing={1}>
        {isEditing ? (
          <Autocomplete
            freeSolo
            options={abbrList}
            value={currentAbbr}
            onChange={handleAbbrChange}
            onBlur={handleBlur}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                inputProps={{
                  ...params.inputProps,
                  minLength: 3,
                  maxLength: 6,
                  pattern: '^[A-Za-z0-9]*$',
                }}
                size="small"
                autoFocus
                sx={{
                  width: '100px',
                  '& .MuiInputBase-input': {
                    fontSize: '12px',
                  },
                }}
              />
            )}
            sx={{
              width: '100px',
              '& .MuiAutocomplete-clearIndicator': {
                '& svg': {
                  fontSize: '12px',
                },
              },
              '& .MuiAutocomplete-option': {
                fontSize: '12px',
              },
            }}
            PaperComponent={({ children }) => (
              <Paper style={{ fontSize: '12px' }}>{children}</Paper>
            )}
          />
        ) : (
          <Box sx={abbrBoxStyle} onClick={handleEditClick}>
            <Typography variant="body1" sx={{ fontSize: '12px' }}>
              {currentAbbr === '' ? '-' : currentAbbr}
            </Typography>
          </Box>
        )}
        {!isEditing && (
          <IconButton
            onClick={handleEditClick}
            sx={{
              color: '#666666',
              width: '16px',
              height: '16px',
              cursor: 'pointer',
            }}
          >
            <EditIcon fontSize="small" sx={{ color: '#666666', width: '16px', height: '16px' }} />
          </IconButton>
        )}
      </Stack>
    </Stack>
  );
}

export default StoreAbbreviationSelection;
