import { useEffect, useRef, useState } from 'react';
import logo from '../constants/Images/digital-ecoinnovision-logo.png';
//@ts-ignore
import styled from 'styled-components';
import { Box, Button, TextField, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router';
import Webcam from 'react-webcam';
import Autocomplete from '@mui/lab/Autocomplete';
import CheckIcon from '@mui/icons-material/Check';
import { PrimaryButton } from '../styles/global/components/dpButtons';
import color from '../styles/color';
import CameraIcon from '@mui/icons-material/Camera';
import RefreshIcon from '@mui/icons-material/Refresh';
import { getCheckInUser, updateCheckIn, updateLoginPhoto } from '../services/checkIn';
import { UserLocation, CheckInUser } from '../Interfaces/checkIn';

export const CheckInPageWrapper = styled.div`
  @media screen and (min-height: 650px) {
    height: 100vh;
  }
  transition-duration: 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  padding: 20px 0 40px 0;
`;

const CheckInStyle = {
  logo: {
    width: '40px',
    height: '60px',
    display: 'flex',
  },
  color: {
    color: color.dpBlue,
  },
  cameraActionButton: {
    zIndex: 1000,
    translate: '0 -54px',
  },
  cameraActionIcon: {
    width: '100px',
    height: '40px',
    color: color.dpWhite,
  },
  webCam: {
    borderRadius: '2px',
  },
  checkIcon: {
    color: 'green',
    marginLeft: '5px',
    marginBottom: '3px',
  },
  actionButtons: {
    height: '35px',
  },
};

let imgsrc: any = '';

export default function CheckIn() {
  const navigate = useNavigate();
  const [isWebcamLoading, setIsWebcamLoading] = useState<boolean>(true);
  const [name, setName] = useState([]);
  const [imageCaptured, setImageCaptured] = useState<boolean>(false);
  const [checkInUser, setCheckInUser] = useState<CheckInUser | null>(null);
  const [location, setLocation] = useState<UserLocation>({
    latitude: '',
    longitude: '',
  });
  const [deviceId, setDeviceId] = useState<string | undefined | null>(null);
  const cameraWidth = window.innerWidth > 600 ? window.innerWidth / 2 : window.innerWidth;
  const cameraHeight = window.innerHeight / 2;
  const videoConstraints = {
    width: cameraWidth,
    height: cameraHeight,
    facingMode: 'user',
    aspectRatio: 1,
  };

  const webCamRef: any = useRef();

  function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  const checkInUserfnc = async () => {
    await updateCheckIn(checkInUser, true, location, deviceId)
      .then(data => {
        localStorage.setItem('allowAction', 'true');
        localStorage.setItem('OperatorCheckedIn', 'true');
        navigate('/task-list');
      })
      .catch(err => {});

    if (checkInUser?.checkedInStatus === false) {
      /// if user is not checked in and is going to check in then store the image else not
      let newFormData = new FormData();
      newFormData.append('upload', await DataURIToBlob(imgsrc), 'checkInImage.jpeg');
      await updateLoginPhoto(checkInUser, newFormData)
        .then(data => {})
        .catch(err => {});
    }
  };

  const showTasks = () => {
    localStorage.setItem('allowAction', 'false');
    localStorage.setItem('OperatorCheckedIn', 'true');
    navigate('/task-list');
  };

  useEffect(() => {
    setIsWebcamLoading(true);
    getCheckInUser()
      .then(data => {
        setName(data.Users);
      })
      .catch(err => {});

    setTimeout(() => {
      setIsWebcamLoading(false);
    }, 2000);
  }, []);

  const capture = async () => {
    imgsrc = await webCamRef.current.getScreenshot();

    await setImageCaptured(true);
  };

  const getDeviceInfo = async () => {
    await getUserLocation();
    await getDeviceId();
  };

  const getUserLocation = async () => {
    if (navigator.geolocation) {
      const position: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    } else console.log('Geolocation is not supported by this browser.');
  };

  const getDeviceId = async () => {
    var uniqueId =
      '_' +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    if (!localStorage.getItem('deviceId')) localStorage.setItem('deviceId', uniqueId);

    setDeviceId(localStorage.getItem('deviceId')?.toString());
  };

  return (
    <CheckInPageWrapper>
      <img src={logo} alt="waterdrop" style={CheckInStyle.logo} />
      <Typography variant="h3" sx={CheckInStyle.color}>
        Check In
      </Typography>

      {imageCaptured ? (
        <>
          <img
            src={imgsrc}
            alt="cameraIcon"
            height={cameraHeight}
            width={cameraWidth}
            style={{
              aspectRatio: 1,
            }}
          />
          <Button
            onClick={() => {
              setImageCaptured(false);
              setIsWebcamLoading(true);
              setTimeout(() => {
                setIsWebcamLoading(false);
              }, 2000);
            }}
            style={CheckInStyle.cameraActionButton}
          >
            <RefreshIcon style={CheckInStyle.cameraActionIcon} />
          </Button>
        </>
      ) : (
        <>
          {isWebcamLoading && (
            <div
              style={{
                height: cameraHeight,
                width: cameraWidth,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress style={CheckInStyle.color} />
            </div>
          )}
          {!isWebcamLoading && (
            <Webcam
              audio={false}
              ref={webCamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              style={CheckInStyle.webCam}
            />
          )}
          <Button
            disabled={isWebcamLoading}
            onClick={capture}
            style={CheckInStyle.cameraActionButton}
          >
            <CameraIcon style={CheckInStyle.cameraActionIcon} />
          </Button>
        </>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignItems: 'stretch',
          marginBottom: '20px',
        }}
      >
        {name.length > 0 && (
          <Autocomplete
            id="operator-name"
            options={name}
            autoHighlight
            getOptionLabel={option => (option.name ? option.name : '')}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {option.name}
                {option.checkedInStatus && <CheckIcon style={CheckInStyle.checkIcon} />}
              </Box>
            )}
            value={checkInUser}
            onChange={(event, newValue) => {
              setCheckInUser(newValue);
            }}
            renderInput={params => <TextField required {...params} label="Select Operator" />}
          />
        )}

        <PrimaryButton
          onClick={() => {
            getDeviceInfo();
          }}
          disabled={imageCaptured === false || checkInUser === null}
          style={CheckInStyle.actionButtons}
        >
          Share my location
        </PrimaryButton>

        <PrimaryButton
          onClick={() => {
            checkInUserfnc();
          }}
          disabled={
            imageCaptured === false ||
            checkInUser === null ||
            deviceId === null ||
            location.longitude === ''
          }
          style={CheckInStyle.actionButtons}
        >
          Continue
        </PrimaryButton>
        <p style={{ textAlign: 'center', margin: 0, padding: 0 }}>OR</p>
        <PrimaryButton
          onClick={() => {
            showTasks();
          }}
          style={CheckInStyle.actionButtons}
        >
          Continue without CheckIn
        </PrimaryButton>
      </div>
    </CheckInPageWrapper>
  );
}
