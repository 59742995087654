import { memo } from 'react';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DigitalClock } from "@mui/x-date-pickers/DigitalClock";

const DigitalTimeSelector = memo(({ handleTimeChange, value }: any) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DigitalClock
        value={value}
        onChange={handleTimeChange}
        ampm={false}
        timeStep={1}
        sx={{ maxHeight: "250px" }}
      />
    </LocalizationProvider>
  );
});

export default DigitalTimeSelector;