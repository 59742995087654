export default function WorkspaceIcon({ color = '#999999' }: { color: string }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '16px' }}
    >
      <path
        d="M6.66671 1.33325H9.33337C9.687 1.33325 10.0261 1.47373 10.2762 1.72378C10.5262 1.97382 10.6667 2.31296 10.6667 2.66659V3.99992H13.3334C13.687 3.99992 14.0261 4.14039 14.2762 4.39044C14.5262 4.64049 14.6667 4.97963 14.6667 5.33325V12.6666C14.6667 13.0202 14.5262 13.3593 14.2762 13.6094C14.0261 13.8594 13.687 13.9999 13.3334 13.9999H2.66671C2.31309 13.9999 1.97395 13.8594 1.7239 13.6094C1.47385 13.3593 1.33337 13.0202 1.33337 12.6666V5.33325C1.33337 4.59325 1.92671 3.99992 2.66671 3.99992H5.33337V2.66659C5.33337 1.92659 5.92671 1.33325 6.66671 1.33325ZM9.33337 3.99992V2.66659H6.66671V3.99992H9.33337Z"
        fill="#193458"
      />
    </svg>
  );
}
