//@ts-nocheck
import { useParams } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import LayoutComponent from "../components/LayoutComponent";
import axiosInstance from "../constants/axiosInstance";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function Inventory(props) {
  const { id } = useParams();
  const [pumps, setPumps] = useState([]);
  const [blowers, setBlowers] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    axiosInstance()
      .get(`/dashboard/onoffstatus?id=${id}`)
      .then(({ data }) => {
        console.log(data);
        setPumps(data.data.pumps);
        setBlowers(data.data.blowers);
      });
  }, [id]);

  const handleChange = (type, index, value) => {
    axiosInstance()
      .post(`/dashboard/updateonoffstatus`, {
        id: id,
        type: type,
        index: index,
        value: value,
      })
      .then(({ data }) => {
        if (type === "pumps") {
          let oldpumps = pumps;
          oldpumps[index]["status"] = value;
          setPumps(oldpumps);
          setRefresh(!refresh);
        } else {
          let oldblowers = blowers;
          oldblowers[index]["status"] = value;
          setBlowers(oldblowers);
          setRefresh(!refresh);
        }
      });
  };

  return (
    <LayoutComponent plantId={id}>
      <Box m={1} p={1}>
        <div
          style={{
            fontFamily: "Montserrat Bold",
            fontSize: 30,
            alignItems: "center",
            verticalAlign: "middle",
          }}
        >
          Equipment Control
          {/* <span style={{float:"right"}}>
                    <GetAppIcon/>
                    </span> */}
        </div>
      </Box>
      <Box m={1} p={1} border={1}>
        <h2>Pumps</h2>
        {pumps.map((pump, index) => {
          return (
            <Box p={1}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>{pump.nickName}</div>
                <div>
                  <ToggleButtonGroup
                    color="primary"
                    value={pump.status}
                    exclusive
                    onChange={(e) =>
                      handleChange("pumps", index, e.target.value)
                    }
                  >
                    <ToggleButton value="ON">ON</ToggleButton>
                    <ToggleButton value="OFF">OFF</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </Box>
          );
        })}
      </Box>
      <Box m={1} p={1} border={1}>
        <h2>Blowers</h2>
        {blowers.map((blower, index) => {
          return (
            <Box p={1} m={1}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>{blower.nickName}</div>
                <div>
                  <ToggleButtonGroup
                    color="primary"
                    value={blower.status}
                    exclusive
                    onChange={(e) =>
                      handleChange("blowers", index, e.target.value)
                    }
                  >
                    <ToggleButton value="ON">ON</ToggleButton>
                    <ToggleButton value="OFF">OFF</ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </div>
            </Box>
          );
        })}
      </Box>
    </LayoutComponent>
  );
}
