import { useState, FC, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import InitialSetupForPlantImagePopup from './InitialSetup';
import ChooseImage from './ChooseImage';
import UploadImage from './UploadImage';
import { fetchImageForPlant, updateImageForPlant } from '../../services/plants';
import { toast } from 'react-toastify';

interface SelectImageForPlantPopup {
  plantId: string;
  open: boolean;
  setShowPopup: Function;
}

const SelectImageForPlantPopup: FC<SelectImageForPlantPopup> = ({
  plantId,
  open,
  setShowPopup,
}) => {
  const [setupType, setSetupType] = useState<string>('Initial-Setup');
  const [canSave, setCanSave] = useState<boolean>(false);
  const [preSetImage, setPreSetImage] = useState<any>({});
  const [newImageId, setNewImageId] = useState<string>('');
  const handleClose = () => {
    setSetupType('Initial-Setup');
    setShowPopup(false);
    setPreSetImage({});
  };

  const handleCancel = () => {
    if (setupType === 'Upload-Image' || setupType === 'Choose-Image') {
      setSetupType('Initial-Setup');
      setCanSave(false);
    } else {
      setCanSave(false);
      setShowPopup(false);
    }
  };

  useEffect(() => {
    console.log('setupType', setupType);
  }, [setupType, setSetupType]);
  useEffect(() => {
    if (open) {
      fetchImageForPlant(plantId).then(data => {
        if (data) {
          console.log(data);
          setPreSetImage(data.data);
        } else {
          setSetupType('Upload-Image');
        }
      });
    }
  }, [open]);
  async function handleUpdateImage() {
    if (newImageId) {
      let response = await updateImageForPlant(plantId, newImageId);

      console.log(response);
      if (response.data.status === 'success') {
        toast.success(response.data.message);
        handleClose();
      } else {
        toast.error(response.data.message);
        handleClose();
      }
    }
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add or select Image for Plant</DialogTitle>
      <DialogContent>
        {setupType == 'Initial-Setup' ? (
          <InitialSetupForPlantImagePopup setSetupType={setSetupType} />
        ) : setupType === 'Choose-Image' ? (
          <ChooseImage
            preSetImage={preSetImage}
            setCanSave={setCanSave}
            setNewImageId={setNewImageId}
          />
        ) : (
          <UploadImage plantId={plantId} handleClose={handleClose} presetImage={preSetImage} />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        {canSave ? <Button onClick={handleUpdateImage}>Save</Button> : <></>}
      </DialogActions>
    </Dialog>
  );
};

export default SelectImageForPlantPopup;
