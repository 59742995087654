import { Box, Typography } from '@mui/material';
import React from 'react';
import { Primary } from '../../../color-palette/colors';

interface CustomTreeHeaderProps {
  text?: string;
  filter?: string;
  heading?: string;
}

const CustomTreeHeader: React.FC<CustomTreeHeaderProps> = ({ heading, filter, text }) => {
  return (
    <>
      <Box sx={{ padding: '7px 0 7px 32px', borderBottom: `1px solid ${Primary[100]}` }}>
        <Typography variant="highlight" sx={{ fontSize: '14px', mr: '16px' }}>
          {heading}
        </Typography>
        {'  '}
        {filter && filter.length > 0 ? <Typography variant="caption1">{text}</Typography> : null}
      </Box>
    </>
  );
};
export default CustomTreeHeader;
