import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField, InputAdornment, IconButton, TextFieldProps } from '@mui/material';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';

interface SearchBoxProps extends Omit<TextFieldProps, 'onChange'> {
  onSearch: (query: string) => void;
  onClear: () => void;
  backgroundColor?: string;
}
export interface SearchBoxHandle {
  handleClear: () => void;
}
const SearchBox = forwardRef<SearchBoxHandle, SearchBoxProps>(
  ({ onSearch, onClear, backgroundColor = '#F2F7FB', sx, ...props }, ref) => {
    const [input, setInput] = useState('');
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInput(event.target.value);
    };
    useImperativeHandle(ref, () => ({
      handleClear,
    }));
    function handleClear() {
      setInput('');
      onClear();
    }
    const handleSearch = () => {
      if (onSearch) {
        onSearch(input);
      }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        handleSearch();
      }
    };
    return (
      <TextField
        value={input}
        variant="outlined"
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        {...props}
        sx={{
          ...sx,
          '.MuiInputBase-root': {
            background: backgroundColor || 'white', // Default to white if no background color is provided
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {input.length > 0 && (
                <IconButton onClick={handleClear} edge="end">
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton onClick={handleSearch} edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    );
  }
);

export default SearchBox;
