import Modal from "@mui/material/Modal";
import { Box, ThemeProvider, Stack } from "@mui/material";

import dpTheme from "../../styles/theme/dpTheme";
import {
  DialogNegativeButtonStyle,
  DialogPositiveButtonStyle,
} from "../../styles/global/components/dpButtons";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function ConfirmationBoxForSolutionFinishing(props: any) {
  const {
    openModal,
    setOpenModal,
    successFunction,
    rejectionFucntion,
    modalText,
  } = props;

  return (
    <ThemeProvider theme={dpTheme}>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <h3 id="modal-modal-title">Confirmation Box</h3>
          <p id="modal-modal-description">{modalText}</p>
          <Stack direction="row" justifyContent="space-between">
            <DialogNegativeButtonStyle
              onClick={() => {
                rejectionFucntion();
              }}
            >
              NO
            </DialogNegativeButtonStyle>
            <DialogPositiveButtonStyle
              onClick={() => {
                successFunction();
              }}
            >
              YES
            </DialogPositiveButtonStyle>
          </Stack>
        </Box>
      </Modal>
    </ThemeProvider>
  );
}
