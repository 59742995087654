import axiosInstance from "../constants/axiosInstance";

const fetchOneTimeTaskService = async (id: string | undefined) => {
    try {
      const { data } = await axiosInstance().get(`/tasks/oneTimeTasks/${id}`);
      return data?.tasks;
    } catch (err) {
      console.error("ERROR | oneTimeTasks | ", err);
    }
};

const fetchRolesService = async (id: string | undefined) => {
    try {
      const { data } = await axiosInstance().get(`/users/roleforTasks?id=${id}`);
      return data?.workforce;
    } catch (err) {
      console.error("ERROR | roleforTasks | ", err);
    }
};

const updateOneTimeTasks = async (id: string | undefined,oneTimeTasks: any) => {
    try {
        const { data } = await axiosInstance().post(`/tasks/updateOneTimeTasks/${id}`, oneTimeTasks);
        return data;
    } catch (err) {
        console.error("ERROR | updateOneTimeTasks | ", err);
    }
};


export {
    fetchOneTimeTaskService,
    fetchRolesService,
    updateOneTimeTasks
}