import { Box } from "@mui/material";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { Sensor } from "../../../Interfaces/PlantConfigurationInterface";
// import { useState } from "react";

interface IProps {
  formTitle: string;
  formData: string[];
  formHelperText: {
    name: string;
    count: number;
  };
  sensorsData: Sensor[];
  componentIndex: number;
  updateSensorList: Function;
  categoryName: String;
}

function FormHeader({
  formTitle,
  formData,
  formHelperText,
  sensorsData,
  componentIndex,
  updateSensorList,
  categoryName,
}: IProps) {
  // const [check, setCheck] = useState(false);
  return (
    <>
      <Box m={1} p={1}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{formTitle}</FormLabel>
          <FormGroup>
            {formData.map((sensorName: string) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        sensorsData.find(
                          (availableSensor: any) =>
                            availableSensor["sensorName"] === sensorName
                        )
                          ? true
                          : false
                      }
                      // checked={check}
                      onChange={() => {
                        updateSensorList(
                          categoryName,
                          componentIndex,
                          sensorName
                        );
                        // setCheck(!check);
                      }}
                      name={sensorName}
                    />
                  }
                  label={sensorName}
                />
              );
            })}
          </FormGroup>
          <FormHelperText>
            Choose Sensor in {formHelperText.name} {formHelperText.count}
          </FormHelperText>
        </FormControl>
      </Box>
    </>
  );
}

export default FormHeader;
