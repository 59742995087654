import React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Stack, IconButton, TextField } from "@mui/material";
import LayoutComponent from "../components/LayoutComponent";
import { useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import CircularProgress from "@mui/material/CircularProgress";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import lodash from "lodash";
import { ToastContainer, toast } from "react-toastify";
import WidgetConfigComponent from "../components/widgetConfigComponent/widgetConfigComponent";
import { widgetInterface } from "../Interfaces/widgetInterfaces";
import { lastMovedWidgetInterface } from "../Interfaces/pageConfigPageInterfaces";
import { plantDatainterface } from "../Interfaces/plantInterfaces";
import { BlackButton } from "../styles/global/components/dpButtons";
import { getPageConfigData, savePageConfigData } from "../services/pageConfig";
import { getPlantData } from "../services/plant";
import UndoIcon from "@mui/icons-material/Undo";

const styles = {
  pageConfigLoadingView: {
    width: "100%",
    height: "200px",
    display: "grid",
    placeItems: "center",
  },
  pageConfigHeader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "20px",
    justifyContent: "flex-start",
    background: "#eee",
  },
  pageConfigHeaderLeftPart: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  addWidgetIcon: {
    color: "#888",
    display: "grid",
    placeItems: "center",
    width: "20px",
    marginRight: "30px",
  },
  widgetClusterHeader: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "10px",
  },
  saveButtonLoadingCircle: {
    marginLeft: "10px",
    color: "#ddd",
  },
};

export default function PageConfig() {
  const { plantId, pageId } = useParams();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<any>(null);
  const [previousPageState, setPreviousPageState] = useState<any>(null);
  const [plantData, setPlantData] = useState<plantDatainterface | null>(null);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [lastMovedWidget, setLastMovedWidget] =
    useState<lastMovedWidgetInterface>({
      widgetName: "",
      direction: "",
    });

  // const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    getPageConfigData(plantId!, pageId!, "pageConfig").then((data) => {
      setPageData(data);
      setLoading(false);
      setRefresh(!refresh);
    });
    getPlantData(plantId!).then((data) => {
      setPlantData(data);
      setRefresh(!refresh);
    });
    setRefresh(!refresh);
  }, []);

  const savePageConfig = () => {
    setSaving(true);
    savePageConfigData(plantId!, pageId!, pageData).then((data) => {
      setSaving(false);
      navigate(`/dashboardConfig/${plantId}`);
    });
  };

  const changePageProperty = (property: string, value: any) => {
    let temp = pageData;
    if (temp != null) {
      temp[property] = value;
      setPageData(temp);
      setRefresh(!refresh);
    }
  };

  const addCustomWidget = (widgetCluster: string) => {
    let csWidgetCount = pageData!.data[widgetCluster].widgets.reduce(
      (total: any, currentWidget: any) => {
        if (currentWidget.widgetOrigin == "custom") return total + 1;
        else return total;
      },
      0
    );

    let customWidget = lodash.cloneDeep(pageData!.customWidgetLayout);
    customWidget["widgetName"] = "Custom Widget " + (csWidgetCount + 1);
    customWidget["widgetNickName"] = "Custom Widget" + (csWidgetCount + 1);

    pageData?.data[widgetCluster].widgets.push(customWidget);

    setRefresh(!refresh);
  };

  const moveWidgetPosition = (
    widgetCluster: string,
    widgetIndex: number,
    action: string
  ): void => {
    let totalWidgetCount = pageData?.data[widgetCluster].widgets.length || 0;
    let tempPageData = pageData!;
    let currWidget = lodash.clone(
      tempPageData.data[widgetCluster].widgets[widgetIndex]
    );
    if (action == "up") {
      if (widgetIndex > 0) {
        let upWidget = lodash.clone(
          tempPageData.data[widgetCluster].widgets[widgetIndex - 1]
        );
        tempPageData.data[widgetCluster].widgets[widgetIndex] = upWidget;
        tempPageData.data[widgetCluster].widgets[widgetIndex - 1] = currWidget;
      } else {
        toast("Cannot go any higher");
      }
      setLastMovedWidget({
        widgetName: currWidget.widgetName,
        direction: "up",
      });
    } else if (action == "down") {
      if (widgetIndex < totalWidgetCount - 1) {
        let downWidget = lodash.clone(
          tempPageData.data[widgetCluster].widgets[widgetIndex + 1]
        );
        tempPageData.data[widgetCluster].widgets[widgetIndex] = downWidget;
        tempPageData.data[widgetCluster].widgets[widgetIndex + 1] = currWidget;
      } else {
        toast("Cannot go any lower");
      }
      setLastMovedWidget({
        widgetName: currWidget.widgetName,
        direction: "down",
      });
    }

    /// reset widgetOrder
    tempPageData.data[widgetCluster].widgets.forEach(
      (widget: widgetInterface, widgetIndex: any) => {
        widget.widgetOrder = widgetIndex;
      }
    );

    setPageData(tempPageData);

    setRefresh(!refresh);
  };

  const handleCheckboxChange = (event: any, widgetCluster: string) => {
    const isChecked = event.target.checked;
    if (pageData !== null) {
      setPreviousPageState({
        ...pageData,
      });
    }

    const updatedWidgets = pageData?.data[widgetCluster].widgets.map(
      (widget: any) => ({
        ...widget,
        checked: isChecked,
      })
    );

    const updatedPageData = {
      ...pageData,
      data: {
        ...pageData?.data,
        [widgetCluster]: {
          ...pageData?.data[widgetCluster],
          widgets: updatedWidgets,
        },
      },
    };

    setPageData(updatedPageData);
  };

  const handleUndo = (widgetCluster: any) => {
    if (previousPageState !== null) {
      const lastUpdatedWidgets = previousPageState?.data[widgetCluster].widgets;
      const updatedPageData = {
        ...previousPageState,
        data: {
          ...previousPageState?.data,
          [widgetCluster]: {
            ...previousPageState?.data[widgetCluster],
            widgets: lastUpdatedWidgets,
          },
        },
      };
      setPageData(updatedPageData);
    }
  };

  const handleUndoAll = () => {
    if (previousPageState !== null) {
      setPageData(previousPageState);
    }
  };

  const handleSelectAllCheckboxChange = (event: any) => {
    const isChecked = event.target.checked;
    let updatedPageData: any = null;
    let previousPageState: any = null;

    if (pageData !== null) {
      previousPageState = { ...pageData };
      updatedPageData = { ...pageData };
      Object.keys(pageData.data).forEach((widgetCluster) => {
        const lastUpdatedWidgets =
          previousPageState.data[widgetCluster].widgets;
        previousPageState = {
          ...previousPageState,
          data: {
            ...previousPageState?.data,
            [widgetCluster]: {
              ...previousPageState?.data[widgetCluster],
              widgets: lastUpdatedWidgets,
            },
          },
        };
        const updatedWidgets = pageData.data[widgetCluster].widgets.map(
          (widget: any) => ({
            ...widget,
            checked: isChecked,
          })
        );
        updatedPageData.data[widgetCluster].widgets = updatedWidgets;
      });
    }
    setPreviousPageState(previousPageState);
    setPageData(updatedPageData);
  };

  if (loading)
    return (
      <LayoutComponent plantId={plantId}>
        <h1>Page Configuration</h1>
        <Box sx={styles.pageConfigLoadingView}>
          <CircularProgress />
        </Box>
      </LayoutComponent>
    );
  else if (pageData)
    return (
      <LayoutComponent plantId={plantId}>
        <h1>Page Configuration</h1>
        <Box
          borderRadius={5}
          sx={styles.pageConfigHeader}
          className="pageConfigHeader"
        >
          <br></br>
          <Box
            m={1}
            p={1}
            borderRadius={5}
            sx={styles.pageConfigHeaderLeftPart}
          >
            <TextField
              inputProps={{ style: { fontSize: "16px" } }}
              id="outlined-basic"
              label="page Name"
              variant="outlined"
              key="pageName"
              defaultValue={pageData.pageName}
              onBlur={(
                e: React.FocusEvent<
                  HTMLInputElement | HTMLTextAreaElement,
                  Element
                >
              ) => {
                changePageProperty("pageName", e.target.value);
              }}
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Time</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={pageData.defaultTimeInDays}
                value={pageData.defaultTimeInDays}
                label="page"
                onChange={(e) => {
                  changePageProperty("defaultTimeInDays", e.target.value);
                }}
                style={{ fontSize: "16px", fontWeight: "normal" }}
              >
                <MenuItem value={-1}>Yesterday</MenuItem>
                <MenuItem value={0}>Today</MenuItem>
                <MenuItem value={1}>1D</MenuItem>
                <MenuItem value={3}>3D</MenuItem>
                <MenuItem value={5}>5D</MenuItem>
                <MenuItem value={10}>10D</MenuItem>
                <MenuItem value={30}>1M</MenuItem>
                <MenuItem value={180}>6M</MenuItem>
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel style={{ marginBottom: "10px" }} component="legend">
                * Select the PowerBI Links you wanna see
              </FormLabel>
              <Autocomplete
                multiple
                id="tags-filled"
                options={plantData?.embeddedLinks || []}
                getOptionLabel={(option) => option.name}
                // defaultValue={ [valueTypes[0]] }
                filterSelectedOptions
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                value={pageData?.embeddedLinks || []}
                onChange={(e, newValue) => {
                  changePageProperty("embeddedLinks", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    // label="value types you wanna see"
                    placeholder=""
                  />
                )}
              />
            </FormControl>
          </Box>

          <Box m={1} p={1} style={{ marginLeft: "auto" }}>
            {/* <Checkbox checked={pageData.autoEmailing} onClick={(e)=>{changePageProperty("autoEmailing",e.target.checked)}} /> 
                <span>Auto Emailing</span>
                <br></br> */}
            <Checkbox
              checked={pageData.public}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changePageProperty("public", e.target.checked);
              }}
            />
            <span>Is this a public page ?</span>
            <br></br>
            <Checkbox
              checked={pageData.showEmbeddedLinks}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changePageProperty("showEmbeddedLinks", e.target.checked);
              }}
            />
            <span>Show Embedded Links ?</span>
          </Box>
        </Box>

        <Stack direction="row" spacing={2}>
          <Box display="flex" alignItems="center">
            <Checkbox onChange={handleSelectAllCheckboxChange} />
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Select All
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <IconButton onClick={handleUndoAll}>
              <UndoIcon />
            </IconButton>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              Undo All
            </Typography>
          </Box>
        </Stack>

        {Object.keys(pageData.data).map((widgetCluster) => {
          return (
            <>
              <Box sx={styles.widgetClusterHeader}>
                <Stack direction="row" spacing={2}>
                  <Checkbox
                    onChange={(event) => {
                      handleCheckboxChange(event, widgetCluster);
                    }}
                  />
                  <Typography
                    variant="h6"
                    component="div"
                    style={{ color: "#333" }}
                  >
                    {widgetCluster}
                  </Typography>
                  {pageData?.data[widgetCluster].widgets.some(
                    (widget: any) => widget.checked
                  ) && (
                    <IconButton
                      onClick={() => {
                        handleUndo(widgetCluster);
                      }}
                    >
                      <UndoIcon />
                    </IconButton>
                  )}
                </Stack>
                <div style={styles.addWidgetIcon}>
                  <AddIcon
                    fontSize="small"
                    onClick={() => {
                      addCustomWidget(widgetCluster);
                    }}
                  />
                </div>
              </Box>
              {pageData?.data[widgetCluster].widgets.map(
                (widget: widgetInterface, widgetIndex: number) => {
                  return (
                    <WidgetConfigComponent
                      key={widget.widgetName + widgetIndex}
                      pageData={pageData}
                      setPageData={setPageData}
                      widgetCluster={widgetCluster}
                      widget={widget}
                      widgetIndex={widgetIndex}
                      moveWidgetPosition={moveWidgetPosition}
                      lastMovedWidget={lastMovedWidget}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  ); // end of return for widget
                }
              )}
            </>
          ); //  end of return for widget cluster
        })}

        <BlackButton
          style={{ minWidth: "100px" }}
          onClick={() => {
            savePageConfig();
          }}
        >
          {saving ? (
            <span style={{ display: "flex", alignItems: "center" }}>
              <span>Saving</span>
              <CircularProgress
                size="20px"
                thickness={5}
                sx={styles.saveButtonLoadingCircle}
              />
            </span>
          ) : (
            <span>SAVE</span>
          )}
        </BlackButton>
        <ToastContainer />
      </LayoutComponent>
    );
  else return null; //  end of return for page component
}
