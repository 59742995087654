import { useEffect, useState } from "react";
import { ThemeProvider, Stack, Divider } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import ecoinnovision_logo from "../constants/Images/digitalpaanilogo.png";
import dpTheme from "../styles/theme/dpTheme";
import PersistentUserList from "../components/loginComponents/PersistentUserList";
import PasswordResetComponent from "../components/loginComponents/PasswordAndConfirmPassword";
import PasswordResetEmailComponent from "../components/loginComponents/PasswordResetEmailComponent";
import PasswordResetErrorComponent from "../components/loginComponents/PasswordResetErrorComponent";
import { getAllCachedLoginEntries } from "../utilities/cachedLoginEntries.utils";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordResetCheckEmail from "../components/loginComponents/PasswordResetCheckEmail";
import { checkTokenValidity } from "../services/Login";
import { toast } from "react-toastify";

const styles = {
  heading: {
    fontSize: "16px",
    color: "#52687C",
  },
};

export default function ForgotPasswordV2() {
  // const { token } = useParams<string>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  //@ts-ignore
  const [refresh, setRefresh] = useState<boolean>(false);
  const [pageState, setPageState] = useState<string>("email");
  let navigate = useNavigate();
  function resetStateToEmail() {
    setPageState("email");
    navigate("/ForgotPasswordV2?token=init");
  }
  function resetStateToError() {
    setPageState("error");
    navigate("/ForgotPasswordV2?token=error");
  }
  function changeStateToCheckEmail() {
    setPageState("check");
  }
  useEffect(() => {
    if (token === "init") {
      setPageState("email");
    } else if (token !== null && token !== undefined) {
      let decodedToken = decodeURIComponent(token!);
      checkTokenValidity(decodedToken!).then((data: any) => {
        console.log(data);
        if (data.data.success) {
          setPageState("password");
        } else {
          toast.error(data.data.message);
          setPageState("error");
        }
      });
    } else {
      setPageState("error");
    }
  }, []);

  useEffect(() => {
    console.log("pageState", pageState);
  }, [pageState]);
  return (
    <ThemeProvider theme={dpTheme}>
      <Stack
        direction="column"
        alignItems="center"
        gap={5}
        sx={{ marginTop: "100px" }}
      >
        <Stack direction="column" alignItems="center" gap={1}>
          <img
            src={ecoinnovision_logo}
            alt="ecoinnovision_logo"
            width="209px"
          />
          <div style={styles.heading}>Forgot your password?</div>
        </Stack>
        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          alignItems="center"
          justifyContent="center"
          gap={{ xs: 3, sm: 5 }}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ height: "100%" }}
          // border={1}
        >
          {getAllCachedLoginEntries()?.length > 0 ? (
            <PersistentUserList refreshMainLoginPage={setRefresh} />
          ) : null}

          {pageState === "password" && (
            <PasswordResetComponent resetStateToError={resetStateToError} />
          )}
          {pageState === "email" && (
            <PasswordResetEmailComponent
              changeStateToCheckEmail={changeStateToCheckEmail}
            />
          )}
          {pageState === "check" && <PasswordResetCheckEmail />}
          {pageState === "error" && (
            <PasswordResetErrorComponent
              resetStateToEmail={resetStateToEmail}
            />
          )}
        </Stack>
      </Stack>
    </ThemeProvider>
  );
}
