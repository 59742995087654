import axiosInstance from "../constants/axiosInstance";

const getUserDetails = async () => {
  try {
    const { data } = await axiosInstance().post("/newUsers/getUserDetails");
    return data;
  } catch (err) {
    console.error("Failed to get user details");
  }
};

const updatePassword = async (
  oldPassword: string,
  newPassword: string,
  confirmPassword: string
) => {
  try {
    const { data } = await axiosInstance().post("/newUsers/changePassword", {
      oldPassword,
      newPassword,
      confirmPassword,
    });
    return data;
  } catch (err) {
    console.error("Failed to update password");
  }
};

const updateUserDetails = async (
  email: string,
  language: string,
  name: string,
  number: string
) => {
  try {
    const { data } = await axiosInstance().post("/newUsers/updateUserDetails", {
      email,
      language,
      name,
      number,
    });
    return data;
  } catch (e) {
    console.log("Failed to update user");
  }
};

const updateProfilePicture = async (newFormData: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/newUsers/uploadProfilePic`,
      newFormData
    );
    return data;
  } catch (err) {
    console.error("ERROR | uploadProfilepic | ", err);
  }
};

const getProfilePicUrl = async (userId: string) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/getProfilePicUrl`, {
      id: userId,
    });
    return data;
  } catch (e) {}
};

export {
  getUserDetails,
  updatePassword,
  updateUserDetails,
  updateProfilePicture,
  getProfilePicUrl,
};
