export default function WorkspaceGrayIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M6.66683 1.33325H9.3335C9.68712 1.33325 10.0263 1.47373 10.2763 1.72378C10.5264 1.97382 10.6668 2.31296 10.6668 2.66659V3.99992H13.3335C13.6871 3.99992 14.0263 4.14039 14.2763 4.39044C14.5264 4.64049 14.6668 4.97963 14.6668 5.33325V12.6666C14.6668 13.0202 14.5264 13.3593 14.2763 13.6094C14.0263 13.8594 13.6871 13.9999 13.3335 13.9999H2.66683C2.31321 13.9999 1.97407 13.8594 1.72402 13.6094C1.47397 13.3593 1.3335 13.0202 1.3335 12.6666V5.33325C1.3335 4.59325 1.92683 3.99992 2.66683 3.99992H5.3335V2.66659C5.3335 1.92659 5.92683 1.33325 6.66683 1.33325ZM9.3335 3.99992V2.66659H6.66683V3.99992H9.3335Z"
        fill="#999999"
      />
    </svg>
  );
}
