export default function UserNameIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '12px' }}
    >
      <path
        d="M6.00008 0.666656C6.70732 0.666656 7.3856 0.947608 7.8857 1.4477C8.3858 1.9478 8.66675 2.62608 8.66675 3.33332C8.66675 4.04057 8.3858 4.71884 7.8857 5.21894C7.3856 5.71904 6.70732 5.99999 6.00008 5.99999C5.29284 5.99999 4.61456 5.71904 4.11446 5.21894C3.61437 4.71884 3.33341 4.04057 3.33341 3.33332C3.33341 2.62608 3.61437 1.9478 4.11446 1.4477C4.61456 0.947608 5.29284 0.666656 6.00008 0.666656ZM6.00008 7.33332C8.94675 7.33332 11.3334 8.52666 11.3334 9.99999V11.3333H0.666748V9.99999C0.666748 8.52666 3.05341 7.33332 6.00008 7.33332Z"
        fill="#999999"
      />
    </svg>
  );
}
