import { useState, useEffect } from 'react';
import theme from '../../styles/theme/dpTheme';

interface IProp {
  innerBreakpointValue?: 'sm';
  outerBreakpointValue?: 'md' | 'lg' | 'xl';
}

export default function useIsTablet({ innerBreakpointValue, outerBreakpointValue }: IProp = {}) {
  // console.log("breakpointValue", breakpointValue);
  const defaultInnerBreakpointValue = innerBreakpointValue || 'sm';
  const defaultOuterBreakpointValue = outerBreakpointValue || 'md';
  const [isTablet, setIsTablet] = useState(() => {
    return (
      window.innerWidth >= theme.breakpoints.values[defaultInnerBreakpointValue] &&
      window.innerWidth <= theme.breakpoints.values[defaultOuterBreakpointValue]
    );
  });

  useEffect(() => {
    function handleResize() {
      // console.log("defaultBreakpointValue", defaultBreakpointValue);
      setIsTablet(
        window.innerWidth >= theme.breakpoints.values[defaultInnerBreakpointValue] &&
          window.innerWidth <= theme.breakpoints.values[defaultOuterBreakpointValue]
      );
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isTablet;
}
