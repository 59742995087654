//@ts-nocheck
import { Box, Button, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/lab/Autocomplete";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DAYS, WEEKS, ASSIGN_ROLE } from "../../constants/constants";

function TaskSection({
  data,
  section,
  subsection,
  addTask,
  UpdateTask,
  deleteTask,
  addnewSubtask,
  delteSubTask,
  UpdateSubTask,
  updateDirectValue,
  isCoreEquipment,
}) {
  return (
    <>
      <Box m={1} p={1} borderLeft={2}>
        {isCoreEquipment && (
          <div
            style={{
              fontFamily: "Montserrat Bold",
              fontSize: 24,
              alignItems: "center",
              verticalAlign: "middle",
              marginBottom: "20px",
            }}
          >
            {subsection}
            <span style={{ float: "right" }}>
              <Button
                onClick={() => {
                  addTask(section, subsection);
                }}
              >
                <AddIcon />
                Add FixedTask
              </Button>
            </span>
          </div>
        )}
        {data.map((task: any, indexTask: number) => {
          console.log(task);
          return (
            <Box m={1} key={indexTask}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <div
                    style={{
                      font: "Montserrat Bold",
                      fontSize: 20,
                    }}
                  >
                    {task.heading.eng}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box m={1}>
                    <Box m={1} p={1}>
                      <div
                        style={{
                          font: "Montserrat Bold",
                          fontSize: 22,
                        }}
                      >
                        Task
                        <span style={{ float: "right" }}>
                          <Button
                            onClick={() => {
                              deleteTask(section, subsection, indexTask);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </span>
                      </div>
                    </Box>
                    <Box m={1} p={1}>
                      <div
                        style={{
                          font: "Montserrat Bold",
                          fontSize: 20,
                        }}
                      >
                        <div>Heading</div>
                        <div
                          style={{
                            font: "Montserrat",
                            fontSize: 16,
                          }}
                        >
                          <TextField
                            fullWidth
                            required
                            value={task.heading.eng}
                            label="Heading English"
                            onChange={(e) => {
                              UpdateTask(
                                section,
                                subsection,
                                indexTask,
                                "heading",
                                "eng",
                                e.target.value
                              );
                            }}
                          />
                        </div>

                        <div
                          style={{
                            font: "Montserrat",
                            fontSize: 16,
                          }}
                        >
                          <TextField
                            fullWidth
                            required
                            value={task.heading.hindi}
                            label="Heading Hindi"
                            onChange={(e) => {
                              UpdateTask(
                                section,
                                subsection,
                                indexTask,
                                "heading",
                                "hindi",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Box>
                    <Box m={1} p={1}>
                      <div
                        style={{
                          font: "Montserrat Bold",
                          fontSize: 20,
                        }}
                      >
                        <div>Description</div>
                        <div
                          style={{
                            font: "Montserrat",
                            fontSize: 16,
                          }}
                        >
                          <TextField
                            fullWidth
                            required
                            value={task.description.eng}
                            label="Description English"
                            onChange={(e) => {
                              UpdateTask(
                                section,
                                subsection,
                                indexTask,
                                "description",
                                "eng",
                                e.target.value
                              );
                            }}
                          />
                        </div>

                        <div
                          style={{
                            font: "Montserrat",
                            fontSize: 16,
                          }}
                        >
                          <TextField
                            fullWidth
                            required
                            value={task.description.hind}
                            label="Description Hindi"
                            onChange={(e) => {
                              UpdateTask(
                                section,
                                subsection,
                                indexTask,
                                "description",
                                "hind",
                                e.target.value
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Box>
                    <Box m={1} p={1}>
                      <div
                        style={{
                          font: "Montserrat Bold",
                          fontSize: 20,
                        }}
                      >
                        SubTasks
                        <span style={{ float: "right" }}>
                          <Button
                            onClick={() =>
                              addnewSubtask(section, subsection, indexTask)
                            }
                          >
                            <AddIcon />
                            Add New SubTask
                          </Button>
                        </span>
                      </div>
                    </Box>
                    {task["subTasks"].map(
                      (subTask: any, subTaskIndex: number) => {
                        return (
                          <Box m={1} p={1} key={subTaskIndex}>
                            <div
                              style={{
                                font: "Montserrat Bold",
                                fontSize: 16,
                              }}
                            >
                              SubTask {subTaskIndex + 1}
                              <span style={{ float: "right" }}>
                                <Button
                                  onClick={() =>
                                    delteSubTask(
                                      section,
                                      subsection,
                                      indexTask,
                                      subTaskIndex
                                    )
                                  }
                                >
                                  Delete SubTask
                                </Button>
                              </span>
                            </div>
                            <div
                              style={{
                                font: "Montserrat",
                                fontSize: 16,
                              }}
                            >
                              <TextField
                                fullWidth
                                required
                                value={subTask.name}
                                label="Description English"
                                onChange={(e) => {
                                  UpdateSubTask(
                                    section,
                                    subsection,
                                    indexTask,
                                    subTaskIndex,
                                    "name",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>

                            <div
                              style={{
                                font: "Montserrat",
                                fontSize: 16,
                              }}
                            >
                              <TextField
                                fullWidth
                                required
                                value={subTask.namehindi}
                                label="Description Hindi"
                                onChange={(e) => {
                                  UpdateSubTask(
                                    section,
                                    subsection,
                                    indexTask,
                                    subTaskIndex,
                                    "namehindi",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </Box>
                        );
                      }
                    )}
                    <Box m={1} p={1}>
                      <Box m={1} p={1}>
                        <div>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={DAYS}
                            value={task["recurringDays"]}
                            onChange={(event, newValue) => {
                              updateDirectValue(
                                section,
                                subsection,
                                indexTask,
                                "recurringDays",
                                newValue
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Recurring Days"
                              />
                            )}
                          />
                        </div>

                        {isCoreEquipment && (
                          <>
                            <div>
                              <TextField
                                fullWidth
                                required
                                value={task["recurringTimes"]}
                                label="Recurring Time"
                                onChange={(e) => {
                                  updateDirectValue(
                                    section,
                                    subsection,
                                    indexTask,
                                    "recurringTimes",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                            <div>
                              <Autocomplete
                                multiple
                                id="tags-standard"
                                options={WEEKS}
                                value={task["weeks"]}
                                onChange={(event, newValue) => {
                                  updateDirectValue(
                                    section,
                                    subsection,
                                    indexTask,
                                    "weeks",
                                    newValue
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant="standard"
                                    label="Recurring Weeks"
                                  />
                                )}
                              />
                            </div>
                          </>
                        )}
                      </Box>
                      {isCoreEquipment && (
                        <>
                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              required
                              value={task["dueTimeFromPosting"]}
                              label="OverDue Time"
                              onChange={(e) => {
                                updateDirectValue(
                                  section,
                                  subsection,
                                  indexTask,
                                  "dueTimeFromPosting",
                                  e.target.value
                                );
                              }}
                            />
                          </Box>
                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              required
                              value={task["videoUrl"]}
                              label="Video Url"
                              onChange={(e) => {
                                updateDirectValue(
                                  section,
                                  subsection,
                                  indexTask,
                                  "videoUrl",
                                  e.target.value
                                );
                              }}
                            />
                          </Box>
                          <Box m={1} p={1}>
                            <Autocomplete
                              id="tags-standard"
                              options={ASSIGN_ROLE}
                              value={task["toUser"]}
                              onChange={(event, newValue) => {
                                updateDirectValue(
                                  section,
                                  subsection,
                                  indexTask,
                                  "toUser",
                                  newValue
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  variant="standard"
                                  label="For Role"
                                />
                              )}
                            />
                          </Box>
                        </>
                      )}

                      {!isCoreEquipment && (
                        <>
                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              required
                              value={task["dueTimeFromPosting"]}
                              label="OverDue Time"
                              onChange={(e) => {
                                updateDirectValue(
                                  section,
                                  "",
                                  indexTask,
                                  "dueTimeFromPosting",
                                  e.target.value
                                );
                              }}
                            />
                          </Box>
                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              required
                              value={task["videoUrl"]}
                              label="Video Url"
                              onChange={(e) => {
                                updateDirectValue(
                                  section,
                                  "",
                                  indexTask,
                                  "videoUrl",
                                  e.target.value
                                );
                              }}
                            />
                          </Box>

                          <Box m={1} p={1}>
                            <Autocomplete
                              id="tags-standard"
                              options={ASSIGN_ROLE}
                              value={task["toUser"]}
                              onChange={(event, newValue) => {
                                updateDirectValue(
                                  section,
                                  "",
                                  indexTask,
                                  "toUser",
                                  newValue
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  variant="standard"
                                  label="For Role"
                                />
                              )}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          );
        })}
      </Box>
    </>
  );
}

export default TaskSection;
