import { Tab, styled } from "@mui/material";

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "none",
  fontSize: "16px",
  opacity: "55%",
  marginRight: theme.spacing(1),
  color: "#000",
  fontWeight: 500,
  "&.Mui-selected": {
    color: "#000",
    opacity: "100%",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#193458",
  },
  "& .badge-wrapper": {
    display: "flex",
    // // alignItems: "center",
    // // justifyContent: "center",
    flexDirection: "row",
  },
  "& .badge-content": {
    position: "relative",
  },
}));
