import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CommitIcon from '@mui/icons-material/Commit';
import { memo } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  FormControl,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PlantConfigStyle } from './PlantConfigStyle';
import React from 'react';
import SubOutputSelector from './subEquipment/SubOutputSelector';

interface IProps {
  showEquipmentOutputDialog: boolean;
  setShowEquipmentOutputDialog: Function;
  processing: boolean;
  setProcessing: Function;
  onConfirmAction: Function;
  plantConfig: any;
  setplantConfig: Function;
  outputOptions: any;
  equipmentCategories: any;
  categoriesValue: any;
  setCategoriesValue: Function;
}

// type EquipmentItem = {
//   id: string;
//   Output?: string[];
//   [key: string]: any;
// };

// type PlantConfig = {
//   [category: string]: EquipmentItem[];
// };

function checkIfSubEquipmentHasData(subEquipments: any) {
  let hasData = false;
  let subEquipmentCategories = Object.keys(subEquipments);
  if (subEquipmentCategories.length > 0) {
    subEquipmentCategories.forEach(category => {
      if (subEquipments[category].length > 0) {
        hasData = true;
      }
    });
  } else {
    hasData = false;
  }
  return hasData;
}

function getSubEquipmentCategories(equipment: any) {
  let keysOfSubEquipments: string[] = [];
  if (equipment['subEquipments']) {
    let subEquipmentCategories = Object.keys(equipment['subEquipments']);
    if (subEquipmentCategories.length > 0) {
      subEquipmentCategories.forEach(category => {
        if (equipment['subEquipments'][category].length > 0) {
          keysOfSubEquipments.push(category);
        }
      });
    }
  }

  return keysOfSubEquipments;
}
// function checkIfNodeCanBeRendered(equipment: any) {
//   let returnValue = false;
//   if (equipment.hasOwnProperty('displayNode')) {
//     returnValue = equipment.displayNode;
//   }
//   return returnValue;
// }
function EquipmentOutput({
  showEquipmentOutputDialog,
  setShowEquipmentOutputDialog,
  processing,
  setProcessing,
  plantConfig,
  setplantConfig,
  outputOptions,
  equipmentCategories,
  setCategoriesValue,
  categoriesValue,
  onConfirmAction,
}: IProps) {
  console.log(plantConfig, 'Equipment Output');

  const handleSelectChange = (
    event: any,
    equipmentCategory: any,
    equipmentId: String,
    equipmentIndex: number
  ) => {
    plantConfig[equipmentCategory][equipmentIndex]['Output'] = [...event.target.value];
    setplantConfig({ ...plantConfig });
  };
  const handleSelectSubEquipmentChange = (
    event: any,
    parentCategory: string,
    parentIndex: number,
    subEquipmentCategory: string,
    subEquipmentIndex: number
  ) => {
    plantConfig[parentCategory][parentIndex]['subEquipments'][subEquipmentCategory][
      subEquipmentIndex
    ]['Output'] = [...event.target.value];
    setplantConfig({ ...plantConfig });
  };
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={showEquipmentOutputDialog}
        onClose={() => {
          setShowEquipmentOutputDialog(false);
        }}
      >
        {processing && (
          <DialogContent>
            <Stack direction="row" alignItems="center" gap={3}>
              <CircularProgress />
              <Typography variant="h3">Saving Data</Typography>
            </Stack>
          </DialogContent>
        )}
        {!processing && (
          <>
            <DialogTitle>
              <Typography variant="h3">Select Equipment Output</Typography>
            </DialogTitle>
            <DialogContent>
              {equipmentCategories.map((equipmentCategory: any, index: number) => {
                return (
                  <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h5">{equipmentCategory}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper} sx={{ width: '100%' }}>
                        <Table aria-label="Equipment Output Selection table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Equipment Name</TableCell>
                              <TableCell align="center">Output</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {plantConfig[equipmentCategory].map((equipment: any, index: number) => (
                              // checkIfNodeCanBeRendered(equipment) &&
                              <React.Fragment key={equipment.id}>
                                <TableRow
                                  key={equipment.id}
                                  sx={{
                                    '&:last-child td, &:last-child th': {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell align="center">{equipment.id}</TableCell>
                                  <TableCell align="center">
                                    <FormControl>
                                      <Select
                                        id={equipment.id}
                                        multiple
                                        value={equipment?.Output || []}
                                        onChange={event => {
                                          handleSelectChange(
                                            event,
                                            equipmentCategory,
                                            equipment.id,
                                            index
                                          );
                                        }}
                                        renderValue={allSelectedValues => (
                                          <div
                                            style={{
                                              display: 'flex',
                                              flexDirection: 'row',
                                              flexWrap: 'wrap',
                                            }}
                                          >
                                            {allSelectedValues.map((selectedValue: any) => (
                                              <div style={PlantConfigStyle.selectedOptions}>
                                                {selectedValue}
                                              </div>
                                            ))}
                                          </div>
                                        )}
                                        style={{
                                          minWidth: '12rem',
                                          maxWidth: '18rem',
                                        }}
                                      >
                                        {outputOptions.map((option: any) => {
                                          if (option.showInSelection) {
                                            if (option.isSubEquipment) {
                                              // Return a different component for isSubEquipment true
                                              return (
                                                <MenuItem key={option.id} value={option.id}>
                                                  {option.parentEquipmentId}
                                                  <ListItemIcon>
                                                    <CommitIcon />
                                                  </ListItemIcon>
                                                  {option.id}
                                                </MenuItem>
                                              );
                                            } else {
                                              // Return another component for isSubEquipment false
                                              return (
                                                <MenuItem key={option.id} value={option.id}>
                                                  {option.id}
                                                </MenuItem>
                                              );
                                            }
                                          } else {
                                            // If showInSelection is false, do not render anything for this option
                                            return null;
                                          }
                                        })}
                                      </Select>
                                    </FormControl>
                                  </TableCell>
                                </TableRow>
                                {equipment['subEquipments'] &&
                                  checkIfSubEquipmentHasData(equipment['subEquipments']) && (
                                    <>
                                      <SubOutputSelector
                                        parentEquipmentCategory={equipmentCategory}
                                        parentEquipmentIndex={index}
                                        parentSubEquipmentCategories={getSubEquipmentCategories(
                                          equipment
                                        )}
                                        handleSelectSubEquipmentChange={
                                          handleSelectSubEquipmentChange
                                        }
                                        subEquipments={equipment['subEquipments']}
                                        outputOptions={outputOptions}
                                      ></SubOutputSelector>
                                    </>
                                  )}
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowEquipmentOutputDialog(false);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setProcessing(true);
                  onConfirmAction();
                }}
              >
                Done
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

export default memo(EquipmentOutput);
