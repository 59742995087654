import LayoutComponent from '../components/LayoutComponent';
import DIForm from '../components/DIForm/DIForm';
import { Box, ThemeProvider, Typography } from '@mui/material';
import { useParams } from 'react-router';
import dpTheme from '../styles/theme/dpTheme';
import { styledBox, styledTitle } from '../styles/global/components/dpBox';
import { useState } from 'react';

export default function DataInput() {
  const { plantId = '' } = useParams<string>();
  const [labTestDIForm, setLabTestDIForm] = useState(true);
  const [maintenanceDIForm, setMaintenanceDIForm] = useState(true);
  const [streamDIForm, setStreamDIForm] = useState(true);
  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent plantId={plantId}>
        <Box sx={styledBox}>
          <Typography variant="h1" sx={styledTitle}>
            Data Input
          </Typography>
          <DIForm formType="waterQualityDIForm" plantId={plantId} />
          {labTestDIForm && <hr></hr>}
          <DIForm formType="labTestDIForm" plantId={plantId} setLabTestDIForm={setLabTestDIForm} />
          {maintenanceDIForm && <hr></hr>}
          <DIForm
            formType="maintenanceDIForm"
            plantId={plantId}
            setMaintenanceDIForm={setMaintenanceDIForm}
          />
          {streamDIForm && <hr></hr>}
          <DIForm formType="streamDIForm" plantId={plantId} setStreamDIForm={setStreamDIForm} />
          {/* <DIForm formType="inventoryDIForm" plantId={plantId} /> */}
        </Box>
      </LayoutComponent>
    </ThemeProvider>
  );
}
