import { useEffect } from 'react';
import LayoutComponent from '../components/LayoutComponent';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import FormulaViewComponent from '../components/FormulaViewComponent';
import { customFormulaInterface, formulaDataForPlant } from '../Interfaces/formulaConfigInterfaces';
import {
  checkAndSetWorkspaceFormulas,
  getCustomFormulaListForWorkspace,
} from '../services/formulaConfig';
import { PrimaryButton } from '../styles/global/components/dpButtons';
import { Stack } from '@mui/system';
import { checkIfAllPlantsInWorkspaceHavePlantAndClientAbbrs } from '../services/alertTaskConfig';
const styles = {
  pageHeading: {
    fontFamily: 'Montserrat Bold',
    fontSize: 30,
    alignItems: 'center',
    verticalAlign: 'middle',
  },
  formulaTypeHeading: {
    fontFamily: 'Montserrat Bold',
    fontSize: 24,
    alignItems: 'center',
    verticalAlign: 'middle',
  },
};

export default function WorkspaceFormulaConfiguration() {
  type pageParams = {
    id: string;
  };

  const { id } = useParams<pageParams>();

  const [customFormulaList, setCustomFormulaList] = useState<customFormulaInterface[]>([]);
  const [formulaDataForWorkspace, setFormulaDataForWorkspace] =
    useState<formulaDataForPlant | null>(null);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [canConfigure, setCanConfigure] = useState(false);
  const [errorDisplayMessage, setErrorDisplayMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      checkIfAllPlantsInWorkspaceHavePlantAndClientAbbrs(id).then((data: any) => {
        setCanConfigure(!data.data.isIssue);
        setErrorDisplayMessage(data.data.returnString);
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getCustomFormulaListForWorkspace(id!).then((data: formulaDataForPlant) => {
      console.log('data : ', data);
      if (data) {
        console.log('formulaData : ', data);
        //if global formula list is not empty then set the state else set it to empty array

        if (data.customFormulas && data.customFormulas.length > 0) {
          setCustomFormulaList(data?.customFormulas);
        } else {
          setCustomFormulaList([]);
        }

        setFormulaDataForWorkspace(data);
      } else {
        setCustomFormulaList([]);
      }
      setLoading(false);
    });
  }, [id]);

  const handleSubmit = async () => {
    console.log('handle submit button pressed');
    if (formulaDataForWorkspace != null) {
      let dataForWorkspace = formulaDataForWorkspace;
      dataForWorkspace['customFormulas'] = customFormulaList;

      console.log(dataForWorkspace);
      checkAndSetWorkspaceFormulas(id!, dataForWorkspace)
        .then(data => {
          console.log(data);
          if (data.res === 'OK') {
            navigate('/plants');
          } else {
            data.res.forEach((element: string) => {
              toast.error(element);
            });
          }
        })
        .catch();
    }
  };

  const addNewFormula = () => {
    let formulaList = customFormulaList;
    let count;
    if (formulaList.length === 0) {
      count = 1000;
    } else {
      formulaList.sort(function (a, b) {
        if (a.priorityNum - b.priorityNum >= 0) return 1;
        else return -1;
      });
      let index = formulaList.length - 1;
      count = formulaList[index]['priorityNum'] + 1;
    }

    formulaList.push({
      lhs: '',
      rhs: '',
      variableList: [],
      type: 'Custom Formula',
      tag: '',
      priorityNum: count,
      isVirtualSensor: false,
    });
    formulaList.sort(function (a, b) {
      if (a.priorityNum - b.priorityNum >= 0) return 1;
      else return -1;
    });
    setCustomFormulaList(formulaList);
    setRefresh(!refresh);
  };

  const updateFormulaValue = (
    formulaType: string,
    index: number,
    part: 'lhs' | 'rhs' | 'tag',
    value: any
  ) => {
    console.log(formulaType + ' ' + index + ' ' + part + ' ' + value);
    let formulaList: customFormulaInterface[] = [];
    if (formulaType === 'Custom Formula') {
      formulaList = customFormulaList;
      if (part === 'tag') {
        let underScoreIndex = formulaList[index]['lhs'].indexOf('_') + 1;
        let plainFormula = formulaList[index]['lhs'].slice(underScoreIndex);
        console.log(
          'underscore index :- ' +
            underScoreIndex +
            ' lhs is :- ' +
            formulaList[index]['lhs'] +
            ' after splice lhs is :- ' +
            plainFormula
        );
        formulaList[index]['lhs'] = value + '_' + plainFormula;
        formulaList[index][part] = value;
      } else {
        formulaList[index][part] = value;
      }
      let rhs = formulaList[index]['rhs'];
      if (rhs.toString().includes('~')) {
        console.log('rhs contains ~');
        formulaList[index]['isVirtualSensor'] = false;
        formulaList[index]['sensorType'] = 'Change Of';
      } else {
        if (formulaList[index]['isVirtualSensor']) {
          formulaList[index]['sensorType'] = 'Virtual Sensor';
        } else {
          formulaList[index]['sensorType'] = 'Intermediary';
        }
      }
      setCustomFormulaList(formulaList);
      console.log(formulaList);
    }

    setRefresh(!refresh);
  };

  const removeFormula = (formulaType: string, index: number) => {
    let formulaList: customFormulaInterface[] = [];
    if (formulaType === 'Custom Formula') {
      formulaList = customFormulaList;
      formulaList.splice(index, 1);
      setCustomFormulaList(formulaList);
    }
    setRefresh(!refresh);
  };

  if (loading) return <div>loading .....</div>;
  else if (!loading && formulaDataForWorkspace)
    return (
      <LayoutComponent>
        {canConfigure ? (
          <>
            <Box m={1} p={1}>
              <div style={styles.pageHeading}>Custom Formula Configuration</div>

              <Box m={1} p={1}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <span style={styles.formulaTypeHeading}> Custom Formulas</span>
                  <span>
                    <PrimaryButton onClick={addNewFormula}>
                      <AddIcon />
                      Add Formula
                    </PrimaryButton>
                  </span>
                </Stack>
                {customFormulaList.map((formula, index) => {
                  return (
                    <FormulaViewComponent
                      formula={formula}
                      index={index}
                      updateFormulaValue={updateFormulaValue}
                      removeFormula={removeFormula}
                    />
                  );
                })}
              </Box>

              <Box m={1} p={1}>
                <PrimaryButton style={{ minWidth: '100px' }} onClick={handleSubmit}>
                  Next
                </PrimaryButton>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 'auto',
                padding: '8px',
                backgroundColor: 'lightcoral',
                borderColor: 'darkred',
                borderWidth: '2px',
                borderStyle: 'solid',
                color: 'white',
                borderRadius: '10px',
              }}
            >
              {errorDisplayMessage}
            </Box>
          </>
        )}

        <ToastContainer />
      </LayoutComponent>
    );
  else return null;
}
