import { Box } from "@mui/material";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Tooltip from "@mui/material/Tooltip";
import whiteLinkIcon from "../../../constants/Images/whiteLinkIcon.svg";
import InfoIcon from "@mui/icons-material/Info";
import Skeleton from "@mui/material/Skeleton";
// import {
//   widgetDataInterface,
//   widgetStructureInterface,
// } from "../../../Interfaces/widgetInterfaces";

const styles = {
  numberViewWrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
    gap: "10px",
    fontSize: "clamp(12px,10px + 0.1vw,25px)",
    fontWeight: "bold",
    color: "white",
    boxSizing: "border-box",
    fontFamily: "Roboto, Regular",
  },
  numberViewHeading: {
    display: "flex",
    alignItems: "flex-start",
    width: "auto",
    //   background : "black",
    padding: "0",
    height: "auto",
    fontSize: "clamp(13px,0.7vw,19px)",
  },
  linkIconStyle: {
    width: "18px",
    height: "18px",
    marginLeft: "auto",
  },
  numberViewBody: {
    width: "auto",
    height: "100%",
    // fontSize : "20px",
    fontSize: "clamp(18px,10vw,30px)",
    paddingTop: "auto",
    color: "#FFFFFF",
    overflow: "hidden",
    fontWeight: "normal",
  },
  numberViewRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  emptyNumberViewBody: {
    width: "100%",
    height: "inherit",
    display: "grid",
    placeItems: "center",
    // fontSize : "13px",
    fontSize: "clamp(13px,0.7vw,22px)",
    color: "#eee",
  },
};

// interface numberWidgetpropInterface {
//   widgetData: widgetDataInterface | null;
//   widgetStructure: widgetStructureInterface;
//   isLoading: boolean;
// }

export default function NumberWidgetPdfView(props: any) {
  const { widgetStructure, widgetData, isLoading, background } = props;
  const numberOfMetricsSelected =
    widgetData?.metricsData?.datasets?.length ?? 0;

  const clickedOnlinkIcon = () => {
    window.location.href = widgetData?.moreInfo ?? "";
  };

  const convertHoursToHoursAndMins = (val: number) => {
    if (val == null) return null;
    let hourPart = Math.floor(val);
    let minPart = val - hourPart;
    minPart = Math.floor(minPart * 60);
    return `${hourPart}h ${minPart}m`;
  };

  if (isLoading)
    return (
      <Box
        sx={{
          ...styles.numberViewWrapper,
          bgcolor: background || "white",
        }}
        p={1}
      >
        <Box
          className="numberHeading"
          style={{
            ...styles.numberViewHeading,
            color: "black",
            fontWeight: "normal",
          }}
        >
          <div>
            {widgetStructure.widgetNickName
              ? widgetStructure.widgetNickName
              : widgetStructure.widgetName}
          </div>
          <Tooltip title="Click to know more">
            <img
              src={whiteLinkIcon}
              style={{ width: "18px", height: "18px", marginLeft: "auto" }}
              onClick={() => {
                clickedOnlinkIcon();
              }}
            ></img>
          </Tooltip>
          <Tooltip
            title={widgetStructure.widgetDescription}
            style={{ color: "#eee" }}
          >
            <InfoIcon fontSize="small" />
          </Tooltip>
        </Box>
        <Box style={styles.emptyNumberViewBody}>
          <Skeleton
            variant="rectangular"
            style={{
              width: "inherit",
              height: "inherit",
            }}
          />
        </Box>
      </Box>
    );
  else if (widgetData)
    return (
      <Box
        sx={{
          ...styles.numberViewWrapper,
          bgcolor: background || "white",
        }}
        p={1}
      >
        <Box className="numberHeading" style={styles.numberViewHeading}>
          <div>
            {widgetData.widgetNickName
              ? widgetData.widgetNickName
              : widgetData.widgetName}
          </div>
          <Tooltip title="Click to know more" style={{}}>
            <img
              src={whiteLinkIcon}
              style={styles.linkIconStyle}
              onClick={() => {
                clickedOnlinkIcon();
              }}
            ></img>
          </Tooltip>
          <Tooltip title={widgetData.widgetDescription}>
            <InfoIcon fontSize="small" style={{ color: "#eee" }} />
          </Tooltip>
        </Box>
        {numberOfMetricsSelected > 0 ? (
          <Box sx={{ ...styles.numberViewBody }}>
            {widgetData.metricsData.datasets.map((data: any) => {
              //  console.log("inside number widget : ", data.data[0])
              return (
                <Box sx={styles.numberViewRow}>
                  <span
                    style={{
                      fontSize: "clamp(13px,0.7vw,19px)",
                    }}
                  >
                    {data.label}{" "}
                  </span>
                  {data.data[0] != null ? (
                    <span style={{ fontSize: "clamp(13px,0.7vw,19px)" }}>
                      {data.label.includes("hoursRun")
                        ? convertHoursToHoursAndMins(
                            parseFloat(data.data[0].toString())
                          )
                        : data.data[0]}
                    </span>
                  ) : (
                    <span
                      style={{
                        marginLeft: "auto",
                        fontSize: "clamp(13px,0.7vw,19px)",
                      }}
                    >
                      {"No Data Available"}
                    </span>
                  )}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box style={{ ...styles.emptyNumberViewBody }}>
            <p>No Metric Selected</p>
          </Box>
        )}
      </Box>
    );
  else return null;
}
