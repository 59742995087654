import { Controls, ControlButton } from 'reactflow';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import useUIControlStore from '../../../store/UIControlStore';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';

const CustomControlPanel = () => {
  const { toggleFullSize, visualisationFullSize } = useUIControlStore();
  const isMobile = useIsMobile();

  const handleFullScreenButtonClick = () => {
    toggleFullSize();
  };

  return (
    <Controls>
      {!isMobile && (
        <ControlButton onClick={handleFullScreenButtonClick} title="Custom Button">
          {!visualisationFullSize ? <OpenInFullIcon /> : <CloseFullscreenIcon />}
        </ControlButton>
      )}
    </Controls>
  );
};

export default CustomControlPanel;
