import { useEffect } from "react";
import LayoutComponent from "../components/LayoutComponent";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddUserPopup from "../components/AddUserPopup/AddUserPopup";
import { ToastContainer, toast } from "react-toastify";
import { userRoleBasedData } from "../Interfaces/userInterface";
import { getAllUsersForRole, deleteUser } from "../services/users";
import { PrimaryButton } from "../styles/global/components/dpButtons";

const styles = {
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  heading: {
    fontFamily: "Montserrat Bold",
    fontSize: 30,
    alignItems: "center",
    verticalAlign: "middle",
  },
};

export default function CSM() {
  const [edit, setEdit] = useState<boolean>(false);
  const [csmdata, setData] = useState<userRoleBasedData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [addUser, setAddUser] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");

  useEffect(() => {
    fetchCSM();
  }, []);

  const fetchCSM = () => {
    setEdit(false);
    setAddUser(false);
    getAllUsersForRole("CSM").then((data) => {
      console.log(data);
      setData(data);
      setLoading(false);
    });
  };

  const DeleteUser = (id: string, count: number) => {
    console.log(count);
    if (count > 0) {
      toast("Cannot Delete user with Plants Assigned");
    } else {
      deleteUser(id)
        .then((response) => {
          fetchCSM();
        })
        .catch();
    }
  };
  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "id",
      width: 350,
    },
    {
      field: "name",
      headerName: "Customer Success Manager Name",
      width: 350,
    },
    {
      field: "email",
      headerName: "Customer Success Manager Email",
      width: 350,
    },
    {
      field: "count",
      headerName: "Plants",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Button
              onClick={() => {
                setUserId(params.row.id);
                setEdit(true);
                setAddUser(true);
              }}
            >
              <EditIcon sx={{ color: "#000" }} />
            </Button>
            <Button
              onClick={() => {
                console.log(params);
                DeleteUser(params.row.id, params.row.count);
              }}
            >
              <DeleteIcon sx={{ color: "#000" }} />
            </Button>
          </>
        );
      },
      width: 300,
    },
  ];

  return (
    <LayoutComponent>
      <Box m={1} p={1}>
        <div style={styles.header}>
          <span style={styles.heading}> Customer Success Managers</span>
          <span>
            <PrimaryButton
              onClick={() => {
                setAddUser(true);
              }}
              sx={{ fontSize: "14px" }}
            >
              <AddIcon fontSize="small" />
              Add
            </PrimaryButton>
          </span>
        </div>
      </Box>
      <Box m={1} p={1}>
        <div style={{ height: 420 }}>
          <DataGrid rows={csmdata} columns={columns} loading={loading} />
        </div>
      </Box>
      {addUser ? (
        <AddUserPopup
          refresh={fetchCSM}
          edit={edit}
          handleClose={() => {
            setAddUser(false);
            setEdit(false);
          }}
          userId={userId}
          role="CSM"
        />
      ) : null}
      <ToastContainer />
    </LayoutComponent>
  );
}
