import ReactFlow, {
  ConnectionLineType,
  MiniMap,
  Background,
  MarkerType,
  ReactFlowProvider,
} from 'reactflow';
//@ts-ignore
import dagre from 'dagre';
import EquipmentNode from './CustomNodes/EquipmentNode/EquimentNode';
import 'reactflow/dist/style.css';
import classes from './styles/LayoutVisualisationStyle.module.css';
import DisplayEdge from './CustomEdges/DisplayEdge';
import React, { useMemo } from 'react';
import CustomControlPanel from './customControlPanel/CustomControlPanel';

const CurrentPlantDiagram = ({ nodes, edges }: any) => {
  const nodeTypes = {
    equipmentNode: EquipmentNode,
  };
  const edgeTypes = {
    equipmentEdge: DisplayEdge,
  };

  const memoizedNodesEdges = useMemo(() => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 500;
    const nodeHeight = 500;

    dagreGraph.setGraph({ rankdir: 'LR' });
    nodes.forEach((node: any) => {
      dagreGraph.setNode(node.id, {
        width: nodeWidth,
        height: nodeHeight,
      });
    });
    edges.forEach((edge: any) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
    dagre.layout(dagreGraph);
    nodes.forEach((node: any) => {
      if (!node.targetPosition && !node.sourcePosition) {
        node.targetPosition = 'left';
        node.sourcePosition = 'right';
      }

      if (!node.isPositionAdjusted) {
        if (node.position) {
          node.position = {
            x: node.position.x * 2.5,
            y: node.position.y * 2.5,
          };
          node.isPositionAdjusted = true;
        } else {
          const nodeWithPosition = dagreGraph.node(node.id);
          node.position = {
            x: nodeWithPosition.x - nodeWidth / 2,
            y: nodeWithPosition.y - nodeHeight / 2,
          };
          node.isPositionAdjusted = true;
        }
      }
    });

    edges.forEach((edge: any) => {
      edge['type'] = 'equipmentEdge';
      edge['markerEnd'] = {
        type: MarkerType.ArrowClosed,
        color: edge?.data?.properties?.color ?? '#000',
      };
      if (edge['sensorDataAvailability']) {
        if (edge['predictedStatus'] === 'ON') {
          edge['animated'] = true;
        }
      }
    });

    return { nodes, edges };
  }, [nodes, edges]);

  // useEffect(() => {
  //   setDefaultViewPort({ x: 0, y: 0, zoom: 1 });
  // }, [nodes]);

  edges.forEach((edge: any) => {
    edge['type'] = 'equipmentEdge';
    edge['markerEnd'] = {
      type: MarkerType.Arrow,
      color: edge?.data?.properties?.color ?? '#000',
    };
    if (edge['sensorDataAvailability']) {
      if (edge['predictedStatus'] === 'ON') {
        edge['animated'] = true;
        // edge['style'] = { stroke: '#36A2C7', strokeWidth: 4 };
        // edge['markerEnd'] = {
        //   type: MarkerType.ArrowClosed,
        //   width: 10,
        //   height: 10,
        //   color: '#36A2C7',
        // };
      } else {
        // edge['style'] = {
        //   stroke: 'rgba(0, 0, 0, 0.2)',
        //   strokeDasharray: '10,10',
        //   strokeWidth: 4,
        // };
        // edge['markerEnd'] = {
        //   type: MarkerType.ArrowClosed,
        //   width: 10,
        //   height: 10,
        //   color: 'rgba(0, 0, 0, 0.2)',
        // };
      }
    } else {
      // edge['style'] = { stroke: 'black', strokeWidth: 4 };
      // edge['markerEnd'] = {
      //   type: MarkerType.ArrowClosed,
      //   width: 20,
      //   height: 20,
      //   color: 'black',
      // };
    }
    // edge['type'] = 'smoothstep';
  });

  return (
    <div className={classes.PlantLayout}>
      <ReactFlowProvider>
        <ReactFlow
          nodes={memoizedNodesEdges.nodes}
          edges={memoizedNodesEdges.edges}
          edgeTypes={edgeTypes}
          nodeTypes={nodeTypes}
          connectionLineType={ConnectionLineType.SmoothStep}
          minZoom={0}
          maxZoom={4}
          fitView
        >
          <MiniMap />
          {/* <Controls /> */}
          <CustomControlPanel />
          <Background />
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  );
};

export default React.memo(CurrentPlantDiagram);
