import { Box, Grid, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';
import Theme from '../../theme';

type BoxStyle = {
  height?: string;
  pl?: string | number;
  pr?: string | number;
};
interface TwoRowLayoutProps {
  child1: ReactNode;
  child2: ReactNode;
  container?: boolean;
  style?: BoxStyle;
  gap?: number;
}

const TwoRowLayout = ({ child1, child2, container = true, style, gap = 4 }: TwoRowLayoutProps) => {
  return (
    <ThemeProvider theme={Theme}>
      <Grid
        container={container}
        direction="column"
        sx={{ ...style, flexWrap: 'nowrap' }}
        gap={gap}
      >
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>{child1}</Box>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <Box sx={{ height: '100%', pb: 1 }}>{child2}</Box>
          {/* {child2} */}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default TwoRowLayout;
