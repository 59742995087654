//@ts-nocheck
import { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { PrimaryRoundedButton } from '../../styles/global/components/dpButtons';
import NextIcon from '@mui/icons-material/ArrowRightAlt';
import LayoutEdgeSelection from './LayoutEdgeSelection';
import { usePlantLayoutStore } from '../../store/PlantLayoutStore';
import { useLayoutStore } from '../../store/LayoutStore2';

function LayoutNodeSelection() {
  const [expanded, setExpanded] = useState('');
  // const [selectedNodes, setSelectedNodes] = useState<any>(null);
  // const [selectedSensors, setSelectedSensors] = useState<any>(null);
  const [loadEdgeSelection, setLoadEdgeSelection] = useState(false);
  const { nodes, edges, selectedNodes, setSelectedNodes, selectedSensors, setSelectedSensors } =
    useLayoutStore();
  console.log(selectedSensors, '*********');

  const handleChange = nodeId => (event, isExpanded) => {
    setExpanded(isExpanded ? nodeId : '');
  };

  const handleNodeSelection = event => {
    const nodeId = event.target.value;
    const checked = event.target.checked;
    let tempSelectedNodes = selectedNodes ? [...selectedNodes] : [];
    if (checked) {
      tempSelectedNodes.push(nodeId);
    } else {
      tempSelectedNodes = tempSelectedNodes.filter((node: any) => node !== nodeId);
    }

    setSelectedNodes(tempSelectedNodes);
  };

  const handleSensorSelection = (event, nodeId, sensor: any) => {
    const sensorId = event.target.value;
    const checked = event.target.checked;
    console.log('here');
    const tempSelectedSensors = selectedSensors ? { ...selectedSensors } : {};
    if (checked) {
      console.log('inside if');
      if (tempSelectedSensors[nodeId]) {
        console.log('inside if 2');
        tempSelectedSensors[nodeId].push({
          sensorId,
          sensorTag: sensor.sensorTag,
          sensorNickName: sensor.sensorNickName,
        });
      } else {
        console.log('inside if else');
        tempSelectedSensors[nodeId] = [
          {
            sensorId,
            sensorTag: sensor.sensorTag,
            sensorNickName: sensor.sensorNickName,
          },
        ];
      }
    } else {
      tempSelectedSensors[nodeId] = tempSelectedSensors[nodeId].filter(
        (sensor: any) => sensor.sensorId !== sensorId
      );
    }

    console.log('tempselected sensor : ', tempSelectedSensors);

    setSelectedSensors(tempSelectedSensors);
  };

  const synchronizeSelectedSensors = () => {
    const updatedSelectedSensors = { ...selectedSensors };

    // Add missing keys with empty arrays
    selectedNodes.forEach(nodeId => {
      if (!updatedSelectedSensors.hasOwnProperty(nodeId)) {
        updatedSelectedSensors[nodeId] = [];
      }
    });

    // Remove keys not present in selectedNodes
    Object.keys(updatedSelectedSensors).forEach(nodeId => {
      if (!selectedNodes.includes(nodeId)) {
        delete updatedSelectedSensors[nodeId];
      }
    });

    setSelectedSensors(updatedSelectedSensors);
  };

  const onNextClick = () => {
    synchronizeSelectedSensors();
    setLoadEdgeSelection(true);
  };

  return (
    <>
      {!loadEdgeSelection && (
        <div>
          <Typography variant="h6" style={{ marginBottom: '1rem' }}>
            Select Nodes:
          </Typography>
          <div
            style={{
              maxHeight: 'calc(100vh - 200px)',
              overflowY: 'auto',
              marginBottom: '1rem',
            }}
          >
            {nodes.map((node: any) => (
              <Accordion
                key={node.id}
                expanded={expanded === node.id}
                onChange={handleChange(node.id)}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      checked={selectedNodes.includes(node.id)}
                      value={node.id}
                      onClick={e => {
                        e.stopPropagation();
                        handleNodeSelection(e);
                      }}
                    />

                    <Typography>{node.data.label}</Typography>
                  </Stack>
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <Typography variant="h6">Select Sensors:</Typography>
                    {node.data.configInfo.sensors?.map((sensor: any) => (
                      <ListItem key={sensor._id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                selectedSensors &&
                                selectedSensors[node.id] &&
                                selectedSensors[node.id].find(
                                  sensorDetail => sensorDetail.sensorId === sensor._id
                                )
                                  ? true
                                  : false
                              }
                              value={sensor?._id}
                              onChange={event => {
                                handleSensorSelection(event, node.id, sensor);
                              }}
                            />
                          }
                          label={
                            sensor?.sensorNickName?.length > 0
                              ? sensor.sensorNickName
                              : sensor.sensorTag
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <PrimaryRoundedButton
            // disabled={!selectedNodes || selectedNodes.length === 0}
            onClick={onNextClick}
          >
            Next
            <NextIcon style={{ justifySelf: 'flex-end' }} />
          </PrimaryRoundedButton>
        </div>
      )}
      {loadEdgeSelection && (
        <LayoutEdgeSelection
          edges={edges}
          selectedNodes={selectedNodes}
          selectedSensors={selectedSensors}
          loadEdgeSelection={loadEdgeSelection}
          setLoadEdgeSelection={setLoadEdgeSelection}
        />
      )}
    </>
  );
}

export default LayoutNodeSelection;
