import { Stack, Box, Checkbox, TextField } from '@mui/material';

export default function MapPlantConfig(props: any) {
  const { plantConfig, handleChange, handlePlantPropertyChange } = props;

  const handleCheckBoxChange = (checked: boolean) => {
    handleChange(checked);
  };
  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center">
        <Checkbox
          checked={plantConfig.showTickets || false}
          onChange={event => {
            handleCheckBoxChange(event.target.checked);
          }}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Box>Show Tickets In Plant Dialog</Box>
      </Stack>
      <TextField
        id="outlined-basic"
        label="Plant Ai Helper Text"
        variant="outlined"
        type="text"
        fullWidth
        multiline
        rows={5}
        value={plantConfig.aiHelperText || ''}
        onChange={event => {
          handlePlantPropertyChange('aiHelperText', event.target.value);
        }}
      />
    </Stack>
  );
}
