import {
  Stack,
  Box,
  TextField,
  FormLabel,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';

export default function MapMetricConfig(props: any) {
  const {
    sensorConfig,
    changeMetricProperty,
    handleSensorValueTypeCustPropertyChange,
    mapFilters,
    changeMetricFilterProperty,
    handleMetricFilterRangeChange,
  } = props;
  const valueTypes = ['raw', 'avg', 'min', 'max', 'timeWeightedSum', 'cumulative'];

  const getMapFilterOptions = () => {
    return mapFilters
      ?.filter((filter: any) => {
        return filter.filterType == 'rangeType';
      })
      .map((filter: any) => {
        return filter.name;
      });
  };

  return (
    <Stack direction="column" gap={0.5}>
      <FormLabel style={{ marginBottom: '10px' }} component="legend">
        Select map filter this sensor represents
      </FormLabel>
      <Autocomplete
        // multiple
        id="tags-filled"
        options={getMapFilterOptions()}
        // getOptionLabel={option => option.name}
        defaultValue={mapFilters?.[0]?.name}
        filterSelectedOptions
        value={sensorConfig?.filtersConfig?.representsFiltersForPlant?.[0] || ''}
        onChange={(e, newValue) => {
          changeMetricFilterProperty([newValue]);
        }}
        renderInput={params => (
          <TextField
            {...params}
            // label="Select map filter this sensor represents"
            placeholder=""
          />
        )}
      />
      <FormLabel style={{ marginBottom: '10px' }} component="legend">
        * select the type of value you want to see
      </FormLabel>
      <Autocomplete
        multiple
        id="tags-filled"
        options={valueTypes}
        // getOptionLabel={option => option.title}
        defaultValue={[valueTypes[0]]}
        filterSelectedOptions
        value={sensorConfig['valueTypes'] || ['raw']}
        onChange={(e, newValue) => {
          changeMetricProperty('valueTypes', newValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            // label="value types you wanna see"
            placeholder=""
          />
        )}
      />
      <Accordion elevation={0}>
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          // expandIcon={<ExpandMoreIcon />}
          expandIcon={<SettingsIcon fontSize="small" />}
        >
          Range Settings
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" gap={2} alignItems="center">
            <Box sx={{ textWrap: 'nowrap' }}>Good Range </Box>
            <TextField
              id="outlined-basic"
              label="Min"
              variant="outlined"
              type="number"
              fullWidth
              value={
                sensorConfig['filtersConfig']['filtersRangeConfig']?.['goodRange']?.['min'] || ''
              }
              onChange={event => {
                handleMetricFilterRangeChange('goodRange', 'min', event.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Max"
              variant="outlined"
              type="number"
              fullWidth
              value={
                sensorConfig['filtersConfig']['filtersRangeConfig']?.['goodRange']?.['max'] || ''
              }
              onChange={event => {
                handleMetricFilterRangeChange('goodRange', 'max', event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Box sx={{ textWrap: 'nowrap' }}>Warning Range</Box>
            <TextField
              id="outlined-basic"
              label="Min"
              variant="outlined"
              type="number"
              fullWidth
              value={
                sensorConfig['filtersConfig']['filtersRangeConfig']?.['warningRange']?.['min'] || ''
              }
              onChange={event => {
                handleMetricFilterRangeChange('warningRange', 'min', event.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Max"
              variant="outlined"
              type="number"
              fullWidth
              value={
                sensorConfig['filtersConfig']['filtersRangeConfig']?.['warningRange']?.['max'] || ''
              }
              onChange={event => {
                handleMetricFilterRangeChange('warningRange', 'max', event.target.value);
              }}
            />
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Box sx={{ textWrap: 'nowrap' }}>Danger Range</Box>
            <TextField
              id="outlined-basic"
              label="Min"
              variant="outlined"
              type="number"
              fullWidth
              value={
                sensorConfig['filtersConfig']['filtersRangeConfig']?.['dangerRange']?.['min'] || ''
              }
              onChange={event => {
                handleMetricFilterRangeChange('dangerRange', 'min', event.target.value);
              }}
            />
            <TextField
              id="outlined-basic"
              label="Max"
              variant="outlined"
              type="number"
              fullWidth
              value={
                sensorConfig['filtersConfig']['filtersRangeConfig']?.['dangerRange']?.['max'] || ''
              }
              onChange={event => {
                handleMetricFilterRangeChange('dangerRange', 'max', event.target.value);
              }}
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        // style={styles.metricAdvancedSettingAccordion}
        elevation={0}
        TransitionProps={{
          mountOnEnter: true,
        }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          // expandIcon={<ExpandMoreIcon />}
          expandIcon={<SettingsIcon fontSize="small" />}
        >
          Advanced Settings
        </AccordionSummary>
        <AccordionDetails
        //  sx={styles.metricAdvancedSettingAccordionDetails}
        >
          <TextField
            type="text"
            value={sensorConfig?.['valueTypeCust']?.['raw']?.nickName || ''}
            variant="standard"
            label="raw"
            placeholder="nickname for raw value"
            multiline
            inputProps={{
              step: '0.01',
              rows: 1,
            }}
            fullWidth
            onChange={e => {
              handleSensorValueTypeCustPropertyChange('raw', 'nickName', e.target.value);
            }}
          />
          <TextField
            type="text"
            value={sensorConfig?.['valueTypeCust']?.['avg']?.nickName || ''}
            variant="standard"
            label="avg"
            placeholder="nickname for avg value"
            multiline
            inputProps={{
              step: '0.01',
              rows: 1,
            }}
            fullWidth
            onChange={e => {
              handleSensorValueTypeCustPropertyChange('avg', 'nickName', e.target.value);
            }}
          />
          <TextField
            type="text"
            value={sensorConfig?.['valueTypeCust']?.['min']?.nickName || ''}
            variant="standard"
            label="min"
            placeholder="nickname for min value"
            multiline
            inputProps={{
              step: '0.01',
              rows: 1,
            }}
            fullWidth
            onChange={e => {
              handleSensorValueTypeCustPropertyChange('min', 'nickName', e.target.value);
            }}
          />
          <TextField
            type="text"
            value={sensorConfig?.['valueTypeCust']?.['max']?.nickName || ''}
            variant="standard"
            label="max"
            placeholder="nickname for max value"
            multiline
            inputProps={{
              step: '0.01',
              rows: 1,
            }}
            fullWidth
            onChange={e => {
              handleSensorValueTypeCustPropertyChange('max', 'nickName', e.target.value);
            }}
          />
          <TextField
            type="text"
            value={sensorConfig?.['valueTypeCust']?.['cumulative']?.nickName || ''}
            variant="standard"
            label="cum"
            placeholder="nickname for cumulative value"
            multiline
            inputProps={{
              step: '0.01',
              rows: 1,
            }}
            fullWidth
            onChange={e => {
              handleSensorValueTypeCustPropertyChange('cumulative', 'nickName', e.target.value);
            }}
          />
          <TextField
            type="text"
            value={sensorConfig?.['valueTypeCust']?.['timeWeightedSum']?.nickName || ''}
            variant="standard"
            label="time weighted sum"
            placeholder="nickname for tws value"
            multiline
            inputProps={{
              step: '0.01',
              rows: 1,
            }}
            fullWidth
            onChange={e => {
              handleSensorValueTypeCustPropertyChange(
                'timeWeightedSum',
                'nickName',
                e.target.value
              );
            }}
          />
          <TextField
            type="number"
            value={sensorConfig?.metricOrder}
            variant="standard"
            label="metricOrder"
            placeholder="Metric Order"
            inputProps={{}}
            fullWidth
            onChange={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
              changeMetricProperty('metricOrder', e.target.value);
            }}
          />
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
}
