//@ts-nocheck
import React from 'react';
import clsx from 'clsx';
import { animated, useSpring } from '@react-spring/web';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import {
  unstable_useTreeItem2 as useTreeItem2,
  UseTreeItem2Parameters,
} from '@mui/x-tree-view/useTreeItem2';
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { Stack, Tooltip } from '@mui/material';
import { Neutrals, Primary } from '../../../../core-components/theme/color-palette/colors';
import { getIconFromFileType } from '../../../../utils/getIcon.utils';
import isIndeterminate from '../../../../utils/isIndetermine';
import { useTreeStore } from '../../../../store/TreeStore';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[400],
  position: 'relative',
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3),
  },
})) as unknown as typeof TreeItem2Root;

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  height: '52px',
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  backgroundColor: 'transparent',
  [`& .${treeItemClasses.iconContainer}`]: {
    marginRight: theme.spacing(2),
  },
  [`&.Mui-expanded `]: {
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '16px',
      top: '44px',
      height: 'calc(100% - 48px)',
      width: '1.5px',
    },
  },
  '&:hover': {
    backgroundColor: Primary[50],
    borderRadius: '0px',
  },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
  color: 'inherit',
  ml: 1,
  fontSize: '14px',
  textOverflow: 'ellipsis',
  fontWeight: 400,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}) as unknown as typeof Typography;

interface CustomLabelProps {
  children: string;
  icon?: React.ElementType;
  expandable?: boolean;
}

function CustomLabel({ icon: Icon, expandable, children = '', ...other }: CustomLabelProps) {
  //@ts-ignore
  const [name, description] = children.split('-');
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.5em',
      }}
    >
      <Tooltip title={children}>
        <Stack
          sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', overflow: 'hidden' }}
          spacing={2}
          direction="row"
        >
          {Icon && (
            <Box
              component={Icon}
              className="labelIcon"
              color="inherit"
              sx={{ mr: 1, width: '16px', height: '16px' }}
            />
          )}
          <StyledTreeItemLabelText
            variant="h4"
            noWrap
            gap={1}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography color={Primary[900]}>{name}</Typography> -{' '}
            <Typography sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }} color={Neutrals[400]}>
              {description}
            </Typography>
          </StyledTreeItemLabelText>
        </Stack>
      </Tooltip>
    </TreeItem2Label>
  );
}

const isExpandable = (reactChildren: React.ReactNode) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, 'rootRef'>,
    Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> {}

const CustomUserGroupTreeItem = React.forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { id, itemId, label, disabled, children, ...other } = props;
  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });
  // @ts-ignore
  const selectedIds = useTreeStore(state => state.ids);
  const item = publicAPI.getItem(itemId);
  const expandable = isExpandable(children);
  let icon = getIconFromFileType(item.type);
  return (
    // @ts-ignore
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)}>
        <CustomTreeItemContent
          sx={{
            marginBottom: '2px',
          }}
          {...getContentProps({
            className: clsx('content', {
              'Mui-expanded': status.expanded,
              'Mui-selected': status.selected,
              'Mui-focused': status.focused,
              'Mui-disabled': status.disabled,
            }),
          })}
        >
          <TreeItem2Checkbox
            disableRipple
            sx={{ pl: 6 }}
            indeterminate={isIndeterminate(item, selectedIds)}
            {...getCheckboxProps()}
          />
          <CustomLabel
            icon={icon}
            expandable={expandable && status.expanded}
            {...getLabelProps()}
          />
          <TreeItem2IconContainer {...getIconContainerProps()} sx={{ paddingRight: '12px' }}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
        </CustomTreeItemContent>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

export default CustomUserGroupTreeItem;
