//@ts-nocheck
import { Button, Typography } from "@mui/material";
import classes from "../../styles/PlantVisualisationStyle.module.css";
import EquipmentSensors from "./EquipmentSensors";
import SettingsIcon from "@mui/icons-material/Settings";
import ControlPrompt from "../../../BiDirectionalPopups/ControlPrompt";
import { useEffect, useRef, useState } from "react";
import { checkBiDirectionalLoadingStatus } from "../../../../services/BiDirectional";
import { useParams } from "react-router-dom";
import LockedPopup from "../../../BiDirectionalPopups/LockedPopup";
import { usePlantLayoutStore } from "../../../../store/PlantLayoutStore";
// import Submersible from '../../../../assets/PlantLayoutDiagram/Submersible.svg';

const EquipmentNodeDiagram = ({
  id: equipmentId,
  biSensorId,
  biSensorStatus,
  LTSensor,
  LTSensorValue,
  OnOffSensorStatus,
  parent,
  equipmentName,
  equipmentImage,
  imageControlId,
  data,
  showAllSensors,
  setShowAllSensors,
  showLimitedSensors,
  showSettings,
}: any) => {
  const isTank = equipmentId.toLowerCase().includes("tank") ? true : false;
  const EquipmentImageId =
    parent === "Dialog" ? `svgDialog${equipmentId}` : `svg${equipmentId}`;
  const EquipmentSvgRef = useRef(null);
  const equipmentNickName = data.nickName || equipmentName;

  const [loadingStatus, setLoadingStatus] = useState<boolean>(false);
  const {
    plantId,
    setBiSensorId,
    setBiSensorStatus,
    setShowLockedPopup,
    setShowControlPrompt,
    setEquipmentNickName,
  } = usePlantLayoutStore();

  const fetchBiDirectionalLoadingStatus = async () => {
    setBiSensorId(biSensorId);
    setBiSensorStatus(biSensorStatus);
    setEquipmentNickName(equipmentNickName);
    const tempLoadingStatus = await checkBiDirectionalLoadingStatus(plantId!);
    setLoadingStatus(tempLoadingStatus);
    if (tempLoadingStatus === true) {
      setShowLockedPopup(true);
    } else {
      setShowControlPrompt(true);
    }
  };

  useEffect(() => {
    try {
      // Submersible image control id: e76krfDAeol1
      // const imageControlId = "e76krfDAeol1";

      setTimeout(() => {
        const object = EquipmentSvgRef.current;
        const contentDocument = object ? object.contentDocument : null;
        const svg = contentDocument
          ? contentDocument.getElementById(imageControlId)
          : null;
        const script = svg ? contentDocument.querySelector("script") : null;

        if (object && contentDocument && svg && script) {
          if (isTank) {
            EquipmentSvgRef.current.contentDocument
              .getElementById(imageControlId)
              .svgatorPlayer.seek(LTSensorValue);
          } else {
            if (OnOffSensorStatus) {
              EquipmentSvgRef.current.contentDocument
                .getElementById(imageControlId)
                .svgatorPlayer.play();
            }
          }
        }
      }, 5000);
    } catch (err) {}
  }, [EquipmentSvgRef.current]);

  return (
    <div
      className={
        showAllSensors ? classes.EquipmentNodeInDialog : classes.EquipmentNode
      }
    >
      <div className={classes.NodeLeftSection}>
        <object
          ref={EquipmentSvgRef}
          id={EquipmentImageId}
          type="image/svg+xml"
          data={equipmentImage}
          className={classes.EquipmentImage}
        ></object>

        {/* <object
          ref={EquipmentSvgRef}
          id={EquipmentImageId}
          type="image/svg+xml"
          data={Submersible}
          className={classes.EquipmentImage}
        ></object>  */}

        <Typography variant="h6">{equipmentNickName}</Typography>
      </div>
      <div className={classes.NodeRightSection}>
        {showAllSensors &&
          data.sensors.map((sensor: any, index: number) => (
            <EquipmentSensors
              key={sensor.sensorId}
              sensor={sensor}
              LTSensor={LTSensor}
            />
          ))}
        {showLimitedSensors && (
          <>
            {data.sensors.slice(0, 4).map((sensor: any, index: number) => (
              <EquipmentSensors
                key={sensor.sensorId}
                sensor={sensor}
                LTSensor={LTSensor}
              />
            ))}
            {data.sensors.length ? (
              <Button
                sx={{
                  alignSelf: "center",
                  marginTop: "5px",
                  fontSize: "12px",
                  backgroundColor: "#36A2C7",
                }}
                variant="contained"
                onClick={() => {
                  setShowAllSensors(true);
                }}
              >
                {data.sensors.length > 4 ? "More Details" : "Expand"}
              </Button>
            ) : null}
          </>
        )}
      </div>
      {showSettings && (
        <>
          <Button
            className={classes.SettingsButton}
            disabled={!data.sensors[0]?.hasOwnProperty("value")}
            onClick={() => {
              fetchBiDirectionalLoadingStatus();
            }}
          >
            <SettingsIcon />
          </Button>
        </>
      )}
    </div>
  );
};

export default EquipmentNodeDiagram;
