export default function ModuleIcon({ width = '16px', color = '#193458' }) {
  return (
    <svg
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: width }}
    >
      <path
        d="M8.56667 11.25C8.89804 11.25 9.16667 11.5186 9.16667 11.85V17.3167C9.16667 17.648 8.89804 17.9167 8.56667 17.9167H3.1C2.76863 17.9167 2.5 17.648 2.5 17.3167V11.85C2.5 11.5186 2.76863 11.25 3.1 11.25H8.56667ZM9.48803 2.50444C9.72199 2.1216 10.278 2.1216 10.512 2.50444L14.0255 8.2538C14.2698 8.65361 13.9821 9.16667 13.5135 9.16667H6.4865C6.01794 9.16667 5.7302 8.65361 5.97453 8.2538L9.48803 2.50444ZM14.5833 10.8333C16.6667 10.8333 18.3333 12.5 18.3333 14.5833C18.3333 16.6667 16.6667 18.3333 14.5833 18.3333C12.5 18.3333 10.8333 16.6667 10.8333 14.5833C10.8333 12.5 12.5 10.8333 14.5833 10.8333Z"
        fill={color}
      />
    </svg>
  );
}
