import { Stack, Typography, Button, Box } from "@mui/material";
import { DashboardComponentStyle as styles } from "./DashboardComponentStyle";
// import Calendar from '../../globalComponents/dateAndTime/Calendar';
// import DigitalTimeSelector from '../../globalComponents/dateAndTime/DigitalTimeSelector';
// import { useRef } from 'react';
// import useOutsideClickDetector from '../../utilities/customHooks/useOutsideClickDetector';
import CalendarAndTime from "../../globalComponents/dateAndTime/CalendarAndTime";
import { useState } from "react";
import useIsMobile from "../../utilities/customHooks/useIsMobile";

// interface IProps {
//   label: string;
//   date: any;
//   time: any;
//   openCalendar: boolean;
//   setOpenCalendar: Function;
//   openTime: boolean;
//   setOpenTime: Function;
//   parent: string;
// }

function SingleDateAndTimePicker({
  label,
  date,
  time,
  openCalendar,
  setOpenCalendar,
  openTime,
  setOpenTime,
  calendarParent,
  timeFrame,
  setTimeFrame,
  setIsBottomBarOpen,
  dashboardDateAndTime,
  dashboardSaveData,
  setDashboardSaveData,
  setParent,
  newDashboardPage,
  setNewDashboardPage,
  setSelectedTimeRange,
  updateGranularityItems
}: any) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? calendarParent : undefined;

  return (
    <Stack direction="column" alignItems="flex-start" spacing={2}>
      <Button
        aria-describedby={id}
        variant="contained"
        sx={styles.dateAndTimeButtonStyle}
        onClick={(event) => {
          if (openCalendar) {
            setOpenCalendar(false);
          } else {
            setOpenCalendar(true);
          }
          setAnchorEl(event.currentTarget);
        }}
      >
        <Typography variant="subtitle1" sx={styles.labelStyle}>
          {label}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Box style={{ whiteSpace: "nowrap" }}>{date}</Box>
          {
            (!useIsMobile({ breakpointValue: "sm" }) && useIsMobile({ breakpointValue: "md" }) ) ?
            <Box style={styles.verticalDividerSm}></Box> : <Box style={styles.verticalDivider}></Box>
          }
          {time}
        </Stack>
      </Button>
      {openCalendar && (
            <CalendarAndTime
              id={id}
              open={open}
              anchorEl={anchorEl}
              handleClose={handleClose}
              time={time}
              openCalendar={openCalendar}
              setOpenCalendar={setOpenCalendar}
              setOpenTime={setOpenTime}
              calendarParent={calendarParent}
              timeFrame={timeFrame}
              setTimeFrame={setTimeFrame}
              setIsBottomBarOpen={setIsBottomBarOpen}
              dashboardSaveData={dashboardSaveData}
              setDashboardSaveData={setDashboardSaveData}
              dashboardDateAndTime={dashboardDateAndTime}
              setParent={setParent}
              newDashboardPage={newDashboardPage}
              setNewDashboardPage={setNewDashboardPage}
              setSelectedTimeRange={setSelectedTimeRange}
              updateGranularityItems={updateGranularityItems}
            />
          )}
    </Stack>
  );
}

export default SingleDateAndTimePicker;
