//@ts-nocheck
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Box,
  Paper,
  Tabs,
  AppBar,
  Tab,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
} from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import clsx from 'clsx';
import DoneIcon from '@mui/icons-material/Done';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { Tooltip, Button } from '@mui/material';
import UserSelectPopup from '../components/UserSelectPopup/UserSelectPopup';
import moment from 'moment';
import EventIcon from '@mui/icons-material/Event';
import useStyles from '../styles/task-list-styles';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';

import oneTimeTaskIcon from '../constants/Images/oneTimeTask.png';
import overDueIcon from '../constants/Images/overDue.png';
import fixedTaskIcon from '../constants/Images/fixedTask.png';
// import alertTaskIcon from "../constants/Images/alertTask.png";
import criticalIcon from '../constants/Images/critical.png';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { fetchTasksList, getUserDetails } from '../services/taskList';
import { changeTaskStatusService } from '../services/expandedView';
import useAuth from '../utilities/useAuth';
import PerformAction from '../globalComponents/dialogs/PerformAction';
import useUserStore from '../store/UserStore';

var actionType = 'Complete Task';

export default function TaskList() {
  const classes = useStyles();

  const navigate = useNavigate();
  let storageLanguage = localStorage.getItem('language');
  const [language, setLanguage] = useState(storageLanguage);
  const [pendingCount, setPendingCount] = useState(0);
  const [overDueCount, setoverDueCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [completedAfterOverDueCount, setCompletedAfterOVerDueCount] = useState(0);
  const [pendingTasks, setPendingTasks] = useState([]);
  const [overDueTasks, setOverDueTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [completedAfteroverDueTasks, setCompletedAfterOverDueTasks] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [taskId, setTaskId] = useState('');
  // const allowAction = JSON.parse(localStorage.getItem("allowAction"));
  const allowAction = useUserStore().allowAction;
  const { role } = useAuth();
  // const id = localStorage.getItem("userid");
  // const checkInPlant = localStorage.getItem("checkInPlant"); // its plant name from which RO checked in

  // added code for tab
  const [tabvalue, setTabValue] = useState(
    localStorage.getItem('activeTabOfTaskList')
      ? parseInt(localStorage.getItem('activeTabOfTaskList'), 10)
      : 0
  );
  const [index, setIndex] = useState(0);

  // for options
  const [roleParameters, setRoleParameters] = useState([]);
  const [plantParameters, setPlantParameters] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  const [plantTypes, setPlantTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [taskRole, setTaskRole] = useState('');
  const [path, setPath] = useState('tasks');
  const [loadmoredis, setloadmoredis] = useState(false);
  const [taskParameters, setTaskParameters] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);
  const [taskPlantName, setTaskPlantName] = useState('');
  const [showPerformActionModal, setShowPerformActionModal] = useState(false);
  const [prcoessing, setProcessing] = useState(false);

  const handleChange2 = (event: any) => {
    const {
      target: { value },
    } = event;
    setRoleParameters([]);
    setRoleParameters(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChange3 = (event: any) => {
    const {
      target: { value },
    } = event;
    // setRoleParameters([]);
    setPlantParameters(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeForTaskParameters = (event: any) => {
    const {
      target: { value },
    } = event;
    // setRoleParameters([]);
    setTaskParameters(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const getPlantInitials = (name: any) => {
    var num_of_letters = 2;
    var namearr;
    if (name != null) namearr = name.split(' ');
    var initials = '';
    if (name != null) namearr = name.split(' ');
    var initials = '';
    if (namearr != null)
      namearr.forEach(element => {
        initials = initials + element[0];
      });
    return initials.substring(0, num_of_letters);
  };

  useEffect(() => {
    if (tabvalue === 0) {
      fetchTasks('tasks', 0);
    } else {
      fetchTasks('alltasks', 0);
    }
    if (document.getElementById('role_form') != null) {
      document.getElementById('role_form').style.display = 'none';
    }
    getUserDetails(localStorage.getItem('userid')).then(data => {
      if (data.user.language) {
        localStorage.setItem('language', data.user.language);
        setLanguage(data.user.language);
      } else {
        localStorage.setItem('language', 'English');
        setLanguage('English');
      }
    });
  }, []);

  const fetchTasks = async (api: any, ind: any) => {
    setLoading(true);
    fetchTasksList(api, ind)
      .then(data => {
        console.log(data);
        setPendingCount(data.body.pendingCount);
        setoverDueCount(data.body.overDueCount);
        setCompletedCount(data.body.completedCount);
        setCompletedAfterOVerDueCount(0);
        setPendingTasks(data.body.tasks.pendingTask);
        setOverDueTasks(data.body.tasks.overDueTask);
        setCompletedAfterOverDueTasks(data.body.tasks.completedAfterOverDueTask);
        setCompletedAfterOverDueTasks(data.body.tasks.completedAfterOverDueTask);
        setCompletedTasks(data.body.tasks.completedTask);
        setPlantTypes(data.body.Plants);
        setRoleTypes(data.body.Roles);
        setRoleParameters(data.body.Roles);
        setPlantParameters(data.body.Plants);
        setloadmoredis(data.body.next);
        setTaskParameters(['Alert Task', 'Fixed Task', 'One Time Task']);
        setTaskTypes(['Alert Task', 'Fixed Task', 'One Time Task']);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
    setOpenPopup(false);
    setTaskId('');
    setTaskId('');
  };

  const loadMore = () => {
    setLoading(true);
    fetchTasksList(path, index + 1)
      .then(data => {
        setPendingCount(data.body.pendingCount);
        setoverDueCount(data.body.overDueCount);
        setCompletedCount(data.body.completedCount);
        setCompletedAfterOVerDueCount(0);
        setPendingTasks([...pendingTasks, ...data.body.tasks.pendingTask]);
        setCompletedAfterOverDueTasks([
          ...completedAfteroverDueTasks,
          ...data.body.tasks.completedAfterOverDueTask,
        ]);
        setCompletedTasks([...completedTasks, ...data.body.tasks.completedTask]);
        setPlantTypes([...new Set([...data.body.Plants, ...plantTypes])]);
        setRoleTypes([...new Set([...roleTypes, ...data.body.Roles])]);
        setRoleParameters([...new Set([...roleTypes, ...data.body.Roles])]);
        setPlantParameters([...new Set([...data.body.Plants, ...plantTypes])]);
        setIndex(index + 1);

        setloadmoredis(data.body.next);
        setLoading(false);
      })

      .catch(err => {
        setLoading(false);
      });
    setOpenPopup(false);
    setTaskId('');
    setTaskId('');
  };

  const openPopupBox = (task, toUser) => {
    if (role === 'Operator Group' || role === 'Rotating Operator') {
      setOpenPopup(true);
      setTaskId(task.taskId);
      setTaskRole(toUser);
      setTaskPlantName(task.plantName);
    }
    // else if(role==="Rotating Operator" && task.plantName != checkInPlant){
    //   console.log("task.plantName : ", task.plantName);
    //   console.log(checkInPlant)
    //   setOpenPopup(true);
    //   setTaskId(task.taskId);
    //   setTaskRole(toUser)
    //   setTaskPlantName(task.plantName)
    // }
    else {
      changeTaskStatusService({
        taskId: taskId,
        status: 'Completed',
        completedBy: userId,
        type: 'Complete Task',
      })
        .then(data => {
          fetchTasks('tasks', index);
        })
        .catch(err => {});
    }
  };

  // code added for tab
  const handleChange = () => {
    if (tabvalue === 1) {
      setTabValue(0);
      setPath('tasks');
      setIndex(0);
      fetchTasks('tasks', 0);
      if (document.getElementById('role_form'))
        document.getElementById('role_form').style.display = 'none';
    } else {
      setTabValue(1);
      setPath('alltasks');
      setIndex(0);
      fetchTasks('alltasks', 0);
      if (document.getElementById('role_form') != null)
        document.getElementById('role_form').style.display = 'inline-block';
    }
  };

  useEffect(() => {
    localStorage.setItem('activeTabOfTaskList', tabvalue);
  }, [tabvalue]);

  return (
    <div className="">
      <LayoutComponent>
        {/* tab ui code */}
        <Grid item xs={12}>
          <Box m={2}>
            <AppBar position="relative" style={{ background: 'rgb(97,152,201)' }}>
              <Tabs
                value={tabvalue}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="My tasks" onClick={() => {}} />
                <Tab label="All tasks" />
              </Tabs>
            </AppBar>
          </Box>
        </Grid>

        {/* tab ui element done */}

        {loading ? (
          <div style={{ textAlign: 'center', marginTop: '100px' }}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Grid className={classes.root} spacing={2}>
              <Grid item xs={12} m={1}>
                <Box p={4}>
                  <Grid container spacing={4}>
                    <Grid item>
                      <Paper className={clsx(classes.paper, classes.overdueTasks)} elevation={4}>
                        <Box p={2} borderRadius="5%">
                          <div className={classes.taskHeader}>
                            Overdue Tasks : <strong>{overDueCount}</strong>
                          </div>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={clsx(classes.paper, classes.pendingTasks)} elevation={4}>
                        <Box p={2} borderRadius="5%">
                          <div className={classes.taskHeader}>
                            {' '}
                            Pending Tasks : <strong>{pendingCount}</strong>
                          </div>
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item>
                      <Paper className={clsx(classes.paper, classes.completedTasks)} elevation={4}>
                        <Box p={2} borderRadius="5%">
                          <div className={classes.taskHeader}>
                            Completed Tasks :{' '}
                            <strong>{completedCount + completedAfterOverDueCount}</strong>
                          </div>
                        </Box>
                      </Paper>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              {/* experiment2 */}
              <div>
                <div style={{}}>
                  {' '}
                  <FilterAltIcon
                    style={{ fontSize: 30, position: 'relative', top: '25%' }}
                  ></FilterAltIcon>
                  {roleTypes != null ? (
                    <FormControl sx={{ m: 1, width: 300 }} id="role_form">
                      <InputLabel style={{ paddingLeft: 20 }} id="demo-multiple-name-label">
                        Role
                      </InputLabel>
                      <InputLabel id="demo-multiple-name-label">Role</InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={roleParameters}
                        onChange={handleChange2}
                        input={<OutlinedInput label="Role" />}
                        // MenuProps={MenuProps}
                      >
                        {roleTypes.map(roleTypes => (
                          <MenuItem
                            key={roleTypes}
                            value={roleTypes}
                            // style={getStyles(name, personName, theme)}
                          >
                            {getPlantInitials(roleTypes)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : null}
                  {plantTypes != null ? (
                    <FormControl
                      sx={{ m: 1, width: 300 }}
                      style={{ paddingLeft: 10, marginBottom: '10px' }}
                    >
                      <InputLabel style={{ paddingLeft: 25 }} id="demo-multiple-name-label">
                        Plant
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={plantParameters}
                        onChange={handleChange3}
                        input={<OutlinedInput label="Role" />}
                        // MenuProps={MenuProps}
                      >
                        {plantTypes.map(plantType => (
                          <MenuItem
                            key={plantType}
                            value={plantType}
                            // style={getStyles(name, personName, theme)}
                          >
                            {plantType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : null}
                  {taskTypes != null ? (
                    <FormControl sx={{ m: 1, width: 300 }}>
                      <InputLabel style={{ paddingLeft: 20 }} id="demo-multiple-name-label">
                        Tasks
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        value={taskParameters}
                        onChange={handleChangeForTaskParameters}
                        input={<OutlinedInput label="Role" />}
                        // MenuProps={MenuProps}
                      >
                        {taskTypes.map(taskTypes => (
                          <MenuItem
                            key={taskTypes}
                            value={taskTypes}
                            // style={getStyles(name, personName, theme)}
                          >
                            {getPlantInitials(taskTypes)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  ) : null}
                </div>
              </div>
              {/* experiment2 close */}

              <Grid item xs={12}>
                {pendingTasks.map(task => {
                  if (
                    plantParameters.indexOf(task.plantName) !== -1 &&
                    roleParameters.indexOf(task.toUser) !== -1 &&
                    taskParameters.indexOf(task.taskType) !== -1
                  )
                    return (
                      <>
                        <Box m={2} pt={2} pb={2}>
                          <Paper elevation={10}>
                            <Box p={1}>
                              <Grid container xs={12} spacing={1} className={classes.dueDateLabel}>
                                <Grid item xs={10}>
                                  <Box
                                    onClick={() => {
                                      navigate(`/expanded-view/${task.taskId}`);
                                    }}
                                  >
                                    <Box p={0.25}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          width: '120%',
                                        }}
                                      >
                                        <Avatar
                                          sx={{
                                            bgcolor: 'rgb(98,154,203)',
                                            width: 25,
                                            height: 25,
                                          }}
                                          style={{ fontSize: '11px' }}
                                        >
                                          {getPlantInitials(task.plantName)}
                                        </Avatar>
                                        <Box p={0.25} style={{ marginLeft: 'auto' }}>
                                          <div>
                                            {task.taskType === 'Alert Task' ? (
                                              <Tooltip title="Alert Task" aria-label="add">
                                                {/* <WarningIcon style={{ color: "#FF0000" }}/> */}
                                                {/* <img src={alertTaskIcon} style={{width : "25px" , height : "25px" }}></img> */}
                                                <NotificationsActiveIcon
                                                  style={{ color: '#519BD0' }}
                                                />
                                              </Tooltip>
                                            ) : null}
                                            {task.taskType === 'Fixed Task' ? (
                                              <Tooltip title="Fixed Task" aria-label="add">
                                                {/* <UpdateIcon style={{ color: "#519BD0" }}/> */}
                                                <img
                                                  src={fixedTaskIcon}
                                                  style={{
                                                    width: '25px',
                                                    height: '25px',
                                                  }}
                                                ></img>
                                              </Tooltip>
                                            ) : null}
                                            {task.taskType === 'One Time Task' ? (
                                              <Tooltip title="One Time Task" aria-label="add">
                                                {/* <TaskIcon style={{ color: "#519BD0" }}/> */}
                                                <img
                                                  src={oneTimeTaskIcon}
                                                  style={{
                                                    width: '25px',
                                                    height: '25px',
                                                  }}
                                                ></img>
                                              </Tooltip>
                                            ) : null}
                                            {task.critical ? (
                                              <Tooltip title="critical" aria-label="add">
                                                {/* <ErrorIcon  style={{ color: "#EB5A46" }}></ErrorIcon> */}
                                                <img
                                                  src={criticalIcon}
                                                  style={{
                                                    width: '25px',
                                                    height: '25px',
                                                  }}
                                                ></img>
                                              </Tooltip>
                                            ) : null}
                                          </div>
                                        </Box>
                                      </div>

                                      <div className={classes.heading}>
                                        {language === 'English' ? (
                                          <>{task.heading.eng}</>
                                        ) : (
                                          <>{task.heading.hindi}</>
                                        )}
                                      </div>
                                    </Box>
                                    <Box pt={1} pb={1}>
                                      <div className={classes.dueDate}>
                                        <span className={classes.dueDateLabel}>
                                          <EventIcon fontSize="small" />
                                          {moment(task.overDueTime, 'DD:MM:yyyy HH:mm:ss').format(
                                            'llll'
                                          )}
                                        </span>
                                      </div>
                                    </Box>

                                    <Box p={0.25}>
                                      <div style={{ fontSize: 12 }}>
                                        {tabvalue == 1 ? 'Role: ' + task.toUser : ''}
                                      </div>
                                      <div style={{ fontSize: 12 }}>Plant: {task.plantName}</div>
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid item xs={2}>
                                  <Tooltip title="Mark Task as Completed" aria-label="add">
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor: '#519BD0',
                                      }}
                                      onClick={e => {
                                        if (allowAction) {
                                          actionType = 'Complete Task';
                                          openPopupBox(task, task.toUser);
                                        } else {
                                          setShowPerformActionModal(true);
                                        }
                                      }}
                                    >
                                      <DoneIcon />
                                    </Button>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          </Paper>
                        </Box>
                      </>
                    );
                })}
                {overDueTasks.map(task => {
                  if (plantParameters != null && roleParameters != null)
                    if (
                      plantParameters.indexOf(task.plantName) !== -1 &&
                      roleParameters.indexOf(task.toUser) !== -1 &&
                      taskParameters.indexOf(task.taskType) !== -1
                    )
                      return (
                        <>
                          <Box m={2} pt={2} pb={2}>
                            <Paper elevation={10}>
                              <Box p={4}>
                                <Grid
                                  container
                                  xs={12}
                                  spacing={1}
                                  className={classes.dueDateLabel}
                                >
                                  <Grid item xs={10}>
                                    <Box
                                      onClick={() => {
                                        navigate(`/expanded-view/${task.taskId}`);
                                      }}
                                    >
                                      <Box p={0.25}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '120%',
                                          }}
                                        >
                                          <Avatar
                                            sx={{
                                              bgcolor: 'rgb(98,154,203)',
                                              width: 25,
                                              height: 25,
                                            }}
                                            style={{ fontSize: '11px' }}
                                          >
                                            {getPlantInitials(task.plantName)}
                                          </Avatar>
                                          <Box p={0.25} style={{ marginLeft: 'auto' }}>
                                            <div style={{}}>
                                              {task.taskType === 'Alert Task' ? (
                                                <Tooltip title="Alert Task" aria-label="add">
                                                  {/* <WarningIcon style={{ color: "#FF0000" }}/> */}
                                                  {/* <img src={alertTaskIcon} style={{width : "25px" , height : "25px" }}></img> */}
                                                  <NotificationsActiveIcon
                                                    style={{ color: '#519BD0' }}
                                                  />
                                                </Tooltip>
                                              ) : null}
                                              {task.taskType === 'Fixed Task' ? (
                                                <Tooltip title="Fixed Task" aria-label="add">
                                                  {/* <UpdateIcon style={{ color: "#519BD0" }}/> */}
                                                  <img
                                                    src={fixedTaskIcon}
                                                    style={{
                                                      width: '25px',
                                                      height: '25px',
                                                    }}
                                                  ></img>
                                                </Tooltip>
                                              ) : null}
                                              {task.taskType === 'One Time Task' ? (
                                                <Tooltip title="One Time Task" aria-label="add">
                                                  {/* <TaskIcon style={{ color: "#519BD0" }}/> */}
                                                  <img
                                                    src={oneTimeTaskIcon}
                                                    style={{
                                                      width: '25px',
                                                      height: '25px',
                                                    }}
                                                  ></img>
                                                </Tooltip>
                                              ) : null}
                                              <Tooltip title="overdue" aria-label="add">
                                                <img
                                                  src={overDueIcon}
                                                  style={{
                                                    width: '25px',
                                                    height: '25px',
                                                  }}
                                                ></img>
                                              </Tooltip>
                                              {task.critical ? (
                                                <Tooltip title="critical" aria-label="add">
                                                  {/* <ErrorIcon  style={{ color: "#EB5A46" }}></ErrorIcon> */}
                                                  <img
                                                    src={criticalIcon}
                                                    style={{
                                                      width: '25px',
                                                      height: '25px',
                                                    }}
                                                  ></img>
                                                </Tooltip>
                                              ) : null}
                                            </div>
                                          </Box>
                                        </div>

                                        <div className={classes.heading}>
                                          {language === 'English' ? (
                                            <>{task.heading.eng}</>
                                          ) : (
                                            <>{task.heading.hindi}</>
                                          )}
                                        </div>
                                      </Box>
                                      <Box pt={1} pb={1}>
                                        <div className={classes.dueDate}>
                                          <span className={classes.dueDateLabel}>
                                            <EventIcon fontSize="small" />
                                            {moment(task.overDueTime, 'DD:MM:yyyy HH:mm:ss').format(
                                              'llll'
                                            )}
                                          </span>
                                        </div>
                                      </Box>
                                      <Box p={0.25}>
                                        {/* <div style={{fontSize: 12}}>{tabvalue == 1 ? "Role: " + task.toUser : ""}</div> */}
                                        <div style={{ fontSize: 12 }}>{task.toUser}</div>
                                        <div style={{ fontSize: 12 }}>Plant: {task.plantName}</div>
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid item xs={2}>
                                    <Tooltip title="Mark Task as Completed" aria-label="add">
                                      <Button
                                        variant="contained"
                                        style={{
                                          backgroundColor: '#519BD0',
                                        }}
                                        onClick={e => {
                                          if (allowAction) {
                                            actionType = 'Complete Task';
                                            openPopupBox(task, task.toUser);
                                          } else {
                                            setShowPerformActionModal(true);
                                          }
                                        }}
                                      >
                                        <DoneIcon />
                                      </Button>
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                          </Box>
                        </>
                      );
                })}
                {completedTasks.map(task => {
                  if (
                    plantParameters.indexOf(task.plantName) !== -1 &&
                    roleParameters.indexOf(task.toUser) !== -1 &&
                    taskParameters.indexOf(task.taskType) !== -1
                  )
                    return (
                      <>
                        <Box m={2} pt={2} pb={2}>
                          <Paper elevation={10}>
                            <Box p={1}>
                              <Grid container xs={12} spacing={1} className={classes.dueDateLabel}>
                                <Grid item xs={10}>
                                  <Box
                                    onClick={() => {
                                      navigate(`/expanded-view/${task.taskId}`);
                                    }}
                                  >
                                    <Box p={0.25}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          width: '120%',
                                        }}
                                      >
                                        <Avatar
                                          sx={{
                                            bgcolor: 'rgb(98,154,203)',
                                            width: 25,
                                            height: 25,
                                          }}
                                          style={{ fontSize: '11px' }}
                                        >
                                          {getPlantInitials(task.plantName)}
                                        </Avatar>
                                        <Box p={0.25} style={{ marginLeft: 'auto' }}>
                                          <div>
                                            {task.taskType === 'Alert Task' ? (
                                              <Tooltip title="Alert Task" aria-label="add">
                                                {/* <WarningIcon style={{ color: "#FF0000" }}/> */}
                                                {/* <img src={alertTaskIcon} style={{width : "25px" , height : "25px" }}></img> */}
                                                <NotificationsActiveIcon
                                                  style={{ color: '#519BD0' }}
                                                />
                                              </Tooltip>
                                            ) : null}
                                            {task.taskType === 'Fixed Task' ? (
                                              <Tooltip title="Fixed Task" aria-label="add">
                                                {/* <UpdateIcon style={{ color: "#519BD0" }}/> */}
                                                <img
                                                  src={fixedTaskIcon}
                                                  style={{
                                                    width: '25px',
                                                    height: '25px',
                                                  }}
                                                ></img>
                                              </Tooltip>
                                            ) : null}
                                            {task.taskType === 'One Time Task' ? (
                                              <Tooltip title="One Time Task" aria-label="add">
                                                {/* <TaskIcon style={{ color: "#519BD0" }}/> */}
                                                <img
                                                  src={oneTimeTaskIcon}
                                                  style={{
                                                    width: '25px',
                                                    height: '25px',
                                                  }}
                                                ></img>
                                              </Tooltip>
                                            ) : null}
                                            {task.critical ? (
                                              <Tooltip title="critical" aria-label="add">
                                                {/* <ErrorIcon  style={{ color: "#EB5A46" }}></ErrorIcon> */}
                                                <img
                                                  src={criticalIcon}
                                                  style={{
                                                    width: '25px',
                                                    height: '25px',
                                                  }}
                                                ></img>
                                              </Tooltip>
                                            ) : null}
                                            {/* <Tooltip title="Task is completed" aria-label="add">
                                                <DoneAllIcon  style={{ color: "#70B500" }} onClick={(e)=>{openPopupBox(task.taskId,task.toUser)}}/>
                                                </Tooltip>  */}
                                          </div>
                                        </Box>
                                      </div>

                                      <div className={classes.heading}>
                                        {language === 'English' ? (
                                          <>{task.heading.eng}</>
                                        ) : (
                                          <>{task.heading.hindi}</>
                                        )}
                                      </div>
                                    </Box>
                                    <Box pt={1} pb={1}>
                                      <div className={classes.dueDate}>
                                        <span className={classes.dueDateLabel}>
                                          <EventIcon fontSize="small" />
                                          {moment(task.overDueTime, 'DD:MM:yyyy HH:mm:ss').format(
                                            'llll'
                                          )}
                                        </span>
                                      </div>
                                    </Box>
                                    <Box p={0.25}>
                                      <div style={{ fontSize: 12 }}>
                                        {tabvalue == 1 ? 'Role: ' + task.toUser : ''}
                                      </div>
                                      <div style={{ fontSize: 12 }}>Plant: {task.plantName}</div>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  style={{}}
                                  onClick={e => {
                                    actionType = 'Uncomplete Task';
                                    openPopupBox(task, task.toUser);
                                  }}
                                >
                                  <Tooltip title="Task is completed" aria-label="add">
                                    <DoneAllIcon style={{ color: '#70B500' }} />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Box>
                          </Paper>
                        </Box>
                      </>
                    );
                })}

                {completedAfteroverDueTasks.map(task => {
                  if (plantParameters != null && roleParameters != null)
                    if (
                      plantParameters.indexOf(task.plantName) !== -1 &&
                      roleParameters.indexOf(task.toUser) !== -1 &&
                      taskParameters.indexOf(task.taskType) !== -1
                    )
                      return (
                        <>
                          <Box m={2} pt={2} pb={2}>
                            <Paper elevation={10}>
                              <Box p={4}>
                                <Grid
                                  container
                                  xs={12}
                                  spacing={1}
                                  className={classes.dueDateLabel}
                                >
                                  <Grid item xs={10}>
                                    <Box
                                      onClick={() => {
                                        navigate(`/expanded-view/${task.taskId}`);
                                      }}
                                    >
                                      <Box p={0.25}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            width: '120%',
                                          }}
                                        >
                                          <Avatar
                                            sx={{
                                              bgcolor: 'rgb(98,154,203)',
                                              width: 25,
                                              height: 25,
                                            }}
                                            style={{ fontSize: '11px' }}
                                          >
                                            {getPlantInitials(task.plantName)}
                                          </Avatar>
                                          <Box p={0.25} style={{ marginLeft: 'auto' }}>
                                            <div>
                                              {task.taskType === 'Alert Task' ? (
                                                <Tooltip title="Alert Task" aria-label="add">
                                                  {/* <WarningIcon style={{ color: "#FF0000" }}/> */}
                                                  {/* <img src={alertTaskIcon} style={{width : "25px" , height : "25px" }}></img> */}
                                                  <NotificationsActiveIcon
                                                    style={{ color: '#519BD0' }}
                                                  />
                                                </Tooltip>
                                              ) : null}
                                              {task.taskType === 'Fixed Task' ? (
                                                <Tooltip title="Fixed Task" aria-label="add">
                                                  {/* <UpdateIcon style={{ color: "#519BD0" }}/> */}
                                                  <img
                                                    src={fixedTaskIcon}
                                                    style={{
                                                      width: '25px',
                                                      height: '25px',
                                                    }}
                                                  ></img>
                                                </Tooltip>
                                              ) : null}
                                              {task.taskType === 'One Time Task' ? (
                                                <Tooltip title="One Time Task" aria-label="add">
                                                  {/* <TaskIcon style={{ color: "#519BD0" }}/> */}
                                                  <img
                                                    src={oneTimeTaskIcon}
                                                    style={{
                                                      width: '25px',
                                                      height: '25px',
                                                    }}
                                                  ></img>
                                                </Tooltip>
                                              ) : null}
                                              <Tooltip
                                                title="Task is completed after overdue"
                                                aria-label="add"
                                              >
                                                {/* <PriorityHighIcon style={{ color: "#EB5A46" }}/> */}
                                                <img
                                                  src={overDueIcon}
                                                  style={{
                                                    width: '25px',
                                                    height: '25px',
                                                  }}
                                                ></img>
                                              </Tooltip>
                                              {task.critical ? (
                                                <Tooltip title="critical" aria-label="add">
                                                  {/* <ErrorIcon  style={{ color: "#EB5A46" }}></ErrorIcon> */}
                                                  <img
                                                    src={criticalIcon}
                                                    style={{
                                                      width: '25px',
                                                      height: '25px',
                                                    }}
                                                  ></img>
                                                </Tooltip>
                                              ) : null}
                                              {/* <Tooltip title="Task is completed" aria-label="add">
                                                  <DoneAllIcon  style={{ color: "#70B500",background : "orange" }} onClick={()=>{console.log("done all button clicked"); openPopupBox(task.taskId,task.toUser)}}/>
                                                 </Tooltip> */}
                                            </div>
                                          </Box>
                                        </div>

                                        <div className={classes.heading}>
                                          {language === 'English' ? (
                                            <>{task.heading.eng}</>
                                          ) : (
                                            <>{task.heading.hindi}</>
                                          )}
                                        </div>
                                      </Box>
                                      <Box pt={1} pb={1}>
                                        <div className={classes.dueDate}>
                                          <span className={classes.dueDateLabel}>
                                            <EventIcon fontSize="small" />
                                            {moment(task.overDueTime, 'DD:MM:yyyy HH:mm:ss').format(
                                              'llll'
                                            )}
                                          </span>
                                        </div>
                                      </Box>
                                      <Box p={0.25}>
                                        <div style={{ fontSize: 12 }}>
                                          {tabvalue == 1 ? 'Role: ' + task.toUser : ''}
                                        </div>
                                        <div style={{ fontSize: 12 }}>Plant: {task.plantName}</div>
                                      </Box>
                                    </Box>
                                  </Grid>

                                  <Grid
                                    item
                                    style={{}}
                                    onClick={e => {
                                      actionType = 'Uncomplete Task';
                                      openPopupBox(task, task.toUser);
                                    }}
                                  >
                                    <Tooltip title="Task is completed" aria-label="add">
                                      <DoneAllIcon style={{ color: '#70B500' }} />
                                    </Tooltip>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Paper>
                          </Box>
                        </>
                      );
                })}
                <Button sx={{ span: 'left' }} onClick={loadMore} disabled={loadmoredis}>
                  Load More
                </Button>
              </Grid>
            </Grid>
          </>
        )}
        {openPopup ? (
          <>
            <UserSelectPopup
              type={actionType}
              taskId={taskId}
              toUser={taskRole}
              handleClose={() => {
                setOpenPopup(false);
              }}
              tabValue={tabvalue}
              taskPlantName={taskPlantName}
              refresh={path => {
                fetchTasks(path, index);
              }}
            />
          </>
        ) : null}
        {showPerformActionModal && (
          <PerformAction
            showPerformActionModal={showPerformActionModal}
            setShowPerformActionModal={setShowPerformActionModal}
            alertMessage="You are not checked-in. Please check-in to perform this action"
            processing={prcoessing}
            setProcessing={setProcessing}
            negativeButtonText="cancel"
            positiveButtonText="Check In"
            onConfirmAction={() => {
              setProcessing(false);
              navigate('/checkIn');
            }}
          />
        )}
      </LayoutComponent>
    </div>
  );
}
