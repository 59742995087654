import axiosInstance from '../constants/axiosInstance';
import { pageConfigDataInterface } from '../Interfaces/dashboardPageInterfaces';

const getPageConfigData = async (
  plantId: string,
  pageId: string | null,
  forView: 'dashboardPage' | 'pdfPage' | 'pageConfig'
) => {
  try {
    const { data } = await axiosInstance().get(
      `/dashboard/getPageConfigData?plantId=${plantId}&pageId=${pageId}&forView=${forView}`
    );
    return data.pageConfigData;
  } catch (error) {
    console.log('error : ', error);
  }
};

const savePageConfigData = async (
  plantId: string,
  pageId: string,
  pageData: pageConfigDataInterface | null
) => {
  try {
    const { data } = await axiosInstance().post(
      `/dashboard/setPageConfigData?plantId=${plantId}&pageId=${pageId}`,
      pageData
    );
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getPageConfigDataV2 = async (
  pageId: string | null,
  forView: 'dashboardPage' | 'pdfPage' | 'pageConfig',
  dataType: 'ALL' | 'STP' = 'STP'
) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/getDashboardPageConfig?pageId=${pageId}&forView=${forView}&dataType=${dataType}`
    );
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const savePageConfigDataV2 = async (
  plantId: string,
  pageId: string,
  pageData: pageConfigDataInterface | null,
  deletedWidgets: any[],
  saveHistory: boolean,
  refreshProps: any
) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/saveDashboardPageConfig?plantId=${plantId}&pageId=${pageId}`,
      {
        dashboardPageConfig: pageData,
        deletedWidgets: deletedWidgets,
        saveHistory: saveHistory,
        refreshProps: refreshProps,
      }
    );
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const insertDashboardPageHistory = async (dashboardPage: any) => {
  try {
    const { data } = await axiosInstance().post(`/newDashboard/insertDashboardPageHistory`, {
      dashboardPage: dashboardPage,
    });
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const findClusterIdByPageId = async (pageId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/findClusterIdByPageId?pageId=${pageId}`
    );
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

export {
  getPageConfigData,
  savePageConfigData,
  getPageConfigDataV2,
  savePageConfigDataV2,
  insertDashboardPageHistory,
  findClusterIdByPageId,
};
