import axiosInstance from "../constants/axiosInstance";

const getNotificationUnreadCount = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/users/getNotificationUnreadCount?plantId=${plantId}`
    );
    return data.count;
  } catch (err) {}
};

const getNotifications = async (
  plantId: string,
  startIndex: number,
  endIndex: number
) => {
  try {
    const { data } = await axiosInstance().get(
      `/users/getLastXNotifications?plantId=${plantId}&startIndex=${startIndex}&endIndex=${endIndex}`
    );
    return data.notifications;
  } catch (err) {}
};

const getCriticalNotifications = async (
  plantId: string,
  startIndex: number,
  endIndex: number
) => {
  try {
    const { data } = await axiosInstance().get(
      `/users/getLastCriticalNotifications?plantId=${plantId}&startIndex=${startIndex}&endIndex=${endIndex}`
    );
    return data.notifications;
  } catch (err) {}
};
const getNormalNotifications = async (
  plantId: string,
  startIndex: number,
  endIndex: number
) => {
  try {
    const { data } = await axiosInstance().get(
      `/users/getLastNormalNotifications?plantId=${plantId}&startIndex=${startIndex}&endIndex=${endIndex}`
    );
    return data.notifications;
  } catch (err) {}
};

const markTheNotificationAsRead = async (
  plantId: String,
  notificationId: String
) => {
  try {
    const { data } = await axiosInstance().get(
      `/users/markNotification?plantId=${plantId}&id=${notificationId}`
    );
    return data;
  } catch (err) {}
};

const clearAllUnreadNotifications = async (plantId: String) => {
  try {
    const { data } = await axiosInstance().get(
      `/users/clearAllUnreadNotifications?plantId=${plantId}`
    );
    return data;
  } catch (err) {}
};

const fetchAllUnreadPersistentNotif = async (plantId: String) => {
  try {
    const { data } = await axiosInstance().post(
      `/notification/getAllUnreadPersistentNotifications?plantId=${plantId}`
    );
    return data.data;
  } catch (err) {}
};
const markPersistentNotifAsRead = async (id: String, userId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/notification/markPersistentNotificationAsRead?plantId`,
      {
        persistentNotificationId: id,
        userId: userId,
      }
    );
    return data;
  } catch (err) {}
};

export {
  getNotificationUnreadCount,
  getNotifications,
  getCriticalNotifications,
  getNormalNotifications,
  markTheNotificationAsRead,
  fetchAllUnreadPersistentNotif,
  markPersistentNotifAsRead,
  clearAllUnreadNotifications,
};
