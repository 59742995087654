import { Stack, Typography, Popover } from "@mui/material";
// import { DashboardComponentStyle as styles } from '../../components/Dashboard2/DashboardComponentStyle';
import Calendar from "../../globalComponents/dateAndTime/Calendar";
import DigitalTimeSelector from "../../globalComponents/dateAndTime/DigitalTimeSelector";
// import { useDashboardStore } from '../../store/DashboardStore';
import useOutsideClickDetector from "../../utilities/customHooks/useOutsideClickDetector";
// import { formatTime } from '../../utilities/helpers';
import React, { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  DialogNegativeButtonStyle,
  PrimaryButton,
} from "../../styles/global/components/dpButtons";
import { updateTimeFrameForRefId } from "../../services/commonApis";
import { add530Hours } from "../../utils/dashboard.utils";

// interface IProps {
//     label: string,
//     date: any,
//     time: any,
//     openCalendar: boolean,
//     setOpenCalendar: Function,
//     openTime: boolean,
//     setOpenTime: Function,
// }

// const style = {
//   calendarBox: {
//     position: "absolute",
//     transform: "translate3d(0px, 41px, 23px)",
//     zIndex: "1201 !important",
//   },
// };

const DateAndTimeStyles = {
  ButtonStyles: { height: "42px", width: "45%" },
  CancelButtonStyle: { backgroundColor: "#E0E0E0", color: "#8B8B8B" },
};

function CalendarAndTime({
  id,
  open,
  anchorEl,
  openCalendar,
  handleClose,
  setOpenCalendar,
  setOpenTime,
  calendarParent,
  timeFrame,
  setTimeFrame,
  setIsBottomBarOpen,
  dashboardDateAndTime,
  updatedDashboarddashboardSaveData,
  dashboardSaveData,
  setDashboardSaveData,
  setParent,
  newDashboardPage,
  setNewDashboardPage,
  setSelectedTimeRange,
  updateGranularityItems
}: any) {
  const calendarRef = React.useRef(null);
  useOutsideClickDetector(calendarRef, () => {
    setOpenCalendar(false);
  });

  const date =
    calendarParent === "from" ? timeFrame.startDate : timeFrame.endDate;

  const [value, setValue] = useState<Dayjs | null>(dayjs(date));
  const [value2, setValue2] = React.useState<Dayjs | null>(dayjs(date));
  const [startDate, setStartDate] = useState(timeFrame.startDate);
  const [endDate, setEndDate] = useState(timeFrame.endDate);

  const combineDateWithTime = (date: any, time: any) => {
    return dayjs(date)
      .set("hour", dayjs(time).hour())
      .set("minute", dayjs(time).minute())
      .set("second", dayjs(time).second())
      .set("millisecond", dayjs(time).millisecond());
  };

  useEffect(() => {
    setParent("dashboard");
  });

  const handleCalendarChange = (newValue: any) => {
    if (calendarParent === "from") {
      setStartDate(newValue.$d);
    } else if (calendarParent === "to") {
      setEndDate(newValue.$d);
    }
    setValue2(newValue);
  };

  const handleTimeChange = (newValue: any) => {
    setValue(newValue);
  };

  const handleDashboardUpdate = async (timeFrame: any) => {
    const updatedDashboard = {
      ...dashboardSaveData,
      selectedTimeRange: {
        ...dashboardSaveData.selectedTimeRange,
        timeRangeType: "absolute",
        startTime: timeFrame.startDate,
        endTime: timeFrame.endDate,
      },
    };

    setDashboardSaveData(updatedDashboard);
    setNewDashboardPage({
      ...newDashboardPage,
      changes: {
        ...newDashboardPage.changes,
        dashboard: updatedDashboard,
      },
    });

    const { startDate: startDate530Ahead, endDate: endDate530Ahead } =
      add530Hours(timeFrame.startDate, timeFrame.endDate);
    await updateTimeFrameForRefId(
      startDate530Ahead,
      endDate530Ahead,
      updatedDashboard.dashboardPageId,
      "dashboardPage"
    );
  };

  const handleApplyClick = () => {
    setParent("dashboard");
    const date2 = calendarParent === "from" ? startDate : endDate;
    const formattedDate: any = combineDateWithTime(date2, value);
    let selectedGranularity: any =
      calendarParent === "from"
        ? updateGranularityItems(formattedDate.$d, timeFrame.endDate)
        : updateGranularityItems(timeFrame.startDate, formattedDate.$d);
    let timeFrame2 =
      calendarParent === "from"
        ? {
            ...timeFrame,
            startDate: formattedDate.$d,
            ...selectedGranularity
          }
        : {
            ...timeFrame,
            endDate: formattedDate.$d,
            ...selectedGranularity
          };
    setTimeFrame(timeFrame2);
    setSelectedTimeRange('Custom');
    setOpenCalendar(false);
    handleDashboardUpdate(timeFrame2);
    setIsBottomBarOpen(true);
  };

  const handleCancel = () => {
    setOpenCalendar(false);
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Stack spacing={2} sx={{ padding: 2 }}>
        {/* Calendar & Time Selector side by side */}
        <Stack direction="row" spacing={2}>
          {openCalendar && (
            <Calendar
              value={value2}
              handleCalendarChange={handleCalendarChange}
            />
          )}
          <Stack spacing={1} sx={{ paddingTop: "25px" }}>
            {/* <Button
                    variant="contained"
                    sx={styles.dateAndTimeButtonStyle}
                >
                    {time}
                </Button> */}
            <DigitalTimeSelector
              value={value}
              handleTimeChange={handleTimeChange}
            />
          </Stack>
        </Stack>

        {/* New button & Apply button side by side at the bottom */}
        <Stack direction="row" alignSelf="flex-end" spacing={2}>
          <DialogNegativeButtonStyle
            sx={[DateAndTimeStyles.ButtonStyles]}
            onClick={handleCancel}
          >
            <Typography variant="h6">Cancel</Typography>
          </DialogNegativeButtonStyle>
          <PrimaryButton
            type="submit"
            sx={DateAndTimeStyles.ButtonStyles}
            onClick={handleApplyClick}
          >
            <Typography variant="h6">Apply</Typography>
          </PrimaryButton>
        </Stack>
      </Stack>
    </Popover>
  );
}

export default CalendarAndTime;
