import axios from "axios";
import localStorageStore from "../store/localStorageStore";

export const backendApi = import.meta.env.VITE_APP_ENDPOINT;

export default function axiosInstance(
  history: any = null,
  passedHeaders: any = null
) {
  let headers: any = passedHeaders ? passedHeaders : {};

  if (localStorage.token) {
    headers.Authorization = `Bearer ${localStorage.token}`;
  }

  const entityId = localStorage.getItem("selectedEntity");
  if (entityId) {
    headers.entity = entityId;
  }

  const axiosInstance = axios.create({
    baseURL: backendApi,
    headers,
  });

  function clearTokenAndRedirectToHome() {
    localStorageStore.logout();
    if (history) {
      history.push("/");
    } else {
      // history.push('/');
      //@ts-ignore
      window.location = "/";
    }
  }

  axiosInstance.interceptors.request.use(
    (request) => {
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      console.log(error);
      if (
        error.request.responseType === "blob" &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        return new Promise(async (resolve, reject) => {
          const bufferArray = await error.response.data.text();
          const err = JSON.parse(bufferArray);
          reject({ open: true, type: "error", message: err.error });
        });
      }
      if (error.message === "Network Error") {
        return new Promise((resolve, reject) => {
          reject({ open: true, type: "error", message: "Api Not Working" });
        });
      }

      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject({
            open: true,
            type: "error",
            message: error.response.data.error,
          });
        });
      }

      if (error.response.status === 401) {
        clearTokenAndRedirectToHome();
        return new Promise((resolve, reject) => {
          reject({
            open: true,
            type: "error",
            message: error.response.data.message,
          });
        });
      } else {
        return new Promise((resolve, reject) => {
          reject({
            open: true,
            type: "error",
            message: error.response.data.error || error.response.data.message,
          });
        });
      }

      // reject(error);
    }
  );

  return axiosInstance;
}
