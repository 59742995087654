import { useEffect, useState } from "react";
import { Button, Box, TextField } from "@mui/material";
import "./styles.css";
import { ToastContainer } from "react-toastify";
import axiosInstance from "../../constants/axiosInstance";
import { getAllUsersForRole, getUser } from "../../services/users";
import { getAllPlants } from "../../services/plant";
import { plantInterfaceForGetAllPlantsApi } from "../../Interfaces/plantInterfaces";
import {
  userData,
  userOGInterface,
  newUserInterface,
} from "../../Interfaces/userInterface";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

interface AddUserPopupInterface {
  userId: string;
  edit: boolean;
  handleClose: Function;
  refresh: Function;
}

interface plantInfoInterface {
  id: string;
  plantName: string;
}

export default function AddOperatorGroup(props: AddUserPopupInterface) {
  const { handleClose, refresh, edit, userId } = props;
  const [newUserData, setnewUserData] = useState<
    newUserInterface | userOGInterface
  >({
    role: "Operator Group",
    plantId: [],
    users: [],
    name: "",
    email: "",
    number: "",
  });
  const [refreshs, setRefreshs] = useState<boolean>(false);
  const [options, setOptionData] = useState<userData[] | []>([]);

  const [plants, setPlants] = useState<plantInfoInterface[] | []>([]);
  //@ts-ignore

  /// these variables are to set the value of data fetched initially because react does not update state
  //// instantly and synchronously
  let allOperators: userData[];
  ////////////////

  useEffect(() => {
    console.log("add operator group edit : ", edit);

    fetchPlants();
    fetchOperators();
    if (edit) {
      fetchUserInfo();
    }
  }, []);

  const fetchPlants = () => {
    getAllPlants().then((plants: plantInterfaceForGetAllPlantsApi[]) => {
      let plantInfo = plants?.map((plant) => {
        return {
          id: plant.id,
          plantName: plant.plantName,
        };
      });
      setPlants(plantInfo);
      console.log("fetch plants : ", plantInfo);
    });
  };

  const fetchOperators = () => {
    // await axiosInstance().get(`/users/getUserInfo?role=Operator`);
    getAllUsersForRole(`Operator`).then((users: userData[]) => {
      console.log("operators : ", users);

      allOperators = users;
      setOptionData(users);
    });
  };

  // Do not touch these functions unless you want to rewrite whole page

  //@ts-ignore

  const fetchUserInfo = () => {
    getUser(userId)
      .then((user: userOGInterface) => {
        setnewUserData(user);
        console.log("user data : ", user);
      })
      .catch();
  };

  const handleChanges = (name: string, value: any) => {
    console.log("changes : ", value);
    let data = newUserData;
    data[name] = value;
    setnewUserData(data);
    console.log(data);
    setRefreshs(!refreshs);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (edit) {
      axiosInstance()
        .post("/users/updateUser", newUserData)
        .then(({ data }) => {
          refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axiosInstance()
        .post("/users/createWorkForce", newUserData)
        .then(({ data }) => {
          console.log(data);
          refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div className="popup-box">
      <div className="box">
        <span
          className="close-icon"
          onClick={() => {
            handleClose();
          }}
        >
          x
        </span>
        <form onSubmit={handleSubmit}>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newUserData.name}
              label="OperatorGroup Name"
              onChange={(e) => {
                handleChanges("name", e.target.value);
              }}
            />
          </Box>
          <Box m={1} p={1}>
            <TextField
              required
              fullWidth
              value={newUserData.email}
              label="Email"
              onChange={(e) => {
                handleChanges("email", e.target.value);
              }}
            />
          </Box>
          <Box>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-name-label">plants</InputLabel>
              <Select
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={newUserData.plantId}
                onChange={(event, newValue) => {
                  handleChanges("plantId", event.target.value);
                }}
                // input={<OutlinedInput label="Name" />}
                // MenuProps={MenuProps}
              >
                {plants?.map((plant) => (
                  <MenuItem value={plant.id}>{plant.plantName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth sx={{ m: 1 }}>
              <InputLabel id="demo-multiple-name-label">Name</InputLabel>
              <Select
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={newUserData.users}
                onChange={(event, newValue) => {
                  handleChanges("users", event.target.value);
                }}
                // input={<OutlinedInput label="Name" />}
                // MenuProps={MenuProps}
              >
                {options.map((option) => (
                  <MenuItem value={option.id}>{option.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box m={1} p={1}>
            <Button variant="contained" type="submit">
              {edit ? <>Update</> : <>Create User</>}
            </Button>
          </Box>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
