import axiosInstance from '../constants/axiosInstance';
import { escalationLevelsData } from '../Interfaces/escalationInterfaces';

const getEquipmentData = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(`/tasks/getEquipments?id=${id}`);
    return data?.equipments;
  } catch (err) {
    console.error('ERROR | getEquipmentData | ', err);
  }
};

const fetchAlertTasks = async () => {
  try {
    const { data } = await axiosInstance().get(`/alerts/getMasterAlerts`);
    return data;
  } catch (err) {
    console.error('ERROR | fetchAlertTasks | ', err);
  }
};

const fetchAlertForPlant = async (id: string | undefined) => {
  console.log('fetch alerts ', id);
  try {
    const data = await axiosInstance().post(`/alerts/alertsForPlantId`, {
      plantId: id,
    });
    return data?.data.data;
  } catch (err) {
    console.error('ERROR | fetchAlertTasks | ', err);
  }
};

const toggleAlertStatus = async (
  id: string | undefined,
  history: any,
  status: boolean,
  alertType: string
) => {
  try {
    const { data } = await axiosInstance().post(`/alerts/toggleAlertStatus`, {
      alertId: id,
      newHistory: history,
      status: status,
      alertType: alertType,
    });
    return data?.data;
  } catch (err) {
    console.error('ERROR | toggleAlertStatus | ', err);
  }
};

const updateSingleAlert = async (
  typeId: string | undefined,
  alertId: string | undefined,
  alertData: any,
  type: string
) => {
  console.log('updateSingleAlert', typeId, alertId, alertData, type);
  try {
    const { data } = await axiosInstance().post(`/alerts/updateSingleAlert`, {
      typeId: typeId,
      alertId: alertId,
      alertData: alertData,
      type: type,
    });
    return data;
  } catch (err) {
    console.error('ERROR | toggleAlertStatus | ', err);
  }
};

// shivam
const sendDeleteRequest = async (alertId: string) => {
  try {
    const { data } = await axiosInstance().post(`/alerts/deleteSingleTask`, {
      alertId: alertId,
    });
    return data?.result;
  } catch (error) {
    console.error('ERROR | sendDeleteRequest | ', error);
  }
};

const getFullSensorListInCategories = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getFullSensorListInCategories?id=${id}`
    );
    return data?.message;
  } catch (err) {
    console.error('ERROR | getFullSensorListInCategories | ', err);
  }
};

const getPlantConfigVarsForPlantById = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getPlantConfigVarsForPlantById?id=${id}`
    );
    return data?.data;
  } catch (err) {
    console.error('ERROR | getPlantConfigVarsForPlantById | ', err);
  }
};

const getBoolSensorTagListForPlant = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getBoolSensorTagListForPlant?plantId=${id}`
    );
    return data?.data;
  } catch (err) {
    console.error('ERROR | getBoolSensorTagListForPlant | ', err);
  }
};

const getAllUserListForPlantId = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(`/users/getAllUserListForPlantId?id=${id}`);
    return data?.message;
  } catch (err) {
    console.error('ERROR | getAllUserListForPlantId | ', err);
  }
};

const getRolesForTasks = async () => {
  try {
    const { data } = await axiosInstance().get(`/users/roleforTasks`);
    return data?.workforce;
  } catch (err) {
    console.error('ERROR | getRolesForTasks | ', err);
  }
};

const getEscalationLevels = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(`/tasks/getEscalationLevels?id=${id}`);
    return data?.body?.escalation;
  } catch (err) {
    console.error('ERROR | getEscalationLevels | ', err);
  }
};

const updateEscalationLevel = async (
  plantId: string | undefined,
  escalationLevelsData: escalationLevelsData
) => {
  try {
    const { data } = await axiosInstance().post(
      `/tasks/updateEscalationLevel?id=${plantId}`,
      escalationLevelsData
    );
    return data;
  } catch (err) {
    console.error('ERROR | updateEscalationLevel | ', err);
  }
};

// const updateAlertTasks = async (
//   id: string | undefined,
//   fixedTasks: FixedTasks[]
// ) => {
//   try {
//     const { data } = await axiosInstance().post(
//       `/tasks/updateAlertTasks?id=${id}`,
//       fixedTasks
//     );
//     return data;
//   } catch (err) {
//     console.error("ERROR | updateAlertTasks | ", err);
//   }
// };

const getWorkspaceEscalationConfig = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(`/tasks/getWorkspaceEscalationLevels?id=${id}`);
    return data?.body?.escalation;
  } catch (err) {
    console.error('ERROR | getEscalationLevels | ', err);
  }
};

const updateWorkspaceEscalationConfig = async (
  workspaceId: string | undefined,
  escalationLevelsData: escalationLevelsData
) => {
  try {
    const { data } = await axiosInstance().post(
      `/tasks/updateWorkspaceEscalationConfig?id=${workspaceId}`,
      escalationLevelsData
    );
    return data;
  } catch (err) {
    console.error('ERROR | updateWorkspaceEscalationLevel | ', err);
  }
};

const getWorkspaceEquipments = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(`/tasks/getWorkspaceEquipmentData?id=${id}`);
    return data?.equipments;
  } catch (err) {
    console.error('ERROR | getEquipmentData | ', err);
  }
};
const fetchMasterWorkspaceAlerts = async () => {
  try {
    const { data } = await axiosInstance().get(`/alerts/getMasterWorkspaceAlerts`);
    return data;
  } catch (err) {
    console.error('ERROR | fetchWorkspaceAlertTasks | ', err);
  }
};

const fetchAlertsForWorkspace = async (id: string | undefined) => {
  console.log('fetch alerts ', id);
  try {
    const data = await axiosInstance().get(`/alerts/alertsForWorkspaceId?id=${id}`);
    return data?.data.data;
  } catch (err) {
    console.error('ERROR | fetchAlertTasks | ', err);
  }
};
const getFullSensorListForWorkspaceInCategories = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getFullSensorListForWorkspaceInCategories?id=${id}`
    );
    return data?.message;
  } catch (err) {
    console.error('ERROR | getFullSensorListForWorkspaceInCategories | ', err);
  }
};

const getPlantConfigVarsForWorkspaceId = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getPlantConfigVarsForWorkspaceId?id=${id}`
    );
    return data?.data;
  } catch (err) {
    console.error('ERROR | getPlantConfigVarsForWorkspaceId | ', err);
  }
};
const getBoolSensorTagListForWorkspace = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getBoolSensorTagListForWorkspace?id=${id}`
    );
    return data?.data;
  } catch (err) {
    console.error('ERROR | getBoolSensorTagListForWorkspace | ', err);
  }
};
const getAllUserListForWorkspaceId = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(`/users/getAllUserListForWorkspaceId?id=${id}`);
    return data?.message;
  } catch (err) {
    console.error('ERROR | getAllUserListForPlantId | ', err);
  }
};
const checkIfAllPlantsInWorkspaceHavePlantAndClientAbbrs = async (id: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/checkIfAllPlantsInWorkspaceHavePlantAndClientAbbrs?id=${id}`
    );
    return data;
  } catch (err) {
    console.error('ERROR | checkIfAllPlantsInWorkspaceHavePlantAndClientAbbrs | ', err);
  }
};

export {
  getEquipmentData,
  fetchAlertTasks,
  getFullSensorListInCategories,
  getPlantConfigVarsForPlantById,
  getBoolSensorTagListForPlant,
  getAllUserListForPlantId,
  getRolesForTasks,
  getEscalationLevels,
  updateEscalationLevel,
  // updateAlertTasks,
  fetchAlertForPlant,
  toggleAlertStatus,
  updateSingleAlert,
  sendDeleteRequest,
  getWorkspaceEscalationConfig,
  updateWorkspaceEscalationConfig,
  getWorkspaceEquipments,
  fetchMasterWorkspaceAlerts,
  fetchAlertsForWorkspace,
  getFullSensorListForWorkspaceInCategories,
  getPlantConfigVarsForWorkspaceId,
  getBoolSensorTagListForWorkspace,
  getAllUserListForWorkspaceId,
  checkIfAllPlantsInWorkspaceHavePlantAndClientAbbrs,
};
