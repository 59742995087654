export default function RoleNameIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M14.0667 8.33342L15 9.27341L10.6467 13.6667L8.33333 11.3334L9.26667 10.3934L10.6467 11.7801L14.0667 8.33342ZM6.66667 11.3334L8.66667 13.3334H2V12.0001C2 10.5267 4.38667 9.33342 7.33333 9.33342L8.59333 9.40675L6.66667 11.3334ZM7.33333 2.66675C8.04058 2.66675 8.71885 2.9477 9.21895 3.4478C9.71905 3.94789 10 4.62617 10 5.33341C10 6.04066 9.71905 6.71894 9.21895 7.21903C8.71885 7.71913 8.04058 8.00008 7.33333 8.00008C6.62609 8.00008 5.94781 7.71913 5.44772 7.21903C4.94762 6.71894 4.66667 6.04066 4.66667 5.33341C4.66667 4.62617 4.94762 3.94789 5.44772 3.4478C5.94781 2.9477 6.62609 2.66675 7.33333 2.66675Z"
        fill="#999999"
      />
    </svg>
  );
}
