//@ts-nocheck
import { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableRow,
  Stack,
  TableCell,
  Button,
  Grid,
  TableBody,
  TextField,
  Typography,
  ThemeProvider,
  FormControl,
  styled,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import classes from '../styles/pages/SolutionDetail.module.css';
import {
  AddBlueButton,
  DialogNegativeButtonStyle,
  DialogPositiveButtonStyle,
} from '../styles/global/components/dpButtons';
import useAuth from '../utilities/useAuth';
import dpTheme from '../styles/theme/dpTheme';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import { getRemarks } from '../services/Remarks';
import { RemarksInterface } from '../Interfaces/Remarks';
import { utilizeSolution, finishSolution } from '../services/UtilizeSolution';
import color from '../styles/color';
import { ToastContainer, toast } from 'react-toastify';
import InputAdornment from '@mui/material/InputAdornment';
import useIsDesktop from '../utilities/customHooks/useIsDesktop';
import Modal from '@mui/material/Modal';
import moment from 'moment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import CheckIcon from '@mui/icons-material/Check';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DateRangeIcon from '@mui/icons-material/DateRange';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';

import ConfirmationBoxForSolutionFinishing from '../components/SolutionConfirmationPopups/confirmationForSolutionFinishing';
import LayoutComponent from '../components/LayoutComponent';

import { createGlobalStyle } from 'styled-components';

// Don't even bother asking why this abomination is needed, but if you remove this or <GlobalStyle/> in TSX below,
// something will break in CSS and you might end up spending hours. trust me
const GlobalStyle = createGlobalStyle`                    
  .css-13uqigx-MuiTypography-root-MuiPickersToolbarText-root-MuiDateTimePickerToolbar-separator {
    transform: translateY(10px);
  }
`;

interface IProps {
  solutionIdProp?: string;
  solutionNameProp?: string;
  solutionPendingProp?: string;
  lastUpdatedValueProp?: string;
  lastUsedTimeProp?: string;
  solutionUsageMechanism?: 'automatic' | 'manual';
  plantIdProp?: string;
  plantIdParam?: string | null;
}

function SolutionDetail({
  solutionIdProp,
  solutionNameProp,
  solutionPendingProp,
  lastUsedTimeProp,
  lastUpdatedValueProp,
  solutionUsageMechanism,
  plantIdProp,
  plantIdParam,
}: IProps) {
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isDesktop = useIsDesktop();

  const [errorMsg, setErrorMsg] = useState('');
  const { role } = useAuth();
  const [dateTime, setDateTime] = useState<Dayjs>(dayjs());
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);

  const [solutionQuantity, setSolutionQuantity] = useState<number>();
  const [remarks, setRemarks] = useState<RemarksInterface[]>([]);
  const [selectedRemark, setSelectedRemark] = useState<string>();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  let solutionInfo = {};

  useEffect(() => {
    fetchRemarks();
  }, []);

  const location = useLocation();

  if (isMobile === true) {
    const { state } = location;
    solutionInfo = { ...state };
  }
  console.log('solutionInfo', solutionInfo);

  const fetchRemarks = () => {
    getRemarks('solution', 'remove').then(data => {
      console.log('remarks', data);
      setRemarks(data);
      setSelectedRemark(data[0]?.remark);
    });
  };

  const getSolutionUsageMechanism = () => {
    return isMobile ? solutionInfo.solutionUsageMechanism : solutionUsageMechanism;
  };

  const handleValidation = () => {
    let isError = false;
    if (solutionQuantity < 0) {
      isError = true;
      setErrorMsg('Please enter a valid Quantity');
    }
    return isError;
  };

  const handleSubmit = () => {
    let isError = handleValidation();

    if (isError === false) {
      const remarkId = remarks.find(remark => remark.remark === selectedRemark)?.remarkID;
      let remarkName = selectedRemark;
      console.log('remark Name : ', selectedRemark);
      let calculatedAt: moment.Moment | Dayjs;

      role === 'Operator Group' ? (calculatedAt = moment()) : (calculatedAt = dateTime);

      console.log('calculatedAt', calculatedAt);
      console.log('plantIdParam', plantIdParam);

      if (isMobile) {
        utilizeSolution({
          solutionId: solutionInfo.solutionId!,
          solutionName: solutionInfo.solutionName!,
          utilizedAmount: parseFloat(solutionQuantity!),
          remarkId: remarkId!,
          remarkName: remarkName!,
          startTime: calculatedAt.toString(),
          endTime: calculatedAt.toString(),
        }).then(data => {
          console.log('return data : ', data);
          if (data.utilizedSolution) {
            toast('Submitted Successfuly');
            setTimeout(() => {
              if (plantIdParam) navigate(`/StoreMaintenance?plantId=${plantIdParam}`);
              else navigate(`/StoreMaintenance`);
            }, 1500);
          } else {
            toast('Failed to submit, perhaps your intended solution usage is more than you have');
          }
        });
      } else {
        utilizeSolution({
          solutionId: solutionIdProp!,
          solutionName: solutionNameProp!,
          utilizedAmount: parseFloat(solutionQuantity!),
          remarkId: remarkId!,
          remarkName: remarkName!,
          startTime: calculatedAt.toString(),
          endTime: calculatedAt.toString(),
        }).then(data => {
          console.log(data);
          if (data.utilizedSolution) {
            toast('Submitted Successfuly');
            setTimeout(() => {
              if (plantIdParam) navigate(`/StoreMaintenance?plantId=${plantIdParam}`);
              else navigate(`/StoreMaintenance`);
            }, 1500);
          } else {
            toast('Failed to submit, perhaps your intended solution usage is more than you have');
          }
        });
      }
    }
  };

  const handleChange = (target: string, e: any) => {
    if (target === 'quant') {
      setSolutionQuantity(e.target.value);
      setErrorMsg('');
    } else {
      setSelectedRemark(e.target.value);
    }
    console.log(solutionQuantity, selectedRemark);
  };

  const finishTheSolution = () => {
    let remarkName = selectedRemark;
    const remarkId = remarks.find(remark => remark.remark === remarkName)?.remarkID;

    let calculatedAt: moment.Moment | Dayjs;

    role === 'Operator Group' ? (calculatedAt = moment()) : (calculatedAt = dateTime);

    if (isMobile) {
      finishSolution({
        solutionId: solutionInfo?.solutionId,
        solutionName: solutionInfo?.solutionName,
        remarkId: remarkId,
        remarkName: remarkName,
        startTime: calculatedAt.toString(),
        endTime: calculatedAt.toString(),
      }).then(data => {
        toast(data.message);
        if (plantIdParam) navigate(`/StoreMaintenance?plantId=${plantIdParam}`);
        else navigate(`/StoreMaintenance`);
        // console.log("yay solution finshed");
      });
    } else {
      finishSolution({
        solutionId: solutionIdProp,
        solutionName: solutionNameProp,
        remarkId,
        remarkName,
        startTime: calculatedAt.toString(),
        endTime: calculatedAt.toString(),
      }).then(data => {
        // console.log("yay solution finshed");
        toast(data.message);
      });
    }
    setOpenModal(false);

    setTimeout(() => {
      if (plantIdParam) navigate(`/StoreMaintenance?plantId=${plantIdParam}`);
      else navigate(`/StoreMaintenance`);
    }, 1000);
  };

  return (
    <ThemeProvider theme={dpTheme}>
      {isMobile && <LayoutComponent />}
      {/* dont remove globalstyle please */}
      <GlobalStyle />

      <Grid
        container
        // height={() => (isMobile ? "95vh" : "95%")}
        direction="column"
        p={isMobile ? 3 : 0}
        rowGap={4}
        alignItems="center"
        justifyContent="space-evenly"
      >
        <Grid item xs={1} alignSelf="flex-start">
          {isMobile && (
            <Stack direction="row" columnGap={4}>
              <Button
                onClick={() => {
                  navigate(-1);
                }}
                sx={{
                  minWidth: '50px',
                  height: '50px',
                  border: '1px solid #DEF2F2',
                  borderRadius: '20%',
                }}
              >
                <ArrowBackIcon fontSize="small" sx={{ color: '#999999', paddingLeft: '5px' }} />
              </Button>
              <Typography variant="h4" sx={{ color: '#1A3356', alignSelf: 'center' }}>
                Solutions
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={4}
          p={2}
          rowGap={isMobile ? 3 : isDesktop ? 4 : 4}
          sx={
            isMobile
              ? {
                  minHeight: '100%',
                  border: `1px solid ${color.dpLightBlue}`,
                  borderRadius: '2%',
                  padding: '20px',
                }
              : null
          }
        >
          <Grid item>
            <Stack
              direction="row"
              p={3}
              justifyContent="flex-start"
              alignItems="center"
              columnGap={3}
              sx={{ border: '1px solid #D8E6F3', borderRadius: '3%' }}
            >
              <Typography variant="subtitle1" sx={{ color: color.dpGrey }}>
                Available Solution
              </Typography>
              <Typography variant="caption" sx={{ color: color.dpGrey }}>
                -
              </Typography>
              <Typography variant="caption" sx={{ color: color.dpGrey }}>
                {isMobile
                  ? parseFloat(solutionInfo.solutionPending)?.toFixed(2)
                  : parseFloat(solutionPendingProp)?.toFixed(2)}{' '}
                Litre
              </Typography>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              p={3}
              justifyContent="flex-start"
              alignItems="center"
              columnGap={3}
              sx={{ border: '1px solid #D8E6F3', borderRadius: '3%' }}
            >
              <Typography variant="subtitle1" sx={{ color: color.dpGrey }}>
                Last Updated Solution Time
              </Typography>
              <Typography variant="caption" sx={{ color: color.dpGrey }}>
                -
              </Typography>
              <Typography variant="caption" sx={{ color: color.dpGrey }}>
                {isMobile ? solutionInfo.lastUsedTime : lastUsedTimeProp}
              </Typography>{' '}
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              p={3}
              justifyContent="flex-start"
              alignItems="center"
              columnGap={3}
              sx={{ border: '1px solid #D8E6F3', borderRadius: '3%' }}
            >
              <Typography variant="subtitle1" sx={{ color: color.dpGrey }}>
                Last Updated Value
              </Typography>

              <Typography variant="caption" sx={{ color: color.dpGrey }}>
                -
              </Typography>
              <Typography variant="caption" sx={{ color: color.dpGrey }}>
                {isMobile ? solutionInfo.lastUpdateValue : lastUpdatedValueProp}
              </Typography>
            </Stack>
          </Grid>

          {getSolutionUsageMechanism() === 'manual' && remarks!.length && (
            <Grid item>
              <FormControl fullWidth required className={classes.form}>
                <InputLabel id="demo-simple-select-label">Remark</InputLabel>
                <Select
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select-required"
                  defaultValue={remarks[0].remark}
                  // value={selectedRemark}
                  // error={remarkErrorMsg.length ? true : false}
                  label="Remark"
                  onChange={e => handleChange('remark', e)}
                >
                  {remarks!.map((remark, index) => {
                    return (
                      <MenuItem key={remark.remarkID} value={remark.remark}>
                        {remark.remark}
                      </MenuItem>
                    );
                  })}
                </Select>
                {/* <FormHelperText sx={{ color: "red" }}>
                {remarkErrorMsg}
              </FormHelperText> */}
              </FormControl>
            </Grid>
          )}
          {getSolutionUsageMechanism() === 'manual' && (
            <Grid item>
              <TextField
                required
                fullWidth
                id="current-solution-used"
                label="Enter Current Solution Used"
                type="number"
                variant="outlined"
                value={solutionQuantity}
                onChange={e => handleChange('quant', e)}
                placeholder={'Placeholder'}
                InputProps={{
                  inputProps: { min: 0 },
                  endAdornment: <InputAdornment position="end">{`litres`}</InputAdornment>,
                }}
                error={errorMsg.length ? true : false}
                helperText={errorMsg}
              />
            </Grid>
          )}

          {role === 'CSM' && (
            <Grid item width="100%" alignSelf="center">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  columnGap="5px"
                  onClick={() => {
                    setOpenDateTimePicker(true);
                  }}
                  sx={{
                    border: '1px solid #999999',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    padding: '15px',
                  }}
                  gap={isDesktop ? 4 : 2}
                >
                  <DateRangeIcon fontSize="small" sx={{ color: '#3EA4A8' }} />
                  <Typography color="dark" sx={{ fontSize: '10px', color: '#999999' }}>
                    {dayjs(dateTime).format('Do MMM YYYY')}
                  </Typography>
                  <ArrowDropDownIcon fontSize="small" sx={{ color: '#0000008F' }} />
                  <Typography variant="h3" sx={{ fontSize: '12px', color: '#000000DE' }}>
                    |
                  </Typography>
                  <WatchLaterIcon fontSize="small" sx={{ marginLeft: '5px', color: '#3EA4A8' }} />
                  <Typography color="dark" sx={{ fontSize: '10px', color: '#999999' }}>
                    {dayjs(dateTime).format('hh:mm A')}
                  </Typography>
                  <ArrowDropDownIcon fontSize="small" sx={{ color: '#0000008F' }} />
                </Stack>

                <MobileDateTimePicker
                  open={openDateTimePicker}
                  onClose={() => setOpenDateTimePicker(false)}
                  value={dateTime}
                  disableFuture={true}
                  onChange={dateTime => {
                    // console.log("dateTime", dateTime);
                    setDateTime(dateTime!);
                  }}
                  slots={{
                    textField: () => <TextField sx={{ display: 'none' }} />,
                  }}
                />
              </LocalizationProvider>
            </Grid>
          )}

          {getSolutionUsageMechanism() === 'manual' && (
            <Grid item>
              <AddBlueButton
                sx={{ paddingTop: '8px', paddingBottom: '8px' }}
                onClick={handleSubmit}
                fullWidth
              >
                SUBMIT
              </AddBlueButton>
            </Grid>
          )}

          {getSolutionUsageMechanism() === 'automatic' && (
            <Grid item container direction="column" justifyContent="space-evenly" rowGap={3}>
              <Grid item>
                <Button
                  onClick={() => {
                    setOpenModal(true);
                    setSelectedRemark('Consumed in the plant');
                  }}
                  sx={{ backgroundColor: '#4188C6 !important', color: '#FFF', height: '50px' }}
                  fullWidth
                >
                  FINISH THE SOLUTION
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => {
                    setOpenModal(true);
                    setSelectedRemark('Wasted');
                  }}
                  sx={{
                    backgroundColor: '#FFF !important',
                    color: '#4188C6',
                    height: '50px',
                    border: '1px solid #4188C6',
                  }}
                  fullWidth
                >
                  SOLUTION WASTED
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <ConfirmationBoxForSolutionFinishing
        openModal={openModal}
        setOpenModal={setOpenModal}
        successFunction={finishTheSolution}
        rejectionFucntion={setOpenModal}
        modalText={'Are you sure you wanna finish this solution, this step cannot be undone!'}
      />
      <ToastContainer />
    </ThemeProvider>
  );
}

export default SolutionDetail;
