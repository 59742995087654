import axiosInstance from "../constants/axiosInstance";

const fetchPlantLayoutData = async (plantId: string) => {
    try {
      const { data } = await axiosInstance().get(
        `/plantManagement/getPlantLayoutDiagram?plantId=${plantId}`
      );
      return data?.data;
    } catch (err) {
    }
};

const fetchChokePointConfig = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/plantManagement/getPlantChokePointConfig?plantId=${plantId}`
    );
    return data?.data;
  } catch(err) {
  }
}

const savePlantChokePointConfig = async (plantChokePointConfig: any) => {
  try {
    const { data } = await axiosInstance().post(`/plantManagement/savePlantChokePointConfig`, plantChokePointConfig);
    return data;
  } catch (err) {
    throw err;
  }
};

export {
    fetchPlantLayoutData,
    fetchChokePointConfig,
    savePlantChokePointConfig
}
