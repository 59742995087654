const globalStyles = {
    backgrounds: {
        paaniBlue: "rgb(97,152,201)",
        darkBlueTheme: "#193458",
    },

    buttons: {
        black: {
            background: "black",
            color: "#ddd",
        },
        paaniBlue: {
            background: "rgb(97,152,201)",
        },
        disabled: {
            background: "#999",
            color: "#white",
        },
    },

    color: {
        paaniBlue: "rgb(97,152,201)",
        darkBlueTheme: "#193458",
    }
};

export { globalStyles };
