import { Chip, Dialog, DialogContent, DialogTitle, IconButton, Divider, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import storeConfigurationPageStyles from '../../styles/pages/Store.module.css';
import { IPlant, IStore } from '../../Interfaces/storeConfigurationInterfaces';
import styles from './StoreConfigurationComponents.module.css';

interface IProps {
  openStoreDetailDialog: boolean, 
  handleStoreDetailDialogClose: (event: {}, reason: "backdropClick" | "escapeKeyDown" | "close") => void,
  selectedStore: IStore | null
}

function StoreDetailDialog({ openStoreDetailDialog, handleStoreDetailDialogClose, selectedStore }: IProps) {
  return (
    <Dialog
      open={openStoreDetailDialog}
      onClose={handleStoreDetailDialogClose}
      PaperProps={{
        className: styles.CustomDialogPaper,
      }}
    >
      <DialogTitle>
        <Typography variant="h4">{selectedStore?.name}</Typography>
        <IconButton
          aria-label="close"
          onClick={(event) => handleStoreDetailDialogClose(event, "close")}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent className={styles.DialogContent}>
        {selectedStore && selectedStore.clients.length > 0 && (
          <div className={styles.ClientInfo}>
            <DoneIcon />
            <span style={{ marginLeft: "8px" }}>
              {selectedStore.clients[0].name}
            </span>
          </div>
        )}
        <div className={styles.AssetContainer}>
          {selectedStore &&
            selectedStore.assets.map((asset: IPlant, index: number) => (
              <Chip
                variant="outlined"
                key={index}
                label={asset.plantName}
                className={storeConfigurationPageStyles.AssetChipOutlined}
              />
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default StoreDetailDialog;