export default function OptionsMenuIcon() {
  return (
    <svg width="46" height="44" viewBox="0 0 46 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 28H32V25.9998H14V28ZM14 22.9998H32V21.0002H14V22.9998ZM14 16V18.0002H32V16H14Z"
        fill="#212121"
      />
    </svg>
  );
}
