//@ts-nocheck
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  Box,
  ListItem,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { PlantConfigStyle } from '../plantConfiguration/PlantConfigStyle';
import { PrimaryRoundedButton } from '../../styles/global/components/dpButtons';
import NextIcon from '@mui/icons-material/ArrowRightAlt';
import { savePlantChokePointConfig } from '../../services/PlantVisualisationConfig';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ToastContainer, toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
import { usePlantLayoutStore } from '../../store/PlantLayoutStore';
import { useLayoutStore } from '../../store/LayoutStore2';
import { saveChokePointConfig } from '../../services/layoutDiagram';
import { updateLayoutDiagramById } from '../../services/layoutDiagram';

function LayoutEdgeSelection({
  selectedNodes,
  selectedSensors,
  edges,
  loadEdgeSelection,
  setLoadEdgeSelection,
}: any) {
  const [expanded, setExpanded] = useState('');
  const { layoutId, plantId, nodesWithChokePoints, setNodesWithChokePoints, nodes } =
    useLayoutStore();
  const navigate = useNavigate();

  const handleChange = nodeId => (event, isExpanded) => {
    setExpanded(isExpanded ? nodeId : '');
  };

  const handleSelectChange = (event, nodeId) => {
    const updatedNodesWithChokePoints = {
      ...nodesWithChokePoints,
      [nodeId]: {
        ...nodesWithChokePoints?.[nodeId],
        [event.target.name]: event.target.value,
      },
    };
    setNodesWithChokePoints(updatedNodesWithChokePoints);
  };

  const saveConfig = () => {
    console.log('selected sensors : ', selectedSensors);
    if (nodesWithChokePoints) {
      Object.keys(nodesWithChokePoints).forEach(nodeId => {
        if (!selectedNodes.includes(nodeId)) delete nodesWithChokePoints[nodeId];
        Object.keys(nodesWithChokePoints[nodeId]).forEach(sensorId => {
          if (
            !selectedSensors[nodeId].find(s => {
              return s.sensorId === sensorId;
            })
          ) {
            delete nodesWithChokePoints[nodeId][sensorId];
            console.log(sensorId);
          }
        });
      });

      setNodesWithChokePoints(nodesWithChokePoints);
      const chokePointData = { layoutId, nodes: nodesWithChokePoints };
      saveChokePointConfig(chokePointData)
        .then(() => {
          toast.success('Choke Points updated successfully!');
        })
        .catch(() => {
          toast.error('Choke Points not updated!');
        });
    }

    updateLayoutDiagramById(layoutId, {
      nodes: nodes,
      edges: edges,
    })
      .then(() => {
        toast.success('Nodes and edges updated successfully!');
      })
      .catch(() => {
        toast.error('Problem updating nodes and edges!');
      });
  };

  return (
    <>
      <div
        style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
          marginBottom: '1rem',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center" marginBottom="10px">
          <ArrowBackIcon
            onClick={() => {
              setLoadEdgeSelection(!loadEdgeSelection);
            }}
            style={{ cursor: 'pointer' }}
          />
          <Typography variant="h6">Select Choke Points:</Typography>
        </Stack>
        {selectedNodes.map((nodeId: any) => (
          <Accordion key={nodeId} expanded={expanded === nodeId} onChange={handleChange(nodeId)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{nodes.find(n => n.id === nodeId)?.data?.label || nodeId}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <Typography variant="h6">
                  {selectedSensors[nodeId].length > 0 ? 'Select Edges:' : 'Select Sensors First!'}
                </Typography>
                {selectedSensors[nodeId].map((sensor: any) => (
                  <ListItem
                    key={sensor.sensorId}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      paddingLeft: '0',
                    }}
                  >
                    <Typography>
                      {sensor.sensorNickName?.length > 0 ? sensor.sensorNickName : sensor.sensorTag}
                    </Typography>
                    <Select
                      id={sensor.sensorId}
                      name={sensor.sensorId}
                      multiple
                      value={
                        nodesWithChokePoints &&
                        nodesWithChokePoints[nodeId] &&
                        nodesWithChokePoints[nodeId][sensor.sensorId]
                          ? nodesWithChokePoints[nodeId][sensor.sensorId]
                          : []
                      }
                      onChange={event => {
                        handleSelectChange(event, nodeId);
                      }}
                      renderValue={allSelectedValues => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                          }}
                        >
                          {allSelectedValues.map((selectedValue: any) => (
                            <div key={selectedValue} style={PlantConfigStyle.selectedOptions}>
                              {edges.find(e => e._id === selectedValue)?.id}
                            </div>
                          ))}
                        </div>
                      )}
                      style={{
                        minWidth: '12rem',
                        maxWidth: '18rem',
                      }}
                    >
                      {edges.map((edge: any) => (
                        <MenuItem key={edge.id} value={edge._id}>
                          {edge.id}
                        </MenuItem>
                      ))}
                    </Select>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', columnGap: '10px' }}>
        <PrimaryRoundedButton
          // disabled={!nodesWithChokePoints}
          onClick={saveConfig}
        >
          Save
          <NextIcon style={{ justifySelf: 'flex-end' }} />
        </PrimaryRoundedButton>
        {/* <PrimaryRoundedButton
          disabled={!nodesWithChokePoints}
          onClick={() => {
            // navigate(`/DataInputConfigv2/${plantId}`);
          }}
        >
          Next
          <NextIcon style={{ justifySelf: "flex-end" }} />
        </PrimaryRoundedButton> */}
      </Box>
      <ToastContainer />
    </>
  );
}

export default LayoutEdgeSelection;
