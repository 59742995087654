//@ts-nocheck
import { useEffect, useState } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Autocomplete from "@mui/lab/Autocomplete";
import {
  Box,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  DialogNegativeButtonStyle,
  DialogPositiveButtonStyle,
} from "../../styles/global/components/dpButtons";
import color from "../../styles/color";
import { getCheckInUser } from "../../services/checkIn";
import { getUsers } from "../../services/taskList";
import {
  addComment,
  changeTaskStatusService,
} from "../../services/expandedView";
import {
  checkOutForOG,
  checkOutForRO,
  checkedInUsers,
} from "../../services/userSelectPopup";
import CheckIcon from "@mui/icons-material/Check";

interface IProps {
  type?: string;
  handleClose?: Function;
  taskId?: string;
  subTaskindex?: number;
  refresh?: Function;
  comment?: string;
  tabValue?: number;
  toUser?: string;
  taskPlantName?: string;
}

const CheckInStyle = {
  checkIcon: {
    color: "green",
    marginLeft: "5px",
    marginBottom: "3px",
  }
};


export default function UserSelectPopup(props: IProps) {
  const navigate = useNavigate();
  const {
    type,
    handleClose,
    taskId,
    subTaskindex,
    refresh,
    comment,
    tabValue,
    toUser,
    taskPlantName,
  } = props;
  const [checkedInUser, setCheckedInUser] = useState([]);
  const [heading, setHeading] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [userSelected, setUserSelected] = useState("");
  const [location, setLocation] = useState(null);
  const [deviceId, setDeviceId] = useState("");
  const [onDuty, setOnDuty] = useState(null);
  const [user, setUser] = useState({});
  const [permissionToEdit, setPermissionToEdit] = useState(null);

  const handleValueChange = (e) => {
    setUserSelected(e.target.value);
    console.log(e.target.value);
  };

  const handleSubmit = (e) => {
    if (userSelected === "") {
      toast("Please Select a User!!!");
    } else {
      if (type === "Uncomplete Task") {
        changeTaskStatusService({
          taskId: taskId,
          status: "Pending",
          completedBy: userSelected.id,
          type: type,
        })
          .then((data) => {
            if (tabValue == 0) refresh("tasks");
            else refresh("alltasks");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "Complete Task") {
        changeTaskStatusService({
          taskId: taskId,
          status: "Completed",
          completedBy: userSelected.id,
        })
          .then((data) => {
            if (tabValue == 0) refresh("tasks");
            else refresh("alltasks");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (
        type === "CheckOut" &&
        userSelected.role === "Rotating Operator"
      ) {
        checkOutForRO({
          checkIn: userSelected,
          checkInStatus: false,
          location: location,
          deviceId: deviceId,
          onDutyStatus: onDuty,
        })
          .then((data) => {
            console.log(data);
            localStorage.setItem("allowAction", "false");
            navigate("/");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "CheckOut") {
        checkOutForOG({
          checkIn: userSelected,
          checkInStatus: false,
        })
          .then((data) => {
            console.log(data, "****************");
            checkedInUsers()
              .then((data) => {
                if (data?.length === 0) {
                  localStorage.setItem("allowAction", "false");
                  navigate("/checkIn");
                } else {
                  handleClose();
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "Start Task") {
        console.log(userSelected);
        changeTaskStatusService({
          taskId: taskId,
          status: "In Progress",
          completedBy: userSelected.id,
        })
          .then(({ data }) => {
            if (tabValue == 0) refresh("tasks");
            else refresh("alltasks");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "Complete Sub Task") {
        console.log("In Complete Sub Task");
        changeTaskStatusService({
          taskId: taskId,
          status: "Completed",
          completedBy: userSelected.id,
          index: subTaskindex,
        })
          .then(({ data }) => {
            if (tabValue == 0) refresh("tasks");
            else refresh("alltasks");
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (type === "Add Comment") {
        addComment({
          taskId: taskId,
          commentedBy: userSelected.id,
          comment: comment,
        })
          .then((data) => {
            if (tabValue == 0) refresh("tasks");
            else refresh("alltasks");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const getDeviceInfo = async () => {
    await getUserLocation();
    await getDeviceId();
  };

  const getUserLocation = async () => {
    if (navigator.geolocation) {
      const position: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });
      console.log("Latitude: ", position.coords.latitude);
      console.log("Longitude: ", position.coords.longitude);
      setLocation({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    } else console.log("Geolocation is not supported by this browser.");
  };

  const getDeviceId = async () => {
    var uniqueId =
      "_" +
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    if (!localStorage.getItem("deviceId"))
      localStorage.setItem("deviceId", uniqueId);

    console.log("deviceId: ", localStorage.getItem("deviceId"));
    console.log("unique id : ", uniqueId);

    setDeviceId(localStorage.getItem("deviceId").toString());
  };

  useEffect(() => {
    getCheckInUser()
      .then((data) => {
        console.log("checked in user : ", data.Users);
        setCheckedInUser(data.Users);

        getUsers()
          .then((data) => {
            console.log("user : ", data);
            if (data.user.onDuty) setOnDuty(data.user.onDuty.status);
            setUser(data.user);

            console.log("toUser : ", toUser);
            console.log("data.user.role : ", data.user.role);

            if (data.user.role === toUser || type === "CheckOut") {
              setPermissionToEdit(true);
              console.log("condition met");
            }

            if (
              data.user.role === "Rotating Operator" &&
              taskPlantName !== localStorage.getItem("checkInPlant")?.toString()
            ) {
              setPermissionToEdit(false);
              setHeading("You don't have permission to edit");
            }
          })
          .catch((err) => {
            console.log("err in getUsers", err);
          });
      })
      .catch((err) => {
        console.log("err in getCheckInUser", err);
      });

    if (type === "Complete Task") {
      setHeading("Choose User to Mark Task as Completed");
      setButtonText("Mark Task Completed");
    } else if (type === "Complete Sub Task") {
      setHeading("Choose User to Mark Sub-Task as Completed");
      setButtonText("Mark Sub-Task Completed");
    } else if (type === "CheckOut") {
      setHeading("Choose User to Checkout");
      setButtonText("Checkout User");
    } else if (type === "Start Task") {
      setHeading("Choose User to Mark Task as Started");
      setButtonText("Mark Task Started");
    } else if (type === "Add Comment") {
      setHeading("Choose User to Comment");
      setButtonText("Comment");
    } else if (type === "Uncomplete Task") {
      setHeading("Choose User to Undo Complete");
      setButtonText("UnComplete");
    }
  }, [permissionToEdit]);

  return permissionToEdit !== null ? permissionToEdit ? (
    <>
      <Dialog open={permissionToEdit} onClose={handleClose}>
        <DialogTitle>{heading}</DialogTitle>
        <DialogContent>
          <Box>
            <Autocomplete
              id="operator-name-1"
              options={checkedInUser}
              autoHighlight
              getOptionLabel={(option) => (option?.name ? option?.name : "")}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {option.name}
                  {option.checkedInStatus && (
                    <CheckIcon style={CheckInStyle.checkIcon} />
                  )}
                </Box>
              )}
              value={userSelected}
              onChange={(event, newValue) => {
                setUserSelected(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Select Operator"
                />
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogNegativeButtonStyle onClick={handleClose}>
            Cancel
          </DialogNegativeButtonStyle>
          <DialogPositiveButtonStyle onClick={handleSubmit}>
            {buttonText}
          </DialogPositiveButtonStyle>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </>
  ) : (
    <div className="popup-box">
      <div
        className="box"
        style={{
          width: "50%",
          height: "200px",
          display: "grid",
          placeItems: "center",
        }}
      >
        <span className="close-icon" onClick={handleClose}>
          x
        </span>
        <div style={{ fontSize: "25px" }}>{heading}</div>
      </div>
    </div>
  ) : <></>;
}
