import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { PLANT_LAYOUT_DIGITAL_ASSETS } from "../../../constants/constants";
import { PlantConfigStyle } from "../PlantConfigStyle";
//@ts-ignore
interface IProps {
  updateValue: Function;
  materialData: string | undefined;
  drainData: string;
  overFlowData: string;
  bypassData: string;
  ageData: number;
  index: number;
}

const getLayoutDaigramObjectFromImageUrl = (
  componentCategory: string,
  imageUrl: string
) => {
  if (imageUrl === "none") return null;
  let componentTypes = Object.keys(
    PLANT_LAYOUT_DIGITAL_ASSETS[componentCategory]
  );
  let componentType =
    componentTypes.find((componentType: string) => {
      if (
        PLANT_LAYOUT_DIGITAL_ASSETS[componentCategory]?.[componentType]?.[
          "imageURL"
        ] === imageUrl
      )
        return true;
      else return false;
    }) || "";

  if (PLANT_LAYOUT_DIGITAL_ASSETS?.[componentCategory]?.[componentType])
    return PLANT_LAYOUT_DIGITAL_ASSETS?.[componentCategory]?.[componentType];
  return null;
};

function DiagramSelectComponent({
  category,
  index,
  updateValue,
  layoutDiagram,
}: any) {
  return (
    <Box mt={1}>
      <FormControl fullWidth style={PlantConfigStyle.equipmentTextField}>
        <InputLabel style={PlantConfigStyle.inputLabel}>
          Diagram Select
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={layoutDiagram?.["imageURL"] || "None"}
          label="Diagram Select"
          onChange={(e) => {
            let value = getLayoutDaigramObjectFromImageUrl(
              category,
              e.target.value
            );
            updateValue({
              category: category,
              index: index,
              valuefor: "layoutDiagram",
              value: value,
            });
          }}
        >
          {Object.keys(PLANT_LAYOUT_DIGITAL_ASSETS[category])?.map(
            (componentType: string) => {
              return (
                <MenuItem
                  value={
                    PLANT_LAYOUT_DIGITAL_ASSETS[category][componentType][
                      "imageURL"
                    ] as string
                  }
                >
                  {componentType}
                </MenuItem>
              );
            }
          )}
          <MenuItem value={"None"}>No Diagram Available</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

export default DiagramSelectComponent;
