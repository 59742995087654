import { dia, util } from '@joint/plus';

// import css
import './control.style.css';

export const Control = dia.HighlighterView.extend({
  UPDATE_ATTRIBUTES: ['power'],
  tagName: 'g',
  children: util.svg/* xml */ `
            <foreignObject width="20" height="20">
                <div class="jj-checkbox" xmlns="http://www.w3.org/1999/xhtml">
                    <button @selector="input" class="remoteControlButtonClick" style="width: 14px; height: 14px; box-sizing: border-box; margin: 2px;"/>
                </div>
            </foreignObject>
        `,
  events: {
    'click button': 'onButtonClick',
  },
  attributes: {
    transform: 'translate(5, 5)',
  },
  // @ts-ignore
  highlight: function (cellView) {
    this.renderChildren();
    this.childNodes.input.checked = Boolean(cellView.model.power);
  },

  // @ts-ignore
  onButtonClick: function (evt) {
    // console.log(this);
    // this.cellView.model.power = evt.target.checked ? 1 : 0;
  },
});
