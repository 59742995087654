import axiosInstance from '../constants/axiosInstance';

const getAlertMasterData = async () => {
  try {
    const { data } = await axiosInstance().get('/tasks/getMaster?master=Alert Tasks');
    return data?.masterData;
  } catch (err) {
    console.error('ERROR | getAlertMasterData | ', err);
  }
};

const postAlertMasterData = async (masterData: any) => {
  try {
    const { data } = await axiosInstance().post('/tasks/updateMaster', {
      master: 'Alert Tasks',
      masterData: masterData,
    });
    return data;
  } catch (err) {
    console.error('ERROR | postAlertMasterData | ', err);
  }
};

const postWorskspaceMasterData = async (masterData: any) => {
  try {
    const { data } = await axiosInstance().post('/tasks/updateMaster', {
      master: 'Workspace Alerts',
      masterData: masterData,
    });
    return data;
  } catch (err) {
    console.error('ERROR | postAlertMasterData | ', err);
  }
};
const getWorkspaceAlertMasterData = async () => {
  try {
    const { data } = await axiosInstance().get('/tasks/getWorkspaceAlertMaster');
    return data?.masterData;
  } catch (err) {
    console.error('ERROR | getAlertMasterData | ', err);
  }
};
export {
  getAlertMasterData,
  postAlertMasterData,
  getWorkspaceAlertMasterData,
  postWorskspaceMasterData,
};
