export function createQueryString(params: Record<string, any>): string {
	const queryParams = [];
  
	for (const [key, value] of Object.entries(params)) {
	  if (value !== undefined && value !== null && value !== '') {
		if (Array.isArray(value)) {
			if(value.length > 0) {
				queryParams.push(`${encodeURIComponent(key)}=${value.map(encodeURIComponent).join(',')}`);
			}
		} else {
		  queryParams.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
		}
	  }
	}
  
	return queryParams.join('&');
}
  