import { forwardRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ThemeProvider,
  Typography,
  Slide,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Checkbox,
  styled,
  CircularProgress,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import DeleteIcon from "@mui/icons-material/Delete";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import dpTheme from "../../styles/theme/dpTheme";
import {
  DialogNegativeButtonStyle,
  PrimaryButton,
} from "../../styles/global/components/dpButtons";
// import { DevTool } from "@hookform/devtools";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";

const DialogStyles = {
  CloseIconStyles: {
    color: "var(--dp-grey-color)",
    width: "1.4rem",
    "&:hover": { cursor: "pointer" },
  },
  ButtonStyles: { height: "42px", width: "45%" },
  CancelButtonStyle: { backgroundColor: "#E0E0E0", color: "#8B8B8B" },
  DeleteIconStyle: {
    height: "40px",
    width: "6px",
    color: "#D55E41",
    border: "1px solid #D55E41",
    borderRadius: `0px 4px 4px 0px`,
  },
  DateInfoStyles: {
    border: "1px solid #BDBDBD",
    borderRadius: "10px",
    width: "65%",
    padding: "10px",
  },
};

const StyledTextField = styled(
  forwardRef(({ showDeleteButton, ...otherProps }: any, ref) => (
    <TextField ref={ref} {...otherProps} />
  ))
)(({ theme, showDeleteButton }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: showDeleteButton ? `4px 0px 0px 4px` : "4px",
  },
  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderRight: showDeleteButton ? "0px" : "1px solid rgba(0, 0, 0, 0.23)", // Adjust the borderRight value accordingly
  },
}));

interface EditComponentDialogProps {
  isEditDialogOpen: boolean;
  setIsEditDialogOpen: Function;
  heading: string;
  title1?: string;
  title2?: string;
  input1?: string;
  input2?: string;
  showSetAsDefault?: boolean;
  createdOn?: string;
  modifiedOn?: string;
  onSubmitFunc: (data: FormValues) => Promise<void>;
  showDeleteButton?: boolean;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const formSchema = yup.object().shape({
  input1: yup
    .string()
    .required("Name is required")
    .min(2, "Minimum two letters are required"),
  input2: yup.string(),
  // .required("Name is required")
  // .min(2, "Minimum two letters are required")
  setAsDefault: yup.boolean(),
});

type FormValues = {
  input1: string;
  input2?: string;
  setAsDefault?: boolean;
};

function EditComponentDialog({
  isEditDialogOpen,
  setIsEditDialogOpen,
  heading = "",
  title1 = "",
  title2 = "",
  input1 = "",
  input2 = "",
  showSetAsDefault = false,
  createdOn,
  modifiedOn,
  onSubmitFunc,
  showDeleteButton = false,
}: EditComponentDialogProps) {
  const form = useForm<FormValues>({
    // defaultValues: { input1: input1, input2: input2, setAsDefault: false },
    resolver: yupResolver(formSchema),
  });
  const {
    register,
    // control,
    handleSubmit,
    // reset,
    formState: { errors },
  } = form;
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   console.log("useEffect");
  //   form.reset({
  //     input1: ``,
  //     input2: ``,
  //   });

  //   setLoading(false);
  //   // setChecked(false);
  // }, []);

  // useEffect(() => {
  //   // Reset the form when input1 prop changes
  //   reset({ inputField1, inputField2, setAsDefault: false });
  // }, [isEditDialogOpen]);

  const onSubmit = async (data: FormValues) => {
    setLoading(true);

    try {
      await onSubmitFunc(data);
      setLoading(false);
      setIsEditDialogOpen(false);
      // Handle success here, or you can pass a callback for success handling if needed.
    } catch (error) {
      // Handle error here, or you can pass a callback for error handling if needed.
    } finally {
      setLoading(false);
      setIsEditDialogOpen(false);
    }
  };

  const handleClose = () => {
    setLoading(false);
    setIsEditDialogOpen(false);
  };

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setChecked(event.target.checked);
  // };

  return (
    <ThemeProvider theme={dpTheme}>
      <Dialog
        open={isEditDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <DialogTitle>
            <Stack
              direction={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography variant="h4" color="primaryText">
                {heading}
              </Typography>
              <DialogActions>
                <CloseIcon
                  sx={DialogStyles.CloseIconStyles}
                  onClick={handleClose}
                />
              </DialogActions>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Stack mt={1} ml={2} mr={2} direction="column">
                {title1 && (
                  <Stack
                    mt={4}
                    direction="row"
                    columnGap={0}
                    alignItems={"center"}
                  >
                    <StyledTextField
                      sx={{
                        boxShadow:
                          "0px 4px 4px 0px rgba(26, 51, 86, 0.06) inset",
                      }}
                      size="small"
                      label={`${title1} Name`}
                      id="outlined-textarea-01"
                      error={errors.input1 ? true : false}
                      helperText={errors.input1?.message}
                      placeholder={`Edit the ${title1} name here`}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton sx={{ color: "#3EA4A8" }}>
                              <FolderIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...register("input1")}
                      showDeleteButton={showDeleteButton}
                      defaultValue={input1}
                    />
                    {showDeleteButton && (
                      <Button sx={DialogStyles.DeleteIconStyle}>
                        <DeleteIcon />
                      </Button>
                    )}
                  </Stack>
                )}
                {title2 && (
                  <Stack
                    mt={4}
                    direction="row"
                    columnGap={0}
                    alignItems={"center"}
                  >
                    <RemoveIcon sx={{ color: "#CECECE" }} />
                    <TextField
                      sx={{
                        boxShadow:
                          "0px 4px 4px 0px rgba(26, 51, 86, 0.06) inset",
                      }}
                      fullWidth
                      label={`${title2} Name`}
                      size="small"
                      id="outlined-textarea-02"
                      error={errors.input2 ? true : false}
                      placeholder={`Add the ${title2} name`}
                      helperText={errors.input2?.message}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton sx={{ color: "#79B256" }}>
                              <InsertDriveFileIcon fontSize="small" />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      {...register("input2")}
                    />

                    {/* <Button sx={DialogStyles.DeleteIconStyle}>
                      <DeleteIcon />
                    </Button> */}
                  </Stack>
                )}
              </Stack>
              {showSetAsDefault && (
                <Stack
                  ml={2}
                  mt={4}
                  direction="row"
                  justifyContent={"flex-start"}
                  columnGap={1}
                  alignItems={"center"}
                >
                  <Checkbox
                    {...register("setAsDefault")}
                    // onChange={handleChange}
                  />
                  <Typography variant="h5" color="secondary">
                    Set as Default
                  </Typography>
                </Stack>
              )}
              <Stack
                mt={2}
                ml={3}
                mb={2}
                direction="column"
                rowGap={2}
                sx={DialogStyles.DateInfoStyles}
              >
                <Stack direction={"row"} columnGap={2}>
                  <WatchLaterIcon sx={{ color: "#BDBDBD" }} fontSize="small" />
                  <Typography variant="body2">
                    Created on : {createdOn}
                  </Typography>
                </Stack>
                <Stack direction={"row"} columnGap={2}>
                  <ChangeCircleIcon
                    sx={{ color: "#BDBDBD" }}
                    fontSize="small"
                  />
                  <Typography variant="body2">
                    Modified on : {modifiedOn}
                  </Typography>
                </Stack>
              </Stack>
            </DialogContentText>
          </DialogContent>

          <Stack
            mb={3}
            ml={2}
            mr={2}
            direction="row"
            justifyContent="space-evenly"
            columnGap={2}
          >
            <DialogNegativeButtonStyle
              sx={DialogStyles.ButtonStyles}
              onClick={handleClose}
            >
              <Typography variant="h6">CANCEL</Typography>
            </DialogNegativeButtonStyle>
            <PrimaryButton type="submit" sx={DialogStyles.ButtonStyles}>
              <Typography variant="h6">SAVE</Typography>
              {loading ? (
                <CircularProgress
                  style={{ marginLeft: "5px", color: "white" }}
                  size={20}
                />
              ) : null}
            </PrimaryButton>
          </Stack>
        </form>
      </Dialog>
      {/* <DevTool control={control} /> */}
    </ThemeProvider>
  );
}

export default EditComponentDialog;
