import jwtDecode, { JwtPayload } from "jwt-decode";

interface TokenInterface extends JwtPayload {
  role: string;
  email: string;
  userId: string;
  userName: string;
}

const decodeToken = (token: string) => {
  if (token) {
    try {
      const decodedToken = jwtDecode<TokenInterface>(token);
      const { role, userId, email, userName } = decodedToken;
      return { role, userId, email, userName };
    } catch (err) {
      console.error("PROBLEM IN TOKEN", err);
      return { role: "", userId: "", email: "", userName: "" };
    }
  }
  return { role: "", userId: "", email: "", userName: "" };
};

function isJwtExpired(token: string) {
  try {
    const payload = jwtDecode<TokenInterface>(token);
    if (payload.exp) {
      const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
      return payload.exp < currentTimestamp; // Check if expiration time is in the past
    }

    return false; // No expiration claim, so not expired
  } catch (error) {
    return true; // Error decoding or parsing the JWT, treat as expired
  }
}

export { decodeToken, isJwtExpired };
