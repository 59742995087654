export default function GroupGrayIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M8 3.66675C8.61884 3.66675 9.21233 3.91258 9.64992 4.35017C10.0875 4.78775 10.3333 5.38124 10.3333 6.00008C10.3333 6.61892 10.0875 7.21241 9.64992 7.65C9.21233 8.08758 8.61884 8.33342 8 8.33342C7.38116 8.33342 6.78767 8.08758 6.35008 7.65C5.9125 7.21241 5.66667 6.61892 5.66667 6.00008C5.66667 5.38124 5.9125 4.78775 6.35008 4.35017C6.78767 3.91258 7.38116 3.66675 8 3.66675ZM3.33333 5.33341C3.70667 5.33341 4.05333 5.43341 4.35333 5.61341C4.25333 6.56675 4.53333 7.51341 5.10667 8.25342C4.77333 8.89341 4.10667 9.33342 3.33333 9.33342C2.8029 9.33342 2.29419 9.1227 1.91912 8.74763C1.54405 8.37256 1.33333 7.86385 1.33333 7.33341C1.33333 6.80298 1.54405 6.29427 1.91912 5.9192C2.29419 5.54413 2.8029 5.33341 3.33333 5.33341ZM12.6667 5.33341C13.1971 5.33341 13.7058 5.54413 14.0809 5.9192C14.456 6.29427 14.6667 6.80298 14.6667 7.33341C14.6667 7.86385 14.456 8.37256 14.0809 8.74763C13.7058 9.1227 13.1971 9.33342 12.6667 9.33342C11.8933 9.33342 11.2267 8.89341 10.8933 8.25342C11.4746 7.50303 11.7444 6.55755 11.6467 5.61341C11.9467 5.43341 12.2933 5.33341 12.6667 5.33341ZM3.66667 12.1667C3.66667 10.7867 5.60667 9.66675 8 9.66675C10.3933 9.66675 12.3333 10.7867 12.3333 12.1667V13.3334H3.66667V12.1667ZM0 13.3334V12.3334C0 11.4067 1.26 10.6267 2.96667 10.4001C2.57333 10.8534 2.33333 11.4801 2.33333 12.1667V13.3334H0ZM16 13.3334H13.6667V12.1667C13.6667 11.4801 13.4267 10.8534 13.0333 10.4001C14.74 10.6267 16 11.4067 16 12.3334V13.3334Z"
        fill="#999999"
      />
    </svg>
  );
}
