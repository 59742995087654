//@ts-nocheck
import { styled } from "@mui/material/styles";
import LayoutComponent from "../components/LayoutComponent";
import { useNavigate, useParams } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  ThemeProvider,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import dpTheme from "../styles/theme/dpTheme";
import { getBiDirectionalHistoriesForPlant } from "../services/BiDirectional";
import { useState, useEffect } from "react";
import BiDirectionalDetails from "../components/BiDirectionalPopups/BiDirectionalDetails";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  width: "25%",

  "&:nth-child(3)": {
    width: "30%",
  },
  "&:last-child": {
    borderTopRightRadius: "7px",
    borderBottomRightRadius: "7px",
  },
  "&:first-child": {
    borderTopLeftRadius: "7px",
    borderBottomLeftRadius: "7px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#FCFCFD",
  cursor: "pointer",
}));

export default function BiDirectionalLogs() {
  const { plantId } = useParams<string>();
  const [isDetailedLogsOpen, setIsDetailedLogsOpen] = useState(false);
  const [equipementSelected, setEquipementSelected] = useState();
  const [rows, setRows] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const handleClick = (index) => {
    setEquipementSelected(rows[index]);
    setIsDetailedLogsOpen(true);
  };

  useEffect(() => {
    setIsDataLoading(true);
    getBiDirectionalHistoriesForPlant(plantId).then((data) => {
      if (data.length > 0) {
        processData(data);
        setIsDataLoading(false);
      } else {
        setRows([]);
        setIsDataLoading(false);
      }
    });
  }, []);
  function processData(data: any) {
    let tempRows = [];
    for (let oneHistory of data) {
      let prev, curr;

      if (oneHistory.events[0].command === "ON") {
        prev = "OFF";
        curr = "ON";
      } else {
        prev = "ON";
        curr = "OFF";
      }
      let fromAndToTime;
      let tempCreatedAt = moment(oneHistory.created_at);
      let tempExpiry = moment(tempCreatedAt).add(oneHistory.expiryMins, "m");
      tempCreatedAt.subtract(5.5, "h");
      tempExpiry.subtract(5.5, "h");
      fromAndToTime =
        moment(tempCreatedAt).format("DD MMM YYYY hh:mm A") +
        " - " +
        moment(tempExpiry).format("DD MMM YYYY hh:mm A");
      let tempRow = {
        equipment: oneHistory.equipmentId,
        name: oneHistory.userName,
        duration: fromAndToTime,
        prev: prev,
        curr: curr,
        expiryMins: oneHistory.expiryMins,
        events: oneHistory.events,
      };
      tempRows.push(tempRow);
    }
    setRows(tempRows);
  }
  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent
        style={{
          background: "#F5F6F9",
          minHeight: window.innerHeight,
        }}
        factoryResetContainer={true}
      >
        <Box mt={2} ml={4} mr={4}>
          <Box mb={4} sx={{ color: "#1A3356" }}>
            <Typography variant="h2">Logs</Typography>
          </Box>
          {rows.length > 0 ? (
            <TableContainer>
              <Table
                sx={{
                  borderCollapse: "separate",
                  borderSpacing: "0px 10px",
                }}
                aria-label="customized table"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      <Typography variant="h4">Equipment</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="h4">Name</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="h4">Duration</Typography>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Typography variant="h4">State</Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <StyledTableRow
                      onClick={() => handleClick(index)}
                      key={row.name + row.equipment}
                      // sx={{ backgroundColor: "#FFFAE6" }}
                    >
                      <StyledTableCell>
                        <Typography variant="subtitle2">
                          {row.equipment}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography variant="subtitle2">{row.name}</Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography variant="subtitle2">
                          {row.duration}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Typography variant="subtitle2">
                          Previous: {row.prev}
                        </Typography>

                        <Typography variant="subtitle2">
                          Current: {row.curr}
                        </Typography>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              {isDataLoading ? <CircularProgress /> : <p>No logs available</p>}{" "}
            </div>
          )}
        </Box>
        {isDetailedLogsOpen && (
          <BiDirectionalDetails
            setIsDetailedLogsOpen={setIsDetailedLogsOpen}
            isDetailedLogsOpen={isDetailedLogsOpen}
            equipment={equipementSelected}
          />
        )}
      </LayoutComponent>
    </ThemeProvider>
  );
}
