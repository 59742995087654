import classes from '../../styles/LayoutVisualisationStyle.module.css';
import OFFIcon from '../../../../assets/icons/OFFIcon.svg';
import ONIcon from '../../../../assets/icons/ONIcon.svg';

const styles = {
  icon: {
    width: '15px',
    height: '16px',
  },
};

const EquipmentSensors = ({ sensor, LTSensor }: any) => {
  const { sensorId, dataType, value, unit, sensorNickName, sensorTag } = sensor;
  const AnalogSensorValue =
    `${!isNaN(value) && value != null ? parseFloat(value) : 'NA'} ${unit?.value || ''}` || 0;

  return (
    <div key={sensorId} className={classes.Sensor}>
      {dataType === 'boolean' ? (
        // <div
        //   className={value ? classes.DigitalSensorOnStatus : classes.DigitalSensorOffStatus}
        // ></div>
        <div className={classes.DigitalSensorValue}>
          <img src={value ? ONIcon : OFFIcon} style={styles.icon} />
        </div>
      ) : (
        <div className={classes.AnalogSensorValue}>{AnalogSensorValue}</div>
      )}
      <div className={classes.SensorName}>{sensorNickName || sensorTag}</div>
    </div>
  );
};

export default EquipmentSensors;
