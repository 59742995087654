import Box from "@mui/material/Box";
import classes from "./PopupWithHeaderAndCloseButton.module.css";
import CancelIcon from "@mui/icons-material/Cancel";
import dpTheme from "../../styles/theme/dpTheme";
import { ThemeProvider } from "@emotion/react";
import { useEffect } from "react";

interface PropsInterface {
  closePopup: Function;
  headingText: String;
  children: React.ReactNode;
}

export default function PopupWithHeaderAndCloseButton(props: PropsInterface) {
  //@ts-ignore
  const { closePopup, headingText, children } = props;

  useEffect(() => {
    console.log("props.children : ", props.children);
  }, []);

  return (
    <ThemeProvider theme={dpTheme}>
      <Box className={classes.popupContainer}>
        <Box my={2} className={classes.popupBox}>
          <Box className={classes.popupHeader}>
            <h3 style={{ width: "100%", textAlign: "center" }}>
              {headingText}
            </h3>
            <CancelIcon
              className={classes.closeIcon}
              onClick={() => {
                closePopup();
              }}
            />
          </Box>
          <Box className={classes.popupBody}>{props.children}</Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
