import { useState, useEffect } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CONFIGURATION_PAGES } from "../../constants/constants";
import dpTheme from "../../styles/theme/dpTheme";

interface IProps {
  plantId: string;
  currentPage: string;
}

function BottomNavigationComponent({ plantId, currentPage }: IProps) {
  const navigate = useNavigate();
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    setValue(currentPage);
    return () => {};
  }, []);

  const handleNavigation = (page: any) => {
    setValue(page.label);
    navigate(`${page.path}/${plantId}`);
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <BottomNavigation
        sx={{
          display: "flex",
          alignItems: "baseline",
          padding: "10px",
          "& .MuiBottomNavigationAction-label": {
            flex: "1 1 auto",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        }}
        value={value}
        showLabels
      >
        {CONFIGURATION_PAGES.map((page) => {
          const pageLabel = (
            <Typography variant="subtitle1">{page.label}</Typography>
          );
          return (
            <BottomNavigationAction
              key={page.path}
              label={pageLabel}
              value={page.label}
              icon={<img src={page.icon} alt={page.label} />}
              onClick={() => handleNavigation(page)}
            />
          );
        })}
      </BottomNavigation>
    </ThemeProvider>
  );
}

export default BottomNavigationComponent;
