import { Box } from "@mui/material";
import { Skeleton } from "@mui/material";
import { PlantConfigStyle } from "./PlantConfigStyle";

function LoadingState() {
  return (
    <Box sx={PlantConfigStyle.loadingState}>
      <Skeleton variant="rectangular" width="100%" height="100%" />
    </Box>
  );
}

export default LoadingState;