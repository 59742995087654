import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ReactNotificationComponent = (title: any, body: any) => {
  toast.info(<Display title={title} body={body} />);

  function Display({ title, body }: { title: any; body: any }) {
    return (
      <div>
        <h4>{title}</h4>
        <p>{body}</p>
      </div>
    );
  }

  return <ToastContainer />;
};

export default ReactNotificationComponent;
