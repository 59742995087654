export default function EditIcon() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8667 6.14523L10.0333 3.34523L10.9667 2.4119C11.2222 2.15634 11.5362 2.02856 11.9087 2.02856C12.2811 2.02856 12.5949 2.15634 12.85 2.4119L13.7833 3.34523C14.0389 3.60079 14.1722 3.90923 14.1833 4.27056C14.1944 4.6319 14.0722 4.94012 13.8167 5.19523L12.8667 6.14523ZM11.9 7.12856L4.83333 14.1952H2V11.3619L9.06667 4.29523L11.9 7.12856Z"
        fill="#ACB3B9"
      />
    </svg>
  );
}
