import { dia, util } from '@joint/plus';
const step = 20;
const MIN_LIQUID_COLOR = "#FFD23F";
const LEVEL_FLAG = "LEVEl";

export class OverHeadTank extends dia.Element {
    // @ts-ignore
    defaults() {
        return {
            ...super.defaults,
            type: "LiquidTank",
            size: {
                width: 160,
                height: 200
            },
            attrs: {
                root: {
                    magnetSelector: "body"
                },
                legs: {
                    fillRule: "evenodd",
                    fill: "gray",
                    clipRule: "evenodd",
                    d: "M5.26681 144.769L0 464.769H9.80043L11.1559 349.29L63.0034 350.478L65.9001 464.654H80.6958L77.0058 350.84L83.5684 351.01L87.7318 464.654H106.648L101.787 350.827L108.128 350.695L113.47 464.654H123.652L117.427 350.502L149.873 349.767L150.168 464.769H160L157.165 144.769H5.26681ZM149.62 343.78L149.654 253.576H111.219L116.887 343.811L149.62 343.78ZM107.65 343.819L102.775 253.576H96.8995L101.344 343.825L107.65 343.819ZM83.1789 343.841L79.3411 253.188L73.2621 252.905L76.6699 343.847L83.1789 343.841ZM62.755 343.859L60.0689 252.289L12.5193 250.35L11.249 343.899L62.755 343.859ZM59.8231 245.741L12.613 244.922L13.8558 152.965H57.0917L59.8231 245.741ZM78.9572 246.121L72.9305 246.001L69.4222 152.965H74.9835L78.9572 246.121ZM102.289 246.584L96.4498 246.468L91.7994 152.965H97.1792L102.289 246.584ZM149.398 247.519L110.669 246.75L104.715 152.965H150.248L149.398 247.519Z"
                },
                body: {
                    stroke: "gray",
                    strokeWidth: 4,
                    x: 0,
                    y: 0,
                    width: "calc(w)",
                    height: "calc(h)",
                    rx: 120,
                    ry: 10,
                    fill: {
                        type: "linearGradient",
                        stops: [
                            { offset: "0%", color: "gray" },
                            { offset: "30%", color: "white" },
                            { offset: "70%", color: "white" },
                            { offset: "100%", color: "gray" }
                        ]
                    }
                },
                top: {
                    x: 0,
                    y: 20,
                    width: "calc(w)",
                    height: 20,
                    fill: "none",
                    stroke: "gray",
                    strokeWidth: 2
                },
                label: {
                    text: "Tank 1",
                    textAnchor: "middle",
                    textVerticalAnchor: "top",
                    x: "calc(w / 2)",
                    y: "calc(h + 270)",
                    fontSize: 14,
                    fontFamily: "sans-serif",
                    fill: "#350100"
                }
            }
        };
    }

    preinitialize() {
        this.markup = util.svg/* xml */ `
                <path @selector="legs"/>
                <rect @selector="body"/>
                <rect @selector="top"/>
                <text @selector="label" />
            `;
    }

    get level() {
        return this.get("level") || 0;
    }

    set level(level) {
        const newLevel = Math.max(0, Math.min(100, level));
        this.set("level", newLevel);
    }
}

export class Panel extends dia.Element {
    defaults() {
        return {
            ...super.defaults,
            type: "Panel",
            size: {
                width: 100,
                height: 130
            },
            level: 0,
            attrs: {
                root: {
                    magnetSelector: "panelBody"
                },
                panelBody: {
                    x: 0,
                    y: 0,
                    width: "calc(w)",
                    height: "calc(h)",
                    rx: 1,
                    ry: 1,
                    fill: "lightgray",
                    stroke: "gray",
                    strokeWidth: 1
                },
                panelWindow: {
                    // turn the panel over so that we can grow the liquid from the bottom
                    // by increasing the height of the bar.
                    transform: "translate(10, 10) rotate(180) translate(-40,-115) scale(0.6)"
                },
                panelTicks: {
                    transform: "translate(55, 15)",
                    d: `M 0 0 h 8 M 0 ${step} h 8 M 0 ${step * 2} h 8 M 0 ${step * 3} h 8 M 0 ${step * 4} h 8 M 0 ${step * 5} h 8`,
                    fill: "none",
                    stroke: "black",
                    strokeWidth: 2,
                    strokeLinecap: "round"
                },
                panelValues: {
                    text: "100%\n80%\n60%\n40%\n20%\n0%",
                    textAnchor: "middle",
                    textVerticalAnchor: "top",
                    x: 80,
                    y: 10,
                    lineHeight: step,
                    fontSize: 14,
                    fontFamily: "sans-serif"
                },
                frame: {
                    width: 40,
                    height: 200,
                    rx: 1,
                    ry: 1,
                    fill: "none",
                    stroke: "black",
                    strokeWidth: 3
                },
                liquid: {
                    x: 0,
                    y: 0,
                    width: 40,
                    height: 0,
                    stroke: "black",
                    strokeWidth: 2,
                    strokeOpacity: 0.2,
                    fill: MIN_LIQUID_COLOR
                },
                glass: {
                    x: 0,
                    y: 0,
                    width: 40,
                    height: 200,
                    fill: "blue",
                    stroke: "none",
                    fillOpacity: 0.1
                },
                label: {
                    text: "Tank 1",
                    textAnchor: "middle",
                    textVerticalAnchor: "top",
                    x: "calc(w / 2)",
                    y: "calc(h + 10)",
                    fontSize: 20,
                    fontFamily: "sans-serif",
                    fill: "#350100"
                }
            }
        };
    }

    preinitialize() {
        this.markup = util.svg/* xml */ `
                <rect @selector="panelBody"/>
                <path @selector="panelTicks"/>
                <text @selector="panelValues" />
                <g @selector="panelWindow">
                    <rect @selector="glass"/>
                    <rect @selector="liquid"/>
                    <rect @selector="frame"/>
                </g>
        `;
    }
}

export const PanelView = dia.ElementView.extend({
    presentationAttributes: dia.ElementView.addPresentationAttributes({
        level: [LEVEL_FLAG],
        color: [LEVEL_FLAG]
    }),

    initFlag: [dia.ElementView.Flags.RENDER, LEVEL_FLAG],

    // @ts-ignore
    confirmUpdate(...args) {
        // @ts-ignore
        let flags = dia.ElementView.prototype.confirmUpdate.call(this, ...args);
        if (this.hasFlag(flags, LEVEL_FLAG)) {
            this.updateLevel();
            flags = this.removeFlag(flags, LEVEL_FLAG);
        }
        return flags;
    },

    updateLevel() {
        const { model } = this;
        const level = Math.max(0, Math.min(100, model.get("level") || 0));
        const color = model.get("color");
        const liquidEl = this.findNode("liquid");
        const windowEl = this.findNode("frame");
        const windowHeight = Number(windowEl.getAttribute("height"));
        const height = Math.round((windowHeight * level) / 100);
        liquidEl.animate(
            {
                height: [`${height}px`],
                fill: [color]
            },
            {
                fill: "forwards",
                duration: 1000
            }
        );
    }
});