import { DataGridPro as MUIDataGrid } from '@mui/x-data-grid-pro';
// import { DataGrid as MUIDataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';

const DataGrid = (props: any) => {
  return (
    <Box sx={{ height: '500px' }}>
      <MUIDataGrid
        {...props}
        sx={{
          border: '1px solid',
          borderRadius: '8px',
          borderColor: '#DAF1F1',
          '& .MuiDataGrid-cell': {
            borderBottom: '1px solid #DAF1F1',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid #DAF1F1',
          },
          '& .MuiDataGrid-row.Mui-selected': {
            backgroundColor: '#F0F9F9', // Change this to your desired selection color
          },
          '& .MuiDataGrid-cell.Mui-selected': {
            backgroundColor: '#F0F9F9', // Change this to your desired selection color
          },
          '& .MuiDataGrid-row.Mui-selected:hover': {
            backgroundColor: '#F0F9F9', // Change this to your desired selection hover color
          },
          '& .MuiDataGrid-cell.Mui-selected:hover': {
            backgroundColor: '#F0F9F9', // Change this to your desired selection hover color
          },
        }}
      />
    </Box>
  );
};

export default DataGrid;
