import LayoutComponent from '../components/LayoutComponent';

import {
  Breadcrumbs,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  FormControl,
  InputLabel,
  InputAdornment,
  TableContainer,
  TablePagination,
  TableHead,
  Autocomplete,
  TextField,
  IconButton,
  CircularProgress,
  FormGroup,
  Select,
  FormControlLabel,
  MenuItem,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  TableRow,
  ThemeProvider,
  Link,
  Typography,
  Button,
  ListItemText,
  Divider,
  Menu,
  Fade,
  FormHelperText,
  Box,
  styled,
} from '@mui/material';
import dpTheme from '../styles/theme/dpTheme';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import color from '../styles/color';
import React, { useEffect, useState } from 'react';
import { GridFilterListIcon } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { AddBlueButton, AddAntiBlueButton } from '../styles/global/components/dpButtons';

import {
  getStoreCategories,
  getFilteredStoreItems,
  getStoreById,
  addItemToStore,
  getAllStoreItems,
  editItem,
} from '../services/Store';
import { useParams, useSearchParams } from 'react-router-dom';
import styles from '../styles/pages/Store.module.css';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IStoreItem } from '../Interfaces/storeManagementInterfaces';
interface ICategoryFilterDialogProps {
  filterOpen: boolean;
  setFilterOpen: Function;
  setSelectedCategories: Function;
  selectedCategories: string[];
  storeCategories: IStoreCategory[];
}

function CategoryFilterDialog(props: ICategoryFilterDialogProps) {
  const { filterOpen, setFilterOpen, setSelectedCategories, selectedCategories, storeCategories } =
    props;

  const handleClose = () => {
    setFilterOpen((prevState: boolean) => !prevState);
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedCategory = e.target.value;
    const isChecked = e.target.checked;

    setSelectedCategories((prevSelectedCategories: string[]) => {
      if (isChecked) {
        // If checked, add the category to the selected list
        return [...prevSelectedCategories, selectedCategory];
      } else {
        // If unchecked, remove the category from the selected list
        return prevSelectedCategories.filter(category => category !== selectedCategory);
      }
    });
  };

  return (
    <Dialog open={filterOpen} onClose={handleClose}>
      <DialogContent sx={{ padding: 0 }}>
        <FormGroup>
          {storeCategories?.map((category: IStoreCategory, index: number) => (
            <React.Fragment key={category.value}>
              <FormControlLabel
                sx={{ padding: 2 }}
                control={
                  <Checkbox
                    value={category.value}
                    onChange={handleCategoryChange}
                    checked={selectedCategories?.includes(category.value)}
                  />
                }
                label={<Typography variant="body1">{category.label}</Typography>}
              />
              {index < storeCategories.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </FormGroup>
      </DialogContent>
    </Dialog>
  );
}

interface IStoreCategory {
  value: string;
  label: string;
}

interface IItemDialogProps {
  open: boolean;
  setOpen: Function;
  storeId: string;
  dialogMode: 'Add' | 'Edit';
  filteredStoreItems: {
    [key: string]: { _id: string; name: string }[];
  };
  storeInfo: any;
  storeCategoryInfo: any;
  itemDetailToEdit: any;
  setRefresh: Function;
  storeCategories: IStoreCategory[];
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#DAF1F1',
  },
}));

const ItemDialog = (props: IItemDialogProps) => {
  const {
    open,
    storeCategoryInfo,
    setOpen,
    itemDetailToEdit,
    dialogMode,
    storeCategories,
    filteredStoreItems,
    storeId,
    storeInfo,
    setRefresh,
  } = props;
  const [loading, setLoading] = useState(false);
  const [isCategoryChemical, setIsCategoryChemical] = useState(true);
  const [filteredItemOptions, setFilteredItemOptions] = useState<any[]>([]);
  const [formData, setFormData] = useState({
    category: '',
    itemName: '',
    unit: '',
    expectedValue: '',
    time: '',
    timeUnit: '',
    asset: [],
  });
  const [errors, setErrors] = useState({
    category: '',
    itemName: '',
    unit: '',
    expectedValue: '',
    time: '',
    timeUnit: '',
    asset: '',
  });
  const isMobile = useIsMobile();
  const dialogWidth = isMobile ? 'calc(100% - 56px)' : '60%'; // 56px accounts for 28px margin on both sides

  const getCategoryNameFromDisplay = () => {
    const categoryName =
      storeCategories?.find(category => category.label === storeCategoryInfo?.storeCategoryName)
        ?.value || '';
    setIsCategoryChemical(categoryName === 'chemicals');

    return categoryName;
  };

  useEffect(() => {
    if (open === true) {
      if (dialogMode === 'Add') {
        setFormData(prevState => ({
          ...prevState,
          category: 'chemicals',
          timeUnit: 'month',
          asset: storeInfo?.assets,
        }));
        fetchFilteredItems('chemicals');
      } else {
        const selectedAssets = storeInfo?.assets?.filter((asset: any) =>
          itemDetailToEdit?.assetIds.includes(asset._id)
        );
        setFormData(prevState => ({
          ...prevState,
          category: getCategoryNameFromDisplay(),
          itemName: itemDetailToEdit?.name,
          unit: itemDetailToEdit?.unit === 'unit' ? 'Unit' : itemDetailToEdit?.unit, // Unit is display name and unit is the actual value name
          asset: selectedAssets,
          expectedValue: itemDetailToEdit?.expectedValue[0]?.value,
          time: itemDetailToEdit?.expectedValue[0]?.time,
          timeUnit: itemDetailToEdit?.expectedValue[0]?.timeUnit,
        }));

        // const selectedCategory = getCategoryNameFromDisplay();
        // const itemsForCategory =
        //   filteredStoreItems[selectedCategory]?.map((item: any) => item?.name) || [];

        // // Update the options
        // setFilteredItemOptions(itemsForCategory);
      }
    }
  }, [open]);

  // Fetch the filtered store items based on the selected category
  const fetchFilteredItems = (category: string) => {
    try {
      // Assuming you have the selected category stored in state

      const selectedCategory: string = category;

      // Fetch the items based on the selected category
      const itemsForCategory =
        filteredStoreItems[selectedCategory]?.map((item: any) => item?.name) || [];

      // Update the options
      setFilteredItemOptions(itemsForCategory);
      const unit = selectedCategory === 'chemicals' ? 'Kg' : 'Unit';
      setFormData(prevState => ({ ...prevState, itemName: itemsForCategory[0], unit: unit }));

      setIsCategoryChemical(selectedCategory === 'chemicals');
    } catch (error) {
      console.error('Error fetching filtered items', error);
    }
  };

  const handleFormFieldChange = (value: any, fieldName: any) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
    if (fieldName === 'category') {
      fetchFilteredItems(value);
      setErrors({
        ...errors,
        itemName: '',
        category: '',
      });
    } else {
      // Clear errors when the user starts typing
      setErrors({
        ...errors,
        [fieldName]: '',
      });
    }
  };

  const handleFormValidation = () => {
    const newErrors = { ...errors };
    let isFormValid = true;
    if (!formData.category) {
      newErrors.category = 'Category is required';
      isFormValid = false;
    }

    if (!formData.itemName) {
      newErrors.itemName = 'Item name is required';
      isFormValid = false;
    }

    if (!formData.unit) {
      newErrors.unit = 'Unit is required';
      isFormValid = false;
    }

    if (isCategoryChemical) {
      if (!formData.expectedValue) {
        newErrors.expectedValue = 'Expected value is required';
        isFormValid = false;
      }
      if (parseFloat(formData.expectedValue) < 0) {
        newErrors.expectedValue = 'Expected value cannot be less than 0';
        isFormValid = false;
      }
      if (!formData.time) {
        newErrors.time = 'Time is required';
        isFormValid = false;
      }
      if (parseFloat(formData.time) <= 0) {
        newErrors.time = 'Time value cannot be less than 0';
        isFormValid = false;
      }
      if (!formData.timeUnit) {
        newErrors.timeUnit = 'Time unit is required';
        isFormValid = false;
      }
    }

    if (!formData.asset || formData.asset.length === 0) {
      newErrors.asset = 'Asset is required';
      isFormValid = false;
    }
    setErrors({ ...newErrors });
    return isFormValid;
  };

  const handleSubmit = () => {
    let isFormValid = false;
    isFormValid = handleFormValidation();
    if (!isFormValid) return;
    if (dialogMode === 'Edit') {
      handleEditItem();
    } else if (dialogMode === 'Add') {
      handleAddItem();
    }
  };

  const handleAddItem = async () => {
    setLoading(true);
    let response;
    try {
      const selectedAssetIds = formData?.asset?.map((selectedAsset: any) => {
        return selectedAsset?._id;
      });

      // // Map item name to ID
      // const selectedItem = filteredStoreItems[formData.category]?.find(
      //   (item: any) => item?.name === formData?.itemName
      // );

      // // Check if the item name exists in the filteredItemOptions
      // const isNewItem = !filteredItemOptions.includes(formData.itemName);
      // const itemId = isNewItem ? null : selectedItem?._id;

      // Check if the item name exists in the filteredItemOptions (case-insensitive)
      const existingItem = filteredItemOptions
        .map(name => name.toLowerCase().trim())
        .includes(formData.itemName.toLowerCase().trim());

      // Map item name to ID if it exists in filteredStoreItems
      let isNewItem = false;
      let itemId = null;
      let selectedItem = null;

      if (!existingItem) {
        isNewItem = true;
      } else {
        const matchingItems = filteredStoreItems[formData.category]?.filter(
          item => item.name.toLowerCase() === formData.itemName.toLowerCase()
        );

        if (matchingItems.length > 0) {
          // If there are matching items, consider the first one
          selectedItem = matchingItems[0];
          itemId = selectedItem._id;
        } else {
          // If no matching item found, treat it as a new item
          isNewItem = true;
        }
      }
      11;
      const data = {
        category: formData.category,
        selectedAssetIds,
        unit: formData.unit === 'Unit' ? 'unit' : formData.unit,
        expectedValue: formData.expectedValue,
        time: formData.time,
        timeUnit: formData.timeUnit,
        itemName: isNewItem ? formData.itemName.trim() : selectedItem?.name,
        itemId: itemId,
        isNewItem: isNewItem, // Add isNewItem key based on the check
      };

      response = await addItemToStore(storeId, data);
      setRefresh((prevState: boolean) => !prevState);
    } catch (error) {
      console.error(error);
      // Handle error
    } finally {
      setLoading(false);
      if (response.includes("couldn't")) {
        toast.error(response);
      } else toast.success(response);
      handleClose();
    }
  };

  const handleEditItem = async () => {
    setLoading(true);
    let response;
    try {
      const selectedAssetIds = formData?.asset?.map((selectedAsset: any) => {
        return selectedAsset?._id;
      });

      // Check if the item name exists in the filteredItemOptions
      // const isNewItem = !filteredItemOptions.includes(formData.itemName); // since item name can't be changed for edit mode, leave this as it is

      const data = {
        selectedAssetIds,
        expectedValue: formData.expectedValue,
        time: formData.time,
        timeUnit: formData.timeUnit,
        itemName: formData.itemName,
        itemId: itemDetailToEdit.itemId, // since item name can't be changed for edit mode, leave this as it is
        isNewItem: false, // since item name can't be changed for edit mode, leave this as it is
      };

      response = await editItem(storeCategoryInfo?.storeCategoryId, data);
      setRefresh((prevState: boolean) => !prevState);
    } catch (error) {
      console.error(error);
      // Handle error
    } finally {
      setLoading(false);
      if (response.includes("couldn't")) {
        toast.error(response);
      } else toast.success(response);
      handleClose();
    }
  };

  const handleClose = async () => {
    setFormData({
      category: '',
      itemName: '',
      unit: '',
      expectedValue: '',
      time: '',
      timeUnit: '',
      asset: [],
    });
    setErrors({
      category: '',
      itemName: '',
      unit: '',
      expectedValue: '',
      time: '',
      timeUnit: '',
      asset: '',
    });
    setFilteredItemOptions([]);

    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: styles.CustomDialogPaper,
        sx: {
          width: dialogWidth, // Apply width dynamically based on screen size
          marginLeft: isMobile ? '28px' : undefined,
          marginRight: isMobile ? '28px' : undefined,
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: '18px 16px 0px 16px!important',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4">{dialogMode} Item</Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: '18px 16px !important',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>
                <Typography variant="body1">Category</Typography>
              </InputLabel>
              <Select
                label={<Typography variant="body1">Category</Typography>}
                // defaultValue={dialogMode === 'Edit' ? categoryName : storeCategories[0]?.value}
                value={formData.category}
                onChange={e => handleFormFieldChange(e.target.value, 'category')}
                disabled={dialogMode === 'Edit'}
                error={!!errors.category}
              >
                {storeCategories &&
                  storeCategories?.map(category => (
                    <MenuItem key={category.value} value={category.value}>
                      <Typography variant="body1">{category.label}</Typography>
                    </MenuItem>
                  ))}
                {/* Add more categories as needed */}
              </Select>

              <FormHelperText>
                {errors.category && (
                  <Typography variant="body2" color="error">
                    {errors.category}
                  </Typography>
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Autocomplete
                disablePortal
                fullWidth
                id="combo-box-item-name"
                options={filteredItemOptions}
                value={formData.itemName}
                onInputChange={(e, newInputValue) =>
                  handleFormFieldChange(newInputValue, 'itemName')
                }
                disabled={dialogMode === 'Edit'}
                freeSolo // Enable adding items not in the list
                renderInput={params => (
                  <TextField
                    {...params}
                    label={<Typography variant="body1">Item Name</Typography>}
                    error={!!errors.itemName}
                  />
                )}
              />
              <FormHelperText>
                {errors.itemName && (
                  <Typography variant="body2" color="error">
                    {errors.itemName}
                  </Typography>
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item mt={2} xs={12}>
            <Typography variant="body1" color="disabled">
              Store Consumption
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>
                <Typography variant="body1">Unit</Typography>
              </InputLabel>
              <Select
                label={<Typography variant="body1">Unit</Typography>}
                error={!!errors.unit}
                value={formData.unit}
                onChange={e => handleFormFieldChange(e.target.value, 'unit')}
                // defaultValue={dialogMode === 'Edit' ? itemDetailToEdit?.unit : 'Kg'}
                disabled={dialogMode === 'Edit'}
              >
                {formData.category === 'chemicals' ? (
                  ['Kg', 'L'].map(unit => (
                    <MenuItem key={unit} value={unit}>
                      <Typography variant="body1">{unit}</Typography>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value={'Unit'}>
                    <Typography variant="body1">Unit</Typography>
                  </MenuItem>
                )}
              </Select>
              <FormHelperText>
                {errors.unit && (
                  <Typography variant="body2" color="error">
                    {errors.unit}
                  </Typography>
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          {isCategoryChemical && (
            <Grid item xs={12} mt={1}>
              <TextField
                fullWidth
                type="number"
                label={<Typography variant="body1">Expected Value</Typography>}
                // defaultValue={
                //   dialogMode === 'Edit' ? itemDetailToEdit?.expectedValue[0]?.value : null
                // }
                value={formData.expectedValue}
                onChange={e => handleFormFieldChange(e.target.value, 'expectedValue')}
                error={!!errors.expectedValue}
              />
              {errors.expectedValue && (
                <Typography variant="body2" color="error">
                  {errors.expectedValue}
                </Typography>
              )}
            </Grid>
          )}
          {isCategoryChemical && (
            <Grid item xs={6} mt={1}>
              <TextField
                label={<Typography variant="body1">Time</Typography>}
                error={!!errors.time}
                value={formData.time}
                onChange={e => handleFormFieldChange(e.target.value, 'time')}
                type="number"
                // defaultValue={
                //   dialogMode === 'Edit' ? itemDetailToEdit?.expectedValue[0]?.time : null
                // }
              />
              {errors.time && (
                <Typography variant="body2" color="error">
                  {errors.time}
                </Typography>
              )}
            </Grid>
          )}
          {isCategoryChemical && (
            <Grid item xs={6} mt={1}>
              <FormControl fullWidth>
                <InputLabel>
                  <Typography variant="body1">Time Unit</Typography>
                </InputLabel>
                <Select
                  label={<Typography variant="body1">Time Unit</Typography>}
                  error={!!errors.timeUnit}
                  value={formData.timeUnit}
                  onChange={e => handleFormFieldChange(e.target.value, 'timeUnit')}
                  // defaultValue={
                  //   dialogMode === 'Edit' ? itemDetailToEdit?.expectedValue[0]?.timeUnit : 'month'
                  // }
                >
                  <MenuItem value={'minute'}>Minute(s)</MenuItem>
                  <MenuItem value={'hour'}>Hour(s)</MenuItem>
                  <MenuItem value={'day'}>Day(s)</MenuItem>
                  <MenuItem value={'month'}>Month(s)</MenuItem>
                </Select>
                <FormHelperText>
                  {errors.timeUnit && (
                    <Typography variant="body2" color="error">
                      {errors.timeUnit}
                    </Typography>
                  )}
                </FormHelperText>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>
                <Typography variant="body1">Asset</Typography>
              </InputLabel>

              <Select
                multiple
                label={<Typography variant="body1">Asset</Typography>}
                // defaultValue={dialogMode === 'Edit' ? itemDetailToEdit?.assetIds : null}
                value={formData.asset.map((asset: any) => asset.plantName)}
                variant="outlined"
                onChange={e => {
                  const selectedAssets = e.target.value;
                  const selectedAssetsInfo = storeInfo?.assets?.filter((asset: any) =>
                    selectedAssets?.includes(asset?.plantName)
                  );
                  handleFormFieldChange(selectedAssetsInfo, 'asset');
                }}
                renderValue={selected => `${selected.length} selected`}
                error={!!errors.asset}
              >
                <MenuItem disabled>
                  <Typography variant="subtitle1">List of Assets</Typography>
                </MenuItem>
                {storeInfo?.assets?.map((asset: any) => (
                  <MenuItem key={asset?.plantName} value={asset?.plantName}>
                    <Checkbox
                      checked={
                        formData.asset
                          .map((asset: any) => asset.plantName)
                          ?.indexOf(asset?.plantName) > -1
                      }
                      sx={{
                        '&.Mui-checked': {
                          color: '#3DA3F5', // Change color to green
                        },
                      }}
                    />
                    <ListItemText primary={asset?.plantName} />
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText>
                {errors.asset && (
                  <Typography variant="body2" color="error">
                    {errors.asset}
                  </Typography>
                )}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} mt={2}>
            <AddBlueButton fullWidth onClick={handleSubmit}>
              {dialogMode === 'Edit' ? ' SAVE' : 'Create'}
              {loading ? (
                <CircularProgress sx={{ marginLeft: '5px', color: 'blue' }} size={15} />
              ) : null}
            </AddBlueButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

function StoreManagement() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [filteredStoreItems, setFilteredStoreItems] = useState<{
    [key: string]: { _id: string; name: string }[];
  }>();
  const [searchQuery, setSearchQuery] = useState('');
  const [finalSearchQuery, setFinalSearchQuery] = useState('');
  const { storeId } = useParams<string>();
  const [storeInfo, setStoreInfo] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const isMobile = useIsMobile();
  const [storeCategoryInfo, setStoreCategoryInfo] = useState<any>();
  const [itemDetailToEdit, setItemDetailToEdit] = useState<any>();
  const [dialogMode, setDialogMode] = useState<'Add' | 'Edit'>('Add');
  const [storeCategories, setStoreCategories] = useState<IStoreCategory[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [categoryFilterOpen, setCategoryFilterOpen] = useState(false);
  const [ItemDialogOpen, setItemDialogOpen] = useState(false);
  const [storeItemsList, setStoreItemsList] = useState<IStoreItem[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [searchParams] = useSearchParams();
  const storeName = searchParams.get('storeName') || 'Default Store';

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCategoryChange = (selectedCategory: string) => {
    setSelectedCategories((prevSelectedCategories: string[]) => {
      if (prevSelectedCategories.includes(selectedCategory)) {
        // If the category is already selected, remove it
        return prevSelectedCategories.filter(category => category !== selectedCategory);
      } else {
        // If the category is not selected, add it to the list
        return [...prevSelectedCategories, selectedCategory];
      }
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    fetchStoreCategories();

    return () => {};
  }, []);

  const fetchData = async (searchQuery: string = '', newSearchQuery = false) => {
    const data = await getAllStoreItems(
      storeId,
      newSearchQuery ? 0 : page,
      rowsPerPage,
      searchQuery,
      selectedCategories
    );
    if (data) {
      setStoreItemsList(data.storeItems);
      setTotalItems(data.totalStoreItems);
      console.log(data.totalStoreItems);
    }
  };

  useEffect(() => {
    setSearchQuery(finalSearchQuery);
    fetchData(finalSearchQuery);
  }, [refresh, storeId, page, rowsPerPage, selectedCategories]);

  const fetchStoreCategories = async () => {
    let tempStoreCategories = await getStoreCategories();
    setStoreCategories(tempStoreCategories);

    setSelectedCategories(tempStoreCategories?.map((category: any) => category?.value));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = () => {
    setPage(0);
    setFinalSearchQuery(searchQuery);
    fetchData(searchQuery, true);
  };

  const handleSearchIconClick = () => {
    fetchData(searchQuery, true);
  };

  const handleRowClick = async (storeCategoryInfo: any, itemDetail: any) => {
    setStoreCategoryInfo(storeCategoryInfo);
    setItemDetailToEdit(itemDetail);
    console.log(itemDetail, 'itemDetail');
    setLoading(true);
    let storeInfo = await getStoreById(storeId!);
    setStoreInfo(storeInfo);
    setDialogMode('Edit');
    if (storeInfo) {
      setItemDialogOpen(true);
    }
    setLoading(false);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/StoreConfiguration">
      <Typography key="3" color="#666666">
        Stores
      </Typography>
    </Link>,
    <Typography key="3" color="#666666">
      {storeName}
    </Typography>,
  ];

  //@ts-ignore
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleAddItemClick = async () => {
    setLoading(true);
    let tempStoreInfo = await getStoreById(storeId!);
    let tempFilteredStoreItems = await getFilteredStoreItems(storeId!);
    setFilteredStoreItems(tempFilteredStoreItems);
    setStoreInfo(tempStoreInfo);
    setDialogMode('Add');
    if (tempStoreInfo && tempFilteredStoreItems) {
      setLoading(false);
      setItemDialogOpen(true);
    }
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent
        factoryResetContainer
        style={{
          marginLeft: isMobile ? 2 : 5,
          marginRight: isMobile ? 2 : 5,
          marginTop: 2,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="10px"
        >
          <Breadcrumbs
            separator={<ArrowForwardIcon fontSize="small" sx={{ color: '#666666' }} />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
          <AddAntiBlueButton
            sx={{
              minWidth: isMobile ? '38px' : '120px',
              height: isMobile ? '38px' : '40px',
            }}
            onClick={handleAddItemClick}
          >
            {isMobile ? '' : 'Add Item'}
            {loading ? (
              <CircularProgress sx={{ marginLeft: '5px', color: 'blue' }} size={15} />
            ) : null}
            <AddIcon fontSize="small" />
          </AddAntiBlueButton>
        </Stack>
        <Box className={styles.StoreMangementSearchSection}>
          <TextField
            placeholder="Search for Item Name"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={e => {
              if (e.key === 'Enter') handleSearch();
            }}
            InputProps={{
              className: styles.SearchField,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleSearchIconClick}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                fieldset: {
                  border: '1px solid #183650',
                },
                '&.Mui-focused fieldset': {
                  border: '1.6px solid #183650',
                },
              },
            }}
          />

          <Button
            id="fade-button"
            aria-controls={open ? 'fade-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              border: `1px solid ${color.dpDarkBlue}99`,
              color: '#000',
            }}
          >
            <GridFilterListIcon fontSize="medium" />
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{
              '& .MuiPaper-root': {
                border: '1px solid #00000066',
                boxShadow: '0px 2px 8px 0px #1A335626',
              },
            }}
          >
            {storeCategories?.map((category: IStoreCategory, index: number) => {
              return (
                <>
                  <MenuItem
                    value={category?.value}
                    onClick={() => handleCategoryChange(category.value)}
                    sx={{ padding: 0, paddingRight: '30px' }}
                  >
                    <Checkbox checked={selectedCategories?.includes(category?.value)} />
                    <Typography variant="body1" sx={{ color: '#5C6066' }}>
                      {category?.label}
                    </Typography>
                  </MenuItem>
                  {index < storeCategories?.length - 1 && <Divider />}
                </>
              );
            })}
          </Menu>

          <Button variant="outlined" className={styles.SearchButton} onClick={handleSearch}>
            {isMobile ? <SearchIcon fontSize="small" /> : 'Search'}
          </Button>
        </Box>
        <TableContainer
          sx={{
            border: '1px solid #DAF1F1',
            borderRadius: '8px',
          }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead
              sx={{
                borderBottom: '1px solid #DAF1F1',
              }}
            >
              <TableRow>
                <TableCell
                  align="left"
                  sx={{
                    borderRight: '1px solid #DAF1F1',
                    borderBottom: '1px solid #DAF1F1',
                  }}
                >
                  <Typography variant="h6">Item Name</Typography>
                </TableCell>
                <TableCell
                  align="left"
                  sx={{
                    borderBottom: '1px solid #DAF1F1',
                    borderRight: '1px solid #DAF1F1',
                  }}
                >
                  <Typography variant="h6">Category</Typography>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    padding: 0,
                    paddingRight: '20px',
                    borderBottom: '1px solid #DAF1F1',
                  }}
                >
                  <Typography
                    variant="h6"
                    width="100%"
                    sx={{
                      paddingTop: 2,
                      paddingBottom: 2,
                    }}
                  >
                    Config Details
                  </Typography>

                  <Table
                    sx={{
                      width: '100%',
                      marginTop: 2,
                      borderTop: '1px solid #DAF1F1',
                      minWidth: isMobile ? '300px' : '450px',
                    }}
                  >
                    <TableHead>
                      <TableRow sx={{ border: 0 }}>
                        <TableCell sx={{ border: 0 }} align="left" width="50%">
                          <Typography sx={{ marginLeft: '50%' }} variant="h6">
                            Units
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ border: 0 }} align="left" width="50%">
                          <Typography sx={{ marginLeft: '50%' }} variant="h6">
                            Expected Consumption
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {storeItemsList?.map(storeCategory =>
                storeCategory.storeItemDetails?.map(itemDetail => (
                  <StyledTableRow
                    key={itemDetail._id}
                    onClick={() =>
                      handleRowClick(
                        {
                          storeCategoryName: storeCategory?.storeCategoryName,
                          storeCategoryId: storeCategory?._id,
                        },
                        itemDetail
                      )
                    }
                  >
                    <TableCell
                      sx={{
                        borderBottom: '1px solid #DAF1F1',
                      }}
                    >
                      <Typography variant="body1">{itemDetail.name}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottom: '1px solid #DAF1F1',
                      }}
                    >
                      <Typography variant="body1">{storeCategory?.storeCategoryName}</Typography>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: 0,
                        paddingRight: '20px',
                        borderBottom: '1px solid #DAF1F1',
                      }}
                    >
                      <Table
                        sx={{
                          width: '100%',
                          // marginTop: 2,
                          // borderTop: "1px solid #DAF1F1",
                        }}
                      >
                        <TableBody>
                          <TableRow sx={{ border: 0 }}>
                            <TableCell sx={{ border: 0 }} width="50%">
                              {itemDetail.unit !== '' ? (
                                <Typography sx={{ marginLeft: '50%' }} variant="body1">
                                  {itemDetail.unit === 'L' ? 'litres' : itemDetail.unit}
                                </Typography>
                              ) : (
                                <Typography sx={{ marginLeft: '50%' }} variant="body1">
                                  Unit
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell sx={{ border: 0 }} width="50%">
                              {itemDetail.expectedValue.length > 0 ? (
                                <>
                                  <Typography sx={{ marginLeft: '50%' }} variant="body1" noWrap>
                                    {itemDetail.expectedValue[0].value}
                                  </Typography>
                                  <Typography
                                    sx={{ marginLeft: '50%', color: 'grey' }}
                                    variant="caption"
                                    noWrap
                                  >
                                    {itemDetail.expectedValue[0].time}{' '}
                                    {itemDetail.expectedValue[0].timeUnit}
                                  </Typography>
                                </>
                              ) : (
                                <Typography sx={{ marginLeft: '50%' }} variant="body1">
                                  -
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableCell>
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <CategoryFilterDialog
          filterOpen={categoryFilterOpen}
          setFilterOpen={setCategoryFilterOpen}
          storeCategories={storeCategories}
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
        />
        <ItemDialog
          open={ItemDialogOpen}
          setOpen={setItemDialogOpen}
          dialogMode={dialogMode}
          storeCategoryInfo={storeCategoryInfo}
          itemDetailToEdit={itemDetailToEdit}
          storeId={storeId!}
          setRefresh={setRefresh}
          filteredStoreItems={filteredStoreItems!}
          storeInfo={storeInfo}
          storeCategories={storeCategories}
        />
      </LayoutComponent>
    </ThemeProvider>
  );
}

export default StoreManagement;
