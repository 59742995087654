//@ts-nocheck
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import TwoRowLayout from '../../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import SearchBox from '../../../../core-components/theme/components/Searchbox/Searchbox';
import { Neutrals } from '../../../../core-components/theme/color-palette/colors';
import LayoutComponent from '../../../../components/LayoutComponent';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import Table from '../../../../core-components/theme/components/Table/Table';
import Button from '../../../../core-components/theme/components/Button/Button';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUsersList, deleteUser } from '../../../../services/newUsers';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OptionsMenu from './OptionsMenu';
import PersonIcon from '@mui/icons-material/Person';
import RoleNameIcon from '../../../../core-components/assets/icons/RoleNameIcon';
import WorkspaceGrayIcon from '../../../../core-components/assets/icons/WorkspaceGrayIcon';
import GroupGrayIcon from '../../../../core-components/assets/icons/GroupGrayIcon';


const UserListPage = () => {
  const MAX_VISIBLE_ITEMS = 3;
  const userColumns = [
    {
      field: 'name',
      headerName: 'Name',
      renderCell: (item: { name: string; email: string }) => {
        return (
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <PersonIcon />
            <Stack>
              <Typography noWrap variant="h4" ml={2}>
                {item.name}
              </Typography>
              <Typography noWrap variant="caption" ml={2} color="#999">
                {item.email}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },

    {
      field: 'roles',
      headerName: 'Roles',
      renderCell: (value: any[]) => {
        const rolesData = value.slice(0, MAX_VISIBLE_ITEMS);
        const hasMore = value.length > MAX_VISIBLE_ITEMS;
        return (
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            {rolesData?.map((role: any) => (
              <>
                <RoleNameIcon />
                <Typography variant="h4" ml={2} mr={2} noWrap>
                  {role}
                </Typography>
              </>
            ))}
            {hasMore && (
              <Tooltip title={value.slice(MAX_VISIBLE_ITEMS).map(item => item.name + ', ')}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    ml: 1,
                  }}
                >
                  ...
                </Typography>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'workspaces',
      headerName: 'Workspaces',
      renderCell: (value: any[]) => {
        const workspaceData = value.slice(0, 1);
        const hasMore = value.length > 1;
        return (
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            {workspaceData?.map((workspace: any) => (
              <>
                <WorkspaceGrayIcon />
                <Typography variant="h4" ml={2} mr={2} noWrap>
                  {workspace}
                </Typography>
              </>
            ))}
            {hasMore && (
              <Tooltip title={value.slice(MAX_VISIBLE_ITEMS).map(item => item.name + ', ')}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    ml: 1,
                  }}
                >
                  ...
                </Typography>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'groups',
      headerName: 'Groups',
      renderCell: (value: any[]) => {
        const groupData = value.slice(0, 1);
        const hasMore = value.length > 1;
        return (
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            {groupData.map((group: any) => (
              <>
                <GroupGrayIcon />
                <Typography variant="h4" ml={2} mr={2} noWrap>
                  {group}
                </Typography>
              </>
            ))}
            {hasMore && (
              <Tooltip title={value.slice(MAX_VISIBLE_ITEMS).map(item => item.name + ', ')}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    ml: 1,
                  }}
                >
                  ...
                </Typography>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'defaultHomepage',
      headerName: 'Default Homepage',
      renderCell: (value: 'string') => {
        return (
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <Typography noWrap variant="h4">
              {value}
            </Typography>
          </Stack>
        );
      },
    },
  ];

  const [usersList, setUsersList] = useState<any>([]);
  const [filterUsersQuery, setFilterUsersQuery] = useState('');
  //@ts-ignore
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const navigate = useNavigate();

  const handleSearch = (query: string) => {
    setFilterUsersQuery(query);
    const filteredRows = usersList.filter((row: any) =>
      row.name.name.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredUsersList(filteredRows);
  };

  const handleClear = () => {
    setFilterUsersQuery('');
    setFilteredUsersList(usersList);
  };

  // console.log(Originalworkspacerowsdata);

  const handleEditUser = (id: string) => {
    navigate(`/editUser/${id}`, { state: { id } });
  };

  const mutation = useMutation({
    mutationFn: (id: any) => deleteUser({ deletedUserId: id }),
    onSuccess: (data, id) => {
      if (data !== undefined) {
        toast.success(`User deleted and child Users Updated`);
        setUsersList((prevData: any) => prevData.filter((workspace: any) => workspace._id !== id));
        setFilteredUsersList((prevData: any) =>
          prevData.filter((workspace: any) => workspace._id !== id)
        );
      }
    },
    onError: (error: any) => {
      toast.error(`Problem in deleting User`);
      console.error('Failed to delete User:', error);
    },
  });

  const handleDeleteUser = (id: string) => {
    mutation.mutate(id);
  };

  const { data: usersListData, status: usersListStatus } = useQuery({
    queryKey: ['usersList'],
    queryFn: getUsersList,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (usersListStatus === 'success') {
      setUsersList(usersListData);
      setFilteredUsersList(usersListData);
    }
  }, [usersListData, usersListStatus]);

  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{ pl: 4, pr: 4 }}
        child1={
          <Box sx={{ width: '100%' }}>
            <Button sx={{ mb: 2 }} onClick={() => navigate('/createUser')} endIcon={<AddIcon />}>
              Create
            </Button>
            <SearchBox
              placeholder="Search Group"
              sx={{
                width: '100%',
                flexGrow: 1,
                height: '52px',
                borderColor: `${Neutrals[500]}`,
                borderRadius: '4px',
              }}
              onSearch={handleSearch}
              onClear={handleClear}
            />
          </Box>
        }
        child2={
          <Box>
            <Table
              rows={filteredUsersList || []}
              columns={userColumns}
              renderOptionsCell={row => (
                <OptionsMenu
                  row={row}
                  handleDeleteUser={handleDeleteUser}
                  handleEditUser={handleEditUser}
                />
              )}
            />
          </Box>
        }
      />
    </LayoutComponent>
  );
};

export default UserListPage;
