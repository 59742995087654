import React, { useState } from 'react';
import { Box, Menu, MenuItem, Stack, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Table from '../../../core-components/theme/components/Table/Table';
import CustomIconButton from '../../../core-components/theme/components/Button/Iconbutton';
import DetailIcon from '../../../core-components/assets/icons/DetailIcon';
import DialogBox from '../../../core-components/theme/components/DialogBox/DialogBox';
import TwoRowLayout from '../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import EditIcon from '../../../core-components/assets/icons/EditIcon';
import ConfirmationDialog from '../../../core-components/theme/components/DialogBox/ConfirmationDialog';
import DeleteFilledIcon from '../../../core-components/assets/icons/DeleteIconFilled';
import { Neutrals } from '../../../core-components/theme/color-palette/colors';
import AssetIcon from '../../../core-components/assets/icons/AssetIcon';

const OptionsMenu: React.FC<{ row: any; handleDeleteWorkspace: any; handleEditWorkspace: any }> = ({
  row,
  handleDeleteWorkspace,
  handleEditWorkspace,
}) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const handleOpen = () => {
    setIsDetailsDialogOpen(true);
  };
  const handleConfirmationOpen = () => {
    setIsConfirmationDialogOpen(true);
  };
  const handleClose = () => {
    setIsDetailsDialogOpen(false);
    closeMenu();
  };
  const handleConfirmationClose = () => {
    setIsConfirmationDialogOpen(false);
    closeMenu();
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const columns = [
    {
      field: 'assets',
      headerName: (
        <Stack direction="row" alignItems="center" gap={2}>
          <AssetIcon color="#193458" />
          <Typography variant="header">Assets</Typography>
        </Stack>
      ),
    },
    {
      field: 'createdBy',
      headerName: <Typography variant="h4">Created By</Typography>,
    },
  ];

  function transformWorkspaceData(data: any) {
    const { assets, createdBy } = data;
    const { name, email } = createdBy;
    return assets.map((asset: { _id: string; name: string }) => ({
      assets: asset.name,
      createdBy: (
        <Stack>
          <Typography variant="h4">{name}</Typography>
          <Typography variant="caption2">{email}</Typography>
        </Stack>
      ),
    }));
  }

  return (
    <>
      <CustomIconButton onClick={openMenu} icon={MoreVertIcon} />
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        slotProps={{
          paper: {
            style: {
              boxShadow: '0px 2px 8px 0px #1A335626',
            },
          },
        }}
        MenuListProps={{
          sx: {
            border: `1px solid ${Neutrals[300]}`,
            py: 0,
            backgroundColor: '#ffffff',
            borderRadius: '4px',
          },
        }}
      >
        <MenuItem
          onClick={handleOpen}
          sx={{ height: '36px', paddingLeft: '8px', borderBottom: `1px solid ${Neutrals[300]}` }}
        >
          <DetailIcon />
          <Typography ml={2} color={Neutrals[500]}>
            Details
          </Typography>
        </MenuItem>
        <MenuItem
          sx={{ height: '36px', paddingLeft: '8px', borderBottom: `1px solid ${Neutrals[300]}` }}
          onClick={() => handleEditWorkspace(row._id)}
        >
          <EditIcon />
          <Typography ml={2} color={Neutrals[500]}>
            Edit
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleConfirmationOpen} sx={{ height: '36px', paddingLeft: '8px' }}>
          <DeleteFilledIcon />
          <Typography ml={2} color={Neutrals[500]}>
            Delete
          </Typography>
        </MenuItem>
        <DialogBox open={isDetailsDialogOpen} title={row.name} handleClose={handleClose}>
          <Box>
            <TwoRowLayout
              style={{ height: '60vh' }}
              child1={
                <>
                  <Typography lineHeight="20px" variant="h4">
                    {row.description}
                  </Typography>
                </>
              }
              child2={
                <>
                  <Table rows={transformWorkspaceData(row)} columns={columns} />
                </>
              }
            />
          </Box>
        </DialogBox>
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          handleClose={handleConfirmationClose}
          title="Delete Workspace"
          description={
            <Typography variant="h4" lineHeight="20px">
              Are you sure you want to delete{' '}
              <Typography component="span" variant="highlight">
                {row.name}
              </Typography>
              ? You can’t undo this action
            </Typography>
          }
          actions={[
            {
              text: 'Cancel',
              color: 'white',
              textColor: '#8B8B8B',
              handler: () => console.log('Cancel clicked'),
            },
            {
              text: 'Delete',
              color: 'SemanticError.dark',
              textColor: 'white',
              handler: () => handleDeleteWorkspace(row._id),
            },
          ]}
        ></ConfirmationDialog>
      </Menu>
    </>
  );
};

export default OptionsMenu;
