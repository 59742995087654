import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LayoutDiagram from '../components/LayoutVisualisation/LayoutDiagram';
import LayoutNodeSelection from '../components/LayoutVisualisationConfig/LayoutNodeSelection';
import { Grid, Box } from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import { getSingleLayoutDiagramByFilters } from '../services/layoutDiagram';
import { useState } from 'react';
import { useLayoutStore } from '../store/LayoutStore2';
import { getChokePointConfigByLayoutId } from '../services/layoutDiagram';
import CircularProgress from '@mui/material/CircularProgress';

const LayoutVisualisationConfig: React.FC = () => {
  //   const { plantId } = useParams<string>();
  const { workspaceId } = useParams();
  const {
    setLayoutId,
    setNodes,
    setEdges,
    setSelectedNodes,
    setSelectedSensors,
    setNodesWithChokePoints,
    nodes,
    setLayoutName,
    resetStore,
  } = useLayoutStore();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (workspaceId) {
      setLoading(true);
      console.log('Workspace ID changed:', workspaceId);
      getSingleLayoutDiagramByFilters({
        assetId: workspaceId,
      }).then(LayoutDiagram => {
        const nodesData = LayoutDiagram.nodes;
        const edgesData = LayoutDiagram.edges;
        console.log('edges : ', edgesData);
        setNodes(nodesData);
        setEdges(edgesData);
        setLoading(false);
        setLayoutId(LayoutDiagram._id);
        setLayoutName(LayoutDiagram.assetName);

        getChokePointConfigByLayoutId(LayoutDiagram._id).then((chokePointConfig: any) => {
          if (chokePointConfig) {
            const selectedNodes = Object.keys(chokePointConfig?.nodes);
            let selectedSensors: any = {};
            selectedNodes.forEach(node => {
              selectedSensors[node] = Object.keys(chokePointConfig?.nodes[node])
                .map(sensorId => {
                  const sensorParentData = nodesData.find(
                    (nodeDetail: any) => nodeDetail.id === node
                  );

                  if (!sensorParentData) return null;

                  const sensor = sensorParentData.data?.configInfo?.sensors?.find(
                    (sensor: any) => sensor._id === sensorId
                  );
                  return {
                    sensorId,
                    sensorNickName: sensor?.sensorNickName,
                    sensorTag: sensor?.sensorTag,
                  };
                })
                .filter(sensor => {
                  return sensor != null;
                });
            });
            console.log('fist time selectedSensors : ', selectedSensors);
            setSelectedNodes(selectedNodes);

            setSelectedSensors(selectedSensors);
            setNodesWithChokePoints(chokePointConfig?.nodes);
          }
        });
      });
    } else {
      navigate('/404');
    }

    return () => {
      // Remove the event listener when the component unmounts or before the effect runs again
      resetStore();
    };
  }, [workspaceId]);

  useEffect(() => {}, [nodes]);

  return (
    <LayoutComponent>
      <Grid container spacing={4} sx={{ padding: '40px', height: '100vh' }}>
        <Grid item md={9}>
          {!loading ? (
            <LayoutDiagram />
          ) : (
            <Box
              sx={{
                height: '100%',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
        <Grid item md={3}>
          {!loading ? (
            <LayoutNodeSelection />
          ) : (
            <Box
              sx={{
                height: '100%',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
        </Grid>
      </Grid>
    </LayoutComponent>
  );
};

export default LayoutVisualisationConfig;
