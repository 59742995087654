import { useState } from "react";
import { Box } from "@mui/material";
import LayoutComponent from "../components/LayoutComponent";
import { useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { pageConfigSummaryInterface } from "../Interfaces/dashboardConfigPageInterfaces";
import { BlackButton } from "../styles/global/components/dpButtons";
import {
  changeDefaultPageForUser,
  createClonePage,
  deletePage,
  getDashboardConfigData,
  setAutoEmailingData,
} from "../services/dashboardConfig";
import { CallMade } from "@mui/icons-material";
import { useDashboardStore } from "../store/DashboardStore";

export default function DashboardPage() {
  const { plantId } = useParams();
  const navigate = useNavigate();
  const {  setSelectedPageId } = useDashboardStore();
  const [dashboardConfigData, setDashboardConfigData] = useState<
    pageConfigSummaryInterface[]
  >([]);
  const [refresh, setRefresh] = useState(false);
  // const [pageData,setPageData] = useState()

  const styles = {
    dashboardConfigHeader: {
      fontFamily: "Montserrat Bold",
      fontSize: 30,
      alignItems: "center",
      verticalAlign: "middle",
    },
    dashboardConfigBody: {
      height: "400px",
      color: "red",
    },
    icon: {
      color: "black",
    },
  };

  useEffect(() => {
    getDashboardConfigData(plantId!).then((data) => {
      console.log("data : ", data);
      setDashboardConfigData(data);
    });
  }, [refresh]);

  const changeDefault = async (pageId: string) => {
    var tempData = dashboardConfigData;
    console.log("in default pageId : ", pageId);
    dashboardConfigData.forEach((page: pageConfigSummaryInterface) => {
      if (page.pageId.toString() === pageId.toString()) page.default = true;
      else page.default = false;
    });
    setDashboardConfigData(tempData);
    changeDefaultPageForUser(plantId!, pageId).then((data) => {});
  };

  const changeDefaultAutoEmailing = async (
    pageId: string,
    timeRange: string
  ) => {
    var temp = dashboardConfigData;
    var subField = "";

    if (timeRange == "daily") {
      subField = "dailyReportPageId";
      dashboardConfigData.forEach((page: pageConfigSummaryInterface) => {
        page.pageId.toString() === pageId.toString()
          ? (page.sendDailyReport = true)
          : (page.sendDailyReport = false);
      });
    }

    if (timeRange == "weekly") {
      subField = "weeklyReportPageId";
      dashboardConfigData.forEach((page: pageConfigSummaryInterface) => {
        page.pageId.toString() === pageId.toString()
          ? (page.sendWeeklyReport = true)
          : (page.sendWeeklyReport = false);
      });
    }

    if (timeRange == "monthly") {
      subField = "monthlyReportPageId";
      dashboardConfigData.forEach((page) => {
        page.pageId.toString() === pageId.toString()
          ? (page.sendMonthlyReport = true)
          : (page.sendMonthlyReport = false);
      });
    }

    setDashboardConfigData(temp);

    setAutoEmailingData(plantId!, pageId, subField).then((data) => {});
  };

  const addNewpage = () => {
    // reroute it to page configuration page
    navigate(`/pageConfig/${plantId}/11223344`);
  };

  const deletePageFunc = async (pageId: string, permissionToEdit: boolean) => {
    if (permissionToEdit)
      deletePage(pageId).then((data) => {
        setRefresh(!refresh);
      });
    else
      toast(
        "You are not the creator of this page hence you don't have permission to delete"
      );
  };

  const editPage = async (pageId: string, permissionToEdit: boolean) => {
    if (permissionToEdit) navigate(`/pageConfig/${plantId}/${pageId}`);
    else
      toast(
        "You are not the creator of this page hence you dont have permission to edit"
      );
  };

  const clonePage = async (pageId: string, pageName: string) => {
    createClonePage(pageId)
      .then((data) => {
        setRefresh(!refresh);
        toast(`${pageName} cloned`);
      })
      .catch((data) => {});
  };

  const cols: GridColDef[] = [
    {
      field: "pageName",
      headerName: "Page",
      width: 250,
    },
    {
      field: "goTo",
      headerName: "Go To",
      width: 100,
      renderCell: (params: GridCellParams) => {
        return (
          <Button
            sx={{ color: "black" }}
            onClick={() => {
              setSelectedPageId(params.row.id);
              navigate(`/dashboardPage/${plantId}`);
            }}
          >
            <CallMade />
          </Button>
        );
      },
    },
    {
      field: "default",
      headerName: "Default",
      width: 100,
      renderCell: (params: GridCellParams) => {
        var pageId = params.row.pageId;

        if (params.row.default === true)
          return <CheckIcon style={{ color: "green" }} onClick={() => {}} />;
        else
          return (
            <ClearIcon
              style={{ color: "red" }}
              onClick={() => {
                changeDefault(`${pageId}`);
              }}
            />
          );
      },
    },
    {
      field: "pageCreatorName",
      headerName: "creator",
      width: 250,
    },
    {
      field: "lastUpdated",
      headerName: "Last Updated",
      width: 250,
      renderCell: (params: GridCellParams) => {
        return params.row.lastUpdateInfo.lastUpdated;
      },
    },
    {
      field: "timeRangeInDays",
      headerName: "Default Time",
      width: 250,
      renderCell: (params: GridCellParams) => {
        return parseInt(params.row.defaultTimeInDays) + "D";
      },
    },
    {
      field: "privacy",
      headerName: "privacy",
      width: 250,
      renderCell: (params: GridCellParams) => {
        return params.row.public ? "public" : "private";
      },
    },
    {
      field: "dailyAutoEmailing",
      headerName: "Daily Report",
      width: 200,
      renderCell: (params: GridCellParams) => {
        var pageId = params.row.pageId;
        if (params.row.sendDailyReport === true)
          return <CheckIcon style={{ color: "green" }} onClick={() => {}} />;
        else
          return (
            <ClearIcon
              style={{ color: "red" }}
              onClick={() => {
                changeDefaultAutoEmailing(`${pageId}`, "daily");
              }}
            />
          );
      },
    },
    {
      field: "weeklyAutoEmailing",
      headerName: "Weekly Report",
      width: 200,
      renderCell: (params: GridCellParams) => {
        var pageId = params.row.pageId;
        if (params.row.sendWeeklyReport === true)
          return <CheckIcon style={{ color: "green" }} onClick={() => {}} />;
        else
          return (
            <ClearIcon
              style={{ color: "red" }}
              onClick={() => {
                changeDefaultAutoEmailing(`${pageId}`, "weekly");
              }}
            />
          );
      },
    },
    {
      field: "monthlyAutoEmailing",
      headerName: "Monthly Report",
      width: 200,
      renderCell: (params: GridCellParams) => {
        var pageId = params.row.pageId;
        if (params.row.sendMonthlyReport === true)
          return <CheckIcon style={{ color: "green" }} onClick={() => {}} />;
        else
          return (
            <ClearIcon
              style={{ color: "red" }}
              onClick={() => {
                changeDefaultAutoEmailing(`${pageId}`, "monthly");
              }}
            />
          );
      },
    },
    {
      field: "edit",
      headerName: "Edit Page",
      width: 200,
      renderCell: (params: GridCellParams) => {
        return (
          <Button
            onClick={() => {
              editPage(params.row.pageId, params.row.permissionToEdit);
            }}
          >
            <SettingsIcon fontSize="small" sx={styles.icon} />
          </Button>
        );
      },
    },
    {
      field: "clone",
      headerName: "clone",
      width: 200,
      renderCell: (params: GridCellParams) => {
        return (
          <Button
            onClick={() => {
              clonePage(params.row.pageId, params.row.pageName);
            }}
          >
            <ContentCopyIcon fontSize="small" sx={styles.icon} />
          </Button>
        );
      },
    },
    {
      field: "delete",
      headerName: "delete",
      width: 200,
      renderCell: (params: GridCellParams) => {
        return (
          <Button
            onClick={() => {
              deletePageFunc(params.row.pageId, params.row.permissionToEdit);
            }}
          >
            <DeleteIcon fontSize="small" sx={styles.icon} />
          </Button>
        );
      },
    },
  ];

  return (
    <LayoutComponent plantId={plantId}>
      <Box className="dashboardConfig">
        <Box m={1} p={1}>
          <div
            className="dashboardConfigHeader"
            style={styles.dashboardConfigHeader}
          >
            Dashboard Configuration
            <span style={{ float: "right" }}>
              <BlackButton
                onClick={() => {
                  addNewpage();
                }}
              >
                <AddIcon />
                Add Page
              </BlackButton>
            </span>
          </div>
        </Box>
        <Box className="dashboardConfigBody" sx={styles.dashboardConfigBody}>
          <DataGrid columns={cols} rows={dashboardConfigData} />
        </Box>
      </Box>
      <ToastContainer />
    </LayoutComponent>
  );
}
