import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  FormLabel,
  Checkbox,
} from '@mui/material';

export default function ExtraInfoConfig(props: any) {
  const { widget, widgetClusterIndex, widgetIndex, changeExtraInfoWidgetProperty } = props;

  return (
    <Accordion>
      <AccordionSummary>Extra Info Config</AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: '100%' }}>
          <FormLabel component="legend">
            <Checkbox
              checked={widget.extraInfo?.showOpenAlerts || false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeExtraInfoWidgetProperty(
                  widgetClusterIndex,
                  widgetIndex,
                  'showOpenAlerts',
                  e.target.checked
                );
              }}
            />
            Show Open Alerts
          </FormLabel>
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormLabel component="legend">
            <Checkbox
              checked={widget.extraInfo?.showOpenCriticalAlerts || false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeExtraInfoWidgetProperty(
                  widgetClusterIndex,
                  widgetIndex,
                  'showOpenCriticalAlerts',
                  e.target.checked
                );
              }}
            />
            Show Open Critical Alerts
          </FormLabel>
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormLabel component="legend">
            <Checkbox
              checked={widget.extraInfo?.showOpenNonCriticalAlerts || false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeExtraInfoWidgetProperty(
                  widgetClusterIndex,
                  widgetIndex,
                  'showOpenNonCriticalAlerts',
                  e.target.checked
                );
              }}
            />
            Show Open Non Critical Alerts
          </FormLabel>
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormLabel component="legend">
            <Checkbox
              checked={widget.extraInfo?.showClosedAlerts || false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeExtraInfoWidgetProperty(
                  widgetClusterIndex,
                  widgetIndex,
                  'showClosedAlerts',
                  e.target.checked
                );
              }}
            />
            Show Closed Alerts
          </FormLabel>
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormLabel component="legend">
            <Checkbox
              checked={widget.extraInfo?.showClosedCriticalAlerts || false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeExtraInfoWidgetProperty(
                  widgetClusterIndex,
                  widgetIndex,
                  'showClosedCriticalAlerts',
                  e.target.checked
                );
              }}
            />
            Show Closed Critical Alerts
          </FormLabel>
        </Box>
        <Box sx={{ width: '100%' }}>
          <FormLabel component="legend">
            <Checkbox
              checked={widget.extraInfo?.showClosedNonCriticalAlerts || false}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeExtraInfoWidgetProperty(
                  widgetClusterIndex,
                  widgetIndex,
                  'showClosedNonCriticalAlerts',
                  e.target.checked
                );
              }}
            />
            Show Closed Non Critical Alerts
          </FormLabel>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
