import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

function AbbrSelector({ options, setAbbr }: { options: string[]; setAbbr: Function }) {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: any, newInputValue: string) => {
    const upperCaseValue = newInputValue.toUpperCase();
    if (upperCaseValue.length <= 6) {
      setInputValue(upperCaseValue);
      setAbbr(upperCaseValue);
    }
  };

  return (
    <Autocomplete
      freeSolo
      options={options.map((option: string) => option.toUpperCase())}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={params => (
        <TextField
          {...params}
          label="Abbreviation"
          variant="outlined"
          helperText={
            'Abbreviation must start with an uppercase letter followed by uppercase letters or numbers only.'
          }
          inputProps={{
            ...params.inputProps,
            minLength: 3,
            maxLength: 6,
            pattern: '^[A-Z][A-Z0-9]*$',
          }}
        />
      )}
    />
  );
}

export default AbbrSelector;
