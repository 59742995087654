import { useEffect, useMemo, useState } from 'react';
import ReactFlow, {
  ConnectionLineType,
  MiniMap,
  Controls,
  useNodesState,
  useEdgesState,
  ReactFlowProvider,
  MarkerType,
  Background,
} from 'reactflow';
//@ts-ignore
import dagre from 'dagre';
import 'reactflow/dist/style.css';
import { Typography } from '@mui/material';
import lodash from 'lodash';
import { useLayoutStore } from '../../store/LayoutStore2';
import configEdge from './CustomEdges/ConfigEdge';
import ConfigNode from './CustomNodes/configNode/ConfigNode';

const LayoutDiagram = () => {
  const {
    nodes: storedNodes,
    edges: storedEdges,
    setNodes: setStoredNodes,
    layoutName,
  } = useLayoutStore();

  const [refreshPage, setRefreshPage] = useState(false);

  const edgeTypes = useMemo(
    () => ({
      equipmentEdge: configEdge,
    }),
    []
  );

  const nodeTypes = useMemo(
    () => ({
      equipmentNode: ConfigNode,
    }),
    []
  );

  const getNodesAndEdges = (nodes: any[], edges: any[]) => {
    let newNodes: any[] = [];
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeWidth = 200;
    const nodeHeight = 100;

    dagreGraph.setGraph({ rankdir: 'LR' });
    nodes.forEach((node: any) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
    edges.forEach((edge: any) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
    dagre.layout(dagreGraph);
    edges.forEach(edge => {
      edge['type'] = 'equipmentEdge';
      edge['markerEnd'] = {
        type: MarkerType.ArrowClosed,
        color: edge?.data?.properties?.color ?? '#000',
      };
    });
    newNodes = nodes.map((node: any) => {
      if (node.position) {
        if (!node.targetPosition && !node.sourcePosition) {
          node.targetPosition = 'left';
          node.sourcePosition = 'right';
        }
        return node;
      } else {
        const nodeWithPosition = dagreGraph.node(node.id);
        if (!node.targetPosition && !node.sourcePosition) {
          node.targetPosition = 'left';
          node.sourcePosition = 'right';
        }
        // We are shifting the dagre node position (anchor=center center) to the top left
        // so it matches the React Flow node anchor point (top left).

        node.position = {
          x: nodeWithPosition.x - nodeWidth / 2,
          y: nodeWithPosition.y - nodeHeight / 2,
        };

        return node;
      }
    });

    return { nodes: newNodes, edges };
  };
  const { nodes: layoutedNodes, edges: layoutedEdges } = getNodesAndEdges(storedNodes, storedEdges);
  //@ts-ignore
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  //@ts-ignore
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  useEffect(() => {
    setNodes(storedNodes);
    setEdges(storedEdges);
    setRefreshPage(!refreshPage);
  }, [storedNodes, storedEdges, setNodes, setEdges]);

  useEffect(() => {
    // Only update storedNodes if nodes have actually changed.
    // This is checked using Lodash's isEqual for deep comparison.
    console.log('useEffect of layoutDiagram');
    if (!lodash.isEqual(nodes, storedNodes)) {
      console.log('useEffect of layoutDiagram 1 : ', storedNodes);
      setStoredNodes(nodes);
      console.log('useEffect of layoutDiagram 2 : ', storedNodes);
      // setRefreshPage(!refreshPage);
    }
  }, [nodes, edges]);

  useEffect(() => {
    console.log('Nodes updated', nodes);
    console.log('Edges updated', edges);
  }, [nodes, edges]);

  return (
    <div style={{ textAlign: 'center' }}>
      <Typography variant="h6">
        {layoutName ? layoutName + ' layout diagram' : 'Layout Diagram'}
      </Typography>
      <div
        style={{
          height: '600px',
          margin: '0 auto',
          textAlign: 'center',
          border: '1px solid black',
          borderRadius: '5px',
          padding: '20px',
        }}
      >
        <ReactFlowProvider>
          <ReactFlow
            // key={refreshPage}
            nodes={nodes}
            edges={edges}
            edgeTypes={edgeTypes}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            connectionLineType={ConnectionLineType.Step}
            minZoom={0}
            maxZoom={4}
            fitView
          >
            <MiniMap />
            <Controls />
            <Background />
          </ReactFlow>
        </ReactFlowProvider>
      </div>
    </div>
  );
};

export default LayoutDiagram;
