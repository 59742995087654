import axiosInstance from "../constants/axiosInstance";

const getTanks = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/plants/fetchDosingTankListForPlant`,
      {
        plantId: plantId,
      }
    );
    return data.data;
  } catch (err) {
    console.error(`ERROR | getChemicals`, err);
  }
};

export default getTanks;
