import { useState, useEffect } from "react";
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  ThemeProvider,
  Button,
  Typography,
  DialogContentText,
} from "@mui/material";
import dpTheme from "../../styles/theme/dpTheme";
import {
  DialogPositiveButtonStyle,
  DialogNegativeButtonStyle,
} from "../../styles/global/components/dpButtons";
import { usePlantLayoutStore } from "../../store/PlantLayoutStore";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import classes from "./BiDirectional.module.css";
import { acknowledgeControl } from "../../services/BiDirectional";
// import SelectTimeDialog from "./SelectTimeDialog";

interface IProps {
  showConfirmationDialog: boolean;
  setShowConfirmationDialog: Function;
  input: boolean;
  relativeTime: string;
  equipmentName: string;
  userId: string;
  controlId: string;
}

const ConfirmationDialog = ({
  showConfirmationDialog,
  setShowConfirmationDialog,
  input,
  userId,
  controlId,
  relativeTime,
  equipmentName,
}: IProps) => {
  // const [showSelectTimeDialog, setShowSelectTimeDialog] = useState(false); //  not needed now, will need later ***DO NOT DELETE***
  const [isYesButtonDisabled, setIsYesButtonDisabled] = useState(true);
  const { biDirectionalInput, setLoadingStatus } = usePlantLayoutStore();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsYesButtonDisabled(false);
    }, 4000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleConfirm = () => {
    // setShowSelectTimeDialog(true);   //  not needed now, will need later ***DO NOT DELETE***
    try {
      acknowledgeControl(controlId, userId);
      toast.success("Request has been sent");
      setLoadingStatus(true);
    } catch (err) {
      toast.error("Request Failed");
      return;
    }
    setShowConfirmationDialog(false);
  };
  const message = `
  You are trying to manually TURN ${biDirectionalInput ? `ON ` : `OFF `} the
  ${equipmentName}. This means the ${equipmentName} will be removed
  from the automation control in the meanwhile. Please note that
  if ${equipmentName} has a standby equipment it usually interchanges with, then that
  equipment’s operations will also be affected. There is a chance
  both will run at the same time, so you may consider taking
  control of that equipment too. Finally, please note that
  whatever happens to the equipment in this time period is your
  responsibility.`;

  const handleCancel = () => {
    setShowConfirmationDialog(false);
  };
  const handleClose = () => {
    setShowConfirmationDialog(false);
  };

  return (
    <>
      <ThemeProvider theme={dpTheme}>
        <Dialog
          open={showConfirmationDialog}
          onClose={handleClose}
          maxWidth={"lg"}
        >
          <DialogTitle mt={2} mr={2} ml={2} className={classes.dialogTitle}>
            <Typography variant={"h2"}>Are You Sure?</Typography>
            <Button onClick={handleClose}>
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent>
            <DialogContentText mt={2} mr={2} ml={2}>
              <Typography variant={"h4"}> {message}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.dialogAction}>
            <DialogNegativeButtonStyle
              className={classes.dialogButton}
              onClick={handleCancel}
            >
              <Typography variant={"body1"}>No</Typography>
            </DialogNegativeButtonStyle>

            <DialogPositiveButtonStyle
              className={classes.dialogButton}
              disabled={isYesButtonDisabled}
              onClick={handleConfirm}
            >
              <Typography variant={"body1"}>Yes</Typography>
            </DialogPositiveButtonStyle>
          </DialogActions>
        </Dialog>
        {/* {showSelectTimeDialog && ( // @ not needed now, will need later ***DO NOT DELETE***
          <SelectTimeDialog
            showSelectTimeDialog={showSelectTimeDialog}
            setShowSelectTimeDialog={setShowSelectTimeDialog}
            timeOfConfirmation={timeOfConfirmation}
          />
        )} */}
      </ThemeProvider>
    </>
  );
};

export default ConfirmationDialog;
