import axiosInstance from "../constants/axiosInstance";

const fetchTasksList = async (path: any, ind: any) => {
  try {
    const { data } = await axiosInstance().get(
      "/tasks/" + path + `?index=${ind}`
    );
    return data;
  } catch (err) {
    console.error("ERROR | fetchTasksList | ", err);
  }
};

// const changeTaskStatus = async (taskId, userId) => {
//     try {
//         const { data } = await  axiosInstance().post("/tasks/changeTaskStatus", {
//           taskId: taskId,
//           status: "Completed",
//           completedBy: userId,
//           type: "Complete Task",
//         });
//         return data;
//     } catch(err) {
//         console.error("ERROR | changeTaskStatus | ", err);
//     }
// }

const getUsers = async () => {
  try {
    const { data } = await axiosInstance().get("/users/user");
    return data;
  } catch (err) {
    console.error("ERROR | getUsers | ", err);
  }
};
const getUserDetails = async (userId: string) => {
  try {
    const { data } = await axiosInstance().get("/users/user?id=" + userId);
    return data;
  } catch (err) {
    console.error("ERROR | getUserDetails | ", err);
  }
};
export { fetchTasksList, getUsers, getUserDetails };
