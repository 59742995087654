import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import LayoutComponent from '../../../components/LayoutComponent';
import TwoRowLayout from '../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import SearchBox from '../../../core-components/theme/components/Searchbox/Searchbox';
import { Neutrals, Primary } from '../../../core-components/theme/color-palette/colors';
import WorkspaceIcon from '../../../core-components/assets/icons/WorkspaceIcon';
import AssetIcon from '../../../core-components/assets/icons/AssetIcon';
import OptionsMenu from './OptionMenu';
import Table from '../../../core-components/theme/components/Table/Table';
import { deleteWorkspace, getWorkspaceList } from '../../../services/newWorkspaces';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../../core-components/theme/components/Button/Button';

const MAX_VISIBLE_ITEMS = 3;
const WorkspaceListPage = () => {
  // const [workspaces, setWorkspaces] = useState<any[]>([]);
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleSearchQuery = (query: string) => {
    setQuery(query.trim());
  };

  const handleClearSearch = () => {
    setQuery('');
  };

  const editWorkspace = (id: string) => {
    navigate(`/editWorkspace/${id}`, { state: { id } });
  };
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: any) => deleteWorkspace(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['workspaceList'] });
      toast.success(`Workspace deleted`);
    },
    onError: (error: any) => {
      toast.error(`Problem in deleting workspace`);
      console.error('Failed to delete workspace:', error);
    },
  });

  const handleDeleteWorkspace = (id: string) => {
    mutation.mutate(id);
  };

  const { data: workspaceData, status: workspaceDataStatus } = useQuery({
    queryKey: ['workspaceList'],
    queryFn: getWorkspaceList,
    refetchOnWindowFocus: false,
  });
  const getFilteredWorkspaces = () => {
    const filteredRows = workspaceData?.filter((row: any) =>
      row.name.toLowerCase().includes(query.toLowerCase())
    );
    return filteredRows;
  };
  useEffect(() => {
    if (workspaceDataStatus === 'success') {
      // setWorkspaces(workspaceData);
    }
  }, [workspaceData, workspaceDataStatus]);

  const workspaceColumns = [
    {
      field: 'name',
      headerName: 'Workspace Name',
      renderCell: (value: string) => {
        return (
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <WorkspaceIcon color="#193458" />
            <Typography noWrap variant="h4" ml={2}>
              {value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'noOfAssets',
      headerName: 'No of assets',
      align: 'center' as 'center',
    },
    {
      field: 'assets',
      headerName: 'List of assets',
      renderCell: (value: any[]) => {
        const assets = value.slice(0, MAX_VISIBLE_ITEMS);
        const hasMore = value.length > MAX_VISIBLE_ITEMS;
        return (
          <Stack
            direction="row"
            sx={{ maxWidth: '500px', display: 'flex', alignItems: 'center', overflow: 'hidden' }}
          >
            {assets.map(asset => (
              <Box key={asset._id} sx={{ display: 'flex' }}>
                <AssetIcon />
                <Typography variant="label" ml={2} mr={2} noWrap>
                  {asset.name}
                </Typography>
              </Box>
            ))}
            {hasMore && (
              <Tooltip title={value.slice(MAX_VISIBLE_ITEMS).map(item => item.name + ', ')}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    ml: 1,
                  }}
                >
                  ...
                </Typography>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{ pl: 4, pr: 4 }}
        child1={
          <Box sx={{ width: '100%' }}>
            <Button onClick={() => navigate('/createWorkspace/null')} endIcon={<AddIcon />}>
              Create
            </Button>
            <SearchBox
              placeholder="Search Workspace"
              backgroundColor={`${Primary[50]}`}
              sx={{
                width: '100%',
                flexGrow: 1,
                height: '52px',
                borderColor: `${Neutrals[500]}`,
                borderRadius: '4px',
              }}
              onSearch={handleSearchQuery}
              onClear={handleClearSearch}
            />
          </Box>
        }
        child2={
          <Box>
            <Table
              rows={getFilteredWorkspaces() || []} // Render the filtered rows in the table
              columns={workspaceColumns}
              renderOptionsCell={row => (
                <OptionsMenu
                  row={row}
                  handleDeleteWorkspace={handleDeleteWorkspace}
                  handleEditWorkspace={editWorkspace}
                />
              )}
            />
          </Box>
        }
      />
    </LayoutComponent>
  );
};

export default WorkspaceListPage;
