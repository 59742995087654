import LayoutComponent from "../components/LayoutComponent";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import classes from "../styles/pages/DosingConfiguration.module.css";
import { PrimaryButton } from "../styles/global/components/dpButtons";
import dpTheme from "../styles/theme/dpTheme";
import { ThemeProvider } from "@emotion/react";
import { InfoBox } from "../styles/global/components/TextInfoBoxes";
import {
  TankRelationInterface,
  TankOfTankRelationInterface,
  DosingConfigInterface,
} from "../Interfaces/DosingConfigurationIntefaces";
import axiosInstance from "../constants/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import DosingTankRelation from "../components/DosingConfig/DosingTankRelation";
import CircularProgress from "@mui/material/CircularProgress";
import { toast, ToastContainer } from "react-toastify";
import BottomNavigationComponent from "../components/BottomNavigationComponent/BottomNavigationComponent";

// const mockApiResponse: TankRelationInterface[] = [
//   {
//     tank: { id: "1", name: "Tank 1" },
//     tankConnector: {
//       id: "C1",
//       name: "connector C1",
//     },
//     dosingMechanism: "automatic",
//     defaultFlowRate: 40,
//     sensor: {
//       id: "S1",
//       name: "ON/OFF Sensor",
//     },
//   },
//   {
//     tank: { id: "2", name: "Tank 2" },
//     tankConnector: {
//       id: "C2",
//       name: "connector 2",
//     },
//     dosingMechanism: "manual",
//     defaultFlowRate: 20,
//     sensor: {
//       id: "S2",
//       name: "Level Sensor",
//     },
//   },
//   {
//     tank: { id: "3", name: "Mixing Tank" },
//     tankConnector: {
//       id: "C3",
//       name: "Connector 3",
//     },
//     dosingMechanism: "automatic",
//     defaultFlowRate: 60,
//     sensor: {
//       id: "S3",
//       name: "Pressure Sensor",
//     },
//   },
// ];

export default function DosingConfiguration() {
  const { plantId } = useParams();
  const navigate = useNavigate();
  const [dosingConfig, setDosingConfig] = useState<DosingConfigInterface>({
    plantId: plantId as string,
    dosingRelations: [],
  });
  const [errorWhileFetching, setErrorWhileFetching] = useState(false);
  const [tankOptions, setTankOptions] = useState<TankOfTankRelationInterface[]>(
    []
  );
  const [refresh, setRefresh] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  useEffect(() => {
    fetchPlantDosingConfig();
    fetchTankOptions(plantId!);
    // setTankOptions(mockTanks);
  }, []);

  const fetchPlantDosingConfig = async () => {
    console.log("inside fetch plant dosing config");
    try {
      const { data } = await axiosInstance().post(
        `/plants/fetchPlantDosingConfig`,
        { plantId: plantId }
      );
      if (data.data) setDosingConfig(data.data);
    } catch (err) {
      setErrorWhileFetching(true);
    }
  };

  const fetchTankOptions = async (plantId: string) => {
    const { data } = await axiosInstance().get(
      `/plants/fetchTankListForDosingConfig?plantId=${plantId}`
    );
    let tankList = data.data;
    console.log("tanklist : ", tankList);
    setTankOptions(tankList);
    setRefresh(!refresh);
  };

  const handleTankRelationChange = (
    relationIndex: number,
    property: string,
    newValue: any
  ) => {
    dosingConfig["dosingRelations"][relationIndex][property] = newValue;
    setDosingConfig(dosingConfig);
    setRefresh(!refresh);
    console.log("dosingConfig", dosingConfig);
  };

  const addNewTankRelation = () => {
    console.log("dosingConfig : ", dosingConfig);
    let relationIndex = dosingConfig?.dosingRelations?.length;
    const newRelation: TankRelationInterface = {
      tank: { id: relationIndex.toString(), name: "" }, // if you put intial id = "" then react can't seem to differentiate between two tank relations
      tankConnector: { id: "", name: "", connectorType: "" },
      dosingMechanism: "manual",
      defaultFlowRate: 0,
      sensor: { id: "", name: "", sensorTag: "" },
    };
    dosingConfig.dosingRelations.push(newRelation);
    setDosingConfig(dosingConfig);
    console.log("dosingConfig : ", dosingConfig);
    setRefresh(!refresh);
  };

  // const addNewTankRelation = () => {
  //   let newRelationId = `relationId${tankRelationIds.length + 1}`;
  //   setTankRelationIds([...tankRelationIds, newRelationId]);
  // };

  const deleteTankRelation = (relationIndex: number) => {
    dosingConfig.dosingRelations.splice(relationIndex, 1);
    setDosingConfig(dosingConfig);

    console.log("index :", relationIndex);
    setRefresh(!refresh);
  };

  const saveDosingConfig = async () => {
    /// if sensorId od any relation is '', i,e sesnsor is not selected, select the dosingMechanism to manual
    setSaving(true);
    if (dosingConfig) {
      let relations = dosingConfig.dosingRelations;
      relations.forEach((relation) => {
        if (relation?.sensor?.id === "") relation!.dosingMechanism = "manual";
      });
    }
    setDosingConfig(dosingConfig);
    //// save dosing config
    const { data } = await axiosInstance().post(
      `/plants/savePlantDosingConfig`,
      dosingConfig
    );
    setSaving(false);
    toast(data.data.message);
    navigate(`/plants`);
  };

  const getRemaingTankOptions = (index: number) => {
    let dosingRelations = dosingConfig.dosingRelations;
    let filteredTankOptions = tankOptions.filter((option) => {
      let relationIndex = dosingRelations.findIndex((relation: any) => {
        return relation.tank.id === option.id;
      });
      if (relationIndex === index) return true;
      else {
        if (relationIndex !== -1) return false;
        else return true;
      }
    });
    console.log("remaing tank options : ", filteredTankOptions);
    return filteredTankOptions;
  };

  if (errorWhileFetching)
    return (
      <ThemeProvider theme={dpTheme}>
        <LayoutComponent>
          <InfoBox currentStatus="failure" style={{ marginTop: "20px" }}>
            Some Error while fetching data, check connection to dosing
            microservice
          </InfoBox>
        </LayoutComponent>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent>
        <h2>Dosing Management</h2>
        <Box className={classes.pageBody}>
          <InfoBox currentStatus={"success"}>
            All the equipments will be fetched from, plant configuration and
            based on the configuration all these data will be fetched
          </InfoBox>
          <Box sx={{ display: "flex" }}>
            <PrimaryButton
              sx={{ marginLeft: "auto" }}
              onClick={addNewTankRelation}
            >
              Add Tank Relation
            </PrimaryButton>
          </Box>
          <Box className={classes.tankRelationsContainer}>
            {dosingConfig?.dosingRelations?.map((relation, index) => {
              return (
                <DosingTankRelation
                  plantId={plantId}
                  relation={relation}
                  // tankOptions={tankOptions}
                  tankOptions={getRemaingTankOptions(index)}
                  index={index}
                  handleTankRelationChange={handleTankRelationChange}
                  deleteTankRelation={deleteTankRelation}
                />
              );
            })}
          </Box>
          <PrimaryButton
            sx={{ width: "150px", marginLeft: "auto" }}
            onClick={() => {
              saveDosingConfig();
            }}
          >
            <span>Save</span>
            {saving ? (
              <CircularProgress
                size={20}
                sx={{ color: "#fff", marginLeft: "10px" }}
              />
            ) : null}
          </PrimaryButton>
          <Box
            m={4}
            p={3}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BottomNavigationComponent
              plantId={plantId!}
              currentPage="Dosing"
            />
          </Box>
        </Box>
        <ToastContainer />
      </LayoutComponent>
    </ThemeProvider>
  );
}
