import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  ThemeProvider,
  Typography,
  DialogContentText,
} from "@mui/material";
import dpTheme from "../../styles/theme/dpTheme";
import { PrimaryRoundedButton } from "../../styles/global/components/dpButtons";

const FailedPopup = (
  showFailedPopup: boolean,
  setShowFailedPopup: Function,
  message: string
) => {
  const handleClose = () => {
    setShowFailedPopup(false);
  };
  return (
    <>
      <ThemeProvider theme={dpTheme}>
        <Dialog open={showFailedPopup} maxWidth={"lg"} onClose={handleClose}>
          <DialogTitle>
            <Typography variant={"h2"}>Control Failed !</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant={"h4"}>{message}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryRoundedButton onClick={handleClose}>
              OKAY
            </PrimaryRoundedButton>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default FailedPopup;
