const styledBox = {
  border: "2px solid black",
  borderRadius: 2,
  margin: "20px 0",
  padding: 3,
};

const styledBoxWithShadow = {
  // border: "2px solid #dddddd",
  // borderRadius: 2,
  // margin: "20px 0",
  // padding: 3,
  // boxShadow: "5px 5px #dddddd"
};

const styledTitle = {
  paddingLeft: "9px",
};

const styledEquipmentTitle = {
  paddingLeft: "9px",
  fontSize: "20px",
};

export { styledBox, styledTitle, styledEquipmentTitle, styledBoxWithShadow };
