import { Box, Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SuccessIcon from "../../assets/icons/SuccessIcon.svg";
import FailIcon from "../../assets/icons/FailIcon.svg";
import { AlertStyles, DialogSuccessButtonStyle } from "./AlertsStyles";

function MessageDialog({ message, type, closeMethod, showMessageDialog }: any) {
  return (
    <Dialog
      open={showMessageDialog}
      onClose={() => {
        closeMethod();
        // setShowProcessingResult(false);
      }}
    >
      <>
        <DialogContent>
          <Box sx={AlertStyles.ProcessingStyle}>
            <img
              src={type === "success" ? SuccessIcon : FailIcon}
              alt="status Icon"
              style={AlertStyles.IconStyles}
            />
            {message}
          </Box>
        </DialogContent>
        <DialogActions sx={AlertStyles.ProcessingStyle}>
          <DialogSuccessButtonStyle
            onClick={() => {
              closeMethod();
            }}
          >
            Continue
          </DialogSuccessButtonStyle>
        </DialogActions>
      </>
    </Dialog>
  );
}

export default MessageDialog;
