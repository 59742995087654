//@ts-nocheck

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CurrentLayoutDiagram from '../components/LayoutVisualisation/CurrentLayoutDiagram';
import { usePlantLayoutStore } from '../store/PlantLayoutStore';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { fetchPlantLayoutData } from '../services/PlantVisualisationConfig';
import { getBidirectionalSensorsForPlant } from '../services/BiDirectional';
import { Typography, Box } from '@mui/material';
import { markPersistentNotifAsRead, fetchAllUnreadPersistentNotif } from '../services/notification';
import classes from '../styles/dashboardPage.module.css';
import { checkBiDirectionalLoadingStatus } from '../services/BiDirectional';
import { InfoBox } from '../styles/global/components/TextInfoBoxes';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import useClientSideLayoutStore from '../store/ClientSideLayoutStore';
import { getTimeBasedLayoutDataForAssetId } from '../services/sseApis';
import CircularProgress from '@mui/material/CircularProgress';
import useUIControlStore from '../store/UIControlStore';

function LayoutVisualisation(props) {
  const backendApi = import.meta.env.VITE_APP_ENDPOINT;
  const navigate = useNavigate();
  // const { plantId } = usePlantLayoutStore();
  const { assetId } = useParams<string>();
  console.log('assetId : ', assetId);
  const plantId = assetId;

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [showDiagram, setShowDiagram] = useState(false);
  const { setBidirectionalSensorsForPlant } = usePlantLayoutStore();

  const [listening, setListening] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [isEventsInitialized, setIsEventsInitialized] = useState(false);
  const { loadingCurrentLayoutDiagram, setLoadingCurrentLayoutDiagram } =
    useClientSideLayoutStore();
  const [fullScreenView, setFullScreenView] = useState(false);

  const { visualisationFullSize } = useUIControlStore();

  const { sseSwitchedOn, timeFrame } = props;

  useEffect(() => {
    //@ts-ignore
    getBidirectionalSensorsForPlant(plantId).then(biSensorIds => {
      setBidirectionalSensorsForPlant(biSensorIds);
      setShowDiagram(true);
    });
  }, [assetId]);

  useEffect(() => {
    setFullScreenView(visualisationFullSize);
  }, [visualisationFullSize]);

  // useEffect(() => {
  //   console.log('inside ue');
  //   let events;
  //   try {
  //     if (plantId) {
  //       if (!listening) {
  //         // https://testingapi.digitalpaani.com
  //         //http://localhost:4000
  //         // const events = new EventSourcePolyfill(
  //         //   `${backendApi}/sseRoutes/getPlantLayoutEvents?plantId=${plantId}`,
  //         //   {
  //         //     headers: {
  //         //       Authorization: `Bearer ${localStorage.token}`,
  //         //     },
  //         //     heartbeatTimeout: 9000000,
  //         //     mode: 'cors',
  //         //   }
  //         // );

  //         events = new EventSourcePolyfill(
  //           `${backendApi}/sseRoutes/getLayoutDiagramEvents?assetId=${assetId}`,
  //           {
  //             headers: {
  //               Authorization: `Bearer ${localStorage.token}`,
  //             },
  //             heartbeatTimeout: 9000000,
  //             mode: 'cors',
  //           }
  //         );

  //         events.onmessage = event => {
  //           const plantLayoutData = JSON.parse(event.data);
  //           console.log('edges : ', plantLayoutData.edges);
  //           setNodes(plantLayoutData.nodes);
  //           setEdges(plantLayoutData.edges);
  //           setIsEventsInitialized(true);
  //         };

  //         // fetchPlantLayoutData(plantId).then((plantLayoutData) => {
  //         //   setNodes(plantLayoutData.nodes);
  //         //   setEdges(plantLayoutData.edges);
  //         // });

  //         setRefresh(!refresh);
  //       }
  //     } else {
  //       navigate('/404');
  //     }
  //   } catch (err) {}

  //   return () => {
  //     if (events) {
  //       setNodes([]);
  //       setEdges([]);
  //       events.close();
  //       setIsEventsInitialized(false);
  //       console.log('SSE connection closed');
  //     }
  //   };
  // }, [assetId]);

  useEffect(() => {
    if (sseSwitchedOn) {
      // Initialize SSE
      setNodes([]);
      setEdges([]);
      setLoadingCurrentLayoutDiagram(true);
      const events = new EventSourcePolyfill(
        `${backendApi}/sseRoutes/getLayoutDiagramEvents?assetId=${assetId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
          heartbeatTimeout: 9000000,
          mode: 'cors',
        }
      );

      events.onmessage = event => {
        setLoadingCurrentLayoutDiagram(false);
        const plantLayoutData = JSON.parse(event.data);
        console.log('plantLayoutData : ', plantLayoutData);
        setNodes(plantLayoutData.nodes);
        setEdges(plantLayoutData.edges);
      };

      return () => {
        events.close();
        setNodes([]);
        setEdges([]);
        console.log('SSE connection closed');
      };
    } else {
      // Fetch data from API
      setLoadingCurrentLayoutDiagram(true);
      getTimeBasedLayoutDataForAssetId(assetId, timeFrame).then(plantLayoutData => {
        setNodes(plantLayoutData.nodes);
        setEdges(plantLayoutData.edges);
        setLoadingCurrentLayoutDiagram(false);
      });
    }
  }, [assetId, timeFrame, sseSwitchedOn]);

  return (
    <>
      <Box
        sx={{
          padding: '0',
          textAlign: 'center',
          height: fullScreenView
            ? { xs: '80vh', sm: '77vh', md: '77vh' }
            : { xs: '50vh', sm: '65vh', md: '68vh' },
          border: '1px solid #999999',
          borderRadius: '12px',
          overflow: 'hidden',
          marginBottom: '5px',
        }}
      >
        {!loadingCurrentLayoutDiagram && nodes?.length > 0 && (
          <Box sx={{ height: '100%' }}>
            <CurrentLayoutDiagram nodes={nodes} edges={edges} />
          </Box>
        )}
        {!loadingCurrentLayoutDiagram && nodes?.length == 0 && (
          <Box sx={{ height: '50vh', display: 'grid', placeItems: 'center' }}>
            No nodes were configured for this asset or the configured nodes have display disabled!
          </Box>
        )}
        {loadingCurrentLayoutDiagram && (
          <Box sx={{ height: '100%', display: 'grid', placeItems: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </>
  );
}

export default LayoutVisualisation;
