import React from 'react';
import useIsMobile from '../../../utilities/customHooks/useIsMobile';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';

const styles = {
  tabStyles: {
    marginRight: '1.5rem',
    whiteSpace: 'nowrap',
    '& .Mui-selected': {
      color: '#2196F3',
    },
    '& .MuiTab-root': {
      color: '#00000099',
    },
  },
};

const AssetTabComponent = ({
  selectedStoreAssets,
  setSelectedStoreAssets,
  selectedAssetTab,
  setSelectedAssetTab,
  assetName,
  setAssetName,
}: any) => {
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    const selectedAsset = selectedStoreAssets.find((asset: any) => asset.plantName === newValue);

    // If the selectedAsset is found, update the state with _id and name
    if (selectedAsset) {
      setSelectedAssetTab({ _id: selectedAsset._id, plantName: selectedAsset.plantName });
    }

    setAssetName(newValue);
  };

  return (
    <>
      <Box
        sx={{
          margin: !isMobile ? '.5rem 0rem' : '',
          marginTop: isMobile ? '.5rem' : '',
          marginBottom: isMobile ? '1rem' : '1rem',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        <TabContext value={assetName}>
          <TabList onChange={handleTabChange} variant="scrollable" scrollButtons={false}>
          <Tab label="All Asset" value="All Asset" sx={styles.tabStyles} />
            {selectedStoreAssets?.map((assets: any, index: number) => (
              <Tab
                key={index}
                value={assets.plantName}
                label={assets.plantName}
                sx={index === selectedStoreAssets.length - 1 ? {} : styles.tabStyles}
              />
            ))}
          </TabList>
        </TabContext>
      </Box>
    </>
  );
};

export default AssetTabComponent;
