import { Button } from '@mui/material';
import { styled } from '@mui/system';
import dpTheme from '../../theme/dpTheme';
import color from '../../color';

const palette = dpTheme.palette;

// sky blue colored button as seen on downloadpdf button on dashboardpage
const PrimaryButton = styled(Button)({
  backgroundColor: palette.primary.main,
  color: palette.secondaryText.main,
  textTransform: 'none',
  height: 'clamp(50px,3.5vw,65px)',
  fontSize: 'clamp(15px,1vw,25px)',
  letterSpacing: '0.4px',
  fontFamily: 'Roboto,sans-serif',
  paddingLeft: '20px',
  paddingRight: '20px',
  fontWeight: '500',
  '&:hover': {
    backgroundColor: palette.primary.light,
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
});

const SecondaryButton = styled(Button)({
  backgroundColor: palette.secondary.main,
  color: palette.secondaryText.main,
  // margin: "0 1.2rem 1.2rem 0",
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: palette.secondary.light,
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
});
const AddAntiBlueButton = styled(Button)({
  backgroundColor: color.dpWhite,
  color: color.dpLightBlue,
  border: `1px solid ${color.dpLightBlue}`,
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: ' 24px',
  letterSpacing: '0.4px',
  textAlign: 'left',

  '&:hover': {
    backgroundColor: color.dpLightBlue,
    color: '#fff',
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
});

const AddBlueButton = styled(Button)({
  backgroundColor: color.dpLightBlue,
  color: color.dpWhite,
  border: `1px solid ${color.dpLightBlue}`,
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: ' 24px',
  letterSpacing: '0.4px',
  textAlign: 'left',

  '&:hover': {
    backgroundColor: color.dpWhite,
    color: color.dpLightBlue,
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
});

const WhiteButton = styled(Button)({
  backgroundColor: palette.secondaryText.main,
  color: color.dpDarkBlue,
  border: `1px solid ${color.dpDarkBlue}`,
  // margin: "0 1.2rem 1.2rem 0",
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: palette.secondary.light,
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
});

const BlackButton = styled(Button)({
  backgroundColor: '#000000',
  color: '#ddd',
  border: '1px solid black',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#fff',
    color: '#000',
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
});

const DialogPositiveButtonStyle = styled(Button)({
  backgroundColor: color.dpGreenButton,
  color: color.successDark,
  textTransform: 'capitalize',
  // border: `1px solid ${color.successDark}`,
  border: 'none',
  padding: '0.6rem 1.4rem',
  '&:hover': {
    backgroundColor: color.dpGreenButtonOnHover,
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
  [dpTheme.breakpoints.down('sm')]: {
    padding: '0.5rem 0.8rem',
  },
});

const DialogNegativeButtonStyle = styled(Button)({
  backgroundColor: color.dpRedButton,
  color: 'white',
  textTransform: 'capitalize',
  padding: '0.6rem 1.4rem',
  border: `1px solid ${color.dpRedButton}`,

  '&:hover': {
    backgroundColor: color.dpRedButtonOnHover,
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
  [dpTheme.breakpoints.down('sm')]: {
    padding: '0.5rem 0.8rem',
  },
});

const DialogSuccessButtonStyle = styled(Button)({
  backgroundColor: palette.success.main,
  color: palette.success.dark,
  margin: '0 1.2rem 0 0',
  textTransform: 'capitalize',
  '&:hover': {
    backgroundColor: palette.success.main,
  },
});

const PrimaryRoundedButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: palette.primary.main,
  color: palette.secondaryText.main,
  textTransform: 'none',
  height: 'clamp(40px,2.5vw,50px)',
  fontSize: 'clamp(15px,1vw,25px)',
  letterSpacing: '0.4px',
  fontFamily: 'Roboto,sans-serif',
  paddingLeft: '40px',
  paddingRight: '40px',
  fontWeight: '500',
  '&:hover': {
    backgroundColor: palette.primary.light,
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
  borderRadius: '20px',
});

const StyledActionableButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: palette.primary.main,
  color: palette.secondaryText.main,
  textTransform: 'none',
  height: 'clamp(40px,2.5vw,50px)',
  fontSize: 'clamp(15px,1vw,25px)',
  letterSpacing: '0.4px',
  fontFamily: 'Roboto,sans-serif',
  paddingLeft: '40px',
  paddingRight: '40px',
  fontWeight: '500',
  '&:hover': {
    backgroundColor: palette.primary.light,
  },
  '&:disabled': {
    backgroundColor: palette.disabled.main,
    color: palette.secondaryText.main,
  },
  borderRadius: '20px',
});

const PreviousPageButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  paddingLeft: '10px',
  paddingRight: '10px',
  backgroundColor: color.errorMain,
  color: color.dpWhite,
  textTransform: 'none',
  height: 'clamp(40px,2.5vw,50px)',
  fontSize: 'clamp(15px,1vw,25px)',
  letterSpacing: '0.4px',
  fontFamily: 'Roboto,sans-serif',
  // padding: "1rem 2.5rem",
  fontWeight: '500',
  opacity: '0.65',
  // border: `solid 1px ${color.errorMain}`,
  borderRadius: '0.3rem',
  '&:hover': {
    opacity: '1',
    backgroundColor: color.errorMain,
  },
});

const PrimaryInfoButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: color.primaryMain,
  color: color.dpWhite,
  textTransform: 'none',
  height: 'clamp(40px,2.5vw,50px)',
  fontSize: 'clamp(15px,1vw,25px)',
  letterSpacing: '0.4px',
  fontFamily: 'Roboto,sans-serif',
  padding: '1rem 2.5rem',
  fontWeight: '500',
  opacity: '0.65',
  borderRadius: '0.3rem',
  '&:hover': {
    opacity: '1',
    backgroundColor: color.primaryMain,
  },
});

const SecondaryInfoButton = styled(Button)({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: color.infoMain,
  color: color.dpWhite,
  textTransform: 'none',
  height: 'clamp(40px,2.5vw,50px)',
  fontSize: 'clamp(15px,1vw,25px)',
  letterSpacing: '0.4px',
  fontFamily: 'Roboto,sans-serif',
  padding: '1rem 2.5rem',
  fontWeight: '500',
  opacity: '0.65',
  borderRadius: '0.3rem',
  '&:hover': {
    opacity: '1',
    backgroundColor: color.infoMain,
  },
});

const IconButton = styled(Button)({
  borderRadius: '7px',
  background: '#FFF',
  boxShadow: '0px 2px 2px 0px rgba(26, 51, 86, 0.25)',
  maxHeight: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '10px 8px',
});

export {
  PrimaryButton,
  SecondaryButton,
  WhiteButton,
  BlackButton,
  DialogPositiveButtonStyle,
  DialogNegativeButtonStyle,
  DialogSuccessButtonStyle,
  PrimaryRoundedButton,
  StyledActionableButton,
  AddBlueButton,
  AddAntiBlueButton,
  PreviousPageButton,
  PrimaryInfoButton,
  SecondaryInfoButton,
  IconButton,
};
