import { Button } from '@mui/material';
import { styled } from '@mui/material';
import { Theme } from '@mui/material/styles';

interface IProps {
  theme: Theme;
  textColor: string;
  backgroundColor: string;
}

const InventoryOptionButton = styled(Button)(({ theme, textColor, backgroundColor }: IProps) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: backgroundColor,
  color: textColor,
  textTransform: 'none',
  height: 'clamp(40px,2.5vw,50px)',
  fontSize: 'clamp(15px,2.5vw,20px)',
  letterSpacing: '0.4px',
  fontFamily: 'Roboto,sans-serif',
  padding: '2rem 4rem',
  // fontWeight: "bold",
  opacity: '1',
  borderRadius: '0.3rem',
  border: `1px solid ${textColor}`,
  [theme.breakpoints.down('sm')]: {
    padding: '1rem 0',
    width: '100%',
  },
  '&:hover': {
    opacity: '0.85',
    backgroundColor: backgroundColor,
  },
  '&:disabled': {
    opacity: '1',
    backgroundColor: '#dddddd',
    border: `1px solid #999999`,
  },
}));

const InventoryOptionButton2 = styled(Button)(({ theme, textColor, backgroundColor }: IProps) => ({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: backgroundColor,
  color: textColor,
  textTransform: 'none',
  height: 'auto',
  fontSize: 'clamp(15px,2.5vw,20px)',
  letterSpacing: '0.4px',
  fontFamily: 'Roboto,sans-serif',
  padding: '1.6rem 4rem',
  // fontWeight: "bold",
  opacity: '1',
  borderRadius: '0.3rem',
  border: `1px solid ${textColor}`,
  [theme.breakpoints.down('sm')]: {
    padding: '1rem 0',
    width: '100%',
  },
  '&:hover': {
    opacity: '0.85',
    backgroundColor: backgroundColor,
  },
  '&:disabled': {
    opacity: '1',
    backgroundColor: '#dddddd',
    border: `1px solid #999999`,
  },
}));

export { InventoryOptionButton, InventoryOptionButton2 };
