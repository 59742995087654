import { useRef, useState } from "react";
import { TextField, IconButton, Stack, Box } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { isNameProperlyFormatted } from "../../utils/name.utils";
import { toast } from "react-toastify";

import useOutsideClickDetector from "../../utilities/customHooks/useOutsideClickDetector";
import { delay } from "../../utils/time.utils";

interface propsInterface {
  widgetClusterIndex: number;
  widgetIndex: number;
  text: string;
  changeHeadingWidgetHeading: Function;
}

const styles = {
  textStyle: {
    fontSize: 20,
    fontWeight: 500,
  },
};

export default function HeadingWidgetConfig(props: propsInterface) {
  const { widgetClusterIndex, widgetIndex, text, changeHeadingWidgetHeading } =
    props;
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const textFieldRef = useRef(null);
  useOutsideClickDetector(textFieldRef, async () => {
    await delay(500);
    setAllowEdit(false);
  });

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{ minWidth: "350px" }}
    >
      {allowEdit ? (
        <TextField
          fullWidth
          type="text"
          defaultValue={text! ?? ""}
          inputRef={textFieldRef}
          variant="standard"
          multiline
          maxRows={2}
          disabled={false}
          inputProps={{
            maxLength: 60,
            style: styles.textStyle,
          }}
          onBlur={(e) => {
            console.log("abc blur");
            if (isNameProperlyFormatted(e.target.value)) {
              changeHeadingWidgetHeading(
                widgetClusterIndex,
                widgetIndex,
                "english",
                e.target.value
              );
              // setAllowEdit(false);
            } else {
              toast.error("Nicknames cannot be a empty string!");
              if (textFieldRef && textFieldRef.current != null) {
                let a: any = textFieldRef.current;
                a.value = text;
                a.focus();
              }
            }
          }}
        />
      ) : (
        <Box sx={styles.textStyle}>{text}</Box>
      )}
      <IconButton
        onClick={() => {
          console.log("clicked pen");
          setAllowEdit(true);
        }}
      >
        <EditIcon />
      </IconButton>
    </Stack>
  );
}
