//@ts-nocheck
import {
  Box,
  Grid,
  Stack,
  TextField,
  useMediaQuery,
  Theme,
  Typography,
  IconButton,
  Collapse,
  Divider,
} from '@mui/material';

import TwoRowLayout from '../../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import SearchBox from '../../../../core-components/theme/components/Searchbox/Searchbox';
import { Neutrals, Primary } from '../../../../core-components/theme/color-palette/colors';
import Tabs from '../../../../core-components/theme/components/Tabs/Tabs';
import CustomTreeComponent from '../../../../core-components/theme/components/Tree/CustomTree';
import React, { useEffect, useRef, useState } from 'react';
import LayoutComponent from '../../../../components/LayoutComponent';
import Button from '../../../../core-components/theme/components/Button/Button';
import { filterItemsByCriteria } from '../../permissions/utility/helper';
import CheckboxList from '../../../../core-components/theme/components/List/List';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { getUserItemlabel } from '../../helpers/customUserTreeHelpers';
import CustomUserGroupTreeItem from './CustomUserGroupTree';
import CustomWorkspaceTreeItem from '../../../workspace-management/component/CustomWorkspaceTree';
import { getWorkspaceItemlabel } from '../../../workspace-management/helpers/customtreehelpers';
import { getModules, idstoworkspace } from '../hooks/moduleHandler';
import { useMutation, UseMutationResult, useQuery } from '@tanstack/react-query';
import {
  getFeatureList,
  getUserList,
  getWorkspaceList,
  saveUserGroup,
} from '../../../../services/userGroup/userGroup';
import { useNavigate, useParams } from 'react-router-dom';
import { isNameProperlyFormatted } from '../../../../utils/name.utils';
import { toast } from 'react-toastify';
import { isValidNameFormat } from '../../../../utilities/name.utils';
import { isDescriptionProperlyFormatted } from '../../../../utilities/description.utils';
interface FormData {
  name: string;
  description: string;
  users: string[];
  workspaceAssets: WorkspaceAsset[];
  featureTags: string[];
}
interface WorkspaceAsset {
  workspace: string;
  assets: string[];
}
const GroupCreatePage = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [value, setValue] = useState(0);
  const [mode, setMode] = useState('create');
  const [userGroupId, setUserGroupId] = useState('');
  const [isUserFetched, setUserFetched] = useState(false);
  const [OriginalfilteredData, setOriginalfilteredData] = useState<any[]>();
  const [isFeatureListFetched, setFeatureListFetched] = useState(false);
  const [isWorkspaceFetched, setWorkspaceFetched] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [filterWorkspace, setFilterWorkspace] = useState([]);
  const [originalWorkspace, setOriginalWorkspace] = useState([]);

  const { data: userDetails, status: userStatus } = useQuery({
    queryKey: ['userDetails', value],
    queryFn: () => getUserList({ mode: mode, ...(userGroupId && { userGroupId: userGroupId }) }),
    initialData: users,
    enabled: value === 0 && !isUserFetched,
  });

  const { data: moduleList, status: modulesStatus } = useQuery({
    queryKey: ['moduleDetails', value],
    queryFn: () =>
      getFeatureList({
        mode: mode,
        ...(userGroupId && { userGroupId: userGroupId }),
      }),
    enabled: value === 1 && !isFeatureListFetched,
  });

  const { data: workspaceList, status: workspaceStatus } = useQuery({
    queryKey: ['workspaceDetails', value],
    queryFn: () =>
      getWorkspaceList({ mode: mode, ...(userGroupId && { userGroupId: userGroupId }) }),
    enabled: value === 2 && !isWorkspaceFetched,
  });

  const [formData, setFormData] = useState<FormData>({
    name: '',
    description: '',
    users: [],
    workspaceAssets: [],
    featureTags: [],
  });
  const [query, setQuery] = useState('');
  const [selectedTreeItems, setSelectedTreeItems] = useState([]);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  function handleTreeSelected(val: any) {
    setFormData({
      ...formData,
      featureTags: val,
    });
  }

  function handleWorkspaceSelected(val: any) {
    setFormData({
      ...formData,
      workspaceAssets: val,
    });
  }

  const mutation: UseMutationResult<void, unknown, FormData> = useMutation({
    mutationFn: (formData: FormData) => saveUserGroup(formData),
    onSuccess: data => {
      toast.success(`User Group created`);
      navigate('/userGroupList/'); // Redirect to another page
      console.log('Mutation successful:', data);
    },
    onError: error => {
      toast.error(`Failed to Create User Group`);
      console.error('Mutation failed:', error);
    },
  });

  const handlePost = (): void => {
    const updatedFormData = { ...formData };
    updatedFormData.name = updatedFormData.name.trim();
    if (!isValidNameFormat(updatedFormData.name)) {
      toast.error('Name cannot be empty and max length 30');
      return;
    }
    updatedFormData.description = updatedFormData.description?.trim() || '';
    if (!isDescriptionProperlyFormatted(updatedFormData.description)) {
      toast.error(`Description cannot be this long, max length is 200 characters`);
      return;
    }
    updatedFormData.featureTags = getModules(originalItems, formData.featureTags);
    updatedFormData.workspaceAssets = idstoworkspace(originalWorkspace, formData.workspaceAssets);
    if (updatedFormData.featureTags.length === 0) {
      toast.error(`Please select atleast one feature`);
      return;
    }
    if (updatedFormData.workspaceAssets.length === 0) {
      toast.error(`Please select atleast one workspace`);
      return;
    }
    mutation.mutate(updatedFormData);
  };
  const searchBoxRef = useRef(null);
  const handleChange = (newValue: React.SetStateAction<number>) => {
    setValue(newValue);
    if (searchBoxRef.current) {
      //@ts-ignore
      searchBoxRef.current.handleClear();
    }
    setQuery('');
  };

  const placeHolder = (index: number) => {
    switch (index) {
      case 0:
        return 'Search for user';
      case 1:
        return 'Search for module';
      case 2:
        return 'Search for workspace';
      default:
        return '';
    }
  };
  function handlestateChange(val: number[]) {
    setFormData({
      ...formData,
      users: val,
    });
    setUsers(val);
  }

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [filteredData, setFilteredData] = useState([]);
  const [workspacerowsdata] = useState([]);
  const handleSearch = (query: string) => {
    setQuery(query);
    if (value === 0) {
      const filteredRows = OriginalfilteredData.filter(item =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredData(filteredRows);
    }
    if (value === 1) {
      const filtered = filterItemsByCriteria(originalItems, query, []);
      setFilterItems(filtered);
    }
    if (value === 2) {
      const filtered = filterItemsByCriteria(originalWorkspace, query, []);
      setFilterWorkspace(filtered);
    }
  };

  useEffect(() => {
    if (userStatus === 'success') {
      setFilteredData(userDetails);
      setOriginalfilteredData(userDetails);
      setUserFetched(true);
    } else if (userStatus === 'error' || userStatus === 'idle') {
      setUserFetched(false);
    }
  }, [userStatus, userDetails]);
  useEffect(() => {
    if (modulesStatus === 'success') {
      setFilterItems(moduleList);
      setOriginalItems(moduleList);
      setFeatureListFetched(true);
    } else if (modulesStatus === 'error' || modulesStatus === 'idle') {
      setFeatureListFetched(false);
    }
  }, [modulesStatus, moduleList]);

  useEffect(() => {
    if (workspaceStatus === 'success') {
      setFilterWorkspace(workspaceList);
      setOriginalWorkspace(workspaceList);
      setWorkspaceFetched(true);
    } else if (workspaceStatus === 'error' || workspaceStatus === 'idle') {
      setWorkspaceFetched(false);
    }
  }, [workspaceStatus, workspaceList]);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate(`/userGroupList`);
  };

  const handleClear = () => {
    setQuery('');
    if (value === 0) {
      setFilteredData(filteredData);
    }
    if (value === 1) {
      setFilterItems(originalItems);
    }
    if (value === 2) {
      setFilterWorkspace(originalWorkspace);
    }
  };
  function handleSelected(val: string) {
    if (!selectedIds.includes(val)) {
      setSelectedIds(prev => [...prev, val]);
    }
    return;
  }
  const tabs = [
    {
      label: 'User List',
      Component: (
        <CheckboxList
          initialChecked={formData.users}
          items={filteredData}
          onToggle={handlestateChange}
        />
      ),
    },
    {
      label: 'Module List',
      Component: (
        <CustomTreeComponent
          CustomTreeItem={CustomUserGroupTreeItem}
          selectedIds={formData.featureTags}
          onSelect={handleSelected}
          handleTreeSelected={handleTreeSelected}
          selectedTreeItems={selectedTreeItems}
          checkboxSelection={true}
          multiSelect={true}
          getItemId={(item: { id: string }) => item.itemId}
          getItemlabel={getUserItemlabel}
          items={filterItems}
          isFeatureList={true}
        />
      ),
    },
    {
      label: 'Workspace List',
      Component: (
        <CustomTreeComponent
          CustomTreeItem={CustomWorkspaceTreeItem}
          selectedIds={formData.workspaceAssets}
          onSelect={handleSelected}
          handleTreeSelected={handleWorkspaceSelected}
          selectedTreeItems={selectedTreeItems}
          checkboxSelection={true}
          multiSelect={true}
          getItemId={(item: { itemId: string }) => item.itemId}
          getItemlabel={getWorkspaceItemlabel}
          items={filterWorkspace}
          isWorkspaceList={true}
        />
      ),
    },
  ];
  const isButtonDisabled =
    !formData.name ||
    formData.users.length === 0 ||
    formData.featureTags.length === 0 ||
    formData.workspaceAssets.length === 0;
  function handleFormData(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const [open, setOpen] = useState(true);

  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{
          pl: isMobile ? 2 : 4,
          pr: isMobile ? 2 : 4,
        }}
        child1={
          <Stack direction="column" width="100%">
            <Box
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: `${Primary[50]}`,
                borderRadius: 1,
                padding: isMobile ? '16px 24px' : '33px 23px',
                border: '1px solid #bdbdbd',
              }}
            >
              <Grid container direction="column" borderRadius="4px">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={handleToggle}
                >
                  {isMobile && (
                    <Typography
                      lineHeight="24px"
                      letterSpacing="0.15px"
                      color="#666"
                      variant="body"
                    >
                      User Group Details
                    </Typography>
                  )}
                  {isMobile && <IconButton>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>}
                </Grid>
                {isMobile && open && (
                  <Divider variant="fullWidth" sx={{ mt: 1, mb: '24px', color: '#bdbdbd' }} />
                )}
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    borderRadius="4px"
                    justifyContent="space-around"
                    alignItems="center"
                    gap={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={4}
                      width={isMobile ? '100%' : undefined}
                      p={isMobile ? 2 : undefined}
                    >
                      <TextField
                        label="Group Name"
                        placeholder="Group Name"
                        value={formData.name}
                        name="name"
                        onChange={handleFormData}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={7}
                      p={isMobile ? 2 : undefined}
                      width={isMobile ? '100%' : undefined}
                    >
                      <TextField
                        label="Description"
                        name="description"
                        value={formData.description}
                        placeholder="Workspace Description"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Box>
            <Box sx={{ width: '100%' }}>
              <SearchBox
                placeholder={placeHolder(value)}
                backgroundColor={`${Primary[50]}`}
                sx={{
                  width: '100%',
                  flexGrow: 1,
                  mt: 2,
                  mb: 2,
                  height: '52px',
                  borderColor: `${Neutrals[500]}`,
                  borderRadius: '4px',
                }}
                ref={searchBoxRef}
                onSearch={handleSearch}
                onClear={handleClear}
              />
            </Box>
          </Stack>
        }
        child2={
          <Box p={0}>
            <Tabs tabs={tabs} value={value} handleChange={handleChange} />
            <Box
              pt={4}
              gap={6}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: isMobile ? 'center' : 'flex-end',
              }}
            >
              <Button
                variant="contained"
                sx={{
                  border: `1px solid ${Neutrals[500]}`,
                  borderRadius: '4px',
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  backgroundColor: 'white',
                  color: `${Neutrals[500]}`,
                  flex: isMobile ? 1 : 0,
                  boxShadow: 'none',
                  ':hover': { background: 'none', boxShadow: 'none' },
                }}
                onClick={handleCancel}
              >
                <Typography variant="body" color="Neutrals.500" lineHeight="24px">
                  Cancel
                </Typography>
              </Button>
              <Button
                disabled={isButtonDisabled}
                variant="contained"
                onClick={handlePost}
                sx={{
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  flex: isMobile ? 1 : 0,
                  borderRadius: '4px',
                  backgroundColor: `${Primary[500]}`,
                  boxShadow: 'none',
                  ':hover': { boxShadow: 'none' },
                }}
              >
                <Typography variant="body" lineHeight="24px" color="white">
                  Save
                </Typography>
              </Button>
            </Box>
          </Box>
        }
      />
    </LayoutComponent>
  );
};

export default GroupCreatePage;
