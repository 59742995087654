import { Box, Stack, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { getDataBreakEventsForAssetId } from '../../services/dataBreak/dataBreak';
import { useParams } from 'react-router-dom';
import DateTimeForTableCell from '../../core-components/theme/components/DateTime/DateTimeForTableCell';
import DataGrid from '../../core-components/theme/components/DataGrid/DataGrid';
import InfoIcon from '@mui/icons-material/Info';

const columns = [
  {
    field: 'plcName',
    headerName: 'PLC Name',
    width: 200,
  },
  {
    field: 'from',
    headerName: 'From',
    width: 250,
    renderCell: (params: any) => (
      <DateTimeForTableCell date={params.row.from as string} dateStringFormat="" />
    ),
  },
  {
    field: 'to',
    headerName: 'To',
    width: 200,
    renderCell: (params: any) => (
      <DateTimeForTableCell date={params.row.to as string} dateStringFormat="" />
    ),
  },
  {
    field: 'reason',
    headerName: 'Possible Reason',
    width: 400,
    renderCell: (params: any) => (
      <Stack direction="row" alignItems="center" gap={1}>
        <Tooltip
          title={
            params.row.confidenceAboutReason == 1
              ? '100% confident the reason for data break is as mentioned!'
              : 'Low confidence on the mentioned reason for data break!'
          }
        >
          <InfoIcon
            sx={{ width: '18px' }}
            color={params.row.confidenceAboutReason == 1 ? 'primary' : 'secondary'}
          />
        </Tooltip>
        <Box>{params.row.reason}</Box>
      </Stack>
    ),
  },
  {
    field: 'recoveryStatus',
    headerName: 'Recovery Status',
    width: 200,
    renderCell: (params: any) => (
      <Stack direction="column">
        <Box>{params.row.recoveryStatus}</Box>
        <Box
          sx={{ fontSize: '11px', color: '#777' }}
        >{`${params.row.recoveredPercentage}% Recovered`}</Box>
      </Stack>
    ),
  },
];

interface DataBreakForSinglePlantTableProps {
  searchQuery: string;
}

const DataBreakDetailsForSinglePlantTable = (props: DataBreakForSinglePlantTableProps) => {
  const [events, setEvents] = useState<any[]>([]);
  const [filteredEvents, setFilteredEvents] = useState<any[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { assetId } = useParams();
  const { searchQuery } = props;

  useEffect(() => {
    loadMoreEvents();
  }, []);

  useEffect(() => {
    const tempEvents = events.filter(
      event =>
        event.reason?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.recoveryStatus?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.plcName?.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredEvents(tempEvents);
  }, [searchQuery, events]);

  const loadMoreEvents = async () => {
    setLoading(true);
    const data = await getDataBreakEventsForAssetId(assetId!, page * 10, 10);
    if (data.length === 0) {
    } else {
      setEvents(prevEvents => [...prevEvents, ...data]);
      setFilteredEvents(prevEvents => [...prevEvents, ...data]);
      setPage(prevPage => prevPage + 1);
    }
    setLoading(false);
  };

  return (
    <Box>
      <DataGrid
        rows={filteredEvents}
        columns={columns}
        loading={loading}
        onRowsScrollEnd={loadMoreEvents}
      />
    </Box>
  );
};

export default DataBreakDetailsForSinglePlantTable;
