import { useState, useEffect } from 'react';
import theme from '../../styles/theme/dpTheme';

function useIsDesktop() {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsDesktop(window.innerWidth > theme.breakpoints.values.md);
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isDesktop;
}

export default useIsDesktop;
