import { useState, FC, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from '@mui/material';
import { toast } from 'react-toastify';
import {
  getInventoryMigrationLogsForPlantId,
  migrateInventory,
  revertInventoryMigration,
} from '../../services/migrationServices/InventoryMigration';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

interface MigrationScriptPopup {
  open: boolean;
  handleClose: any;
  plantId: string;
}

const MigrationScriptPopup: FC<MigrationScriptPopup> = ({ open, handleClose, plantId }) => {
  const [inventoryMigratedSuccessfully, setInventoryMigratedSuccessfully] = useState(false);
  const [inventoryMigrationLogs, setInventoryMigrationLogs] = useState<any>(null);
  const [migratePressCount, setMigratePressCount] = useState(5);
  const [revertPressCount, setRevertPressCount] = useState(5);
  const [loading, setLoading] = useState(false);
  const [refreshLogs, setRefreshLogs] = useState(false);
  const [loadingLogs, setLoadingLogs] = useState(false);

  useEffect(() => {
    console.log('refreshLogs : ', refreshLogs);
    setLoadingLogs(true);
    getInventoryMigrationLogsForPlantId(plantId).then(data => {
      if (data) {
        setInventoryMigratedSuccessfully(data.migrationSuccessfull);
        setInventoryMigrationLogs(data);
      } else {
        setInventoryMigrationLogs(null);
        setInventoryMigratedSuccessfully(false);
      }
      setLoadingLogs(false);
    });
    setLoading(false);
    setRevertPressCount(5);
    setMigratePressCount(5);
  }, [open, refreshLogs]);

  const handleMigrateInventory = async () => {
    if (migratePressCount > 1) {
      setMigratePressCount(migratePressCount - 1);
    } else {
      setLoading(true);
      await migrateInventory(plantId).then((data: any) => {
        toast(data.message);
        setLoading(false);
        setRefreshLogs(!refreshLogs);
      });
    }
  };

  const handleRevertInventory = async () => {
    if (revertPressCount > 1) {
      setRevertPressCount(revertPressCount - 1);
    } else {
      setLoading(true);
      await revertInventoryMigration(plantId).then((data: any) => {
        toast(data.message);
        setLoading(false);
        setRefreshLogs(!refreshLogs);
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Inventory Migration </DialogTitle>
      <DialogContent>
        {loadingLogs && <Box>loadingLogs...</Box>}
        {inventoryMigrationLogs && !loadingLogs ? (
          <Accordion elevation={0} sx={{ border: '1px solid black' }}>
            <AccordionSummary>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ width: '100%' }}
              >
                <Box>Migration Logs</Box>{' '}
                <Box sx={{ marginLeft: 'auto' }}>
                  {inventoryMigratedSuccessfully ? (
                    <CheckIcon sx={{ color: 'green' }} />
                  ) : (
                    <ClearIcon sx={{ color: 'red' }} />
                  )}
                </Box>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              {inventoryMigrationLogs?.messages?.map((message: any) => {
                return <Box>{message}</Box>;
              })}
            </AccordionDetails>
          </Accordion>
        ) : (
          <Box>No Migration Logs yet</Box>
        )}
      </DialogContent>
      <DialogActions>
        <Stack direction="row" gap={5}>
          <Button
            variant="contained"
            fullWidth
            sx={{ width: '250px' }}
            disabled={loading}
            onClick={() => {
              handleRevertInventory();
            }}
          >
            {loading ? `Reversion In progress` : `Revert Inventory ${revertPressCount} clicks left`}
          </Button>
          <Button
            variant="contained"
            fullWidth
            sx={{ width: '250px' }}
            disabled={loading || inventoryMigratedSuccessfully}
            onClick={() => {
              handleMigrateInventory();
            }}
          >
            {inventoryMigratedSuccessfully
              ? `Migrate Inventory`
              : loading
              ? `Migration in progress`
              : `Migrate Inventory ${migratePressCount} clicks left`}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default MigrationScriptPopup;
