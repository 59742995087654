export function generatePassword() {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let password = '';
  const length = 8;
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }
  return password;
}

export function resizeImage(file: File): Promise<Blob | null> {
  return new Promise(resolve => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return;

      const scale = 500 / img.width;
      canvas.width = 500;
      canvas.height = img.height * scale;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        } else {
          resolve(null);
        }
      }, file.type);
    };
  });
}

export function validateName(formData: any, setNameError: any) {
  if (formData.name.length < 4 || formData.name.length > 25) {
    setNameError('Name must be between 4 to 25 characters.');
  } else {
    setNameError('');
  }
}

export function validatePhone(formData: any, setPhoneError: any) {
  if (formData.phoneNo.length !== 10) {
    setPhoneError('Phone number must be of 10 characters.');
  } else {
    setPhoneError('');
  }
}

export function validatePassword(formData: any, setPasswordError: any) {
  if (formData.password.length < 8) {
    setPasswordError('Password must be at least 8 characters.');
  } else {
    setPasswordError('');
  }
}

export function validateEmail(formData: any, setEmailError: any) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(formData.email)) {
    setEmailError('Email is invalid.');
  } else {
    setEmailError('');
  }
}
