import {
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";

export default function DIConfigForExpectedValue(props: any) {
  const { dataBlock, handleExpectedValueChange } = props;

  const convertTimeToMinutes = (time: number, timeUnit: string) => {
    if (!timeUnit) return 1440;
    if (timeUnit === "minute") return time * 1;
    if (timeUnit === "hour") return time * 60;
    if (timeUnit === "day") return time * 1440;
    if (timeUnit === "month") return time * 1440 * 30;
    return 1;
  };

  const handleExpectedTimeChange = (
    index: number,
    time: any,
    timeUnit: any
  ) => {
    handleExpectedValueChange(index, "time", time);

    /// recalculating time in mins and setting it
    let timeInMins = convertTimeToMinutes(parseFloat(time), timeUnit);
    handleExpectedValueChange(index, "timeMins", timeInMins);
  };

  const handleExpecetedTimeUnitChange = (
    index: number,
    time: any,
    timeUnit: string
  ) => {
    handleExpectedValueChange(index, "timeUnit", timeUnit);

    // recalculating time in mins and setting it
    let timeInMins = convertTimeToMinutes(parseFloat(time), timeUnit);
    handleExpectedValueChange(index, "timeMins", timeInMins);
  };

  if (dataBlock.expectedValue)
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          ExpectedValue
        </AccordionSummary>
        <AccordionDetails
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {dataBlock.expectedValue.map((EV: any, index: number) => {
            return (
              <Stack direction="column" gap={3}>
                {" "}
                <TextField
                  fullWidth
                  type="number"
                  defaultValue={EV.value}
                  label="Expected Value"
                  onBlur={(e) => {
                    handleExpectedValueChange(
                      index,
                      "value",
                      parseFloat(e.target.value)
                    );
                  }}
                />
                <Stack direction="row" spacing={2}>
                  <TextField
                    fullWidth
                    required
                    type="number"
                    defaultValue={EV.time || 1}
                    label="time"
                    onBlur={(e) => {
                      let time = e.target.value;
                      let timeUnit = EV.timeUnit;
                      handleExpectedTimeChange(index, time, timeUnit);
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      time unit
                    </InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={EV.timeUnit || "minute"}
                      onChange={(e) => {
                        let time = EV.time;
                        let timeUnit = e.target.value;
                        handleExpecetedTimeUnitChange(index, time, timeUnit);
                      }}
                      label="timeUnit"
                    >
                      <MenuItem value={"minute"}>Minutes</MenuItem>
                      <MenuItem value={"hour"}>Hours</MenuItem>
                      <MenuItem value={"day"}>Days</MenuItem>
                      <MenuItem value={"month"}>Months</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
  else return null;
}
