export default function AssetIcon({ color = '#999999' }: { color?: string }) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.66671 11.9999V13.3333H5.33337V11.9999H2.66671ZM2.66671 9.33325V10.6666H9.33337V9.33325H2.66671ZM6.66671 11.9999V13.3333H9.33337V11.9999H6.66671ZM10.6667 9.33325V10.6666H13.3334V9.33325H10.6667ZM10.6667 11.9999V13.3333H13.3334V11.9999H10.6667ZM1.33337 14.6666V5.33325L4.66671 7.99992V5.33325L8.00004 7.99992V5.33325L11.3334 7.99992L12 1.33325H14L14.6667 7.99992V14.6666H1.33337Z"
        fill={color}
      />
    </svg>
  );
}
