import { Grid, Typography, Box, Paper, ThemeProvider } from "@mui/material";
import PlantVisualisation from "./PlantVisualisation";
import BidirectionalControl from "../components/BidirectionalControl/BidirectionalControl";
import LayoutComponent from "../components/LayoutComponent";
import { useNavigate, useParams } from "react-router-dom";
import { PrimaryButton } from "../styles/global/components/dpButtons";
import dpTheme from "../styles/theme/dpTheme";
import useUserStore from "../store/UserStore";
import { useEffect, useState } from "react";
import { checkIfPlantIsInBidirectionalControl } from "../services/BiDirectional";

const PlantVisualisationParentStyles = {
  TitleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: "20px",
    marginBottom: "10px",
  },
  BiDirectionalInfoContainer: {
    display: "flex",
    flexDirection: "row",
    columnGap: "10px",
    alignItem: "baseline",
  },
  BiDirectionalInfoBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "secondary.main",
    borderRadius: "7px",
    paddingLeft: "10px",
    paddingRight: "10px",
    columnGap: "10px",
  },
  BiDirectionalInfoBoxBadge: {
    display: "flex",
    padding: "10px",
    borderRadius: "7px",
    backgroundColor: "#438B72",
    color: "white",
  },
};

function PlantVisualisationParent() {
  const { plantId } = useParams<string>();
  //@ts-ignore
  const [status, setStatus] = useState("Off");
  const [remotePlantControl, setRemotePlantControl] = useState<boolean>(false);
  const navigate = useNavigate();
  useEffect(() => {
    checkIfPlantIsInBidirectionalControl(plantId!).then((data) => {
      console.log("plant under control", data);
      if (data.autoMode) {
        setRemotePlantControl(false);
        setStatus("Off");
      } else {
        setRemotePlantControl(true);
        setStatus("On");
      }
    });
  }, [plantId]);

  //make call every 30 seconds in useeffect
  useEffect(() => {
    const interval = setInterval(() => {
      checkIfPlantIsInBidirectionalControl(plantId!).then((data) => {
        console.log("plant under control", data);
        if (data.autoMode) {
          setRemotePlantControl(false);
          setStatus("Off");
        } else {
          setRemotePlantControl(true);
          setStatus("On");
        }
      });
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  // const plantName = localStorage.getItem("username");
  const plantName = useUserStore().userName;
  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent factoryResetContainer={true} plantId={plantId}>
        <Box sx={PlantVisualisationParentStyles.TitleContainer}>
          <Typography variant="h5">{plantName} Plant Diagram</Typography>
          <Box sx={PlantVisualisationParentStyles.BiDirectionalInfoContainer}>
            <Paper
              elevation={2}
              sx={PlantVisualisationParentStyles.BiDirectionalInfoBox}
            >
              <Typography variant="h6">Remote plant control</Typography>
              <Paper
                variant="elevation"
                sx={
                  remotePlantControl
                    ? PlantVisualisationParentStyles.BiDirectionalInfoBoxBadge
                    : PlantVisualisationParentStyles.BiDirectionalInfoBoxBadge
                }
              >
                <Typography variant="h6">{status}</Typography>
              </Paper>
            </Paper>
            <Paper elevation={2} sx={{ borderRadius: "7px" }}>
              <PrimaryButton
                onClick={() => {
                  navigate(`/BiDirectionalLogs/${plantId}`);
                }}
              >
                <Typography variant="subtitle1">Bi-directional logs</Typography>
              </PrimaryButton>
            </Paper>
          </Box>
        </Box>
        <Grid container spacing={0}>
          {/* First Child */}
          <Grid item xs={12}>
            {" "}
            {/* Adjust the xs value to change the width */}
            <BidirectionalControl />
          </Grid>

          {/* Second Child */}
          <Grid item xs={12}>
            {" "}
            {/* Adjust the xs value to change the width */}
            <PlantVisualisation />
          </Grid>
        </Grid>
      </LayoutComponent>
    </ThemeProvider>
  );
}

export default PlantVisualisationParent;
