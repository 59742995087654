import axiosInstance from "../constants/axiosInstance";

const getSolutionList = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/InventoryManagement/SolutionList?plantId=${plantId}`
    );
    console.log("data", data.data);
    // return data?.data;
    return data.data;
  } catch (err) {
    console.error("ERROR | getSolutionList | ", err);
  }
};

export { getSolutionList };
