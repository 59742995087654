import { Box, Button } from '@mui/material';
import { InfoBox } from '../../styles/global/components/TextInfoBoxes';
import { useEffect, useState } from 'react';
import {
  fetchAllUnreadPersistentNotif,
  markPersistentNotifAsRead,
} from '../../services/notification';
import { checkBiDirectionalLoadingStatus } from '../../services/BiDirectional';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import classes from '../../styles/dashboardPage.module.css';
import LockedPopup from '../BiDirectionalPopups/LockedPopup';
import ControlPrompt from '../BiDirectionalPopups/ControlPrompt';
import { usePlantLayoutStore } from '../../store/PlantLayoutStore';
import { useParams } from 'react-router-dom';

function BidirectionalControl() {
  // const [loadingStatus, setLoadingStatus] = useState<boolean>();
  const [persistentNotifs, setPersistentNotifs] = useState<any>(null);
  const {
    biSensorId,
    biSensorStatus,
    showLockedPopup,
    showControlPrompt,
    equipmentNickName,
    loadingStatus,
    setShowLockedPopup,
    setLoadingStatus,
    setShowControlPrompt,
  } = usePlantLayoutStore();

  const { assetId } = useParams<string>();

  const plantId = assetId;

  useEffect(() => {
    let intervalId: any;
    async function fetchNotifData() {
      const tempPersistentNotifs = await fetchAllUnreadPersistentNotif(plantId!);
      setPersistentNotifs(tempPersistentNotifs);

      intervalId = setInterval(async () => {
        const tempPersistentNotifs = await fetchAllUnreadPersistentNotif(plantId!);
        setPersistentNotifs(tempPersistentNotifs);
      }, 30000);
    }
    fetchNotifData();

    return () => {
      clearInterval(intervalId);
    };
  }, [plantId]);

  useEffect(() => {
    let intervalId: any;
    async function fetchBiDirectionalLoadingStatus() {
      // console.log("plantId", plantId);
      const tempLoadingStatus = await checkBiDirectionalLoadingStatus(plantId!);
      setLoadingStatus(tempLoadingStatus);

      intervalId = setInterval(async () => {
        const tempIntervalloadingStatus = await checkBiDirectionalLoadingStatus(plantId!);
        setLoadingStatus(tempIntervalloadingStatus);
      }, 30000);
    }
    fetchBiDirectionalLoadingStatus();

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handleReadPersistentNotification = async (id: string) => {
    const userId: string = localStorage.getItem('userid')!;
    await markPersistentNotifAsRead(id, userId);
    const tempPersistentNotifs = await fetchAllUnreadPersistentNotif(plantId!);
    setPersistentNotifs(tempPersistentNotifs);
  };

  return (
    <>
      <Box className={classes.persistentNotifsContainer} my={persistentNotifs?.length > 0 ? 3 : 0}>
        {persistentNotifs &&
          [...persistentNotifs].reverse().map((notif: any) => {
            return notif.type === 'success' ? (
              <InfoBox currentStatus={'success'} className={classes.infoBox} my={1}>
                {notif.heading}
                <Button
                  onClick={() => {
                    handleReadPersistentNotification(notif._id);
                  }}
                >
                  <HighlightOffOutlinedIcon />
                </Button>
              </InfoBox>
            ) : (
              <InfoBox currentStatus={'failure'} className={classes.infoBox}>
                {notif.heading}

                <Button
                  onClick={() => {
                    handleReadPersistentNotification(notif._id);
                  }}
                >
                  <HighlightOffOutlinedIcon />
                </Button>
              </InfoBox>
            );
          })}
      </Box>
      {loadingStatus && (
        <Box my={3} sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {loadingStatus ? (
        <LockedPopup showLockedPopup={showLockedPopup} setShowLockedPopup={setShowLockedPopup} />
      ) : (
        <ControlPrompt
          sensorId={biSensorId}
          showControlPrompt={showControlPrompt}
          setShowControlPrompt={setShowControlPrompt}
          existingStatus={biSensorStatus}
          equipmentName={equipmentNickName}
        />
      )}
    </>
  );
}

export default BidirectionalControl;
