import { Box, Button, Paper, Typography } from "@mui/material";
import AlertConditionComponent from "./AlertConditionComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmation from "../../globalComponents/dialogs/DeleteConfirmation";
import { useState } from "react";
export default function AlertConditionContainer(props: {
  conditionSet: any;
  plantSensorList: any;
  configVarList: any;
  updateCondition: any;
  alertIndex: any;
  deleteAlertStatement: Function;
  deleteConditionSet: Function;
  addAlertStatement: Function;
  addConditionSet: Function;
  boolSensorTagList: string[];
  conditionType: string;
}) {
  const {
    conditionSet,
    plantSensorList,
    configVarList,
    updateCondition,
    alertIndex,
    deleteAlertStatement,
    deleteConditionSet,
    addAlertStatement,
    addConditionSet,
    boolSensorTagList,
    conditionType,
  } = props;

  // function getTagValue(lhs: string) {
  //   if (lhs.includes("_")) {
  //     console.log(lhs.split("_")[0]);
  //     return lhs.split("_")[0];
  //   }
  // }

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [processing, setProcessing] = useState(false);
  return (
    <Box>
      {conditionSet &&
        conditionSet.length > 0 &&
        conditionSet?.map((condition: [object], index: number) => {
          return (
            <Box>
              <Paper
                // borderRadius={"10px"}
                elevation={2}
                style={{
                  padding: "0.7rem 0",
                  boxShadow: "grey",
                }}
              >
                <Box
                  borderBottom={1}
                  style={{
                    borderColor: "#dddddd",
                    padding: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant={"h4"} style={{ marginLeft: "50px" }}>
                    Condition Set {index + 1}
                  </Typography>
                  <Button
                    onClick={(e) => {
                      setShowDeleteModal(true);
                    }}
                  >
                    <DeleteIcon />
                  </Button>
                </Box>

                <AlertConditionComponent
                  condition={condition}
                  conditionSetIndex={index}
                  plantSensorList={plantSensorList}
                  configVarList={configVarList}
                  updateCondition={updateCondition}
                  alertIndex={alertIndex}
                  deleteAlertStatement={deleteAlertStatement}
                  addAlertStatement={addAlertStatement}
                  boolSensorTagList={boolSensorTagList}
                  conditionType={conditionType}
                />
              </Paper>
              <Box
                style={{
                  display: "block",
                  textAlign: "center",
                }}
              >
                {index <= conditionSet.length - 2 ? (
                  <p>__________OR__________</p>
                ) : (
                  <></>
                )}
              </Box>
              <DeleteConfirmation
                showDeleteModal={showDeleteModal}
                setShowDeleteModal={setShowDeleteModal}
                alertMessage={"You are deleting an entire condition set."}
                processing={processing}
                setProcessing={setProcessing}
                onConfirmAction={() => {
                  deleteConditionSet(alertIndex, index, conditionType);
                  setProcessing(false);
                }}
              />
            </Box>
          );
        })}

      <Box
        style={{
          display: "block",
          textAlign: "center",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        {conditionSet.length === 0 ? (
          <>
            <Button
              style={{
                background: "#519BD0",
                color: "#fff",
              }}
              onClick={(e) => addConditionSet(alertIndex, conditionType)}
            >
              ADD
            </Button>
          </>
        ) : (
          <>
            <Button
              style={{ background: "#519BD0", color: "#fff" }}
              onClick={(e) => addConditionSet(alertIndex, conditionType)}
            >
              OR
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
