import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  ThemeProvider,
  Typography,
  DialogContentText,
} from "@mui/material";
import dpTheme from "../../styles/theme/dpTheme";
import { PrimaryRoundedButton } from "../../styles/global/components/dpButtons";

interface IProps {
  showLockedPopup: boolean;
  setShowLockedPopup: Function;
}
const LockedPopup = ({ showLockedPopup, setShowLockedPopup }: IProps) => {
  const handleClose = () => {
    setShowLockedPopup(false);
  };

  return (
    <>
      <ThemeProvider theme={dpTheme}>
        <Dialog open={showLockedPopup} maxWidth={"lg"} onClose={handleClose}>
          <DialogTitle>
            <Typography variant={"h1"}>Wait for the last command !</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant={"h4"}>
                Kindly wait for the previous command to process
              </Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <PrimaryRoundedButton onClick={handleClose}>
              OKAY
            </PrimaryRoundedButton>
          </DialogActions>
        </Dialog>
      </ThemeProvider>
    </>
  );
};

export default LockedPopup;
