import React, { useEffect } from "react";
import {
  fetchChokePointConfig,
  fetchPlantLayoutData,
} from "../services/PlantVisualisationConfig";
import { useNavigate, useParams } from "react-router-dom";
import PlantLayoutDiagram from "../components/PlantVisualisation/PlantLayoutDiagram";
import { usePlantLayoutStore } from "../store/PlantLayoutStore";
import NodeSelection from "../components/PlantVisualisationConfig/NodeSelection";
import { Grid } from "@mui/material";
import LayoutComponent from "../components/LayoutComponent";

const PlantVisualizationConfig: React.FC = () => {
  const { plantId } = useParams<string>();
  const {
    setPlantId,
    setNodes,
    setEdges,
    setSelectedNodes,
    setSelectedSensors,
    setNodesWithChokePoints,
  } = usePlantLayoutStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (plantId) {
      setPlantId(plantId);
      fetchPlantLayoutData(plantId).then((plantLayoutData) => {
        const nodesData = plantLayoutData.nodes;
        const edgesData = plantLayoutData.edges;
        setNodes(nodesData);
        setEdges(edgesData);

        fetchChokePointConfig(plantId).then((chokePointConfig: any) => {
          const selectedNodes = Object.keys(chokePointConfig?.nodes);
          let selectedSensors: any = {};
          selectedNodes.forEach((node) => {
            selectedSensors[node] = Object.keys(
              chokePointConfig?.nodes[node]
            ).map((sensorId) => {
              const sensorParentData = nodesData.find(
                (nodeDetail: any) => nodeDetail.id === node
              );
              const sensor = sensorParentData.data.configInfo.sensors.find(
                (sensor: any) => sensor.sensorId === sensorId
              );
              return {
                sensorId,
                sensorName: sensor?.sensorName,
              };
            });
          });
          console.log("selected sensors : ", selectedSensors);
          setSelectedNodes(selectedNodes);
          setSelectedSensors(selectedSensors);
          setNodesWithChokePoints(chokePointConfig?.nodes);
        });
      });
    } else {
      navigate("/404");
    }
  }, []);

  return (
    <LayoutComponent plantId={plantId}>
      <Grid container spacing={4} sx={{ padding: "40px", height: "100vh" }}>
        <Grid item lg={9}>
          <PlantLayoutDiagram />
        </Grid>
        <Grid item lg={3}>
          <NodeSelection />
        </Grid>
      </Grid>
    </LayoutComponent>
  );
};

export default PlantVisualizationConfig;
