import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Stack } from '@mui/material';
import DataInputConfigBlock from './DataInputConfigBlock';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import { getDIConfigForm, saveDIForm } from '../../services/dIConfigForm';
import classes from './DIConfig.module.css';
import { PrimaryRoundedButton } from '../../styles/global/components/dpButtons';
import { DIForm, Question } from '../../Interfaces/DIForm';
import { InfoBox } from '../../styles/global/components/TextInfoBoxes';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import color from '../../styles/color';
import Tooltip from '@mui/material/Tooltip';

interface IProps {
  formType: string;
}

export default function EquipmentConfigurationForm({ formType }: IProps) {
  const { id = '' } = useParams<string>();
  const [form, setForm] = useState<DIForm | null>(null);
  const [questionCategories, setQuestionCategories] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [errorList, setErrorList] = useState<string[]>([]);
  //@ts-ignore
  const [isExpectedValue, setIsExpectedValue] = useState<boolean>(false);
  const [reloadForm, setReloadForm] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    getDIConfigForm(id, formType).then((DI: DIForm) => {
      console.log('data from getDIConfigForm', DI);
      setLoading(false);
      console.log('DI form', DI);
      setForm(DI);

      setQuestionCategories(Object.keys(DI.questionBank));
      setRefresh(!refresh);
    });
  }, [reloadForm]);

  // const updateQuestionBlock = (
  //   dataBlock: Question,
  //   section: string,
  //   index: number
  // ) => {
  //   let data = form;
  //   let questionBank: QuestionBank = data!.questionBank;
  //   console.log("inside updateNon inventory");
  //   console.log("section : ", section);
  //   console.log("index : ", index);
  //   console.log(
  //     "data[section][index] : ",
  //     questionBank[section]["questions"][index]
  //   );
  //   questionBank[section]["questions"][index] = dataBlock;
  //   // Object.assign()
  //   // data!.questionBank[section]["questions"][index] = dataBlock;
  //   setForm(data);
  //   setRefresh(!refresh);
  // };

  const updateQuestionBlock = (dataBlock: Question, section: string, index: number) => {
    // Make a copy of the state object
    const updatedForm: any = { ...form };
    const updatedQuestionBank = { ...updatedForm.questionBank };

    // Update the question block in the copy
    updatedQuestionBank[section]['questions'][index] = dataBlock;

    // Update the copy with the new question bank
    updatedForm.questionBank = updatedQuestionBank;

    // Update the state with the new copy
    setForm(updatedForm);
    setRefresh(prevRefresh => !prevRefresh);
  };

  const handleClearSection = (value: boolean) => {
    let temp: any = form;
    temp['clearSection'] = value;
    console.log('value : ', value);
    setForm(form);
    setRefresh(!refresh);
  };

  const saveFormData = () => {
    console.log('form : ', form);
    // axiosInstance().post(`/data-input/saveDIForm`, {
    //   plantId: id,
    //   data: form,
    // });
    setSaving(true);
    saveDIForm(id, form!)
      .then(data => {
        if (data?.length > 0) {
          data.forEach((err: any) => {
            toast(err);
          });
          setErrorList(data);
        }
        toast.success(`Updated ${form!.formName} section`, {
          toastId: `successToastFor${form!.formName}`,
        });
        console.log('Saved');
        setSaving(false);
        setReloadForm(!reloadForm);
      })
      .catch(err => {
        setSaving(false);
      });
  };

  return (
    <>
      <Box m={1} p={1}>
        {
          <Accordion TransitionProps={{ mountOnEnter: true }} elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <div className={classes['form-name']}>
                {loading ? (
                  <div>
                    <CircularProgress disableShrink className={classes['black-color']} size={20} />{' '}
                    {'loading...'}
                  </div>
                ) : (
                  <Stack direction="row" alignItems="center" gap={2}>
                    {form?.formConfiguration?.isFormConfigured ? (
                      <CheckCircleIcon sx={{ color: color.successDark }} />
                    ) : (
                      <Tooltip title="You haven't selected any question in this category">
                        <ErrorIcon sx={{ color: color.infoMain }} />
                      </Tooltip>
                    )}
                    <span>{form?.formName}</span>
                  </Stack>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes['form-name-container']}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  checked={form?.clearSection}
                  onChange={event => {
                    handleClearSection(event.target.checked);
                  }}
                />
                Clear values daily
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {questionCategories.length === 0 && (
                  <InfoBox currentStatus="failure" mb={4}>
                    {"It seems like you haven't done plant configuration properly"}
                  </InfoBox>
                )}
                {errorList?.map(err => {
                  return (
                    <InfoBox currentStatus="failure" mb={4}>
                      {err}
                    </InfoBox>
                  );
                })}
                {questionCategories.map((category: any) => {
                  return (
                    <Accordion
                      style={{ width: '100%' }}
                      TransitionProps={{ mountOnEnter: true }}
                      elevation={0}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        {form?.questionBank?.[category]?.nickName
                          ? form?.questionBank?.[category]?.nickName
                          : category}
                      </AccordionSummary>
                      <AccordionDetails className={classes['form-detail-container']}>
                        <Box sx={{ display: 'flex' }}>
                          <div
                          //  style={{ flex: 1, columnCount: 2 }}
                          >
                            {form?.questionBank[category].questions.map(
                              (question: Question, index: number) => {
                                return (
                                  <DataInputConfigBlock
                                    formType={formType}
                                    dataBlock={question}
                                    updateFunction={updateQuestionBlock}
                                    category={category}
                                    index={index}
                                    handleIsExpectedValue={setIsExpectedValue}
                                  />
                                );
                              }
                            )}
                          </div>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
                <div className={classes['centered-element']}>
                  <PrimaryRoundedButton onClick={saveFormData}>
                    <span>Save</span>
                    {saving ? (
                      <CircularProgress size={20} sx={{ color: '#fff', marginLeft: '10px' }} />
                    ) : null}
                  </PrimaryRoundedButton>
                </div>
              </Box>
            </AccordionDetails>
          </Accordion>
        }
      </Box>
      {/* <ToastContainer /> */}
    </>
  );
}
