import React, { useState, useEffect } from 'react';
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from 'reactflow';
import {
  Dialog,
  IconButton,
  Typography,
  Box,
  Stack,
  TextField,
  Tooltip,
  Paper,
} from '@mui/material';
import Select from 'react-select';
import EditIcon from '@mui/icons-material/Edit';

import { getSensorListBasedOnFilters } from '../../../services/sensors';
import { useLayoutStore } from '../../../store/LayoutStore2';
import ColorPicker from '../../../globalComponents/colorPicker/ColorPicker';
import makeAnimated from 'react-select/animated';
import Draggable from 'react-draggable';

const edgeDetailStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '20px',
  height: '20px',
  background: '#eee',
  border: '1px solid #fff',
  cursor: 'pointer',
  borderRadius: '50%',
  fontSize: '10px',
  fontWeight: 600,
};

// const apiEdgeTypesList = [
//   { _id: 1, label: "Clear Water" },
//   { _id: 2, label: "Dirty Water" },
//   { _id: 3, label: "Sludge" },
//   // ... other edge types
// ];

const DraggablePaper = (props: any) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    // maxHeight: '200px', // Set the max height of the dropdown menu
    // overflowY: 'hidden', // Add scroll if the content exceeds the max height
  }),
};

export default function ConfigEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  target,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,
}: any) {
  //   const [edgePath, labelX, labelY] = getStraightPath({
  //     sourceX,
  //     sourceY,
  //     targetX,
  //     targetY,
  //   });

  // const [edgePath, labelX, labelY] = getBezierPath({
  //   sourceX,
  //   sourceY,
  //   sourcePosition,
  //   targetX,
  //   targetY,
  //   targetPosition,
  // });

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { edges, setEdges: setStoredEdges } = useLayoutStore();
  const [open, setOpen] = useState(false);
  //   const [selectedEdgeType, setSelectedEdgeType] = useState<any>("");
  const [sensorList, setSensorList] = useState<any[]>([]);
  const [edgeColor, setEdgeColor] = useState<string>(
    edges.find(e => e.id === id)?.data?.properties?.color || '#000000'
  ); // Default color
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [width, setWidth] = useState<number | undefined>(
    edges.find(e => e.id === id)?.data?.properties?.width || 2
  );

  const animatedComponents = makeAnimated();

  useEffect(() => {
    if (data.concernedAssetIds)
      getSensorListBasedOnFilters({
        plantId: { $in: data.concernedAssetIds },
      }).then(data => {
        setSensorList(data);
      });
  }, []);

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleColorChange = (newColor: string) => {
    setEdgeColor(newColor);
    const edgeIndex = edges.findIndex(e => e.id === id);
    if (edgeIndex !== -1) {
      const updatedEdges = [...edges];
      const edge = { ...updatedEdges[edgeIndex] };

      edge.data = {
        ...edge.data,
        properties: { ...edge.data.properties, color: newColor },
      };
      updatedEdges[edgeIndex] = edge;
      setStoredEdges(updatedEdges);
    }
  };

  const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newWidth = event.target.valueAsNumber; // Use valueAsNumber to ensure it's a number
    setWidth(newWidth);

    const edgeIndex = edges.findIndex(e => e.id === id);
    if (edgeIndex !== -1) {
      const updatedEdges = [...edges];
      const edge = { ...updatedEdges[edgeIndex] };

      edge.data = {
        ...edge.data,
        properties: { ...edge.data.properties, width: newWidth },
      };
      updatedEdges[edgeIndex] = edge;
      setStoredEdges(updatedEdges);
    }
  };

  function calculateLabelPosition() {
    let offsetX = 0;
    let offsetY = 0;

    // Check the direction of the edge
    const isLeftToRight = sourceX < targetX;

    // Split the edgePath into an array by spaces and commas
    const pathData = edgePath.replace(/M|C/g, '').split(/[ ,]/).filter(Boolean);

    // Check if pathData has the expected number of elements
    if (pathData.length >= 6) {
      // Adjust offset based on direction
      offsetX = isLeftToRight ? 0 : -25; // Adjust 20px to the right or left
      // offsetY = midY - labelY; // Center vertically
      offsetY = isLeftToRight ? 5 : 0; // Center vertically
    } else {
      console.log('Path data format unexpected or too short:', pathData);
    }

    // Adjust for parallel edges if needed
    // ... your logic for parallel edges ...

    // Return the adjusted position
    return { x: labelX + offsetX, y: labelY + offsetY };
  }

  const { x, y } = calculateLabelPosition();

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: edgeColor, strokeWidth: width }}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            //transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
            pointerEvents: 'all',
          }}
        >
          <div onClick={handleOpenDialog} style={edgeDetailStyle}>
            {id}
          </div>
        </div>
      </EdgeLabelRenderer>

      <Dialog open={open} onClose={handleCloseDialog} PaperComponent={DraggablePaper}>
        {/* <Draggable handle="#draggable-dialog-title"> */}
        <Box m={1} sx={{ width: '400px', height: '500px' }}>
          <Stack direction="column" gap={2}>
            <Typography variant="h6" style={{ cursor: 'move' }} id="draggable-dialog-title">
              Edge Config {id}
            </Typography>
            <Select
              isMulti
              components={animatedComponents}
              options={sensorList.map(s => ({
                value: s._id,
                label: s.sensorTag || `${s._id} sensorTag : NA`,
                sensorNickName: s.sensorNickName,
              }))}
              styles={customStyles}
              value={
                edges
                  .find(e => e.id === id)
                  ?.data?.sensors?.map((s: any) => ({
                    value: s._id,
                    label: s.sensorTag || `${s._id} sensorTag : NA`,
                    sensorNickName: s.sensorNickName,
                  })) || []
              }
              formatOptionLabel={option => (
                <div
                  style={{
                    color: option.sensorNickName?.length > 0 ? 'darkgreen' : 'black',
                  }}
                >
                  <Tooltip title={option.label || ''}>
                    <div>
                      {option.sensorNickName?.length > 0 ? option.sensorNickName : option.label}
                    </div>
                  </Tooltip>
                </div>
              )}
              onChange={selectedOptions => {
                const edgeIndex = edges.findIndex(e => e.id === id);
                if (edgeIndex !== -1) {
                  const updatedEdges = [...edges];
                  const edge = { ...updatedEdges[edgeIndex] };

                  const sensors = selectedOptions.map(option => {
                    const sensor = sensorList.find(s => s._id === option.value);
                    return sensor || null;
                  });

                  edge.data = { ...edge.data, sensors };
                  updatedEdges[edgeIndex] = edge;
                  setStoredEdges(updatedEdges);
                }
              }}
              filterOption={({ label, data }, inputValue) => {
                const labelLower = label?.toLowerCase();
                const inputValueLower = inputValue.toLowerCase();
                const sensorNickNameLower =
                  data && data.sensorNickName ? data.sensorNickName.toLowerCase() : '';

                return (
                  labelLower.includes(inputValueLower) ||
                  sensorNickNameLower.includes(inputValueLower)
                );
              }}
            />

            {/* <Select
              options={apiEdgeTypesList.map((edgeType) => ({
                value: edgeType._id,
                label: edgeType.label,
              }))}
              value={selectedEdgeType}
              onChange={(selectedOption) => {
                const edgeIndex = edges.findIndex((e) => e.id === id);
                if (edgeIndex !== -1) {
                  const updatedEdges = [...edges];
                  const edge = { ...updatedEdges[edgeIndex] };

                  edge.edgeType = selectedOption;
                  setSelectedEdgeType(selectedOption ? selectedOption : "");
                  updatedEdges[edgeIndex] = edge;
                  setStoredEdges(updatedEdges);
                }
              }}
            /> */}

            <Stack direction="row" gap={1} alignItems="center">
              <Typography>
                Edge Color - {edges.find(e => e.id === id)?.data?.properties?.color || '#000000'}
              </Typography>{' '}
              <Box
                sx={{
                  background: edges.find(e => e.id === id)?.data?.properties?.color || '#000000',
                  borderRadius: '5px',
                  padding: '5px',
                  width: '10px',
                  height: '10px',
                }}
              ></Box>
              <IconButton
                size="small"
                onClick={() => {
                  setOpenColorPicker(true);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Stack>
            <ColorPicker
              initialColor={edges.find(e => e.id === id)?.data?.properties?.color || '#000000'}
              open={openColorPicker}
              onClose={() => {
                setOpenColorPicker(false);
              }}
              onColorChange={handleColorChange}
            />

            <TextField
              label="Edge Width"
              type="number"
              value={width || ''}
              onChange={handleWidthChange}
              sx={{ zIndex: 0 }}
            />
          </Stack>
        </Box>
        {/* </Draggable> */}
      </Dialog>
    </>
  );
}
