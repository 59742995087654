import { create } from "zustand";

export type HistoryState = {
  historyStack: string[];
  addToHistory: (path: string) => void;
};

const useHistoryStore = create<HistoryState>((set) => ({
  historyStack: [],
  addToHistory: (path) =>
    set((state) => ({
      historyStack: [...state.historyStack, path], // Keep only the last 5 entries
    })),
}));

export default useHistoryStore;
