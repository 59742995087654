import { Grid, Typography, Box, Paper, ThemeProvider, Button, Stack } from '@mui/material';
import LayoutVisualisation from './LayoutVisualisation';
import BidirectionalControl from '../components/BidirectionalControl/BidirectionalControl';
import LayoutComponent from '../components/LayoutComponent';
import { useNavigate, useParams } from 'react-router-dom';
import dpTheme from '../styles/theme/dpTheme';
import useUserStore from '../store/UserStore';
import { useEffect, useState } from 'react';
import { upperCase } from 'lodash';
import { checkIfPlantIsInBidirectionalControl } from '../services/BiDirectional';
import NavigationComponent from '../components/LayoutVisualisation/NavigationComponente';
import useNavigationStore from '../store/NavigationStore';
import { getLayoutDiagramMetaDataForAssetId } from '../services/layoutDiagram';
//@ts-ignore
import LayoutDiagram from '../components/LayoutVisualisation/LayoutDiagram';
import LayoutDateTimeSelector from '../components/LayoutVisualisation/dateTImeSelector/LayoutDateTimeSelector';
import useIsTablet from '../utilities/customHooks/useIsTablet';
import useUIControlStore from '../store/UIControlStore';

const LayoutVisualisationParentStyles = {
  TitleContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    justifyContent: 'space-between',
    // marginLeft: '15px',
    gap: '20px',
    marginTop: '20px',
    // background: 'blue',
  },
  BiDirectionalInfoContainer: {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    gap: '10px',
    alignItem: 'baseline',
  },
  BiDirectionalInfoBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1A3356',
    background: '#fff',
    border: '1px solid #C4C4C4',
    borderRadius: '4px',
    gap: { xs: '10px', md: '10px' },
    maxWidth: { xs: '100%', sm: '250px', md: '250px' },
    padding: '10px',
  },
  BiDirectionalLogBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#1A3356',
    borderRadius: '4px',
    background: '#fff',
    padding: '10px',
    border: '1px solid #C4C4C4',
    maxWidth: { xs: '100%', sm: '270px', md: '200px' },
    textTansform: 'none',
    // columnGap: '10px',
  },
  BiDirectionalInfoONBoxBadge: {
    display: 'grid',
    placeItems: 'center',
    borderRadius: '2px',
    padding: '5px',
    fontSize: '8px',
    backgroundColor: '#438B72',
    color: 'white',
  },
  BiDirectionalInfoOFFBoxBadge: {
    display: 'grid',
    placeItems: 'center',
    borderRadius: '2px',
    padding: '5px',
    fontSize: '8px',
    backgroundColor: '#E64A23',
    color: 'white',
  },
};

function LayoutVisualisationParent() {
  const { assetId } = useParams<string>();
  console.log('assetId 1 : ', assetId);
  const plantId = assetId;
  //@ts-ignore
  const [status, setStatus] = useState('Off');
  const [remotePlantControl, setRemotePlantControl] = useState<boolean>(false);
  const navigate = useNavigate();
  //@ts-ignore
  const { nodePath, setNodePath } = useNavigationStore();
  const [sseSwitchedOn, setSSESwitchedOn] = useState(true);
  const [timeFrame, setTimeFrame] = useState({ startTime: new Date(), endTime: new Date() });
  const [fullScreenView, setFullScreenView] = useState(false);
  const isTablet = useIsTablet();
  const { visualisationFullSize, setVisualisationFullSize } = useUIControlStore();

  useEffect(() => {
    checkIfPlantIsInBidirectionalControl(plantId!).then(data => {
      if (data.autoMode) {
        setRemotePlantControl(false);
        setStatus('Off');
      } else {
        setRemotePlantControl(true);
        setStatus('On');
      }
    });

    const controller = new AbortController();
    const signal = controller.signal;

    getLayoutDiagramMetaDataForAssetId(assetId!, signal).then((metaData: any) => {
      setNodePath({
        assetId: metaData?.assetId,
        assetName: metaData.assetName,
        assetType: metaData.assetType,
      });
    });
    return () => {
      controller.abort();
    };
  }, [assetId]);

  useEffect(() => {
    setVisualisationFullSize(false);
  }, []);

  useEffect(() => {
    setFullScreenView(visualisationFullSize);
  }, [visualisationFullSize]);

  //make call every 30 seconds in useeffect
  useEffect(() => {
    const interval = setInterval(() => {
      checkIfPlantIsInBidirectionalControl(plantId!).then(data => {
        if (data.autoMode) {
          setRemotePlantControl(false);
          setStatus('Off');
        } else {
          setRemotePlantControl(true);
          setStatus('On');
        }
      });
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  // const plantName = localStorage.getItem("username");
  const plantName = useUserStore().userName;
  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent plantId={plantId} factoryResetContainer={fullScreenView}>
        <Box sx={LayoutVisualisationParentStyles.TitleContainer}>
          <Stack
            sx={{ width: fullScreenView ? '100%' : { sm: '100%', md: '200px' } }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h5">{plantName} Plant Diagram</Typography>
            </Box>
            {(isTablet || fullScreenView) && <NavigationComponent />}
          </Stack>
          {/* <Box sx={LayoutVisualisationParentStyles.BiDirectionalInfoContainer}> */}
          {!fullScreenView && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                justifyContent: { sm: 'space-between', md: 'flex-end' },
                width: { sm: '100%', md: '95%' },
                gap: { xs: '10px', lg: '20px' },
              }}
            >
              <Paper elevation={0} sx={LayoutVisualisationParentStyles.BiDirectionalInfoBox}>
                <Typography variant="h6" sx={{ fontSize: '12px' }}>
                  REMOTE PLANT CONTROL
                </Typography>
                <Paper
                  variant="elevation"
                  sx={
                    remotePlantControl
                      ? LayoutVisualisationParentStyles.BiDirectionalInfoONBoxBadge
                      : LayoutVisualisationParentStyles.BiDirectionalInfoOFFBoxBadge
                  }
                >
                  <Typography variant="h6" sx={{ fontSize: '10px' }}>
                    {upperCase(status)}
                  </Typography>
                </Paper>
              </Paper>

              <Button
                onClick={() => {
                  navigate(`/BiDirectionalLogs/${plantId}`);
                }}
                sx={LayoutVisualisationParentStyles.BiDirectionalLogBox}
              >
                <Typography variant="subtitle1" sx={{ color: '#1A3356', fontSize: '12px' }}>
                  BI-DIRECTIONAL LOGS
                </Typography>
              </Button>

              <LayoutDateTimeSelector
                setTimeFrame={setTimeFrame}
                sseSwitchedOn={sseSwitchedOn}
                setSSESwitchedOn={setSSESwitchedOn}
              />
            </Box>
          )}
        </Box>

        <Grid container spacing={0}>
          {/* First Child */}
          <Grid item xs={12}>
            {' '}
            {/* Adjust the xs value to change the width */}
            <BidirectionalControl />
          </Grid>

          {/* Second Child */}
          <Grid item xs={12} mt={3}>
            <Box>
              {!isTablet && !fullScreenView && <NavigationComponent />}
              {/* Adjust the xs value to change the width */}
              <LayoutVisualisation sseSwitchedOn={sseSwitchedOn} timeFrame={timeFrame} />
            </Box>
          </Grid>
        </Grid>
      </LayoutComponent>
    </ThemeProvider>
  );
}

export default LayoutVisualisationParent;
