import { useState, useRef } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Menu,
} from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import classes from "./DashboardComponents.module.css";

const DPInputSelect = ({
  labelId,
  id,
  label,
  labelClassName,
  inputSelectOnChange,
  className,
  menuItems,
  size,
  selectedOption,
  selectedId,
  setIsBottomBarOpen,
}: any) => {
  //@ts-ignore
  const [openMenu, setOpenMenu] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);
  const iconButtonRefs = useRef<(HTMLButtonElement | null)[]>([]);

  //Hack
  const selectRef = useRef<any>(null);

  const handleSubMenuOpen = (event: any, index: number) => {
    setOpenSubMenu(index);
  };

  const handleSubMenuClose = () => {
    setOpenSubMenu(null);
  };

  return (
    <FormControl className={classes.DPForm}>
      <InputLabel id={labelId} className={labelClassName}>
        {label}
      </InputLabel>
      <Select
        ref={selectRef}
        labelId={labelId}
        id={id}
        value={selectedOption}
        label={label}
        className={className}
        sx={{
          // ".MuiOutlinedInput-notchedOutline": { border: 0 },
          // "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
          //   border: 0,
          // },
          // "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          //   { border: 0 },
          ".MuiOutlinedInput-input": {
            padding: `${size * 8}px ${size * 8 + 2}px`,
          },
        }}
        open={openMenu}
        onOpen={() => {
          setOpenMenu(true);
          setIsBottomBarOpen(false);
        }}
        onClose={() => {
          setOpenMenu(false);
          handleSubMenuClose();
        }}
        renderValue={() => (
          <span
            style={{ fontSize: "16px", fontWeight: "500", color: "#666" }}
          >
            {selectedOption}
          </span>
        )}
      >
        {menuItems.map((menuItem: any, index: number) => (
          <MenuItem
            key={index}
            value={menuItem.label}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              backgroundColor:
                (selectedId && selectedId === menuItem.pageId) ||
                (menuItem.files &&
                  menuItem.files.length > 0 &&
                  menuItem.files.find(
                    (file: any) => file.pageId === selectedId
                  ))
                  ? "#E3EEFA"
                  : "inherit",
              fontSize: "14px",
              fontWeight: "400",
              color: "#4D5156",
            }}
            onClick={(event) => {
              if (menuItem.files && menuItem.files.length > 0) {
                handleSubMenuOpen(event, index);
              } else {
                inputSelectOnChange(menuItem);
                handleSubMenuClose();
              }
            }}
            onMouseEnter={(event) => {
              if (menuItem.files && menuItem.files.length > 0) {
                if (openMenu) {
                  handleSubMenuOpen(event, index);
                }
              } else {
                handleSubMenuClose();
              }
            }}
          >
            {menuItem.label}
            <>
              {menuItem.files && menuItem.files.length > 0 ? (
                <IconButton
                  ref={(el) => (iconButtonRefs.current[index] = el)}
                  edge="end"
                  onClick={(event) => handleSubMenuOpen(event, index)}
                  sx={{
                    padding: 0,
                  }}
                >
                  <ArrowRightIcon />
                </IconButton>
              ) : (
                ""
              )}
            </>
          </MenuItem>
        ))}
      </Select>
      {menuItems.map((menuItem: any, index: number) => (
        <Menu
          style={{ pointerEvents: "none" }}
          autoFocus={false}
          disableAutoFocus
          disableEnforceFocus
          key={index}
          anchorEl={iconButtonRefs.current[index]}
          open={openSubMenu === index}
          onClose={handleSubMenuClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{
            transform: "translate(4.2px, 0px)",
          }}
        >
          {menuItem.files &&
            menuItem.files.length > 0 &&
            menuItem.files.map((childMenuItem: any, childIndex: number) => (
              <MenuItem
                style={{ pointerEvents: "auto" }}
                key={childIndex}
                value={childMenuItem.label}
                onClick={(e: any) => {
                  inputSelectOnChange(childMenuItem);
                  handleSubMenuClose();
                  setOpenMenu(false);
                  if (selectRef.current) {
                    selectRef.current?.props?.onClose(e); // trigger the onClose event of the Select
                  }
                }}
                sx={{
                  fontSize: "14px",
                  fontWeight: "400",
                  color: "#4D5156",
                  backgroundColor:
                    selectedId && selectedId === childMenuItem.pageId
                      ? "#E3EEFA"
                      : "inherit",
                }}
              >
                {childMenuItem.label}
              </MenuItem>
            ))}
        </Menu>
      ))}
    </FormControl>
  );
};

export default DPInputSelect;
