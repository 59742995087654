const color = {
    primaryMain: "#36A2C7",
    primaryLight: "#6198c9",
    secondaryMain: "#193458",
    secondaryLight: "#36A2C7",
    errorMain: "#DE350B",
    errorLight: "#FFEBE6",
    infoMain: "#f27f0c",
    infoLight: "#f7ad19",
    secondaryInfoMain: "#0BA4D4",
    successMain: "#ABF5D1",
    successDark: "#425018",
    primaryTextMain: "#000000",
    secondaryTextMain: "#ffffff",
    primaryTitleMain: "#000000",
    disabledMain: "#dddddd",
    disabledDark: "#999999",
    iconsMain: "#000000",
    dpWhite: "#ffffff",
    dpBlue: "#36A2C7",
    dpLightBlue: "#3271A9",
    dpBlack: "#000000",
    dpPink: "#ffbaed",
    dpDarkPink: "#9d0074",
    dpPurple: "#f8a7ff",
    dpDarkPurple: "#65006e",
    dpLightOrange: "#fbdea3",
    dpGreenOnHover: "#95ddba",
    dpRedOnHover: "#f7d4cc",
    dpGrey: "#666666",
    dpTextColor: "#2C3556",
    dpGreenButton: "rgba(193,229,97,0.7)",
    dpDarkBlue: "#183650",

    dpGreenButtonOnHover: "rgba(193,229,97,1)",
    dpRedButton: "rgba(248,73,30,0.8)",
    dpRedDark: "#502318",
    dpRedButtonOnHover: "rgba(248,73,30,1)"

};

export default color;
