import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { Autocomplete } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PLANT_CONFIG_DATA } from '../../constants/constants';
import { Pump } from '../../Interfaces/PlantConfigurationInterface';
import { PlantConfigStyle } from './PlantConfigStyle';
import FormHeader from './subComponents/FormHeader';
import { styledBoxWithShadow } from '../../styles/global/components/dpBox';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { useState } from 'react';
import SensorTagArray from './subComponents/SensorTagArray';
import DiagramSelectComponent from './subComponents/DiagramSelectComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddEquipmentInsideEquipmentTypePopup from '../PlantConfigPopups/EquipmentInsideTypeSelector';
import AddIcon from '@mui/icons-material/Add';
import SubEquipmentViewAndControl from './subEquipment/SubEquipmentViewAndControl';
import SettingsIcon from '@mui/icons-material/Settings';
import EqtInsideEqt from '../../assets/icons/EqtInsideEqt.svg';
interface IProps {
  pumpData: Pump[];
  addPump: Function;
  RemoveElement: Function;
  updateSensorList: Function;
  updateValue: Function;
  handleNickNameChange: Function;
  updateTagName: Function;
  handleMultiSelect: Function;
  updateSensorTagValue: Function;
  plantConfig: any;
  setPlantConfig: Function;
  TankOptions: Function;
  ValveOptions: Function;
  CoreEquipmentOptions: Function;
  HeaderOptions: Function;
  tankChoice: any;
  valveChoice: any;
  equipChoice: any;
  headerChoice: any;
  handleShowOnDiagramCheck: Function;
  sensorPostFix: string;
  handleBiDirectionalCheck: Function;
}

function PumpConfig({
  pumpData,
  addPump,
  RemoveElement,
  updateSensorList,
  updateValue,
  handleNickNameChange,
  updateTagName,
  handleMultiSelect,
  tankChoice,
  updateSensorTagValue,
  plantConfig,
  setPlantConfig,
  TankOptions,
  ValveOptions,
  CoreEquipmentOptions,
  HeaderOptions,
  valveChoice,
  equipChoice,
  headerChoice,
  handleShowOnDiagramCheck,
  sensorPostFix,
  handleBiDirectionalCheck,
}: IProps) {
  const [equipmentIndex, setEquipmentIndex] = useState<Number | null>(null);
  const [equipmentName, setEquipmentName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [equipment, setEquipment] = useState('');
  const [showAddSubEquipment, setShowAddSubEquipment] = useState(false);
  function checkIfSubEquipmentHasData(subEquipments: any) {
    let hasData = false;
    let subEquipmentCategories = Object.keys(subEquipments);
    if (subEquipmentCategories.length > 0) {
      subEquipmentCategories.forEach(category => {
        if (subEquipments[category].length > 0) {
          hasData = true;
        }
      });
    } else {
      hasData = false;
    }
    return hasData;
  }
  function checkIfBidirectionalControlPossible(sensors: any) {
    let onOffSensor = sensors.find((sensor: any) => sensor.sensorName === 'ON/OFF Status');
    if (onOffSensor) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
      <Box m={1} p={2}>
        {pumpData.map((pump: any, index) => {
          return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.6rem',
                  }}
                >
                  <Typography variant="h4">Pump {pump.count}</Typography>
                  <Box sx={{ marginLeft: 'auto' }}>
                    {pump['subEquipments'] && checkIfSubEquipmentHasData(pump['subEquipments']) && (
                      <img
                        src={EqtInsideEqt}
                        style={{ height: '20px' }}
                        alt="Equipment Inside Icon"
                      />
                    )}
                  </Box>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box m={1} p={1} sx={styledBoxWithShadow}>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <FormHeader
                          formTitle="Choose Sensors"
                          formData={PLANT_CONFIG_DATA['Pumps']['sensors']}
                          formHelperText={{ name: 'Pump', count: pump.count }}
                          sensorsData={pump.sensors}
                          componentIndex={index}
                          categoryName="Pumps"
                          updateSensorList={updateSensorList}
                        />
                        <SensorTagArray
                          categoryName="Pumps"
                          formHelperText={{
                            name: 'Pump',
                            count: pump.count,
                          }}
                          sensorsTagData={pump['sensors']}
                          componentIndex={index}
                          updateSensorTagValue={updateSensorTagValue}
                          hmiData={pump['hmiButtons']}
                          updateValue={updateValue}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Box m={1}>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={tankChoice}
                            value={pump['In']}
                            onChange={(eveny, newValue) => {
                              handleMultiSelect('Pumps', index, 'In', newValue);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Choose Input Tanks"
                                placeholder="Input Tanks"
                                style={PlantConfigStyle.equipmentTextField}
                              />
                            )}
                          />
                        </Box>
                        <Box m={1}>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={tankChoice}
                            value={pump['Out']}
                            onChange={(eveny, newValue) => {
                              handleMultiSelect('Pumps', index, 'Out', newValue);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Choose Output Tanks"
                                placeholder="Output Tanks"
                                style={PlantConfigStyle.equipmentTextField}
                              />
                            )}
                          />
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'model+OF' + pump['count']}
                            label="Onboarding Flow"
                            value={pump['onFlow']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'onFlow',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 13,
                              step: '0.01',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'model+PN' + pump['count']}
                            label="Pump Name"
                            value={pump['pumpName']}
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'pumpName',
                                value: e.target.value,
                              })
                            }
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <DiagramSelectComponent
                          category="Pumps"
                          index={index}
                          updateValue={updateValue}
                          layoutDiagram={pump['layoutDiagram']}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Box m={1}>
                          <FormControl fullWidth style={PlantConfigStyle.equipmentTextField}>
                            <InputLabel style={PlantConfigStyle.inputLabel}>
                              Select Pump Type
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id={'id+Pump' + pump['count']}
                              value={pump['type']}
                              onChange={e =>
                                updateValue({
                                  category: 'Pumps',
                                  index,
                                  valuefor: 'type',
                                  value: e.target.value,
                                })
                              }
                            >
                              <MenuItem value=""></MenuItem>
                              {PLANT_CONFIG_DATA['Pump Types'].map((type: any) => {
                                return <MenuItem value={type}>{type}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'make+Pump' + pump['count']}
                            label="Make"
                            value={pump['make']}
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'make',
                                value: e.target.value,
                              })
                            }
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'model+Pump' + pump['count']}
                            label="Model"
                            value={pump['model']}
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'model',
                                value: e.target.value,
                              })
                            }
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'model+MR' + pump['count']}
                            label="Motor Rating"
                            value={pump['motorRating']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'motorRating',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 13,
                              step: '0.01',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>

                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'model+MR' + pump['count']}
                            label="Pump NickName"
                            value={pump['nickName']}
                            onChange={e =>
                              handleNickNameChange({
                                category: 'Pumps',
                                index,
                                value: e.target.value,
                              })
                            }
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'model+MH' + pump['count']}
                            label="Minimum Head"
                            value={pump['minHead']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'minHead',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 13,
                              step: '0.01',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'model+MxH' + pump['count']}
                            label="Maximum Head"
                            value={pump['maxHead']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'maxHead',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 13,
                              step: '0.01',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'model+DH' + pump['count']}
                            label="Duty Head"
                            value={pump['dutyHead']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'dutyHead',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 13,
                              step: '0.01',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'model+DF' + pump['count']}
                            label="Design Flow"
                            value={pump['designFlow']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'designFlow',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 13,
                              step: '0.01',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>

                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'age' + pump['count']}
                            label="Age in months"
                            value={pump['age']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Pumps',
                                index,
                                valuefor: 'age',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 1000,
                              step: '1',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>

                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'tagName' + pump['count']}
                            label="Tag Name"
                            value={pump['tagName']}
                            type="text"
                            onChange={e => updateTagName('Pumps', index, e.target.value)}
                            inputProps={{
                              maxLength: 1000,
                              step: '1',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={pump.displayNode}
                              // checked={check}
                              onChange={() => {
                                handleShowOnDiagramCheck('Pumps', index, !pump.displayNode);
                              }}
                              name={'Show on diagram'}
                            />
                          }
                          label={'Show on diagram'}
                        />
                        {checkIfBidirectionalControlPossible(pump['sensors']) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={pump.bidDirectionalControl}
                                // checked={check}
                                onChange={() => {
                                  handleBiDirectionalCheck(
                                    'Pumps',
                                    index,
                                    !pump.bidDirectionalControl
                                  );
                                }}
                                name={'Bi-Directional Control'}
                              />
                            }
                            label={'Bi-Directional Control'}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={PlantConfigStyle.configFormWrapper}>
                    <Button
                      variant="contained"
                      style={PlantConfigStyle.deleteButton}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setEquipmentIndex(index);
                        setEquipmentName(`Pump ${pump.count}`);
                        setEquipment('Pumps');
                      }}
                    >
                      <DeleteIcon style={PlantConfigStyle.deleteIcon} />
                    </Button>
                    <Button
                      variant="contained"
                      style={PlantConfigStyle.deleteButton}
                      onClick={() => {
                        setShowAddSubEquipment(true);
                        setEquipmentIndex(index);
                        setEquipmentName(`Pump ${pump.count}`);
                        setEquipment('Pumps');
                      }}
                    >
                      <AddIcon style={PlantConfigStyle.deleteIcon} />
                    </Button>
                  </div>
                </Box>
                {pump['subEquipments'] && checkIfSubEquipmentHasData(pump['subEquipments']) && (
                  <>
                    <Box m={1} p={1}>
                      <span
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          margin: '2rem 0',
                        }}
                      >
                        <Typography variant="h4">Sub - Equipments</Typography>
                        <SettingsIcon style={{ paddingLeft: '1rem' }} />
                      </span>
                    </Box>
                    <SubEquipmentViewAndControl
                      parentEquipmentType="Pumps"
                      parentEquipmentIndex={index}
                      subEquipments={pump['subEquipments']}
                      plantConfig={plantConfig}
                      setplantConfig={setPlantConfig}
                      tankChoice={tankChoice}
                      valveChoice={valveChoice}
                      equipChoice={equipChoice}
                      headerChoice={headerChoice}
                      sensorPostFix={sensorPostFix}
                    />
                  </>
                )}
              </AccordionDetails>
            </Accordion>
          );
        })}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={`You are deleting the ${equipmentName}`}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            RemoveElement(equipment, equipmentIndex);
            setProcessing(false);
            setShowDeleteModal(false);
          }}
        />
        <AddEquipmentInsideEquipmentTypePopup
          open={showAddSubEquipment}
          handleClose={() => {
            setShowAddSubEquipment(false);
          }}
          plantConfig={plantConfig}
          setPlantConfig={setPlantConfig}
          parentCategory="Pumps"
          parentIndex={equipmentIndex!}
          TankOptions={TankOptions}
          ValveOptions={ValveOptions}
          CoreEquipmentOptions={CoreEquipmentOptions}
          HeaderOptions={HeaderOptions}
          sensorPostFix={sensorPostFix}
        />
      </Box>
    </>
  );
}

export default PumpConfig;
