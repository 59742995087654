import axiosInstance from "../constants/axiosInstance";

const postLoginDetail = async (
  email: string,
  password: string,
  token: string
) => {
  try {
    const { data } = await axiosInstance().post("/users/login", {
      email,
      password,
      token,
    });
    console.log(data);
    return data;
  } catch (err) {
    console.error("Login Failed");
  }
};

const postLoginDetailV2 = async (
  email: string,
  password: string,
  token: string
) => {
  try {
    const { data } = await axiosInstance().post("/newUsers/login", {
      email,
      password,
      token,
    });
    console.log(data);
    return data;
  } catch (err) {
    console.error("Login Failed");
  }
};

const loginUsingAuthToken = async (token: string) => {
  try {
    localStorage.setItem("token", token);
    const { data } = await axiosInstance().post(
      "/newUsers/loginUsingAuthToken"
    );
    return data;
  } catch (error) {
    console.error("Login witb auth token Failed");
  }
};

const loginUsing2StepVerification = async (
  oneFactorAuthToken: string,
  otp: number
) => {
  try {
    const { data } = await axiosInstance(null, { oneFactorAuthToken }).post(
      "/newUsers/loginUsing2StepVerification",
      {
        otp,
      }
    );
    return data;
  } catch (error) {
    console.error("Login with auth token Failed");
  }
};

const getOTPForLogin = async (oneFactorAuthToken: string) => {
  try {
    const { data } = await axiosInstance(null, { oneFactorAuthToken }).post(
      "/newUsers/getOTPForLogin"
    );
    return data;
  } catch (error) {
    console.error("Login with auth token Failed");
  }
};

const resetPassword = async (email: string) => {
  try {
    const { data } = await axiosInstance().post(
      "/newUsers/getPasswordResetToken",
      {
        email,
      }
    );
    return data;
  } catch (error) {
    console.error("Reset password failed");
  }
};

const checkTokenValidity = async (token: string) => {
  try {
    const { data } = await axiosInstance().post(
      "/newUsers/checkTokenValidity",
      {
        token,
      }
    );
    return data;
  } catch (error) {
    console.error("Token validity check failed");
  }
};
const changePasswordToken = async (
  token: string,
  password: string,
  confirmPassword: string
) => {
  try {
    const { data } = await axiosInstance().post(
      "/newUsers/changePasswordWithToken",
      {
        token,
        password,
        confirmPassword,
      }
    );
    return data;
  } catch (error) {
    console.error("Token validity check failed");
  }
};
export {
  postLoginDetail,
  loginUsingAuthToken,
  loginUsing2StepVerification,
  postLoginDetailV2,
  getOTPForLogin,
  resetPassword,
  checkTokenValidity,
  changePasswordToken,
};
