import { useState } from "react";
import { Button } from "@mui/material";
import "./styles.css";
import { ToastContainer, toast } from "react-toastify";

export default function ConfigSelectPopup(props: any) {
  console.log(props);
  const { category, handleClose, options, returnValue } = props;
  const [optionSelected, setOptionSelected] = useState("");

  const handleValueChange = (e: any) => {
    setOptionSelected(e.target.value);
    console.log(e.target.value);
  };

  const handleSubmit = (e: any) => {
    if (optionSelected === "") {
      toast(`Please Select a ${category}!!!`);
    } else {
      returnValue(category, optionSelected);
    }
  };

  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={handleClose}>
          x
        </span>
        <div>Select {category} Type</div>
        <div>
          <select onChange={handleValueChange}>
            <option value=" "> </option>
            {options.map((user: any) => {
              return <option value={user}>{user}</option>;
            })}
          </select>
        </div>
        <div>
          <Button variant="outlined" color="primary" onClick={handleSubmit}>
            OK
          </Button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
