import { SidebarMenuItemInterface } from "../Interfaces/SideBarInterfaces";
import localStorageStore from "./localStorageStore"; // Import your localStorage facade

interface UserStore {
  token: string;
  language: string;
  sideBar: SidebarMenuItemInterface[] | null;
  userId: string | null;
  userName: string;
  landingPage: string;
  allowAction: boolean;
  keepCredentials: boolean;
  setToken: (token: string) => void;
  setLanguage: (language: string) => void;
  setSideBar: (sideBar: SidebarMenuItemInterface[]) => void;
  setUserId: (userId: string) => void;
  setUserName: (userName: string) => void;
  setLandingPage: (landingPage: string) => void;
  setAllowAction: (allowAction: boolean) => void;
  setKeepCredentials: (keepCredentials: boolean) => void;
}

const getItemOrDefault = <T>(key: string, defaultValue: T): T => {
  const value = localStorageStore.getItem(key);
  return value != null ? (JSON.parse(value) as T) : defaultValue;
};

const useUserStore = (): UserStore => {
  const setToken = (token: string) => {
    localStorageStore.setItem("token", token);
  };

  const setLanguage = (language: string) => {
    localStorageStore.setItem("language", language);
  };

  const setSideBar = (sideBar: SidebarMenuItemInterface[]) => {
    localStorageStore.setItem("sideBar", JSON.stringify(sideBar));
  };

  const setUserId = (userId: string) => {
    localStorageStore.setItem("userId", userId);
  };

  const setUserName = (userName: string) => {
    localStorageStore.setItem("userName", userName);
  };

  const setLandingPage = (landingPage: string) => {
    localStorageStore.setItem("landingPage", landingPage);
  };

  const setAllowAction = (allowAction: boolean) => {
    localStorageStore.setItem("allowAction", JSON.stringify(allowAction));
  };

  const setKeepCredentials = (keepCredentials: boolean) => {
    localStorageStore.setItem(
      "keepCredentials",
      JSON.stringify(keepCredentials)
    );
  };

  return {
    token: localStorageStore.getItem("token") || "",
    language: localStorageStore.getItem("language") || "",
    sideBar: getItemOrDefault("sideBar", null),
    userId: localStorageStore.getItem("userId") || "",
    userName: localStorageStore.getItem("userName") || "",
    landingPage: localStorageStore.getItem("landingPage") || "/",
    allowAction: getItemOrDefault("allowAction", false),
    keepCredentials: getItemOrDefault("keepCredentials", false),
    setToken,
    setLanguage,
    setSideBar,
    setUserId,
    setUserName,
    setLandingPage,
    setAllowAction,
    setKeepCredentials,
  };
};

export default useUserStore;
