import {
  TimelineContent,
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  timelineItemClasses,
} from "@mui/lab";
import {
  Typography,
  ThemeProvider,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Stack,
  Paper,
} from "@mui/material";
import dpTheme from "../../styles/theme/dpTheme";
import { PrimaryButton } from "../../styles/global/components/dpButtons";
import moment from "moment";
import { useEffect, useState } from "react";

interface IProps {
  isDetailedLogsOpen: boolean;
  setIsDetailedLogsOpen: Function;
  equipment: any;
}
interface IColorMapper {
  [key: string]: "success" | "error" | "info";

  success: "success";
  failure: "error";
  pending: "info";
}

export default function BiDirectionalDetails({
  isDetailedLogsOpen,
  setIsDetailedLogsOpen,
  equipment,
}: IProps) {
  let [events, setEvents] = useState([]);
  let eventsAfterJustInitiation = [
    {
      _id: "",
      browserDetails: "",
      heading: "Acknowledged",
      ipAddr: "::ffff:127.0.0.1",
      code: "pending",
      type: "Acknowledged",
      userId: "",
      timestamp: "--",
    },
    {
      _id: "",
      browserDetails: "",
      heading: "Command Executed",
      ipAddr: "::ffff:127.0.0.1",
      code: "pending",
      type: "Command processed",
      userId: "",
      timestamp: "--",
    },
    {
      _id: "",
      browserDetails: "",
      heading: "Command Completed",
      ipAddr: "::ffff:127.0.0.1",
      code: "pending",
      type: "State Restoration",
      userId: "",
      timestamp: "--",
    },
    {
      _id: "",
      browserDetails: "",
      heading: "Initiation to revert back",
      ipAddr: "::ffff:127.0.0.1",
      code: "pending",
      type: "State Restoration",
      userId: "",
      timestamp: "--",
    },
    {
      _id: "",
      browserDetails: "",
      heading: "Reverted Back",
      ipAddr: "::ffff:127.0.0.1",
      code: "pending",
      type: "State Restoration",
      userId: "",
      timestamp: "--",
    },
  ];

  let eventsAfterCommandStart = [
    {
      _id: "",
      browserDetails: "",
      heading: "Command Completed",
      ipAddr: "::ffff:127.0.0.1",
      code: "pending",
      type: "State Restoration",
      userId: "",
      timestamp: "--",
    },
    {
      _id: "",
      browserDetails: "",
      heading: "Initiation to revert back",
      ipAddr: "::ffff:127.0.0.1",
      code: "pending",
      type: "State Restoration",
      userId: "",
      timestamp: "--",
    },
    {
      _id: "",
      browserDetails: "",
      heading: "Reverted Back",
      ipAddr: "::ffff:127.0.0.1",
      code: "pending",
      type: "State Restoration",
      userId: "",
      timestamp: "--",
    },
  ];

  useEffect(() => {
    if (equipment.events.length === 1) {
      let newEvents: any = [];
      let initiationObj = equipment.events[0];
      if (initiationObj.success) {
        initiationObj["code"] = "success";
      } else {
        initiationObj["code"] = "error";
      }
      newEvents.push(initiationObj);
      for (let event of eventsAfterJustInitiation) {
        newEvents.push(event);
      }
      setEvents(newEvents);
    } else if (equipment.events.length === 3) {
      let newEvents: any = [];
      let controlEndTime = moment(equipment.events[1].timestamp);
      controlEndTime.add(equipment.expiryMins, "m");
      controlEndTime.subtract(5.5, "h");
      for (let event of equipment.events) {
        if (event.success) {
          event["code"] = "success";
        } else {
          event["code"] = "error";
        }
        newEvents.push(event);
      }
      if (equipment.events[2].success) {
        for (const eventAfterStart of eventsAfterCommandStart) {
          eventAfterStart["timestamp"] = controlEndTime.toDate().toString();
          eventAfterStart["code"] = "pending";
          newEvents.push(eventAfterStart);
        }
      }
      setEvents(newEvents);
    } else {
      let newEvents: any = [];
      for (let event of equipment.events) {
        // console.log(event);
        if (event.success) {
          event["code"] = "success";
        } else {
          event["code"] = "failure";
        }
        newEvents.push(event);
      }
      setEvents(newEvents);
    }
  }, []);

  //remove when integrating
  // const commandLogs = [
  //   { subCommand: "Initiated", status: "success", time: "12 Jun 2023" },
  //   { subCommand: "Acknowledged ", status: "failure", time: "12 Jun 2023" },
  //   { subCommand: "Command Executed ", status: "pending", time: "12 Jun 2023" },
  //   {
  //     subCommand: "Initiated to revert back ",
  //     status: "success",
  //     time: "12 Jun 2023",
  //   },
  // ];
  const colorMapper: IColorMapper = {
    success: "success",
    failure: "error",
    pending: "info",
  };

  const handleClose = () => {
    setIsDetailedLogsOpen(false);
  };
  return (
    <ThemeProvider theme={dpTheme}>
      <Dialog
        open={isDetailedLogsOpen}
        onClose={handleClose}
        maxWidth={"sm"}
        aria-labelledby="dialog-title"
      >
        <DialogTitle id="alert-dialog-title">
          <Stack
            direction="row"
            alignItems="baseline"
            justifyContent="space-between"
          >
            <Stack direction="column">
              <Typography variant="h2" color="secondary.main">
                Detailed Logs
              </Typography>
              <Typography variant="h5" color="secondary.main" mt={2}>
                {equipment.equipment}
              </Typography>
            </Stack>

            <Button onClick={handleClose}>
              <Typography variant="h4">X</Typography>
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Timeline
            position="right"
            sx={{
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {events.map((event: any, index: number) => {
              return (
                <TimelineItem
                  className="custom-timeline-item"
                  key={event.timestamp}
                >
                  <TimelineSeparator>
                    <TimelineDot color={colorMapper[event.code]} />
                    {index === events.length - 1 ? null : <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Stack width="300px" gap={1} p={2} component={Paper}>
                      <Typography color="secondary" variant="h6">
                        {event.heading}
                      </Typography>
                      <Typography color="disabled" variant="body2">
                        {" "}
                        {event.timestamp === "--"
                          ? "-- / -- / --"
                          : moment(event.timestamp)
                              .subtract(5.5, "h")
                              .format("DD MMM YYYY hh:mm A")
                              .toString()}
                      </Typography>
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            sx={{
              borderRadius: "7px",
              height: "40px",
            }}
            onClick={handleClose}
          >
            Okay
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
