import axiosInstance from '../../constants/axiosInstance';

const migrateInventory = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().post(`/plantManagement/migrateInventory/${plantId}`);
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const revertInventoryMigration = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/revertInverntoryMigration/${plantId}`
    );
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getInventoryMigrationLogsForPlantId = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/getInventoryMigrationLogsForPlantId/${plantId}`
    );
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

export { migrateInventory, revertInventoryMigration, getInventoryMigrationLogsForPlantId };
