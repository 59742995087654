//@ts-nocheck
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { Box, Button, ThemeProvider, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getAlertMasterData, postAlertMasterData } from '../services/alertTaskMaster';
import LayoutComponent from '../components/LayoutComponent';
import dpTheme from '../styles/theme/dpTheme';
import TaskSection from '../components/alertTaskMaster/TaskSection';
import WorkflowConfiguration from '../components/alertTaskMaster/WorkflowConfiguration';
import AlertTaskForm from '../components/alertTaskMaster/AlertTaskForm';
import { PrimaryButton } from '../styles/global/components/dpButtons';
import { FixedTasks, MasterData } from '../Interfaces/alertTask';

const AlertMasterStyle = {
  body: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px 12px',
    marginBottom: '16px',
  },
  sectionHeading: {
    alignItems: 'center',
    verticalAlign: 'middle',
    backgroundColor: 'red',
  },
  sectionBody: {
    fontFamily: 'Montserrat Bold',
    fontSize: 24,
    alignItems: 'center',
    verticalAlign: 'middle',
    marginBottom: '20px',
  },

  summaryStyle: {
    fontFamily: 'Montserrat',
  },
};

export default function AlertTaskMaster() {
  const navigate = useNavigate();
  const [masterData, setMasterData] = useState<MasterData>({});
  const [refresh, setRefresh] = useState<boolean>(true);

  useEffect(() => {
    getAlertMasterData().then(data => {
      setMasterData(data);
    });
  }, []);

  const addnewSubtask = (section: string, subsection: string, indexofTask: number) => {
    let oldData = masterData;
    if (section === 'Core Equipments' || section === 'Tanks') {
      oldData[section][subsection][indexofTask]['subTasks'].push({
        name: 'Enter Subtask',
        namehindi: 'सबटास्क दर्ज करें',
      });
    } else {
      oldData[section][indexofTask]['subTasks'].push({
        name: 'Enter Subtask',
        namehindi: 'सबटास्क दर्ज करें',
      });
    }
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const UpdateTask = (
    section: string,
    subsection: string,
    index: number,
    sectiona: string,
    valuefor: string,
    value: any
  ) => {
    let oldData = masterData;
    if (section === 'Core Equipments' || section === 'Tanks') {
      oldData[section][subsection][index][sectiona][valuefor] = value;
    } else {
      // oldData[subsection][index][sectiona][valuefor]=value
      if (section === 'General' || section === 'Blowers' || section === 'Pumps')
        oldData[section][index][sectiona][valuefor] = value;
    }

    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const addTask = (section: string, subsection: string) => {
    let newTask = {
      toUser: 'Operator Group',
      observationConditions: [],
      resolutionConditions: [],
      type: 'Mixed',
      dueTimeFromPosting: '02:00:00',
      autoCalling: false,
      escalations: {
        'Level 1': {
          time: '10:00:00',
          group: 'Escalation Level 1',
        },
        'Level 2': {
          time: '12:00:00',
          group: 'Escalation Level 2',
        },
        'Level 3': {
          time: '14:00:00',
          group: 'Escalation Level 3',
        },
      },
      heading: {
        eng: 'Enter Heading',
        hindi: 'शीर्षक दर्ज करें',
      },
      description: {
        eng: 'Enter Description',
        hind: 'विवरण दर्ज करें',
      },
      subTasks: [
        {
          name: 'Enter Subtask',
          namehindi: 'सबटास्क दर्ज करें',
        },
      ],
    };

    let oldData = masterData;
    if (section === 'Core Equipments' || section === 'Tanks') {
      oldData[section][subsection].push(newTask);
    } else {
      oldData[section].push(newTask);
    }

    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const UpdateSubTask = (
    section: string,
    subsection: string,
    taskindex: number,
    subTaskIndex: number,
    key: string,
    value: any
  ) => {
    let oldData = masterData;
    if (section === 'Core Equipments' || section === 'Tanks') {
      oldData[section][subsection][taskindex]['subTasks'][subTaskIndex][key] = value;
    } else {
      oldData[section][taskindex]['subTasks'][subTaskIndex][key] = value;
    }
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const deleteSubTask = (
    section: string,
    subsection: string,
    indexofTask: number,
    indexofSubTask: number
  ) => {
    let oldData = masterData;
    if (section === 'Core Equipments' || section === 'Tanks') {
      oldData[section][subsection][indexofTask]['subTasks'].splice(indexofSubTask, 1);
    } else {
      oldData[section][indexofTask]['subTasks'].splice(indexofSubTask, 1);
    }
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const updateDirectValue = (
    section: string,
    subsection: string,
    taskindex: number,
    key: string,
    value: any
  ) => {
    let oldData = masterData;
    if (section === 'Core Equipments' || section === 'Tanks') {
      oldData[section][subsection][taskindex][key] = value;
    } else {
      oldData[section][taskindex][key] = value;
    }
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const deleteTask = (section: string, subsection: string, index: number) => {
    let oldData = masterData;
    // debugger;
    if (section === 'Core Equipments' || section === 'Tanks') {
      oldData[section][subsection].splice(index, 1);
    } else {
      oldData[section].splice(index, 1);
    }
    setMasterData(oldData);
    setRefresh(!refresh);
  };

  const update = () => {
    let data = masterData;
    let sections = Object.keys(data);
    let success = true;
    for (let sectionIndex = 0; sectionIndex < sections.length; sectionIndex++) {
      if (sections[sectionIndex] === 'Core Equipments' || sections[sectionIndex] === 'Tanks') {
        let subsections = Object.keys(data[sections[sectionIndex]]);
        for (let subIndex = 0; subIndex < subsections.length; subIndex++) {
          for (
            let taskIndex = 0;
            taskIndex < data[sections[sectionIndex]][subsections[subIndex]].length;
            taskIndex++
          ) {
            try {
              // data[sections[sectionIndex]][subsections[subIndex]][dataIndex]["observationConditions"]=JSON.parse(data[sections[sectionIndex]][dataIndex]["observationConditions"])
              data[sections[sectionIndex]][subsections[subIndex]][taskIndex][
                'observationConditions'
              ] = JSON.parse(data[sections[sectionIndex]][taskIndex]['observationConditions']);
            } catch (err) {
              success = false;
              break;
            }
          }
        }
      } else {
        for (let taskIndex = 0; taskIndex < data[sections[sectionIndex]].length; taskIndex++) {
          //  console.log("data index : ", dataIndex);
          try {
            // data[sections[sectionIndex]][dataIndex]["observationConditions"]=JSON.parse(data[sections[sectionIndex]][dataIndex]["observationConditions"])

            data[sections[sectionIndex]][taskIndex]['observationConditions'] = JSON.parse(
              data[sections[sectionIndex]][taskIndex]['observationConditions']
            );
          } catch (err) {
            success = false;
            break;
          }
        }
      }
    }
    if (success) {
      postAlertMasterData(masterData).then(data => {
        navigate('/plants');
      });
    } else {
      toast('Condition of any of the alert is not in Valid Json format. Please Check');
    }
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent>
        <Box m={'12px 0 16px'} p={'2px 10px'}>
          <Typography variant="h1">Alert Task Master</Typography>
        </Box>
        {Object.keys(masterData).map(section => {
          if (['Core Equipments', 'Tanks'].includes(section)) {
            return (
              <Box m={1} p={1} borderRadius={5} border={2}>
                <Typography variant="h3">{section}</Typography>
                {Object.keys(masterData[section]).map(subsection => {
                  return (
                    <Box m={1} p={1} borderLeft={2}>
                      <div style={AlertMasterStyle.sectionBody}>
                        {subsection}
                        <span style={{ float: 'right' }}>
                          <Button
                            variant="contained"
                            onClick={() => {
                              addTask(section, subsection);
                            }}
                          >
                            <AddIcon />
                            Add Alert Task
                          </Button>
                        </span>
                      </div>
                      {masterData[section][subsection].map((task, indexTask: number) => {
                        return (
                          <Box m={1}>
                            <Accordion TransitionProps={{ unmountOnExit: true }}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div
                                  style={{
                                    font: 'Montserrat Bold',
                                    fontSize: 20,
                                  }}
                                >
                                  {task.heading.eng}
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box m={1}>
                                  <TaskSection
                                    section={section}
                                    subsection={subsection}
                                    indexTask={indexTask}
                                    task={task}
                                    deleteTask={deleteTask}
                                    updateTask={UpdateTask}
                                    updateDirectValue={updateDirectValue}
                                  />
                                  <AlertTaskForm
                                    task={task}
                                    section={section}
                                    subsection={subsection}
                                    indexTask={indexTask}
                                    addNewSubTask={addnewSubtask}
                                    deleteSubTask={deleteSubTask}
                                    updateSubTask={UpdateSubTask}
                                    updateDirectValue={updateDirectValue}
                                  />
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          </Box>
                        );
                      })}
                    </Box>
                  );
                })}
              </Box>
            );
          } else {
            return (
              <Box m={1} p={2} borderRadius={5} border={2}>
                <div style={AlertMasterStyle.body}>
                  <Typography variant="h3">{section}</Typography>
                  <span style={{ float: 'right' }}>
                    <Button
                      variant="contained"
                      onClick={() => {
                        addTask(section, '');
                      }}
                    >
                      <AddIcon />
                      Add Alert Task
                    </Button>
                  </span>
                </div>
                {masterData[section].map((task: FixedTasks, indexTask: number) => {
                  if (typeof task['observationConditions'] !== 'string') {
                    task['observationConditions'] = JSON.stringify(task['observationConditions']);
                  }
                  return (
                    <Box m={1}>
                      <Accordion TransitionProps={{ unmountOnExit: true }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography variant="subtitle2" style={AlertMasterStyle.summaryStyle}>
                            {task.heading.eng}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box m={1}>
                            <TaskSection
                              section={section}
                              subsection={''}
                              indexTask={indexTask}
                              task={task}
                              deleteTask={deleteTask}
                              updateTask={UpdateTask}
                              updateDirectValue={updateDirectValue}
                            />
                            <WorkflowConfiguration
                              addNewSubTask={addnewSubtask}
                              deleteSubTask={deleteSubTask}
                              updateSubTask={UpdateSubTask}
                              updateDirectValue={updateDirectValue}
                              section={section}
                              indexTask={indexTask}
                              task={task}
                            />
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
              </Box>
            );
          }
        })}
        <Box m={1} p={1}>
          <PrimaryButton onClick={update}>Update Master</PrimaryButton>
        </Box>
        <ToastContainer />
      </LayoutComponent>
    </ThemeProvider>
  );
}
