const breakpoints = {
    mobile: "@media (max-width: 767px)",
    tablet: "@media (min-width: 768px) and (max-width: 1024px)"
};

export const DashboardComponentStyle = {
    headingStyle: {
        color: "#1A3356",
        fontFamily: "Roboto",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
        [breakpoints.tablet]: {
            fontSize: "24px",  
        },
        [breakpoints.mobile]: {
            fontSize: "20px", 
        },
    },
    labelStyle: {
        position: 'absolute',
        margin: 0,
        padding: "0 8px",
        fontWeight: 500,
        color: "#666",
        transform: "translate(7px, -9px) scale(0.75)",
        zIndex: 1,
        backgroundColor: "white",
        lineHeight: "1.4375em",
        fontSize: "16px",
        left: 0,
        top: 0,
        pointerEvents: "auto",
        userSelect: "none",
        letterSpacing: "-0.05px",
        transformOrigin: "top left",
        whiteSpace: "nowrap",
    },
    dateAndTimeButtonStyle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        color: "#666",
        backgroundColor: "white",
        padding: "8px 10px",
        border: "1px solid #BDBDBD",
        textTransform: "capitalize",
        boxShadow: "none",
        '&:hover': {
            backgroundColor: 'white',
            boxShadow: 'none'
        },
        marginTop: '0 !important',
        minHeight: '50px',
        width: '100%',
        height: '100%'
    },
    verticalDivider: {
        width: '2px',
        height: '15px',
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        marginLeft: '52px',
        marginRight: '26px',
    },
    verticalDividerSm: {
        width: '2px',
        height: '15px',
        backgroundColor: 'rgba(0, 0, 0, 0.38)',
        marginLeft: '24px',
        marginRight: '16px'
    },
    clockSettingStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: "white",
        width: '42px',
        cursor: 'pointer',
        borderRadius: '4px',
        border: '1px solid #BDBDBD',
        padding: '2px 4px',
        [breakpoints.tablet]: {
            marginLeft: 'inherit',
        },
        [breakpoints.mobile]: {
            marginLeft: '0 !important',
            padding: '2px 8px',
        }
    },
    ToolbarSection2: {
        display: 'flex',
        flexDirection:  'row',
        justifyContent: 'space-between',
        marginTop: '27px',
        // maxHeight: '76px',
        [breakpoints.tablet]: {
        },
        [breakpoints.mobile]: {
            marginTop: '17px',
            flexDirection: 'column',
        }
    }
}