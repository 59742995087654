import axiosInstance from '../constants/axiosInstance';

const getSolutionsListForClient = async (
  storeId: string,
  assetId: string,
  chemicalId: string,
  startIndex: number,
  endIndex: number
) => {
  try {
    let { data } = await axiosInstance().get(`/store/getSolutionsListForClient`, {
      params: {
        storeId: storeId,
        assetId: assetId,
        chemicalId: chemicalId,
        startIndex: startIndex,
        endIndex: endIndex,
      },
    });
    return data?.data;
  } catch (err) {
    console.error('ERROR | getInventory | ', err);
  }
};

export { getSolutionsListForClient };
