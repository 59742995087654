import axiosInstance from '../constants/axiosInstance';
import { createQueryString } from '../utilities/application';

const getChemicalLogData = async (storeId: string, plantId: string, chemicalId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/store/getChemicalCumulativeDataAndFilters?storeId=${storeId}&asset=${plantId}&chemicalId=${chemicalId}`
    );
    return data?.data;
  } catch (err) {
    console.error('ERROR | getChemicalLogData | ', err);
  }
};

const getAssetsForChemical = async (storeId: string, chemicalId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/store/getAssetsForChemical?storeId=${storeId}&chemicalId=${chemicalId}`
    );
    return data?.data;
  } catch (err) {
    console.error('ERROR | getChemicalLogData | ', err);
  }
};

const getChemicalLogGridData = async (params: Record<string, any>) => {
  try {
    const queryString = createQueryString(params);
    const { data } = await axiosInstance().get(`/store/chemicalLogs?${queryString}`);
    return data?.data;
  } catch (err) {
    console.error('ERROR | getChemicalLogGridData | ', err);
  }
};

export { getChemicalLogData, getChemicalLogGridData, getAssetsForChemical };
