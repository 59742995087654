import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Flowmeter } from '../../Interfaces/PlantConfigurationInterface';
import { Autocomplete } from '@mui/material';
import { styledBoxWithShadow } from '../../styles/global/components/dpBox';
import { useState } from 'react';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { PlantConfigStyle } from './PlantConfigStyle';
import DiagramSelectComponent from './subComponents/DiagramSelectComponent';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddEquipmentInsideEquipmentTypePopup from '../PlantConfigPopups/EquipmentInsideTypeSelector';
import SensorTagArray from './subComponents/SensorTagArray';
import SubEquipmentViewAndControl from './subEquipment/SubEquipmentViewAndControl';
import SettingsIcon from '@mui/icons-material/Settings';
import EqtInsideEqt from '../../assets/icons/EqtInsideEqt.svg';
interface IProps {
  flowMeterData: Flowmeter[];
  addFlowMeter: Function;
  RemoveElement: Function;
  updateValue: Function;
  updateSensorTagValue: Function;
  handleNickNameChange: Function;
  handleMultiSelect: Function;
  equipChoice: any;
  updateFlowmeterType: Function;
  plantConfig: any;
  setPlantConfig: Function;
  TankOptions: Function;
  ValveOptions: Function;
  CoreEquipmentOptions: Function;
  HeaderOptions: Function;
  tankChoice: any;
  valveChoice: any;
  headerChoice: any;
  handleShowOnDiagramCheck: Function;
  sensorPostFix: string;
}

function FlowMeterConfig({
  flowMeterData,
  addFlowMeter,
  RemoveElement,
  handleMultiSelect,
  updateSensorTagValue,
  handleNickNameChange,
  updateValue,
  equipChoice,
  updateFlowmeterType,
  plantConfig,
  setPlantConfig,
  TankOptions,
  ValveOptions,
  CoreEquipmentOptions,
  HeaderOptions,
  tankChoice,
  valveChoice,
  headerChoice,
  handleShowOnDiagramCheck,
  sensorPostFix,
}: IProps) {
  const [equipmentIndex, setEquipmentIndex] = useState<Number | null>(null);
  const [equipmentName, setEquipmentName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [equipment, setEquipment] = useState('');
  const [showAddSubEquipment, setShowAddSubEquipment] = useState(false);
  function checkIfSubEquipmentHasData(subEquipments: any) {
    let hasData = false;
    let subEquipmentCategories = Object.keys(subEquipments);
    if (subEquipmentCategories.length > 0) {
      subEquipmentCategories.forEach(category => {
        if (subEquipments[category].length > 0) {
          hasData = true;
        }
      });
    } else {
      hasData = false;
    }
    return hasData;
  }
  return (
    <>
      <Box m={1} p={2}>
        {flowMeterData.map((flowmeter: any, index) => {
          return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.6rem',
                  }}
                >
                  <Typography variant="h4">Flowmeter {flowmeter.count}</Typography>
                  <Box sx={{ marginLeft: 'auto' }}>
                    {flowmeter['subEquipments'] &&
                      checkIfSubEquipmentHasData(flowmeter['subEquipments']) && (
                        <img
                          src={EqtInsideEqt}
                          style={{ height: '20px' }}
                          alt="Equipment Inside Icon"
                        />
                      )}
                  </Box>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box m={1} p={1} sx={styledBoxWithShadow}>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Box m={1} p={1}>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={equipChoice}
                            value={flowmeter['In']}
                            onChange={(eveny, newValue) => {
                              handleMultiSelect('Flowmeter', index, 'In', newValue);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Choose Input"
                                placeholder="Input"
                                style={PlantConfigStyle.equipmentTextField}
                              />
                            )}
                          />
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={equipChoice}
                            value={flowmeter['Out']}
                            onChange={(eveny, newValue) => {
                              handleMultiSelect('Flowmeter', index, 'Out', newValue);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Choose Output"
                                placeholder="Output"
                                style={PlantConfigStyle.equipmentTextField}
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <>
                          <Box m={1} p={1}>
                            <Select
                              id={'Flow Meter Conf ' + flowmeter['count']}
                              value={flowmeter['name']}
                              onChange={e =>
                                updateFlowmeterType('Flowmeter', index, 'name', e.target.value)
                              }
                              label="Flowmeter Name"
                            >
                              <MenuItem value={'Inlet Flowmeter'}>Inlet Flowmeter</MenuItem>
                              <MenuItem value={'Outlet Flowmeter'}>Outlet Flowmeter</MenuItem>
                              <MenuItem value={'Other'}>Other</MenuItem>
                            </Select>
                          </Box>
                        </>
                        <>
                          <Box m={1} p={1}>
                            <TextField
                              fullWidth
                              id={'model+MR' + flowmeter['count']}
                              label="FlowMeter NickName"
                              value={flowmeter['nickName']}
                              onChange={e => {
                                handleNickNameChange({
                                  category: 'Flowmeter',
                                  index: index,
                                  value: e.target.value,
                                });
                              }}
                              style={PlantConfigStyle.equipmentTextField}
                            />
                          </Box>
                        </>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'FlowMeterAge ' + flowmeter['count']}
                            label="Age in months"
                            value={flowmeter['age']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Flowmeter',
                                index,
                                valuefor: 'age',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 1000,
                              step: '1',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>

                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'FlowMeterTag ' + flowmeter['count']}
                            label="Tag Name"
                            value={flowmeter['tagName']}
                            type="text"
                            onChange={e =>
                              updateValue({
                                category: 'Flowmeter',
                                index,
                                valuefor: 'tagName',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 1000,
                              step: '1',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <DiagramSelectComponent
                          category="Flowmeter"
                          index={index}
                          updateValue={updateValue}
                          layoutDiagram={flowmeter['layoutDiagram']}
                        />
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Box m={1}>
                          <SensorTagArray
                            categoryName="Flowmeter"
                            formHelperText={{
                              name: flowmeter.name,
                              count: flowmeter.count,
                            }}
                            sensorsTagData={flowmeter['sensors']}
                            componentIndex={index}
                            updateSensorTagValue={updateSensorTagValue}
                            updateValue={updateValue}
                            hmiData={flowmeter['hmiButtons']}
                          />
                        </Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={flowmeter.displayNode}
                              // checked={check}
                              onChange={() => {
                                handleShowOnDiagramCheck(
                                  'Flowmeter',
                                  index,
                                  !flowmeter.displayNode
                                );
                              }}
                              name={'Show on diagram'}
                            />
                          }
                          label={'Show on diagram'}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={PlantConfigStyle.configFormWrapper}>
                    <Button
                      variant="contained"
                      style={PlantConfigStyle.deleteButton}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setEquipmentIndex(index);
                        setEquipmentName(`Flowmeter ${flowmeter.count}`);
                        setEquipment('Flowmeter');
                      }}
                    >
                      <DeleteIcon style={PlantConfigStyle.deleteIcon} />
                    </Button>
                    <Button
                      variant="contained"
                      style={PlantConfigStyle.deleteButton}
                      onClick={() => {
                        setShowAddSubEquipment(true);
                        setEquipmentIndex(index);
                        setEquipmentName(`Flowmeter ${flowmeter.count}`);
                        setEquipment('Flowmeter');
                      }}
                    >
                      <AddIcon style={PlantConfigStyle.deleteIcon} />
                    </Button>
                  </div>
                </Box>
                {flowmeter['subEquipments'] &&
                  checkIfSubEquipmentHasData(flowmeter['subEquipments']) && (
                    <>
                      <Box m={1} p={1}>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            margin: '2rem 0',
                          }}
                        >
                          <Typography variant="h4">Sub - Equipments</Typography>
                          <SettingsIcon style={{ paddingLeft: '1rem' }} />
                        </span>
                      </Box>
                      <SubEquipmentViewAndControl
                        parentEquipmentType="Flowmeter"
                        parentEquipmentIndex={index}
                        subEquipments={flowmeter['subEquipments']}
                        plantConfig={plantConfig}
                        setplantConfig={setPlantConfig}
                        tankChoice={tankChoice}
                        valveChoice={valveChoice}
                        equipChoice={equipChoice}
                        headerChoice={headerChoice}
                        sensorPostFix={sensorPostFix}
                      />
                    </>
                  )}
              </AccordionDetails>
            </Accordion>
          );
        })}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={`You are deleting the ${equipmentName}`}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            RemoveElement(equipment, equipmentIndex);
            setProcessing(false);
            setShowDeleteModal(false);
          }}
        />
        <AddEquipmentInsideEquipmentTypePopup
          open={showAddSubEquipment}
          handleClose={() => {
            setShowAddSubEquipment(false);
          }}
          plantConfig={plantConfig}
          setPlantConfig={setPlantConfig}
          parentCategory="Flow Meters"
          parentIndex={equipmentIndex!}
          TankOptions={TankOptions}
          ValveOptions={ValveOptions}
          CoreEquipmentOptions={CoreEquipmentOptions}
          HeaderOptions={HeaderOptions}
          sensorPostFix={sensorPostFix}
        />
      </Box>
    </>
  );
}

export default FlowMeterConfig;
