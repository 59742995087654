function getInitials(input: string, characterLimit: number): string {
  // Split the input string into words using whitespace as the delimiter
  // ex: Piyush Negi fetches PN
  const words = input.split(/\s+/);

  // Extract the initials of each word and concatenate them
  const initials = words.map(word => word.charAt(0)).join('');

  return initials.slice(0, characterLimit);
}

function truncateText(text: string, maxLength: number = 10) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  } else {
    return text;
  }
}

export { getInitials, truncateText };
