import { useState } from "react";
import { FormControl, TextField } from "@mui/material";
import loginStyles from "../styles/login.module.css";
import validator from "validator";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { sendForgotPasswordToEmail } from "../services/users";
import { PrimaryRoundedButton } from "../styles/global/components/dpButtons";

export default function ForgotPassword() {
  const classes = loginStyles;
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const handleSubmit = () => {
    let isValidEmail = validateInput();
    if (isValidEmail) {
      sendForgotPasswordToEmail(email)
        .then((data) => {
          toast("Password sent to Email");
        })
        .catch((err) => {
          console.log(err);
          toast(err.message);
        });
    }
  };

  const validateInput = () => {
    if (email.length === 0) {
      setEmailError("Field cannot be left blank");
      return false;
    } else if (validator.isEmail(email)) {
      setEmailError("");
      return true;
    } else {
      setEmailError("Please enter a valid email");
      return false;
    }
  };

  return (
    <div className={classes.container}>
      <h2 className={classes.heading}>Password Reset</h2>
      <FormControl fullWidth={true} className={classes.form}>
        <div className={classes.inputContainer}>
          <TextField
            className={classes.input}
            required
            id="email"
            label="Email"
            variant="outlined"
            error={emailError.length > 0 ? true : false}
            //Remove helperText if error not wanted as subtext and call toast("Error") instead in validateInput
            helperText={emailError.length > 0 ? emailError : null}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
        </div>
        <div className={classes.inputContainer}>
          <PrimaryRoundedButton
            className={classes.submitButton}
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            Reset Password
          </PrimaryRoundedButton>
        </div>
        <div className={classes.forgotPasswordBlock}>
          <Link to="/" className={classes.forgotPassword}>
            Go Back
          </Link>
        </div>
      </FormControl>
      <ToastContainer />
    </div>
  );
}
