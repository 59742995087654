import { Dialog, DialogContent } from '@mui/material';
import EquipmentNodeDiagram from './EquipmentNodeDiagram';

function EquipmentNodeDialog({ id, biSensorId, biSensorStatus, LTSensor, LTSensorValue,  equipmentName, equipmentImage, imageControlId, data, showAllSensors, setShowAllSensors, showSettings, OnOffSensorStatus }: any) {
  return (
    <Dialog
      open={true}
      maxWidth='md'
      onClose={() => {
        setShowAllSensors(!showAllSensors);
      }}
    >
      <DialogContent>
        <EquipmentNodeDiagram
          id={id}
          parent="Dialog"
          equipmentName={equipmentName}
          equipmentImage={equipmentImage}
          imageControlId={imageControlId}
          data={data}
          showAllSensors={showAllSensors}
          showSettings={showSettings}
          biSensorId={biSensorId}
          biSensorStatus={biSensorStatus}
          LTSensor={LTSensor}
          LTSensorValue={LTSensorValue}
          OnOffSensorStatus={OnOffSensorStatus}
        />
      </DialogContent>
    </Dialog>
  );
}

export default EquipmentNodeDialog;