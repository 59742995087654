import axiosInstance from "../constants/axiosInstance";

const getDashboardConfigData = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/dashboard/getDashboardConfigData?plantId=${plantId}`
    );
    return data.dashboardConfigData;
  } catch (error) {
  }
};

const setAutoEmailingData = async (
  plantId: string,
  pageId: string,
  subField: string
) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/setAutoEmailingData?plantId=${plantId}&pageId=${pageId}`,
      { subField: subField }
    );
    return data.data;
  } catch (error) {
  }
};

const deletePage = async (pageId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/dashboard/deleteDashboardPage?pageId=${pageId}`
    );
    return data.data;
  } catch (error) {
  }
};

const createClonePage = async (pageId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/dashboard/clonePageConfig?pageId=${pageId}`
    );
    return data.data;
  } catch (error) {
  }
};

const changeDefaultPageForUser = async (plantId: string, pageId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/dashboard/setDashboardConfigData?plantId=${plantId}&pageId=${pageId}`
    );
    return data.data;
  } catch (error) {
  }
};

export {
  getDashboardConfigData,
  setAutoEmailingData,
  deletePage,
  createClonePage,
  changeDefaultPageForUser,
};
