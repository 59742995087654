//@ts-nocheck piyush when in list pr.
import React, { useEffect, useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DialogBox from '../../../../core-components/theme/components/DialogBox/DialogBox';
import Table from '../../../../core-components/theme/components/Table/Table';
import DetailIcon from '../../../../core-components/assets/icons/DetailIcon';
import DeleteFilledIcon from '../../../../core-components/assets/icons/DeleteIconFilled';
import EditIcon from '../../../../core-components/assets/icons/EditIcon';
import CustomIconButton from '../../../../core-components/theme/components/Button/Iconbutton';
import { Box, Chip, Menu, MenuItem, Stack, Typography } from '@mui/material';
import CustomTreeComponent from '../../../../core-components/theme/components/Tree/CustomTree';
import Tabs from '../../../../core-components/theme/components/Tabs/Tabs';
import TwoRowLayout from '../../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import ConfirmationDialog from '../../../../core-components/theme/components/DialogBox/ConfirmationDialog';
import { Neutrals } from '../../../../core-components/theme/color-palette/colors';
import fakeWorkspace from './fakeworkspace';
import CustomWorkspaceTreeItem from '../../../workspace-management/component/CustomWorkspaceTree';
import {
  getWorkspaceItemId,
  getWorkspaceItemlabel,
} from '../../../workspace-management/helpers/customtreehelpers';
import { getUserItemId, getUserItemlabel } from '../../helpers/customUserTreeHelpers';
import CustomUserTreeItem from './CustomUserTree';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getFeatureList,
  getUserList,
  getWorkspaceList,
} from '../../../../services/userGroup/userGroup';
import fakeUserData from './fakeUser';

const OptionsMenu: React.FC<{ row: any; handleDeleteUser: any; handleEditUser: any }> = ({
  row,
  handleDeleteUser,
  handleEditUser,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  //@ts-ignore
  const [filterItems, setFilterItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [Confirmationopen, setConfirmationOpen] = useState(false);
  console.log({ row });
  function handleOpen() {
    setOpen(true);
  }
  function handleClose() {
    setOpen(false);
    handleMenuClose();
  }
  function handleConfirmationOpen() {
    setConfirmationOpen(true);
  }
  function handleConfirmationClose() {
    setConfirmationOpen(false);
    handleMenuClose();
  }
  const [value, setValue] = useState(0);
  const handleChange = (newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const columns = [
    {
      field: 'userName',
      headerName: 'User Name',
    },
    {
      field: 'email',
      headerName: 'Email',
    },
  ];

  const transformData = (
    data: { userName?: string[]; email?: string[] } = {}
  ): { userName: string; email: string }[] => {
    if (userDetails) {
      return data.map(item => {
        return {
          userName: item.name,
          email: item.email,
        };
      });
    }
  };

  const { data: userDetails, isLoading: userDetailsLoading } = useQuery({
    queryKey: ['userDetails', open, value],
    queryFn: () => getUserList({ mode: 'details', userGroupId: row._id }),
    enabled: open && value === 0,
  });

  const { data: moduleList, isLoading: moduleListLoading } = useQuery({
    queryKey: ['moduleDetails', open, value],
    queryFn: () => getFeatureList({ mode: 'details', userGroupId: row._id }),
    enabled: open && value === 1,
  });

  const { data: workspaceList, isLoading: workspaceListLoading } = useQuery({
    queryKey: ['workspaceDetails', open, value],
    queryFn: () => getWorkspaceList({ mode: 'details', userGroupId: row._id }),
    enabled: open && value === 2,
  });

  useEffect(() => {
    if (userDetails) {
      transformData(userDetails);
    }
  }, [userDetails, userDetailsLoading]);

  return (
    <>
      <CustomIconButton onClick={handleMenuOpen} icon={MoreVertIcon} />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        slotProps={{
          paper: {
            style: {
              boxShadow: '0px 2px 8px 0px #1A335626',
            },
          },
        }}
        MenuListProps={{
          sx: {
            border: `1px solid ${Neutrals[300]}`,
            py: 0,
            backgroundColor: '#ffffff',
            borderRadius: '4px',
          },
        }}
      >
        {/* for details */}
        <MenuItem
          sx={{
            height: '36px',
            paddingLeft: '8px',
            borderBottom: `1px solid ${Neutrals[300]}`,
            display: 'flex',
            alignItems: 'center',
          }}
          onClick={handleOpen}
        >
          <DetailIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Details
          </Typography>
        </MenuItem>
        {/* for edit page */}
        <MenuItem
          sx={{
            height: '36px',
            borderBottom: `1px solid ${Neutrals[300]}`,
            display: 'flex',
            paddingLeft: '8px',
            alignItems: 'center',
          }}
          onClick={() => handleEditUser(row._id)}
        >
          <EditIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Edit
          </Typography>
        </MenuItem>
        {/* for delete */}
        <MenuItem
          sx={{ height: '36px', paddingLeft: '8px', display: 'flex', alignItems: 'center' }}
          onClick={handleConfirmationOpen}
        >
          <DeleteFilledIcon />
          <Typography ml={2} pr={2} color={Neutrals[500]}>
            Delete
          </Typography>
        </MenuItem>
        {/* for details */}
        <DialogBox open={open} title={row?.name?.name + ' - Details'} handleClose={handleClose}>
          <Box>
            <TwoRowLayout
              gap={0}
              style={{ height: '60vh' }}
              child1={
                <>
                  <Stack direction="column" pb={2} width="100%">
                    <Stack direction="row" mb={2} gap={4}>
                      <Typography variant="h4">Email ID:{row?.name?.email}</Typography>
                      <Typography variant="h4">Phone Numeber: +91 999999999</Typography>
                    </Stack>
                    <Stack direction="column" mb={2}>
                      <Typography variant="h4" mb={2}>
                        User Group
                      </Typography>
                      <Stack direction="row" gap={2}>
                        {row?.groups.map(group => <Chip label={group?.name} />)}
                      </Stack>
                    </Stack>
                    <Stack>
                      <Typography variant="h4" mb={2}>
                        Adminstrative Role
                      </Typography>
                      <Stack direction="row" gap={2}>
                        {row?.roles.map(role => <Chip label={role?.name} />)}
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              }
              child2={
                <CustomTreeComponent
                  show={true}
                  heading="Workspace / Asset"
                  getItemId={(item: any) => item.itemId}
                  getItemlabel={getWorkspaceItemlabel}
                  CustomTreeItem={CustomUserTreeItem}
                  items={fakeUserData || []}
                />
              }
            />
          </Box>
        </DialogBox>
        {/* for delete */}
        <ConfirmationDialog
          open={Confirmationopen}
          handleClose={handleConfirmationClose}
          title="Delete User"
          description={
            <Typography variant="h4">
              Are you sure you want to delete{' '}
              <Typography component="span" variant="highlight">
                {row?.name?.name}
              </Typography>
              ? You can’t undo this action
            </Typography>
          }
          actions={[
            {
              text: 'Cancel',
              color: 'white',
              textColor: '#8B8B8B',
              handler: () => console.log('cancel clicked'),
            },
            {
              text: 'Delete',
              color: 'SemanticError.dark',
              textColor: 'white',
              handler: () => handleDeleteUser(row._id),
            },
          ]}
        ></ConfirmationDialog>
      </Menu>
    </>
  );
};

export default OptionsMenu;
