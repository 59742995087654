import { useState, useEffect } from 'react';
import {} from '@emotion/react';
import dpTheme from '../styles/theme/dpTheme';
import LayoutComponent from '../components/LayoutComponent';
import {
  ThemeProvider,
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Stack,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';
import { PrimaryRoundedButton } from '../styles/global/components/dpButtons';
import { useLocation, useParams } from 'react-router-dom';
import createSolution from '../services/Solution';
import getTanks from '../services/Tanks';
import { ToastContainer, toast } from 'react-toastify';
import InputAdornment from '@mui/material/InputAdornment';
import { UNITS_INFO } from '../utils/units.utils';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { InfoBox } from '../styles/global/components/TextInfoBoxes';
import moment from 'moment';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DateRangeIcon from '@mui/icons-material/DateRange';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import useAuth from '../utilities/useAuth';

interface TanksInterface {
  name: string;
  id: string;
  dosingMechanism: string;
}

export default function ChemicalComposition() {
  const { plantId } = useParams();
  const { state } = useLocation();
  const {
    storeId,
    clientId,
    chemicalName,
    chemicalId,
    chemicalUnit,
    availableQuantity,
    remarkId,
    plantIdParam,
  } = state;

  const [solutionName, setSolutionName] = useState('');
  const [selectedTankName, setSelectedTankName] = useState<string>('');
  const [tanks, setTanks] = useState<TanksInterface[]>([]);
  const [solventQuantity, setSolventQuantity] = useState<number>();
  const [chemicalQuantity, setChemicalQuantity] = useState<number>();
  const [isFormComplete, setIsFormComplete] = useState(false);
  const { role } = useAuth();
  //@ts-ignore
  const [unit, setUnit] = useState<string>('Kg');

  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [dateTime, setDateTime] = useState<Dayjs>(dayjs());

  //@ts-ignore
  const [solventUnit, setSolventUnit] = useState<string>('l');
  const [loadingTankList, setLoadingTankList] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTanks();
  }, []);

  const fetchTanks = () => {
    setLoadingTankList(true);
    getTanks(plantId!).then(data => {
      console.log('tanklist : ', data);
      setTanks(data);
      setLoadingTankList(false);
    });
  };
  const handleChange = (target: string, e: any) => {
    if (target === 'solution') {
      setSolutionName(e.target.value);
    }
    if (target === 'tank') {
      setSelectedTankName(e.target.value);
    }
    if (target === 'solventQuant') {
      setSolventQuantity(e.target.value);
    }
    if (target === 'chemicalQuant') {
      setChemicalQuantity(e.target.value);
    }
    if (target === 'unit') {
      setUnit(e.target.value);
    }
    const isComplete =
      solutionName !== '' &&
      // selectedTankName !== "" &&  // uncomment this line to make selectedTankName a required field
      solventQuantity !== 0 &&
      chemicalQuantity !== 0;

    console.log(isComplete);

    setIsFormComplete(isComplete);
  };

  const handleClick = async () => {
    if (!isFormComplete) {
      toast.error('Please fill in all fields.');
      return;
    }
    if (chemicalQuantity! <= 0 || solventQuantity! <= 0) {
      toast.error('Quantity cannot be a non-positive value ');
      return;
    }
    if (chemicalQuantity! > availableQuantity) {
      toast.error("Chemical Quantity can't be greater than available Quantity");
      return;
    }
    let calculatedAt: moment.Moment | Dayjs;

    role === 'Operator Group' ? (calculatedAt = moment()) : (calculatedAt = dateTime);

    const selectedTank = tanks?.find(tank => tank.name === selectedTankName);
    const tempForm = {
      storeId,
      plantId: plantId,
      clientId: clientId,
      solutionName: solutionName,
      chemical: {
        chemicalId: chemicalId,
        chemicalQuantity: chemicalQuantity,
        chemicalUnit: chemicalUnit,
      },
      solvent: {
        solventName: 'Water', // @ not generalised
        solventQuantity: solventQuantity,
        solventUnit: solventUnit, /// @ solvent unit is also the solution unit
      },
      tank: selectedTank,
      remarkId,
      createdOn: calculatedAt,
    };

    postSolution(tempForm);
  };

  const postSolution = (tempForm: any) => {
    setIsFormSubmitting(true);
    createSolution(tempForm)
      .then(data => {
        console.log('great success');
        toast('Submitted successfully!');
        setIsFormSubmitting(false);
        setTimeout(() => {
          if (plantIdParam) navigate(`/StoreMaintenance?plantId=${plantIdParam}`);
          else navigate(`/StoreMaintenance`);
        }, 1000);
      })
      .catch(error => {
        console.error(error);
        setIsFormSubmitting(false);
        toast.error('Failed to submit the form. Please try again.');
      });
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent>
        <Box m={'20px 0 16px'}>
          <Typography variant="h1">Create Solution</Typography>
          <InfoBox currentStatus={'success'} m={1}>
            {
              'If the chemical unit is not correct ask your csm to change the chemical configuration!'
            }
          </InfoBox>
          <Box mt={'20px'} border={'1px solid'} borderRadius={2}>
            <Box margin={'10px 0 10px 10px'} p={'10px'}>
              <Typography variant={'body1'}>Chemical : {chemicalName}</Typography>
              <Typography variant={'body1'}>
                Available Quantity : {availableQuantity.toFixed(2)}
              </Typography>

              {role === 'CSM' && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <MobileDateTimePicker
                    open={openDateTimePicker}
                    onClose={() => setOpenDateTimePicker(false)}
                    value={dateTime}
                    disableFuture={true}
                    onChange={dateTime => {
                      // console.log("dateTime", dateTime);
                      setDateTime(dateTime!);
                    }}
                    slots={{
                      // textField: CustomDateTimeField({
                      //   openDateTimePicker,
                      //   setOpenDateTimePicker,
                      // }),
                      textField: () => (
                        <TextField
                          fullWidth
                          // value={getDisplayDateTimeValue(dateTime)}
                          InputProps={{
                            readOnly: true,

                            startAdornment: (
                              <InputAdornment
                                sx={{
                                  display: 'flex',
                                  width: '100%',
                                  paddingLeft: '10%',
                                }}
                                position="start"
                              >
                                <Stack
                                  direction="row"
                                  width="100%"
                                  justifyContent="center"
                                  alignItems="center"
                                  columnGap={2}
                                >
                                  <DateRangeIcon fontSize="small" sx={{ color: '#3EA4A8' }} />
                                  <Typography variant="body1">
                                    {dayjs(dateTime).format('Do MMM YYYY')}
                                  </Typography>
                                  {/* <Divider /> */}
                                  <Typography variant="h4">|</Typography>

                                  <WatchLaterIcon fontSize="small" sx={{ color: '#3EA4A8' }} />
                                  <Typography variant="body1">
                                    {dayjs(dateTime).format('hh:mm A')}
                                  </Typography>
                                </Stack>
                              </InputAdornment>
                            ),
                          }}
                          onClick={() => setOpenDateTimePicker(true)}
                        />
                      ),
                    }}
                  />
                </LocalizationProvider>
              )}
              <Box mt={3}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select a Tank</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-required"
                    label="Select a Tank"
                    value={selectedTankName}
                    onChange={e => handleChange('tank', e)}
                  >
                    {/* the following is loading state for tanklist */}
                    {loadingTankList && (
                      <Box
                        sx={{
                          width: '100%',
                          display: 'grid',
                          placeItems: 'center',
                        }}
                      >
                        <CircularProgress size="small" />
                      </Box>
                    )}

                    {/* the following is options for tanklist */}
                    {tanks &&
                      tanks.length > 0 &&
                      tanks.map(tank => {
                        return (
                          <MenuItem key={tank.id} value={tank.name}>
                            <ListItemIcon>
                              <CheckCircleIcon
                                style={
                                  tank.dosingMechanism === 'automatic'
                                    ? { color: dpTheme.palette.success.dark }
                                    : { color: dpTheme.palette.error.dark }
                                }
                              />
                            </ListItemIcon>
                            {tank.name}
                          </MenuItem>
                        );
                      })}
                    {/* the following is if no dosing tanks are configured in dosing config*/}
                    {(!tanks || tanks?.length == 0) && (
                      <InfoBox currentStatus="failure">
                        If You Don't See any tank List, Means Your Csm Haven't configured
                        DosingConfig
                      </InfoBox>
                    )}
                  </Select>
                </FormControl>
              </Box>
              <Box mt={'20px'}>
                <TextField
                  fullWidth
                  required
                  label={'Name of Solution'}
                  value={solutionName}
                  onChange={e => {
                    handleChange('solution', e);
                  }}
                />
              </Box>

              <Box
                mt={3}
                mb={3}
                sx={{
                  display: 'flex',
                  flexFlow: 'row nowrap',
                  columnGap: '10px',
                }}
              >
                <TextField
                  fullWidth
                  required
                  type="number"
                  label={'Chemical Quantity'}
                  value={chemicalQuantity}
                  onChange={e => handleChange('chemicalQuant', e)}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position="start">
                        {UNITS_INFO[chemicalUnit]?.fullForm}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'row nowrap',
                  columnGap: '10px',
                }}
              >
                <TextField
                  fullWidth
                  required
                  type="number"
                  label={'Quantity of Water'}
                  value={solventQuantity}
                  onChange={e => handleChange('solventQuant', e)}
                  InputProps={{
                    inputProps: { min: 0 },
                    endAdornment: (
                      <InputAdornment position="start">
                        {UNITS_INFO[solventUnit]?.fullForm}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <PrimaryRoundedButton
              style={{ margin: '10px' }}
              onClick={handleClick}
              disabled={!isFormComplete || isFormSubmitting}
            >
              {isFormSubmitting ? 'Submitting...' : 'Submit'}
            </PrimaryRoundedButton>
          </Box>
        </Box>
        <ToastContainer />
      </LayoutComponent>
    </ThemeProvider>
  );
}
