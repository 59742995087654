import { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  IconButton,
  Box,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import ReplayIcon from "@mui/icons-material/Replay";
import { toast } from "react-toastify";
import useUserStore from "../../store/UserStore";

const styles = {
  container: {
    minWidth: "320px",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  heading: {
    fontSize: "16px",
    fontWeight: 500,
  },
  resendOTP: {
    color: "black",
    fontSize: "13px",
    textTransform: "none",
  },
  closeIcon: {
    fontSize: "16px",
    color: "black",
  },
};

interface OTPInputProps {
  showOtpPopup: boolean;
  onClose: () => void;
  onResendOTP: () => void;
  onSubmit: (otp: number) => Promise<void>;
  timerDurationSeconds: number;
}

function OTPPopup({
  showOtpPopup,
  onClose,
  onResendOTP,
  onSubmit,
  timerDurationSeconds,
}: OTPInputProps) {
  const [otp, setOTP] = useState<number | null>(null);
  const [timer, setTimer] = useState<number>(timerDurationSeconds);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [otpError, setOtpError] = useState<string>("");
  const userName = useUserStore().userName;

  // Countdown timer effect
  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  // Handle OTP input
  const handleOTPChange = (event: any) => {
    setOTP(event.target.value);
  };

  // Handle form submission
  const handleSubmit = async () => {
    setSubmitting(true);
    if (otp != null && otp.toString()?.length === 6) {
      setOtpError("");
      await onSubmit(otp);
      setSubmitting(false);
    } else {
      setOtpError("Please Enter a valid 6 digit otp");
      setSubmitting(false);
    }
  };

  const displayTimer = (timer: number) => {
    const displayTimer =
      timer >= 60
        ? `${Math.floor(timer / 60)}:${(timer % 60)
            .toString()
            .padStart(2, "0")}`
        : `00:${timer.toString().padStart(2, "0")}`;
    return displayTimer;
  };

  const resendOTP = async () => {
    console.log("username : ", userName);

    try {
      await onResendOTP();
      setTimer(timerDurationSeconds);
    } catch (error) {
      toast.error("Some error in resend otp");
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={showOtpPopup}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <Stack
        p={3}
        pb={4}
        direction="column"
        alignItems="center"
        gap={3}
        sx={styles.container}
      >
        <Box sx={styles.header}>
          <Box sx={styles.heading}>Two Factor Authentication</Box>
          <IconButton onClick={onClose}>
            <CloseIcon sx={styles.closeIcon} />
          </IconButton>
        </Box>
        <Box width="90%">
          <TextField
            label="Enter OTP"
            variant="outlined"
            type="number"
            fullWidth
            value={otp}
            onChange={handleOTPChange}
            margin="normal"
            inputProps={{ style: { fontSize: 14 } }}
            InputLabelProps={{ style: { fontSize: 14 } }}
            error={otpError.length > 0 ? true : false}
            helperText={otpError.length > 0 ? otpError : null}
          />
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack direction="row" alignItems="center">
              <IconButton disabled={timer > 0} onClick={resendOTP}>
                <ReplayIcon fontSize="small" />
              </IconButton>
              <Box sx={styles.resendOTP}>Resend OTP</Box>
            </Stack>

            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ letterSpacing: "1px" }}
            >
              {displayTimer(timer)}
            </Typography>
          </Stack>
        </Box>

        <Box sx={{ width: "90%" }}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={submitting}
            onClick={handleSubmit}
          >
            {!submitting ? "Submit" : "Submitting ..."}
          </Button>
        </Box>
      </Stack>
    </Dialog>
  );
}

export default OTPPopup;
