import axiosInstance from '../constants/axiosInstance';

const getOpenAlerts = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(`/alerts/getOpenAlerts?plantId=${plantId}`);
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getClosedAlerts = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(`/alerts/getClosedAlerts?plantId=${plantId}`);
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getPlantsForUser = async (userId: string) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/getPlantsForUser`);
    console.log('data', data);
    return data.data;
  } catch (err) {
    console.log('error | services | getPlantsForUserId ', err);
  }
};

const getOpenAlertsForClient = async (clientId: string, selectedPlants: any[]) => {
  try {
    const { data } = await axiosInstance().post(
      `/newAlerts/getOpenAlertsForClient?clientId=${clientId}`,
      { selectedPlants }
    );
    return data.data;
  } catch (error) {
    console.log('error | services | getOpenAlertsForClient ', error);
  }
};

const getClosedAlertsForClient = async (clientId: string, selectedPlants: any[]) => {
  try {
    const { data } = await axiosInstance().post(
      `/newAlerts/getClosedAlertsForClient?clientId=${clientId}`,
      { selectedPlants }
    );
    console.log('data.data', data);
    return data.data;
  } catch (error) {
    console.log('error | services | getClosedAlertsForClient ', error);
  }
};

const getAllTicketIdsOfAlert = async (alertId: string, plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/alerts/getAllTicketIdsOfAlert?alertId=${alertId}&plantId=${plantId}`
    );
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getTicketData = async (ticketId: string, plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/alerts/getTicketData?ticketId=${ticketId}&plantId=${plantId}`
    );
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const addCommentToTicket = async (ticketId: string, plantId: string, comment: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/alerts/addCommentToTicket?ticketId=${ticketId}&plantId=${plantId}`,
      {
        message: comment,
      }
    );
    return data.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const markTicketResolved = async (alertId: string, closedBy: string) => {
  try {
    const { data } = await axiosInstance().post(`/alerts/markTicketResolved`, {
      alertId: alertId,
      closedBy: closedBy,
    });
    return data.msg;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const openTheAlert = async (alertId: string) => {
  try {
    const { data } = await axiosInstance().post(`/alerts/openTheAlert?alertId=${alertId}`, {});
    return data.msg;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export {
  getPlantsForUser,
  getOpenAlerts,
  getOpenAlertsForClient,
  getClosedAlerts,
  getClosedAlertsForClient,
  getAllTicketIdsOfAlert,
  getTicketData,
  addCommentToTicket,
  markTicketResolved,
  openTheAlert,
};
