import axiosInstance from "../constants/axiosInstance";

const updateCheckIn = async (
  checkInUser: any,
  checkInStatus: boolean,
  location: any,
  deviceId: any
) => {
  try {
    const { data } = await axiosInstance().post("/users/checkinUpdate", {
      checkIn: checkInUser,
      checkInStatus,
      location,
      deviceId,
    });
    return data;
  } catch (err) {
    console.error("ERROR | updateCheckIn | ", err);
  }
};

const updateLoginPhoto = async (checkInUser: any, newFormData: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/users/uploadLoginPhoto?id=${checkInUser.id}`,
      newFormData
    );
    return data;
  } catch (err) {
    console.error("ERROR | updateLoginPhoto | ", err);
  }
};

const getCheckInUser = async () => {
  try {
    const { data } = await axiosInstance().get("/users/checkInUser");
    return data;
  } catch (err) {
    console.error("ERROR | getCheckInUser | ", err);
  }
};

export { updateCheckIn, updateLoginPhoto, getCheckInUser };
