import jwtDecode from "jwt-decode";

function addCachedLoginEntry(userId: string, userToken: string) {
  // Retrieve the current list of login entries from local storage
  const storedLoginEntries = localStorage.getItem("cachedLoginEntries") || "[]";
  const loginEntries: { id: string; token: string }[] =
    JSON.parse(storedLoginEntries);

  // Check if an entry with the same id already exists
  const existingEntryIndex = loginEntries.findIndex(
    (entry) => entry.id === userId
  );

  if (existingEntryIndex !== -1) {
    // If an entry with the same id exists, replace its token
    loginEntries[existingEntryIndex].token = userToken;
  } else {
    // If no entry with the same id exists, add the new entry
    loginEntries.push({ id: userId, token: userToken });
  }

  // Save the updated entries back to local storage
  localStorage.setItem("cachedLoginEntries", JSON.stringify(loginEntries));
}

function removeCachedLoginEntry(userId: string) {
  console.log("inside remove login entry");
  // Retrieve the current list of login entries from local storage
  const storedLoginEntries = localStorage.getItem("cachedLoginEntries") || "[]";
  const loginEntries: { id: string; token: string }[] =
    JSON.parse(storedLoginEntries);

  // Find the index of the entry to remove by id
  const entryIndex = loginEntries.findIndex((entry) => entry.id === userId);

  // If the entry was found, remove it
  if (entryIndex !== -1) {
    loginEntries.splice(entryIndex, 1);

    // Save the updated list back to local storage
    localStorage.setItem("cachedLoginEntries", JSON.stringify(loginEntries));
  }
}

function getCachedLoginToken(userId: string): string | null {
  const storedLoginEntries = localStorage.getItem("cachedLoginEntries") || "[]";
  const loginEntries: { id: string; token: string }[] =
    JSON.parse(storedLoginEntries);

  // Find the entry by id
  const foundEntry = loginEntries.find((entry) => entry.id === userId);

  return foundEntry ? foundEntry.token : null;
}

function decodeTokenBody(userToken: string): any | null {
  try {
    // Decode the token without the need for a secret key
    const decoded = jwtDecode(userToken);

    // Return the decoded body (claims)
    return decoded;
  } catch (error) {
    // Token decoding failed, return null or handle the error as needed
    console.error("Error decoding token:", error);
    return null;
  }
}

function getAllCachedLoginEntries() {
  const storedEntries = localStorage.getItem("cachedLoginEntries") || "[]";
  return JSON.parse(storedEntries);
}

export {
  addCachedLoginEntry,
  getCachedLoginToken,
  removeCachedLoginEntry,
  decodeTokenBody,
  getAllCachedLoginEntries,
};
