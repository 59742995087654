import axiosInstance from "../constants/axiosInstance";
import { DIForm } from "../Interfaces/DIForm";

const getDIConfigForm = async (id: string, formType: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/data-input/generateDIConfigForm?id=${id}&&formType=${formType}`
    );
    return data.DI;
  } catch (err) {
    console.error("ERROR | getDIConfigForm | ", err);
  }
};

const saveDIForm = async (id: string, form: DIForm) => {
  try {
    const { data } = await axiosInstance().post(`/data-input/saveDIForm`, {
      plantId: id,
      data: form,
    });
    return data.data;
  } catch (err) {
    console.error("ERROR | saveDIForm");
  }
};

export { getDIConfigForm, saveDIForm };
