import { useEffect, useState } from 'react';
import { Button, Box, TextField, Stack, Checkbox } from '@mui/material';
import './styles.css';
import Autocomplete from '@mui/lab/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import axiosInstance from '../../constants/axiosInstance';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import { getPlantAbbr, getSavedClientAbbr } from '../../services/plant';
import AbbrSelector from '../Common/AbbrSelector';

export default function AddPlantPopup(props: any) {
  const { handleClose, plantId, refreshParentPage, edit } = props;
  const [newPlantData, setNewPlantData] = useState<any>({
    packages: [],
    renewOn: moment().add(1, 'year').format('YYYY-MM-DD'),
    csm: {},
    serviceProvider: { name: '' },
    client: {},
    abbr: '',
  });
  const [csm, setcsm] = useState([]);
  const [client, setClient] = useState([]);
  const [clientAbbr, setClientAbbr] = useState<string>('');
  const [initialAbbrSetup, setInitialAbbrSetup] = useState<boolean>(false);
  const [serviceProvider, setServiceProvider] = useState([]);
  const [abbr, setAbbr] = useState<string>('');
  const [abbrList, setAbbrList] = useState<string[]>([]);
  const packages = [
    'Data Input',
    'Task Management & Maintenance Management',
    'Dashboard, Reports & Alerts',
  ];
  const [refreshs, setRefreshs] = useState(false);
  const plantType = ['STP', 'WTP'];

  useEffect(() => {
    fetchCSM();
    fetchClients();
    fetchServiceProviders();
    if (edit) {
      fetchPlantInfo();
    } else {
      setInitialAbbrSetup(true);
    }
  }, []);

  useEffect(() => {
    if (initialAbbrSetup) {
      setNewPlantData({ ...newPlantData, abbr: abbr });
    }
  }, [abbr]);

  useEffect(() => {
    if (newPlantData.plantName?.length >= 3) {
      createAbbrForPlant(newPlantData.plantName);
    } else {
      setAbbrList([]);
    }
  }, [newPlantData.plantName]);

  useEffect(() => {
    if (newPlantData.client?.id) {
      fetchClientAbbr(newPlantData.client.id);
    }
  }, [newPlantData.client?.id]);

  const fetchCSM = () => {
    axiosInstance()
      .get('/users/userbyRole?role=CSM')
      .then(({ data }) => {
        setcsm(data.users);
      })
      .catch();
  };

  const fetchClients = () => {
    axiosInstance()
      .get('/users/userbyRole?role=Client')
      .then(({ data }) => {
        setClient(data.users);
      })
      .catch();
  };

  const fetchServiceProviders = () => {
    axiosInstance()
      .get('/users/userbyRole?role=Service Provider')
      .then(({ data }) => {
        setServiceProvider(data.users);
      })
      .catch();
  };

  const fetchPlantInfo = () => {
    axiosInstance()
      .get(`/plantManagement/plant?id=${plantId}`)
      .then(({ data }) => {
        setNewPlantData(data.body);
        console.log(data.body);
        if (data.body.abbr === undefined || data.body.abbr === null || data.body.abbr === '') {
          setInitialAbbrSetup(true);
          fetchClientAbbr(data.body.client.id);
          createAbbrForPlant(data.body.plantName);
        } else {
          console.log('here');
          setInitialAbbrSetup(false);
        }
      })
      .catch();
  };

  const createAbbrForPlant = (name: string) => {
    getPlantAbbr(name).then(data => {
      console.log(data);
      setAbbrList(data);
    });
  };
  const fetchClientAbbr = (id: string) => {
    getSavedClientAbbr(id).then(data => {
      if (data === null || data === undefined || data === '') {
        setClientAbbr('');
      } else {
        setClientAbbr(data);
      }
    });
  };
  const handleChanges = (name: any, value: any) => {
    let data = newPlantData;
    data[name] = value;
    setNewPlantData(data);
    setRefreshs(!refreshs);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (
      newPlantData.client === null ||
      newPlantData.client === undefined ||
      newPlantData.client.name === null ||
      newPlantData.client.name === undefined ||
      newPlantData.client.name === '' ||
      newPlantData.client.name === ' '
    ) {
      toast.error('Please select a client');
      return;
    }
    if (newPlantData.hasOwnProperty('abbr')) {
      if (
        newPlantData.abbr === null ||
        newPlantData.abbr === undefined ||
        newPlantData.abbr === '' ||
        newPlantData.abbr === ' '
      ) {
        toast.error('Please select an abbreviation');
        return;
      } else {
        if (newPlantData.abbr.length === 0) {
          toast.error('Please select an abbreviation');
          return;
        } else if (newPlantData.abbr.length < 3) {
          toast.error('Abbreviation must be atleast 3 characters');
          return;
        } else if (newPlantData.abbr.length > 6) {
          toast.error('Abbreviation must be atmost 6 characters');
          return;
        }
      }
    } else {
      toast.error('Please select an abbreviation');
      return;
    }

    if (edit) {
      axiosInstance()
        .post('/plantManagement/updatePlant', newPlantData)
        .then(({ data }) => {
          if (data.data.type === 'error') {
            toast.error(data.data.message);
          } else if (data.data.type === 'success') {
            toast.success(data.data.message);
            refreshParentPage();
          } else {
            toast(data.data.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      axiosInstance()
        .post('/plantManagement/createNewPlant', newPlantData)
        .then(({ data }) => {
          if (data.data.type === 'error') {
            toast.error(data.data.message);
          } else if (data.data.type === 'success') {
            toast.success(data.data.message);
            refreshParentPage();
          } else {
            toast(data.data.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const addEmbeddedLinks = () => {
    let temp: any = newPlantData;
    let embeddedLink = {
      name: 'name',
      link: 'link',
      followPageTimeFrame: false,
    };
    if (!temp['embeddedLinks']) temp['embeddedLinks'] = [];
    temp['embeddedLinks'].push(embeddedLink);
    setNewPlantData(temp);
    setRefreshs(!refreshs);
  };

  const handleEmbeddedLinkChange = (index: number, key: string, value: any) => {
    let temp: any = newPlantData;
    temp['embeddedLinks'][index][key] = value;
    setNewPlantData(temp);
    setRefreshs(!refreshs);
  };

  const deleteEmbeddedLink = (index: number) => {
    let temp: any = newPlantData;
    temp['embeddedLinks'].splice(index, 1);
    setNewPlantData(temp);
    setRefreshs(!refreshs);
  };

  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={handleClose}>
          x
        </span>
        <form onSubmit={handleSubmit}>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newPlantData.plantName}
              label="Plant Name"
              onChange={e => {
                handleChanges('plantName', e.target.value);
              }}
            />
          </Box>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newPlantData.plantNickName}
              label="Plant Nick-Name"
              onChange={e => {
                handleChanges('plantNickName', e.target.value);
              }}
            />
          </Box>
          {initialAbbrSetup ? (
            <Box m={1} p={1}>
              <AbbrSelector options={abbrList} setAbbr={setAbbr} />
            </Box>
          ) : (
            <Box m={1} p={1}>
              <TextField fullWidth disabled value={newPlantData?.abbr} label="Abbreviation" />
            </Box>
          )}
          <Box m={1} p={1}>
            <TextField fullWidth disabled value={clientAbbr} label="Client Name Abbreviation" />
          </Box>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newPlantData.sensorfreq}
              label="Sensor Data Frequency (min)"
              type="number"
              onChange={e => {
                handleChanges('sensorfreq', e.target.value);
              }}
            />
          </Box>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newPlantData.tur}
              label="Turbidity Multiplier"
              type="number"
              onChange={e => {
                handleChanges('tur', e.target.value);
              }}
            />
          </Box>
          <Box m={1} p={1}>
            <Autocomplete
              id="tags-standard"
              options={csm}
              getOptionLabel={option => option.name || ''}
              value={newPlantData.csm}
              onChange={(event, newValue) => {
                handleChanges('csm', newValue);
              }}
              renderInput={params => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Customer Success Manager"
                />
              )}
            />
          </Box>

          <Box m={1} p={1}>
            <Autocomplete
              id="tags-standard"
              options={client}
              getOptionLabel={option => option.name}
              value={newPlantData.client}
              // disabled={edit}
              onChange={(event, newValue) => {
                handleChanges('client', newValue);
              }}
              renderInput={params => (
                <TextField
                  required
                  {...params}
                  // disabled={edit}
                  variant="standard"
                  label="Client"
                />
              )}
            />
          </Box>
          <Box m={1} p={1}>
            <Autocomplete
              id="tags-standard"
              options={serviceProvider}
              getOptionLabel={option => option.name || ''}
              value={newPlantData?.serviceProvider || null}
              onChange={(event, newValue) => {
                handleChanges('serviceProvider', newValue);
              }}
              renderInput={params => (
                <TextField {...params} variant="standard" label="Service Provider" />
              )}
            />
          </Box>
          <Box m={1} p={1}>
            <Autocomplete
              multiple
              id="tags-standard"
              options={packages}
              value={newPlantData.packages}
              onChange={(event, newValue) => {
                handleChanges('packages', newValue);
              }}
              renderInput={params => <TextField {...params} variant="standard" label="Packages" />}
            />
          </Box>
          <Box m={1} p={1}>
            <TextField
              required
              id="pub"
              label="Publish Topic"
              variant="standard"
              sx={{ marginBottom: 2, width: '100%' }}
              value={newPlantData.pubTopic ?? ''}
              onChange={event => {
                handleChanges('pubTopic', event.target.value);
              }}
            />
            <TextField
              required
              id="sub"
              label="Subscription Topic"
              variant="standard"
              sx={{ width: '100%' }}
              value={newPlantData.subTopic ?? ''}
              onChange={event => {
                handleChanges('subTopic', event.target.value);
              }}
            />
          </Box>

          <Box m={1} p={1}>
            <Autocomplete
              id="tags-standard"
              options={plantType}
              value={newPlantData.plantType || null}
              onChange={(event, newValue) => {
                handleChanges('plantType', newValue);
              }}
              renderInput={params => (
                <TextField {...params} required variant="standard" label="Plant Type" />
              )}
            />
          </Box>
          {/* <Box m={1} p={1}>
            <TextField
              required
              fullWidth
              id="schematic"
              label="Power Bi Schematic"
              defaultValue={newPlantData.schematic}
              onChange={(event) => {
                handleChanges("schematic", event.target.value);
              }}
            />
          </Box> */}
          <Box
            m={1}
            p={1}
            borderRadius={5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              background: '#eee',
              fontSize: '13px',
            }}
          >
            <p style={{ fontSize: '13px' }}>Embedded Link</p>
            {newPlantData.embeddedLinks?.map((link: any, index: number) => {
              return (
                <Stack
                  m={2}
                  p={2}
                  direction="column"
                  border={1}
                  borderRadius={2}
                  gap={2}
                  sx={{ background: 'white' }}
                >
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id={link.name}
                    label="name"
                    defaultValue={link.name}
                    onChange={event => {
                      handleEmbeddedLinkChange(index, 'name', event.target.value);
                    }}
                  />
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id={link.schematic}
                    label="link"
                    defaultValue={link.link}
                    onChange={event => {
                      handleEmbeddedLinkChange(index, 'link', event.target.value);
                    }}
                  />
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      checked={link.followPageTimeFrame}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleEmbeddedLinkChange(
                          index,
                          'followPageTimeFrame',
                          event.target.checked
                        );
                      }}
                    />
                    <Box>Follow Page TimeFrame</Box>
                    <DeleteIcon
                      fontSize="medium"
                      onClick={() => {
                        deleteEmbeddedLink(index);
                      }}
                      sx={{ marginLeft: 'auto' }}
                    />
                  </Stack>
                </Stack>
              );
            })}
            <Box py={1} style={{ marginLeft: 'auto' }}>
              <Button
                variant="contained"
                style={{ background: 'black', color: '#ddd', fontSize: '13px' }}
                onClick={() => {
                  addEmbeddedLinks();
                }}
              >
                Add More
              </Button>
            </Box>
          </Box>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              id="date"
              label="Renew Date"
              type="date"
              defaultValue={newPlantData.renewOn}
              onChange={event => {
                handleChanges('renewOn', event.target.value);
              }}
            />
          </Box>

          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              id="lat"
              label="Latitude"
              value={newPlantData.lat}
              onChange={event => {
                handleChanges('lat', event.target.value);
              }}
            />
          </Box>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              id="lon"
              label="Longitude"
              value={newPlantData.long}
              onChange={event => {
                handleChanges('long', event.target.value);
              }}
            />
          </Box>
          {edit && (
            <Stack direction="row" alignItems="center">
              <Checkbox
                checked={newPlantData.migrateAllNONDISensors}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  handleChanges('migrateAllNONDISensors', event.target.checked);
                }}
              />
              <Box>MigrateAll NON DI Sensors</Box>
            </Stack>
          )}
          <Box m={1} p={1}>
            <Button variant="contained" type="submit">
              {edit ? <>Update</> : <>Create Plant</>}
            </Button>
          </Box>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
