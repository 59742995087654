import {
  TextField,
  Box,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from '@mui/material';
import { MIN_ALLOWED_SENSOR_VALUE, MAX_ALLOWED_SENSOR_VALUE } from '../../../constants/constants';
import { useRef } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const SensorConfigForOtherSensors = (props: any) => {
  const { index, sensor, updateOtherSensorTag, sensorIndex } = props;
  const maxValueRef = useRef(null);
  const minValueRef = useRef(null);
  return (
    <>
      <Accordion sx={{ marginBottom: '10px' }}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Stack direction="row" gap={1}>
            {(sensor['maxValue'] || sensor['minValue']) && (
              <Box>
                <FilterAltIcon fontSize="small" sx={{ color: 'green' }} />
              </Box>
            )}
            {sensor['sensorNickName'] && sensor['sensorNickName']?.length > 1 ? (
              <Tooltip title={sensor['sensorTag']}>
                <Box sx={{ color: 'green' }}>{sensor['sensorNickName']}</Box>
              </Tooltip>
            ) : (
              <Box>{sensor['sensorTag']}</Box>
            )}
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box mb={2}>
            <TextField
              type="String"
              value={sensor['sensorTag']}
              variant="outlined"
              label={sensor['sensorName']}
              onChange={e => updateOtherSensorTag(index, e.target.value, 'sensorTag', sensorIndex)}
              //   style={PlantConfigStyle.equipmentTextField}
            />
          </Box>
          <Box mb={2}>
            <TextField
              type="String"
              value={sensor['sensorNickName']}
              variant="outlined"
              label={sensor['sensorName'] + ' Nickname'}
              onChange={e =>
                updateOtherSensorTag(index, e.target.value, 'sensorNickName', sensorIndex)
              }
              //   style={PlantConfigStyle.equipmentTextField}
            />
          </Box>
          <Box mb={2}>
            <Stack direction="row" gap={1}>
              <TextField
                fullWidth
                type="number"
                inputRef={minValueRef}
                defaultValue={sensor['minValue'] ?? MIN_ALLOWED_SENSOR_VALUE}
                variant="outlined"
                label={sensor['sensorName'] + ' Min Value'}
                onBlur={(e: any) =>
                  updateOtherSensorTag(index, e.target.value, 'minValue', sensorIndex, minValueRef)
                }
              ></TextField>
              <TextField
                fullWidth
                type="number"
                inputRef={maxValueRef}
                defaultValue={sensor['maxValue'] ?? MAX_ALLOWED_SENSOR_VALUE}
                variant="outlined"
                label={sensor['sensorName'] + ' Max Value'}
                onBlur={(e: any) =>
                  updateOtherSensorTag(index, e.target.value, 'maxValue', sensorIndex, maxValueRef)
                }
              ></TextField>
            </Stack>
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default SensorConfigForOtherSensors;
