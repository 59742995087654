import classes from '../../styles/PlantVisualisationStyle.module.css';

const EquipmentSensors = ({ sensor, LTSensor }: any) => {
    const { sensorId, sensorName, dataType, value, unit } = sensor;
    const AnalogSensorValue = `${value}${unit}` || 0;

    return (
        <div key={sensorId} className={classes.Sensor}>
            {
                dataType === "boolean" 
                ? 
                    <div className={value ? classes.DigitalSensorOnStatus: classes.DigitalSensorOffStatus }></div>
                :
                    <div className={classes.AnalogSensorValue}>{AnalogSensorValue}</div>
            }
            <div className={classes.SensorName}>
                <span>{sensorName}</span>
            </div>
        </div>
    );
}

export default EquipmentSensors;
