import { useEffect, useState } from "react";
import { ThemeProvider, Stack, Divider } from "@mui/material";
// import loginStyles from "../styles/loginV2.module.css";
import "react-toastify/dist/ReactToastify.css";
//@ts-ignore
import ecoinnovision_logo from "../constants/Images/digitalpaanilogo.png";
// import DiwaliDesktp from "../constants/Images/DiwaliDesktop.jpg";
// import DiwaliMobile from "../constants/Images/DiwaliMobile.jpg";
// import DiwaliTablet from "../constants/Images/DiwaliTablet.jpg";
import logo from "../constants/Images/digitalpaanilogo.png";
import dpTheme from "../styles/theme/dpTheme";
import EmailPasswordLogin from "../components/loginComponents/EmailPasswordLogin";
import PersistentUserList from "../components/loginComponents/PersistentUserList";
import { getAllCachedLoginEntries } from "../utilities/cachedLoginEntries.utils";

const styles = {
  heading: {
    fontSize: "16px",
    color: "#52687C",
  },
};

export default function LoginV2() {
  // const classes = useStyles();
  // const classes = loginStyles;

  //@ts-ignore
  const [refresh, setRefresh] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imgSrc, setImageSrc] = useState<any>();
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleWidthChange = () => {
    let size = "desktop";
    if (windowWidth <= 430) {
      size = "mobile";
    } else if (windowWidth <= 1024) {
      size = "tablet";
    } else {
      size = "desktop";
    }
    handleAvatarSize(size);
  };
  useEffect(() => {
    handleWidthChange();
  }, [windowWidth]);

  const handleAvatarSize = (size: string) => {
    if (size === "mobile") {
      setImageSrc(logo);
    } else if (size === "tablet") {
      setImageSrc(logo);
    } else {
      setImageSrc(logo);
    }
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <Stack direction="column" alignItems="center" marginTop={"10%"} gap={1}>
        <img src={imgSrc} width={"209px"} alt="ecoinnovision_logo" />
        <div style={styles.heading}>Sign In</div>
      </Stack>
      <Stack
        direction="column"
        alignItems="center"
        gap={5}
        sx={{ marginTop: "50px" }}
      >
        {/* <Stack direction="column" alignItems="center" gap={1}>
          <img
            src={ecoinnovision_logo}
            alt="ecoinnovision_logo"
            width="209px"
          />
          <div style={styles.heading}>Sign In</div>
        </Stack> */}

        <Stack
          direction={{ xs: "column-reverse", sm: "row" }}
          alignItems="center"
          justifyContent="center"
          gap={{ xs: 3, sm: 5 }}
          divider={<Divider orientation="vertical" flexItem />}
          sx={{ height: "100%" }}
          // border={1}
        >
          {getAllCachedLoginEntries()?.length > 0 ? (
            <PersistentUserList refreshMainLoginPage={setRefresh} />
          ) : null}
          <EmailPasswordLogin />
        </Stack>
      </Stack>
    </ThemeProvider>
  );
}
