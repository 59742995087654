import { Box, Button, Dialog, Input, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { AlertStyles, DialogPositiveButtonStyle, DialogSuccessButtonStyle } from './AlertsStyles';
import { useState } from 'react';

function AddAlertDialog({
  closeMethod,
  showAddAlertDialogue,
  addAlert,
  masterAlertList,
  plantId,
  alertType,
}: any) {
  let emptyAlert: any = {
    toUser: 'Operator Group',
    observationConditions: [],
    resolutionConditions: [],
    type: 'Mixed',
    dueTimeFromPosting: '02:00:00',
    autoCalling: false,
    escalations: {
      'Level 1': {
        time: '10:00:00',
        group: 'Escalation Level 1',
      },
      'Level 2': {
        time: '12:00:00',
        group: 'Escalation Level 2',
      },
      'Level 3': {
        time: '14:00:00',
        group: 'Escalation Level 3',
      },
    },
    heading: {
      eng: 'Enter Heading',
      hindi: 'शीर्षक दर्ज करें',
    },
    description: {
      eng: 'Enter Description',
      hindi: 'विवरण दर्ज करें',
    },
    subTasks: [
      {
        name: 'Enter Subtask',
        namehindi: 'सबटास्क दर्ज करें',
      },
    ],
  };
  const [selectedAlert, setSelectedAlert] = useState<any>(emptyAlert);
  return (
    <Dialog
      fullWidth={true}
      maxWidth={'md'}
      open={showAddAlertDialogue}
      onClose={() => {
        closeMethod();
        // setShowProcessingResult(false);
      }}
    >
      <>
        <DialogContent>
          <Box sx={AlertStyles.ProcessingStyle}>
            <h1>Add New Alert</h1>
          </Box>

          <Select
            id="alertTasksFromMaster"
            style={{ color: 'black' }}
            value={selectedAlert['heading']['eng']}
            onChange={(e: SelectChangeEvent<any>) => {
              setSelectedAlert(e.target.value as any);
            }}
            fullWidth
            input={<Input />}
            renderValue={value => <span style={{ color: 'black' }}>{value}</span>}
          >
            <MenuItem value="" disabled>
              <em>Master Alerts</em>
            </MenuItem>
            {masterAlertList.map((alert: any) => (
              <MenuItem value={alert} key={alert['heading']['eng']}>
                {alert['heading']['eng']}
              </MenuItem>
            ))}
          </Select>
          <Box
            style={{
              marginTop: '20px',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'block',
            }}
          >
            <Button
              style={{ margin: 'auto' }}
              onClick={() => {
                let alertToAdd = emptyAlert;

                let random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
                alertToAdd['id'] = random4DigitNumber;
                if (alertType === 'workspaceAlerts') {
                  alertToAdd['workspaceId'] = plantId;
                } else {
                  alertToAdd['plantId'] = plantId;
                }

                addAlert(emptyAlert);
              }}
            >
              Add Empty Alert
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogPositiveButtonStyle
            onClick={() => {
              let random4DigitNumber = Math.floor(1000 + Math.random() * 9000);
              selectedAlert['id'] = random4DigitNumber;
              if (alertType === 'workspaceAlerts') {
                selectedAlert['workspaceId'] = plantId;
              } else {
                selectedAlert['plantId'] = plantId;
              }
              addAlert(selectedAlert);
            }}
          >
            Add Alert
          </DialogPositiveButtonStyle>

          <DialogSuccessButtonStyle
            onClick={() => {
              closeMethod();
            }}
          >
            Cancel
          </DialogSuccessButtonStyle>
        </DialogActions>
      </>
    </Dialog>
  );
}

export default AddAlertDialog;
