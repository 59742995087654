import axiosInstance from "../constants/axiosInstance";

const fetchRoles = async (id: string) => {
    try {
        const { data } = await axiosInstance().get(`/users/roleforTasks?id=${id}`);
        return data?.workforce;
    } catch (err) {
        console.error("ERROR | fetchRoles | ", err);
    }
  };

const fetchEscalations = async (id: string) => {
    try {
        const { data } = await axiosInstance().get(`/tasks/getEscalationLevels?id=${id}`);
        return data?.body?.escalation;
    } catch (err) {
        console.error("ERROR | fetchEscalations | ", err);
    }
};

const getEquipments = async (id: string) => {
    try {
        const { data } = await axiosInstance().get(`/tasks/getEquipments?id=${id}`);
        return data?.equipments;
    } catch (err) {
        console.error("ERROR | getEquipments | ", err);
    }
};

const getFixedTasks = async (id: string) => {
  try {
    const { data } = await axiosInstance().get(`/tasks/fetchFixedTasks?id=${id}`);
    return data?.body;
  } catch (err) {
    console.error("ERROR | getFixedTasks | ", err);
  }
};

export {
    fetchRoles,
    fetchEscalations,
    getEquipments,
    getFixedTasks
}