import axiosInstance from "../constants/axiosInstance";

const getMasterData = async () => {
  try {
    const { data } = await axiosInstance().get(
      "/tasks/getMaster?master=Fixed Tasks"
    );
    return data?.masterData;
  } catch (err) {
    console.error("ERROR | getMasterData | ", err);
  }
};

const postMasterData = async (masterData: any) => {
  try {
    const { data } = await axiosInstance().post("/tasks/updateMaster", {
      master: "Fixed Tasks",
      masterData,
    });
    return data;
  } catch (err) {
    console.error("ERROR | postMasterData | ", err);
  }
};

export { getMasterData, postMasterData };
