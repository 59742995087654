//@ts-nocheck
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Select, ThemeProvider } from '@mui/material';
// import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { TextSnippetRounded, ImageSearch } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SettingsIcon from '@mui/icons-material/Settings';
import TaskIcon from '@mui/icons-material/Task';
import SensorsIcon from '@mui/icons-material/Sensors';
import LayoutComponent from '../components/LayoutComponent';
import AddPlantPopup from '../components/AddPlantPopup/AddPlantPopup';
import AddPlcPopup from '../components/AddPlcPopUp/AddPlcPopup';
import AddHmiPopup from '../components/AddHmiPopup/AddHmiPopup';
import { fetchPlants, deletePlant } from '../services/plants';
import CallMadeIcon from '@mui/icons-material/CallMade';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PrimaryButton } from '../styles/global/components/dpButtons';
import dpTheme from '../styles/theme/dpTheme';
import useAuth from '../utilities/useAuth';
import DeleteConfirmation from '../globalComponents/dialogs/DeleteConfirmation';
import SelectImageForPlantPopup from '../components/SelectImageForPlantPopup/SelectImageForPlantPopup';
import { ToastContainer, toast } from 'react-toastify';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import MigrationScriptPopup from '../components/PlantConfigPopups/MigrationScriptPopup';

export default function Plant() {
  const navigate = useNavigate();
  const [addPlant, setAddPlant] = useState(false);
  const [plantData, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [plantId, setPlantId] = useState('');
  const [showPlcPopup, setShowPlcPopup] = useState(false);
  const [showHmiPopup, setShowHmiPopup] = useState(false);
  const [showImageSelectPopup, setShowImageSelectPopup] = useState(false);
  const { role } = useAuth();
  const [redirectMenuAnchorEl, setRedirectMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [showMigrationScriptsPopup, setShowMigrationScriptsPopup] = useState(false);

  useEffect(() => {
    setLoading(true);
    setEdit(false);
    setAddPlant(false);
    fetchPlants().then(plantData => {
      setData(plantData);
      setLoading(false);
    });
  }, [refreshPage]);

  const columns: GridColDef[] = [
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.actions.includes('PLC_Configuration_R') && (
              <Button
                onClick={() => {
                  setPlantId(params.id);
                  setShowPlcPopup(true);
                }}
              >
                <TextSnippetRounded />
              </Button>
            )}
            {params.row.actions.includes('PlantsList_Configuration_A') && (
              <>
                <Button
                  onClick={() => {
                    setPlantId(params.id);
                    setEdit(true);
                    setAddPlant(true);
                  }}
                >
                  <EditIcon />
                </Button>
                <Button
                  onClick={() => {
                    setPlantId(params.id);
                    setShowImageSelectPopup(true);
                  }}
                >
                  <ImageSearch />
                </Button>
                <Button
                  onClick={() => {
                    setPlantId(params.id);
                    setShowMigrationScriptsPopup(true);
                  }}
                >
                  <DriveFileMoveIcon />
                </Button>
              </>
            )}
            {params.row.actions.includes('PlantsList_Configuration_D') && (
              <>
                <Button
                  onClick={() => {
                    setShowDeleteModal(true);
                    setPlantId(params.id);
                  }}
                >
                  <DeleteIcon />
                </Button>
              </>
            )}
            {params.row.actions.includes('HMI_Configuration_R') && (
              <Button
                onClick={() => {
                  setPlantId(params.id);
                  setShowHmiPopup(true);
                }}
              >
                HMI
              </Button>
            )}
            {params.row.actions.includes('PlantDetails_Configuration_WE') && (
              <Button
                onClick={() => {
                  navigate(`/PlantConfig/${params.id}`);
                }}
              >
                <SettingsIcon sx={{ color: 'black' }} />
              </Button>
            )}
            {params.row.actions.includes('OneTimeTask_Configuration_W') && (
              <Button
                onClick={() => {
                  navigate(`/oneTimeTask/${params.id}`);
                }}
              >
                <TaskIcon sx={{ color: 'black' }} />
              </Button>
            )}
            {params.row.actions.includes('SensorDetails_Configuration_WE') && (
              <Button
                onClick={() => {
                  navigate(`/sensors/${params.id}`);
                }}
              >
                <SensorsIcon sx={{ color: 'black' }} />
              </Button>
            )}
            {params.row.actions.length > 0 && (
              <Button
                onClick={event => {
                  setRedirectMenuAnchorEl(event.currentTarget);
                }}
              >
                <CallMadeIcon sx={{ color: 'black' }} />
              </Button>
            )}
            <PlantConfigMenu
              anchorEl={redirectMenuAnchorEl}
              setAnchorEl={setRedirectMenuAnchorEl}
              params={params}
            />
          </>
        );
      },
      width: 400,
    },
    {
      field: 'id',
      headerName: 'Id',
      width: 250,
    },
    {
      field: 'plantName',
      headerName: 'Plant Name',
      width: 250,
    },
    {
      field: 'csmName',
      headerName: 'Customer Success Manager',
      width: 300,
    },
    {
      field: 'clName',
      headerName: 'Client',
      width: 200,
    },
    {
      field: 'sPName',
      headerName: 'Service Provider',
      width: 200,
    },
    {
      field: 'createdOn',
      headerName: 'Created On',
      width: 200,
    },
    {
      field: 'renewOn',
      headerName: 'Renewal Date',
      width: 200,
    },
    {
      field: 'packages',
      headerName: 'Packages',
      width: 300,
    },
    {
      field: 'lat',
      headerName: 'Latitude',
      width: 200,
    },
    {
      field: 'long',
      headerName: 'Longitude',
      width: 200,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
    },
  ];

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent plantId={plantId}>
        <Box m={1} p={1}>
          <div
            style={{
              fontFamily: 'Montserrat Bold',
              fontSize: 30,
              alignItems: 'center',
              verticalAlign: 'middle',
            }}
          >
            Plants
            <span style={{ float: 'right' }}>
              <Button
                variant="contained"
                onClick={() => {
                  setAddPlant(true);
                }}
              >
                <AddIcon />
                Add
              </Button>
            </span>
          </div>
        </Box>
        <Box m={1} p={1}>
          <div style={{ height: 420 }}>
            <DataGrid rows={plantData} columns={columns} loading={loading} />
          </div>
        </Box>
        {addPlant ? (
          <AddPlantPopup
            refreshParentPage={() => {
              setRefreshPage(!refreshPage);
            }}
            handleClose={() => {
              setAddPlant(false);
              setEdit(false);
            }}
            plantId={plantId}
            edit={edit}
          />
        ) : null}
        {showPlcPopup ? (
          <AddPlcPopup
            handleClose={() => {
              setShowPlcPopup(false);
            }}
            plantId={plantId}
          />
        ) : null}
        {showHmiPopup ? (
          <AddHmiPopup
            setShowHmiPopup={setShowHmiPopup}
            showHmiPopup={showHmiPopup}
            plantId={plantId}
          />
        ) : null}

        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={'Are you sure you want to delete this plant?'}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={async () => {
            deletePlant(plantId).then((response: any) => {
              if (response) {
                console.log(response);
                let Data = response.body;
                if (Data.type === 'success') {
                  toast.success(Data.message);
                } else {
                  toast.error(Data.message);
                }
                fetchPlants().then(plantData => {
                  setData(plantData);
                  setLoading(false);
                  setRefreshPage(true);
                  setProcessing(false);
                });
                setShowDeleteModal(false);
                setRefreshPage(false);
              }
            });
          }}
        />
        <SelectImageForPlantPopup
          open={showImageSelectPopup}
          setShowPopup={setShowImageSelectPopup}
          plantId={plantId}
        />
        <MigrationScriptPopup
          open={showMigrationScriptsPopup}
          handleClose={() => {
            setShowMigrationScriptsPopup(false);
          }}
          plantId={plantId}
        />
        <ToastContainer />
      </LayoutComponent>
    </ThemeProvider>
  );
}

function PlantConfigMenu(props: any) {
  const { anchorEl, setAnchorEl, params } = props;

  const navigate = useNavigate();
  const redirectTo = (link: string) => {
    navigate(link);
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={anchorEl && params.hasFocus ? true : false}
      onClose={() => {
        setAnchorEl(null);
      }}
    >
      {params.row.actions.includes('PlantDetails_Configuration_WE') && (
        <MenuItem
          onClick={() => {
            redirectTo(`/PlantConfig/${params.id}`);
          }}
        >
          Plant Config
        </MenuItem>
      )}
      {params.row.actions.includes(
        'PlantMaintaince_Configuration_WE' ||
          'PlantLabTestResults_Configuration_WE' ||
          'WaterQualityTestResults_Configuration_WE'
      ) && (
        <MenuItem
          onClick={() => {
            redirectTo(`/DataInputConfigv2/${params.id}`);
          }}
        >
          Datainput Config
        </MenuItem>
      )}
      {params.row.actions.includes() && ( // @pending : Missing permission
        <MenuItem
          onClick={() => {
            redirectTo(`/StoreMaintenance?plantId=${params.id}`);
          }}
        >
          Store Input
        </MenuItem>
      )}

      {params.row.actions.includes('PlantEscalation_Configuration_WE') && (
        <MenuItem
          onClick={() => {
            redirectTo(`/escalation/${params.id}`);
          }}
        >
          Escalation Config
        </MenuItem>
      )}
      {params.row.actions.includes('FixedTask_Configuration_WE') && (
        <MenuItem
          onClick={() => {
            redirectTo(`/fixedTasksConfig/${params.id}`);
          }}
        >
          Fixed Tasks Config
        </MenuItem>
      )}
      {params.row.actions.includes('AlertTask_Configuration_W') && (
        <MenuItem
          onClick={() => {
            redirectTo(`/alertTasksConfig/${params.id}`);
          }}
        >
          Alert Config
        </MenuItem>
      )}

      {params.row.actions.includes('ManualTickets_Usage_WE') && (
        <MenuItem
          onClick={() => {
            redirectTo(`/manualAlertsConfig/${params.id}`);
          }}
        >
          Manual Alert Config
        </MenuItem>
      )}
      {params.row.actions.includes('Formula_Configuration_WE') && (
        <MenuItem
          onClick={() => {
            redirectTo(`/formulaConfiguration/${params.id}`);
          }}
        >
          Formula Config
        </MenuItem>
      )}
      {params.row.actions.includes() && ( // @pending : Missing permission
        <MenuItem
          onClick={() => {
            redirectTo(`/DosingConfiguration/${params.id}`);
          }}
        >
          Dosing Config
        </MenuItem>
      )}
    </Menu>
  );
}
