import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { styledBoxWithShadow } from '../../styles/global/components/dpBox';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SinglePlantOutputConfigurator from './SinglePlantOutputConfigurator';
import { PlantConfigStyle } from './PlantConfigStyle';
interface IProps {
  outputPlants: any;
  plantConfig: any;
  setPlantConfig: any;
  handleShowOnDiagramCheck: Function;
}

function OutputPlantConfig({
  outputPlants,
  plantConfig,
  setPlantConfig,
  handleShowOnDiagramCheck,
}: IProps) {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [plantToBeDeleted, setPlantToBeDeleted] = useState<any>({});
  const [plantIndexToBeDeleted, setPlantIndexToBeDeleted] = useState<number>(0);
  function removeElement(index: number) {
    let newPlantConfig = { ...plantConfig };
    newPlantConfig['outputPlants'].splice(index, 1);
    setPlantConfig(newPlantConfig);
  }
  return (
    <>
      <Box m={1} p={2}>
        {outputPlants.map((plant: any, index: number) => {
          return (
            <>
              <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0.6rem',
                    }}
                  >
                    <Typography variant="h4">{plant.name}</Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box m={1} p={1} sx={styledBoxWithShadow}>
                    <SinglePlantOutputConfigurator
                      plant={plant}
                      plantId={plant._id}
                      plantConfig={plantConfig}
                      setPlantConfig={setPlantConfig}
                      plantIndex={index}
                      handleShowOnDiagramCheck={handleShowOnDiagramCheck}
                    />
                    <div style={PlantConfigStyle.configFormWrapper}>
                      <Button
                        variant="contained"
                        style={PlantConfigStyle.deleteButton}
                        onClick={() => {
                          setShowDeleteModal(true);
                          setPlantToBeDeleted(plant);
                          setPlantIndexToBeDeleted(index);
                        }}
                      >
                        <DeleteIcon style={PlantConfigStyle.deleteIcon} />
                      </Button>
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </>
          );
        })}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={`You are deleting the output plant ${plantToBeDeleted.name}`}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            removeElement(plantIndexToBeDeleted);
            setProcessing(false);
            setShowDeleteModal(false);
          }}
        />
      </Box>
    </>
  );
}

export default OutputPlantConfig;
