import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PLANT_CONFIG_DATA } from '../../../constants/constants';
import { ICoreEquipment } from '../../../Interfaces/PlantConfigurationInterface';
import { PlantConfigStyle } from '.././PlantConfigStyle';
import FormHeader from '.././subComponents/FormHeader';
import SensorTagArray from '.././subComponents/SensorTagArray';
import DeleteConfirmation from '../../../globalComponents/dialogs/DeleteConfirmation';
import { useState } from 'react';
import DiagramSelectComponent from '.././subComponents/DiagramSelectComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IProps {
  coreEquipmentData: ICoreEquipment[];
  RemoveElement: Function;
  updateSensorList: Function;
  updateValue: Function;
  updateSensorTagValue: Function;
  handleNickNameChange: Function;
  handleShowOnDiagramCheckForSubequipment: Function;
  handleBiDirectionalCheckForSubequipment: Function;
}
function checkIfBidirectionalControlPossible(sensors: any) {
  let onOffSensor = sensors.find((sensor: any) => sensor.sensorName === 'ON/OFF Status');
  if (onOffSensor) {
    return true;
  } else {
    return false;
  }
}
const createComponentTextFields = (
  coreEquipmentDetails: ICoreEquipment,
  Index: number,
  updateValue: Function
) => {
  type IField = {
    fieldName: keyof ICoreEquipment;
    fieldLabel: string;
    variant: 'outlined' | 'filled' | 'standard' | undefined;
    placeholder: string;
    maxLength: number;
    step: string;
    handleTextFieldChange: Function;
    updateSensorTagValue?: Function;
  };
  const fields: IField[] = [
    {
      fieldName: 'age',
      fieldLabel: 'Age in months',
      variant: 'outlined',
      placeholder: '',
      maxLength: 1000,
      step: '1',
      handleTextFieldChange: updateValue,
    },
  ];
  return fields.map(fieldDetails => {
    const { fieldName, variant, fieldLabel, placeholder, maxLength, step, handleTextFieldChange } =
      fieldDetails;
    return {
      id: `${fieldName}-${coreEquipmentDetails.id}`,
      fieldName,
      Index,
      type: `${typeof coreEquipmentDetails[fieldName]}`,
      value: coreEquipmentDetails[fieldName],
      variant,
      label: fieldLabel,
      placeholder,
      inputProps: { maxLength, step },
      handleTextFieldChange,
    };
  });
};

function CoreEquipment({
  coreEquipmentData,
  RemoveElement,
  updateSensorList,
  updateValue,
  updateSensorTagValue,
  handleNickNameChange,
  handleShowOnDiagramCheckForSubequipment,
  handleBiDirectionalCheckForSubequipment,
}: IProps) {
  const [equipmentIndex, setEquipmentIndex] = useState<Number | null>(null);
  const [equipmentName, setEquipmentName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [equipment, setEquipment] = useState('');

  return (
    <>
      <Box m={1} p={2}>
        {coreEquipmentData.map((CoreEquipment: any, Index: number) => {
          const componentTextFields = createComponentTextFields(CoreEquipment, Index, updateValue);
          return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.6rem',
                  }}
                >
                  <Typography variant="h4">
                    {CoreEquipment.name} {CoreEquipment.count}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box m={1} p={1}>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <FormHeader
                          formTitle="Choose Sensors"
                          formData={
                            PLANT_CONFIG_DATA['Core Equipments'][CoreEquipment.name]['sensors']
                          }
                          formHelperText={{
                            name: CoreEquipment.name,
                            count: CoreEquipment.count,
                          }}
                          sensorsData={CoreEquipment.sensors}
                          componentIndex={Index}
                          categoryName="Core Equipments"
                          updateSensorList={updateSensorList}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Box m={1} p={1}>
                          {componentTextFields.map(data => (
                            <TextField
                              id={data.id}
                              type={data.type}
                              value={data.value}
                              variant={data.variant}
                              label={data.label}
                              placeholder={data.placeholder}
                              inputProps={data.inputProps}
                              onChange={e =>
                                data.handleTextFieldChange({
                                  category: 'Core Equipments',
                                  index: data.Index,
                                  valuefor: data.fieldName,
                                  value: parseFloat(e.target.value),
                                })
                              }
                              style={PlantConfigStyle.equipmentTextField}
                            />
                          ))}
                          <DiagramSelectComponent
                            category="Core Equipments"
                            index={Index}
                            updateValue={updateValue}
                            layoutDiagram={CoreEquipment['layoutDiagram']}
                          />
                          <SensorTagArray
                            categoryName="Core Equipments"
                            formHelperText={{
                              name: CoreEquipment.name,
                              count: CoreEquipment.count,
                            }}
                            sensorsTagData={CoreEquipment['sensors']}
                            componentIndex={Index}
                            updateSensorTagValue={updateSensorTagValue}
                            hmiData={CoreEquipment['hmiButtons']}
                            updateValue={updateValue}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={CoreEquipment.displayNode}
                                // checked={check}
                                onChange={() => {
                                  handleShowOnDiagramCheckForSubequipment(
                                    'Core Equipments',
                                    Index,
                                    !CoreEquipment.displayNode
                                  );
                                }}
                                name={'Show on diagram'}
                              />
                            }
                            label={'Show on diagram'}
                          />
                          {checkIfBidirectionalControlPossible(CoreEquipment['sensors']) && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={CoreEquipment.bidDirectionalControl}
                                  // checked={check}
                                  onChange={() => {
                                    handleBiDirectionalCheckForSubequipment(
                                      'Core Equipments',
                                      Index,
                                      !CoreEquipment.bidDirectionalControl
                                    );
                                  }}
                                  name={'Bi-Directional Control'}
                                />
                              }
                              label={'Bi-Directional Control'}
                            />
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Box m={1}>
                          <TextField
                            value={CoreEquipment['nickName']}
                            variant="outlined"
                            label="Equipment NickName"
                            onChange={e =>
                              handleNickNameChange({
                                category: 'Core Equipments',
                                index: Index,
                                value: e.target.value,
                              })
                            }
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={PlantConfigStyle.configFormWrapper}>
                    <Button
                      variant="contained"
                      style={PlantConfigStyle.deleteButton}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setEquipmentIndex(Index);
                        setEquipmentName(`${CoreEquipment.name} ${CoreEquipment.count}`);
                        setEquipment('Core Equipments');
                      }}
                    >
                      <DeleteIcon style={PlantConfigStyle.deleteIcon} />
                    </Button>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={`You are deleting the ${equipmentName}`}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            RemoveElement(equipment, equipmentIndex);
            setProcessing(false);
            setShowDeleteModal(false);
          }}
        />
      </Box>
    </>
  );
}

export default CoreEquipment;
