import { Stack, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';

export default function MapWidgetLevelConfig(props: any) {
  const { widgetConfig, handleWidgetLookChange, handleWidgetPropertyChange } = props;

  return (
    <Stack direction="column" gap={2} mt={2}>
      <TextField
        id="outlined-basic"
        label="Map Zoom"
        variant="outlined"
        type="text"
        fullWidth
        defaultValue={widgetConfig?.widgetName}
        onBlur={event => {
          handleWidgetPropertyChange('widgetName', event.target.value);
        }}
      />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Widget Layout</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={widgetConfig?.widgetLook?.widgetLayout || 'graphLayout'}
          label="Widget Layout"
          onChange={event => {
            handleWidgetLookChange('widgetLayout', event.target.value);
          }}
        >
          <MenuItem value={'mapLayout1'}>Layout 1 (Like Graph Widget)</MenuItem>
          <MenuItem value={'mapLayout2'}>Layout 2 (Takes Full Space of a row)</MenuItem>
          <MenuItem value={'mapLayout3'}>Layout 3 (Like Layout 2 but covers 2 rows)</MenuItem>
        </Select>
      </FormControl>
      <TextField
        id="outlined-basic"
        label="Map Zoom"
        variant="outlined"
        type="number"
        fullWidth
        value={widgetConfig?.widgetLook?.mapZoom || 4}
        onChange={event => {
          handleWidgetLookChange('mapZoom', event.target.value);
        }}
      />
      <TextField
        id="outlined-basic"
        label="Widget Ai Helper Text"
        variant="outlined"
        type="text"
        fullWidth
        multiline
        rows={5}
        value={widgetConfig.aiHelperText || ''}
        onChange={event => {
          handleWidgetPropertyChange('aiHelperText', event.target.value);
        }}
      />
    </Stack>
  );
}
