//@ts-nocheck
import { clamp } from 'lodash';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useEffect, useRef } from 'react';
import { getAggregationTypeFromId } from '../../../utils/dashboard.utils';
Chart.register(...registerables);
Chart.register(zoomPlugin);

export default function BarChart(props: any) {
  const { data, enableZoom, viewEveryNthTick, ticksColor, resetZoom } = props;
  const chartRef: any = useRef(null);

  function resetZoomFunc() {
    if (chartRef && chartRef.current) chartRef.current.resetZoom();
  }

  useEffect(() => {
    resetZoomFunc();
  }, [resetZoom]);
  return (
    <Bar
      ref={chartRef}
      data={data}
      options={{
        plugins: {
          legend: {
            position: 'top',
            labels: {
              boxHeight: 7,
              boxWidth: 30,
              font: {
                size: function (context) {
                  var width = context.chart.width;
                  var height = context.chart.height;
                  var size = Math.round(height / 27);
                  return clamp(11, size, 16);
                },
              },
              color: '#666',
            },
            onClick: function (event, legendItem) {
              const chart = this.chart; // Get the chart instance
              let index = legendItem.datasetIndex;

              // Check if any of the given dataset is hidden
              let hiddenFlag: boolean = false;

              chart.data.datasets.forEach((dataset, i) => {
                if (dataset.hidden === true) {
                  hiddenFlag = true;
                }
              });

              // && chart.data.datasets[index + 1].hidden === true
              if (chart.data.datasets[index].hidden === false && hiddenFlag === true) {
                chart.data.datasets.forEach((dataset, i) => {
                  dataset.hidden = false; //Make all dataset hidden false
                });
              } else if (chart.data.datasets[index].hidden === true && hiddenFlag === true) {
                chart.data.datasets.forEach((dataset, i) => {
                  if (i === index) {
                    dataset.hidden = false; // Show only the clicked dataset
                  }
                });
              } else {
                // Toggle the visibility of all datasets
                chart.data.datasets.forEach((dataset, i) => {
                  if (i === index) {
                    dataset.hidden = false; // Show only the clicked dataset
                  } else {
                    dataset.hidden = true; // Hide all other datasets
                  }
                });
              }

              chart.update(); // Refresh the chart
            },
          },
          zoom: {
            zoom: {
              wheel: { enabled: enableZoom },
              pinch: { enabled: enableZoom },
              mode: 'x',
            },
            pan: {
              enabled: enableZoom,
              mode: 'x',
            },
          },
          tooltip: {
            enabled: true,
            callbacks: {
              title: function (context) {
                if (context.length > 0) {
                  const index = context[0].dataIndex;
                  const data = context[0].chart.data.labels;

                  const currentTime = data[index]; // Assuming x is your time value
                  const datasetIndex = context[0].datasetIndex;
                  const dataset = context[0].chart.data.datasets[datasetIndex];
                  // Get next time value if exists
                  const nextTime =
                    index < data.length - 1 ? data[index + 1] : dataset.lastTimeLabel;

                  return getAggregationTypeFromId(dataset.id) != 'Raw'
                    ? `${currentTime} ${nextTime.length > 0 ? `-` : ''} ${nextTime}`
                    : `${currentTime}`;
                }
                return '';
              },
              label: function (context) {
                const datasetLabel = context.dataset.label || '';
                const value = context.parsed.y;
                return `${datasetLabel}: ${value}`;
              },
              footer: function (context) {
                if (context.length > 0) {
                  const datasetIndex = context[0].datasetIndex;
                  const dataset = context[0].chart.data.datasets[datasetIndex];
                  return `Aggregation Type: ${getAggregationTypeFromId(dataset.id || '')}`;
                }
                return '';
              },
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: true,
            },
            ticks: {
              callback: function (val: any, index: number) {
                // Hide the label of every 2nd dataset
                return index % viewEveryNthTick === 0 ? this.getLabelForValue(val) : '';
              },
              color: ticksColor,
              font: {
                size: function (context) {
                  var width = context.chart.width;
                  var height = context.chart.height;
                  var size = Math.round(height / 27);
                  return clamp(12, size, 16);
                },
              },
            },
          },
          y: {
            grid: {
              display: true,
            },
            ticks: {
              color: ticksColor,
              font: {
                size: function (context) {
                  var width = context.chart.width;
                  var height = context.chart.height;
                  var size = Math.round(height / 27);
                  return clamp(12, size, 16);
                },
              },
            },
          },
        },
        maintainAspectRatio: false,
      }}
    />
  );
}
