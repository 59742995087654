export default function PermissionIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '16px' }}
    >
      <rect
        x="2.5"
        y="2.5"
        width="11"
        height="11"
        rx="0.5"
        fill="#DAE4F1"
        stroke="#193458"
        stroke-linejoin="round"
      />
    </svg>
  );
}
