import { FC, useEffect, useState } from 'react';
import { Grid, MenuItem, Select, Stack } from '@mui/material';
import { getUploadedImages } from '../../services/plants';

interface ChooseImagePopup {
  preSetImage: any;
  setCanSave: Function;
  setNewImageId: Function;
}

const ChooseImagePopup: FC<ChooseImagePopup> = ({ preSetImage, setCanSave, setNewImageId }) => {
  let [imageList, setImageList] = useState<any>([]);
  let [selectedImageUrl, setSelectedImageUrl] = useState<string>('');
  let [selectedImageId, setSelectedImageId] = useState<string>('');
  let [selectedImageName, setSelectedImageName] = useState<string>('');

  useEffect(() => {
    setSelectedImageUrl(preSetImage.url);
    setSelectedImageId(preSetImage._id);
    setSelectedImageName(preSetImage.imageName);
    console.log(preSetImage.imageName);
  }, [preSetImage]);
  useEffect(() => {
    getUploadedImages().then(data => {
      console.log(data);
      setImageList(data.data);
    });
  }, []);
  useEffect(() => {
    setNewImageId(selectedImageId);
    if (selectedImageId !== preSetImage._id) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }, [selectedImageId]);

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '50px',
        alignContent: 'center',
        width: '500px',
      }}
    >
      <Stack sx={{ width: 'inherit' }}>
        <Grid style={{}} container spacing={2}>
          {/* Column 1 */}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              borderRight: '1px solid #E0E0E0',
              // paddingRight: "16px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Grid item sx={{ marginRight: '20px' }}>
                  <img
                    src={selectedImageUrl}
                    alt="Preview"
                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Column 2 */}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              borderRight: 'none',
              // paddingLeft: "16px",
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <Select
                  value={selectedImageName}
                  onChange={e => {
                    setSelectedImageName(e.target.value);
                    let selectedImage = imageList.find(
                      (image: any) => image.imageName === e.target.value
                    );
                    setSelectedImageUrl(selectedImage.url);
                    setSelectedImageId(selectedImage._id);
                  }}
                >
                  {imageList.map((image: any) => {
                    return <MenuItem value={image.imageName}>{image.imageName}</MenuItem>;
                  })}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* {canSave && (
          <Box
            sx={{
              width: 'inherit',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Button
              variant="contained"
              style={{
                backgroundColor: '#183650',
                // borderColor: '#183650',
                margin: '30px 20px 30px 0px',
              }}
              onClick={() => {}}
            >
              Save
            </Button>
          </Box>
        )} */}
      </Stack>
    </div>
  );
};

export default ChooseImagePopup;
