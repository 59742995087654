import axiosInstance from '../constants/axiosInstance';

async function getDashboardPageListData(plantId: string) {
  try {
    let dashboardPageList = await axiosInstance().post(
      `/newDashboard/getDashboardPageListData?plantId=${plantId}`
    );
    return dashboardPageList.data.data;
  } catch (err) {
    return err;
  }
}

async function clonePageConfig(clusterId: string, pageId: string) {
  try {
    let response = await axiosInstance().post(
      `/newDashboard/clonePageConfig?clusterId=${clusterId}&pageId=${pageId}`
    );
    return response;
  } catch (err) {
    return err;
  }
}

async function deleteDashboardPage(clusterId: string, pageId: string) {
  try {
    let response = await axiosInstance().post(
      `/newDashboard/deleteDashboardPage?clusterId=${clusterId}&pageId=${pageId}`
    );
    return response;
  } catch (err) {
    return err;
  }
}

async function deleteCluster(clusterId: string, dashboardPages: string[]) {
  try {
    let response = await axiosInstance().post(
      `/newDashboard/deleteDashboardCluster?clusterId=${clusterId}`,
      {
        dashboardPages,
      }
    );
    console.log(response);
    return response;
  } catch (err) {
    console.error(`err | services | deleteCluster`, err);
    return err;
  }
}

async function editCluster(clusterId: string, newClusterName: string) {
  try {
    let response = await axiosInstance().post(
      `/newDashboard/editCluster?clusterId=${clusterId}&clusterName=${newClusterName}`
    );
    console.log(response);
    return response.data;
  } catch (err) {
    console.error(`err | services | editCluster`, err);
    return err;
  }
}

async function setDashboardConfigData(pageId: string, plantId: string) {
  try {
    let response = await axiosInstance().post(
      `/newDashboard/setDashboardConfigData?pageId=${pageId}&plantId=${plantId}`
    );
    return response;
  } catch (err) {
    return err;
  }
}

async function setAutoEmailingDataV2(
  pageId: string,
  plantId: string,
  subField: any,
  isChecked: boolean
) {
  try {
    let response = await axiosInstance().post(
      `/plantManagement/setAutoEmailingDataV2?plantId=${plantId}&pageId=${pageId}`,
      { subField: subField, isChecked: isChecked }
    );
    return response;
  } catch (err) {
    return err;
  }
}

async function setUnifiedDashboardConfigData(pageId: string) {
  try {
    let response = await axiosInstance().post(
      `/newDashboard/setUnifiedDashboardConfigData?pageId=${pageId}`
    );
    return response;
  } catch (err) {
    return err;
  }
}

async function getUnifiedDashboardPageListData() {
  try {
    let dashboardPageList = await axiosInstance().post(
      `/newDashboard/getUnifiedDashboardPageListData`
    );
    return dashboardPageList.data.data;
  } catch (err) {
    return err;
  }
}
async function syncDashboardPageWithSensorDB(pageId: string) {
  try {
    let response = await axiosInstance().get(`/plantManagement/syncDashboardData?pageId=${pageId}`);
    return response;
  } catch (e) {
    return e;
  }
}
export {
  getDashboardPageListData,
  clonePageConfig,
  setAutoEmailingDataV2,
  deleteDashboardPage,
  deleteCluster,
  setDashboardConfigData,
  editCluster,
  setUnifiedDashboardConfigData,
  getUnifiedDashboardPageListData,
  syncDashboardPageWithSensorDB,
};
