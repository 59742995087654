import { dia, util } from '@joint/plus';

const LIQUID_COLOR = '#00A2FF';
const FLOW_FLAG = 'FLOW';

export class Pipe extends dia.Link {
    // @ts-ignore
    defaults() {
        return {
            ...super.defaults,
            type: 'Pipe',
            z: -1,
            router: { name: 'rightAngle' },
            flow: 0,
            attrs: {
                flow: 0,
                liquid: {
                    connection: true,
                    stroke: LIQUID_COLOR,
                    strokeWidth: 10,
                    strokeLinejoin: 'round',
                    strokeLinecap: 'square',
                    strokeDasharray: '10,20',
                },
                line: {
                    connection: true,
                    stroke: '#eee',
                    strokeWidth: 10,
                    strokeLinejoin: 'round',
                    strokeLinecap: 'round',
                },
                outline: {
                    connection: true,
                    stroke: '#444',
                    strokeWidth: 16,
                    strokeLinejoin: 'round',
                    strokeLinecap: 'round',
                },
                sourceId: '',
                targetId: '',
            },
        };
    }

    preinitialize() {
        this.markup = util.svg/* xml */ `
                <path @selector="outline" fill="none"/>
                <path @selector="line" fill="none"/>
                <path @selector="liquid" fill="none"/>
            `;
    }

    setFlow(newFlow: number) {
        this.set('flow', newFlow);
    }
}

export const PipeView = dia.LinkView.extend({
    presentationAttributes: dia.LinkView.addPresentationAttributes({
        flow: [FLOW_FLAG],
    }),

    // @ts-ignore
    initFlag: [...dia.LinkView.prototype.initFlag, FLOW_FLAG],

    flowAnimation: null,

    // @ts-ignore
    confirmUpdate(...args) {
        // @ts-ignore
        let flags = dia.LinkView.prototype.confirmUpdate.call(this, ...args);
        if (this.hasFlag(flags, FLOW_FLAG)) {
            this.updateFlow();
            flags = this.removeFlag(flags, FLOW_FLAG);
        }
        return flags;
    },

    getFlowAnimation() {
        let { flowAnimation } = this;
        if (flowAnimation) return flowAnimation;
        const liquidEl = this.findNode('liquid');
        // stroke-dashoffset = sum(stroke-dasharray) * n;
        // 90 = 10 + 20 + 10 + 20 + 10 + 20
        const keyframes = { strokeDashoffset: [90, 0] };
        flowAnimation = liquidEl.animate(keyframes, {
            fill: 'forwards',
            duration: 1000,
            iterations: Infinity,
        });
        this.flowAnimation = flowAnimation;
        return flowAnimation;
    },

    updateFlow() {
        const { model } = this;
        const flowRate = model.get('flow') || 0;
        this.getFlowAnimation().playbackRate = flowRate;
        const liquidEl = this.findNode('liquid');
        liquidEl.style.stroke = flowRate === 0 ? '#ccc' : '';
    },
});

export class Join extends dia.Element {
    defaults() {
        return {
            ...super.defaults,
            type: 'Join',
            size: {
                width: 30,
                height: 30,
            },
            attrs: {
                body: {
                    fill: '#eee',
                    stroke: '#666',
                    strokeWidth: 2,
                    d: 'M 10 0 H calc(w - 10) l 10 10 V calc(h - 10) l -10 10 H 10 l -10 -10 V 10 Z',
                }
            },
        };
    }

    preinitialize() {
        this.markup = util.svg/* xml */ `
                <path @selector="body"/>
            `;
    }
}
