import { useState, useEffect } from 'react';
import {
  TextField,
  ThemeProvider,
  Dialog,
  Stack,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Grid,
  MenuItem,
  FormHelperText,
  CircularProgress,
  // Box,
} from '@mui/material';

import dpTheme from '../../styles/theme/dpTheme';
import { AddBlueButton } from '../../styles/global/components/dpButtons';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import { useNavigate } from 'react-router-dom';
import { IPlants, StoreItemDetails } from '../../Interfaces/InventoryOptionsInterface';
import CloseIcon from '@mui/icons-material/Close';

import { getRemarks } from '../../services/Remarks';
import { RemarksInterface } from '../../Interfaces/Remarks';
import { updateStore } from '../../services/UpdateInventory';
import { toast } from 'react-toastify';
import moment from 'moment';
import useAuth from '../../utilities/useAuth';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DateRangeIcon from '@mui/icons-material/DateRange';
import dayjs, { Dayjs } from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat.js';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import BalanceIcon from '@mui/icons-material/Balance';
dayjs.extend(advancedFormat);
// @ts-ignore
import { createGlobalStyle } from 'styled-components';

// Don't even bother asking why this abomination is needed, but if you remove this or <GlobalStyle/> in TSX below,
// something will break in CSS and you might end up spending hours. trust me
const GlobalStyle = createGlobalStyle`                    
  .css-13uqigx-MuiTypography-root-MuiPickersToolbarText-root-MuiDateTimePickerToolbar-separator {
    transform: translateY(10px);
  }
`;

interface IProps {
  isUpdateClicked: boolean;
  setIsUpdateClicked: Function;
  setIsInventoryOptionSelected: Function;
  setRefresh: Function;
  action: string;
  storeId: string;
  inputType: 'number' | 'float';
  product: StoreItemDetails;
  categoryName: string;
  plantsList: IPlants[];
  clientId: string;
  plantIdParam: string | null;
}
export default function InventoryProductUsage({
  isUpdateClicked,
  setIsUpdateClicked,
  setIsInventoryOptionSelected,
  setRefresh,
  action,
  storeId,
  product,
  categoryName,
  inputType,
  plantsList,
  clientId,
  plantIdParam,
}: IProps) {
  const [remarks, setRemarks] = useState<RemarksInterface[]>();
  const [selectedRemark, setSelectedRemark] = useState<string>('');
  const isMobile = useIsMobile();
  const [quantErrorMsg, setQuantErrorMsg] = useState<string>('');
  const [remarkErrorMsg, setRemarkErrorMsg] = useState<string>('');

  const [assetErrorMsg, setAssetErrorMsg] = useState<string>('');

  // const [form, setForm] = useState({});
  const [selectedPlantId, setSelectedPlantId] = useState('');
  const [quantity, setQuantity] = useState<string>('');
  const [prepareSolutionRemark, setPrepareSolutionRemark] = useState<any>(null);
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [dateTime, setDateTime] = useState<Dayjs>(dayjs());
  const navigate = useNavigate();
  const { role } = useAuth();
  const remarksRequiringAssets = ['Consumed in the plant', 'Prepared Solution'];
  const [isLoading, setIsLoading] = useState(false);

  console.log('product', product);
  console.log('isMobile', isMobile);

  useEffect(() => {
    fetchRemarks();
    if (action === 'remove') {
      setSelectedPlantId(getFilteredPlants()[0]?.plantId);
    }
  }, [categoryName, action]);

  useEffect(() => {
    let defaultRemark: RemarksInterface;
    let preparedSolutionRemark: RemarksInterface;
    if (action === 'remove' && remarks) {
      defaultRemark = remarks!.find((remark, index) => {
        return remark.remark === 'Consumed in the plant';
      })!;
      preparedSolutionRemark = remarks!.find((remark, index) => {
        return remark.remark === 'Prepared Solution';
      })!;
      if (!defaultRemark) {
        defaultRemark = remarks[0];
      }

      setSelectedRemark(defaultRemark?.remark);
      setPrepareSolutionRemark(preparedSolutionRemark);
    } else {
      if (remarks) {
        setSelectedRemark(remarks[0].remark);
      }
    }
  }, [remarks, isUpdateClicked]);

  useEffect(() => {
    setQuantity('');
    setQuantErrorMsg('');
    setAssetErrorMsg('');
  }, [isUpdateClicked, selectedRemark]);

  const fetchRemarks = () => {
    getRemarks(categoryName, action).then(data => {
      setRemarks(data);
    });
  };

  const handleClose = () => {
    setQuantErrorMsg('');
    setRemarkErrorMsg('');
    setAssetErrorMsg('');
    setIsUpdateClicked(false);
  };

  const handleValidation = () => {
    let isError = false;
    if (selectedRemark === '') {
      setRemarkErrorMsg('Please select a remark');
      isError = true;
    }
    if (quantity === '') {
      setQuantErrorMsg('Please provide a quantity');
      isError = true;
    }
    if (action === 'remove' && selectedPlantId === '') {
      setAssetErrorMsg('Please select one Asset ');
    }

    if (action === 'remove' && parseFloat(quantity) > product.availableQuantity) {
      setQuantErrorMsg("Can't be greater than available value");
      isError = true;
    }
    if (inputType === 'float' && !/^\d+(\.\d+)?$/.test(quantity)) {
      console.log('here');
      setQuantErrorMsg('Please enter a valid value');
      isError = true;
    } else {
      if (inputType === 'number' && !/^\d+$/.test(quantity)) {
        console.log('here number');
        setQuantErrorMsg('Please enter a valid integer value');
        isError = true;
      }
    }

    return isError;
  };
  const handleSubmit = () => {
    if (selectedRemark === 'Prepared Solution') {
      setIsUpdateClicked(false);
      navigate(`/ChemicalComposition/${selectedPlantId}`, {
        state: {
          storeId,
          clientId: clientId,
          chemicalName: product.name,
          chemicalId: product.itemId,
          chemicalUnit: product.unit,
          availableQuantity: product.availableQuantity,
          remarkId: prepareSolutionRemark.remarkID,
          plantIdParam: plantIdParam,
        },
      });
      return;
    }
    let isError = handleValidation();
    // console.log("typeof +quantity:", typeof parseFloat(quantity));
    // console.log("quantErrorMsg", quantErrorMsg);
    if (isError === true) {
      return;
    }
    setIsLoading(true);

    const remarkToSend = remarks!.find(tempRemark => selectedRemark === tempRemark.remark);

    let calculatedAt: moment.Moment | Dayjs;

    role === 'Operator Group' ? (calculatedAt = moment()) : (calculatedAt = dateTime);

    updateStore({
      storeId: storeId,
      assetId: selectedPlantId!,
      amount: parseFloat(quantity),
      remarkID: remarkToSend!.remarkID,
      unit: product.unit,
      action: action,
      calculatedAt: calculatedAt,
      categoryItemId: product.itemId,
      storeCategoryName: categoryName,
    })
      .then(data => {
        // console.log(quantErrorMsg);
        setRefresh((prevState: boolean) => !prevState);
        setTimeout(() => {
          setIsLoading(false);
          setIsUpdateClicked(false);
          setIsInventoryOptionSelected(false);
          toast(data.data.message);
          if (plantIdParam) navigate(`/StoreMaintenance?plantId=${plantIdParam}`);
          else navigate(`/StoreMaintenance`);
        }, 1500);
      })
      .catch(err => {
        toast.error(err.message);
      });
  };
  const handlePlantChange = (e: any) => {
    setSelectedPlantId(e.target.value);
  };

  const getFilteredPlants = () => {
    return plantsList?.filter(plant => product?.assetIds.includes(plant.plantId));
  };

  const handleChange = (target: string, e: any) => {
    console.log('target', target, 'e.target.value', e.target.value);
    if (target === 'remark') {
      setSelectedRemark(e.target.value);
      setRemarkErrorMsg('');
    } else if (target === 'quantity') {
      setQuantity(e.target.value);
      setQuantErrorMsg('');
    }
  };

  return (
    <ThemeProvider theme={dpTheme}>
      {/* dont remove globalstyle please */}
      <GlobalStyle />
      {product && (
        <Dialog
          // fullWidth
          open={isUpdateClicked}
          onClose={handleClose}
          // maxWidth={'sm'}
          sx={{ boxSizing: 'border-box' }}
        >
          <Grid
            height="100%"
            width="100%"
            container
            direction="column"
            alignItems="center"
            justifyContent="space-evenly"
            rowGap={4}
            pl={4}
            pr={4}
            mb={2}
          >
            <Grid
              container
              item
              mt={3}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              // columnGap={2}
            >
              <Grid item>
                <Typography variant="h3">{product.name}</Typography>
              </Grid>
              <Grid item>
                <IconButton aria-label="close" onClick={handleClose}>
                  <CloseIcon sx={{ color: 'black' }} />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item width="100%" alignSelf="flex-start">
              <Stack
                direction="row"
                // width="100%"
                p={3}
                justifyContent="center"
                alignItems="center"
                columnGap={2}
                borderRadius="10px"
                sx={{ border: '1px solid #DEF2F2' }}
              >
                <BalanceIcon fontSize="small" sx={{ color: '#3EA4A8' }} />
                <Typography variant="h6">Available Quantity | </Typography>
                <Typography color="disabled.dark" variant="body2" sx={{ fontSize: '12px' }}>
                  {product.availableQuantity?.toFixed(2)}
                </Typography>
              </Stack>
            </Grid>

            <Grid item width="100%" alignSelf="flex-start">
              {remarks && (
                <FormControl fullWidth required>
                  <InputLabel id="demo-simple-select-label">Remark</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select-required"
                    value={selectedRemark}
                    disabled={!remarks}
                    fullWidth
                    error={remarkErrorMsg.length ? true : false}
                    label="Remark"
                    onChange={e => handleChange('remark', e)}
                  >
                    {remarks?.length &&
                      remarks!.map((remark, index) => {
                        return (
                          <MenuItem key={remark.remarkID} value={remark.remark}>
                            {remark.remark}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText sx={{ color: 'red' }}>{remarkErrorMsg}</FormHelperText>
                </FormControl>
              )}
            </Grid>
            <Grid
              container
              // item
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              // sx={{ columnGap: '5px' }}
            >
              <Grid item width="40%">
                {product.unit && (
                  <FormControl
                    fullWidth
                    required
                    disabled={selectedRemark === 'Prepared Solution' ? true : false}
                  >
                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select-required"
                      defaultValue={product.unit}
                      label="Unit"
                      sx={{ flex: 1, width: '100%' }}
                    >
                      <MenuItem value={product.unit}>{product.unit}</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>

              <Grid item width="50%">
                <TextField
                  disabled={selectedRemark === 'Prepared Solution' ? true : false}
                  autoFocus
                  fullWidth
                  required
                  error={quantErrorMsg.length ? true : false}
                  helperText={quantErrorMsg}
                  margin="dense"
                  id="quantity"
                  label="Quantity"
                  type="text"
                  variant="outlined"
                  value={quantity}
                  onChange={e => {
                    handleChange('quantity', e);
                  }}
                  sx={{
                    margin: '0 !important',
                    // marginTop: '0 !important',
                    // marginBottom: '0 !important'
                  }}
                />
              </Grid>
            </Grid>
            {role === 'CSM' && selectedRemark !== 'Prepared Solution' && (
              <Grid item width="100%" alignSelf="center">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    columnGap="5px"
                    onClick={() => {
                      setOpenDateTimePicker(true);
                    }}
                    sx={{
                      border: '1px solid #999999',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      padding: '15px',
                    }}
                  >
                    <DateRangeIcon fontSize="small" sx={{ color: '#3EA4A8' }} />
                    <Typography noWrap color="dark" sx={{ fontSize: '10px', color: '#999999' }}>
                      {dayjs(dateTime).format('Do MMM YYYY')}
                    </Typography>
                    <ArrowDropDownIcon fontSize="small" />
                    <Typography variant="h3" sx={{ fontSize: '12px', color: '#999999' }}>
                      |
                    </Typography>
                    <WatchLaterIcon fontSize="small" sx={{ marginLeft: '5px', color: '#3EA4A8' }} />
                    <Typography noWrap color="dark" sx={{ fontSize: '10px', color: '#999999' }}>
                      {dayjs(dateTime).format('hh:mm A')}
                    </Typography>
                    <ArrowDropDownIcon fontSize="small" />
                  </Stack>
                  <MobileDateTimePicker
                    open={openDateTimePicker}
                    onClose={() => setOpenDateTimePicker(false)}
                    value={dateTime}
                    disableFuture={true}
                    onChange={dateTime => {
                      // console.log("dateTime", dateTime);
                      setDateTime(dateTime!);
                    }}
                    slots={{
                      textField: () => <TextField sx={{ display: 'none' }} />,
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {action === 'remove' && remarksRequiringAssets.includes(selectedRemark) && (
              <Grid item width="100%">
                <FormControl fullWidth>
                  <InputLabel id="asset-select-label">Asset</InputLabel>
                  <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedPlantId}
                    error={assetErrorMsg.length ? true : false}
                    label="Asset"
                    onChange={handlePlantChange}
                  >
                    {getFilteredPlants()?.map((plant: IPlants) => (
                      <MenuItem value={plant?.plantId}>{plant.plantName}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText sx={{ color: 'red' }}>{assetErrorMsg}</FormHelperText>
                </FormControl>
              </Grid>
            )}
            <Grid
              container
              item
              xs
              direction="row"
              alignContent="flex-end"
              justifyContent="space-between"
              pb={4}
              columnGap={3}
            >
              {/* <Grid item xs>
                <WhiteButton fullWidth onClick={handleClose}>
                  <Typography variant="h6">CANCEL</Typography>
                </WhiteButton>
              </Grid> */}
              <Grid item xs>
                <AddBlueButton
                  fullWidth
                  sx={{ padding: '10px' }}
                  type="submit"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  <Typography variant="h6">SUBMIT</Typography>
                  {isLoading ? (
                    <CircularProgress
                      size={20}
                      thickness={4}
                      sx={{ color: 'white', marginLeft: '10px' }}
                    />
                  ) : (
                    ''
                  )}
                </AddBlueButton>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </ThemeProvider>
  );
}
