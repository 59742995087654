import { create } from 'zustand';
interface TreeState {
  ids: string[];
  workspace: string[];
  setIds: (newId: string[]) => void;
  setWorkspace: (newId: string[]) => void;
  cancel: () => void;
}

const useTreeStore = create<TreeState>(set => ({
  ids: [],
  workspace: [],
  setIds: (newId: string[]) =>
    set((state: { ids: any }) => ({
      ids: newId,
    })),
  setWorkspace: (newId: string[]) =>
    set((state: { ids: any }) => ({
      workspace: newId,
    })),
  cancel: () =>
    set(() => ({
      ids: [],
    })),
}));
export { useTreeStore };
