import { Box } from '@mui/system';
import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import { useEffect } from 'react';

export const tabStyles = {
  marginRight: '1.5rem',
  whiteSpace: 'nowrap',
  '& .Mui-selected': {
    color: '#2196F3',
  },
  '& .MuiTab-root': {
    color: '#00000099',
  },
};

interface IAssets {
  _id: string;
  plantName: string;
}

interface AllAssetsProps {
  selectedStoreAssets: IAssets[];
  setSelectedStoreAssets: (selectedStoreAssets: IAssets[]) => void;
  selectedAssetTab: { _id: string; name: string };
  setSelectedAssetTab: (selectedAssetTab: { _id: string; name: string }) => void;
  assetName: string;
  setAssetName: (assetName: string) => void;
}

export const AllAssetTabs: React.FC<AllAssetsProps> = ({
  selectedStoreAssets,
  setSelectedStoreAssets,
  selectedAssetTab,
  setSelectedAssetTab,
  assetName,
  setAssetName,
}) => {
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    const selectedAsset = selectedStoreAssets.find(asset => asset.plantName === newValue);

    // If the selectedAsset is found, update the state with _id and name
    if (selectedAsset) {
      setSelectedAssetTab({ _id: selectedAsset._id, name: selectedAsset.plantName });
    }
    setAssetName(newValue);
  };
  useEffect(() => {
    if (selectedStoreAssets.length === 0) {
      const storedDataString = localStorage.getItem('selectedStoreAssets');
      const selectedAssets: IAssets[] = JSON.parse(storedDataString as string);
      setSelectedStoreAssets(selectedAssets);
    }
  }, []);
  return (
    <Box
      sx={{
        margin: !isMobile ? '.5rem 0rem' : '',
        marginTop: isMobile ? '.5rem' : '',
        marginBottom: isMobile ? '1rem' : '1rem',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
        maxWidth: '100%',
      }}
    >
      <TabContext value={assetName}>
        <TabList onChange={handleTabChange} variant="scrollable" scrollButtons={false}>
          {selectedStoreAssets.length > 1 ? (
            <Tab label="All Asset" value="All Asset" sx={tabStyles} />
          ) : (
            <></>
          )}
          {/* <Tab label="All Asset" value="All Asset" sx={tabStyles} /> */}
          {selectedStoreAssets?.map((assets, index) => (
            <Tab
              key={index}
              value={assets.plantName}
              label={assets.plantName}
              sx={index === selectedStoreAssets.length - 1 ? {} : tabStyles}
            />
          ))}
        </TabList>
      </TabContext>
    </Box>
  );
};
