import { useEffect, useState } from "react";
import { Box, TextField } from "@mui/material";
import "./styles.css";
import Autocomplete from "@mui/lab/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import Switch from "@mui/material/Switch";
import {
  userData,
  newUserInterface,
  userRoleBasedData,
} from "../../Interfaces/userInterface";
import { plantInterfaceForGetAllPlantsApi } from "../../Interfaces/plantInterfaces";
import {
  createNewUser,
  getAllUsersForRole,
  getUser,
  updateUser,
} from "../../services/users";
import { getAllPlants } from "../../services/plant";
import { BlackButton } from "../../styles/global/components/dpButtons";

interface AddDpTeamPopupInterface {
  handleClose: Function;
  edit: boolean;
  userId: string;
  forWho: string;
  refresh: Function;
}

interface plantOption {
  id: string;
  plantName: string;
}

const newUserObj = {
  role: "",
  name: "",
  email: "",
  number: "",
  plantId: [],
  defaultPageId: "",
  forid: "",
  userStatus: "active",
};

export default function AddDpTeamPopup(props: AddDpTeamPopupInterface) {
  const { handleClose, refresh, edit, userId, forWho } = props;
  const [newUserData, setnewUserData] = useState<userData | newUserInterface>(
    newUserObj
  );
  const [refreshs, setRefreshs] = useState(false);
  const [options, setOptionData] = useState<userRoleBasedData[]>([]);
  const [emailreq, setEmailReq] = useState(true);
  const [loading, setLoading] = useState(true);

  const roles = [
    "Operator",
    "Rotating Operator",
    "Maintenance In-Charge",
    "Site Supervisor",
    "Service Head",
    "Procurer",
    "Building Level Client",
    "Senior Client",
    "Dp Team",
  ];

  const [plants, setPlants] = useState<plantOption[]>([]);

  useEffect(() => {
    console.log("edit value : ", edit);
    console.log("hey i am here");

    setLoading(true);
    fetchPlants();
    if (forWho === "Client") {
      fetchClients();
    }
    if (edit) {
      fetchUserInfo();
    }
    setLoading(!loading);
  }, []);

  const fetchPlants = async () => {
    getAllPlants().then((data) => {
      console.log("plants : ", data);
      let plantInfo: plantOption[] = [];
      data.map((plant: plantInterfaceForGetAllPlantsApi) => {
        plantInfo.push({
          id: plant.id,
          plantName: plant.plantName,
        });
      });
      setPlants(plantInfo);
    });
  };

  const fetchClients = () => {
    getAllUsersForRole("Client").then((data) => {
      setOptionData(data);
      setRefreshs(!refreshs);
    });
  };

  const fetchUserInfo = () => {
    getUser(userId)
      .then((data) => {
        setnewUserData(data);
        console.log("user data : ", data);
        setRefreshs(!refreshs);
      })
      .catch();
  };

  const handleChanges = (key: string, value: any) => {
    let data = newUserData;
    if (key === "role") {
      data[key] = value;
      if (value === "Operator") {
        setEmailReq(false);
      } else {
        setEmailReq(true);
      }
    } else if (key === "plantId") {
      let plantIds: string[] = [];
      value.map((plant: any) => {
        plantIds.push(plant.id);
      });
      data[key] = plantIds;
    } else if (key == "userStatus") {
      // console.log("User status : " ,  value );
      if (value) data[key] = "active";
      else data[key] = "inactive";
    } else {
      data[key] = value;
    }

    setnewUserData(data);
    setRefreshs(!refreshs);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("newUserData : ", newUserData);
    console.log("edit : ", edit);
    if (edit) {
      updateUser(newUserData)
        .then((data) => {
          if (data.data.type === "error") {
            toast.error(data.data.message);
          } else if (data.data.type === "success") {
            toast.success(data.data.message);
          } else {
            toast(data.data.message);
          }
          refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      createNewUser(newUserData)
        .then((data) => {
          console.log(data);
          toast(data.data.message);
          refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getSelectedClient = (
    options: userRoleBasedData[],
    user: userData | newUserInterface
  ) => {
    let client = null;
    let choice = options.filter((obj) => {
      return obj.id === user?.forid;
    });
    console.log("choice : ", choice);
    client = choice[0];
    return client;
  };

  const getSelectedPlants = (
    userData: userData | newUserInterface,
    allPlants: plantOption[]
  ) => {
    let selectedPlants: plantOption[] = [];
    userData?.plantId?.forEach((plantid) => {
      let matchingPlant = allPlants?.find(
        (plantData) => plantData.id === plantid
      );
      if (matchingPlant) selectedPlants.push(matchingPlant);
    });

    return selectedPlants;
  };

  if (loading) return <div>loading</div>;
  else
    return (
      <div className="popup-box">
        <div className="box">
          <span
            className="close-icon"
            onClick={() => {
              handleClose();
            }}
          >
            x
          </span>
          <form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              handleSubmit(e);
            }}
          >
            <Box m={1} p={1}>
              <TextField
                fullWidth
                required
                value={newUserData?.name}
                label="Name"
                onChange={(e) => {
                  handleChanges("name", e.target.value);
                }}
              />
            </Box>
            <Box m={1} p={1}>
              <TextField
                fullWidth
                required
                value={newUserData?.number}
                label="Number"
                onChange={(e) => {
                  handleChanges("number", e.target.value);
                }}
              />
            </Box>
            <Box m={1} p={1}>
              {emailreq ? (
                <TextField
                  required
                  fullWidth
                  value={newUserData?.email}
                  label="Email"
                  onChange={(e) => {
                    handleChanges("email", e.target.value);
                  }}
                />
              ) : (
                <TextField
                  fullWidth
                  value={newUserData?.email}
                  label="Email"
                  onChange={(e) => {
                    handleChanges("email", e.target.value);
                  }}
                />
              )}
            </Box>

            <Box m={1} p={1}>
              <Autocomplete
                id="tags-standard-1"
                options={options}
                getOptionLabel={(option: userRoleBasedData) => option?.name}
                value={getSelectedClient(options, newUserData) || null}
                onChange={(event, newValue) => {
                  handleChanges("forid", newValue?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    variant="standard"
                    label={forWho}
                  />
                )}
              />
            </Box>

            <Box m={1} p={1}>
              <Autocomplete
                id="tags-standard-2"
                options={roles}
                value={newUserData?.role || null}
                onChange={(event, newValue) => {
                  handleChanges("role", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    variant="standard"
                    label="Role"
                  />
                )}
              />
            </Box>

            <Box m={1} p={1}>
              <Autocomplete
                multiple
                id="tags-standard-3"
                options={plants}
                getOptionLabel={(option) => option.plantName || ""}
                value={getSelectedPlants(newUserData, plants)}
                // value ={existingPlantsCopy}

                onChange={(event, newValue) => {
                  handleChanges("plantId", newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Plants" />
                )}
              />
            </Box>

            {newUserData?.userStatus ? (
              <Box m={1} p={1}>
                User Status :{" "}
                <Switch
                  checked={newUserData?.userStatus == "active" ? true : false}
                  onChange={(event) => {
                    handleChanges("userStatus", event.target.checked);
                  }}
                />
              </Box>
            ) : (
              <Box m={1} p={1}>
                User Status :{" "}
                <Switch
                  checked={true}
                  onChange={(event) => {
                    handleChanges("userStatus", event.target.checked);
                  }}
                />
              </Box>
            )}

            <Box m={1} p={1}>
              <BlackButton variant="contained" type="submit">
                {edit ? <>Update</> : <>Create User</>}
              </BlackButton>
            </Box>
          </form>
        </div>
        <ToastContainer />
      </div>
    );
}
