import { Typography, Grid } from '@mui/material';
import { ChemicalCumulativeData } from '../../Interfaces/ChemicalLog';
import { UNITS_INFO } from '../../utils/units.utils';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import BalanceIcon from '@mui/icons-material/Balance';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import LastMonthUsageIcon from '../../assets/icons/LastMonthUsage.svg';

interface IProps {
  assetId: String;
  chemicalId: String;
  chemicalName: String;
  chemicalUnit: string;
  chemicalCumulativeData: ChemicalCumulativeData;
}

const getUnitsFullForm = (unit: string | undefined) => {
  if (!unit) return '';
  if (UNITS_INFO[unit]) return UNITS_INFO[unit]?.fullForm;
  else return '';
};

function ChemicalAccount({
  assetId,
  chemicalId,
  chemicalName,
  chemicalUnit,
  chemicalCumulativeData,
}: IProps) {
  const isMobile = useIsMobile({ breakpointValue: 'md' });
  const isMobile1 = useIsMobile({ breakpointValue: 'sm' });
  return (
    <Grid
      container
      justifyContent="space-between"
      flexWrap="nowrap"
      gap="2.5%"
      mb="1.5rem"
      mt={isMobile1 ? '1rem' : ''}
    >
      <Grid
        container
        item
        xs={4}
        justifyContent="center"
        alignItems="center"
        pt={3}
        pl={1}
        pr={1}
        pb={3}
        borderRadius="8px"
        sx={{ border: '1px solid #DEF2F2' }}
      >
        <BalanceIcon
          sx={{ color: '#3EA4A8', marginRight: !isMobile || isMobile1 ? '4px' : '' }}
          fontSize="small"
        />
        <Grid item>
          <Typography variant="caption">
            <Typography variant="caption" sx={{ color: '#666', fontWeight: 500 }}>
              {isMobile !== true && <>Current Balance</>}
            </Typography>
            <Typography variant="caption" sx={{ color: '#666', fontWeight: 400 }}>
              &nbsp;{!isMobile1 ? '| ' : ''}
              {`${chemicalCumulativeData.currentBalance} ${getUnitsFullForm(
                chemicalUnit.toLowerCase()
              )}`}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={4}
        justifyContent="center"
        alignItems="center"
        pt={3}
        pl={1}
        pr={1}
        pb={3}
        borderRadius="8px"
        sx={{ border: '1px solid #DEF2F2' }}
      >
        <CalendarMonthIcon
          sx={{ color: '#3EA4A8', marginRight: !isMobile || isMobile1 ? '4px' : '' }}
          fontSize="small"
        />
        <Grid item>
          <Typography variant="caption">
            <Typography variant="caption" sx={{ color: '#666', fontWeight: 500 }}>
              {isMobile !== true && <>Current Month Usage</>}
            </Typography>
            <Typography variant="caption" sx={{ color: '#666', fontWeight: 400 }}>
              &nbsp;{!isMobile1 ? '| ' : ''}
              {`${chemicalCumulativeData.currentMonthUsage} ${getUnitsFullForm(
                chemicalUnit.toLowerCase()
              )}`}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={4}
        justifyContent="center"
        alignItems="center"
        pt={3}
        pl={1}
        pr={1}
        pb={3}
        borderRadius="8px"
        sx={{ border: '1px solid #DEF2F2' }}
      >
        <img
          src={LastMonthUsageIcon}
          alt="logo"
          style={{
            color: '#3EA4A8',
            marginRight: !isMobile || isMobile1 ? '4px' : '',
            width: '21px',
            marginTop: '2px',
          }}
        />
        <Grid item>
          <Typography variant="caption">
            <Typography variant="caption" sx={{ color: '#666', fontWeight: 500 }}>
              {isMobile !== true && <>Last Month Usage</>}
            </Typography>
            <Typography variant="caption" sx={{ color: '#666', fontWeight: 400 }}>
              &nbsp;{!isMobile1 ? '| ' : ''}
              {`${chemicalCumulativeData.lastMonthUsage} ${getUnitsFullForm(
                chemicalUnit.toLowerCase()
              )}`}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ChemicalAccount;
