import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  IconButton,
  Checkbox,
  Stack,
  Tooltip,
} from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { getSensorListBasedOnFilters } from '../../services/sensors';
import GeneralMetricSearchBar from './GeneralMetricSearchBar';
import { SortableContainer, SortableElement, SortEnd } from 'react-sortable-hoc';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface sensorItem {
  _id: string;
  sensorId: string;
  sensorTag: string;
  sensorNickName?: string;
}

interface PlantConfigComponentProps {
  plantIndex: number;
  plantConfigInfo: any;
  deletePlant: Function;
  handleAddSensor: Function;
  handleDeleteSensor: Function;
  handleDiagramChange: Function;
  reOrderSensors: Function;
  handleSensorValueChange: Function;
  handlePlantValueChange: Function;
}

const SortableListItem = SortableElement(
  ({ plantIndex, handleDeleteSensor, sensor, handleSensorValueChange }: any) => (
    <Accordion elevation={0} sx={{ background: 'inherit' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${sensor._id}-content`}
        id={`${sensor._id}-header`}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '95%',
            justifyContent: 'space-between',
          }}
        >
          {/* <Typography>{sensor.sensorTag}</Typography> */}

          <Stack direction="row" alignItems="center" gap={1}>
            <span>
              {sensor?.sensorNickName && sensor?.sensorNickName != ''
                ? sensor.sensorNickName
                : sensor.sensorTag}
            </span>
            {sensor.sensorNickName && (
              <Tooltip title={sensor.sensorTag}>
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
            )}
          </Stack>

          <IconButton
            edge="end"
            onClick={() => {
              handleDeleteSensor(plantIndex, sensor?._id);
            }}
          >
            <DeleteIcon sx={{ color: 'black', fontSize: '22px' }} />
          </IconButton>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container alignItems="center">
          <Grid item xs={8}>
            <Typography>Display on node:</Typography>
          </Grid>
          <Grid item xs={4}>
            <Checkbox
              checked={sensor.display || false}
              onChange={e => {
                handleSensorValueChange(plantIndex, sensor._id, 'display', e.target.checked);
              }}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
);

const SortableList = SortableContainer(
  ({ plantIndex, handleDeleteSensor, sensors, handleSensorValueChange }: any) => {
    return (
      <List>
        {sensors.map((sensor: any, index: number) => (
          <SortableListItem
            key={sensor._id}
            index={index}
            //@ts-ignore
            plantIndex={plantIndex}
            //@ts-ignore
            sensor={sensor}
            handleDeleteSensor={handleDeleteSensor}
            handleSensorValueChange={handleSensorValueChange}
          />
        ))}
      </List>
    );
  }
);

const PlantConfigComponent: React.FC<PlantConfigComponentProps> = ({
  plantIndex,
  plantConfigInfo,
  deletePlant,
  handleAddSensor,
  handleDeleteSensor,
  handleDiagramChange,
  reOrderSensors,
  handleSensorValueChange,
  handlePlantValueChange,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  // const sensorList: any[] = [
  //   { sensorId: "620cf68a15f5530706eef952", sensorTag: "sensor 1" },
  //   { sensorId: "620cf68a15f5530706eef953", sensorTag: "sensor 2" },
  //   { sensorId: "620cf68a15f5530706eef954", sensorTag: "sensor 3" },
  // ]; // Example sensors
  const [sensorList, setSensorList] = useState<any[]>([]);
  // const handleOpen = (): void => setOpen(true);

  useEffect(() => {
    getSensorListBasedOnFilters({ plantId: plantConfigInfo.plantId }).then(data => {
      setSensorList(data);
      console.log(data);
    });
  }, []);

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    // Reorder sensorList based on drag-and-drop
    reOrderSensors(plantIndex, oldIndex, newIndex);
  };

  return (
    <Accordion
      elevation={0}
      sx={{
        borderRadius: '5px',
        padding: '10px',
        marginTop: '20px',
        background: '#F0F0F0',
        border: '1px solid #BDBDBD',
        maxHeight: '450px',
        overflowY: 'scroll',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ flex: 1 }}>{plantConfigInfo?.plantName}</Typography>
        <IconButton onClick={() => console.log('Delete plant')}>
          <DeleteIcon
            sx={{ color: 'black', fontSize: '22px' }}
            onClick={e => {
              e.stopPropagation();
              setShowDeleteModal(true);
            }}
          />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="row" rowGap={3}>
          <Grid item xs={6} md={6} lg={6} p={3} borderRadius={1}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={plantConfigInfo.displayNode}
                    onChange={event => {
                      handlePlantValueChange(plantIndex, 'displayNode', event.target.checked);
                    }}
                  />
                }
                label="Display Node"
              />
            </FormGroup>
            <GeneralMetricSearchBar
              sensorList={sensorList}
              keywordFilterList={[]}
              addedSensorList={plantConfigInfo.sensors}
              handleAddSensor={(sensor: sensorItem) => {
                let newWidgetMetric = {
                  _id: sensor._id,
                  sensorTag: sensor.sensorTag,
                  sensorNickName: sensor.sensorNickName,
                  display: true,
                };
                handleAddSensor(plantIndex, newWidgetMetric);
              }}
            />
            <Box
              mt={2}
              p={1}
              borderRadius={4}
              sx={{ background: '#ddd', height: '220px', overflowY: 'scroll' }}
            >
              <Typography mt={1} sx={{ color: '#555' }}>
                {`Added Sensors (${plantConfigInfo?.sensors?.length || 0})`}
              </Typography>
              <SortableList
                //@ts-ignore
                sensors={plantConfigInfo?.sensors}
                plantIndex={plantIndex}
                handleDeleteSensor={handleDeleteSensor}
                onSortEnd={onSortEnd}
                distance={10}
                // lockAxis="y"
                helperClass="sortableHelper"
                handleSensorValueChange={handleSensorValueChange}
              />
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
      <DeleteConfirmation
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        alertMessage="This is a irreversible step, are you sure you want to continue?"
        processing={false}
        setProcessing={() => {}}
        onConfirmAction={() => {
          deletePlant(plantIndex);
        }}
      />
    </Accordion>
  );
};

export default PlantConfigComponent;
