import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';

import { PLANT_CONFIG_DATA } from '../../../constants/constants';
import { ITank } from '../../../Interfaces/PlantConfigurationInterface';
import MaterialOfConstruction from '.././MaterialOfConstruction';
import { PlantConfigStyle } from '.././PlantConfigStyle';
import FormHeader from '.././subComponents/FormHeader';
import SensorTagArray from '.././subComponents/SensorTagArray';

import { useState } from 'react';
import DeleteConfirmation from '../../../globalComponents/dialogs/DeleteConfirmation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DiagramSelectComponent from '.././subComponents/DiagramSelectComponent';

interface IProps {
  tankData: ITank[];
  RemoveElement: Function;
  updateSensorList: Function;
  updateValue: Function;
  handleNickNameChange: Function;
  updateSensorTagValue: Function;
  handleShowOnDiagramCheckForSubequipment: Function;
}

const createComponentTextFields = (
  TankDetails: ITank,
  componentIndex: number,
  updateValue: Function,
  handleNickNameChange: Function
) => {
  type IField = {
    fieldName: keyof ITank;
    fieldLabel: string;
    variant: 'outlined' | 'filled' | 'standard' | undefined;
    placeholder: string;
    maxLength: number;
    step?: string | undefined;
    fieldType: 'number' | 'text';
    handleTextFieldChange: Function;
  };
  const fields: IField[] = [
    {
      fieldName: 'area',
      fieldLabel: 'Effective Area',
      variant: 'outlined',
      placeholder: 'in sq. m',
      maxLength: 13,
      step: '0.01',
      fieldType: 'number',
      handleTextFieldChange: updateValue,
    },
    {
      fieldName: 'height',
      fieldLabel: 'Height',
      variant: 'outlined',
      placeholder: 'in sq. m',
      maxLength: 13,
      step: '0.01',
      fieldType: 'number',
      handleTextFieldChange: updateValue,
    },
    {
      fieldName: 'free board',
      fieldLabel: 'Free Board',
      variant: 'outlined',
      placeholder: 'in sq. m',
      maxLength: 13,
      step: '0.01',
      fieldType: 'number',
      handleTextFieldChange: updateValue,
    },
    {
      fieldName: 'nickName',
      fieldLabel: 'Tank NickName',
      variant: 'outlined',
      placeholder: 'in sq. m',
      maxLength: 20,
      fieldType: 'text',
      handleTextFieldChange: handleNickNameChange,
    },
  ];
  return fields.map(fieldDetails => {
    const {
      fieldName,
      variant,
      fieldLabel,
      placeholder,
      maxLength,
      step,
      fieldType,
      handleTextFieldChange,
    } = fieldDetails;
    return {
      id: `${fieldName}-${TankDetails.id}`,
      fieldName,
      componentIndex,
      type: fieldType,
      value: TankDetails[fieldName],
      variant,
      label: fieldLabel,
      placeholder,
      inputProps: { maxLength, step },
      handleTextFieldChange,
    };
  });
};

function Tank({
  tankData,
  RemoveElement,
  updateSensorList,
  updateValue,
  handleNickNameChange,
  updateSensorTagValue,
  handleShowOnDiagramCheckForSubequipment,
}: IProps) {
  const [equipmentIndex, setEquipmentIndex] = useState<Number | null>(null);
  const [equipmentName, setEquipmentName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [equipment, setEquipment] = useState('');

  return (
    <>
      <Box m={1} p={2}>
        {tankData.map((Tank: ITank, Index: number) => {
          const componentTextFields = createComponentTextFields(
            Tank,
            Index,
            updateValue,
            handleNickNameChange
          );
          return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.6rem',
                  }}
                >
                  <Typography variant="h4">
                    {Tank.name} {Tank.count}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box m={1} p={1}>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <FormHeader
                          formTitle="Choose Sensors"
                          formData={PLANT_CONFIG_DATA['Tanks'][Tank.name]['sensors']}
                          formHelperText={{
                            name: Tank.name,
                            count: Tank.count,
                          }}
                          sensorsData={Tank.sensors}
                          componentIndex={Index}
                          categoryName="Tanks"
                          updateSensorList={updateSensorList}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Box m={1} p={1}>
                          {componentTextFields.map(data => (
                            <TextField
                              id={data.id}
                              type={data.type}
                              value={data.value}
                              variant={data.variant}
                              label={data.label}
                              placeholder={data.placeholder}
                              inputProps={data.inputProps}
                              onChange={e =>
                                data.handleTextFieldChange({
                                  category: 'Tanks',
                                  index: data.componentIndex,
                                  valuefor: data.fieldName,
                                  value:
                                    data.type === 'number'
                                      ? parseFloat(e.target.value)
                                      : e.target.value,
                                })
                              }
                              style={PlantConfigStyle.equipmentTextField}
                            />
                          ))}
                          <DiagramSelectComponent
                            category="Tanks"
                            index={Index}
                            updateValue={updateValue}
                            layoutDiagram={Tank['layoutDiagram']}
                          />
                          <SensorTagArray
                            categoryName="Tanks"
                            formHelperText={{
                              name: Tank.name,
                              count: Tank.count,
                            }}
                            sensorsTagData={Tank['sensors']}
                            componentIndex={Index}
                            updateSensorTagValue={updateSensorTagValue}
                            hmiData={Tank['hmiButtons']}
                            updateValue={updateValue}
                          />
                        </Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Tank.displayNode}
                              // checked={check}
                              onChange={() => {
                                handleShowOnDiagramCheckForSubequipment(
                                  'Tanks',
                                  Index,
                                  !Tank.displayNode
                                );
                              }}
                              name={'Show on diagram'}
                            />
                          }
                          label={'Show on diagram'}
                        />
                      </Grid>
                      <MaterialOfConstruction
                        updateValue={updateValue}
                        materialData={Tank['material']}
                        drainData={Tank['drain']}
                        overFlowData={Tank['overflow']}
                        bypassData={Tank['bypass']}
                        ageData={Tank['age']}
                        index={Index}
                      />
                    </Grid>
                  </Grid>
                  <div style={PlantConfigStyle.configFormWrapper}>
                    <Button
                      variant="contained"
                      style={PlantConfigStyle.deleteButton}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setEquipmentIndex(Index);
                        setEquipmentName(`${Tank.name} ${Tank.count}`);
                        setEquipment('Tanks');
                      }}
                    >
                      <DeleteIcon style={PlantConfigStyle.deleteIcon} />
                    </Button>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={`You are deleting the ${equipmentName}`}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            RemoveElement(equipment, equipmentIndex);
            setProcessing(false);
            setShowDeleteModal(false);
          }}
        />
      </Box>
    </>
  );
}

export default Tank;
