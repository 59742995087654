import moment from 'moment';
import { Stack, Box } from '@mui/material';
interface IDateTimeForTableCellProps {
  date: string;
  dateStringFormat: string;
}
const isValidDate = (dateString: string) => {
  return !isNaN(Date.parse(dateString));
};

export default function DateTimeForTableCell(props: IDateTimeForTableCellProps) {
  const { date, dateStringFormat } = props;
  if (isValidDate(date))
    return (
      <Stack flexDirection="row" gap={1}>
        <Box sx={{ fontSize: '13px' }}>
          {moment(date, dateStringFormat).format('DD MMM YYYY -')}
        </Box>
        <Box sx={{ color: '#999999E5', fontSize: '12px' }}>
          {moment(date, dateStringFormat).format('hh:mm a')}
        </Box>
      </Stack>
    );
  else return <Box> - </Box>;
}
