//@ts-nocheck
import { clamp } from 'lodash';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { useEffect, useRef } from 'react';
import { getAggregationTypeFromId } from '../../../utils/dashboard.utils';
Chart.register(...registerables);
Chart.register(zoomPlugin);
import { useState } from 'react';

export default function LineChart(props: any) {
  const { data, enableZoom, viewEveryNthTick, ticksColor, resetZoom, validRangeSettings } = props;

  const chartRef: any = useRef(null);
  function resetZoomfunc() {
    if (chartRef && chartRef.current) chartRef.current.resetZoom();
  }


  const rangePlugin = {
    id: 'rangePlugin',
    beforeDraw: chart => {
      const {
        ctx,
        chartArea: { top, bottom, left, right },
        scales: { y },
      } = chart;

      if (!validRangeSettings) {
        return;
      }

      const { applyMin, applyMax, min, max } = validRangeSettings;
  
      ctx.save();
      ctx.fillStyle = 'rgba(166, 229, 138, 1)';

      if (applyMin && min !== null && applyMax && max !== null) {
        const minY = y.getPixelForValue(min);
        const maxY = y.getPixelForValue(max);
  
        if (max < min) {
          // Case when max is smaller than min
          const minClampedY = Math.max(Math.min(minY, bottom), top);
          const maxClampedY = Math.max(Math.min(maxY, bottom), top);
  
          // Band from min to top
          ctx.fillRect(left, top, right - left, minClampedY - top);
  
          // Band from max to bottom
          ctx.fillRect(left, maxClampedY, right - left, bottom - maxClampedY);
  
          // Draw boundary lines
          ctx.strokeStyle = 'rgba(46, 46, 46, 1)';
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(left, minClampedY);
          ctx.lineTo(right, minClampedY);
          ctx.moveTo(left, maxClampedY);
          ctx.lineTo(right, maxClampedY);
          ctx.stroke();
        } else {
          // Normal case when min is smaller than max
          const minClampedY = Math.max(Math.min(minY, bottom), top);
          const maxClampedY = Math.max(Math.min(maxY, bottom), top);
          ctx.fillRect(left, maxClampedY, right - left, minClampedY - maxClampedY);
  
          ctx.strokeStyle = 'rgba(46, 46, 46, 1)';
          ctx.lineWidth = 1;
          ctx.beginPath();
          ctx.moveTo(left, maxClampedY);
          ctx.lineTo(right, maxClampedY);
          ctx.moveTo(left, minClampedY);
          ctx.lineTo(right, minClampedY);
          ctx.stroke();
        }
      } else if (applyMin && min !== null) {
        // Only Min applied
        const minY = Math.max(Math.min(y.getPixelForValue(min), bottom), top);
        ctx.fillRect(left, top, right - left, minY - top);
  
        ctx.strokeStyle = 'rgba(46, 46, 46, 1)';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(left, minY);
        ctx.lineTo(right, minY);
        ctx.moveTo(left, top);
        ctx.lineTo(right, top);
        ctx.stroke();
      } else if (applyMax && max !== null) {
        // Only Max applied
        const maxY = Math.max(Math.min(y.getPixelForValue(max), bottom), top);
        ctx.fillRect(left, maxY, right - left, bottom - maxY);
  
        ctx.strokeStyle = 'rgba(46, 46, 46, 1)';
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(left, maxY);
        ctx.lineTo(right, maxY);
        ctx.moveTo(left, bottom);
        ctx.lineTo(right, bottom);
        ctx.stroke();
      }
  
      ctx.restore();
    },
  };
  

  useEffect(() => {
    resetZoomfunc();
  }, [resetZoom]);

  return (
    <Line
      ref={chartRef}
      data={data}
      options={{
        plugins: {
          legend: {
            position: 'top',

            labels: {
              // padding : 5,
              boxHeight: 3,
              boxWidth: 30,
              font: {
                size: function (context) {
                  var width = context.chart.width;
                  var height = context.chart.height;
                  var size = Math.round(height / 27);
                  return clamp(11, size, 19);
                },
              },
              color: '#666',
            },

            onHover: function (event, legendItem, legend) {
              // Change cursor to pointer when hovering over a legend item
              event.native.target.style.cursor = 'pointer';
            },
            onLeave: function (event, legendItem, legend) {
              // Change cursor back to default when not hovering over a legend item
              event.native.target.style.cursor = 'default';
            },

            onClick: function (event, legendItem) {
              const chart = this.chart; // Get the chart instance
              let index = legendItem.datasetIndex;

              // Check if any of the given dataset is hidden
              let hiddenFlag: boolean = false;

              chart.data.datasets.forEach((dataset, i) => {
                if (dataset.hidden === true) {
                  hiddenFlag = true;
                }
              });

              // && chart.data.datasets[index + 1].hidden === true
              if (chart.data.datasets[index].hidden === false && hiddenFlag === true) {
                chart.data.datasets.forEach((dataset, i) => {
                  dataset.hidden = false; //Make all dataset hidden false
                });
              } else if (chart.data.datasets[index].hidden === true && hiddenFlag === true) {
                chart.data.datasets.forEach((dataset, i) => {
                  if (i === index) {
                    dataset.hidden = false; // Show only the clicked dataset
                  }
                });
              } else {
                // Toggle the visibility of all datasets
                chart.data.datasets.forEach((dataset, i) => {
                  if (i === index) {
                    dataset.hidden = false; // Show only the clicked dataset
                  } else {
                    dataset.hidden = true; // Hide all other datasets
                  }
                });
              }

              chart.update(); // Refresh the chart
            },
          },
          zoom: {
            zoom: {
              wheel: { enabled: enableZoom },
              pinch: { enabled: enableZoom },
              mode: 'x',
            },
            pan: {
              enabled: enableZoom,
              mode: 'x',
            },
          },
          tooltip: {
            enabled: true,
            callbacks: {
              title: function (context) {
                if (context.length > 0) {
                  const index = context[0].dataIndex;
                  const data = context[0].chart.data.labels;

                  const currentTime = data[index]; // Assuming x is your time value
                  const datasetIndex = context[0].datasetIndex;
                  const dataset = context[0].chart.data.datasets[datasetIndex];
                  // Get next time value if exists
                  const nextTime =
                    index < data.length - 1 ? data[index + 1] : dataset.lastTimeLabel;

                  return getAggregationTypeFromId(dataset.id) != 'Raw'
                    ? `${currentTime} ${nextTime.length > 0 ? `-` : ''} ${nextTime}`
                    : `${currentTime}`;
                }
                return '';
              },
              label: function (context) {
                const datasetLabel = context.dataset.label || '';
                const value = context.parsed.y;
                return `${datasetLabel}: ${value}`;
              },
              footer: function (context) {
                if (context.length > 0) {
                  const datasetIndex = context[0].datasetIndex;
                  const dataset = context[0].chart.data.datasets[datasetIndex];
                  return `Aggregation Type: ${getAggregationTypeFromId(dataset.id || '')}`;
                }
                return '';
              },
            },
          },
        },

        scales: {
          x: {
            grid: {
              display: true,
            },
            ticks: {
              callback: function (val: any, index: number, ticks: any) {
                // Hide the last label
                // if (index === ticks.length - 1) {
                //   return;
                // }
                // Hide the label of every 2nd dataset
                return index % viewEveryNthTick === 0 ? this.getLabelForValue(val) : '';
              },
              color: ticksColor,
              font: {
                size: function (context) {
                  var width = context.chart.width;
                  var height = context.chart.height;
                  var size = Math.round(height / 27);
                  return clamp(12, size, 16);
                },
              },
            },
          },
          y: {
            // stacked: true,
            grid: {
              display: true,
            },
            ticks: {
              color: ticksColor,
              font: {
                size: function (context) {
                  var width = context.chart.width;
                  var height = context.chart.height;
                  var size = Math.round(height / 27);
                  return clamp(12, size, 16);
                },
              },
            },
          },
        },
        maintainAspectRatio: false,
      }}
      plugins={[rangePlugin]}
    />
  );
}
