import axiosInstance from "../constants/axiosInstance";

const getMaintenanceData = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/dashboard/getMaintenanceData/${plantId}`
    );
    return data.data;
  } catch (error) {
    console.log("error : ", error);
  }
};

export { getMaintenanceData };
