import { useNavigate, useParams } from 'react-router';
import LayoutComponent from '../components/LayoutComponent';
import { Box, ThemeProvider, Typography } from '@mui/material';
import DIConfigForm from '../components/DIConfig/DIConfigForm';
import { styledBox, styledTitle } from '../styles/global/components/dpBox';
import { PrimaryRoundedButton } from '../styles/global/components/dpButtons';
import dpTheme from '../styles/theme/dpTheme';
import NextIcon from '@mui/icons-material/ArrowRightAlt';
import { ToastContainer } from 'react-toastify';
import BottomNavigationComponent from '../components/BottomNavigationComponent/BottomNavigationComponent';

export default function DataInputConfiguration() {
  const navigate = useNavigate();
  const { id } = useParams<string>();

  const nextPage = () => {
    navigate(`/escalation/${id}`);
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent plantId={id}>
        <Box sx={styledBox}>
          <Typography variant="h1" sx={styledTitle}>
            Data Input Configuration
          </Typography>
          <DIConfigForm formType="waterQualityDIForm" />
          <hr></hr>
          <DIConfigForm formType="labTestDIForm" />
          <hr></hr>
          <DIConfigForm formType="maintenanceDIForm" />
          <hr></hr>
          <DIConfigForm formType="streamDIForm" />
          {/* <DIConfigForm formType="inventoryDIForm" /> */}
        </Box>

        <Box m={1} p={1}>
          <PrimaryRoundedButton onClick={nextPage}>
            Next
            <NextIcon style={{ justifySelf: 'flex-end' }} />
          </PrimaryRoundedButton>
        </Box>
        <Box
          m={4}
          p={1}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <BottomNavigationComponent plantId={id!} currentPage="Data Input" />
        </Box>
      </LayoutComponent>
      <ToastContainer />
    </ThemeProvider>
  );
}
