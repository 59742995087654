import { memo, useState } from 'react';
import EquipmentNodeDiagram from './EquipmentNodeDiagram';
import EquipmentNodeDialog from './EquipmentNodeDialog';
import { Handle, Position } from 'reactflow';
import classes from '../../styles/LayoutVisualisationStyle.module.css';
import { usePlantLayoutStore } from '../../../../store/PlantLayoutStore';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useClientSideLayoutStore from '../../../../store/ClientSideLayoutStore';

const EquimentNode = memo(({ id, data, isConnectable, sourcePosition, targetPosition }: any) => {
  const [showAllSensors, setShowAllSensors] = useState(false);
  const { biDirectionalSensorsForPlant } = usePlantLayoutStore();
  //@ts-ignore
  const { loadingCurrentLayoutDiagram, setLoadingCurrentLayoutDiagram } =
    useClientSideLayoutStore();
  const navigate = useNavigate();

  //const [showSettings, setShowSettings] = useState<boolean>(false);

  let biSensorId = null;
  let showSettings = false;
  let biSensorStatus = false;
  let LTSensorValue = 0;
  let OnOffSensorStatus = false;

  const LTSensor = data.configInfo.sensors.find(
    (sensor: any) => sensor.sensorName === 'Level Transmitter'
  );
  if (LTSensor) {
    LTSensorValue = parseFloat(LTSensor.value) || 0;
    // console.log(LTSensorValue, `Level Transmitter:`, LTSensor);
  }
  const OnOffSensor = data.configInfo.sensors.find(
    (sensor: any) => sensor.sensorName === 'ON/OFF Status'
  );
  if (OnOffSensor) {
    OnOffSensorStatus = parseInt(OnOffSensor.value) ? true : false;
    // console.log(OnOffSensorStatus, "On/Off Sensor:", OnOffSensor);
  }

  data.configInfo.sensors.forEach((sensor: any) => {
    if (biDirectionalSensorsForPlant?.includes(sensor._id)) {
      biSensorId = sensor.sensorId;
      biSensorStatus = parseInt(sensor.value) ? true : false; //This is ON/OFF Sensor Status;
      showSettings = true;
      // setShowSettings(true);
      console.log(`showSettings ${data.label} ${showSettings}`);
    }
  });

  const handleNodeClick = async () => {
    console.log('inside ue 3 :s ');
    setLoadingCurrentLayoutDiagram(true);
    if (data.componentId && data?.componentInside === true) {
      navigate(`/LayoutVisualisation/${data.componentId}`);
      setLoadingCurrentLayoutDiagram(false);
    } else {
      // toast.error('No layout diagram exists for this node');
      setLoadingCurrentLayoutDiagram(false);
    }
  };

  return (
    <div>
      <Handle
        type="target"
        position={targetPosition || Position.Left}
        isConnectable={false}
        className={classes.Node}
      />
      <Box
        onClick={handleNodeClick}
        sx={{
          border: `${data?.properties?.width ?? 1}px solid ${data?.properties?.color || '#999'}`,
          borderRadius: '8px',
          '&:hover': { cursor: data.componentInside ? 'pointer' : 'auto' },
        }}
      >
        <EquipmentNodeDiagram
          id={id}
          equipmentName={data.label}
          equipmentImage={data.image}
          imageControlId={data.imageControlId}
          data={data.configInfo}
          showLimitedSensors={true}
          setShowAllSensors={setShowAllSensors}
          showSettings={showSettings}
          biSensorId={biSensorId}
          biSensorStatus={biSensorStatus}
          LTSensor={LTSensor}
          LTSensorValue={LTSensorValue}
          OnOffSensorStatus={OnOffSensorStatus}
          nodeData={data}
        />
        {showAllSensors && (
          <EquipmentNodeDialog
            id={id}
            equipmentName={data.label}
            equipmentImage={data.image}
            imageControlId={data.imageControlId}
            data={data.configInfo}
            showAllSensors={showAllSensors}
            setShowAllSensors={setShowAllSensors}
            showSettings={showSettings}
            biSensorId={biSensorId}
            biSensorStatus={biSensorStatus}
            LTSensor={LTSensor}
            LTSensorValue={LTSensorValue}
            OnOffSensorStatus={OnOffSensorStatus}
            nodeData={data}
          />
        )}
      </Box>
      <Handle
        type="source"
        position={sourcePosition || Position.Right}
        id="b"
        isConnectable={false}
        className={classes.Node}
      />
    </div>
  );
});

export default EquimentNode;
