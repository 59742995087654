import axiosInstance from '../constants/axiosInstance';

const getTimeBasedLayoutDataForAssetId = async (assetId: string, timeFrame: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/sseRoutes/getTimeBasedLayoutDataForAssetId?assetId=${assetId}`,
      { timeFrame }
    );
    return data;
  } catch (err) {
    throw err;
  }
};

export { getTimeBasedLayoutDataForAssetId };
