const UNITS_ALLOWED: string[] = ["l", "g", "unit"];

const UNITS_INFO: { [key: string]: any } = {
  l: {
    fullForm: "litres",
  },
  g: {
    fullForm: "grams",
  },
  kg: {
    fullForm: "kg"
  },
  unit: {
    fullForm: "Unit(s)",
  },
};

const getUnitsFullForm = (unit: string) => {
  if (!unit) return "";
  if (UNITS_INFO[unit]) return UNITS_INFO[unit]?.fullForm;
  else return "";
};

export { UNITS_ALLOWED, UNITS_INFO, getUnitsFullForm };
