export default function FeatureIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ minWidth: '16px' }}
    >
      <path
        d="M3.01444 14.3672L3.0114 14.3655C2.85444 14.2751 2.72435 14.1445 2.63447 13.9873C2.54459 13.83 2.49816 13.6517 2.49993 13.4705L2.49996 13.4705L2.49996 13.4656L2.49996 2.53437L2.49998 2.53437L2.49994 2.52947C2.49816 2.34834 2.54459 2.17 2.63447 2.01273C2.72435 1.85546 2.85444 1.72493 3.0114 1.63451L3.0114 1.63452L3.01407 1.63297C3.16246 1.54627 3.33123 1.50058 3.50308 1.50058C3.67494 1.50058 3.84371 1.54627 3.9921 1.63297L3.9921 1.63297L3.99487 1.63457L13.4847 7.09776C13.6413 7.19017 13.7712 7.32177 13.8616 7.47963C13.9523 7.63807 14 7.81745 14 8C14 8.18255 13.9523 8.36194 13.8616 8.52037C13.7712 8.67824 13.6413 8.80985 13.4847 8.90226L3.99483 14.3667L3.99483 14.3667L3.99246 14.3681C3.844 14.4546 3.67519 14.5002 3.50333 14.5C3.33148 14.4999 3.16275 14.4541 3.01444 14.3672Z"
        fill="#DAE4F1"
        stroke="#193458"
      />
    </svg>
  );
}
