import axiosInstance from '../constants/axiosInstance';
import { userData, newUserInterface } from '../Interfaces/userInterface';

const getAllUsersForRole = async (role: string) => {
  try {
    const { data } = await axiosInstance().get(`/users/getUserInfo?role=${role}`);
    return data?.users;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getWorkForce = async (forWho: string, userId: string | null) => {
  try {
    if (userId == null) {
      const { data } = await axiosInstance().get(`/users/getWorkForce?forWho=${forWho}`);
      return data.workforce;
    } else {
      const { data } = await axiosInstance().get(
        `/users/getWorkForce?forWho=${forWho}&id=${userId}`
      );
      return data.workforce;
    }
  } catch (error) {
    console.log(error);
  }
};

const deleteUser = async (userId: string) => {
  try {
    const { data } = await axiosInstance().delete(`/users/deleteUser?id=${userId}`);
    return data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getUser = async (userId: string) => {
  try {
    const { data } = await axiosInstance().get(`/users/user?id=${userId}`);
    return data.user;
  } catch (error) {
    console.log('error : ', error);
  }
};

const createNewUser = async (userData: newUserInterface) => {
  try {
    const { data } = await axiosInstance().post('/users/createUser', userData);
    return data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const updateUser = async (newUserData: userData | newUserInterface) => {
  try {
    const { data } = await axiosInstance().post('/users/updateUser', newUserData);
    return data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const fetchDpTeam = async () => {
  try {
    const { data } = await axiosInstance().get('/users/getDpTeam');
    return data.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const sendForgotPasswordToEmail = async (email: string) => {
  try {
    const { data } = await axiosInstance().post('/users/forgotPassword', {
      email: email,
    });
    return data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const resetPassword = async (newPassword: string) => {
  try {
    const { data } = await axiosInstance().post('/users/resetPassword', {
      password: newPassword,
    });
    return data;
  } catch (error) {
    console.log('error in reset password api : ', error);
  }
};

const fetchPlantWorkForce = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(`/tasks/fetchPlantWorkforce?id=${plantId}`);
    return data?.body;
  } catch (err) {
    console.error('ERROR | fetchPlantWorkForce | ', err);
  }
};

const getAllUsersForRoleAndPlantIds = async (plantIds: string[], role: string) => {
  try {
    const { data } = await axiosInstance().post(`/users/getAllUserListForRoleAndPlantIds`, {
      plantIds: plantIds,
      role: role,
    });
    return data?.users;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getListOfPlantsAssignedToUser = async (userId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/newUsers/getListOfPlantsAssignedToUser?userId=${userId}`
    );
    return data?.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getListOfClientsForTheCSM = async () => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/getListOfClientsForTheCSM`);
    return data?.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getClientAbbr = async (clientName: string) => {
  try {
    const { data } = await axiosInstance().post(`/plantManagement/clientAbbrSuggestions`, {
      clientName: clientName,
    });
    return data?.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const fetchWorksapceWorkForce = async (workspaceId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/users/getWorkForceForWorkspace?workspaceId=${workspaceId}`
    );

    return data;
  } catch (error) {
    console.log(error);
  }
};

const getAllUsersForRoleBasedOnConfigurorId = async (role: string, id: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/users/getAllUsersForRoleBasedOnConfigurorId?role=${role}&id=${id}`
    );
    return data?.users;
  } catch (error) {
    console.log('error : ', error);
  }
};

export {
  getAllUsersForRole,
  deleteUser,
  getUser,
  createNewUser,
  updateUser,
  fetchDpTeam,
  sendForgotPasswordToEmail,
  resetPassword,
  fetchPlantWorkForce,
  getWorkForce,
  getAllUsersForRoleAndPlantIds,
  getListOfPlantsAssignedToUser,
  getListOfClientsForTheCSM,
  getClientAbbr,
  fetchWorksapceWorkForce,
  getAllUsersForRoleBasedOnConfigurorId,
};
