import {
  Primary,
  SemanticError,
  SemanticWarning,
} from '../core-components/theme/color-palette/colors';

type ChipStyle = {
  color: string;
  backgroundColor: string;
};

function chipColor(priority: string): ChipStyle {
  switch (priority) {
    case 'Low':
      return { color: '#FFFFFF', backgroundColor: Primary[500] };

    case 'Medium':
      return { color: '#FFFFFF', backgroundColor: SemanticWarning[500] };

    case 'High':
      return { color: '#FFFFFF', backgroundColor: SemanticError[500] };

    default:
      return { color: '#FFFFFF', backgroundColor: SemanticWarning[500] };
  }
}

export default chipColor;
