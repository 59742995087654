import axiosInstance from '../../constants/axiosInstance';
const getCurrentConnectivityStatusForAllPlants = async () => {
  try {
    const { data } = await axiosInstance().post(
      `/dataLogger/getCurrentConnectivityStatusForAllPlants`
    );
    return data.data;
  } catch (error) {
    // console.log("error : ", error);
  }
};

const getDataBreakEventsForAssetId = async (
  assetId: string,
  skip: number = 0,
  limit: number = 10
) => {
  try {
    const { data } = await axiosInstance().post(
      `/dataLogger/getDataBreakEventsForAssetId/${assetId}?skip=${skip}&&limit=${10}`
    );
    return data.data;
  } catch (error) {
    // console.log("error : ", error);
  }
};

export { getCurrentConnectivityStatusForAllPlants, getDataBreakEventsForAssetId };
