import { useEffect } from "react";
import LayoutComponent from "../components/LayoutComponent";
import { Box } from "@mui/material";
import npmLicenses from "../constants/npmLicenses.json";
import { globalStyles } from "../globalStyles/globalStyles";
import { FixedSizeList as List } from "react-window";

interface licenseInterface {
  licenses?: string;
  repository?: string;
  publisher?: string;
  email?: string;
  path?: string;
  licenseFile?: string;
}

const styles = {
  aboutPageContainer: {
    color: globalStyles.color.darkBlueTheme,
  },
  licenseListContainer: {
    width: "inherit",
    height: "inherit",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "center",
  },
  licenseName: {
    fontWeight: "bolder",
    color: "#444",
  },
};

const licenses = npmLicenses;

const licenseRow = ({ data, index, style }: any) => {
  let key: string = Object.keys(licenses)[index];
  let license: licenseInterface = licenses[key as keyof typeof licenses];
  return (
    <Box m={1} style={style}>
      <p style={styles.licenseName}>{key}</p>
      <p>License :{license["licenses"]}</p>
      <p>Publisher : {license["publisher"]}</p>
      <p>
        project Link :
        <a href={license["repository"]}>{license["repository"]}</a>
      </p>
      <hr></hr>
    </Box>
  );
};

export default function AboutPage() {
  useEffect(() => {
    console.log(npmLicenses);
  }, []);

  return (
    <LayoutComponent>
      <Box my={3} style={styles.aboutPageContainer}>
        <h2>Acknowledgments</h2>
        {/* <AutoSizer style={styles.licenseListContainer}>
          {({ height, width }) => ( */}
        <List
          width={window.innerWidth * 0.8}
          height={window.innerHeight * 0.71}
          itemData={Object.keys(licenses)}
          itemCount={Object.keys(licenses).length}
          itemSize={180}
          style={styles.rowContainer}
        >
          {licenseRow}
        </List>
        {/* )}
        </AutoSizer> */}
        <h3>Total Count : {Object.keys(licenses).length}</h3>
      </Box>
    </LayoutComponent>
  );
}
