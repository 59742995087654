import { useMemo, useState } from 'react'
import DateAndTime from '../globalComponents/dateAndTime/DateAndTime';
import { IconButton } from '../styles/global/components/dpButtons';
import CustomDialog from '../globalComponents/dialogs/CustomDialog';

function TestPage() {
    const [openDialog, setOpenDialog] = useState(false);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const dateAndTimeComponent = useMemo(() => <DateAndTime />, []);
    return (
        <div>TestPage
            <IconButton onClick={handleOpenDialog}>
              date
            </IconButton>
            <CustomDialog open={openDialog} onClose={handleCloseDialog}>
                {dateAndTimeComponent}
            </CustomDialog>
        </div>
    )
}

export default TestPage