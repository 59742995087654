const handleRoleAdd = (treeData, selectedRoleItemIds) => {
  const workspaceAssetRoles = [];

  // Loop through the workspaces
  treeData.forEach(workspace => {
    // Loop through the assets (children of the workspace)
    workspace.children.forEach(asset => {
      // Filter the roles within the asset based on selectedRoleItemIds
      const matchingRoles = asset.roles.filter(role => selectedRoleItemIds.includes(role.itemId));

      if (matchingRoles.length > 0) {
        // Extract all matching role IDs
        const roleIds = matchingRoles.map(role => role._id);

        // Push the workspace and asset data into workspaceAssetRoles
        workspaceAssetRoles.push({
          workspaceId: workspace._id, // Workspace ID
          assetId: asset._id, // Asset ID
          roleIds, // Role IDs associated with the asset
        });
      }
    });
  });

  return workspaceAssetRoles;
};

export { handleRoleAdd };
