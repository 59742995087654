import { create } from 'zustand';

export type UIControlState = {
  visualisationFullSize: boolean;
  setVisualisationFullSize: (fullSize: boolean) => void;
  toggleFullSize: () => void;
};

const useUIControlStore = create<UIControlState>(set => ({
  visualisationFullSize: false,
  setVisualisationFullSize: (fullSize: boolean) =>
    set(() => ({
      visualisationFullSize: fullSize,
    })),
  toggleFullSize: () =>
    set(state => ({
      visualisationFullSize: !state.visualisationFullSize,
    })),
}));

export default useUIControlStore;
