import create from 'zustand';

// Define a type for your store's state
interface ClientSideLayoutStoreState {
  loadingCurrentLayoutDiagram: boolean;
  setLoadingCurrentLayoutDiagram: (loading: boolean) => void;
}

const useClientSideLayoutStore = create<ClientSideLayoutStoreState>(set => ({
  loadingCurrentLayoutDiagram: false,
  setLoadingCurrentLayoutDiagram: (loading: boolean) =>
    set({ loadingCurrentLayoutDiagram: loading }),
}));

export default useClientSideLayoutStore;
