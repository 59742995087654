//@ts-nocheck
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  ThemeProvider,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import LayoutComponent from "../components/LayoutComponent";
import dpTheme from "../styles/theme/dpTheme";
import { makeStyles } from "@mui/styles";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { getInventoryCategoryLogs } from "../services/InventoryCategoryLogs";
import { lowerCase } from "lodash";
import { UNITS_INFO, UNITS_ALLOWED } from "../utils/units.utils";
import WarningIcon from "@mui/icons-material/Warning";
import "../styles/pages/InventoryCategoryLogs.module.css";
interface InventoryCategoryLogs {
  name: string;
  expectedConsumption: {
    value: string;
    time: string;
  };
  consumption: {
    value: string;
    time: string;
  };
  consumptionPerDay: string;
  unit: string;
  lastConsumption?: string;
  lastUpdated: string;
  isActive: boolean;
  stockAvailable: string;
  isOverConsumed: boolean;
  itemId: string;
  id: string;
}

const useStyles = makeStyles({
  rowPointer: {
    cursor: "pointer",
  },
  blueBorder: {
    borderBottom: "1px solid #DAF1F1 ", // Set the border color to blue
  },
  dirtyYellow: {
    background: "#FCFEE8",
  },
});

export default function InventoryCategoryLogs() {
  const { plantId } = useParams<string>();
  const [loading, setLoading] = useState(true);
  const url = useLocation();
  const urlArr = url.pathname.split("/");
  const inventoryCategoryName =
    urlArr[1].charAt(0).toLowerCase() + urlArr[1].slice(1);
  const displayInventoryCategoryName = urlArr[1];
  const navigate = useNavigate();

  const [inventoryCategoryData, setInventoryCategoryData] = useState<
    InventoryCategoryLogs[]
  >([]);
  const classes = useStyles();
  const isChemicalPage = inventoryCategoryName === "chemicals";

  useEffect(() => {
    localStorage.setItem("plantId", plantId!);
    fetchInventoryCategoryLogs();
  }, [inventoryCategoryName]);

  const fetchInventoryCategoryLogs = () => {
    getInventoryCategoryLogs(plantId!, inventoryCategoryName!)
      .then((data) => {
        console.log(data);
        setInventoryCategoryData(data);
        setLoading(false);
      })
      .catch();
  };

  const getUnitsFullForm = (unit) => {
    if (!unit) return "";
    if (UNITS_INFO[unit]) return UNITS_INFO[unit]?.fullForm;
    else return "";
  };

  const columnGroupingModel = [
    {
      groupId: "Consumption",
      headerAlign: "center",
      children: [
        { field: "averageConsumption" },
        { field: "expectedConsumption" },
      ],
    },
  ];

  const COLUMNS: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      width: 100,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {params.row.name}
          {params.row.isActive ? (
            <></>
          ) : (
            <WarningIcon fontSize="small" sx={{ color: "red" }} />
          )}
        </span>
      ),
    },
    {
      field: "stockAvailable",
      headerName: "Stock Available",
      flex: 1,
      sortable: false,

      width: 50,
      renderCell: (params) => (
        <span>{`${params.row.stockAvailable} ${getUnitsFullForm(
          params.row.unit
        )}`}</span>
      ),
    },
    {
      field: "lastConsumption",
      headerName: "Last Consumption",
      sortable: false,
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.lastConsumption ? (
            <span style={{ color: "black" }}>{` ${
              params.row.lastConsumption
            }  ${getUnitsFullForm(params.row.unit)}`}</span>
          ) : (
            <span>{"NA"}</span>
          )}
          <br />
          {params.row.lastUpdated ? (
            <span style={{ color: "grey", fontSize: "12px" }}>
              Last Updated {params.row.lastUpdated}
            </span>
          ) : (
            <span style={{ color: "grey", fontSize: "12px" }}>
              {"Not Updated Yet"}
            </span>
          )}
        </div>
      ),
    },
    {
      field: "averageConsumption",
      headerName: "Average",
      width: 200,
      sortable: false,
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.consumption ? (
            <span style={{ color: "black" }}>{` ${
              params.row.consumption.value
            }  ${getUnitsFullForm(params.row.unit)}`}</span>
          ) : (
            <span>{"NA"}</span>
          )}
          <br />
          {params.row.consumption ? (
            <span style={{ color: "grey", fontSize: "12px" }}>
              {params.row.consumption.time}
            </span>
          ) : (
            <span style={{ color: "grey", fontSize: "12px" }}>
              {"Not Used Yet"}
            </span>
          )}
        </div>

        // <span>{`${params.row.consumptionPerDay} ${getUnitsFullForm(
        //   params.row.unit
        // )}`}</span>
      ),
    },
    {
      field: "expectedConsumption",
      headerName: "Expected",
      sortable: false,
      width: 200,
      flex: 1,
      renderCell: (params) => (
        <div>
          {params.row.expectedConsumption ? (
            <span style={{ color: "black" }}>{` ${
              params.row.expectedConsumption.value
            }  ${getUnitsFullForm(params.row.unit)}`}</span>
          ) : (
            <span>{"NA"}</span>
          )}
          <br />
          {params.row.expectedConsumption ? (
            <span style={{ color: "grey", fontSize: "12px" }}>
              {params.row.expectedConsumption.time}
            </span>
          ) : (
            <span style={{ color: "grey", fontSize: "12px" }}>
              {"Not Used Yet"}
            </span>
          )}
        </div>
      ),
    },
  ];

  const getRowClassName = (params) => {
    return [
      classes.rowPointer,
      classes.blueBorder,
      params.row.isOverConsumed ? classes.dirtyYellow : null,
    ];
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent plantId={plantId} factoryResetContainer={true}>
        <Box ml={2} mr={2} mt={4}>
          <Box
            ml={3}
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            <Typography variant="h1">{displayInventoryCategoryName}</Typography>
          </Box>
          <Box
            mt={3}
            ml={3}
            mr={3}
            style={{
              display: "flex",
              justifyContent: "center",
              height: "620px",
            }}
          >
            {loading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <DataGrid
                sx={{
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "#F0F9F9",
                  },
                }}
                experimentalFeatures={{ columnGrouping: true }}
                rows={inventoryCategoryData}
                columns={COLUMNS}
                disableColumnMenu={true}
                // disableColumnFilter={true}
                getRowClassName={(params) => getRowClassName(params)}
                loading={loading}
                columnBuffer={2}
                columnGroupingModel={columnGroupingModel}
                onRowClick={(params) => {
                  if (isChemicalPage) {
                    navigate(
                      `/ChemicalLogs?plantId=${plantId}&chemicalId=${params.row.itemId}&chemicalName=${params.row.name}&chemicalUnit=${params.row.unit}`
                    );
                  }
                }}
              />
            )}
          </Box>
        </Box>
      </LayoutComponent>
    </ThemeProvider>
  );
}
