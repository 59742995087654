import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { OtherSensor } from '../../Interfaces/PlantConfigurationInterface';
import { PlantConfigStyle } from './PlantConfigStyle';
import { styledBoxWithShadow } from '../../styles/global/components/dpBox';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { useState } from 'react';
import DiagramSelectComponent from './subComponents/DiagramSelectComponent';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddEquipmentInsideEquipmentTypePopup from '../PlantConfigPopups/EquipmentInsideTypeSelector';
import SubEquipmentViewAndControl from './subEquipment/SubEquipmentViewAndControl';
import SettingsIcon from '@mui/icons-material/Settings';
import EqtInsideEqt from '../../assets/icons/EqtInsideEqt.svg';
import SensorConfigForOtherSensors from './subComponents/SenorConfigForOtherSensors';
interface IProps {
  otherSensorData: OtherSensor[];
  AddOtherSensorConfig: Function;
  RemoveElement: Function;
  updateValue: Function;
  updateOtherSensorTag: Function;
  updateOtherSensorNickname: Function;
  plantConfig: any;
  setPlantConfig: Function;
  TankOptions: Function;
  ValveOptions: Function;
  CoreEquipmentOptions: Function;
  HeaderOptions: Function;
  handleShowOnDiagramCheck: Function;
  tankChoice: any;
  valveChoice: any;
  equipChoice: any;
  headerChoice: any;
  sensorPostFix: string;
}

function OtherSensorsConfig({
  otherSensorData,
  AddOtherSensorConfig,
  RemoveElement,
  updateValue,
  updateOtherSensorTag,
  updateOtherSensorNickname,
  plantConfig,
  setPlantConfig,
  TankOptions,
  ValveOptions,
  CoreEquipmentOptions,
  HeaderOptions,
  tankChoice,
  valveChoice,
  equipChoice,
  headerChoice,
  handleShowOnDiagramCheck,
  sensorPostFix,
}: IProps) {
  const [equipmentIndex, setEquipmentIndex] = useState<Number | null>(null);
  const [equipmentName, setEquipmentName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [equipment, setEquipment] = useState('');
  const [showAddSubEquipment, setShowAddSubEquipment] = useState(false);
  function checkIfSubEquipmentHasData(subEquipments: any) {
    let hasData = false;
    let subEquipmentCategories = Object.keys(subEquipments);
    if (subEquipmentCategories.length > 0) {
      subEquipmentCategories.forEach(category => {
        if (subEquipments[category].length > 0) {
          hasData = true;
        }
      });
    } else {
      hasData = false;
    }
    return hasData;
  }
  return (
    <>
      <Box m={1} p={2}>
        {otherSensorData.map((sensor, index) => {
          return (
            <>
              <Accordion TransitionProps={{ unmountOnExit: true }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '0.6rem',
                    }}
                  >
                    <Typography variant="h4">
                      {sensor.name} {sensor.count}
                    </Typography>
                    <Box sx={{ marginLeft: 'auto' }}>
                      {sensor['subEquipments'] &&
                        checkIfSubEquipmentHasData(sensor['subEquipments']) && (
                          <img
                            src={EqtInsideEqt}
                            style={{ height: '20px' }}
                            alt="Equipment Inside Icon"
                          />
                        )}
                    </Box>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Box m={1} p={1} sx={styledBoxWithShadow}>
                    <Box m={1}>
                      <TextField
                        id={'age' + index}
                        label="Age in months"
                        value={sensor['age']}
                        type="Number"
                        onChange={e =>
                          updateValue({
                            category: 'Other Sensors',
                            index,
                            valuefor: 'age',
                            value: e.target.value,
                          })
                        }
                        inputProps={{
                          maxLength: 1000,
                          step: '1',
                        }}
                        style={PlantConfigStyle.equipmentTextField}
                      />
                    </Box>
                    <Box m={1} p={1}>
                      <TextField
                        value={sensor['nickName']}
                        variant="outlined"
                        label="Sensor NickName"
                        onChange={e => updateOtherSensorNickname(index, e.target.value)}
                        style={PlantConfigStyle.equipmentTextField}
                      />
                    </Box>
                    <DiagramSelectComponent
                      category="Other Sensors"
                      index={index}
                      updateValue={updateValue}
                      layoutDiagram={sensor['layoutDiagram']}
                    />
                    <>
                      {sensor['sensors'].length >= 1 ? (
                        <Box m={1}>
                          <FormLabel component="legend">
                            Sensor Tags for {sensor.name} {sensor.count}
                          </FormLabel>

                          {sensor['sensors'].map((sensor: any, sensorIndex: number) => {
                            return (
                              <SensorConfigForOtherSensors
                                index={index}
                                sensorIndex={sensorIndex}
                                sensor={sensor}
                                updateOtherSensorTag={updateOtherSensorTag}
                              />
                            );
                          })}
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sensor.displayNode}
                                // checked={check}
                                onChange={() => {
                                  handleShowOnDiagramCheck(
                                    'Other Sensors',
                                    index,
                                    !sensor.displayNode
                                  );
                                }}
                                name={'Show on diagram'}
                              />
                            }
                            label={'Show on diagram'}
                          />
                        </Box>
                      ) : (
                        <></>
                      )}
                    </>
                    <div style={PlantConfigStyle.configFormWrapper}>
                      <Button
                        variant="contained"
                        style={PlantConfigStyle.deleteButton}
                        onClick={() => {
                          setShowDeleteModal(true);
                          setEquipmentIndex(index);
                          setEquipmentName(`${sensor.name} ${sensor.count}`);
                          setEquipment('Other Sensors');
                        }}
                      >
                        <DeleteIcon style={PlantConfigStyle.deleteIcon} />
                      </Button>
                      <Button
                        variant="contained"
                        style={PlantConfigStyle.deleteButton}
                        onClick={() => {
                          setShowAddSubEquipment(true);
                          setEquipmentIndex(index);
                          setEquipmentName(`${sensor.name} ${sensor.count}`);
                          setEquipment('Other Sensors');
                        }}
                      >
                        <AddIcon style={PlantConfigStyle.deleteIcon} />
                      </Button>
                    </div>
                  </Box>
                  {sensor['subEquipments'] &&
                    checkIfSubEquipmentHasData(sensor['subEquipments']) && (
                      <>
                        <Box m={1} p={1}>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              margin: '2rem 0',
                            }}
                          >
                            <Typography variant="h4">Sub - Equipments</Typography>
                            <SettingsIcon style={{ paddingLeft: '1rem' }} />
                          </span>
                        </Box>
                        <SubEquipmentViewAndControl
                          parentEquipmentType="Other Sensors"
                          parentEquipmentIndex={index}
                          subEquipments={sensor['subEquipments']}
                          plantConfig={plantConfig}
                          setplantConfig={setPlantConfig}
                          tankChoice={tankChoice}
                          valveChoice={valveChoice}
                          equipChoice={equipChoice}
                          headerChoice={headerChoice}
                          sensorPostFix={sensorPostFix}
                        />
                      </>
                    )}
                </AccordionDetails>
              </Accordion>
            </>
          );
        })}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={`You are deleting the ${equipmentName}`}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            RemoveElement(equipment, equipmentIndex);
            setProcessing(false);
            setShowDeleteModal(false);
          }}
        />
        <AddEquipmentInsideEquipmentTypePopup
          open={showAddSubEquipment}
          handleClose={() => {
            setShowAddSubEquipment(false);
          }}
          plantConfig={plantConfig}
          setPlantConfig={setPlantConfig}
          parentCategory="Other Sensors"
          parentIndex={equipmentIndex!}
          TankOptions={TankOptions}
          ValveOptions={ValveOptions}
          CoreEquipmentOptions={CoreEquipmentOptions}
          HeaderOptions={HeaderOptions}
          sensorPostFix={sensorPostFix}
        />
      </Box>
    </>
  );
}

export default OtherSensorsConfig;
