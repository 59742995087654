// store.ts
import { create } from "zustand";

interface WorkspaceState {
  workspaceName: string;
  selectedClient: string | null;
  setNewWorkspace: (name: string, client: string | null) => void;
}

const useWorkspaceStore = create<WorkspaceState>((set) => ({
  workspaceName: "",
  selectedClient: null,
  setNewWorkspace: (name, client) =>
    set({ workspaceName: name, selectedClient: client }),
}));

export default useWorkspaceStore;
