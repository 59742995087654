//@ts-nocheck
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Grid, Box, Button, Tooltip, Paper, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import BuildIcon from '@mui/icons-material/Build';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';
import DoneIcon from '@mui/icons-material/Done';
import LayoutComponent from '../components/LayoutComponent';
import UserSelectPopup from '../components/UserSelectPopup/UserSelectPopup';
import ReactPlayer from 'react-player';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import ScheduleIcon from '@mui/icons-material/Schedule';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatIcon from '@mui/icons-material/Chat';
import CheckIcon from '@mui/icons-material/Check';
//@ts-ignore
import ModalImage from 'react-modal-image';
import SendIcon from '@mui/icons-material/Send';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import UploadImagePopup from '../components/UploadImagePopup/UserSelectPopup/UploadImagePopup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { addComment, changeTaskStatusService, fetchTaskService } from '../services/expandedView';
import { RowInterface } from '../Interfaces/expandedViews';
import { SubTask } from '../Interfaces/alertTask';
import useAuth from '../utilities/useAuth';
import PerformAction from '../globalComponents/dialogs/PerformAction';
import { useNavigate } from 'react-router-dom';
import useUserStore from '../store/UserStore';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 70,
    width: 200,
  },
  control: {
    padding: theme.spacing(2),
  },
  heading: {
    fontSize: 22,
    fontFamily: 'Lato',
    fontWeight: 'bold',
  },
  dueDate: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: 12,
    color: '#808080',
    fontFamily: 'Lato Regular',
  },
}));

export default function ExpandedView() {
  const { id } = useParams();
  const language = localStorage.getItem('language');
  const classes = useStyles();
  const [taskHeadingEng, setTaskHeadingEng] = useState('');
  const [taskDescriptionEng, setTaskDescriptionEng] = useState('');
  const [taskHeadingHindi, setTaskHeadingHindi] = useState('');
  const [taskDescriptionHindi, setTaskDescriptionHindi] = useState('');
  const [subTasks, setSubTasks] = useState([]);
  const [currentStatus, setCurrentStatus] = useState<any>({});
  const [type, setType] = useState('');
  const [timeLine, setTimeLine] = useState([]);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  // const allowAction = JSON.parse(localStorage.getItem("allowAction"));
  const allowAction = useUserStore().allowAction;
  const [subTaskIndex, setSubTaskIndex] = useState(-1);
  const [overDueDate, setOverDueDate] = useState('');
  const { role } = useAuth();
  // const userid = localStorage.getItem("userid");
  const userid = useUserStore().userId;
  const [comment, setComment] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [imageupload, setImageUpload] = useState(false);
  const [plantName, setPlantName] = useState('');
  const [rows, setRows] = useState<RowInterface[]>([]);
  const [taskRole, setTaskRole] = useState('');
  const [showPerformActionModal, setShowPerformActionModal] = useState(false);
  const [prcoessing, setProcessing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchExpandedTask();
  }, []);

  const fetchExpandedTask = () => {
    setOpenPopUp(false);
    setComment(false);
    setImageUpload(false);
    setCommentText('');
    setSubTaskIndex(-1);
    fetchTaskService(id)
      .then(data => {
        setTaskHeadingEng(data.heading.eng);
        setTaskDescriptionEng(data.description.eng);
        setTaskHeadingHindi(data.heading.hindi);
        setTaskDescriptionHindi(data.description.hind);
        setTimeLine(data.timeLine);
        setCurrentStatus(data.history[data.history.length - 1]);
        setSubTasks(data.subTasks);
        setOverDueDate(data.overDueTime);
        setPlantName(data.plantName);
        if (data.videoUrl) {
          setVideoUrl(data.videoUrl);
        }

        const tempRows = [];
        for (let i = 0; i < data.historyTable.length; i++) {
          let elm = data.historyTable[i];
          tempRows.push(createData(elm.createdAt, elm.event, elm.completedBy, elm.completedAt));
        }
        setRows(tempRows);
        setTaskRole(data.toUser);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const changeTaskStatus = (to: string) => {
    changeTaskStatusService({ taskId: id, status: to })
      .then(data => {
        fetchExpandedTask();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const StartTask = () => {
    if (role === 'Operator Group') {
      setType('Start Task');
      setOpenPopUp(true);
    } else {
      changeTaskStatusService({
        taskId: id,
        status: 'In Progress',
        completedBy: userid,
      })
        .then(data => {
          fetchExpandedTask();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const FinishTask = () => {
    if (role === 'Operator Group') {
      setType('Complete Task');
      setOpenPopUp(true);
    } else {
      changeTaskStatusService({
        taskId: id,
        status: 'Completed',
        completedBy: userid,
      })
        .then(data => {
          fetchExpandedTask();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const FinishSubTask = (index: number) => {
    if (role === 'Operator Group') {
      setSubTaskIndex(index);
      setType('Complete Sub Task');
      setOpenPopUp(true);
    } else {
      changeTaskStatusService({
        taskId: id,
        status: 'Completed',
        completedBy: userid,
        index: index,
      })
        .then(data => {
          fetchExpandedTask();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const sendComment = () => {
    if (role === 'Operator Group') {
      setType('Add Comment');
      setOpenPopUp(true);
    } else {
      addComment({
        taskId: id,
        commentedBy: userid,
        comment: commentText,
      }).then(({ data }) => {
        fetchExpandedTask();
      });
    }
  };

  function createData(createdAt: string, event: string, completedBy: string, completedAt: string) {
    return { createdAt, event, completedBy, completedAt };
  }

  return (
    <div>
      <LayoutComponent plantId={id}>
        <Grid className={classes.root} spacing={2}>
          <Grid item xs={12}>
            <Box bgcolor="#ADD8E6" p={2}>
              <Grid container xs={12}>
                <Grid item xs={10}>
                  <Box p={0.5}>
                    <div className={classes.heading}> {plantName}</div>
                    <div className={classes.heading}>
                      {language === 'English' ? <>{taskHeadingEng}</> : <>{taskHeadingHindi}</>}
                    </div>
                  </Box>
                  <Box p={0.5}>
                    <div className={classes.dueDate}>
                      <EventIcon fontSize="small" />
                      {moment(overDueDate, 'DD:MM:yyyy HH:mm:ss').format('llll')}
                    </div>
                  </Box>
                  <Box p={0.5}>
                    <div>
                      {currentStatus.status === 'In Progress' ? (
                        <>Started By:{currentStatus.completedBy}</>
                      ) : null}
                      {currentStatus.status === 'Overwritten' ? (
                        <>Task has been OverWritten by another Task</>
                      ) : null}
                      {currentStatus.status === 'Completed' ? (
                        <>Completed By:{currentStatus.completedBy}</>
                      ) : null}
                    </div>
                  </Box>
                  <div>
                    <>
                      {currentStatus.status === 'Pending' ? (
                        <>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: '#519BD0',
                            }}
                            onClick={() => {
                              if (allowAction) {
                                StartTask();
                              } else {
                                setShowPerformActionModal(true);
                              }
                            }}
                          >
                            Start
                          </Button>
                        </>
                      ) : null}

                      {currentStatus.status === 'In Progress' ? (
                        <>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: '#EB5A46',
                            }}
                            onClick={() => {
                              if (allowAction) {
                                changeTaskStatus('Pending');
                              } else {
                                setShowPerformActionModal(true);
                              }
                            }}
                          >
                            Un-Start
                          </Button>
                          <Button
                            variant="contained"
                            style={{
                              backgroundColor: '#70B500',
                            }}
                            onClick={() => {
                              if (allowAction) {
                                FinishTask();
                              } else {
                                setShowPerformActionModal(true);
                              }
                            }}
                          >
                            Finish
                          </Button>
                        </>
                      ) : null}
                    </>
                  </div>
                </Grid>
                <Grid item xs={2} style={{ justifyContent: 'center' }}>
                  <Box p={0.5}>
                    <div
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {currentStatus.status === 'Completed' ? (
                        <>
                          <Tooltip title="Task is Completed">
                            <DoneAllIcon style={{ color: '#70B500' }} />
                          </Tooltip>
                        </>
                      ) : null}
                      {currentStatus.status === 'Pending' ? (
                        <>
                          <Tooltip title="Task is Pending">
                            <TimelapseIcon style={{ color: '#F2D600' }} />
                          </Tooltip>
                        </>
                      ) : null}
                      {currentStatus.status === 'In Progress' ? (
                        <>
                          <Tooltip title="Task is in Progress">
                            <BuildIcon />
                          </Tooltip>
                        </>
                      ) : null}
                    </div>
                    <div
                      style={{
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {currentStatus.overDue === true ? (
                        <>
                          <Tooltip title="Task is Overdue">
                            <PriorityHighIcon style={{ color: '#EB5A46' }} fontSize="large" />
                          </Tooltip>
                        </>
                      ) : null}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2}>
              <Paper elevation={10}>
                <Grid container xs={12}>
                  <div style={{ fontFamily: 'Lato Bold' }}>
                    <Box color="gray" p={1}>
                      <strong>Description:</strong>
                    </Box>
                  </div>
                  <Box m={1}>
                    <div style={{ fontFamily: 'Lato Regular' }}>
                      {language === 'English' ? (
                        <>{taskDescriptionEng}</>
                      ) : (
                        <>{taskDescriptionHindi}</>
                      )}
                    </div>
                  </Box>
                </Grid>
              </Paper>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2}>
              <Paper elevation={10}>
                <Grid container xs={12}>
                  <div style={{ fontFamily: 'Lato Bold' }}>
                    <Box color="gray" p={1}>
                      <strong>Sub-Tasks:</strong>
                    </Box>
                  </div>
                  <Box m={1}>
                    <div style={{ fontFamily: 'Lato Regular' }}>
                      {subTasks.map((subTask: SubTask, index: number) => {
                        return (
                          <Box p={1}>
                            <Grid item xs={10}>
                              {language === 'English' ? (
                                <>{subTask.name}</>
                              ) : (
                                <>{subTask.namehindi}</>
                              )}
                            </Grid>
                            <Grid item xs={2}>
                              <>
                                {subTask['status'] === 'Pending' &&
                                currentStatus.status !== 'Completed' ? (
                                  <Tooltip title="Mark Sub-Task as Completed" aria-label="add">
                                    <Button
                                      variant="contained"
                                      style={{
                                        backgroundColor: '#519BD0',
                                      }}
                                      onClick={() => {
                                        if (allowAction) {
                                          FinishSubTask(index);
                                        } else {
                                          setShowPerformActionModal(true);
                                        }
                                      }}
                                    >
                                      <DoneIcon fontSize="small" />
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <>
                                    <Tooltip title="Sub-Task is completed" aria-label="add">
                                      <DoneAllIcon style={{ color: '#70B500' }} />
                                    </Tooltip>
                                  </>
                                )}
                              </>
                            </Grid>
                          </Box>
                        );
                      })}
                    </div>
                  </Box>
                </Grid>
              </Paper>
            </Box>
          </Grid>
        </Grid>

        <>
          <Box m={1} p={1}>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#519BD0',
              }}
              onClick={() => {
                if (allowAction) {
                  setComment(!comment);
                } else {
                  setShowPerformActionModal(true);
                }
              }}
            >
              <ChatIcon />
              Comment
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (allowAction) {
                  setImageUpload(true);
                } else {
                  setShowPerformActionModal(true);
                }
              }}
              style={{
                backgroundColor: '#519BD0',
              }}
            >
              <AttachFileIcon />
              Attach Image
            </Button>
          </Box>
          {comment ? (
            <Box m={1} p={1}>
              <Grid container>
                <Grid item xs={10} sm={10} lg={10} md={10}>
                  <TextField
                    fullWidth
                    label="Comment"
                    variant="outlined"
                    multiline={true}
                    onChange={e => {
                      setCommentText(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={2} lg={2} md={2}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: '#519BD0',
                    }}
                    onClick={sendComment}
                  >
                    <SendIcon />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </>

        <Box m={1} p={1}>
          <Timeline align="alternate">
            {timeLine.map((item, index: number) => {
              return (
                <TimelineItem>
                  <TimelineOppositeContent>
                    <Typography variant="body2" color="textSecondary">
                      {item.time}
                    </Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot style={{ backgroundColor: '#519BD0' }}>
                      {item.event === 'created' && <ScheduleIcon />}
                      {item.event === 'comment' && <ChatIcon />}
                      {item.event === 'attachment' && <AttachFileIcon />}
                      {item.event === 'closed' && <CheckIcon />}
                      {item.event === 'inProgress' && <BuildIcon />}
                      {item.event === 'escalated' && <PriorityHighIcon />}
                      {item.event === 'Overwritten' && <NoteAltIcon />}
                    </TimelineDot>
                    {index === timeLine.length - 1 ? null : <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent>
                    <Paper elevation={3}>
                      <Box m={1} p={1}>
                        <div style={{ fontFamily: 'Lato Bold', fontSize: 12 }}>{item.message}</div>
                        {item.event === 'comment' ? (
                          <div style={{ textAlign: 'center' }}>
                            <Box m={1} p={1} bgcolor="#D3D3D3" borderRadius={5}>
                              <div
                                style={{
                                  fontFamily: 'Lato Regular',
                                  fontSize: 10,
                                }}
                              >
                                {item.comment}
                              </div>
                            </Box>
                          </div>
                        ) : null}
                        {item.event === 'attachment' ? (
                          <div style={{ textAlign: 'center' }}>
                            <Box m={1} p={1} bgcolor="#D3D3D3" borderRadius={5}>
                              <ModalImage small={item.src} large={item.src} alt={item.message} />
                            </Box>
                          </div>
                        ) : null}
                      </Box>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Box>

        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* <TableCell>Dessert (100g serving)</TableCell> */}
                <TableCell>CreatedAt</TableCell>
                <TableCell align="right">Event</TableCell>
                <TableCell align="right">completedBy</TableCell>
                <TableCell align="right">CompletedAt</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  {/* <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell> */}
                  <TableCell>{row.createdAt}</TableCell>
                  <TableCell align="right">{row.event}</TableCell>
                  <TableCell align="right">{row.completedBy}</TableCell>
                  <TableCell align="right">{row.completedAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {videoUrl !== '' ? (
          <Grid item xs={12}>
            <Box p={2}>
              <Paper elevation={10}>
                <div style={{ fontFamily: 'Lato Bold' }}>
                  <Box color="gray" p={1}>
                    <strong>Training Video:</strong>
                  </Box>
                </div>
                <ReactPlayer url={videoUrl} controls={true} width="100%" />
              </Paper>
            </Box>
          </Grid>
        ) : null}
      </LayoutComponent>
      {openPopUp ? (
        <UserSelectPopup
          type={type}
          handleClose={() => {
            setOpenPopUp(false);
          }}
          refresh={fetchExpandedTask}
          taskId={id}
          subTaskindex={subTaskIndex}
          comment={commentText}
          toUser={taskRole}
        />
      ) : null}
      {imageupload ? (
        <UploadImagePopup
          taskId={id}
          refresh={fetchExpandedTask}
          handleClose={() => {
            setImageUpload(false);
          }}
          role={role}
          id={userid}
        />
      ) : null}
      {showPerformActionModal && (
        <PerformAction
          showPerformActionModal={showPerformActionModal}
          setShowPerformActionModal={setShowPerformActionModal}
          alertMessage="You are not checked-in. Please check-in to perform this action."
          processing={prcoessing}
          setProcessing={setProcessing}
          negativeButtonText="cancel"
          positiveButtonText="Check In"
          onConfirmAction={() => {
            setProcessing(false);
            navigate('/checkIn');
          }}
        />
      )}
    </div>
  );
}
