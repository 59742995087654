import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import * as React from 'react';
import EditIcon from '@mui/icons-material/FiberManualRecord';
import { blue } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MaintenanceTimeLineComponent from '../components/maintenanceTimeLineComponent';
import HistoryIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import { maintenaceData, timeLineEvent } from '../Interfaces/maintenaceInterfaces';
import { getMaintenanceData } from '../services/maintenance';

const styles = {
  pageHeading: {
    fontFamily: 'Montserrat Bold',
    fontSize: 30,
    paddingTop: '5px',
  },
  accordion: {
    boxShadow: '0 1px 1px #F5F5F5',
    marginTop: '8px',
    background: '#F5F5F5',
  },
  isOperationalIcon: {
    color: '#65a765',
    paddingTop: '4px',
    paddingLeft: '2px',
    paddingRight: '10px',
    width: '20px',
  },
  isNotOperationIcon: {
    color: '#FF7F7F',
    paddingTop: '4px',
    paddingLeft: '2px',
    paddingRight: '10px',
    width: '20px',
  },
  operationalStatusUnknownIcon: {
    color: '#B0B0B0',
    paddingTop: '4px',
    paddingLeft: '2px',
    paddingRight: '10px',
    width: '20px',
  },
  equipmentName: {
    fontFamily: 'Montserrat SemiBold',
    fontSize: 18,
    opacity: '85%',
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  loadingSkeleton: {
    margin: '20px',
    width: '95%',
    height: '500px',
  },
};

export default function Maintenance() {
  const { id } = useParams();

  const [maintenanceData, setMaintenanceData] = useState<maintenaceData | null>(null);
  const [plantNames, setPlantNames] = React.useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getMaintenanceData(id!)
      .then(data => {
        setMaintenanceData(data);
        setPlantNames(Object.keys(data));
        setLoading(false);
      })
      .catch(err => console.log('Error : ', err));
  }, [id]);

  const [open, setOpen] = React.useState(false);
  const [selectedTimeLine, setSelectedTimeLine] = React.useState<timeLineEvent[]>([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const SeeHistoryButton = (props: { timeLine: timeLineEvent[] }) => {
    const { timeLine } = props;

    const openTimeLinePopup = () => {
      if (timeLine) {
        setSelectedTimeLine(timeLine);
        handleClickOpen();
      }
    };

    return (
      <Button
        onClick={e => {
          e.stopPropagation();
          openTimeLinePopup();
        }}
        style={{ color: blue[500] }}
        endIcon={<HistoryIcon />}
      >
        See history
      </Button>
    );
  };

  const columns: GridColDef[] = [
    { field: 'actionName', headerName: 'Action Name', width: 360 },
    {
      field: 'actionGeneratedOn',
      headerName: 'Action generated on',
      width: 200,
    },
    {
      field: 'actionCompletedOn',
      headerName: 'Action completd on',
      width: 200,
    },
    {
      field: 'issueType',
      headerName: 'Action Type',
      width: 200,
    },
    {
      field: 'history',
      headerName: 'History',
      sortable: false,
      width: 140,
      renderCell: (params: GridCellParams) => {
        console.log('params : ', params);
        return (
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ cursor: 'pointer' }}
          >
            {/* <MatEdit index={params.row.id} /> */}
            <SeeHistoryButton timeLine={params.row.timeLine} />
          </div>
        );
      },
    },
  ];

  return (
    <LayoutComponent plantId={id}>
      <Box m={1} p={0}>
        <div style={styles.pageHeading}>Maintenance</div>
      </Box>
      {loading && <Skeleton variant="rectangular" style={styles.loadingSkeleton} />}
      {maintenanceData && !loading && (
        <Box className="contianer">
          <Box m={0} p={0}>
            <div>
              <Dialog
                style={{ overflow: 'scroll', height: '100%' }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle></DialogTitle>
                <DialogContent>
                  <div>
                    <CloseIcon onClick={handleClose}></CloseIcon>
                    <MaintenanceTimeLineComponent timeLine={selectedTimeLine} />
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </Box>
          <Box p={1} />
          {plantNames.map(plantName => {
            let Equipments = maintenanceData[plantName];
            console.log('plantName : ', plantName);
            return (
              <>
                <Box p={1}>
                  {' '}
                  <h2>{plantName}</h2>{' '}
                </Box>
                {Equipments.map(data => {
                  return (
                    <Box p={1}>
                      <Accordion
                        TransitionProps={{ mountOnEnter: true, timeout: 500 }}
                        sx={styles.accordion}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon style={{ color: '#519BD0' }}></ExpandMoreIcon>
                          }
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                        >
                          {data?.isOperational == true && (
                            <EditIcon style={styles.isOperationalIcon}></EditIcon>
                          )}
                          {data?.isOperational === false && (
                            <EditIcon style={styles.isNotOperationIcon}></EditIcon>
                          )}
                          {data?.isOperational == null && (
                            <EditIcon style={styles.operationalStatusUnknownIcon}></EditIcon>
                          )}
                          <div style={styles.equipmentName}>{data.title}</div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            {/* <div style={{ fontSize: 14, marginLeft: "15px" }}>Hours Run : {data.hoursRun}</div> */}
                            {data.id.includes('Pump') || data.id.includes('Blower') ? (
                              <Box>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>make</TableCell>
                                      <TableCell align="right">model</TableCell>
                                      <TableCell align="right">
                                        {data.id.includes('Pump') ? 'Pump Type' : 'Capacity'}
                                      </TableCell>
                                      <TableCell align="right">age</TableCell>
                                      {/* <TableCell align="right">Hours Run</TableCell> */}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                      }}
                                    >
                                      <TableCell>{data.make}</TableCell>
                                      <TableCell align="right">{data.model}</TableCell>
                                      <TableCell align="right">
                                        {data.id.includes('Pump') ? data.type : data.capacity}
                                      </TableCell>
                                      <TableCell align="right">{data.age}</TableCell>
                                      {/* <TableCell align="right">{data.hoursRun}</TableCell> */}
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            ) : null}
                            <DataGrid
                              style={{ borderBlockWidth: '6px' }}
                              autoHeight
                              rows={data.history}
                              columns={columns}
                            />
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  );
                })}
              </>
            );
          })}
        </Box>
      )}
    </LayoutComponent>
  );
}
