import React, { Dispatch, SetStateAction } from 'react';
import { Box, TextField, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import BugReportIcon from '@mui/icons-material/BugReport';
import SettingsIcon from '@mui/icons-material/Settings';
import { pageConfigDataInterface } from '../../Interfaces/dashboardPageInterfaces';
import { widgetInterface } from '../../Interfaces/widgetInterfaces';
import DeleteIcon from '@mui/icons-material/Delete';

const styles = {
  metricConfigAccordionDetailsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  metricAdvancedSettingAccordion: {
    background: 'white',
    borderRadius: '5px',
    border: '1px solid black',
  },
  metricAdvancedSettingAccordionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
};

interface metricConfigV2PropsInterface {
  pageData: pageConfigDataInterface;
  setPageData: Dispatch<SetStateAction<pageConfigDataInterface | null>>;
  widgetClusterIndex: string;
  widget: widgetInterface;
  widgetIndex: number;
  metric: string;
  initiateMetricDiagnosis: Function;
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  refreshStandAloneWidget: boolean;
  setRefreshStandAloneWidget: Dispatch<SetStateAction<boolean>>;
  setAreMultipleSensorSelected: Function;
}

export default function MetricConfigComponentV2(props: metricConfigV2PropsInterface) {
  const {
    pageData,
    setPageData,
    widgetClusterIndex,
    widget,
    widgetIndex,
    metric,
    initiateMetricDiagnosis,
    refresh,
    setRefresh,
    setRefreshStandAloneWidget,
    setAreMultipleSensorSelected,
  } = props;
  const valueTypes = ['raw', 'avg', 'min', 'max', 'timeWeightedSum', 'cumulative'];
  const tickWidgetMetric = (
    widgetClusterIndex: string,
    widgetIndex: number,
    metric: string,
    checked: any
  ) => {
    var temp: pageConfigDataInterface = pageData;
    console.log(widgetClusterIndex, widgetIndex, metric, checked);
    temp.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric].checked = checked;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(lastState => !lastState);
  };

  const changeMetricProperty = (
    widgetClusterIndex: string,
    widgetIndex: number,
    metric: string,
    property: string,
    value: any
  ) => {
    var temp: pageConfigDataInterface = pageData;
    console.log(widgetClusterIndex, widgetIndex, metric, property, value);
    temp.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(lastState => !lastState);
  };

  const deleteMetric = () => {
    delete pageData.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric];
    if (Object.keys(pageData.data[widgetClusterIndex].widgets[widgetIndex].metrics).length <= 1) {
      setAreMultipleSensorSelected(false);
    }
    setPageData(pageData);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(lastState => !lastState);
  };

  const changeMetricValueTypeCustomisation = (
    widgetClusterIndex: string,
    widgetIndex: number,
    metric: string,
    valueType: string,
    key: string,
    value: string
  ) => {
    var temp: pageConfigDataInterface = pageData;
    if (temp.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric]['valueTypeCust'] == null)
      temp.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric]['valueTypeCust'] = {
        raw: {},
        avg: {},
        min: {},
        max: {},
        cumulative: {},
        timeWeightedSum: {},
      };
      if (
        temp.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric]['valueTypeCust'][
          valueType
        ] == null
      ) {
        temp.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric]['valueTypeCust'][
          valueType
        ] = {};
      }
    temp.data[widgetClusterIndex].widgets[widgetIndex].metrics[metric]['valueTypeCust'][valueType][
      key
    ] = value;
    setPageData(temp);
    setRefresh(!refresh);
    setRefreshStandAloneWidget(lastState => !lastState);
  };

  return (
    <Box mt={1}>
      <Accordion
        sx={{
          background: 'inherit',
        }}
        elevation={0}
        disableGutters={true}
        TransitionProps={{ mountOnEnter: true }}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
          expandIcon={<ExpandMoreIcon />}
          sx={{
            '& .MuiAccordionSummary-content': {
              padding: '0px !important',
              margin: '0px !important',
            },
            '&.MuiAccordionSummary-root': {
              margin: '0px !important',
              padding: '0px !important',
            },
            width: '100%',
          }}
        >
          <Stack
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            sx={{ width: '100%' }}
          >
            <Checkbox
              checked={widget.metrics[metric].checked}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                tickWidgetMetric(widgetClusterIndex, widgetIndex, metric, event.target.checked);
              }}
            />
            {/* {metric} */}
            <Tooltip title={widget.metrics[metric].nickName ? metric : ''}>
              <span
                style={
                  widget.metrics[metric].nickName
                    ? { color: 'green', maxWidth: '250px', overflowWrap: 'break-word' }
                    : { maxWidth: '250px', overflowWrap: 'break-word' }
                }
              >
                {widget.metrics[metric].nickName ? widget.metrics[metric].nickName : metric}
              </span>
            </Tooltip>
            <DeleteIcon fontSize="small" sx={{ marginLeft: 'auto' }} onClick={deleteMetric} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box m={1} sx={styles.metricConfigAccordionDetailsContainer}>
            {/* <p>* select the type of value you want to see</p> */}
            <FormLabel style={{ marginBottom: '10px' }} component="legend">
              * select the type of value you want to see
            </FormLabel>
            <Autocomplete
              multiple
              id="tags-filled"
              options={widget.allowedValueTypes ? widget.allowedValueTypes : valueTypes}
              // getOptionLabel={(option) => option.title}
              // defaultValue={ [valueTypes[0]] }
              filterSelectedOptions
              value={widget.metrics[metric].valueTypes ?? widget.valueTypes}
              onChange={(e, newValue) => {
                changeMetricProperty(
                  widgetClusterIndex,
                  widgetIndex,
                  metric,
                  'valueTypes',
                  newValue
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  // label="value types you wanna see"
                  placeholder=""
                />
              )}
            />
            <Accordion
              style={styles.metricAdvancedSettingAccordion}
              elevation={0}
              TransitionProps={{
                mountOnEnter: true,
              }}
            >
              <AccordionSummary
                aria-controls="panel1a-content"
                id="panel1a-header"
                // expandIcon={<ExpandMoreIcon />}
                expandIcon={<SettingsIcon fontSize="small" />}
              >
                Advanced Settings
              </AccordionSummary>
              <AccordionDetails sx={styles.metricAdvancedSettingAccordionDetails}>
                <TextField
                  type="text"
                  defaultValue={widget.metrics[metric]?.['valueTypeCust']?.['raw']?.nickName}
                  variant="standard"
                  label="raw"
                  placeholder="nickname for raw value"
                  multiline
                  inputProps={{
                    step: '0.01',
                    rows: 1,
                  }}
                  fullWidth
                  onBlur={e => {
                    changeMetricValueTypeCustomisation(
                      widgetClusterIndex,
                      widgetIndex,
                      metric,
                      'raw',
                      'nickName',
                      e.target.value
                    );
                  }}
                />
                <TextField
                  type="text"
                  defaultValue={widget.metrics[metric]?.['valueTypeCust']?.['avg']?.nickName}
                  variant="standard"
                  label="avg"
                  placeholder="nickname for avg value"
                  multiline
                  inputProps={{
                    step: '0.01',
                    rows: 1,
                  }}
                  fullWidth
                  onBlur={e => {
                    changeMetricValueTypeCustomisation(
                      widgetClusterIndex,
                      widgetIndex,
                      metric,
                      'avg',
                      'nickName',
                      e.target.value
                    );
                  }}
                />
                <TextField
                  type="text"
                  defaultValue={widget.metrics[metric]?.['valueTypeCust']?.['min']?.nickName}
                  variant="standard"
                  label="min"
                  placeholder="nickname for min value"
                  multiline
                  inputProps={{
                    step: '0.01',
                    rows: 1,
                  }}
                  fullWidth
                  onBlur={e => {
                    changeMetricValueTypeCustomisation(
                      widgetClusterIndex,
                      widgetIndex,
                      metric,
                      'min',
                      'nickName',
                      e.target.value
                    );
                  }}
                />
                <TextField
                  type="text"
                  defaultValue={widget.metrics[metric]?.['valueTypeCust']?.['max']?.nickName}
                  variant="standard"
                  label="max"
                  placeholder="nickname for max value"
                  multiline
                  inputProps={{
                    step: '0.01',
                    rows: 1,
                  }}
                  fullWidth
                  onBlur={e => {
                    changeMetricValueTypeCustomisation(
                      widgetClusterIndex,
                      widgetIndex,
                      metric,
                      'max',
                      'nickName',
                      e.target.value
                    );
                  }}
                />
                <TextField
                  type="text"
                  defaultValue={widget.metrics[metric]?.['valueTypeCust']?.['cumulative']?.nickName}
                  variant="standard"
                  label="cum"
                  placeholder="nickname for cumulative value"
                  multiline
                  inputProps={{
                    step: '0.01',
                    rows: 1,
                  }}
                  fullWidth
                  onBlur={e => {
                    changeMetricValueTypeCustomisation(
                      widgetClusterIndex,
                      widgetIndex,
                      metric,
                      'cumulative',
                      'nickName',
                      e.target.value
                    );
                  }}
                />
                <TextField
                  type="text"
                  defaultValue={
                    widget.metrics[metric]?.['valueTypeCust']?.['timeWeightedSum']?.nickName
                  }
                  variant="standard"
                  label="time weighted sum"
                  placeholder="nickname for tws value"
                  multiline
                  inputProps={{
                    step: '0.01',
                    rows: 1,
                  }}
                  fullWidth
                  onBlur={e => {
                    changeMetricValueTypeCustomisation(
                      widgetClusterIndex,
                      widgetIndex,
                      metric,
                      'timeWeightedSum',
                      'nickName',
                      e.target.value
                    );
                  }}
                />
                <TextField
                  type="number"
                  defaultValue={widget.metrics[metric]?.metricOrder}
                  variant="standard"
                  label="metricOrder"
                  placeholder="Metric Order"
                  inputProps={{}}
                  fullWidth
                  onBlur={(
                    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
                  ) => {
                    changeMetricProperty(
                      widgetClusterIndex,
                      widgetIndex,
                      metric,
                      'metricOrder',
                      e.target.value
                    );
                  }}
                />
              </AccordionDetails>
            </Accordion>

            <Button
              variant="contained"
              fullWidth
              style={{
                background: 'black',
                color: 'white',
              }}
              onClick={() => {
                initiateMetricDiagnosis(widget.metrics[metric].sensorId);
              }}
            >
              <BugReportIcon /> Run Diagnosis
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
