import { v4 as uuidv4 } from 'uuid';

const fakeUserData = [
  {
    _id: '66a0f5f3a7f9548cd70b7f7f',
    name: 'park view spa 1',
    itemId: uuidv4(),
    children: [
      {
        _id: '620cf68a15f5530706eef968',
        name: 'Unknown Asset',
        itemId: uuidv4(),
        roles: [
          {
            itemId: uuidv4(),
            _id: '66a8f87b3aebe9e50ab88ac1',
            name: 'abc',
            permissionTags: [
              'Add-EditUser_Configuration_AE',
              'RoleList_View_R',
              'SystemRole_Manage_WE',
              'GroupRole_Manage_WE',
              'Check-in_Usage_WE',
            ],
            defaultHomepage: 'Unified Dashboard',
            assignedGroup: '66a101bf050c3abd5dd7ed70',
            description: 'testing description',
            roleScope: 'Group',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-07-30T14:28:11.781Z',
            updatedAt: '2024-08-09T18:21:33.009Z',
            __v: 0,
            selected: true,
          },
          {
            _id: '66aa52057c36e77d552ec5c2',
            name: 'asd',
            itemId: uuidv4(),

            permissionTags: [
              'Group_View_R',
              'Group_Manage_A',
              'Group_Manage_D',
              'Group_Configuration_WE',
              'PlantsList_Configuration_A',
              'PlantsList_Configuration_E',
              'PlantsList_Configuration_D',
              'PlantsList_View_R',
              'FixedTask_Templating_WE',
              'AlertTask_Templating_WE',
              'UnifiedDashboardList_Manage_AE',
            ],
            defaultHomepage: 'Unified Dashboard',
            assignedGroup: '66a101bf050c3abd5dd7ed70',
            description: '',
            roleScope: 'Group',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-07-31T15:02:29.123Z',
            updatedAt: '2024-08-14T06:57:44.772Z',
            __v: 0,
            selected: false,
          },
          {
            _id: '66ae59ca5c16c870f5176f38',
            name: 'testingtttr',
            itemId: uuidv4(),

            permissionTags: [
              'Add-EditUser_Configuration_AE',
              'DeleteUser_Manage_D',
              'UserList_View_R',
              'PermissionsList_View_R',
              'Check-in_Usage_WE',
              'ManualTickets_Usage_WE',
              'AlertTask_Templating_WE',
            ],
            defaultHomepage: 'Unified Dashboard',
            assignedGroup: '66a101bf050c3abd5dd7ed70',
            description: 'testing description',
            roleScope: 'Group',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-08-03T16:24:42.786Z',
            updatedAt: '2024-08-17T18:32:36.095Z',
            __v: 0,
            selected: false,
          },
          {
            _id: '66b4ca7ba170ea31f0e39d45',
            name: 'testing admin2',
            itemId: uuidv4(),

            permissionTags: [
              'ManualTickets_Configuration_WE',
              'ManualTickets_Usage_WE',
              'Workspace_View_R',
              'Workspace_Configuration_WE',
              'Workspace_Manage_A',
            ],
            defaultHomepage: 'Dashboard',
            assignedGroup: '66a101bf050c3abd5dd7ed70',
            description: 'testing description',
            roleScope: 'Group',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-08-08T13:39:07.376Z',
            updatedAt: '2024-08-11T05:50:37.462Z',
            __v: 0,
            selected: false,
          },
          {
            _id: '66a8cc457c36e77d552e4086',
            name: 'sdhsbhsddhff',
            itemId: uuidv4(),

            permissionTags: [
              'PlantDetails_View_R',
              'PlantDetails_Configuration_WE',
              'PlantLayout_View_R',
              'PlantLayout_Configuration_WE',
              'SensorDetails_View_R',
              'SensorDetails_Configuration_WE',
              'PlantMaintaince_View_R',
              'PlantMaintaince_Configuration_WE',
              'PlantMaintaince_Monitor_R',
              'PlantMaintaince_Usage_W',
              'PlantLabTestResults_View_R',
              'PlantLabTestResults_Configuration_WE',
              'PlantLabTestResults_Monitor_R',
              'PlantLabTestResults_Usage_W',
              'WaterQualityTestResults_View_R',
              'WaterQualityTestResults_Configuration_WE',
              'WaterQualityTestResults_Monitor_R',
              'WaterQualityTestResults_Usage_W',
              'PlantEscalation_View_R',
              'PlantEscalation_Configuration_WE',
              'FixedTask_View_R',
              'FixedTask_Configuration_WE',
              'FixedTask_Usage_W',
              'Formula_View_R',
              'Formula_Configuration_WE',
              'AlertTask_View_R',
              'AlertTask_Configuration_W',
              'OneTimeTask_View_R',
              'OneTimeTask_Configuration_W',
              'OneTimeTask_Usage_W',
            ],
            defaultHomepage: 'Dashboard',
            assignedGroup: null,
            description: 'ekjej.',
            roleScope: 'System',
            roleType: 'Asset',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-07-30T11:19:33.861Z',
            updatedAt: '2024-07-31T12:18:39.214Z',
            __v: 0,
            selected: false,
          },
          {
            _id: '66b4ca39a170ea31f0e39d3c',
            name: 'testing admin',
            itemId: uuidv4(),

            permissionTags: [
              'PlantsList_Configuration_A',
              'PlantsList_Configuration_E',
              'PlantsList_Configuration_D',
              'PlantsList_View_R',
              'PLC_Configuration_AE',
              'PLC_Configuration_D',
              'PLC_Configuration_R',
              'ForgetPassword_Usage_WE',
              'AutoGenerateTickets_View_WE',
              'ManualTickets_Configuration_WE',
              'ManualTickets_Usage_WE',
            ],
            defaultHomepage: 'Plant List',
            assignedGroup: null,
            description: 'All the assets in Gurugram',
            roleScope: 'System',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-08-08T13:38:01.750Z',
            updatedAt: '2024-08-09T17:50:10.934Z',
            __v: 0,
            selected: false,
          },
          {
            roleIds: ['66a8f87b3aebe9e50ab88ac1'],
          },
        ],
        children: [],
      },
    ],
  },
  {
    _id: '66a0f5f3a7f9548cd70b7f7f',
    name: 'park view spa 2',
    itemId: uuidv4(),
    children: [
      {
        _id: '620cf68a15f5530706eef968',
        name: 'Unknown Asset',
        itemId: uuidv4(),
        roles: [
          {
            itemId: uuidv4(),
            _id: '66a8f87b3aebe9e50ab88ac1',
            name: 'abc',
            permissionTags: [
              'Add-EditUser_Configuration_AE',
              'RoleList_View_R',
              'SystemRole_Manage_WE',
              'GroupRole_Manage_WE',
              'Check-in_Usage_WE',
            ],
            defaultHomepage: 'Unified Dashboard',
            assignedGroup: '66a101bf050c3abd5dd7ed70',
            description: 'testing description',
            roleScope: 'Group',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-07-30T14:28:11.781Z',
            updatedAt: '2024-08-09T18:21:33.009Z',
            __v: 0,
            selected: true,
          },
          {
            _id: '66aa52057c36e77d552ec5c2',
            name: 'asd',
            itemId: uuidv4(),

            permissionTags: [
              'Group_View_R',
              'Group_Manage_A',
              'Group_Manage_D',
              'Group_Configuration_WE',
              'PlantsList_Configuration_A',
              'PlantsList_Configuration_E',
              'PlantsList_Configuration_D',
              'PlantsList_View_R',
              'FixedTask_Templating_WE',
              'AlertTask_Templating_WE',
              'UnifiedDashboardList_Manage_AE',
            ],
            defaultHomepage: 'Unified Dashboard',
            assignedGroup: '66a101bf050c3abd5dd7ed70',
            description: '',
            roleScope: 'Group',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-07-31T15:02:29.123Z',
            updatedAt: '2024-08-14T06:57:44.772Z',
            __v: 0,
            selected: false,
          },
          {
            _id: '66ae59ca5c16c870f5176f38',
            name: 'testingtttr',
            itemId: uuidv4(),

            permissionTags: [
              'Add-EditUser_Configuration_AE',
              'DeleteUser_Manage_D',
              'UserList_View_R',
              'PermissionsList_View_R',
              'Check-in_Usage_WE',
              'ManualTickets_Usage_WE',
              'AlertTask_Templating_WE',
            ],
            defaultHomepage: 'Unified Dashboard',
            assignedGroup: '66a101bf050c3abd5dd7ed70',
            description: 'testing description',
            roleScope: 'Group',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-08-03T16:24:42.786Z',
            updatedAt: '2024-08-17T18:32:36.095Z',
            __v: 0,
            selected: false,
          },
          {
            _id: '66b4ca7ba170ea31f0e39d45',
            name: 'testing admin2',
            itemId: uuidv4(),

            permissionTags: [
              'ManualTickets_Configuration_WE',
              'ManualTickets_Usage_WE',
              'Workspace_View_R',
              'Workspace_Configuration_WE',
              'Workspace_Manage_A',
            ],
            defaultHomepage: 'Dashboard',
            assignedGroup: '66a101bf050c3abd5dd7ed70',
            description: 'testing description',
            roleScope: 'Group',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-08-08T13:39:07.376Z',
            updatedAt: '2024-08-11T05:50:37.462Z',
            __v: 0,
            selected: false,
          },
          {
            _id: '66a8cc457c36e77d552e4086',
            name: 'sdhsbhsddhff',
            itemId: uuidv4(),

            permissionTags: [
              'PlantDetails_View_R',
              'PlantDetails_Configuration_WE',
              'PlantLayout_View_R',
              'PlantLayout_Configuration_WE',
              'SensorDetails_View_R',
              'SensorDetails_Configuration_WE',
              'PlantMaintaince_View_R',
              'PlantMaintaince_Configuration_WE',
              'PlantMaintaince_Monitor_R',
              'PlantMaintaince_Usage_W',
              'PlantLabTestResults_View_R',
              'PlantLabTestResults_Configuration_WE',
              'PlantLabTestResults_Monitor_R',
              'PlantLabTestResults_Usage_W',
              'WaterQualityTestResults_View_R',
              'WaterQualityTestResults_Configuration_WE',
              'WaterQualityTestResults_Monitor_R',
              'WaterQualityTestResults_Usage_W',
              'PlantEscalation_View_R',
              'PlantEscalation_Configuration_WE',
              'FixedTask_View_R',
              'FixedTask_Configuration_WE',
              'FixedTask_Usage_W',
              'Formula_View_R',
              'Formula_Configuration_WE',
              'AlertTask_View_R',
              'AlertTask_Configuration_W',
              'OneTimeTask_View_R',
              'OneTimeTask_Configuration_W',
              'OneTimeTask_Usage_W',
            ],
            defaultHomepage: 'Dashboard',
            assignedGroup: null,
            description: 'ekjej.',
            roleScope: 'System',
            roleType: 'Asset',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-07-30T11:19:33.861Z',
            updatedAt: '2024-07-31T12:18:39.214Z',
            __v: 0,
            selected: false,
          },
          {
            _id: '66b4ca39a170ea31f0e39d3c',
            name: 'testing admin',
            itemId: uuidv4(),

            permissionTags: [
              'PlantsList_Configuration_A',
              'PlantsList_Configuration_E',
              'PlantsList_Configuration_D',
              'PlantsList_View_R',
              'PLC_Configuration_AE',
              'PLC_Configuration_D',
              'PLC_Configuration_R',
              'ForgetPassword_Usage_WE',
              'AutoGenerateTickets_View_WE',
              'ManualTickets_Configuration_WE',
              'ManualTickets_Usage_WE',
            ],
            defaultHomepage: 'Plant List',
            assignedGroup: null,
            description: 'All the assets in Gurugram',
            roleScope: 'System',
            roleType: 'Administrative',
            isArchived: false,
            createdBy: '6305f9fc25ba66666c4f7d4d',
            createdAt: '2024-08-08T13:38:01.750Z',
            updatedAt: '2024-08-09T17:50:10.934Z',
            __v: 0,
            selected: false,
          },
          {
            roleIds: ['66a8f87b3aebe9e50ab88ac1'],
          },
        ],
        children: [],
      },
    ],
  },
];
export default fakeUserData;
