import { useEffect, useState, FormEvent } from 'react';
import { Box, TextField } from '@mui/material';
import './styles.css';
import { ToastContainer, toast } from 'react-toastify';
import { userData, newUserInterface } from '../../Interfaces/userInterface';
import { createNewUser, getClientAbbr, getUser, updateUser } from '../../services/users';
import { BlackButton } from '../../styles/global/components/dpButtons';
import AbbrSelector from '../Common/AbbrSelector';
import { InfoBox } from '../../styles/global/components/TextInfoBoxes';

interface addUserPopup {
  userId: string;
  role: string;
  edit: boolean;
  handleClose: Function;
  refresh: Function;
}
export default function AddUserPoup(props: addUserPopup) {
  const { handleClose, role, refresh, edit, userId } = props;
  //@ts-ignore
  const [initialAbbrSetup, setInitialAbbrSetup] = useState<boolean>(false);
  const [abbrList, setAbbrList] = useState<string[]>([]);
  const [abbr, setAbbr] = useState<string>('');
  const [newUserData, setnewUserData] = useState<userData | newUserInterface>({
    name: '',
    number: '',
    email: '',
    role: role,
    abbr: '',
  });
  useEffect(() => {
    if (initialAbbrSetup) {
      setnewUserData({ ...newUserData, abbr: abbr });
    }
  }, [abbr]);
  const [refreshs, setRefreshs] = useState(false);

  useEffect(() => {
    if (edit) {
      fetchUserInfo();
    } else {
      setInitialAbbrSetup(true);
    }
  }, []);

  useEffect(() => {
    if (newUserData.name.length >= 3) {
      createAbbrForClient(newUserData.name);
    } else {
      setAbbrList([]);
    }
  }, [newUserData.name]);

  const fetchUserInfo = () => {
    getUser(userId)
      .then(data => {
        setnewUserData(data);
        if (data.abbr === undefined) {
          setInitialAbbrSetup(true);
          createAbbrForClient(data.name);
        } else {
          setInitialAbbrSetup(false);
          setAbbr(data.abbr);
        }
      })
      .catch();
  };

  const createAbbrForClient = (name: string) => {
    getClientAbbr(name).then(data => {
      setAbbrList(data);
    });
  };

  const handleChanges = (key: string, value: string | number) => {
    let data = newUserData;
    data[key] = value;
    setnewUserData(data);
    setRefreshs(!refreshs);
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (role === 'Client') {
      if (abbr.length === 0) {
        toast.error('Please select an abbreviation');
        return;
      } else if (abbr.length < 3) {
        toast.error('Abbreviation must be atleast 3 characters');
        return;
      } else if (abbr.length > 6) {
        toast.error('Abbreviation must be atmost 6 characters');
        return;
      }
    }
    if (edit) {
      updateUser(newUserData)
        .then(data => {
          if (data.data.type === 'error') {
            toast.error(data.data.message);
          } else if (data.data.type === 'success') {
            toast.success(data.data.message);
            refresh();
          } else {
            toast(data.data.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      createNewUser(newUserData)
        .then(data => {
          if (data.data.type === 'error') {
            toast.error(data.data.message);
          } else if (data.data.type === 'success') {
            toast.success(data.data.message);
            refresh();
          } else {
            toast(data.data.message);
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <div className="popup-box">
      <div className="box">
        <span
          className="close-icon"
          onClick={() => {
            handleClose();
          }}
        >
          x
        </span>
        <form onSubmit={handleSubmit}>
          {role === 'Client' && initialAbbrSetup ? (
            <InfoBox currentStatus="info">
              Please exercise utmost caution when selecting the abbreviation for the client. Be
              advised that this action is irreversible and will have permanent implications. It is
              crucial to ensure accuracy and appropriateness in your selection, as it cannot be
              modified at a later stage.
            </InfoBox>
          ) : (
            <></>
          )}
          {role === 'Client' && !initialAbbrSetup ? (
            <InfoBox currentStatus="success">
              Please note that the abbreviation for the client has already been selected and is now
              permanently set. Due to our system's constraints, it is not possible to modify or
              alter this abbreviation after selection. We advise you to proceed accordingly with the
              understanding that this abbreviation will remain as currently established for all
              future interactions and references.
            </InfoBox>
          ) : (
            <></>
          )}
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newUserData?.name}
              label="Name"
              onChange={e => {
                handleChanges('name', e.target.value);
              }}
            />
          </Box>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newUserData?.number}
              label="Number"
              onChange={e => {
                handleChanges('number', e.target.value);
              }}
            />
          </Box>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newUserData?.email}
              label="Email"
              onChange={e => {
                handleChanges('email', e.target.value);
              }}
            />
          </Box>

          {role === 'Client' && initialAbbrSetup ? (
            <Box m={1} p={1}>
              <AbbrSelector options={abbrList} setAbbr={setAbbr} />
            </Box>
          ) : (
            <Box m={1} p={1}>
              <TextField fullWidth disabled value={newUserData?.abbr} label="Abbreviation" />
            </Box>
          )}

          <Box m={1} p={1}>
            <BlackButton variant="contained" type="submit">
              {edit ? <>Update</> : <>Create User</>}
            </BlackButton>
          </Box>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
