export default function GroupIcon() {
  return (
    <svg width="16" height="11" viewBox="0 0 16 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 0.666656C8.61884 0.666656 9.21233 0.912489 9.64992 1.35007C10.0875 1.78766 10.3333 2.38115 10.3333 2.99999C10.3333 3.61883 10.0875 4.21232 9.64992 4.64991C9.21233 5.08749 8.61884 5.33332 8 5.33332C7.38116 5.33332 6.78767 5.08749 6.35008 4.64991C5.9125 4.21232 5.66667 3.61883 5.66667 2.99999C5.66667 2.38115 5.9125 1.78766 6.35008 1.35007C6.78767 0.912489 7.38116 0.666656 8 0.666656ZM3.33333 2.33332C3.70667 2.33332 4.05333 2.43332 4.35333 2.61332C4.25333 3.56666 4.53333 4.51332 5.10667 5.25332C4.77333 5.89332 4.10667 6.33332 3.33333 6.33332C2.8029 6.33332 2.29419 6.12261 1.91912 5.74754C1.54405 5.37246 1.33333 4.86376 1.33333 4.33332C1.33333 3.80289 1.54405 3.29418 1.91912 2.91911C2.29419 2.54404 2.8029 2.33332 3.33333 2.33332ZM12.6667 2.33332C13.1971 2.33332 13.7058 2.54404 14.0809 2.91911C14.456 3.29418 14.6667 3.80289 14.6667 4.33332C14.6667 4.86376 14.456 5.37246 14.0809 5.74754C13.7058 6.12261 13.1971 6.33332 12.6667 6.33332C11.8933 6.33332 11.2267 5.89332 10.8933 5.25332C11.4746 4.50294 11.7444 3.55745 11.6467 2.61332C11.9467 2.43332 12.2933 2.33332 12.6667 2.33332ZM3.66667 9.16666C3.66667 7.78666 5.60667 6.66666 8 6.66666C10.3933 6.66666 12.3333 7.78666 12.3333 9.16666V10.3333H3.66667V9.16666ZM0 10.3333V9.33332C0 8.40666 1.26 7.62666 2.96667 7.39999C2.57333 7.85332 2.33333 8.47999 2.33333 9.16666V10.3333H0ZM16 10.3333H13.6667V9.16666C13.6667 8.47999 13.4267 7.85332 13.0333 7.39999C14.74 7.62666 16 8.40666 16 9.33332V10.3333Z"
        fill="#193458"
      />
    </svg>
  );
}
