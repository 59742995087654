// NavigationComponent.tsx
import React from 'react';
import useNavigationStore from '../../store/NavigationStore';
import { Box, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const NavigationComponent: React.FC = () => {
  const { nodePath } = useNavigationStore();
  const navigate = useNavigate();

  return (
    <Stack my={3} direction="row" gap={1}>
      {nodePath.map((node: any, index: number) => (
        <Stack direction="row" gap={1} alignItems="center" justifyContent="center">
          <Box
            key={index}
            sx={{
              color: '#666666',
              fontSize: '16px',
              '&:hover': {
                color: '#183650', // Change this to your desired hover background color
                cursor: 'pointer',
              },
            }}
            onClick={() => {
              navigate(`/LayoutVisualisation/${node.assetId}`);
            }}
          >
            {`${node?.assetName}` || 'No asset name available'}
          </Box>
          <Box
            sx={{
              display: 'grid',
              placeItems: 'center',
              justifySelf: 'flex-end',
              alignSelf: 'flex-end',
            }}
          >
            {index < nodePath.length - 1 ? (
              <ArrowForwardIcon
                sx={{
                  color: '#666666',
                  width: 20,
                  height: 16,
                }}
              />
            ) : (
              ''
            )}
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};

export default NavigationComponent;
