import axiosInstance from "../constants/axiosInstance";

const fetchTaskService = async (id: string | undefined) => {
  try {
    const { data } = await axiosInstance().get(`/tasks/fetchTask/${id}`);
    return data.task;
  } catch (err) {
    console.error("ERROR | fetchTaskService | ", err);
  }
};

const addComment = async (changeStatusObject: any) => {
  try {
    const { data } = await axiosInstance().post(
      "/tasks/addComment",
      changeStatusObject
    );
    return data;
  } catch (err) {
    console.error("ERROR | postAlertMasterData | ", err);
  }
};

const changeTaskStatusService = async (changeStatusObject: any) => {
  try {
    const { data } = await axiosInstance().post(
      "/tasks/changeTaskStatus",
      changeStatusObject
    );
    return data;
  } catch (err) {
    console.error("ERROR | postAlertMasterData | ", err);
  }
};

export { fetchTaskService, addComment, changeTaskStatusService };
