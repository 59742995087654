import { useEffect } from 'react';
import { Box } from '@mui/system';
import { TabContext, TabList } from '@mui/lab';
import { Tab } from '@mui/material';

const tabStyles = {
  marginRight: '1.5rem',
  whiteSpace: 'nowrap',
  color: '#2196F3',
  '& .Mui-selected': {
    color: '#2196F3',
  },
  '& .MuiTab-root': {
    color: '#00000099',
  },
};

interface IStore {
  _id: string;
  name: string;
  assets: any;
  clients: any;
  archived: boolean;
  totalItems: number;
}

interface IAssets {
  _id: string;
  plantName: string;
}

interface AllStoreTabsProps {
  storesData: IStore[];
  selectedStoreAssets: IAssets[];
  setSelectedStoreAssets: (selectedStoreAssets: IAssets[]) => void;
  selectedStoreTab: { _id: string; name: string };
  setSelectedStoreTab: (selectedStoreTab: { _id: string; name: string }) => void;
  storeName: string;
  setStoreName: (storeName: string) => void;
}

export const AllStoreTabs: React.FC<AllStoreTabsProps> = ({
  storesData,
  selectedStoreAssets,
  setSelectedStoreAssets,
  selectedStoreTab,
  setSelectedStoreTab,
  storeName,
  setStoreName,
}) => {
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    const selectedStore = storesData.find(store => store.name === newValue);

    // If the selectedAsset is found, update the state with _id and name
    if (selectedStore) {
      setSelectedStoreTab({ _id: selectedStore._id, name: selectedStore.name });
    } else if (storesData.length >= 1) {
      setSelectedStoreTab({ _id: storesData[0]._id, name: storesData[0].name });
    }

    setStoreName(newValue);
    if (newValue === 'All Store' && storesData.length > 1) {
      const assetsSet = new Set();

      storesData?.forEach(stores => {
        stores.assets.forEach((asset: IAssets) => {
          // Convert the asset to a unique key for comparison
          const assetKey = JSON.stringify(asset);

          // Check if the asset is already in the set
          if (!assetsSet.has(assetKey)) {
            assetsSet.add(assetKey);
          }
        });
      });

      // Convert the set back to an array if needed
      const AssetsData: IAssets[] = Array.from(assetsSet) as IAssets[];

      const transformedData = AssetsData.map((item: any) => JSON.parse(item));

      // Update the state or perform any other action with AssetsData
      setSelectedStoreAssets(transformedData);

      // Assuming you want to clear the "transformedData" item
      localStorage.removeItem('selectedStoreAssets');

      // Convert the array to a string using JSON.stringify
      const transformedDataString = JSON.stringify(transformedData);

      // Setting the string in localStorage
      localStorage.setItem('selectedStoreAssets', transformedDataString);
    } else {
      // Find the selected store based on the newValue
      let selectedStore;
      if (storesData.length > 1) {
        selectedStore = storesData.find(store => store.name === newValue) || {};
        setSelectedStoreAssets(selectedStore?.assets || []);
      } else {
        selectedStore = storesData[0];
        setSelectedStoreAssets(selectedStore?.assets || []);
      }

      // Assuming you want to clear the "transformedData" item
      localStorage.removeItem('selectedStoreAssets');

      // Convert the array to a string using JSON.stringify
      const transformedDataString = JSON.stringify(selectedStore?.assets || []);

      // Setting the string in localStorage
      localStorage.setItem('selectedStoreAssets', transformedDataString);
    }
  };

  useEffect(() => {
    handleTabChange(null as any, storeName);
  }, [storesData]);

  return (
    <Box sx={{ marginTop: '1rem' }}>
      <TabContext value={storeName}>
        <TabList
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons={false}
          sx={tabStyles}
        >
          {storesData.length > 1 ? (
            <Tab label="All Store" value="All Store" sx={tabStyles} />
          ) : (
            <></>
          )}
          {/* <Tab label="All Store" value="All Store" sx={tabStyles} /> */}
          {storesData.map((store, index) => (
            <Tab key={index} label={store.name} value={store.name} sx={tabStyles} />
          ))}
        </TabList>
      </TabContext>
    </Box>
  );
};
