import axiosInstance from "../constants/axiosInstance";

const getChemicals = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/InventoryManagement/ChemicalList?plantId=${plantId}`
    );
    return data?.data;
  } catch (err) {
    console.error(`ERROR | getChemicals`, err);
  }
};

const getInventoryCategoryLogs = async (plantId: string, inventoryCategoryName: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/InventoryManagement/InventoryCategoryLogs?plantId=${plantId}&inventoryCategoryName=${inventoryCategoryName}`
    );
    return data?.data;
  } catch(err) {
    console.error(`ERROR | getInventoryCategoryLogs | `, err);
  }
}

export { getChemicals, getInventoryCategoryLogs };
