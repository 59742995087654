import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { PLANT_CONFIG_DATA } from "../../constants/constants";
import { PlantConfigStyle } from "./PlantConfigStyle";

interface IProps {
  updateValue: Function;
  materialData: string | undefined;
  drainData: string;
  overFlowData: string;
  bypassData: string;
  ageData: number;
  index: number;
}

function MaterialOfConstruction({
  updateValue,
  materialData,
  drainData,
  overFlowData,
  bypassData,
  ageData,
  index,
}: IProps) {
  return (
    <>
      <Grid item xs={12} sm={4} md={4} lg={4}>
        <Box m={1} p={1}>
          <Box m={1}>
            <FormControl fullWidth style={PlantConfigStyle.equipmentTextField}>
              <InputLabel style={PlantConfigStyle.inputLabel}>
                Material of Construction
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={materialData}
                onChange={(e) =>
                  updateValue({
                    category: "Tanks",
                    index,
                    valuefor: "material",
                    value: e.target.value,
                  })
                }
              >
                {PLANT_CONFIG_DATA["TankConfig"]["material"].map(
                  (material: any) => {
                    return <MenuItem value={material}>{material}</MenuItem>;
                  }
                )}
              </Select>
            </FormControl>
          </Box>
          <Box m={1}>
            <FormControl fullWidth style={PlantConfigStyle.equipmentTextField}>
              <InputLabel style={PlantConfigStyle.inputLabel}>
                Drain Point
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={drainData}
                onChange={(e) =>
                  updateValue({
                    category: "Tanks",
                    index,
                    valuefor: "drain",
                    value: e.target.value,
                  })
                }
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box m={1}>
            <FormControl fullWidth style={PlantConfigStyle.equipmentTextField}>
              <InputLabel style={PlantConfigStyle.inputLabel}>
                Overflow Line
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={overFlowData}
                onChange={(e) =>
                  updateValue({
                    category: "Tanks",
                    index,
                    valuefor: "overflow",
                    value: e.target.value,
                  })
                }
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box m={1}>
            <FormControl fullWidth style={PlantConfigStyle.equipmentTextField}>
              <InputLabel style={PlantConfigStyle.inputLabel}>
                Bypass
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={bypassData}
                onChange={(e) =>
                  updateValue({
                    category: "Tanks",
                    index,
                    valuefor: "bypass",
                    value: e.target.value,
                  })
                }
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Yes">Yes</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box m={1}>
            <TextField
              fullWidth
              id={`age${index}`}
              label="Age in months"
              value={ageData}
              type="number"
              onChange={(e) =>
                updateValue({
                  category: "Tanks",
                  index,
                  valuefor: "age",
                  value: e.target.value,
                })
              }
              inputProps={{
                maxLength: 1000,
                step: "1",
              }}
              style={PlantConfigStyle.equipmentTextField}
            />
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default MaterialOfConstruction;
