import moment from "moment";
import { useState } from "react";
import { Box, Button } from "@mui/material";
//import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import { DataGrid, GridColDef, GridRowParams } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import HistoryIcon from "@mui/icons-material/History";
import AddCommentIcon from "@mui/icons-material/AddComment";
import ColoredDeleteIcon from "../../assets/icons/DeleteIcon.svg";
import AlertIcon from "../../assets/icons/AlertIcon.svg";
import {
  updateSensorStatus,
  deleteSensor,
  addSensorComment,
} from "../../services/sensors";
import {
  SensorStyles,
  useStyles,
  DialogPositiveButtonStyle,
  DialogNegativeButtonStyle,
} from "./sensors";
import SensorHistory from "./sensorHistory/SensorHistory";
import SuccessDialog from "./SuccessDialog";
import useUserStore from "../../store/UserStore";

// interface IProps {
//   setLoading: any;
//   sensorsData: any;
//   setUpdatedSensorData: Function;
//   processing: boolean;
//   setProcessing: Function;
//   showProcessingResult: boolean;
//   setShowProcessingResult: Function;
//   activeCount: number;
//   inActiveCount: number;
//   deletedCount: number;
// }

function SensorsDataGrid({
  setLoading,
  sensorsData,
  setUpdatedSensorData,
  processing,
  setProcessing,
  showProcessingResult,
  setShowProcessingResult,
  activeCount,
  inActiveCount,
  deletedCount,
}: any) {
  const [showUpdateSensorModal, setShowUpdateSensorModal] = useState(false);
  const [showDeleteSensorModal, setShowDeleteSensorModal] = useState(false);
  const [showSensorHistory, setShowSensorHistory] = useState(false);
  const [selectedSensorId, setSelectedSensorId] = useState("");
  const [selectedSensorTag, setSelectedSensorTag] = useState("");
  const [selectedSensorName, setSelectedSensorName] = useState("");
  const [selectedSensorStatus, setSelectedSensorStatus] = useState(false);
  const [updateStatusComment, setUpdateStatusComment] = useState("");
  const [updateDeleteComment, setUpdateDeleteComment] = useState("");
  const [showSensorCommentModal, setShowSensorCommentModal] = useState(false);
  const [sensorComment, setSensorComment] = useState("");

  const columns: GridColDef[] = [
    {
      field: "sensorTag",
      headerName: "Sensor Tag",
      width: 200,
    },
    {
      field: "sensorName",
      headerName: "Sensor Name",
      width: 200,
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      renderCell: (params: any) => {
        const isEnabled = params?.row?.isEnabled;
        const isDeleted = params?.row?.isDeleted;
        return (
          <div>
            <span
              style={
                isDeleted === true
                  ? SensorStyles.DeletedSensor
                  : isEnabled === true
                  ? SensorStyles.ActiveSensor
                  : SensorStyles.InActiveSensor
              }
            >
              {params?.row?.status}
            </span>
          </div>
        );
      },
    },
    {
      field: "updateSensorStatus",
      headerName: "Update Status",
      width: 180,
      renderCell: (params: any) => {
        return (
          <Switch
            checked={params?.row?.isEnabled || null}
            onChange={() => {
              setSelectedSensorId(params.id);
              setSelectedSensorTag(params?.row?.sensorTag);
              setSelectedSensorName(params?.row?.sensorName);
              setSelectedSensorStatus(params?.row?.isEnabled);
              setShowUpdateSensorModal(true);
            }}
            sx={{}}
          />
        );
      },
    },
    {
      field: "deleteSensor",
      headerName: "Delete",
      width: 130,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              setSelectedSensorId(params.id);
              setSelectedSensorTag(params?.row?.sensorTag);
              setSelectedSensorName(params?.row?.sensorName);
              setShowDeleteSensorModal(true);
            }}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
    {
      field: "comment",
      headerName: "Add Remarks",
      width: 180,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              setSelectedSensorId(params.id);
              setSelectedSensorTag(params?.row?.sensorTag);
              setSelectedSensorName(params?.row?.sensorName);
              setShowSensorCommentModal(true);
            }}
          >
            <AddCommentIcon />
          </Button>
        );
      },
    },
    {
      field: "sensorHistory",
      headerName: "View History",
      width: 170,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              setSelectedSensorId(params.id);
              setShowSensorHistory(true);
            }}
          >
            <HistoryIcon />
          </Button>
        );
      },
    },
    {
      field: "id",
      headerName: "Sensor ID",
      width: 250,
    },
    {
      field: "plcId",
      headerName: "PLC ID",
      width: 250,
    },
  ];

  const customClasses = useStyles();

  return (
    <>
      <Box m={1} p={1}>
        <div style={{ height: 420 }}>
          <DataGrid
            rows={sensorsData}
            columns={columns}
            getRowClassName={(params: GridRowParams) => {
              const { isDeleted = false } = params.row;
              if (isDeleted) {
                return `${customClasses.deletedRowStyle}`;
              }
              return "";
            }}
          />
        </div>
      </Box>
      <Dialog
        open={showUpdateSensorModal}
        onClose={() => {
          setShowUpdateSensorModal(false);
          setShowProcessingResult(false);
        }}
      >
        {processing && (
          <DialogContent>
            <Box sx={SensorStyles.ProcessingStyle}>
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
        {!processing && showProcessingResult && (
          <SuccessDialog
            setShowSuccessModal={setShowUpdateSensorModal}
            setShowProcessingResult={setShowProcessingResult}
          />
        )}
        {!processing && !showProcessingResult && (
          <>
            <DialogContent>
              <Box sx={SensorStyles.DialogStyles}>
                <img
                  src={AlertIcon}
                  alt="Alert Icon"
                  style={SensorStyles.IconStyles}
                />
                <Typography variant="h5" sx={SensorStyles.DialogTitleStyle}>
                  Please Confirm !
                </Typography>
                <Typography>
                  You are turning
                  {selectedSensorStatus ? (
                    <span style={SensorStyles.InActiveColor}> OFF </span>
                  ) : (
                    <span style={SensorStyles.ActiveColor}> ON </span>
                  )}
                  the sensor with following properties:
                </Typography>
                <ul style={SensorStyles.DialogListStyle}>
                  <li>
                    <b>Name:</b> {selectedSensorName}
                  </li>
                  <li>
                    <b>ID:</b> {selectedSensorId}
                  </li>
                  <li>
                    <b>Tag:</b> {selectedSensorTag}
                  </li>
                </ul>
                <TextField
                  id="update-status-comment"
                  label="Add Remarks"
                  variant="outlined"
                  value={updateStatusComment}
                  onChange={(e) => {
                    setUpdateStatusComment(e.target.value);
                  }}
                  fullWidth
                  multiline
                  required
                  sx={{
                    marginTop: "0.8rem",
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <DialogNegativeButtonStyle
                onClick={() => {
                  setShowUpdateSensorModal(false);
                }}
              >
                Cancel
              </DialogNegativeButtonStyle>
              <DialogPositiveButtonStyle
                disabled={updateStatusComment.length === 0 ? true : false}
                onClick={() => {
                  setProcessing(true);
                  const actionDetails = {
                    // updatedBy: localStorage.getItem("userid"),
                    updatedBy: useUserStore().userId,
                    updatedAt: moment(),
                    comment: updateStatusComment,
                    activeCount,
                    inActiveCount,
                  };
                  updateSensorStatus(selectedSensorId, actionDetails).then(
                    (data) => {
                      setUpdatedSensorData(data);
                      setUpdateStatusComment("");
                    }
                  );
                }}
              >
                Confirm
              </DialogPositiveButtonStyle>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        open={showDeleteSensorModal}
        onClose={() => {
          setShowDeleteSensorModal(false);
          setShowProcessingResult(false);
        }}
      >
        {processing && (
          <DialogContent>
            <Box sx={SensorStyles.ProcessingStyle}>
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
        {!processing && showProcessingResult && (
          <SuccessDialog
            setShowSuccessModal={setShowDeleteSensorModal}
            setShowProcessingResult={setShowProcessingResult}
          />
        )}
        {!processing && !showProcessingResult && (
          <>
            <DialogContent>
              <Box sx={SensorStyles.DialogStyles}>
                <img
                  src={ColoredDeleteIcon}
                  alt="Colored Delete Icon"
                  style={SensorStyles.IconStyles}
                />
                <Typography variant="h5" sx={SensorStyles.DialogTitleStyle}>
                  Please Confirm !
                </Typography>
                <Typography>
                  You are deleting the sensor with following properties:
                </Typography>
                <ul style={SensorStyles.DialogListStyle}>
                  <li>
                    <b>Name:</b> {selectedSensorName}
                  </li>
                  <li>
                    <b>ID:</b> {selectedSensorId}
                  </li>
                  <li>
                    <b>Tag:</b> {selectedSensorTag}
                  </li>
                </ul>
                <TextField
                  id="delete-comment"
                  label="Add Remarks"
                  variant="outlined"
                  value={updateDeleteComment}
                  onChange={(e) => {
                    setUpdateDeleteComment(e.target.value);
                  }}
                  fullWidth
                  multiline
                  required
                  sx={{
                    marginTop: "0.8rem",
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <DialogNegativeButtonStyle
                onClick={() => {
                  setShowDeleteSensorModal(false);
                }}
              >
                Cancel
              </DialogNegativeButtonStyle>
              <DialogPositiveButtonStyle
                disabled={updateDeleteComment.length === 0 ? true : false}
                onClick={() => {
                  setProcessing(true);
                  const actionDetails = {
                    //updatedBy: localStorage.getItem("userid"),
                    updatedBy: useUserStore().userId,
                    updatedAt: moment(),
                    comment: updateDeleteComment,
                    deletedCount,
                  };
                  deleteSensor(selectedSensorId, actionDetails).then((data) => {
                    setUpdatedSensorData(data);
                  });
                }}
              >
                Confirm
              </DialogPositiveButtonStyle>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        open={showSensorCommentModal}
        onClose={() => {
          setShowSensorCommentModal(false);
        }}
      >
        {processing && (
          <DialogContent>
            <Box sx={SensorStyles.ProcessingStyle}>
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
        {!processing && showProcessingResult && (
          <SuccessDialog
            setShowSuccessModal={setShowSensorCommentModal}
            setShowProcessingResult={setShowProcessingResult}
          />
        )}
        {!processing && !showProcessingResult && (
          <>
            <DialogContent>
              <Box sx={SensorStyles.DialogStyles}>
                <img
                  src={AlertIcon}
                  alt="Alert Icon"
                  style={SensorStyles.IconStyles}
                />
                <Typography variant="h5" sx={SensorStyles.DialogTitleStyle}>
                  Please Confirm !
                </Typography>
                <Typography>
                  You are adding a comment on the sensor with following
                  properties:
                </Typography>
                <ul style={SensorStyles.DialogListStyle}>
                  <li>
                    <b>Name:</b> {selectedSensorName}
                  </li>
                  <li>
                    <b>ID:</b> {selectedSensorId}
                  </li>
                  <li>
                    <b>Tag:</b> {selectedSensorTag}
                  </li>
                </ul>
                <TextField
                  id="add-comment"
                  label="Add Remarks"
                  variant="outlined"
                  value={sensorComment}
                  onChange={(e) => {
                    setSensorComment(e.target.value);
                  }}
                  fullWidth
                  multiline
                  required
                  sx={{
                    marginTop: "0.8rem",
                  }}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <DialogNegativeButtonStyle
                onClick={() => {
                  setShowSensorCommentModal(false);
                }}
              >
                Cancel
              </DialogNegativeButtonStyle>
              <DialogPositiveButtonStyle
                disabled={sensorComment.length === 0 ? true : false}
                onClick={() => {
                  setProcessing(true);
                  const actionDetails = {
                    // updatedBy: localStorage.getItem("userid"),
                    updatedBy: useUserStore().userId,
                    updatedAt: moment(),
                    comment: sensorComment,
                  };
                  addSensorComment(selectedSensorId, actionDetails).then(() => {
                    setProcessing(false);
                    setShowProcessingResult(true);
                    setSensorComment("");
                  });
                }}
              >
                Confirm
              </DialogPositiveButtonStyle>
            </DialogActions>
          </>
        )}
      </Dialog>
      {showSensorHistory && (
        <SensorHistory
          sensorId={selectedSensorId}
          setShowSensorHistory={setShowSensorHistory}
          showSensorHistory={showSensorHistory}
        />
      )}
    </>
  );
}

export default SensorsDataGrid;
