import DeleteIcon from '../core-components/assets/icons/DeleteIcon';
import FeatureIcon from '../core-components/assets/icons/FeatureIcon';
import ModuleIcon from '../core-components/assets/icons/ModuleIcon';
import PermissionIcon from '../core-components/assets/icons/PermissionIcon';
import SubfeatureIcon from '../core-components/assets/icons/SubfeatureIcon';
import WorkspaceAssetIcon from '../core-components/assets/icons/WorkspaceAssetIcon';
import WorkspaceIcon from '../core-components/assets/icons/WorkspaceIcon';

type FileType =
  | 'module'
  | 'feature'
  | 'subfeature'
  | 'permission'
  | 'asset'
  | 'workspace'
  | 'delete'
  | 'update';

const getIconFromFileType = (fileType: FileType) => {
  switch (fileType) {
    case 'module':
      return ModuleIcon;
    case 'feature':
      return FeatureIcon;
    case 'subfeature':
      return SubfeatureIcon;
    case 'permission':
      return PermissionIcon;
    case 'asset':
      return WorkspaceAssetIcon;
    case 'workspace':
      return WorkspaceIcon;
    case 'delete':
      return DeleteIcon;
    default:
      return ModuleIcon;
  }
};
export { getIconFromFileType };
