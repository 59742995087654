import { ThemeProvider } from '@emotion/react';
import dpTheme from '../styles/theme/dpTheme';
import ChemicalLogFilters from '../components/ChemicalLog/ChemicalLogFilters';
import ChemicalLogGrid from '../components/ChemicalLog/ChemicalLogGrid';
import ChemicalAccount from '../components/ChemicalLog/ChemicalAccount';

import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getChemicalLogData, getAssetsForChemical } from '../services/ChemicalLog';

import LayoutComponent from '../components/LayoutComponent';
import { ChemicalCumulativeData, ChemicalLogsInterface, Filters } from '../Interfaces/ChemicalLog';
import dayjs, { Dayjs } from 'dayjs';
import { ArrowDropDownIcon, DateRange, DateRangeIcon } from '@mui/x-date-pickers-pro';

import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Typography, Box, CircularProgress, Stack } from '@mui/material';

import { Button, Grid } from '@mui/material';
import { SecondaryButton } from '../styles/global/components/dpButtons';

import CustomDateRangePicker from '../components/DateRangePicker';
import useIsMobile from '../utilities/customHooks/useIsMobile';

import { GridFilterListIcon } from '@mui/x-data-grid';
import AssetTabComponent from '../components/Tabs/AssetTabsForSelectedStore/AssetTabComponent';
import CustomDialog from '../globalComponents/dialogs/CustomDialog';
import useIsTablet from '../utilities/customHooks/useIsTablet';

export interface AssetTab {
  _id: string;
  plantName: string;
}

function ChemicalLog() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();
  const storeId = searchParams.get('storeId');
  const [assetId, setAssetId] = useState(searchParams.get('assetId'));
  const chemicalId = searchParams.get('chemicalId');
  const chemicalName = searchParams.get('chemicalName');
  const chemicalUnit = searchParams.get('chemicalUnit');

  const [chemicalLogFilters, setChemicalLogFilters] = useState<Filters | undefined>();
  const [chemicalCumulativeData, setChemicalCumulativeData] = useState<
    ChemicalCumulativeData | undefined
  >();

  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>([
    dayjs().subtract(1, 'month'),
    dayjs(),
  ]);

  const [appliedFilters, setAppliedFilters] = useState<Filters | undefined>();
  const [filtersChanged, setFiltersChanged] = useState(false);

  const [openFilters, setOpenFilters] = useState(false);

  const [selectedStoreAssets, setSelectedStoreAssets] = useState<AssetTab[]>([]);
  const [selectedAssetTab, setSelectedAssetTab] = useState<AssetTab>({
    _id: '',
    plantName: '',
  });
  const [assetName, setAssetName] = useState<string>('All Asset');
  const [loading, setLoading] = useState<boolean>(true);

  const handleOpenFilters = () => {
    setOpenFilters(!openFilters);
  };

  const handleCloseFilters = () => {
    setOpenFilters(false);
  };

  useEffect(() => {
    getChemicalLogData(
      storeId!,
      assetName === 'All Asset' ? assetName : selectedAssetTab._id,
      chemicalId!
    ).then((data: ChemicalLogsInterface) => {
      setChemicalLogFilters(data.filters);
      setChemicalCumulativeData(data.cumulativeData);
      setLoading(false);
    });
  }, [assetName]);

  useEffect(() => {
    getAssetsForChemical(storeId!, chemicalId!).then((data: any) => {
      setSelectedStoreAssets(data[0]);
    });
  }, []);

  useEffect(() => {
    searchParams.set('assetId', assetName === 'All Asset' ? assetName : selectedAssetTab._id);
    setAssetId(assetName === 'All Asset' ? assetName : selectedAssetTab._id);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }, [assetName]);

  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  if (loading)
    return (
      <Box height="99.5vh" display="flex" justifyContent="center" alignItems="center">
        <CircularProgress size="40px" color="inherit" />
      </Box>
    );
  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent factoryResetContainer={true}>
        {chemicalCumulativeData !== undefined &&
          chemicalLogFilters !== undefined &&
          (!isMobile ? (
            <Grid
              container
              direction="row"
              sx={{ boxSizing: 'border-box' }}
              pl={{ sm: 2, md: 4, lg: 4, xl: 4 }}
              pr={{ sm: 2, md: 4, lg: 4, xl: 4 }}
              alignContent="center"
              alignItems="center"
            >
              <Grid
                container
                item
                direction="row"
                alignItems="center"
                wrap="nowrap"
                spacing={20}
                justifyContent="space-between"
                gap="13%"
              >
                <Grid
                  container
                  sm={4}
                  md={5}
                  direction="row"
                  alignItems="center"
                  wrap="nowrap"
                  spacing={3}
                >
                  <Grid item>
                    <Button
                      onClick={() => {
                        navigate('/storeDetails');
                      }}
                      sx={{
                        width: '50px',
                        height: '50px',
                        padding: '18px',
                        minWidth: '0',
                        border: '1px solid #DEF2F2',
                        borderRadius: '20%',
                      }}
                    >
                      <ArrowBackIcon
                        fontSize="small"
                        sx={{ paddingLeft: '10px', color: '#999999' }}
                      />
                    </Button>
                  </Grid>
                  <Grid item zeroMinWidth>
                    <Typography variant="h2" color="secondary.main" noWrap>
                      {chemicalName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid sm={8} item md={7}>
                  <AssetTabComponent
                    selectedStoreAssets={selectedStoreAssets}
                    setSelectedStoreAssets={setSelectedStoreAssets}
                    selectedAssetTab={selectedAssetTab}
                    setSelectedAssetTab={setSelectedAssetTab}
                    assetName={assetName}
                    setAssetName={setAssetName}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', flex: 1, gap: '1.5rem', marginTop: '.5rem' }}>
                <Grid container direction="column" gap="1.5rem" sm={3} md={2.5} lg={3} xl={3}>
                  <SecondaryButton
                    sx={{
                      padding: '1.1rem 0rem',
                      borderRadius: '8px',
                    }}
                    onClick={() => {
                      navigate(
                        `/SolutionDetailsForChemical?storeId=${storeId}&assetId=${assetId}&chemicalId=${chemicalId}&chemicalName=${chemicalName}&chemicalUnit=${chemicalUnit}`,
                        { replace: true }
                      );
                    }}
                  >
                    <Typography variant="h5">SOLUTIONS</Typography>
                    <KeyboardArrowRightIcon fontSize="medium" />
                  </SecondaryButton>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                    pt={3}
                    pb={3}
                    border="1px solid #183650"
                    borderRadius="8px"
                    onClick={handleOpenDialog}
                    sx={{ cursor: 'pointer' }}
                  >
                    <Typography
                      variant="body1"
                      style={{ textAlign: 'center', paddingLeft: '1rem', paddingRight: '1rem' }}
                    >
                      <DateRangeIcon
                        fontSize="small"
                        sx={{ color: '#3EA4A8', marginBottom: '-5px' }}
                      />
                      {!isTablet && (
                        <span
                          style={{
                            color: '#000000DD',
                            fontWeight: 500,
                            marginLeft: '4px',
                            fontSize: isTablet ? '12px' : '',
                          }}
                        >
                          Date |{' '}
                        </span>
                      )}
                      <span
                        style={{
                          color: '#999',
                          fontSize: isTablet ? '12px' : '',
                          marginLeft: isTablet ? '2px' : '',
                        }}
                      >
                        {dayjs(dateRange[0]).format('Do MMM YYYY')} —{' '}
                        {dayjs(dateRange[1]).format('Do MMM YYYY')}
                      </span>
                      <ArrowDropDownIcon sx={{ marginBottom: '-8px', color: '#0000008F' }} />
                    </Typography>
                  </Stack>
                  <ChemicalLogFilters
                    assetId={assetId || ''}
                    chemicalName={chemicalName || ''}
                    chemicalId={chemicalId || ''}
                    chemicalLogFilters={chemicalLogFilters}
                    setFiltersChanged={setFiltersChanged}
                    setAppliedFilters={setAppliedFilters}
                  />
                </Grid>
                <Grid container direction="column" sm={9} md={9.5} lg={9} xl={9}>
                  <ChemicalAccount
                    assetId={assetId || ''}
                    chemicalId={chemicalId || ''}
                    chemicalName={chemicalName || ''}
                    chemicalUnit={chemicalUnit || ''}
                    chemicalCumulativeData={chemicalCumulativeData}
                  />
                  <ChemicalLogGrid
                    dateRange={dateRange}
                    storeId={storeId!}
                    asset={assetName === 'All Asset' ? assetName : selectedAssetTab._id!}
                    chemicalId={chemicalId!}
                    appliedFilters={appliedFilters}
                    filtersChanged={filtersChanged}
                    assetName={assetName}
                    selectedAssetTab={selectedAssetTab}
                  />
                </Grid>
              </Box>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              sx={{ boxSizing: 'border-box' }}
              alignContent="center"
              alignItems="center"
              pl={3}
              pr={3}
            >
              <Grid
                container
                direction="row"
                alignItems="center"
                wrap="nowrap"
                justifyContent="space-between"
                gap={3}
              >
                <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
                  <Grid item>
                    <Button
                      onClick={() => {
                        navigate('/storeDetails');
                      }}
                      sx={{
                        width: '50px',
                        height: '50px',
                        padding: '18px',
                        minWidth: '0',
                        border: '1px solid #DEF2F2',
                        borderRadius: '20%',
                      }}
                    >
                      <ArrowBackIcon
                        fontSize="small"
                        sx={{ paddingLeft: '10px', color: '#999999' }}
                      />
                    </Button>
                  </Grid>
                  <Grid item zeroMinWidth>
                    <Typography variant="h4" color="secondary.main" noWrap>
                      {chemicalName}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid item>
                  <SecondaryButton
                    sx={{
                      padding: '1rem 2.5rem',
                      borderRadius: '8px',
                    }}
                    onClick={() => {
                      navigate(
                        `/SolutionDetailsForChemical?storeId=${storeId}&assetId=${assetId}&chemicalId=${chemicalId}&chemicalName=${chemicalName}`,
                        { replace: true }
                      );
                    }}
                  >
                    <Typography variant="h5">SOLUTIONS</Typography>
                    <KeyboardArrowRightIcon fontSize="medium" />
                  </SecondaryButton>
                </Grid>
              </Grid>

              <Grid
                container
                item
                direction="column"
                alignItems="center"
                wrap="nowrap"
                justifyContent="center"
                gap="1.5rem"
                mt="1.2rem"
                mb="0.4rem"
                position="relative"
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  wrap="nowrap"
                  gap={3}
                >
                  <Grid item>
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      flexWrap="wrap"
                      pt={3}
                      pb={3}
                      border="1px solid #183650"
                      borderRadius="8px"
                      onClick={handleOpenDialog}
                      sx={{ cursor: 'pointer' }}
                    >
                      <Typography
                        variant="body1"
                        style={{
                          textAlign: 'center',
                          paddingLeft: '0.5rem',
                          paddingRight: '0.1rem',
                        }}
                      >
                        <DateRangeIcon
                          fontSize="small"
                          sx={{ color: '#3EA4A8', marginBottom: '-5px' }}
                        />
                        <span
                          style={{
                            color: '#999',
                            fontSize: '12px',
                            marginLeft: '2px',
                          }}
                        >
                          {dayjs(dateRange[0]).format('Do MMM YYYY')} —{' '}
                          {dayjs(dateRange[1]).format('Do MMM YYYY')}
                        </span>
                        <ArrowDropDownIcon sx={{ marginBottom: '-8px', color: '#0000008F' }} />
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Button
                      id="fade-button"
                      sx={{
                        border: `1px solid #DEF2F2`,
                        color: '#000',
                        minWidth: '54px',
                        padding: '16px',
                        borderRadius: '8px',
                      }}
                      onClick={handleOpenFilters}
                    >
                      <GridFilterListIcon fontSize="medium" />
                    </Button>
                  </Grid>
                </Grid>

                {openFilters && (
                  <ChemicalLogFilters
                    assetId={assetId || ''}
                    chemicalName={chemicalName || ''}
                    chemicalId={chemicalId || ''}
                    chemicalLogFilters={chemicalLogFilters}
                    setFiltersChanged={setFiltersChanged}
                    setAppliedFilters={setAppliedFilters}
                    openFilters={openFilters}
                    handleCloseFilters={handleCloseFilters}
                  />
                )}
              </Grid>

              <ChemicalAccount
                assetId={assetId || ''}
                chemicalId={chemicalId || ''}
                chemicalName={chemicalName || ''}
                chemicalUnit={chemicalUnit || ''}
                chemicalCumulativeData={chemicalCumulativeData}
              />

              <AssetTabComponent
                selectedStoreAssets={selectedStoreAssets}
                setSelectedStoreAssets={setSelectedStoreAssets}
                selectedAssetTab={selectedAssetTab}
                setSelectedAssetTab={setSelectedAssetTab}
                assetName={assetName}
                setAssetName={setAssetName}
              />

              <ChemicalLogGrid
                dateRange={dateRange}
                storeId={storeId!}
                asset={assetName === 'All Asset' ? assetName : selectedAssetTab._id!}
                chemicalId={chemicalId!}
                appliedFilters={appliedFilters}
                filtersChanged={filtersChanged}
                assetName={assetName}
                selectedAssetTab={selectedAssetTab}
              />
            </Grid>
          ))}
        <CustomDialog open={openDialog} onClose={handleCloseDialog}>
          <CustomDateRangePicker
            dateRange={dateRange}
            setDateRange={setDateRange}
            handleCloseDialog={handleCloseDialog}
          />
        </CustomDialog>
      </LayoutComponent>
    </ThemeProvider>
  );
}

export default ChemicalLog;
