import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import TwoRowLayout from '../../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import { Neutrals, Primary } from '../../../../core-components/theme/color-palette/colors';
import CustomTreeComponent from '../../../../core-components/theme/components/Tree/CustomTree';
import LayoutComponent from '../../../../components/LayoutComponent';
import Button from '../../../../core-components/theme/components/Button/Button';
import CustomUserGroupTreeItem from '../../userGroup/component/CustomUserGroupTree';
import { getUserItemId, getUserItemlabel } from '../../helpers/customUserTreeHelpers';
import { getPermissionListForUser, getRoleDetails, saveRole } from '../../../../services/role';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPermissions, processAllTags } from '../../userGroup/hooks/moduleHandler';
import { isValidNameFormat } from '../../../../utilities/name.utils';
import { isDescriptionProperlyFormatted } from '../../../../utilities/description.utils';
import { FormData } from '../interfaces/FormData';
import { useTreeStore } from '../../../../store/TreeStore';

const RoleEditPage = () => {
  const { id: roleId } = useParams();
  const mode = 'edit';
  const [formData, setFormData] = useState<FormData>({
    roleId: roleId,
    name: '',
    description: '',
    defaultHomepage: '',
    roleScope: '',
    groupName: '',
    roleType: '',
    permissionTags: [],
  });
  const [open, setOpen] = useState(true);
  const [asset, setAssets] = useState<any[]>([]);
  const [administrative, setAdministrative] = useState<any[]>([]);
  const [tagSet, setTagSet] = useState(false);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const setIds = useTreeStore(state => state.setIds);
  const treeids = useTreeStore(state => state.ids);

  function handleTreeSelected(val: any) {
    setFormData({
      ...formData,
      permissionTags: val,
    });
    setIds(val);
  }

  const {
    data: roleDetails,
    status,
    error,
  } = useQuery({
    queryKey: ['getWorkspaceData'],
    queryFn: () => getRoleDetails(roleId!),
    enabled: formData.name === '',
    refetchOnWindowFocus: false,
  });
  const {
    data: permissionList,
    status: permissionListStatus,
    refetch,
  } = useQuery({
    queryKey: ['permissionList'],
    queryFn: () => getPermissionListForUser({ mode: mode, roleId: roleId }),
    enabled: false, // only fetch if roleDetails is available
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (permissionListStatus === 'success') {
      const { assets, administrative } = permissionList.data[0];
      setIds([]);
      if (formData.roleType) {
        if (formData.roleType === 'Asset') {
          formData.permissionTags = processAllTags(assets);
        } else {
          formData.permissionTags = processAllTags(administrative);
        }
      }
      setIds(formData.permissionTags);
      setAssets(assets);
      setAdministrative(administrative);
      setTagSet(true);
    }
  }, [permissionList, permissionListStatus]);
  function handleFormData(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }

  const handleToggle = () => {
    setOpen(!open);
  };
  const descriptionLimit = 200;
  const handleSaveWorkspaceData = () => {
    const updatedFormData = { ...formData };
    updatedFormData.name = updatedFormData.name.trim();
    updatedFormData.description = updatedFormData.description?.trim() || '';
    if (!isDescriptionProperlyFormatted) {
      toast.error(`Description cannot be this long, max length is ${descriptionLimit} characters`);
      return;
    }
    if (!isValidNameFormat(updatedFormData.name)) {
      toast.error('Name cannot be empty and max length 30');
      return;
    }
    updatedFormData.permissionTags = getPermissions(
      formData.roleType === 'Asset' ? asset : administrative,
      formData.permissionTags
    );
    if (updatedFormData.permissionTags.length === 0) {
      toast.error('Please selected atleast one permission');
      return;
    }
    mutation.mutate(updatedFormData);
  };
  console.log({ treeids });
  const mutation = useMutation({
    mutationFn: (formData: FormData) => saveRole(formData),
    onSuccess: data => {
      // Check if the response indicates success or failure
      if (data !== undefined) {
        toast.success(`Role created`);
        navigate('/rolelist'); // Redirect to another page
      } else {
        throw new Error('Failed to save role');
      }
    },
    onError: error => {
      toast.error(`Problem in saving role`);
      console.error('Error saving role:', error);
    },
  });
  function handleCancel() {
    setAssets([]);
    setAdministrative([]);
    setTagSet(false);
    setIds([]);
    navigate('/rolelist');
  }
  const location = useLocation();

  useEffect(() => {
    if (status === 'success') {
      setFormData({
        roleId: roleId,
        name: roleDetails.name,
        description: roleDetails.description,
        defaultHomepage: roleDetails.defaultHomepage,
        roleScope: roleDetails.roleScope,
        groupName: roleDetails?.assignedGroup?.name,
        assignedGroup: roleDetails?.assignedGroup?._id,
        roleType: roleDetails.roleType,
        permissionTags: [],
      });
      refetch();
    } else if (status === 'error') {
      // console.error('Error fetching workspace data:', error);
    }
  }, [status, roleDetails, error, roleId, location.state]);
  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{
          pl: isMobile ? 2 : 4,
          pr: isMobile ? 2 : 4,
        }}
        child1={
          <Stack direction="column" width="100%">
            <Box
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: `${Primary[50]}`,
                borderRadius: 3,
                padding: isMobile ? '16px 24px' : '33px 23px',
                border: '1px solid #bdbdbd',
              }}
            >
              <Grid container direction="column" borderRadius="12px">
                <Grid
                  container
                  onClick={handleToggle}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {isMobile && (
                    <Typography
                      variant="body"
                      letterSpacing="0.15px"
                      color="#666"
                      lineHeight="24px"
                    >
                      Role Details
                    </Typography>
                  )}
                  {isMobile && (
                    <IconButton onClick={handleToggle}>
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )}
                </Grid>
                {isMobile && open && (
                  <Divider variant="fullWidth" sx={{ mt: 1, mb: '24px', color: '#bdbdbd' }} />
                )}
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="space-around"
                    alignItems="center"
                    gap={2}
                  >
                    <Grid item xs={12} sm={5} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Role Name"
                        placeholder="Admin/CSM/Client"
                        value={formData.name}
                        name="name"
                        onChange={handleFormData}
                        variant="outlined"
                        required
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Description"
                        name="description"
                        placeholder="Description"
                        value={formData.description}
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="space-around"
                    alignItems="center"
                    gap={2}
                    mt={2}
                  >
                    <Grid item xs={12} sm={2} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Default Homepage"
                        select
                        value={formData.defaultHomepage}
                        name="defaultHomepage"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                      >
                        <MenuItem value="Unified Dashboard">Unified Dashboard</MenuItem>
                        <MenuItem value="Tasks">Tasks</MenuItem>
                        <MenuItem value="Dashboard">Dashboard</MenuItem>
                        <MenuItem value="Plant List">Plant List</MenuItem>
                        <MenuItem value="User List">User List</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={2} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Scope"
                        select
                        value={formData.roleScope}
                        name="roleScope"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                        disabled={roleId ? true : false}
                      >
                        <MenuItem value="Group">Group</MenuItem>
                        <MenuItem value="System">System</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label={formData.roleScope === 'System' ? 'N/A for System' : 'Group Name'}
                        select
                        placeholder="Name"
                        value={formData.groupName}
                        name="groupName"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                        disabled={roleId ? true : false}
                      >
                        <MenuItem value={formData.groupName}>{formData.groupName}</MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={3} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Role Type"
                        select
                        value={formData.roleType}
                        name="roleType"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: '16px',
                        }}
                        disabled={roleId ? true : false}
                      >
                        <MenuItem value={formData.roleType}>{formData.roleType}</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Box>
          </Stack>
        }
        child2={
          <Box p={0} borderRadius="8px">
            <CustomTreeComponent
              heading="Permission List"
              show={true}
              CustomTreeItem={CustomUserGroupTreeItem}
              getItemId={getUserItemId}
              getItemlabel={getUserItemlabel}
              selectedIds={formData.permissionTags}
              handleTreeSelected={handleTreeSelected}
              checkboxSelection={true}
              multiSelect={true}
              items={tagSet ? (formData.roleType === 'Asset' ? asset : administrative) : []}
            />
            <Box
              gap={6}
              pt={4}
              sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
              pb={2}
            >
              <Button
                variant="contained"
                sx={{
                  border: `1px solid ${Neutrals[500]}`,
                  borderRadius: '4px',
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  backgroundColor: 'white',
                  color: `${Neutrals[500]}`,
                  flex: isMobile ? 1 : 0,
                  boxShadow: 'none',
                  ':hover': { background: 'none', boxShadow: 'none' },
                }}
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={formData.name.length === 0}
                variant="contained"
                sx={{
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  flex: isMobile ? 1 : 0,
                  borderRadius: '4px',
                  backgroundColor: `${Primary[500]}`,
                  boxShadow: 'none',
                  ':hover': { boxShadow: 'none' },
                }}
                onClick={handleSaveWorkspaceData}
              >
                Save
              </Button>
            </Box>
          </Box>
        }
      />
    </LayoutComponent>
  );
};

export default RoleEditPage;
