import create from 'zustand';
import { persist } from 'zustand/middleware';

interface NodeItem {
  assetName: string;
  assetType: string;
  assetId: string;
  priority?: number;
}
//@ts-ignore
interface INavigationStore {
  nodePath: NodeItem[];
  setNodePath: (newNode: NodeItem) => void;
}

const useNavigationStore = create<any>(
  persist(
    (set, get) => ({
      nodePath: [],
      setNodePath: (newNode: NodeItem) => {
        switch (newNode.assetType) {
          case 'workspace':
            newNode.priority = 1;
            break;
          case 'plant':
            newNode.priority = 2;
            break;
          case 'equipment':
            newNode.priority = 3;
            break;
          default:
            newNode.priority = 4;
        }

        const currentPath = get().nodePath;
        const updatedPath = currentPath
          .filter((item: any) => item.priority && item.priority < (newNode.priority ?? 4))
          .filter((item: any) => item.assetId !== newNode.assetId);

        updatedPath.push(newNode);
        set({ nodePath: updatedPath });
      },
    }),
    {
      name: 'navigation-store', // Unique name of the store
      getStorage: () => localStorage, // Specify localStorage as the storage
    }
  )
);

export default useNavigationStore;
