import {
    Dialog,
    DialogContent,
    Box,
    CircularProgress,
    Typography,
    DialogActions,
  } from "@mui/material";
  import { SensorStyles } from "../../components/sensors/sensors";
  import {
    DialogNegativeButtonStyle,
    DialogPositiveButtonStyle,
  } from "../../styles/global/components/dpButtons";
  import ColoredDeleteIcon from "../../assets/icons/AlertIcon.svg";
  import color from "../../styles/color";
  
  const PerformActionStyle = {
    DialogStyle: {
      padding: "20px",
    },
    DialogActionStyle: {
      display: "flex",
      justifyContent: "space-evenly",
    },
    NegativeButtonStyle: {
      color: color.primaryMain,
      backgroundColor: color.dpWhite,
      border: `1px solid ${color.primaryMain}`,
    },
    PositiveButtonStyle: {
      color: color.dpWhite,
      backgroundColor: color.errorMain,
    },
  };
  
  interface IProps {
    showPerformActionModal: boolean;
    setShowPerformActionModal: Function;
    alertMessage: string;
    processing: boolean;
    setProcessing: Function;
    onConfirmAction: Function;
    negativeButtonText: string;
    positiveButtonText: string;
  }
  
  function PerformAction({
    showPerformActionModal,
    setShowPerformActionModal,
    alertMessage,
    processing,
    setProcessing,
    onConfirmAction,
    negativeButtonText,
    positiveButtonText
  }: IProps) {
    return (
      <Dialog
        open={showPerformActionModal}
        onClose={() => {
          setShowPerformActionModal(false);
        }}
      >
        {processing && (
          <DialogContent>
            <Box>
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
        {!processing && (
          <div style={PerformActionStyle.DialogStyle}>
            <DialogContent>
              <img
                src={ColoredDeleteIcon}
                alt="Colored Delete Icon"
                style={SensorStyles.IconStyles}
              />
              <Typography variant="h5">Alert !</Typography>
              <Typography>{alertMessage}</Typography>
            </DialogContent>
            <DialogActions style={PerformActionStyle.DialogActionStyle}>
              <DialogNegativeButtonStyle
                style={PerformActionStyle.NegativeButtonStyle}
                onClick={() => {
                  setShowPerformActionModal(false);
                }}
              >
                {negativeButtonText}
              </DialogNegativeButtonStyle>
              <DialogPositiveButtonStyle
                style={PerformActionStyle.PositiveButtonStyle}
                onClick={() => {
                  setProcessing(true);
                  onConfirmAction();
                  setShowPerformActionModal(false);
                }}
              >
                {positiveButtonText}
              </DialogPositiveButtonStyle>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
  
  export default PerformAction;
  