import { useState } from 'react';
import {
  Stack,
  Box,
  Select,
  MenuItem,
  Button,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Checkbox,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddFilterPopup from './addFilterPopup';
import SettingsIcon from '@mui/icons-material/Settings';
import EditFilterPopup from './editFilterPopup';
import EditIcon from '@mui/icons-material/Edit';

export default function MapFiltersConfig(props: any) {
  const {
    mapFilters,
    handleMapFilterRangeChange,
    setSelectedMapFilterIndex,
    selectedMapFilterIndex,
    handleMapFilterPropertyChange,
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [openEditFilterDialog, setOpenEditFilterDialog] = useState(false);

  const handleAddMenuItem = (item: string) => {
    if (item) {
      mapFilters.push(item);
    }
  };

  const handleDeleteMenuItem = (itemIndex: number) => {
    setSelectedMapFilterIndex(0);
    mapFilters.splice(itemIndex, 1);
    setRefresh(!refresh);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
    console.log('here in open');
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleEditMapFilterProperty = (property: string, value: any) => {
    handleMapFilterPropertyChange(property, value);
    setRefresh(!refresh);
  };

  return (
    <Box>
      <Stack spacing={2}>
        <FormControl>
          <InputLabel>Select a Filter</InputLabel>
          <Select
            value={selectedMapFilterIndex || 0}
            onChange={e => {
              setSelectedMapFilterIndex(e.target.value);
            }}
            label="Select a Filter"
          >
            {mapFilters?.map((filter: any, index: number) => (
              <MenuItem key={index} value={index}>
                <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
                  <Typography>{filter.name}</Typography>
                  <IconButton
                    onClick={event => {
                      event.stopPropagation();
                      setSelectedMapFilterIndex(index);
                      setOpenEditFilterDialog(true);
                    }}
                    sx={{ marginLeft: 'auto' }}
                  >
                    <EditIcon sx={{ fontSize: '20px' }} />
                  </IconButton>
                  <IconButton
                    onClick={event => {
                      event.stopPropagation();
                      handleDeleteMenuItem(index);
                    }}
                    disabled={filter.name === 'Plant Score' || filter.name === 'Connectivity'}
                  >
                    <DeleteIcon sx={{ fontSize: '20px' }} />
                  </IconButton>
                </Stack>
              </MenuItem>
            ))}
            <Box sx={{ display: 'grid', placeItems: 'center' }}>
              <Button
                onClick={event => {
                  event.stopPropagation();
                  handleDialogOpen();
                }}
              >
                Add Another Filter
              </Button>
            </Box>
          </Select>
        </FormControl>
        {mapFilters[selectedMapFilterIndex]?.['filterType'] === 'rangeType' && (
          <Stack direction="row" alignItems="center">
            <Checkbox
              checked={mapFilters[selectedMapFilterIndex]?.useFilterRangeAsDefault || false}
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={event => {
                handleMapFilterPropertyChange('useFilterRangeAsDefault', event.target.checked);
              }}
            />
            <Box>Make the range default for all sensor *</Box>
          </Stack>
        )}
        <Stack direction="row" alignItems="center">
          <Checkbox
            checked={mapFilters[selectedMapFilterIndex]?.default || false}
            inputProps={{ 'aria-label': 'controlled' }}
            onChange={event => {
              handleMapFilterPropertyChange('default', event.target.checked);
            }}
          />
          <Box>Make this filter default</Box>
        </Stack>
        {mapFilters[selectedMapFilterIndex]?.['filterType'] === 'rangeType' && (
          <Accordion elevation={0}>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
              // expandIcon={<ExpandMoreIcon />}
              expandIcon={<SettingsIcon fontSize="small" />}
            >
              Range Settings
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="row" gap={2} alignItems="center">
                <Box sx={{ textWrap: 'nowrap' }}>Good Range </Box>
                <TextField
                  id="outlined-basic"
                  label="Min"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={mapFilters[selectedMapFilterIndex]['range']['goodRange']['min']}
                  onChange={event => {
                    handleMapFilterRangeChange('goodRange', 'min', event.target.value);
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Max"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={mapFilters[selectedMapFilterIndex]['range']['goodRange']['max']}
                  onChange={event => {
                    handleMapFilterRangeChange('goodRange', 'max', event.target.value);
                  }}
                />
              </Stack>
              <Stack direction="row" gap={2} alignItems="center">
                <Box sx={{ textWrap: 'nowrap' }}>Warning Range</Box>
                <TextField
                  id="outlined-basic"
                  label="Min"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={mapFilters[selectedMapFilterIndex]['range']['warningRange']['min']}
                  onChange={event => {
                    handleMapFilterRangeChange('warningRange', 'min', event.target.value);
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Max"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={mapFilters[selectedMapFilterIndex]['range']['warningRange']['max']}
                  onChange={event => {
                    handleMapFilterRangeChange('warningRange', 'max', event.target.value);
                  }}
                />
              </Stack>
              <Stack direction="row" gap={2} alignItems="center">
                <Box sx={{ textWrap: 'nowrap' }}>Danger Range</Box>
                <TextField
                  id="outlined-basic"
                  label="Min"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={mapFilters[selectedMapFilterIndex]['range']['dangerRange']['min']}
                  onChange={event => {
                    handleMapFilterRangeChange('dangerRange', 'min', event.target.value);
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Max"
                  variant="outlined"
                  type="number"
                  fullWidth
                  value={mapFilters[selectedMapFilterIndex]['range']['dangerRange']['max']}
                  onChange={event => {
                    handleMapFilterRangeChange('dangerRange', 'max', event.target.value);
                  }}
                />
              </Stack>
            </AccordionDetails>
          </Accordion>
        )}
        <AddFilterPopup
          open={openDialog}
          onClose={handleDialogClose}
          onAdd={handleAddMenuItem}
          mapFilters={mapFilters}
        />
        <EditFilterPopup
          open={openEditFilterDialog}
          onClose={() => {
            setOpenEditFilterDialog(false);
          }}
          editMapFilter={handleEditMapFilterProperty}
          filterConfig={mapFilters[selectedMapFilterIndex]}
        />
      </Stack>
    </Box>
  );
}
