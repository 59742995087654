import axiosInstance from "../constants/axiosInstance";

const getStoreCategories = async () => {
  try {
    let { data } = await axiosInstance().post(`/store/getStoreCategories`);
    return data?.data;
  } catch (err) {
    console.error("ERROR | getStoreCategories | ", err);
  }
};

const getStoreById = async (storeId: string) => {
  try {
    let { data } = await axiosInstance().post(`/store/getStoreById/${storeId}`);
    return data?.data;
  } catch (err) {
    console.error("ERROR | getStoreById | ", err);
  }
};

const getFilteredStoreItems = async (storeId: string) => {
  try {
    let { data } = await axiosInstance().post(
      `/store/getFilteredStoreItems?storeId=${storeId}`
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | getFilteredStoreItems | ", err);
  }
};
const addItemToStore = async (storeId: string, formData: any) => {
  try {
    let { data } = await axiosInstance().post(
      `/store/addItem?storeId=${storeId}`,
      formData
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | addItemToStore | ", err);
  }
};
function arrayToString(arr?: string[]): string {
  return arr ? arr.join(',') : '';
}

const getAllStoreItems = async (
  storeId: string | undefined,
  page: number,
  rowsPerPage: number,
  searchQuery: string,
  selectedCategories: string[]
) => {
  try {
    let categories = arrayToString(selectedCategories);
    let { data } = await axiosInstance().post(
      `/store/getAllStoreItems/${storeId}?page=${page}&pageSize=${rowsPerPage}&filters=${categories}&searchQuery=${searchQuery}`
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | addItemToStore | ", err);
  }
};

const getStoreItemsForClient = async (storeId: string, assetId: string, clientId?: string, page?: number, pageSize?: number, searchQuery?: string, filters?: string[]) => {
  try {
    let categories = arrayToString(filters);
    const { data } = await axiosInstance().get(
      `/store/getStoreItemsForClient?storeId=${storeId}&assetId=${assetId}` +
      `${(page === 0 || page) ? `&page=${page}` : ''}` +
      `${pageSize ? `&pageSize=${pageSize}` : ''}` +
      `${categories ? `&filters=${categories}` : ''}` +
      `${searchQuery ? `&searchQuery=${searchQuery}` : ''}`
    );
    return data;
  } catch(err){
    console.log("Error | getStoreItemsForClient | ", err)
  }
}

const editItem = async (storeCategoryId: string, formData: any) => {
  try {
    let { data } = await axiosInstance().post(
      `/store/editItem?storeCategoryId=${storeCategoryId}`,
      formData
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | editItem | ", err);
  }
};

export {
  getStoreCategories,
  getFilteredStoreItems,
  getStoreById,
  editItem,
  addItemToStore,
  getAllStoreItems,
  getStoreItemsForClient
};
