import { Box } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SuccessIcon from "../../assets/icons/SuccessIcon.svg";
import { SensorStyles, DialogSuccessButtonStyle } from "./sensors";

function SuccessDialog(props: any) {
  return (
    <>
      <DialogContent>
        <Box sx={SensorStyles.ProcessingStyle}>
          <img
            src={SuccessIcon}
            alt="Success Icon"
            style={SensorStyles.IconStyles}
          />
          <h1 style={SensorStyles.SuccessStyle}>Success</h1>
        </Box>
      </DialogContent>
      <DialogActions sx={SensorStyles.ProcessingStyle}>
        <DialogSuccessButtonStyle
          onClick={() => {
            props.setShowSuccessModal(false);
            props.setShowProcessingResult(false);
          }}
        >
          Continue
        </DialogSuccessButtonStyle>
      </DialogActions>
    </>
  );
}

export default SuccessDialog;
