import { Box, Stack, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import {
  decodeTokenBody,
  getAllCachedLoginEntries,
} from "../../utilities/cachedLoginEntries.utils";

import { ITokenBody } from "../../Interfaces/tokenInterface";
import { ICachedLoginEntry } from "../../Interfaces/login.interfaces";
import PersistentUserItem from "./persistentUserItem";

interface propsInterface {
  refreshMainLoginPage: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function PersistentUserList(props: propsInterface) {
  const { refreshMainLoginPage } = props;
  const [loginEntriesList, setLoginEntriesList] = useState<ITokenBody[]>([]);

  const [refreshPage, setRefreshPage] = useState<boolean>(false);

  useEffect(() => {
    const cachedLoginEntries: ICachedLoginEntry[] = getAllCachedLoginEntries();
    let loginEntriesList = cachedLoginEntries.map((loginEntry) => {
      return { id: loginEntry.id, ...decodeTokenBody(loginEntry.token) };
    });
    setLoginEntriesList(loginEntriesList);
  }, [refreshPage]);
  //@ts-ignore

  if (loginEntriesList.length > 0)
    return (
      <Box
        sx={{
          display: "grid",
          placeItems: "center",
          height: "50vh",
          overflowY: "scroll"
        }}
      >
        <Stack
          direction="column"
          gap={2}
          divider={<Divider orientation="horizontal" flexItem />}
        >
          {loginEntriesList.map((loginEntry) => {
            return (
              <PersistentUserItem
                loginEntry={loginEntry}
                setRefreshParentPage={setRefreshPage}
                refreshMainLoginPage={refreshMainLoginPage}
              />
            );
          })}
        </Stack>
      </Box>
    );
  else return null;
}
