const eqtTypeAndTag: any = {
  Tanks: {
    'Equalization Tank': 'EQT',
    'Aeration Tank': 'AT',
    'Anoxic Tank': 'AXT',
    'Tube Settler Tank': 'TST',
    'MBBR Tank': 'MBBR',
    'MBR Tank': 'MBR',
    'SBR Tank': 'SBR',
    'Sludge Holding Tank': 'SHT',
    'Permeate Tank': 'PMT',
    'Treated Water Tank': 'TWT',
    'Chlorine Contact Tank': 'CCT',
    'Soft Water Tank': 'SWT',
    'Ultrafication Treated Water Tank': 'UTWT',
    'Neutralization tank': 'NEUTT',
    'Coagulation Tank': 'COAGT',
    'Flocculation Tank': 'FLOCT',
    'Caustic Dosing Tank': 'CAUSDT',
    'PAC Dosing Tank': 'PACDT',
    'Poly Dosing Tank': 'POLYDT',
    'Sodium Chloride Dosing Tank': 'NACLDT',
    'Lime Dosing Tank': 'LIMEDT',
    'UF CIP Tank': 'UFCIPT',
    'UF permeate tank': 'UFPERMT',
    'RO permeate tank': 'ROPERMT',
    'MEE Condensate Tank': 'MEECONDT',
    'MEE Concentrate Tank': 'MEECONCT',
    'Custom Tank': 'CS',
  },
  'Core Equipments': {
    'Bar Screen Chamber': 'BS',
    Decanter: 'DC',
    'UF (Ultrafiltration)': 'UF',
    Ozonator: 'OZ',
    'Filter Press': 'FP',
    'Screw Press': 'SP',
    Centrifuge: 'CEN',
    Softner: 'SOF',
    Clarifier: 'CLF',
    Hydrocyclone: 'HC',
    Compressor: 'CMP',
    'Sludge thickener': 'STH',
    MGF: 'MGF',
    ACF: 'ACF',
    DMF: 'DMF',
    Agitator: 'AGT',
    'RO Membrane': 'ROMEM',
    'RO Micron Cartridge Filter': 'ROMCF',
    'Vapour Liquid Separator': 'VLS',
    'Forced Circulation Evaporator': 'FCE',
    'Pre-Heater': 'PREHEATER',
    'Condensate Pot': 'CONDPOT',
    'Thermo Compressor': 'THERMCOMP',
    'UV System': 'UV',
    'Primary Clarifier': 'PC',
    'Secondary Clarifier': 'SC',
    'Anoxic Mixer': 'ANOXMIX',
    'Anaerobic Mixer': 'ANOBMIX',
  },
  Pumps: {
    Pump: 'PMP',
  },
  Flowmeter: {
    'Inlet Flowmeter': {
      'Flow Status': 'FMF',
      Totalizer: 'Inlet_water',
    },
    'Outlet Flowmeter': {
      'Flow Status': 'FMF',
      Totalizer: 'Water_Outlet',
    },
    Other: {
      'Flow Status': 'FMF',
      Totalizer: 'FMT',
    },
    Flowmeter: 'FLW',
  },
  Headers: {
    Header: 'HEAD',
  },
  Blowers: {
    Blower: 'BLW',
  },
  Valves: {
    'Motorized Valve': 'MV',
    'Solenoid Valve': 'SV',
    'Actuator Valve': 'AV',
  },
  'Other Sensors': {
    'Flooding Sensor': 'FS',
    'Energy Meter': {
      'Energy Used Per Hour': 'EMKWH',
      'Total energy Used': 'EMKW',
    },
    'Odour Sensor': 'OS',
    'Smoke Detector': 'SD',
    'Chlorine Gas Analyzer': 'CGA',
    'Fingerprint Sensor': 'FPS',
    Valves: 'VAL',
    Hooter: 'HT',
    'Load Cell': 'LC',
  },
};

const sensorTypeAndTag: any = {
  Tanks: {
    'Level Transmitter': 'LT',
    'High Level Switch': 'HLS',
    'Low Level Switch': 'LLS',
    'pH Sensor': 'PH',
    'BOD Water Quality Analyser': 'BOD',
    'COD Water Quality Analyser': 'COD',
    'TSS Water Quality Analyser': 'TSS',
    'Turbidity Sensor': 'TUR',
    TDS: 'TDS',
    'ORP Sensor': 'ORP',
    'Pressure Transmitter Outlet Line': 'PT',
    'Chlorine analyzer': 'CA',
    'DO Sensor': 'DO',
    'SV30 Sensor': 'SV',
    'Inlet Pressure Transmitter': 'PTI',
    'Outlet Pressure Transmitter': 'PTO',
    'Pressure Switch Inlet': 'PSI',
    'Pressure Switch Outlet': 'PSO',
    'Conductivity Meter': 'CM',
    'Conductivity Meter Switch': 'CMS',
    'TSS Sensor': 'TSS',
  },
  'Core Equipments': {
    'Bar Screen Sensor': '_1',
    'Bar Screen Sensor 2': '_2',
    'Bar Screen Sensor 3': '_3',
    'Bar Screen Sensor 4': '_4',
    'Bar Screen Sensor 5': '_5',
    'Bar Screen Sensor 6': '_6',
    'ON/OFF Status': 'ONOFF',
    'Proximity Sensor': 'PS',
    'Pressure Transmitter Inlet': 'PTI',
    'Pressure Transmitter Outlet': 'PTO',
    'Pressure Switch Inlet': 'PSI',
    'Pressure Switch Outlet': 'PSO',
    'Pressure Transmitter Backwash': 'PTBWS',
    'Micron cartridge inlet pressure': 'CATPT',
    'Flow switch in backwash line': 'BWS',
    'ORP Sensor': 'ORP',
    'Inlet Turbidity': 'TURI',
    'Inlet Chlorine': 'CHLI',
    'Inlet TDS': 'TDSI',
    'Inlet PH': 'PHI',
    'Manual/Auto Status': 'MAN',
    'Triping Status': 'TRP',
    'Bag Filter Pressure Transmitter': 'BAGFILPT',
    'Pressure Transmitter Reject': 'PTREJ',
    'Pressure Transmitter Permeate': 'PTPERM',
    'Ampere Sensor': 'AMP',
    'Bar Screen Percentage Sensor': 'PER',
    'Level Transmitter': 'LT',
  },
  Pumps: {
    'ON/OFF Status': 'ONOFF',
    'Triping Status': 'TRP',
    'Manual/Auto Status': 'MAN',
    'Pressure Transmitter Outlet': 'PTO',
    'Pressure Switch Inlet': 'PSI',
    'Pressure Switch Outlet': 'PSO',
    'Temperature Sensor': 'TMP',
    'Ampere Sensor': 'AMP',
    'VFD Ampere': 'AMPV',
    'VFD RPM': 'RPMV',
    'VFD Frequency': 'FRQV',
  },
  Headers: {
    'Pressure Transmitter': 'PT',
    'Pressure Switch Inlet': 'PSI',
    'Pressure Switch Outlet': 'PSO',
    'Air Flowmeter': 'AIRFMF',
  },
  Blowers: {
    'ON/OFF Status': 'ONOFF',
    'Triping Status': 'TRP',
    'Manual/Auto Status': 'MAN',
    'Vibration Sensor': 'VBS',
    'Temperature Sensor': 'TMP',
    'VFD Ampere': 'AMPV',
    'VFD RPM': 'RPMV',
    'VFD Frequency': 'FRQV',
  },
  Valves: {
    'ON/OFF Status': 'ONOFF',
    'Triping Status': 'TRP',
    'Manual/Auto Status': 'MAN',
  },
};

export function SensorTagGenerator(
  equipmentType: string,
  equipmentConfig: any,
  sensorIndex: number,
  sensorName: string,
  sensorPostFix: string
): { sensorTag: string; sensorNickName: string } {
  switch (equipmentType) {
    case 'Tanks':
      return tankNameGenerator(equipmentConfig, sensorIndex, sensorName, sensorPostFix);
    case 'Pumps':
      return pumpNameGenerator(equipmentConfig, sensorIndex, sensorName, sensorPostFix);
    case 'Blowers':
      return blowerNameGenerator(equipmentConfig, sensorName, sensorPostFix);
    case 'Headers':
      return headerNameGenerator(equipmentConfig, sensorIndex, sensorName, sensorPostFix);
    case 'Core Equipments':
      return coreEquipmentNameGenerator(equipmentConfig, sensorName, sensorPostFix);
    case 'Flowmeter':
      return flowmeterNameGenerator(equipmentConfig, sensorName, sensorPostFix);
    case 'Valves':
      return valveNameGenerator(equipmentConfig, sensorName, sensorPostFix);
    default:
      return { sensorTag: 'NAN', sensorNickName: 'NAN' };
  }
}
function tankNameGenerator(
  equipmentConfig: any,
  sensorIndex: number,
  sensorName: string,
  sensorPostFix: string
): { sensorTag: string; sensorNickName: string } {
  let eqtName = equipmentConfig['name'];
  let eqtNum = equipmentConfig['count'];

  let eqtNameTag = eqtTypeAndTag['Tanks'][eqtName];

  let sensorIndexes = getAllIndexes(equipmentConfig['sensors'], sensorName);
  let sensorNumber = 0;
  if (sensorIndex >= 1) {
    sensorNumber = sensorIndexes.indexOf(sensorIndex);

    sensorNumber += 1;
  } else {
    sensorNumber = 1;
  }

  let sensorTypeTag = sensorTypeAndTag['Tanks'][sensorName];
  let sensorTag =
    sensorTypeTag + '_' + eqtNameTag + 'dd' + eqtNum + '_' + sensorNumber + sensorPostFix;

  let sensorNickName = '';
  if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
    sensorNickName = equipmentConfig['nickName'] + ' ' + sensorTypeTag + ' Sensor ' + sensorNumber;
  }

  return { sensorTag, sensorNickName };
}

function pumpNameGenerator(
  equipmentConfig: any,
  sensorIndex: number,
  sensorName: string,
  sensorPostFix: string
): { sensorTag: string; sensorNickName: string } {
  let inputGenTag = '';
  let outputGenTag = '';
  let backTagName = '';
  let sensorNumber = 0;
  if (equipmentConfig['In'].length >= 1) {
    equipmentConfig['In'].forEach((tank: any, index: any) => {
      let splitName = tank.split(' ');
      let eqtName = '';
      let eqtNum = '';
      if (splitName.length === 3) {
        eqtName = splitName[0] + ' ' + splitName[1];
        eqtNum = splitName[2];
      } else {
        eqtName = splitName[0] + ' ' + splitName[1] + ' ' + splitName[2];
        eqtNum = splitName[3];
      }

      inputGenTag = inputGenTag + eqtTypeAndTag['Tanks'][eqtName] + 'dd' + eqtNum;
    });
  }
  if (equipmentConfig['Out'].length >= 1) {
    equipmentConfig['Out'].forEach((tank: any, index: any) => {
      let splitName = tank.split(' ');
      let eqtName = '';
      let eqtNum = '';
      if (splitName.length === 3) {
        eqtName = splitName[0] + ' ' + splitName[1];
        eqtNum = splitName[2];
      } else {
        eqtName = splitName[0] + ' ' + splitName[1] + ' ' + splitName[2];
        eqtNum = splitName[3];
      }

      outputGenTag = outputGenTag + eqtTypeAndTag['Tanks'][eqtName] + 'dd' + eqtNum;
    });
  }

  if (equipmentConfig['tagName'] && equipmentConfig['tagName'].length >= 1) {
    let tagNameArr = equipmentConfig['tagName'].split('_');
    backTagName = tagNameArr[1];
  }

  if (equipmentConfig['tagName'] && equipmentConfig['tagName'].length >= 1) {
    let tagNameArr = equipmentConfig['tagName'].split('_');
    sensorNumber = tagNameArr[2];
  } else {
    // sensor number logic

    let sensorIndexes = getAllIndexes(equipmentConfig['sensors'], sensorName);

    if (sensorIndex >= 1) {
      sensorNumber = sensorIndexes.indexOf(sensorIndex);

      sensorNumber += 1;
    } else {
      sensorNumber = 1;
    }
  }

  let sensorTag = '';

  let sensorTypeTag = sensorTypeAndTag['Pumps'][sensorName];

  if (equipmentConfig['tagName'] && equipmentConfig['tagName'].length >= 1) {
    sensorTag = sensorTypeTag + '_' + backTagName + '_' + sensorNumber + sensorPostFix;
  } else {
    sensorTag =
      sensorTypeTag + '_' + inputGenTag + 'ss' + outputGenTag + '_' + sensorNumber + sensorPostFix;
  }
  let sensorNickName = '';
  if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
    sensorNickName = equipmentConfig['nickName'] + ' ' + sensorTypeTag + ' Sensor ' + sensorNumber;
  }

  return { sensorTag, sensorNickName };
}

function blowerNameGenerator(
  equipmentConfig: any,
  sensorName: string,
  sensorPostFix: string
): { sensorTag: string; sensorNickName: string } {
  let eqtNameTag = eqtTypeAndTag['Blowers']['Blower'];
  let eqtNum = equipmentConfig['count'];

  let sensorTypeTag = sensorTypeAndTag['Blowers'][sensorName];
  let sensorTag = sensorTypeTag + '_' + eqtNameTag + '_' + eqtNum + sensorPostFix;
  let sensorNickName = '';
  if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
    sensorNickName = equipmentConfig['nickName'] + ' ' + sensorTypeTag + ' Sensor';
  }

  return { sensorTag, sensorNickName };
}

function headerNameGenerator(
  equipmentConfig: any,
  sensorIndex: number,
  sensorName: string,
  sensorPostFix: string
): { sensorTag: string; sensorNickName: string } {
  let eqtNameTag = eqtTypeAndTag['Headers']['Header'];
  let eqtNum = equipmentConfig['count'];

  let sensorIndexes = getAllIndexes(equipmentConfig['sensors'], sensorName);

  let sensorNumber = 0;
  if (sensorIndex >= 1) {
    sensorNumber = sensorIndexes.indexOf(sensorIndex);

    sensorNumber += 1;
  } else {
    sensorNumber = 1;
  }

  let sensorTypeTag = sensorTypeAndTag['Headers'][sensorName];
  let sensorTag =
    sensorTypeTag + '_' + eqtNameTag + 'dd' + eqtNum + '_' + sensorNumber + sensorPostFix;
  let sensorNickName = '';
  if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
    sensorNickName = equipmentConfig['nickName'] + ' ' + sensorTypeTag + ' Sensor ' + sensorNumber;
  }

  return { sensorTag, sensorNickName };
}

function coreEquipmentNameGenerator(
  equipmentConfig: any,
  sensorName: string,
  sensorPostFix: string
): { sensorTag: string; sensorNickName: string } {
  let eqtNameTag = eqtTypeAndTag['Core Equipments'][equipmentConfig['name']];
  let eqtNum = equipmentConfig['count'];

  let sensorTypeTag = sensorTypeAndTag['Core Equipments'][sensorName];
  let sensorTag = '';

  if (equipmentConfig['name'] === 'Bar Screen Chamber' && sensorName.includes('Chamber')) {
    sensorTag = eqtNameTag + sensorTypeTag + '_' + eqtNum + sensorPostFix;
  } else {
    sensorTag = sensorTypeTag + '_' + eqtNameTag + '_' + eqtNum + sensorPostFix;
  }
  let sensorNickName = '';
  if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
    sensorNickName = equipmentConfig['nickName'] + ' ' + sensorTypeTag + ' Sensor';
  }

  return { sensorTag, sensorNickName };
}

function flowmeterNameGenerator(
  equipmentConfig: any,
  sensorName: string,
  sensorPostFix: string
): { sensorTag: string; sensorNickName: string } {
  let inputGenTag = '',
    outputGenTag = '',
    eqtNameTag = '',
    sensorTag = '',
    backTagName = '';
  if (
    equipmentConfig['name'] === 'Inlet Flowmeter' ||
    equipmentConfig['name'] === 'Outlet Flowmeter'
  ) {
    eqtNameTag = eqtTypeAndTag['Flowmeter'][equipmentConfig['name']][sensorName];
  } else {
    eqtNameTag = eqtTypeAndTag['Flowmeter']['Other'][sensorName];
  }

  if (equipmentConfig['In'].length >= 1) {
    equipmentConfig['In'].forEach((tank: any, index: any) => {
      let splitName = tank.split(' ');

      let eqtName = '';
      let eqtNum = '';
      if (splitName.length === 3) {
        eqtName = splitName[0] + ' ' + splitName[1];
        eqtNum = splitName[2];
      } else {
        eqtName = splitName[0] + ' ' + splitName[1] + ' ' + splitName[2];
        eqtNum = splitName[3];
      }

      inputGenTag = inputGenTag + eqtTypeAndTag['Tanks'][eqtName] + 'dd' + eqtNum;
    });
  }
  if (equipmentConfig['Out'].length >= 1) {
    equipmentConfig['Out'].forEach((tank: any, index: any) => {
      let splitName = tank.split(' ');

      let eqtName = '';
      let eqtNum = '';
      if (splitName.length === 3) {
        eqtName = splitName[0] + ' ' + splitName[1];
        eqtNum = splitName[2];
      } else {
        eqtName = splitName[0] + ' ' + splitName[1] + ' ' + splitName[2];
        eqtNum = splitName[3];
      }

      outputGenTag = outputGenTag + eqtTypeAndTag['Tanks'][eqtName] + 'dd' + eqtNum;
    });
  }
  backTagName = inputGenTag + 'ss' + outputGenTag;
  if (
    equipmentConfig['name'] === 'Inlet Flowmeter' ||
    equipmentConfig['name'] === 'Outlet Flowmeter'
  ) {
    if (sensorName === 'Flow Status') {
      sensorTag = eqtNameTag + '_' + backTagName + '_1' + sensorPostFix;
    } else {
      sensorTag = eqtNameTag + sensorPostFix;
    }
  } else {
    sensorTag = eqtNameTag + '_' + backTagName + '_1' + sensorPostFix;
  }

  let sensorNickName = '';
  if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
    sensorNickName = equipmentConfig['nickName'] + ' ' + eqtNameTag + ' Sensor';
  }

  return { sensorTag, sensorNickName };
}

function valveNameGenerator(
  equipmentConfig: any,
  sensorName: string,
  sensorPostFix: string
): { sensorTag: string; sensorNickName: string } {
  let eqtNameTag =
    sensorTypeAndTag['Valves'][sensorName] + '_' + eqtTypeAndTag['Valves'][equipmentConfig['name']];
  let inputGenTag = '';
  let outputGenTag = '';
  let backTagName = '';

  if (equipmentConfig['In'].length >= 1) {
    equipmentConfig['In'].forEach((tank: any, index: any) => {
      let splitName = tank.split(' ');
      let eqtName = '';
      let eqtNum = '';
      if (splitName.length === 3) {
        eqtName = splitName[0] + ' ' + splitName[1];
        eqtNum = splitName[2];
      } else {
        eqtName = splitName[0] + ' ' + splitName[1] + ' ' + splitName[2];
        eqtNum = splitName[3];
      }
      inputGenTag = inputGenTag + eqtTypeAndTag['Tanks'][eqtName] + 'dd' + eqtNum;
    });
  }
  if (equipmentConfig['Out'].length >= 1) {
    equipmentConfig['Out'].forEach((tank: any, index: any) => {
      let splitName = tank.split(' ');

      let eqtName = '';
      let eqtNum = '';
      if (splitName.length === 3) {
        eqtName = splitName[0] + ' ' + splitName[1];
        eqtNum = splitName[2];
      } else {
        eqtName = splitName[0] + ' ' + splitName[1] + ' ' + splitName[2];
        eqtNum = splitName[3];
      }

      outputGenTag = outputGenTag + eqtTypeAndTag['Tanks'][eqtName] + 'dd' + eqtNum;
    });
  }
  backTagName = inputGenTag + 'ss' + outputGenTag;
  let sensorTag = eqtNameTag + '_' + backTagName + '_' + equipmentConfig['count'] + sensorPostFix;
  let sensorNickName = '';
  if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
    sensorNickName =
      equipmentConfig['nickName'] + ' ' + sensorTypeAndTag['Valves'][sensorName] + ' Sensor ';
  }

  return { sensorTag, sensorNickName };
}

function getAllIndexes(arr: any, val: string) {
  var indexes = [],
    i;
  for (i = 0; i < arr.length; i++) if (arr[i]['sensorName'] === val) indexes.push(i);
  return indexes;
}

export function otherSensorDataGenerator(equipmentConfig: any, sensorPostFix: string) {
  if (equipmentConfig['name'] !== 'Energy Meter') {
    let eqtNameTag = eqtTypeAndTag['Other Sensors'][equipmentConfig['name']];
    let eqtNum = equipmentConfig['count'];
    let sensorNickName = '';
    if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
      sensorNickName = equipmentConfig['nickName'] + ' ' + eqtNameTag + ' Sensor ' + eqtNum;
    }
    let sensorTag = eqtNameTag + '_' + eqtNum + sensorPostFix;
    let sensor = {
      sensorName: equipmentConfig['name'],
      sensorTag: sensorTag,
      sensorNickName: sensorNickName,
      dateCreated: new Date(),
    };
    equipmentConfig['sensors'].push(sensor);
  } else {
    let eqtNum = equipmentConfig['count'];
    let sensorTag1 =
      eqtTypeAndTag['Other Sensors'][equipmentConfig['name']]['Energy Used Per Hour'] +
      '_' +
      eqtNum +
      sensorPostFix;
    let sensorTag2 =
      eqtTypeAndTag['Other Sensors'][equipmentConfig['name']]['Total energy Used'] +
      '_' +
      eqtNum +
      sensorPostFix;
    let sensorNickName1 = '';
    let sensorNickName2 = '';
    if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
      sensorNickName1 =
        equipmentConfig['nickName'] +
        ' ' +
        eqtTypeAndTag['Other Sensors'][equipmentConfig['name']]['Energy Used Per Hour'] +
        ' Sensor ' +
        eqtNum;
      sensorNickName2 =
        equipmentConfig['nickName'] +
        ' ' +
        eqtTypeAndTag['Other Sensors'][equipmentConfig['name']]['Total energy Used'] +
        ' Sensor ' +
        eqtNum;
    }
    let sensor1 = {
      sensorName: 'Energy Used Per Hour',
      sensorTag: sensorTag1,
      sensorNickName: sensorNickName1,
      dateCreated: new Date(),
    };
    let sensor2 = {
      sensorName: 'Total energy Used',
      sensorTag: sensorTag2,
      sensorNickName: sensorNickName2,
      dateCreated: new Date(),
    };
    equipmentConfig['sensors'].push(sensor1);
    equipmentConfig['sensors'].push(sensor2);
  }
  return equipmentConfig;
}

export function otherSensorNickNameGenerator(equipmentConfig: any, sensorIndex: number) {
  if (equipmentConfig['name'] !== 'Energy Meter') {
    let eqtNameTag = eqtTypeAndTag['Other Sensors'][equipmentConfig['name']];
    let eqtNum = equipmentConfig['count'];
    let sensorNickName = '';
    if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
      sensorNickName = equipmentConfig['nickName'] + ' ' + eqtNameTag + ' Sensor ' + eqtNum;
    }
    return sensorNickName;
  } else {
    let eqtNum = equipmentConfig['count'];
    let sensorNickName = '';

    if (equipmentConfig['nickName'] && equipmentConfig['nickName'].length > 1) {
      sensorNickName =
        equipmentConfig['nickName'] +
        ' ' +
        eqtTypeAndTag['Other Sensors'][equipmentConfig['name']][
          equipmentConfig['sensors'][sensorIndex]['sensorName']
        ] +
        ' Sensor ' +
        eqtNum;
    }
    return sensorNickName;
  }
}
