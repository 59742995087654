import {
  Dialog,
  DialogContent,
  Box,
  CircularProgress,
  Typography,
  DialogActions,
} from '@mui/material';
import { SensorStyles } from '../../components/sensors/sensors';
import {
  DialogNegativeButtonStyle,
  DialogPositiveButtonStyle,
} from '../../styles/global/components/dpButtons';
import ColoredDeleteIcon from '../../assets/icons/DeleteIcon.svg';
import color from '../../styles/color';

const DeleteConfirmationStyle = {
  DialogStyle: {
    padding: '20px',
  },
  DialogActionStyle: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  NegativeButtonStyle: {
    color: color.primaryMain,
    backgroundColor: color.dpWhite,
    border: `1px solid ${color.primaryMain}`,
  },
  PositiveButtonStyle: {
    color: color.dpWhite,
    backgroundColor: color.errorMain,
  },
};

interface IProps {
  showDeleteModal: boolean;
  setShowDeleteModal: Function;
  alertMessage: string;
  processing: boolean;
  setProcessing: Function;
  onConfirmAction: Function;
}

function DeleteConfirmation({
  showDeleteModal,
  setShowDeleteModal,
  alertMessage,
  processing,
  setProcessing,
  onConfirmAction,
}: IProps) {
  return (
    <Dialog
      open={showDeleteModal}
      onClose={() => {
        setShowDeleteModal(false);
        setProcessing(false);
      }}
    >
      {processing && (
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      )}
      {!processing && (
        <div style={DeleteConfirmationStyle.DialogStyle}>
          <DialogContent>
            <img
              src={ColoredDeleteIcon}
              alt="Colored Delete Icon"
              style={SensorStyles.IconStyles}
            />
            <Typography variant="h5">Alert !</Typography>
            <Typography>{alertMessage}</Typography>
          </DialogContent>
          <DialogActions style={DeleteConfirmationStyle.DialogActionStyle}>
            <DialogNegativeButtonStyle
              style={DeleteConfirmationStyle.NegativeButtonStyle}
              onClick={e => {
                e.stopPropagation();
                setShowDeleteModal(false);
              }}
            >
              Cancel
            </DialogNegativeButtonStyle>
            <DialogPositiveButtonStyle
              style={DeleteConfirmationStyle.PositiveButtonStyle}
              onClick={e => {
                e.stopPropagation();
                setProcessing(true);
                onConfirmAction();
                setShowDeleteModal(false);
              }}
            >
              Confirm
            </DialogPositiveButtonStyle>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
}

export default DeleteConfirmation;
