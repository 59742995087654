import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import SidebarContext from '../context/SidebarContext';
import { Toolbar, Container } from '@mui/material';
import classes from '../styles/layoutStyles.module.css';
import { useState } from 'react';
import { Box } from '@mui/material';
import useUserStore from '../store/UserStore';

type Props = {
  children?: React.ReactNode;
  plantId?: any;
  style?: any;
  factoryResetContainer?: boolean;
};

export default function LayoutComponent(props: Props) {
  const [open, setOpen] = React.useState(false);
  // const classes = useStyles();
  //const classs = { containerOpen: "co", containerClose: "CC" };
  //creating object to be passed onto Context
  const value = { open, setOpen };
  const [sideBarChange, setSideBarChange] = useState(true);

  // const sideBarMenu = JSON.parse(localStorage.getItem("sideBar")!);

  let sideBarMenu = useUserStore().sideBar || [];
  let userId = localStorage.getItem("userId");
  let showMenu = localStorage.getItem("showMenu");
  if (showMenu === null) {
    showMenu = "false";
  }
  if (showMenu === "false") {
    if (userId === "66161c78c8b2c9c00c4fefbb") {
      sideBarMenu = [
        // { name: "Plants", link: "/plants" },
        { name: "WorkForce", link: "/workforce" },
        // {
        //   name: "Manpower Allocationa and Availability",
        //   link: "/manpowerAvailability",
        // },
        // { name: "AI analysis", link: "/AIAnalysisConfig" },
      ];
    }
  }
  const refreshSideBar = () => {
    setSideBarChange(!sideBarChange);
  };

  return (
    <Box sx={{ ...props.style }}>
      <SidebarContext.Provider value={value}>
        <Header plantId={props.plantId} />
        <Toolbar />
        <Sidebar
          sideBarMenu={sideBarMenu}
          refresh={refreshSideBar}
          close={() => {
            setOpen(false);
          }}
        />
        {props.factoryResetContainer === true ? (
          <Box p={2}>{props.children}</Box>
        ) : (
          <Container
            id="container_test"
            sx={{ maxWidth: '1280px !important' }}
            className={open ? classes.containerOpen : classes.containerClose}
          >
            {props.children}
          </Container>
        )}
      </SidebarContext.Provider>
    </Box>
  );
}
