import { useState, FC, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';

interface AddPlantPopup {
  plantConfig: any;
  setPlantConfig: any;
  open: boolean;
  handleClose: any;
  otherPlantOptions: any;
  close: any;
}

const AddPlantPopup: FC<AddPlantPopup> = ({
  plantConfig,
  setPlantConfig,
  open,
  handleClose,
  otherPlantOptions,
  close,
}) => {
  const [selectedPlant, setSelectedPlant] = useState<{ id: any; name: string } | null>(null);
  const [canSave, setCanSave] = useState<boolean>(false);

  useEffect(() => {
    //getSubPlantsToBeAddedByPlantConfig
  }, []);
  async function handleCreate() {
    let newPlantConfig = plantConfig;
    if (selectedPlant) {
      let plantToInsert: any = { _id: selectedPlant.id, name: selectedPlant.name };
      if (newPlantConfig.outputPlants && newPlantConfig.outputPlants.length > 0) {
        let lastPlantInOutputPlants =
          newPlantConfig.outputPlants[newPlantConfig.outputPlants.length - 1];
        let count = lastPlantInOutputPlants.count + 1;
        let id = 'Output plant ' + count;
        plantToInsert.id = id;
        plantToInsert.count = count;
        newPlantConfig.outputPlants.push({ ...plantToInsert });
      } else {
        let count = 1;
        let id = 'Output plant ' + count;
        plantToInsert.id = id;
        plantToInsert.count = count;
        newPlantConfig.outputPlants = [{ ...plantToInsert }];
      }
      setPlantConfig(newPlantConfig);
      toast.success('Output plant added successfully');
      close(false);
    }
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Select an output plant</DialogTitle>
      <DialogContent>
        <Autocomplete
          options={otherPlantOptions}
          getOptionLabel={(option: { name: string }) => option.name}
          style={{ width: 300, margin: '20px' }}
          renderInput={params => <TextField {...params} label="Choose a plant" />}
          onChange={(event, newValue: any) => {
            setSelectedPlant(newValue);
            setCanSave(!!newValue);
          }}
          filterOptions={(options, { inputValue }) => {
            return options
              .filter(option => option.name.toLowerCase().includes(inputValue.toLowerCase()))
              .sort((a, b) => a.name.indexOf(inputValue) - b.name.indexOf(inputValue));
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        {canSave ? <Button onClick={handleCreate}>Save</Button> : null}
      </DialogActions>
    </Dialog>
  );
};

export default AddPlantPopup;
