import { Box } from '@mui/material';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import TicketPopupBox from '../IssueTrackerPopupBox/IssueTrackerPopupBox';
import { getAllTicketIdsOfAlert } from '../../services/tickets';
import { alertInterface } from '../../Interfaces/ticketsv2Interfaces';

const styles = {
  issueTrackerBox: {
    cursor: 'pointer',
    width: '100%',
    borderRadius: '10px',
    border: '1px solid #D3DCEB',
    boxSizing: 'border-box',
    fontFamily: 'Roboto',
    '&:hover': {
      backgroundColor: '#F2F7FB',
    },
  },
  issueTrackerInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '30px',
  },
  issueTrackerInfoRightHand: {
    display: 'flex',
    width: '100%',
    gap: '10px',
    flexDirection: { xs: 'column', sm: 'row' },
    justifyContent: { xs: 'flex-start', sm: 'space-between' },
    alignItems: { xs: 'flex-start', sm: 'center' },
  },
  issueTrackerHeading: {
    fontWeight: 500,
    fontSize: '14px',
  },
  issueTrackerTag: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: 12,
    width: 'fit-content',
  },
  metaDataBoldFont: {
    fontWeight: 500,
    color: '#222222',
    fontSize: '13px',
  },
};

interface IssueTrackerComponentProps {
  plants: {
    plantName: string;
    plantId: string;
  }[];
  alert: alertInterface;
  refreshStateOfParent: boolean;
  setRefreshStateOfParent: Dispatch<SetStateAction<boolean>>;
}
export default function IssueTrackerComponent(props: IssueTrackerComponentProps) {
  //@ts-ignore
  const { plants, alert, refreshStateOfParent, setRefreshStateOfParent } = props;
  const [ticketIds, setTicketIds] = useState([]);
  //@ts-ignore
  const [refresh, setRefresh] = useState(false);
  const [openTicketPopup, setOpenTicketPopup] = useState(false);

  useEffect(() => {
    getAllTicketIdsOfAlert(alert._id, alert.plantId).then(data => {
      setTicketIds(data);
    });
  }, []);
  const getNameInititals = (name: string | undefined) => {
    let nameInitials = '';
    if (name) {
      nameInitials = name.split(' ').reduce((root, word) => {
        return root + word[0];
      }, '');
    }
    return nameInitials;
  };

  return (
    <Box
      p={4}
      sx={styles.issueTrackerBox}
      onClick={() => {
        setOpenTicketPopup(true);
      }}
    >
      <Box sx={styles.issueTrackerInfoContainer}>
        <Tooltip title={alert.primaryUser?.name}>
          <Avatar sx={{ bgcolor: '#C0E3E7', width: '50px', height: '50px' }}>
            {getNameInititals(alert.primaryUser?.name).substring(0, 2)}
          </Avatar>
        </Tooltip>
        <Box className="issueTextBody" sx={styles.issueTrackerInfoRightHand}>
          <Stack className="headingAndTagsContainer" direction="column" spacing={1}>
            <span style={styles.issueTrackerHeading}>{alert.heading?.eng}</span>
            <Box className="tagContainer" style={{ display: 'flex', flexDirection: 'row' }}>
              {alert.headers?.map((tag: any) => {
                return (
                  <Box
                    m={1}
                    px={1}
                    py={1}
                    bgcolor={tag === 'verified' ? 'green' : '#F07373'}
                    borderRadius={10}
                    sx={styles.issueTrackerTag}
                  >
                    {tag}
                  </Box>
                );
              })}
            </Box>
          </Stack>
          <Stack
            className="metadataContainer"
            direction={{ xs: 'row', sm: 'column' }}
            spacing="10px"
            width={{ xs: '100%', sm: '200px' }}
            sx={{ fontSize: '13px' }}
          >
            <div>
              <span style={styles.metaDataBoldFont}>Created At{'  '}</span>
              {alert?.lastTicket?.createdAt
                ? moment(alert?.lastTicket?.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSS')?.format(
                    'DD/MM/YYYY hh:mm a'
                  )
                : '--'}
            </div>
            <div>
              <span style={styles.metaDataBoldFont}>Category {'  '}</span>
              {alert.issueType?.map((type, index) => {
                return (
                  <span style={{ marginRight: '2px' }}>
                    {type}
                    {index !== alert.issueType.length - 1 ? ',' : ''}
                  </span>
                );
              })}
              {alert.issueType == null || alert.issueType?.length < 1 ? (
                <span style={{ marginRight: '2px' }}>None</span>
              ) : null}
            </div>
            <div>
              <span style={styles.metaDataBoldFont}>
                {alert.hasOwnProperty('plantId') ? 'Plant ' : 'Plants '}
              </span>
              {alert.hasOwnProperty('plantId')
                ? plants?.filter(plant => plant.plantId === alert.plantId)[0]['plantName']
                : alert.plantNames}
            </div>
          </Stack>
        </Box>
      </Box>
      <ToastContainer />
      {openTicketPopup ? (
        <TicketPopupBox
          plants={plants}
          alert={alert}
          ticketIds={ticketIds}
          ticketopen={openTicketPopup}
          setOpenTicketPopup={setOpenTicketPopup}
          setRefreshStateOfIssueTrackerComponent={setRefresh}
          setRefreshStateOfTicketsPage={setRefreshStateOfParent}
        />
      ) : null}
    </Box>
  );
}
