import { useState } from 'react';

const RefreshPageVariables = () => {
  const [expireTime, setExpireTime] = useState<number>(Date.now() + 1000);
  const [checkboxChecked, setCheckboxChecked] = useState<boolean>(false);
  const [customRefreshInterval, setCustomRefreshInterval] = useState<number>(60);
  const [enableCustomRefreshOnly, setEnableCustomRefreshOnly] = useState<boolean>(false);

  return {
    expireTime,
    setExpireTime,
    checkboxChecked,
    setCheckboxChecked,
    customRefreshInterval,
    setCustomRefreshInterval,
    enableCustomRefreshOnly,
    setEnableCustomRefreshOnly,
  };
};

export default RefreshPageVariables;
