import axiosInstance from '../constants/axiosInstance';

const fetchSensorsList = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(`/plantManagement/getSensorList?id=${plantId}`);
    return data?.message;
  } catch (err) {
    console.error('ERROR | fetchSensorsList | ', err);
  }
};

const getSensorListForPlantId = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/getSensorListForPlantId?plantId=${plantId}`
    );
    return data?.data;
  } catch (err) {
    console.error('ERROR | fetchSensorsList | ', err);
  }
};

const updateSensorStatus = async (sensorId: string, actionDetails: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/updateSensorStatus?id=${sensorId}`,
      actionDetails
    );
    return data?.message;
  } catch (err) {
    console.error('ERROR | updateSensorStatus | ', err);
  }
};

const fetchSensorHistory = async (sensorId: string) => {
  try {
    const { data } = await axiosInstance().get(`/plantManagement/sensorHistory?id=${sensorId}`);
    return data?.message;
  } catch (err) {
    console.error('ERROR | fetchSensorHistory | ', err);
  }
};

const deleteSensor = async (sensorId: string, actionDetails: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/deleteSensor?id=${sensorId}`,
      actionDetails
    );
    return data?.message;
  } catch (err) {
    console.error('ERROR | deleteSensor | ', err);
  }
};

const addSensorComment = async (sensorId: string, actionDetails: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/updateSensorHistory?id=${sensorId}`,
      actionDetails
    );
    return data?.message;
  } catch (err) {
    console.error('ERROR | addSensorComment | ', err);
  }
};

const getSensorListBasedOnFilters = async (filters: any) => {
  try {
    const response = await axiosInstance().post(`/sensors/getSensorListBasedOnFilters`, filters);
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error('Error fetching sensor list:', error);
    throw error;
  }
};
const getSensorListForUserId = async () => {
  try {
    const response = await axiosInstance().post(`/sensors/getSensorListForUserId`);
    return response.data;
  } catch (error) {
    // Handle error appropriately
    console.error('Error fetching sensor list for clientId:', error);
    throw error;
  }
};

export {
  fetchSensorsList,
  updateSensorStatus,
  fetchSensorHistory,
  deleteSensor,
  addSensorComment,
  getSensorListForPlantId,
  getSensorListBasedOnFilters,
  getSensorListForUserId,
};
