import { create } from "zustand";
import { GRANULARITY, TIME_RANGE } from "../constants/constants";
import { formatTime } from "../utilities/helpers";

type DashboardStore = {
  plantName: string | null;
  selectedPageId: string | null;
  selectedDashboardPage: any;
  selectedGranularity: string | null;
  selectedRelativeTimeRange: string | null;
  startDate: any;
  endDate: any;
  startTime: string | null;
  endTime: string | null;
  timeRangeType: string;
  widgetDateAndTime: any;
  setPlantName: (plantName: string | null) => void;
  setSelectedPageId: (selectedPageId: string | null) => void;
  setSelectedGranularity: (selectedGranularity: string | null) => void;
  setSelectedTimeRange: (selectedRelativeTimeRange: string | null) => void;
  setSelectedDashboardPage: (selectedDashboardPage: any) => void;
  setStartDate: (startDate: string) => void;
  setEndDate: (endDate: string) => void;
  setStartTime: (startTime: string) => void;
  setEndTime: (endTime: string) => void;
  setTimeRangetype: (timeRangeType: string) => void;
  setWidgetDateAndTime: (widgetDateAndTime: any) => void;
};

export const useDashboardStore: any = create<DashboardStore>((set) => ({
  selectedPageId: "",
  selectedDashboardPage: null,
  selectedGranularity: GRANULARITY[0].label,
  selectedRelativeTimeRange: TIME_RANGE[0].label,
  startDate: new Date( new Date().valueOf() - 24 * 60 * 60 * 1000),
  endDate: new Date(),
  startTime: formatTime(new Date( new Date().valueOf() - 24 * 60 * 60 * 1000)),
  endTime: formatTime(new Date()),
  timeRangeType: "relative",
  plantName: "",
  widgetDateAndTime: {},
  setPlantName: (plantName) => set({ plantName }),
  setSelectedPageId: (selectedPageId) => set({ selectedPageId }),
  setSelectedGranularity: (selectedGranularity) => set({ selectedGranularity }),
  setSelectedTimeRange: (selectedRelativeTimeRange) => set({ selectedRelativeTimeRange }),
  setSelectedDashboardPage: (selectedDashboardPage) => set({ selectedDashboardPage }),
  setStartDate: (startDate) => set({ startDate }),
  setEndDate: (endDate) => set({ endDate }),
  setStartTime: (startTime) => set({ startTime }),
  setEndTime: (endTime) => set({ endTime }),
  setTimeRangetype: (timeRangeType) => set({ timeRangeType }),
  setWidgetDateAndTime: (widgetDateAndTime) => set({ widgetDateAndTime })
}));
