import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LayoutComponent from '../components/LayoutComponent';
import { useEffect, useState } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import CircularProgress from '@mui/material/CircularProgress';
import classes from '../styles/SolutionDetailsForChemical.module.css';
import SolutionLogsPopup from '../components/SolutionLogsPopup/SolutionLogsPopup';
import { ThemeProvider } from '@emotion/react';
import dpTheme from '../styles/theme/dpTheme';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { stubFalse } from 'lodash';
import { getSolutionsListForClient } from '../services/Solutions';
import { SolutionInterface } from '../Interfaces/SolutionInterfaces';
import axiosInstance from '../constants/axiosInstance';
import { useLocation } from 'react-router-dom';
import { SecondaryButton } from '../styles/global/components/dpButtons';
import { Button, Grid, Paper, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import styles from '../styles/pages/Store.module.css';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import { getAssetsForChemical } from '../services/ChemicalLog';
import AssetTabComponent from '../components/Tabs/AssetTabsForSelectedStore/AssetTabComponent';
import { AssetTab } from './ChemicalLog';

export default function SolutionDetailsForChemical() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const storeId = searchParams.get('storeId');
  const [assetId, setAssetId] = useState(searchParams.get('assetId'));
  const chemicalId = searchParams.get('chemicalId');
  const chemicalName = searchParams.get('chemicalName');
  const chemicalUnit = searchParams.get('chemicalUnit');
  const navigate = useNavigate();
  const [solutions, setSolutions] = useState<SolutionInterface[]>([]);
  const [totalActiveSolutions, setTotalActiveSolutions] = useState<number | string>('NA');
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [showSolutionLogsPopup, setShowSolutionLogsPopup] = useState(false);
  const [solutionId, setSolutionId] = useState<string>('');

  const [selectedStoreAssets, setSelectedStoreAssets] = useState<AssetTab[]>([]);
  const [selectedAssetTab, setSelectedAssetTab] = useState<AssetTab>({
    _id: '',
    plantName: '',
  });
  const [assetName, setAssetName] = useState<string>('All Asset');

  useEffect(() => {
    getAssetsForChemical(storeId!, chemicalId!).then((data: any) => {
      setSelectedStoreAssets(data[0]);
    });
  }, []);

  useEffect(() => {
    setSolutions([]);
    searchParams.set('assetId', assetName === 'All Asset' ? assetName : selectedAssetTab._id);
    setAssetId(assetName === 'All Asset' ? assetName : selectedAssetTab._id);
    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  }, [assetName]);

  useEffect(() => {
    getTotalActiveSolutions();
    loadMoreSolutions();
  }, [assetId]);

  const getTotalActiveSolutions = async () => {
    let { data } = await axiosInstance().get(
      `/store/getlActiveSolutionsCountForChemical?chemicalStoreId=${storeId}&assetId=${assetId}&chemicalId=${chemicalId}`
    );
    setTotalActiveSolutions(data?.data);
  };

  const loadMoreSolutions = async () => {
    setLoading(true);
    let solutionList = await getSolutionsListForClient(
      storeId!,
      assetId!,
      chemicalId!,
      solutions.length,
      solutions.length + 10
    );
    setSolutions([...solutions, ...solutionList]);
    if (solutionList.length > 0) setHasNextPage(true);
    else setHasNextPage(stubFalse);
    setLoading(false);
  };

  //@ts-ignore
  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading: loading,
    hasNextPage: hasNextPage,
    onLoadMore: loadMoreSolutions,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: undefined,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 20px 0px',
  });

  const getSolutionFinishedOnTime = (solution: SolutionInterface, format: 'date' | 'time') => {
    if (solution.solutionPending > 0) {
      return '--';
    } else {
      if (format === 'date')
        return moment(solution.modifiedOn, 'YYYY-MM-DDTHH:mm:ssZ').format('DD MMM YYYY');
      else return moment(solution.modifiedOn, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm a');
    }
  };

  const calculateDaysBetweenTwoTimeStrings = (timeString1: string, timeString2: string) => {
    let startTime = moment(timeString1);
    let endTime = moment(timeString2);

    return endTime.diff(startTime, 'days');
  };

  const calculateSolutionConcentration = (
    soluteAmount: number,
    solventAmount: number,
    soluteUnit: string,
    solventUnit: string
  ) => {
    //Convert code to kg if unit is in g
    if (soluteUnit === 'g') {
      soluteAmount = soluteAmount / 1000; // convert grams to kilograms
      soluteUnit = 'kg'; // update unit
    }

    if (soluteUnit === 'kg' && solventUnit === 'l')
      return (soluteAmount / solventAmount).toFixed(2) + ' kgs/ltr';
    else return (soluteAmount / (soluteAmount + solventAmount)).toFixed(2);
  };

  const handleRowClick = (solutionId: string) => {
    setSolutionId(solutionId);
    setShowSolutionLogsPopup(true);
  };

  const findPlantNameById = (assetId: string) => {
    console.log(assetId);
    const plant = selectedStoreAssets.find((item: any) => item._id === assetId);
    return plant?.plantName;
  };

  const isMobile1 = useIsMobile({ breakpointValue: 'sm' });
  const isMobile = useIsMobile({ breakpointValue: 'md' });

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent factoryResetContainer={true}>
        {!isMobile1 ? (
          <Grid
            container
            direction="row"
            sx={{ boxSizing: 'border-box' }}
            alignItems="center"
            pl={{ sm: 3, md: 4, lg: 4, xl: 4 }}
            pr={{ sm: 3, md: 4, lg: 4, xl: 4 }}
          >
            <Grid
              container
              item
              direction="row"
              alignItems="center"
              wrap="nowrap"
              spacing={20}
              justifyContent="space-between"
            >
              <Grid
                container
                sm={6}
                md={6}
                lg={5.5}
                xl={8}
                direction="row"
                alignItems="center"
                wrap="nowrap"
                spacing={3}
              >
                <Grid item>
                  <Button
                    onClick={() => {
                      navigate(
                        `/ChemicalLogs?storeId=${storeId}&` +
                          `assetId=${assetId}&` +
                          `chemicalId=${chemicalId}&` +
                          `chemicalName=${chemicalName}&` +
                          `chemicalUnit=${chemicalUnit}`
                      );
                    }}
                    sx={{
                      width: '50px',
                      height: '50px',
                      padding: '18px',
                      minWidth: '0',
                      border: '1px solid #DEF2F2',
                      borderRadius: '20%',
                    }}
                  >
                    <ArrowBackIcon
                      fontSize="small"
                      sx={{ paddingLeft: '10px', color: '#999999' }}
                    />
                  </Button>
                </Grid>
                <Grid item zeroMinWidth>
                  <Typography variant="h2" color="secondary.main" noWrap>
                    Solutions Details
                  </Typography>
                </Grid>
              </Grid>
              <Grid item sm={6} md={6} lg={6.5} xl={4}>
                <AssetTabComponent
                  selectedStoreAssets={selectedStoreAssets}
                  setSelectedStoreAssets={setSelectedStoreAssets}
                  selectedAssetTab={selectedAssetTab}
                  setSelectedAssetTab={setSelectedAssetTab}
                  assetName={assetName}
                  setAssetName={setAssetName}
                />
              </Grid>
            </Grid>

            <Grid
              container
              sm={12}
              md={10.5}
              lg={12}
              xl={10}
              flexWrap={!isMobile ? 'nowrap' : 'wrap'}
              gap={4}
            >
              <Grid
                container
                direction={isMobile ? 'row' : 'column'}
                gap="1.5rem"
                md={2}
                lg={2}
                xl={3}
              >
                <SecondaryButton
                  sx={{
                    padding: isMobile ? '1.1rem 5.5rem' : '1.1rem 0rem',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundColor: '#183650', // Set the background color to transparent on hover
                      cursor: 'default',
                    },
                  }}
                >
                  <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {chemicalName?.toLocaleUpperCase()}
                  </Typography>
                </SecondaryButton>
                <Button
                  sx={{
                    color: '#666',
                    padding: '1.1rem 1rem 1rem 1rem',
                    border: '1px solid #DEF2F2 !important',
                    borderRadius: '8px',
                    fontSize: '12px',
                    '&:hover': {
                      backgroundColor: 'transparent', // Set the background color to transparent on hover
                      cursor: 'default',
                    },
                  }}
                >
                  Total Active Solutions | {totalActiveSolutions}
                </Button>
              </Grid>

              <Grid container direction="column" sm={12} md={12} lg={10} xl={10}>
                <TableContainer
                  component={Paper}
                  className={styles.StoresTable}
                  sx={{
                    borderRadius: '8px',
                    overflowY: 'auto',
                    overflowX: 'auto',
                    maxHeight: '775px',
                    'scrollbar-width': 'none',
                    '&::-webkit-scrollbar': {
                      width: '0', // You can adjust this value to control the width of the scrollbar
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'transparent', // Hide the scrollbar thumb
                    },
                  }}
                >
                  <Table aria-label="Solution Details Table">
                    <TableHead
                      sx={{
                        position: 'sticky',
                        top: 0,
                        backgroundColor: '#FFF', // Set your desired background color for the header
                      }}
                    >
                      <TableRow>
                        <TableCell
                          align="center"
                          className={styles.StoreTableHeaderCellBorderBottomSolutionDetail}
                        >
                          <div className={styles.HeaderContent3}>Index</div>
                        </TableCell>
                        <TableCell
                          align="left"
                          className={styles.StoreTableHeaderCellSolutionDetail}
                        >
                          <div className={styles.HeaderContent2}>Solution Name</div>
                        </TableCell>
                        {assetName === 'All Asset' && (
                          <TableCell
                            align="center"
                            className={styles.StoreTableHeaderCellSolutionDetail}
                          >
                            <div className={styles.HeaderContent2}>Plant Name</div>
                          </TableCell>
                        )}
                        <TableCell
                          align="center"
                          className={styles.StoreTableHeaderCellSolutionDetail}
                        >
                          <div className={styles.HeaderContent2}>Concentration</div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={styles.StoreTableHeaderCellSolutionDetail}
                        >
                          <div className={styles.HeaderContent2}>Preparation Date and Time</div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={styles.StoreTableHeaderCellSolutionDetail}
                        >
                          <div className={styles.HeaderContent2}>Used By Date and Time</div>
                        </TableCell>
                        <TableCell
                          align="center"
                          className={styles.StoreTableHeaderCellSolutionDetail}
                        >
                          <div className={styles.HeaderContent}>
                            Solution Used{' '}
                            <Typography
                              style={{ fontSize: '10px', color: '#999999', fontWeight: 400 }}
                              variant="caption"
                              display="inline"
                              gutterBottom
                            >
                              (No. of Days)
                            </Typography>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={styles.StoresTableBody}>
                      {solutions.map((solution, index) => (
                        <TableRow
                          key={solution._id}
                          hover={true}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                            '&:hover': { backgroundColor: '#F0F9F9 !important' },
                          }}
                          onClick={() => {
                            handleRowClick(solution._id);
                          }}
                          className={styles.StoresTableRow}
                        >
                          <TableCell align="center" className={styles.StoresTableRowCell}>
                            {index + 1}
                          </TableCell>
                          <TableCell
                            align="left"
                            className={styles.StoresTableRowCell}
                            sx={{ paddingLeft: '22px !important' }}
                          >
                            {solution.solutionName}
                          </TableCell>
                          {assetName === 'All Asset' && (
                            <TableCell
                              align="left"
                              className={styles.StoresTableRowCell}
                              sx={{ paddingLeft: '25px !important' }}
                            >
                              {findPlantNameById(solution.plantId)}
                            </TableCell>
                          )}
                          <TableCell align="center" className={styles.StoresTableRowCell}>
                            {calculateSolutionConcentration(
                              solution.chemicalQuantity,
                              solution.waterQuantity,
                              solution.chemicalUnit,
                              solution.solventUnit
                            )}
                          </TableCell>
                          <TableCell
                            className={styles.StoresTableRowCell}
                            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                          >
                            <Stack direction="column">
                              <span>
                                {moment(solution.createdOn, 'YYYY-MM-DDTHH:mm:ssZ').format(
                                  'DD MMM YYYY'
                                )}
                              </span>
                              <span style={{ color: '#999999E5', fontSize: '12px' }}>
                                {moment(solution.createdOn, 'YYYY-MM-DDTHH:mm:ssZ').format(
                                  'HH:mm a'
                                )}
                              </span>
                            </Stack>
                          </TableCell>

                          <TableCell align="center" className={styles.StoresTableRowCell}>
                            <Stack direction="column">
                              <span>{getSolutionFinishedOnTime(solution, 'date')}</span>
                              <span style={{ color: '#999999E5', fontSize: '12px' }}>
                                {getSolutionFinishedOnTime(solution, 'date')}
                              </span>
                            </Stack>
                          </TableCell>
                          <TableCell align="center" className={styles.StoresTableRowCell}>
                            {solution.solutionPending >= 0
                              ? '--'
                              : calculateDaysBetweenTwoTimeStrings(
                                  solution.createdOn,
                                  solution.modifiedOn
                                )}
                          </TableCell>
                        </TableRow>
                      ))}
                      {(loading || hasNextPage) && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Box className={classes.infiniteScrollerLoader} ref={sentryRef}>
                              <CircularProgress size="30px" color="inherit" />
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                      {solutions.length === 0 && !(loading || hasNextPage) && (
                        <TableRow>
                          <TableCell colSpan={6}>
                            <Box className={classes.infiniteScrollerLoader} ref={sentryRef}>
                              Solution Not Present
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Grid container direction="column" pl={3} pr={3} pt={1} pb={1}>
            <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={2}>
              <Grid item>
                <Button
                  onClick={() => {
                    navigate(
                      `/ChemicalLogs?storeId=${storeId}&` +
                        `assetId=${assetId}&` +
                        `chemicalId=${chemicalId}&` +
                        `chemicalName=${chemicalName}&` +
                        `chemicalUnit=${chemicalUnit}`
                    );
                  }}
                  sx={{
                    width: '50px',
                    height: '50px',
                    padding: '18px',
                    minWidth: '0',
                    border: '1px solid #DEF2F2',
                    borderRadius: '20%',
                  }}
                >
                  <ArrowBackIcon fontSize="small" sx={{ paddingLeft: '10px', color: '#999999' }} />
                </Button>
              </Grid>
              <Grid item zeroMinWidth>
                <Typography variant="h4" color="secondary.main" noWrap>
                  Solutions Details
                </Typography>
              </Grid>
            </Grid>

            <Grid item mb={3} mt={3}>
              <SecondaryButton
                sx={{
                  width: '100%',
                  padding: '1rem 2.5rem',
                  borderRadius: '8px',
                  '&:hover': {
                    backgroundColor: '#183650', // Set the background color to transparent on hover
                    cursor: 'default',
                  },
                }}
              >
                <Typography variant="h5">{chemicalName?.toLocaleUpperCase()}</Typography>
              </SecondaryButton>
            </Grid>

            <Button
              sx={{
                marginBottom: '1rem',
                color: '#666',
                padding: '1.1rem 1rem 1rem 1rem',
                border: '1px solid #DEF2F2 !important',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: 'transparent', // Set the background color to transparent on hover
                  cursor: 'default',
                },
              }}
            >
              Total Active Solutions | {totalActiveSolutions}
            </Button>

            <AssetTabComponent
              selectedStoreAssets={selectedStoreAssets}
              setSelectedStoreAssets={setSelectedStoreAssets}
              selectedAssetTab={selectedAssetTab}
              setSelectedAssetTab={setSelectedAssetTab}
              assetName={assetName}
              setAssetName={setAssetName}
            />

            <TableContainer
              component={Paper}
              className={styles.StoresTable}
              sx={{
                borderRadius: '8px',
                overflowY: 'auto',
                overflowX: 'auto',
                'scrollbar-width': 'none',
                maxHeight: '775px',
                '&::-webkit-scrollbar': {
                  width: '0', // You can adjust this value to control the width of the scrollbar
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'transparent', // Hide the scrollbar thumb
                },
              }}
            >
              <Table aria-label="Solution Details Table">
                <TableHead
                  sx={{
                    position: 'sticky',
                    top: 0,
                    backgroundColor: '#FFF', // Set your desired background color for the header
                  }}
                >
                  <TableRow>
                    <TableCell
                      align="center"
                      className={styles.StoreTableHeaderCellBorderBottomSolutionDetail}
                    >
                      <div className={styles.HeaderContent3}>Index</div>
                    </TableCell>
                    <TableCell align="left" className={styles.StoreTableHeaderCellSolutionDetail}>
                      <div className={styles.HeaderContent2}>Solution Name</div>
                    </TableCell>
                    {assetName === 'All Asset' && (
                      <TableCell
                        align="center"
                        className={styles.StoreTableHeaderCellSolutionDetail}
                      >
                        <div className={styles.HeaderContent2}>Plant Name</div>
                      </TableCell>
                    )}
                    <TableCell align="center" className={styles.StoreTableHeaderCellSolutionDetail}>
                      <div className={styles.HeaderContent2}>Concentration</div>
                    </TableCell>
                    <TableCell align="center" className={styles.StoreTableHeaderCellSolutionDetail}>
                      <div className={styles.HeaderContent2}>Preparation Date and Time</div>
                    </TableCell>
                    <TableCell align="center" className={styles.StoreTableHeaderCellSolutionDetail}>
                      <div className={styles.HeaderContent2}>Used By Date and Time</div>
                    </TableCell>
                    <TableCell align="center" className={styles.StoreTableHeaderCellSolutionDetail}>
                      <div className={styles.HeaderContent}>
                        Solution Used{' '}
                        <Typography
                          style={{ fontSize: '10px', color: '#999999', fontWeight: 400 }}
                          variant="caption"
                          display="inline"
                          gutterBottom
                        >
                          (No. of Days)
                        </Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={styles.StoresTableBody}>
                  {solutions.map((solution, index) => (
                    <TableRow
                      key={solution._id}
                      hover={true}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '&:hover': { backgroundColor: '#F0F9F9 !important' },
                      }}
                      onClick={() => {
                        handleRowClick(solution._id);
                      }}
                      className={styles.StoresTableRow}
                    >
                      <TableCell align="center" className={styles.StoresTableRowCell}>
                        {index + 1}
                      </TableCell>
                      <TableCell
                        align="left"
                        className={styles.StoresTableRowCell}
                        sx={{ paddingLeft: '22px !important' }}
                      >
                        {solution.solutionName}
                      </TableCell>
                      {assetName === 'All Asset' && (
                        <TableCell align="center" className={styles.StoresTableRowCell}>
                          {findPlantNameById(solution.plantId)}
                        </TableCell>
                      )}
                      <TableCell align="center" className={styles.StoresTableRowCell}>
                        {calculateSolutionConcentration(
                          solution.chemicalQuantity,
                          solution.waterQuantity,
                          solution.chemicalUnit,
                          solution.solventUnit
                        )}
                      </TableCell>
                      <TableCell
                        className={styles.StoresTableRowCell}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      >
                        <Stack direction="column">
                          <span>
                            {moment(solution.createdOn, 'YYYY-MM-DDTHH:mm:ssZ').format(
                              'DD MMM YYYY'
                            )}
                          </span>
                          <span style={{ color: '#999999E5', fontSize: '12px' }}>
                            {moment(solution.createdOn, 'YYYY-MM-DDTHH:mm:ssZ').format('HH:mm a')}
                          </span>
                        </Stack>
                      </TableCell>
                      <TableCell align="center" className={styles.StoresTableRowCell}>
                        <Stack direction="column">
                          <span>{getSolutionFinishedOnTime(solution, 'date')}</span>
                          <span style={{ color: '#999999E5', fontSize: '12px' }}>
                            {getSolutionFinishedOnTime(solution, 'date')}
                          </span>
                        </Stack>
                      </TableCell>
                      <TableCell align="center" className={styles.StoresTableRowCell}>
                        {solution.solutionPending > 0
                          ? '--'
                          : calculateDaysBetweenTwoTimeStrings(
                              solution.createdOn,
                              solution.modifiedOn
                            )}
                      </TableCell>
                    </TableRow>
                  ))}

                  {(loading || hasNextPage) && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box className={classes.infiniteScrollerLoader} ref={sentryRef}>
                          <CircularProgress size="30px" color="inherit" />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}

                  {solutions.length === 0 && !(loading || hasNextPage) && (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <Box className={classes.infiniteScrollerLoader} ref={sentryRef}>
                          Solution Not Present
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
        {showSolutionLogsPopup ? (
          <SolutionLogsPopup
            solutionId={solutionId}
            closePopup={() => {
              setShowSolutionLogsPopup(false);
            }}
          />
        ) : null}
      </LayoutComponent>
    </ThemeProvider>
  );
}
