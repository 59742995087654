import { Box, FormControl, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import classes from "../../styles/pages/DosingConfiguration.module.css";
import Stack from "@mui/material/Stack";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import {
  // TankRelationInterface,
  // TankOfTankRelationInterface,
  TankConnectorOfTankRelationInterface,
  SensorOfTankRelationInteface,
} from "../../Interfaces/DosingConfigurationIntefaces";
import axiosInstance from "../../constants/axiosInstance";

export default function DosingTankRelation(props: any) {
  const {
    plantId,
    relation,
    tankOptions,
    handleTankRelationChange,
    index,
    deleteTankRelation,
  } = props;

  const [tankConnectorOptions, setTankConnectorOptions] = useState<
    TankConnectorOfTankRelationInterface[]
  >([]);
  const [sensorOptions, setSensorOptions] = useState<
    SensorOfTankRelationInteface[]
  >([]);
  const [refreshThisComponent, setRefreshThisComponent] =
    useState<Boolean>(false);

  useEffect(() => {
    // setTankOptions(mockTanks);
    initialOptionFetch();
  }, [tankOptions]);

  const initialOptionFetch = async () => {
    let selectedTankId = getCurrentSelectedTankId(relation, tankOptions);
    if (selectedTankId) {
      let tankConnOptions = await fetchConnectorOptions(
        plantId!,
        selectedTankId
      );
      let selectedConnectorId = getCurrentSelectedConnectorId(
        relation,
        tankConnOptions
      );
      if (selectedConnectorId) {
        fetchSensorOptions(plantId, selectedConnectorId);
      }
    }
    setRefreshThisComponent(!refreshThisComponent);
  };

  const fetchConnectorOptions = async (plantId: string, tankId: string) => {
    const { data } = await axiosInstance().post(
      `/plants/fetchTankConenctorListForDosingConfig`,
      { plantId: plantId, tankId: tankId }
    );
    let connectorList = data.data;
    setTankConnectorOptions(connectorList);
    // setRefreshThisComponent(!refreshThisComponent);
    return connectorList;
  };

  const fetchSensorOptions = async (plantId: string, componentId: string) => {
    const { data } = await axiosInstance().post(
      `/plants/fetchSensorListForDosingConfig`,
      { plantId: plantId, componentId: componentId }
    );
    let sensorList = data.data;
    setSensorOptions(sensorList);
    // setRefreshThisComponent(!refreshThisComponent);
  };

  const getCurrentSelectedTankId = (tankRelation: any, tankOptions: any) => {
    let selectedTank = tankOptions.find((tank: any) => {
      return tank.id === tankRelation.tank.id;
    });
    return selectedTank?.id;
  };

  const getCurrentSelectedConnectorId = (
    tankRelation: any,
    ConnectorOptions: any
  ) => {
    let selectedTank = ConnectorOptions.find((connector: any) => {
      return connector.id === tankRelation.tankConnector.id;
    });
    return selectedTank?.id;
  };

  const getCurrentSelectedSensorId = (
    tankRelation: any,
    sensorOptions: any
  ) => {
    let selectedSensor = sensorOptions.find((sensor: any) => {
      return sensor.id === tankRelation.sensor.id;
    });
    console.log("selectedSensor : ", selectedSensor);
    return selectedSensor?.id;
  };

  const handleTankChange = (tankId: any) => {
    let selectedTank = tankOptions.find((tank: any) => {
      if (tank.id === tankId) return true;
      else return false;
    });
    relation["tank"] = selectedTank;
    handleTankRelationChange(index, "tank", selectedTank);
    fetchConnectorOptions(plantId, selectedTank?.id!);
  };

  const handleConnectorChange = (connecterId: any) => {
    let selectedConnector = tankConnectorOptions.find((connector: any) => {
      if (connector.id === connecterId) return true;
      else return false;
    });
    relation["tankConnector"] = selectedConnector;
    handleTankRelationChange(index, "tankConnector", selectedConnector);
    fetchSensorOptions(plantId, selectedConnector?.id!);
  };

  const handleSensorChange = (sensorId: string) => {
    let selectedSensor = sensorOptions.find((sensor: any) => {
      if (sensor.id === sensorId) return true;
      else return false;
    });
    relation["sensor"] = selectedSensor;
    handleTankRelationChange(index, "sensor", selectedSensor);
  };

  return (
    <Box className={classes.relationContainer}>
      <Box m={1} className={classes.editableField}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="tank-field-label">Tank</InputLabel>
          <Select
            labelId="tank-field-label"
            id={relation.tank.id + index}
            value={getCurrentSelectedTankId(relation, tankOptions) || ""}
            label="Tanks"
            onChange={(e) => {
              handleTankChange(e.target.value);
              // handleTankRelationChange(e.target.value, "tank", index);
            }}
          >
            {tankOptions?.map((option: any) => {
              return <MenuItem value={option.id}>{option.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
      <Box m={1} className={classes.editableField}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="tankConnector-field-label">Connector</InputLabel>
          <Select
            labelId="tankConnector-field-label"
            id="demo-simple-select"
            value={
              getCurrentSelectedConnectorId(relation, tankConnectorOptions) ||
              ""
            }
            label="Connector"
            onChange={(e) => {
              handleConnectorChange(e.target.value);
              // handleTankRelationChange(e.target.value, "tankConnector", index);
            }}
          >
            {tankConnectorOptions?.map((option) => {
              return <MenuItem value={option.id}>{option.name}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
      <Box m={1} className={classes.editableField}>
        <Stack direction="column" gap={-0.5}>
          <div>
            <Checkbox
              size="small"
              checked={relation.dosingMechanism === "automatic" ? true : false}
              disabled={tankConnectorOptions.length === 0}
              onChange={(e) => {
                handleTankRelationChange(
                  index,
                  "dosingMechanism",
                  e.target.checked ? "automatic" : "manual"
                );
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<RadioButtonCheckedIcon />}
            />{" "}
            Automatic
          </div>
          <div>
            <Checkbox
              size="small"
              checked={relation.dosingMechanism === "manual" ? true : false}
              onChange={(e) => {
                handleTankRelationChange(
                  index,
                  "dosingMechanism",
                  e.target.checked ? "manual" : "automatic"
                );
              }}
              icon={<RadioButtonUncheckedIcon />}
              checkedIcon={<RadioButtonCheckedIcon />}
            />{" "}
            Manual
          </div>
        </Stack>
      </Box>
      <Box m={1} className={classes.editableField}>
        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="sensor-field-label">Sensor</InputLabel>
          <Select
            labelId="sensor-field-label"
            id="demo-simple-select"
            value={getCurrentSelectedSensorId(relation, sensorOptions) || ""}
            label="Connector"
            disabled={relation.dosingMechanism === "manual" ? true : false}
            onChange={(e) => {
              handleSensorChange(e.target.value);
            }}
          >
            {sensorOptions?.map((option) => {
              return (
                <MenuItem
                  disabled={
                    !option.name?.includes(`ON/OFF`) ||
                    !option.sensorTag?.includes(`ONOFF`)
                  }
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box m={1} className={classes.editableField}>
        <TextField
          id="outlined-basic"
          label="Default flow per min"
          type="number"
          variant="outlined"
          defaultValue={relation.defaultFlowRate}
          sx={{ width: "100%" }}
          onBlur={(e) => {
            handleTankRelationChange(index, "defaultFlowRate", e.target.value);
          }}
        />
      </Box>

      <DeleteIcon
        fontSize="medium"
        className={classes.deleteButton}
        onClick={() => {
          deleteTankRelation(index);
        }}
      />
    </Box>
  );
}
