export default function RoleIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.99985 8.66667C7.23985 8.66667 8.27318 7.81333 8.57318 6.66667H3.42651C3.72651 7.81333 4.75985 8.66667 5.99985 8.66667Z"
        fill="#183650"
      />
      <path
        d="M3.15984 6H8.8465C9.0265 6 9.17317 5.85333 9.17317 5.67333V5.66C9.17317 5.48 9.0265 5.33333 8.8465 5.33333H8.6665C8.6665 4.34667 8.1265 3.5 7.33317 3.03333V3.66667C7.33317 3.85333 7.1865 4 6.99984 4C6.81317 4 6.6665 3.85333 6.6665 3.66667V2.76C6.45317 2.70667 6.23317 2.66667 5.99984 2.66667C5.7665 2.66667 5.5465 2.70667 5.33317 2.76V3.66667C5.33317 3.85333 5.1865 4 4.99984 4C4.81317 4 4.6665 3.85333 4.6665 3.66667V3.03333C3.87317 3.5 3.33317 4.34667 3.33317 5.33333H3.15984C2.97984 5.33333 2.83317 5.48 2.83317 5.66V5.68C2.83317 5.85333 2.97984 6 3.15984 6Z"
        fill="#183650"
      />
      <path
        d="M13.2798 7.78667L12.9465 7.20667C12.9265 7.16667 12.8798 7.15333 12.8465 7.16667L12.4332 7.33333C12.3465 7.26667 12.2532 7.21333 12.1532 7.17333L12.0932 6.73333C12.0798 6.69333 12.0398 6.66667 11.9998 6.66667H11.3332C11.2932 6.66667 11.2598 6.69333 11.2532 6.74L11.1932 7.18C11.0932 7.22 10.9998 7.28 10.9132 7.34L10.4998 7.17333C10.4598 7.16 10.4198 7.17333 10.3998 7.21333L10.0665 7.79333C10.0465 7.83333 10.0532 7.87333 10.0865 7.9L10.4398 8.17333C10.4332 8.22667 10.4265 8.28 10.4265 8.33333C10.4265 8.38667 10.4332 8.44667 10.4398 8.49333L10.0865 8.76667C10.0532 8.79333 10.0465 8.84 10.0665 8.87333L10.3998 9.45333C10.4198 9.49333 10.4665 9.50667 10.4998 9.49333L10.9132 9.32667C10.9998 9.39333 11.0932 9.44667 11.1932 9.48667L11.2532 9.92667C11.2598 9.97333 11.2932 10 11.3332 10H11.9998C12.0398 10 12.0798 9.97333 12.0798 9.92667L12.1398 9.48667C12.2398 9.44667 12.3332 9.38667 12.4198 9.32667L12.8332 9.49333C12.8732 9.50667 12.9132 9.49333 12.9332 9.45333L13.2665 8.87333C13.2865 8.83333 13.2798 8.79333 13.2465 8.76667L12.8998 8.49333C12.9065 8.44 12.9132 8.38667 12.9132 8.33333C12.9132 8.28 12.9065 8.22 12.8998 8.17333L13.2532 7.9C13.2865 7.87333 13.2932 7.82667 13.2798 7.78667ZM11.6665 8.88667C11.3598 8.88667 11.1132 8.63333 11.1132 8.33333C11.1132 8.02667 11.3665 7.78 11.6665 7.78C11.9665 7.78 12.2198 8.03333 12.2198 8.33333C12.2198 8.64 11.9732 8.88667 11.6665 8.88667Z"
        fill="#183650"
      />
      <path
        d="M14.7332 4.56C14.7398 4.48667 14.7465 4.41333 14.7465 4.33333C14.7465 4.25333 14.7398 4.18 14.7265 4.10667L15.2198 3.72C15.2665 3.68667 15.2732 3.62 15.2465 3.57333L14.7798 2.76667C14.7532 2.71333 14.6865 2.7 14.6398 2.71333L14.0665 2.94667C13.9465 2.85333 13.8132 2.78 13.6732 2.72L13.5865 2.1C13.5732 2.04 13.5265 2 13.4665 2H12.5332C12.4732 2 12.4265 2.04 12.4198 2.1L12.3332 2.72C12.1932 2.78 12.0598 2.86 11.9398 2.94667L11.3598 2.71333C11.3065 2.69333 11.2465 2.71333 11.2198 2.76667L10.7532 3.57333C10.7265 3.62667 10.7332 3.68667 10.7798 3.72L11.2732 4.10667C11.2598 4.18 11.2532 4.26 11.2532 4.33333C11.2532 4.40667 11.2598 4.48667 11.2732 4.56L10.7798 4.94667C10.7332 4.98 10.7265 5.04667 10.7532 5.09333L11.2198 5.9C11.2465 5.95333 11.3132 5.96667 11.3598 5.95333L11.9398 5.72C12.0598 5.81333 12.1932 5.88667 12.3332 5.94667L12.4198 6.56667C12.4265 6.62667 12.4732 6.66667 12.5332 6.66667H13.4665C13.5265 6.66667 13.5732 6.62667 13.5798 6.56667L13.6665 5.94667C13.8065 5.88667 13.9398 5.80667 14.0598 5.72L14.6398 5.95333C14.6932 5.97333 14.7532 5.95333 14.7798 5.9L15.2465 5.09333C15.2732 5.04 15.2665 4.98 15.2198 4.94667L14.7332 4.56ZM12.9998 5.16667C12.5398 5.16667 12.1665 4.79333 12.1665 4.33333C12.1665 3.87333 12.5398 3.5 12.9998 3.5C13.4598 3.5 13.8332 3.87333 13.8332 4.33333C13.8332 4.79333 13.4598 5.16667 12.9998 5.16667Z"
        fill="#183650"
      />
      <path
        d="M5.99984 10C4.21984 10 0.666504 10.8933 0.666504 12.6667V14H11.3332V12.6667C11.3332 10.8933 7.77984 10 5.99984 10Z"
        fill="#183650"
      />
    </svg>
  );
}
