import { useState, useEffect } from 'react';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Typography,
  Chip,
  FormHelperText,
  CircularProgress,
  IconButton,
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Checkbox,
  Paper,
  ListSubheader,
  PaperProps,
} from '@mui/material';
import { createStore, getAllClients, getSelectedClientAssets } from '../../services/stores';
import { IPlant, IUser } from '../../Interfaces/storeConfigurationInterfaces';
import styles from './StoreConfigurationComponents.module.css';
import { toast } from 'react-toastify';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { makeStyles, createStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import StoreAbbreviationSelection from './StoreAbbreviationSelection';

interface IProps {
  openCreateStoreDialog: boolean;
  handleCreateStoreDialogClose: (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown' | 'close'
  ) => void;
  refreshData: Function;
}

const CustomPaper: React.FC<PaperProps> = props => {
  const theme = useTheme();

  return (
    <Paper
      {...props}
      sx={{
        '& .MuiListSubheader-root': {
          fontSize: '16px',
          color: 'rgba(0, 0, 0, 0.54)',
          paddingTop: theme.spacing(1),
          paddingBottom: '0 !important',
          marginBottom: '0 !important',
        },
        '& .MuiAutocomplete-listbox': {
          paddingTop: '0 !important',
        },
      }}
    >
      <ListSubheader>List of Assets</ListSubheader>
      {props.children}
    </Paper>
  );
};

const useStyles = makeStyles(theme =>
  createStyles({
    option: {
      '&[data-focus="true"]': {
        backgroundColor: 'white !important',
      },
      '&[aria-selected="true"]': {
        backgroundColor: 'white !important',
      },
    },
  })
);

function CreateStoreDialog({
  openCreateStoreDialog,
  handleCreateStoreDialogClose,
  refreshData,
}: IProps) {
  const [storeName, setStoreName] = useState('');
  const [storeNameOnceBlur, setStoreNameOnceBlur] = useState('');
  const [clients, setClients] = useState<any>([]);
  const [selectedClient, setSelectedClient] = useState<string | null>('');
  const [assets, setAssets] = useState<IPlant[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [assetsError, setAssetsError] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [selectedClientObj, setSelectedClientObj] = useState<IUser | null>(null);
  const classes = useStyles();
  const [storeNameError, setStoreNameError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [abbr, setAbbr] = useState('');
  const [abbrError, setAbbrError] = useState(false);
  const [showStoreAbbr, setShowStoreAbbr] = useState(false);

  // Fetch clients data
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const data = await getAllClients();
        setClients(data);
        setFilteredOptions(data);
      } catch (err) {
        console.error('Error fetching clients:', err);
      }
    };
    fetchClients();
  }, []);

  // Fetch assets data when selectedClient changes
  useEffect(() => {
    if (selectedClient) {
      getSelectedClientAssets(selectedClient).then(assetsData => {
        if (assetsData && assetsData.length > 0) {
          setAssets(assetsData);
          setAssetsError(false);
        } else {
          // Handle empty assets array
          setAssets([]);
          setAssetsError(true);
        }
      });
    } else {
      setAssets([]);
      setAssetsError(false);
    }
  }, [selectedClient]);

  const handleStoreNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setStoreName(newValue);
  };

  const handleStoreNameBlur = () => {
    // Check for alphanumeric characters
    const isAlphanumeric = /^[a-z0-9\s]*$/i.test(storeName);

    if (!isAlphanumeric && storeName !== '') {
      setStoreNameError(`Store name can't contain special characters`);
      setShowStoreAbbr(false);
    } else if (storeName.length > 0 && storeName.length < 4) {
      setStoreNameError(
        `Store name should be at least 4 characters long to get store abbreviation`
      );
      setShowStoreAbbr(false);
    } else {
      setStoreNameError('');
      setStoreNameOnceBlur(storeName);
      if (storeName.length > 3 && storeName !== '') setShowStoreAbbr(true);
      else setShowStoreAbbr(false);
    }
  };

  const handleClientChange = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: IUser | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<IUser>
  ) => {
    setSelectedClient(newValue?._id || null);
    setSelectedClientObj(newValue);
    setSelectedAssets([]);
  };

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: string) => {
    setInputValue(newInputValue);
    setFilteredOptions(
      clients.filter(
        (option: any) =>
          option?.name.toLowerCase().includes(newInputValue.toLowerCase()) ||
          option?.email.toLowerCase().includes(newInputValue.toLowerCase())
      )
    );
  };

  const handleAssetChange = (event: React.ChangeEvent<{}>, newValue: IPlant[]) => {
    setSelectedAssets(newValue.map(asset => asset._id));
  };

  const handleCreateStore = async () => {
    if (
      !storeName ||
      !selectedClient ||
      selectedAssets.length === 0 ||
      !abbr ||
      abbr === '' ||
      abbr === undefined ||
      abbr === null ||
      abbr.length === 0
    ) {
      toast.error('Please fill all the required fields.');
      return;
    }
    const payload = {
      name: storeName,
      assets: selectedAssets,
      clients: [selectedClient],
      storeAbbr: abbr,
    };

    try {
      setIsCreating(true);
      const response = await createStore(payload);
      toast.success(`Successful creation of store: ${response.name}`);
      handleCreateStoreDialogClose({}, 'close');
      setIsCreating(false);
      refreshData();
    } catch (error) {
      toast.error('Error creating store');
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Dialog
      open={openCreateStoreDialog}
      onClose={handleCreateStoreDialogClose}
      PaperProps={{
        className: styles.CustomDialogPaper,
      }}
    >
      <DialogTitle>
        <Typography variant="h4">Create New Store</Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleCreateStoreDialogClose({}, 'close')}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.DialogContent}>
        <Stack spacing={3}>
          <Stack>
            <TextField
              label="Store Name"
              value={storeName}
              onChange={handleStoreNameChange}
              onBlur={handleStoreNameBlur}
              fullWidth
              required
              error={storeNameError.length > 0}
            />

            {showStoreAbbr && (
              <StoreAbbreviationSelection
                storeName={storeNameOnceBlur}
                abbr={abbr}
                setAbbr={setAbbr}
                setAbbrError={setAbbrError}
                component="create"
              />
            )}
            {storeNameError && (
              <FormHelperText className={styles.FormHelperText} error>
                {storeNameError}
              </FormHelperText>
            )}
          </Stack>
          <Autocomplete
            value={selectedClientObj}
            onChange={handleClientChange}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={filteredOptions}
            getOptionLabel={option => {
              return `${option.name} | ${option.email}` || '';
            }}
            renderOption={(props, option: IUser) => (
              <li {...props}>
                <Stack direction="column" alignItems="flex-start">
                  <Typography variant="body1">{option.name}</Typography>
                  <Typography variant="body2" sx={{ fontSize: '0.8rem', color: '#C4C4C4' }}>
                    {option.email}
                  </Typography>
                </Stack>
              </li>
            )}
            renderInput={params => <TextField {...params} label="Client Name" required />}
            fullWidth
            isOptionEqualToValue={(option, value) => option._id === value._id}
          />
          <Autocomplete
            multiple
            options={assets}
            disableCloseOnSelect
            getOptionLabel={option => option.plantName}
            value={assets.filter(asset => selectedAssets.includes(asset._id))}
            onChange={handleAssetChange}
            PaperComponent={CustomPaper}
            classes={{
              option: classes.option,
            }}
            renderOption={(props, option: IPlant, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  checked={selected}
                />
                {option.plantName}
              </li>
            )}
            renderTags={(value: IPlant[], getTagProps) => {
              const extraCount = value.length > 2 ? `+${value.length - 2}` : null;
              return (
                <>
                  {value.slice(0, 2).map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option.plantName}
                      {...getTagProps({ index })}
                      key={option._id}
                    />
                  ))}
                  {extraCount && (
                    <span style={{ marginLeft: 8, verticalAlign: 'middle' }}>{extraCount}</span>
                  )}
                </>
              );
            }}
            renderInput={params => (
              <TextField {...params} label="Assets" variant="outlined" fullWidth margin="normal" />
            )}
            disabled={!selectedClient || assetsError}
            sx={{
              marginTop: 'unset !important',
              '&.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input': {
                padding: '7.5px 0 !important',
                minWidth: '0 !important',
              },
            }}
          />
          {assetsError && (
            <FormHelperText className={styles.FormHelperText} error>
              No assets present for this client.
            </FormHelperText>
          )}
        </Stack>
      </DialogContent>
      <DialogActions className={styles.DialogActions}>
        <Button
          onClick={handleCreateStore}
          disabled={
            isCreating ||
            !storeName.trim() ||
            !selectedClient ||
            selectedAssets.length === 0 ||
            storeNameError.length > 0 ||
            abbrError
          }
          className={styles.DialogCreateActionButton}
          fullWidth
        >
          {isCreating ? <CircularProgress size={24} color="inherit" /> : 'CREATE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateStoreDialog;
