import { create } from 'zustand';

interface IStore {
  _id: string;
  name: string;
  assets: any;
  clients: any;
  archived: boolean;
  totalItems: number;
}

interface IAssets {
  _id: string,
  plantName: string
}

interface StoreState {
  storesData: IStore[];
  setStoresData: (storesData: IStore[]) => void;
  selectedStoreAssets: IAssets[];
  setSelectedStoreAssets: (selectedStoreAssets: IAssets[]) => void;
  storeName: string;
  setStoreName: (storeName: string) => void;
  setSelectedStoreTab: (selectedStoreTab: { _id: string; name: string }) => void;
  selectedStoreTab: { _id: string; name: string };
  selectedAssetTab: { _id: string; name: string };
  setSelectedAssetTab: (selectedAssetTab: { _id: string; name: string }) => void;
  assetName: string;
  setAssetName: (assetName: string) => void;
}

export const useTabs = create<StoreState>((set) => ({
  storesData: [],
  setStoresData: (storesData) => set({ storesData }),
  selectedStoreAssets: [],
  setSelectedStoreAssets: (selectedStoreAssets) => set({ selectedStoreAssets }),
  storeName: "All Store",
  setStoreName: (storeName) => set({ storeName }),
  setSelectedStoreTab: (selectedStoreTab) => set({ selectedStoreTab }),
  selectedStoreTab: { _id: '', name: '' },
  selectedAssetTab: { _id: '', name: '' },
  setSelectedAssetTab: (selectedAssetTab) => set({ selectedAssetTab }),
  assetName: "All Asset",
  setAssetName: (assetName) => set({ assetName }),
}));