import React from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Stack, IconButton, TextField } from '@mui/material';
import LayoutComponent from '../components/LayoutComponent';
import { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import lodash from 'lodash';
import { toast } from 'react-toastify';
import WidgetConfigComponentV2 from '../components/widgetConfigComponentsV2/widgetConfigComponentV2';
import { lastMovedWidgetInterface } from '../Interfaces/pageConfigPageInterfaces';
import { plantDatainterface } from '../Interfaces/plantInterfaces';
import { BlackButton } from '../styles/global/components/dpButtons';
import {
  findClusterIdByPageId,
  getPageConfigDataV2,
  savePageConfigDataV2,
} from '../services/pageConfig';
import { getPlantData } from '../services/plant';
import {
  AnalyticsWidget,
  HeadingWidget,
  IInflatedDashboadPage,
  IInflatedWidgetCluster,
  MapWidget,
} from '../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
import { getSensorListForPlantId } from '../services/sensors';
import { getMasterCustomWidget } from '../services/dashboard/widget';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteConfirmation from '../globalComponents/dialogs/DeleteConfirmation';
import {
  deleteDashboardPage,
  clonePageConfig,
  syncDashboardPageWithSensorDB,
} from '../services/DashboardPageList';
import { delay } from '../utils/time.utils';
import { TIME_UNITS } from '../utils/time.utils';
import HeadingWidgetConfig from '../components/widgetConfigComponentsV2/HeadingWidgetConfig';
import RefreshPageComponent from '../utilities/RefreshPage/RefreshPageComponent';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import useIsTablet from '../utilities/customHooks/useIsTablet';
import RefreshPageVariables from '../utilities/RefreshPage/RefreshPageVariables';
import SyncIcon from '@mui/icons-material/Sync';
const styles = {
  pageConfigLoadingView: {
    width: '100%',
    height: '200px',
    display: 'grid',
    placeItems: 'center',
  },
  pageConfigHeader: {
    background: '#eee',
  },
  pageConfigHeaderLeftPart: {
    width: { xs: '90%', sm: '75%' },
    background: 'red',
  },
  addWidgetIcon: {
    marginRight: '20px',
  },
  widgetClusterHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '10px',
    boxSizing: 'border-box',
  },
  saveButtonLoadingCircle: {
    marginLeft: '10px',
    color: '#ddd',
  },
};

export default function PageConfigV2() {
  const {
    expireTime,
    setExpireTime,
    checkboxChecked,
    setCheckboxChecked,
    customRefreshInterval,
    setCustomRefreshInterval,
    enableCustomRefreshOnly,
    setEnableCustomRefreshOnly,
  } = RefreshPageVariables();

  const refreshProps = {
    checkboxChecked,
    customRefreshInterval,
    enableCustomRefreshOnly,
  };

  const { plantId, pageId } = useParams();
  const navigate = useNavigate();
  const [pageData, setPageData] = useState<IInflatedDashboadPage['pageConfig'] | null>(null);
  const [completePageData, setCompletePageData] = useState<any>(null);
  //@ts-ignore
  const [previousPageState, setPreviousPageState] = useState<any>(null);
  const [plantData, setPlantData] = useState<plantDatainterface | null>(null);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [lastMovedWidget, setLastMovedWidget] = useState<lastMovedWidgetInterface>({
    widgetName: '',
    direction: '',
  });
  const [sensorList, setSensorList] = useState<any[]>([]);
  const [customWidget, setCustomWidget] = useState<any>(null);
  const [initialPageData, setInitialPageData] = useState<any>(null);
  const [deletedWidgets, setDeletedWidgets] = useState<any[]>([]);
  const [showPageDeleteModal, setShowPageDeleteModal] = useState<boolean>(false);
  const [
    //@ts-ignore
    processingDeleteConfirmationCallBackBack,
    setProcessingDeleteConfirmationCallBack,
  ] = useState<boolean>(false);
  const [clusterId, setClusterId] = useState<string | null>(null);
  useEffect(() => {
    getPageConfigDataV2(pageId!, 'pageConfig', 'STP').then(data => {
      setCheckboxChecked(data?.refreshProps?.checkboxChecked);
      setCustomRefreshInterval(data?.refreshProps?.customRefreshInterval);
      setEnableCustomRefreshOnly(
        localStorage.getItem('enableCustomRefreshOnly') === 'true'
          ? true
          : data?.refreshProps?.enableCustomRefreshOnly
      );
      setCompletePageData(data);
      setPageData(data?.pageConfig);
      setInitialPageData(lodash.cloneDeep(data?.pageConfig));
      findClusterIdByPageId(data?._id).then(data => {
        setClusterId(data);
      });
      setLoading(false);
      setRefresh(!refresh);
    });
    getPlantData(plantId!).then(data => {
      setPlantData(data);
      setRefresh(!refresh);
    });
    getSensorListForPlantId(plantId!).then(data => {
      setSensorList(data);
      setRefresh(!refresh);
    });
    getMasterCustomWidget('graph').then(data => {
      setCustomWidget(data);
    });
    setRefresh(!refresh);
  }, []);

  const savePageConfig = () => {
    if (checkboxChecked && (customRefreshInterval < 60 || customRefreshInterval > 600)) {
      toast.error('Refresh range should be between 60 to 600 seconds');
      return;
    }
    setSaving(true);
    const isStateChanged = lodash.isEqual(initialPageData, pageData);
    const saveHistory = !isStateChanged;
    savePageConfigDataV2(
      plantId!,
      pageId!,
      completePageData,
      deletedWidgets,
      saveHistory,
      refreshProps
    ).then(data => {
      setSaving(false);
      navigate(`/DashboardPageList/${plantId}`);
    });
  };

  const changePageProperty = (property: string, value: any) => {
    if (pageData != null) {
      pageData[property] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const addCustomWidget = (widgetClusterIndex: number) => {
    let csWidgetCount = pageData!.data[widgetClusterIndex].widgets.reduce(
      (total: any, currentWidget: any) => {
        if (currentWidget.widgetOrigin == 'custom') return total + 1;
        else return total;
      },
      0
    );

    let newCustomWidget = lodash.cloneDeep(customWidget);
    newCustomWidget['widgetName'] = 'Custom Widget ' + (csWidgetCount + 1);
    newCustomWidget['widgetNickName'] = 'Custom Widget' + (csWidgetCount + 1);

    pageData?.data[widgetClusterIndex].widgets.push(newCustomWidget);
    toast(`Added new custom widget`);
    setRefresh(!refresh);
  };

  const moveWidgetPosition = (
    widgetClusterIndex: number,
    widgetIndex: number,
    action: string
  ): void => {
    let totalWidgetCount = pageData?.data[widgetClusterIndex].widgets.length || 0;
    let tempPageData = pageData!;
    let currWidget = lodash.clone(tempPageData.data[widgetClusterIndex].widgets[widgetIndex]);
    if (action == 'up') {
      if (widgetIndex > 1) {
        // because widget 0 is heading
        let upWidget = lodash.clone(tempPageData.data[widgetClusterIndex].widgets[widgetIndex - 1]);
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex] = upWidget;
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex - 1] = currWidget;
      } else {
        toast('Cannot go any higher');
      }
      setLastMovedWidget({
        widgetName: currWidget.widgetName,
        direction: 'up',
      });
    } else if (action == 'down') {
      if (widgetIndex < totalWidgetCount - 1) {
        let downWidget = lodash.clone(
          tempPageData.data[widgetClusterIndex].widgets[widgetIndex + 1]
        );
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex] = downWidget;
        tempPageData.data[widgetClusterIndex].widgets[widgetIndex + 1] = currWidget;
      } else {
        toast('Cannot go any lower');
      }
      setLastMovedWidget({
        widgetName: currWidget.widgetName,
        direction: 'down',
      });
    }

    /// reset widgetOrder
    tempPageData.data[widgetClusterIndex].widgets.forEach((widget, widgetIndex: number) => {
      widget.widgetOrder = widgetIndex;
    });

    setPageData(tempPageData);

    setRefresh(!refresh);
  };

  const changeWidgetClusterProperty = (
    widgetClusterIndex: number,
    property: string,
    value: any
  ) => {
    if (pageData) {
      pageData.data[widgetClusterIndex][property] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const handleSelectAllCheckboxChange = (event: any) => {
    let previousPageState: any = lodash.clone(pageData);
    if (pageData !== null) {
      pageData.data.forEach((widgetCluster: any) => {
        widgetCluster.showWidgetCluster = event.target.checked;
        widgetCluster.widgets.forEach((widget: any) => {
          widget.checked = event.target.checked;
        });
      });
    }
    setPreviousPageState(previousPageState);
    setPageData(pageData);
  };

  // const changeDefaultGranularitySettings = (property: string, value: any) => {
  //   if (pageData != null) {
  //     pageData.defaultGranularitySettings[property] = value;
  //     setPageData(pageData);
  //     setRefresh(!refresh);
  //   }
  // };
  const changeDefaultTimeRangeSettings = (property: string, value: any) => {
    if (pageData != null) {
      pageData.defaultTimeRangeSettings[property] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const deletePage = async () => {
    let res: any = await deleteDashboardPage(clusterId!, pageId!);
    if (res.data) {
      let { data } = res;
      toast(data.data.message);
      await delay(1000);
      navigate(`/DashboardPageList/${plantId}`);
    }
  };

  const clonePage = async () => {
    let res: any = await clonePageConfig(clusterId!, pageId!);
    if (res.data) {
      let { data } = res;
      toast(data?.data?.message);
      await delay(1000);
      navigate(`/DashboardPageList/${plantId}`);
    }
  };

  const syncDashboardPage = async (pageId: string) => {
    let res: any = await syncDashboardPageWithSensorDB(pageId);
    console.log(res);
  };

  const changeHeadingWidgetHeading = async (
    widgetClusterIndex: number,
    widgetIndex: number,
    language: string,
    value: string
  ) => {
    if (pageData != null) {
      pageData.data[widgetClusterIndex].widgets[widgetIndex].heading[language] = value;
      setPageData(pageData);
      setRefresh(!refresh);
    }
  };

  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();

  if (loading)
    return (
      <LayoutComponent plantId={plantId}>
        <h1>Page Configuration</h1>
        <Box sx={styles.pageConfigLoadingView}>
          <CircularProgress />
        </Box>
      </LayoutComponent>
    );
  else if (pageData)
    return (
      <LayoutComponent plantId={plantId}>
        {isMobile ? (
          <>
            <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3} mb={2}>
              <h1
                style={{
                  margin: 0,
                  marginTop: '-4px',
                  fontSize: '1.5rem',
                  color: '#1A3356',
                }}
              >
                Page Configuration
              </h1>
              <Stack direction="row">
                <IconButton
                  onClick={() => {
                    syncDashboardPage(pageId!);
                  }}
                  color="inherit"
                >
                  <SyncIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    clonePage();
                  }}
                  color="inherit"
                >
                  <FileCopyIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setShowPageDeleteModal(true);
                  }}
                  color="inherit"
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Stack>
            </Stack>
            <Stack mb={2}>
              <RefreshPageComponent
                expireTime={expireTime}
                setExpireTime={setExpireTime}
                checkboxChecked={checkboxChecked}
                setCheckboxChecked={setCheckboxChecked}
                customRefreshInterval={customRefreshInterval}
                setCustomRefreshInterval={setCustomRefreshInterval}
                enableCustomRefreshOnly={enableCustomRefreshOnly}
                setEnableCustomRefreshOnly={setEnableCustomRefreshOnly}
              />
            </Stack>
          </>
        ) : (
          <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3} mb={2}>
            <Stack direction="row" gap={isTablet ? 2 : 4}>
              <h1
                style={{
                  margin: '0',
                  marginTop: isTablet ? '6px' : '2px',
                  fontSize: isTablet ? '1.6rem' : '2rem',
                  color: '#1A3356',
                }}
              >
                Page Configuration
              </h1>
              <RefreshPageComponent
                expireTime={expireTime}
                setExpireTime={setExpireTime}
                checkboxChecked={checkboxChecked}
                setCheckboxChecked={setCheckboxChecked}
                customRefreshInterval={customRefreshInterval}
                setCustomRefreshInterval={setCustomRefreshInterval}
                enableCustomRefreshOnly={enableCustomRefreshOnly}
                setEnableCustomRefreshOnly={setEnableCustomRefreshOnly}
              />
            </Stack>
            <Stack direction="row">
              <IconButton
                onClick={() => {
                  syncDashboardPage(pageId!);
                }}
                color="inherit"
              >
                <SyncIcon fontSize="small" />
              </IconButton>

              <IconButton
                onClick={() => {
                  clonePage();
                }}
                color="inherit"
              >
                <FileCopyIcon fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => {
                  setShowPageDeleteModal(true);
                }}
                color="inherit"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Stack>
          </Stack>
        )}
        <Stack
          borderRadius={5}
          border="1px solid #BDBDBD"
          direction={{ xs: 'column', md: 'row' }}
          justifyContent={{ xs: 'flex-start', sm: 'space-around' }}
          gap={{ xs: 1, md: 2 }}
          alignItems="center"
          mb={2}
          padding={2}
          sx={styles.pageConfigHeader}
          className="pageConfigHeader"
        >
          <TextField
            fullWidth
            inputProps={{ style: { fontSize: '16px' } }}
            id="outlined-basic"
            label="Page Name"
            variant="outlined"
            key="pageName"
            defaultValue={pageData.pageName}
            onBlur={(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
              changePageProperty('pageName', e.target.value);
            }}
          />
          <Box
            sx={{
              width: { xs: '100%', md: '500px' },
            }}
          >
            <FormControl
              sx={{
                width: '100%',
              }}
            >
              <InputLabel id="demo-simple-select-label">Time</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={pageData.defaultTimeInDays}
                value={pageData.defaultTimeInDays}
                label="page"
                onChange={e => {
                  let days: any = e.target.value;
                  let timeInMinutes: any = TIME_UNITS['days'].timeInMinutes * Math.abs(days); // timeInMinutes is always positive
                  changePageProperty('defaultTimeInDays', e.target.value);

                  changeDefaultTimeRangeSettings('unit', 'days');
                  changeDefaultTimeRangeSettings('unitMultiplier', days);
                  changeDefaultTimeRangeSettings('timeRangeInMins', timeInMinutes);
                }}
                disabled={pageData?.defaultTimeRangeSettings?.timeRangeType === 'absolute'}
                style={{ fontSize: '16px', fontWeight: 'normal' }}
              >
                <MenuItem value={-1}>Yesterday</MenuItem>
                <MenuItem value={0}>Today</MenuItem>
                <MenuItem value={1}>Last 24 Hours</MenuItem>
                <MenuItem value={3}>3 Days</MenuItem>
                <MenuItem value={7}>7 Days</MenuItem>
                <MenuItem value={10}>10 Days</MenuItem>
                <MenuItem value={30}>1 Months</MenuItem>
                <MenuItem value={180}>6 Months</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <FormControl sx={{ width: '100%' }}>
            <Autocomplete
              multiple
              id="tags-filled"
              options={plantData?.embeddedLinks || []}
              getOptionLabel={option => option.name}
              // defaultValue={ [valueTypes[0]] }
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.name === value.name}
              value={(pageData?.embeddedLinks as any) || ([] as any)}
              onChange={(e, newValue) => {
                changePageProperty('embeddedLinks', newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  variant="outlined"
                  // label="value types you wanna see"
                  placeholder=""
                  label="Select the PowerBI Links you want to see"
                />
              )}
            />
          </FormControl>

          <Stack
            m={1}
            direction="column"
            style={{
              minWidth: '220px',
              marginLeft: 'auto',
            }}
          >
            <Box>
              <Checkbox
                checked={pageData.public}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changePageProperty('public', e.target.checked);
                }}
              />
              <span>Is this a public page ?</span>
            </Box>
            <Box>
              <Checkbox
                checked={pageData.showEmbeddedLinks}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  changePageProperty('showEmbeddedLinks', e.target.checked);
                }}
              />
              <span>Show Embedded Links ?</span>
            </Box>
            {/* <Box borderRadius={5} sx={{ overflow: "hidden" }}>
              <Accordion elevation={0}>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  // expandIcon={<ExpandMoreIcon />}
                  expandIcon={<SettingsIcon fontSize="small" />}
                >
                  Default Time Range Settings
                </AccordionSummary>
                <AccordionDetails>
                  <PageLevelTimeRangeSettings
                    timeRangeSettings={pageData?.defaultTimeRangeSettings}
                    changeTimeRangeSettings={changeDefaultTimeRangeSettings}
                  />
                </AccordionDetails>
              </Accordion>
            </Box>
            <Box borderRadius={5} sx={{ overflow: "hidden" }}>
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  // expandIcon={<ExpandMoreIcon />}
                  expandIcon={<SettingsIcon fontSize="small" />}
                >
                  Default Granularity Settings
                </AccordionSummary>
                <AccordionDetails>
                  <PageLevelGranularitySettings
                    granularitySettings={pageData?.defaultGranularitySettings}
                    changeGranularitySettings={changeDefaultGranularitySettings}
                    timeRangeSettings={pageData?.defaultTimeRangeSettings}
                  />
                </AccordionDetails>
              </Accordion>
            </Box> */}
          </Stack>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={1}>
          <Checkbox onChange={handleSelectAllCheckboxChange} />
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            Select All
          </Typography>
        </Stack>

        {pageData.data.map((widgetCluster: IInflatedWidgetCluster, widgetClusterIndex: number) => {
          return (
            <>
              {widgetCluster.widgets.map(
                (widget: AnalyticsWidget | HeadingWidget | MapWidget, widgetIndex: number) => {
                  if (widget.widgetType === 'heading') {
                    return (
                      <Box sx={styles.widgetClusterHeader}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Checkbox
                            checked={widgetCluster.showWidgetCluster}
                            onChange={event => {
                              changeWidgetClusterProperty(
                                widgetClusterIndex,
                                'showWidgetCluster',
                                event.target.checked
                              );
                            }}
                          />
                          <HeadingWidgetConfig
                            widgetClusterIndex={widgetClusterIndex}
                            widgetIndex={widgetIndex}
                            text={widget.heading.english}
                            changeHeadingWidgetHeading={changeHeadingWidgetHeading}
                          />
                        </Stack>

                        <IconButton style={styles.addWidgetIcon}>
                          <AddIcon
                            fontSize="small"
                            onClick={() => {
                              addCustomWidget(widgetClusterIndex);
                            }}
                          />
                        </IconButton>
                      </Box>
                    );
                  } else {
                    return (
                      <WidgetConfigComponentV2
                        key={widget?.widgetName + widgetIndex}
                        pageData={pageData}
                        setPageData={setPageData}
                        widgetCluster={widgetCluster.clusterName}
                        widgetClusterIndex={widgetClusterIndex}
                        //@ts-ignore
                        widget={widget}
                        widgetIndex={widgetIndex}
                        moveWidgetPosition={moveWidgetPosition}
                        lastMovedWidget={lastMovedWidget}
                        sensorList={sensorList}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        plantId={plantId!}
                        setDeletedWidgets={setDeletedWidgets}
                      />
                    );
                  }
                  // end of return for widget
                }
              )}
            </>
          ); //  end of return for widget cluster
        })}

        <BlackButton
          style={{ minWidth: '100px' }}
          onClick={() => {
            savePageConfig();
          }}
        >
          {saving ? (
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <span>Saving</span>
              <CircularProgress size="20px" thickness={5} sx={styles.saveButtonLoadingCircle} />
            </span>
          ) : (
            <span>SAVE</span>
          )}
        </BlackButton>

        <DeleteConfirmation
          showDeleteModal={showPageDeleteModal}
          setShowDeleteModal={setShowPageDeleteModal}
          alertMessage="Are you sure you want to delete this page ?"
          processing={false}
          setProcessing={setProcessingDeleteConfirmationCallBack}
          onConfirmAction={() => {
            deletePage();
          }}
        />
      </LayoutComponent>
    );
  else return null; //  end of return for page component
}
