const localStorageStore = {
  setItem: (key: string, value: string) => {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.error("Error saving to localStorage", e);
    }
  },
  getItem: (key: string) => {
    try {
      return localStorage.getItem(key);
    } catch (e) {
      console.error("Error getting data from localStorage", e);
      return null;
    }
  },
  removeItem: (key: string) => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      console.error("Error removing data from localStorage", e);
    }
  },
  clear: () => {
    try {
      localStorage.clear();
    } catch (e) {
      console.error("Error clearing localStorage", e);
    }
  },
  logout: () => {
    try {
      const firebaseToken = localStorage.getItem("FireBase");
      const loginEntries = localStorage.getItem("cachedLoginEntries");
      localStorage.clear();
      firebaseToken ? localStorage.setItem("FireBase", firebaseToken) : null;
      loginEntries
        ? localStorage.setItem("cachedLoginEntries", loginEntries)
        : null;
    } catch (e) {
      console.error("Error clearing localStorage", e);
    }
  },
};

export default localStorageStore;
