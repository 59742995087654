import { Box, Button } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { globalStyles } from "../../globalStyles/globalStyles";
import "react-toastify/dist/ReactToastify.css";

const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    zIndex: 100,
};

function ModalBox(props: any) {
    const {
      open,
      handleClose,
      modalBoxPurpose,
      markIssueResolved,
      reOpenIssue,
      deleteAlert,
    } = props;
    return (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {modalBoxPurpose === "resolveConfirmation" &&
              "Please confirm resolution for this issue, this will be reflected by a verified mark in tickets page!"}
  
            {modalBoxPurpose === "reOpenConfirmation" &&
              "Please confirm you want to reopen this issue"}
            {modalBoxPurpose === "deleteAlertConfirmation" &&
              "Please confirm you want to delete this alert. This step can't be undone!"}
          </Typography>
          <Box m={1} style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              style={{ ...globalStyles.buttons.black, padding: "10px" }}
              onClick={() => {
                if (modalBoxPurpose === "resolveConfirmation") {
                  markIssueResolved();
                } else if (modalBoxPurpose === "reOpenConfirmation") {
                  reOpenIssue();
                } else if (modalBoxPurpose === "deleteAlertConfirmation") {
                  deleteAlert();
                }
                handleClose();
              }}
            >
              {modalBoxPurpose === "resolveConfirmation" && "Mark Resolved"}
              {modalBoxPurpose === "reOpenConfirmation" && "ReOpen"}
              {modalBoxPurpose === "deleteAlertConfirmation" && "Delete"}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
}

export default ModalBox;