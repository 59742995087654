import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from '@mui/material';
import PlantConfigComponent from '../components/workspace/PlantConfigComponent';
import LayoutComponent from '../components/LayoutComponent';
import { getWorkspace } from '../services/workspace';
import useWorkspaceStore from '../store/workspaceStore';
import { ToastContainer } from 'react-toastify';
import { PLANT_LAYOUT_DIGITAL_ASSETS_2 } from '../constants/constants';
import { getListOfPlantsAssignedToUser } from '../services/users';
import WorkspaceOutputConfig from '../components/workspace/WorkspaceOutputConfig';
import EditableHeading from '../components/Common/EditableHeading';
import DomainIcon from '@mui/icons-material/Domain';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import useHistoryStore from '../store/useHistoryStore';

const WorkspaceConfig: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [opentWorkspaceOutputConfig, setOpenWorkspaceOutputConfig] = useState<boolean>(false);
  const [workspaceConfig, setWorkspaceConfig] = useState<any>(null);
  const [refresh, setRefresh] = useState<boolean>(false);
  const [plantList, setPlantList] = useState<any[]>([]);
  const { workspaceId } = useParams();
  const { selectedClient, workspaceName } = useWorkspaceStore();
  const navigate = useNavigate();
  const { historyStack } = useHistoryStore();

  async function getWorkspaceAndPlantList() {
    let workspace = await getWorkspace(workspaceId!);
    if (workspace) {
      setWorkspaceConfig(workspace);
      let plantList = await getListOfPlantsAssignedToUser(workspace.client);
      setPlantList(plantList);
    } else {
      setWorkspaceConfig({
        workspaceName: workspaceName,
        plants: [],
        client: selectedClient,
        createdBy: null,
      });
      let plantList = await getListOfPlantsAssignedToUser(selectedClient || '1234');
      setPlantList(plantList);
    }
  }

  useEffect(() => {
    if (
      historyStack[historyStack?.length - 1]?.includes('layoutVisualisationConfig') &&
      workspaceId === '1234'
    )
      navigate('/workspaceConfigList');

    getWorkspaceAndPlantList();
  }, [historyStack]);

  const deletePlant = (plantIndex: number) => {
    workspaceConfig.plants.splice(plantIndex, 1);
    setWorkspaceConfig(workspaceConfig);

    setRefresh(!refresh);
  };

  const handleOpen = (): void => setOpen(true);
  const handleClose = (): void => setOpen(false);
  const handleAddPlant = (plant: any): void => {
    workspaceConfig?.plants?.push({
      plantId: plant._id,
      plantName: plant.plantName,
      diagram: PLANT_LAYOUT_DIGITAL_ASSETS_2[0],
      sensors: [],
      outputs: [],
      displayNode: true,
    });
    setWorkspaceConfig(workspaceConfig);
    console.log('workspace config : ', workspaceConfig);
    setRefresh(!refresh);
    handleClose();
  };

  const handleAddSensor = (plantIndex: number, sensor: any): void => {
    workspaceConfig.plants?.[plantIndex].sensors.push(sensor);
    setWorkspaceConfig(workspaceConfig);
    setRefresh(!refresh);
  };
  const handleDeleteSensor = (plantIndex: number, sensorId: string): void => {
    let sensorList = workspaceConfig.plants?.[plantIndex].sensors;
    let newSensorList = sensorList.filter((s: any) => s._id != sensorId);
    workspaceConfig.plants[plantIndex].sensors = newSensorList;
    setWorkspaceConfig(workspaceConfig);
    setRefresh(!refresh);
  };

  const handleSensorValueChange = (
    plantIndex: number,
    sensorId: string,
    property: string,
    value: any
  ) => {
    let sensorIndex = workspaceConfig?.plants?.[plantIndex]?.sensors.findIndex(
      (s: any) => s._id === sensorId
    );
    if (workspaceConfig?.plants?.[plantIndex]?.sensors?.[sensorIndex]) {
      workspaceConfig.plants[plantIndex].sensors[sensorIndex][property] = value;
      setWorkspaceConfig(workspaceConfig);
      console.log(workspaceConfig);
      setRefresh(!refresh);
    }
  };

  function arrayMove(array: any[], from: number, to: number) {
    const newArray = array.slice();
    newArray.splice(to < 0 ? newArray.length + to : to, 0, newArray.splice(from, 1)[0]);
    return newArray;
  }

  const reOrderSensors = (plantIndex: number, oldIndex: number, newIndex: number) => {
    // Reorder sensorList based on drag-and-drop
    const reorderedSensorList = arrayMove(
      workspaceConfig.plants[plantIndex].sensors,
      oldIndex,
      newIndex
    );
    workspaceConfig.plants[plantIndex].sensors = reorderedSensorList;
    setWorkspaceConfig(workspaceConfig);
    setRefresh(!refresh);
  };

  const handleDiagramChange = (plantIndex: number, newValue: any): void => {
    workspaceConfig.plants[plantIndex].diagram = newValue;
    setWorkspaceConfig(workspaceConfig);
    setRefresh(!refresh);
  };

  const handlePlantValueChange = (plantIndex: number, property: string, newValue: any): void => {
    console.log(newValue);
    workspaceConfig.plants[plantIndex][property] = newValue;
    setWorkspaceConfig(workspaceConfig);
    setRefresh(!refresh);
    console.log(workspaceConfig);
  };

  const handleWorkspaceValueChange = (property: string, value: any) => {
    workspaceConfig[property] = value;
    setWorkspaceConfig(workspaceConfig);
    setRefresh(!refresh);
  };

  return (
    <LayoutComponent>
      {/* <ThemeProvider theme={dpTheme}> */}
      {workspaceConfig && (
        <Box>
          <Stack direction="column" gap={2}>
            <Typography variant="h4" fontWeight="bold" mt={3}>
              Workspace Configuration
            </Typography>
            <EditableHeading
              text={workspaceConfig.workspaceName}
              handleValueChange={(value: any) => {
                handleWorkspaceValueChange('workspaceName', value);
              }}
              errorText="Empty nicknames are not allowed!"
            />
            <EditableHeading
              text={workspaceConfig.description}
              placeHolder="Write your workspace description here !"
              fontSize={20}
              fontWeight={300}
              color="#777"
              handleValueChange={(value: any) => {
                handleWorkspaceValueChange('description', value);
              }}
              errorText="Empty descriptions are not allowed!"
              allowEmptyField={true}
            />
            <Stack direction="row" gap={2}>
              <Typography variant="h5">Plants</Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                sx={{ width: '130px', marginLeft: 'auto' }}
              >
                Add Plant
              </Button>
            </Stack>
            <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Select a Plant</DialogTitle>
              <DialogContent>
                <List>
                  {plantList.length > 0 ? (
                    plantList.map(plant => (
                      <ListItem button key={plant._id}>
                        <ListItemIcon>
                          <DomainIcon />
                        </ListItemIcon>
                        <ListItemText primary={plant.plantName} />

                        <Box p={0.5} ml={1}>
                          {workspaceConfig.plants.find((p: any) => {
                            return p.plantId === plant._id;
                          }) ? (
                            <CheckIcon fontSize="small" color="success" />
                          ) : (
                            <AddIcon fontSize="small" onClick={() => handleAddPlant(plant)} />
                          )}
                        </Box>
                      </ListItem>
                    ))
                  ) : (
                    <ListItem>
                      <ListItemIcon>
                        <DomainIcon />
                      </ListItemIcon>
                      <ListItemText primary={'No plant available For this client !'} />
                    </ListItem>
                  )}
                </List>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
            <Box>
              {workspaceConfig?.plants?.map((plant: any, index: number) => (
                <PlantConfigComponent
                  key={index}
                  plantIndex={index}
                  plantConfigInfo={plant}
                  deletePlant={deletePlant}
                  handleAddSensor={handleAddSensor}
                  handleDeleteSensor={handleDeleteSensor}
                  handleDiagramChange={handleDiagramChange}
                  reOrderSensors={reOrderSensors}
                  handleSensorValueChange={handleSensorValueChange}
                  handlePlantValueChange={handlePlantValueChange}
                />
              ))}
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setOpenWorkspaceOutputConfig(true);
              }}
              sx={{ width: '130px', marginLeft: 'auto' }}
            >
              Save
            </Button>
          </Stack>
          <WorkspaceOutputConfig
            open={opentWorkspaceOutputConfig}
            handleClose={() => {
              setOpenWorkspaceOutputConfig(false);
            }}
            plantList={plantList}
            workspaceConfig={workspaceConfig}
            handlePlantValueChange={handlePlantValueChange}
          />
        </Box>
      )}
      <ToastContainer />
      {/* </ThemeProvider> */}
    </LayoutComponent>
  );
};

export default WorkspaceConfig;
