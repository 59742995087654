import { Dialog, DialogContent } from '@mui/material';
import EquipmentNodeDiagram from './EquipmentNodeDiagram';

function EquipmentNodeDialog({
  id,
  biSensorId,
  biSensorStatus,
  LTSensor,
  LTSensorValue,
  equipmentName,
  equipmentImage,
  imageControlId,
  data,
  showAllSensors,
  setShowAllSensors,
  showSettings,
  OnOffSensorStatus,
  nodeData,
}: any) {
  return (
    <Dialog
      open={true}
      maxWidth="lg"
      onClose={(event: any) => {
        event.stopPropagation();
        setShowAllSensors(!showAllSensors);
      }}
      sx={{ '& .MuiDialog-paper': { width: { xs: '90%', sm: '500px', padding: 0 } } }}
    >
      <DialogContent
        sx={{
          padding: '0px !important',
          width: '100% !important ',
          height: { xs: '420px', sm: '270px' },
          borderRadius: '8px',
        }}
      >
        <EquipmentNodeDiagram
          id={id}
          parent="Dialog"
          equipmentName={equipmentName}
          equipmentImage={equipmentImage}
          imageControlId={imageControlId}
          data={data}
          showAllSensors={showAllSensors}
          showSettings={showSettings}
          biSensorId={biSensorId}
          biSensorStatus={biSensorStatus}
          LTSensor={LTSensor}
          LTSensorValue={LTSensorValue}
          OnOffSensorStatus={OnOffSensorStatus}
          nodeData={nodeData}
        />
      </DialogContent>
    </Dialog>
  );
}

export default EquipmentNodeDialog;
