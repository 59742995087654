import { useState } from "react";
import { Tooltip, styled } from "@mui/material";

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    fontSize: "14px",
  },
}));

interface TextCellProps {
  value: string;
}

const TextCell: React.FC<TextCellProps> = ({ value }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const displayText = isHovered ? value : `${value.slice(0, 75)}`;

  return (
    <StyledTooltip title={value} enterDelay={500}>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {displayText}
      </div>
    </StyledTooltip>
  );
};

export default TextCell;
