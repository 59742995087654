import axios from 'axios';
import axiosInstance from '../constants/axiosInstance';
import { timeFrame } from '../Interfaces/dashboardPageInterfaces';

const getPageDropdownListForUser = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/dashboard/getPageDropdownListForUser?plantId=${plantId}`
    );
    return data.pageDropdownList;
  } catch (error) {}
};

const getPdfForDashboardPage = async (
  plantId: string,
  pageId: string | null,
  timeFrame: timeFrame | null,
  printFormat: string
) => {
  try {
    const { data } = await axiosInstance().post(
      `/dashboard/getPdfForDashboardPage?plantId=${plantId}&pageId=${pageId}`,
      { timeFrame: timeFrame, printFormat: printFormat }
    );
    return data.pdf;
  } catch (error) {}
};

const getWidgetDataFromDb = async (plantId: string, widgetStructure: any, controller: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/dashboard/getWidgetData?plantId=${plantId}`,
      widgetStructure,
      {
        cancelToken: new axios.CancelToken(c => {
          controller.signal.addEventListener('abort', () => c());
        }),
      }
    );
    return data?.widgetData;
  } catch (err) {}
};

const getDashboardClusters = async (plantId: string, userId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/newdashboard/dashboardClusters?plantId=${plantId}&userId=${userId}`
    );
    return data?.data?.dashboardClusters;
  } catch (err) {}
};

const getDashboardPageData = async (pageId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/getDashboardPageData?pageId=${pageId}`
    );
    return data?.data;
  } catch (err) {}
};

const getWidgetData = async ({
  plantId,
  widgetId,
  parent = 'dashboard',
  widgetStructure,
  startDate,
  endDate,
  granularity,
  timeRangeType,
  clock,
  selectedChart,
  widgetType,
}: any) => {
  try {
    let resp;
    if (!widgetId) {
      resp = await axiosInstance().post(
        `/newDashboard/getWidgetData?plantId=${plantId}&startDate=${startDate}&endDate=${endDate}`,
        { widgetStructure }
      );
    } else {
      resp = await axiosInstance().post(
        `/newDashboard/getWidgetData?plantId=${plantId}&widgetId=${widgetId}&startDate=${startDate}&endDate=${endDate}&granularity=${granularity}&timeRangeType=${timeRangeType}&parent=${parent}&clock=${clock}&selectedChart=${selectedChart}&widgetType=${widgetType}`
      );
    }
    return resp.data?.data;
  } catch (err) {
    console.error('ERROR | getWidgetData', err);
  }
};

const saveWidgetData = async (data: any) => {
  try {
    const resp = await axiosInstance().post(`/newDashboard/updateWidgetTimeSettings`, data);
    return resp.data.data;
  } catch (err) {}
};

const saveDashboardData = async (data: any) => {
  try {
    const resp = await axiosInstance().post(`/newDashboard/updateDashboardTimeSettings`, data);
    return resp.data.data;
  } catch (err) {}
};

const createNewDashboard = async (data: any) => {
  try {
    const resp = await axiosInstance().post(`/newDashboard/createNewDashboardPage`, data);
    return resp.data?.data;
  } catch (err) {}
};

const getDashboardClustersForClient = async () => {
  try {
    const { data } = await axiosInstance().post(`/newdashboard/unfiedDashboardClusters`);
    return data?.data?.dashboardClusters;
  } catch (err) {}
};

const createNewUnifiedDashboard = async (data: any) => {
  try {
    const resp = await axiosInstance().post(`/newDashboard/createNewUnifiedDashboardPage`, data);
    return resp.data?.data;
  } catch (err) {}
};

const getPdfForUnifiedDashboardPage = async (
  pageId: string | null,
  timeFrame: timeFrame | null,
  printFormat: string
) => {
  try {
    const { data } = await axiosInstance().post(
      `/dashboard/getPdfForUnifiedDashboardPage?plantId=null&pageId=${pageId}`,
      { timeFrame: timeFrame, printFormat: printFormat }
    );
    return data.pdf;
  } catch (error) {}
};

const migrateSinglePlantDashboardPageToUnifiedDashboardForSingleUser = async () => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/migrateSinglePlantDashboardPageToUnifiedDashboardForSingleUser`
    );
    return data?.data;
  } catch (err) {
    console.log(err);
  }
};

const showMigrationOptionForUnifiedDashboard = async () => {
  try {
    const { data } = await axiosInstance().post(
      `/newDashboard/showMigrationOptionForUnifiedDashboard`
    );
    return data?.data;
  } catch (err) {
    console.log(err);
  }
};

const getMapWidgetData = async (widgetId: string, timeFrame: any, filterName: string) => {
  try {
    filterName = filterName.trim();
    const { data } = await axiosInstance().post(
      `/newdashboard/getMapWidgetData?widgetId=${widgetId}&filterName=${filterName}`,
      { timeFrame }
    );
    return data?.data;
  } catch (err) {}
};

const getMapWidgetFiltersList = async (widgetId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/newdashboard/getMapWidgetFiltersList?widgetId=${widgetId}`
    );
    return data?.data;
  } catch (err) {}
};

export {
  getPageDropdownListForUser,
  getPdfForDashboardPage,
  getWidgetDataFromDb,
  getDashboardClusters,
  getDashboardPageData,
  getWidgetData,
  saveWidgetData,
  saveDashboardData,
  createNewDashboard,
  getDashboardClustersForClient,
  createNewUnifiedDashboard,
  getPdfForUnifiedDashboardPage,
  migrateSinglePlantDashboardPageToUnifiedDashboardForSingleUser,
  showMigrationOptionForUnifiedDashboard,
  getMapWidgetData,
  getMapWidgetFiltersList,
};
