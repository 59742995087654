import { memo, useState } from "react";
import EquipmentNodeDiagram from "./EquipmentNodeDiagram";
import EquipmentNodeDialog from "./EquipmentNodeDialog";
import { Handle, Position } from "reactflow";
import classes from "../../styles/PlantVisualisationStyle.module.css";
import { usePlantLayoutStore } from "../../../../store/PlantLayoutStore";

const EquimentNode = memo(({ id, data, isConnectable }: any) => {
  const [showAllSensors, setShowAllSensors] = useState(false);
  const { biDirectionalSensorsForPlant } = usePlantLayoutStore();
  let biSensorId = null;
  let showSettings = false;
  let biSensorStatus = false;
  let LTSensorValue = 0;
  let OnOffSensorStatus = false;

  const LTSensor = data.configInfo.sensors.find(
    (sensor: any) => sensor.sensorName === "Level Transmitter"
  );
  if (LTSensor) {
    LTSensorValue = parseFloat(LTSensor.value) || 0;
    // console.log(LTSensorValue, `Level Transmitter:`, LTSensor);
  }
  const OnOffSensor = data.configInfo.sensors.find(
    (sensor: any) => sensor.sensorName === "ON/OFF Status"
  );
  if (OnOffSensor) {
    OnOffSensorStatus = parseInt(OnOffSensor.value) ? true : false;
    // console.log(OnOffSensorStatus, "On/Off Sensor:", OnOffSensor);
  }

  data.configInfo.sensors.forEach((sensor: any) => {
    if (biDirectionalSensorsForPlant.includes(sensor.sensorId)) {
      biSensorId = sensor.sensorId;
      biSensorStatus = parseInt(sensor.value) ? true : false; //This is ON/OFF Sensor Status;
      showSettings = true;
    }
  });

  return (
    <div>
      <Handle
        type="target"
        position={Position.Left}
        isConnectable={isConnectable}
        className={classes.Node}
      />
      <EquipmentNodeDiagram
        id={id}
        equipmentName={data.label}
        equipmentImage={data.image}
        imageControlId={data.imageControlId}
        data={data.configInfo}
        showLimitedSensors={true}
        setShowAllSensors={setShowAllSensors}
        showSettings={showSettings}
        biSensorId={biSensorId}
        biSensorStatus={biSensorStatus}
        LTSensor={LTSensor}
        LTSensorValue={LTSensorValue}
        OnOffSensorStatus={OnOffSensorStatus}
      />
      {showAllSensors && (
        <EquipmentNodeDialog
          id={id}
          equipmentName={data.label}
          equipmentImage={data.image}
          imageControlId={data.imageControlId}
          data={data.configInfo}
          showAllSensors={showAllSensors}
          setShowAllSensors={setShowAllSensors}
          showSettings={showSettings}
          biSensorId={biSensorId}
          biSensorStatus={biSensorStatus}
          LTSensor={LTSensor}
          LTSensorValue={LTSensorValue}
          OnOffSensorStatus={OnOffSensorStatus}
        />
      )}
      <Handle
        type="source"
        position={Position.Right}
        id="b"
        isConnectable={isConnectable}
        className={classes.Node}
      />
    </div>
  );
});

export default EquimentNode;
