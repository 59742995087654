import React from "react";
import { Box, Stack, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { TIME_UNITS } from "../../utils/time.utils";
import { filterGranularityOptionsBasedOnTimeRange } from "../../utils/dashboard.utils";
import { toast } from "react-toastify";
// import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

interface propsInterface {
  minAllowedTimeRangeSettings: any;
  minAllowedGranularitySettings: any;
  changeMinAllowedTimeRangeSettings: Function;
  changeWidgetMinAllowedGranularitySettings: Function;
  widgetClusterIndex: number;
  widgetIndex: number;
}

export default function TimeRangeSettings(props: propsInterface) {
  const {
    minAllowedTimeRangeSettings,
    minAllowedGranularitySettings,
    changeMinAllowedTimeRangeSettings,
    changeWidgetMinAllowedGranularitySettings,
    widgetClusterIndex,
    widgetIndex,
  } = props;

  const reCalculateAndSetTimeRangeInMinsForMinAllowedAndCustomSettings = () => {
    let unit = minAllowedTimeRangeSettings["unit"];
    const timeInMinsPerUnit: number = TIME_UNITS[unit].timeInMinutes;
    let unitMultiplier = minAllowedTimeRangeSettings.unitMultiplier;
    const newTimeRangeInMins = timeInMinsPerUnit * unitMultiplier;
    changeMinAllowedTimeRangeSettings(
      widgetClusterIndex,
      widgetIndex,
      "timeRangeInMins",
      newTimeRangeInMins
    );

    let allowedGranularityOptions =
      filterGranularityOptionsBasedOnTimeRange(newTimeRangeInMins);
    let currentMinAllowedGranularityUnit = minAllowedGranularitySettings.unit;

    if (!allowedGranularityOptions.includes(currentMinAllowedGranularityUnit)) {
      let newMinAllowedGranularityUnit = allowedGranularityOptions[0];
      let newMinAllowedGranularityInMins =
        TIME_UNITS[newMinAllowedGranularityUnit].timeInMinutes *
        minAllowedGranularitySettings *
        unitMultiplier;
      changeWidgetMinAllowedGranularitySettings(
        widgetClusterIndex,
        widgetIndex,
        "unit",
        newMinAllowedGranularityUnit
      );
      changeWidgetMinAllowedGranularitySettings(
        widgetClusterIndex,
        widgetIndex,
        "granularityInMins",
        newMinAllowedGranularityInMins
      );
      toast(
        `The selected minimum allowed time range changed your minimum allowed granularity from ${currentMinAllowedGranularityUnit} to ${newMinAllowedGranularityUnit}`
      );
    }
  };

  return (
    <Box>
      {minAllowedTimeRangeSettings.timeRangeType === "relative" && (
        <Box mt={2}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <FormControl fullWidth>
              <InputLabel>Unit:</InputLabel>
              <Select
                name="unit"
                value={minAllowedTimeRangeSettings.unit}
                onChange={(e) => {
                  changeMinAllowedTimeRangeSettings(
                    widgetClusterIndex,
                    widgetIndex,
                    "unit",
                    e.target.value
                  );
                  reCalculateAndSetTimeRangeInMinsForMinAllowedAndCustomSettings();
                }}
              >
                {Object.keys(TIME_UNITS).map((timeUnit) => {
                  return (
                    <MenuItem value={timeUnit}>
                      {TIME_UNITS[timeUnit].displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <TextField
                type="number"
                name="unitMultiplier"
                label="time"
                value={minAllowedTimeRangeSettings.unitMultiplier}
                inputProps={{
                  maxLength: 100,
                  step: "1",
                  min: 1,
                }}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  changeMinAllowedTimeRangeSettings(
                    widgetClusterIndex,
                    widgetIndex,
                    "unitMultiplier",
                    e.target.value
                  );
                  reCalculateAndSetTimeRangeInMinsForMinAllowedAndCustomSettings();
                }}
              />
            </FormControl>
          </Stack>
        </Box>
      )}
      {/* <div>
        <h3>Current Time Range Settings:</h3>
        <pre>{JSON.stringify(minAllowedTimeRangeSettings, null, 2)}</pre>
      </div> */}
    </Box>
  );
}
