import axiosInstance from '../constants/axiosInstance';

const getPlantData = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(`/plantManagement/getPlant?id=${plantId}`);
    return data.body;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getAllPlants = async () => {
  //gets all plants if user == super admin and only the plants assigned to csm if user == csm, no plants for rest of the user roles
  try {
    const { data } = await axiosInstance().get('/plantManagement/getallPlants');
    return data.body;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getPlantAbbr = async (plantName: string) => {
  try {
    const { data } = await axiosInstance().post(`/plantManagement/plantAbbrSuggestions`, {
      plantName: plantName,
    });
    return data?.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const getSavedClientAbbr = async (clientId: string) => {
  try {
    const { data } = await axiosInstance().post(`/plantManagement/getSavedClientAbbr`, {
      clientId: clientId,
    });
    return data?.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

const updateDataBreakAlertStatusForPlant = async (
  plantId: string,
  enableDataBreakAlerts: boolean
) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantManagement/updateDataBreakAlertStatusForPlant?plantId=${plantId}`,
      {
        enableDataBreakAlerts,
      }
    );
    return data?.data;
  } catch (error) {
    console.log('error : ', error);
  }
};

export {
  getPlantData,
  getAllPlants,
  getPlantAbbr,
  getSavedClientAbbr,
  updateDataBreakAlertStatusForPlant,
};
