import {
  Dialog,
  DialogContent,
  FormControl,
  Button,
  InputLabel,
  Grid,
  MenuItem,
  Select,
  Typography,
  Box,
  FormHelperText,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';

import classes from './StyledComponents/UpdateInventoryDialog.module.css';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import InventoryProductUsage from './InventoryProductUsage';
import { IPlants, StoreItemDetails } from '../../Interfaces/InventoryOptionsInterface';

interface IProps {
  isInventoryOptionSelected: boolean;
  setIsInventoryOptionSelected: Function;
  setRefresh: Function;
  selectedInventoryOptionName: string;
  storeId: string;
  selectedInventoryItemDetails: StoreItemDetails[];
  plantsList: IPlants[];
  inputType: 'number' | 'float';
  clientId: string;
  plantIdParam: string | null;
}

function UpdateInventoryDialog({
  isInventoryOptionSelected,
  setIsInventoryOptionSelected,
  selectedInventoryOptionName,
  setRefresh,
  selectedInventoryItemDetails,
  plantsList,
  storeId,
  inputType,
  clientId,
  plantIdParam,
}: IProps) {
  const [productName, setProductName] = useState<string>(selectedInventoryItemDetails[0].name);
  const [product, setProduct] = useState<StoreItemDetails | null>(null);
  const [action, setAction] = useState(null);
  //@ts-ignore
  const isMobile = useIsMobile();
  const [isUpdateClicked, setIsUpdateClicked] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    try {
      setProductName(selectedInventoryItemDetails[0]?.name || '');
    } catch (err) {
      console.log(err);
    }
  }, [isInventoryOptionSelected]);

  const handleClick = (e: any) => {
    console.log('ebc : ', e);
    console.log('ebd : ', e.target.value);
    if (productName !== '') {
      let tempProduct;
      tempProduct = selectedInventoryItemDetails.find(item => item.name === productName);
      setProduct(tempProduct!);
      console.log('product', tempProduct);
      setAction(e.target.value);
      setIsUpdateClicked(true);
    } else {
      setErrorMsg(`Please select a ${selectedInventoryOptionName}`);
    }
  };
  const handleClose = () => {
    setIsInventoryOptionSelected(false);
    setProductName('');
    setErrorMsg('');
  };
  const capitalizeFirstLetter = (name: string) => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  return (
    <>
      <Dialog
        className={classes.DialogBody}
        onClose={handleClose}
        open={isInventoryOptionSelected}
        sx={{ display: isUpdateClicked ? 'none' : 'block' }}
        // fullScreen={isMobile}
      >
        <DialogContent className={classes.DialogContentStyle}>
          <Grid container mb={4} p={1} justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">
                {capitalizeFirstLetter(selectedInventoryOptionName)}
              </Typography>
            </Grid>
            {/* <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ position: "absolute", right: 8, top: 8 }}
                >
                <CloseIcon sx={{ color: "black" }} />
              </IconButton>
            </Grid> */}
            <Grid item>
              <CloseIcon className={classes.CloseIconStyle} onClick={handleClose} />
            </Grid>
          </Grid>
          {selectedInventoryItemDetails && (
            <FormControl required fullWidth>
              <InputLabel id="select-chemical-label">
                Select {selectedInventoryOptionName}
              </InputLabel>
              <Select
                labelId="select-chemical-label"
                id="select-chemical-name"
                defaultValue={selectedInventoryItemDetails[0].name}
                value={productName}
                disabled={!selectedInventoryItemDetails}
                error={errorMsg.length ? true : false}
                label={`Select ${selectedInventoryOptionName}`}
                onChange={e => {
                  setProductName(e.target.value);
                  setErrorMsg('');
                }}
              >
                {selectedInventoryItemDetails &&
                  selectedInventoryItemDetails.map(item => {
                    return (
                      <MenuItem key={item.name} value={`${item.name}`}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormHelperText sx={{ color: 'red' }}>{errorMsg}</FormHelperText>
            </FormControl>
          )}
          <Box className={classes.DialogFooter}>
            <Button
              sx={{
                backgroundColor: '#CCF2D9',
                color: '#1F7E3F',
                border: `1px solid #1F7E3F`,
              }}
              fullWidth
              value={'add'}
              onClick={e => {
                handleClick(e);
              }}
            >
              ADD TO STORE
            </Button>
            <Button
              fullWidth
              sx={{
                backgroundColor: '#FAD9D0',
                color: '#AA3113',
                border: `1px solid #AA3113`,
              }}
              value={'remove'}
              onClick={e => {
                handleClick(e);
              }}
            >
              REMOVE FROM STORE
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {action !== null && (
        <InventoryProductUsage
          isUpdateClicked={isUpdateClicked}
          setIsUpdateClicked={setIsUpdateClicked}
          setIsInventoryOptionSelected={setIsInventoryOptionSelected}
          action={action}
          setRefresh={setRefresh}
          product={product!}
          categoryName={selectedInventoryOptionName}
          plantsList={plantsList}
          inputType={inputType}
          storeId={storeId}
          clientId={clientId}
          plantIdParam={plantIdParam}
        />
      )}
    </>
  );
}

export default UpdateInventoryDialog;
