import axiosInstance from "../constants/axiosInstance";

const getPlantFeatureAuthDataForCSM = async () => {
  try {
    const { data } = await axiosInstance().post(
      `/plantFeatureAuth/getPlantFeatureAuthDataForCSM`
    );
    return data.data;
  } catch (err) {
    console.error("ERROR | getRemarks | ", err);
  }
};

const getPlantFeatureAuthDataByPlantId = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantFeatureAuth/getPlantFeatureAuthDataByPlantId?plantId=${plantId}`
    );
    return data.data;
  } catch (err) {
    console.error("ERROR | getRemarks | ", err);
  }
};

const updatePlantFeatureAuth = async (
  plantId: string,
  updateData: any,
  options: any = {}
) => {
  try {
    const { data } = await axiosInstance().post(
      `/plantFeatureAuth/updatePlantFeatureAuth`,
      {
        plantId: plantId,
        updateData: updateData,
        options,
      }
    );
    return data.data;
  } catch (err) {
    console.error("ERROR | getRemarks | ", err);
  }
};

const isFeatureAccessibleByPlantId = async (plantId: string, featureName: string) => {
  try {
    const queryString = `?plantId=${encodeURIComponent(plantId)}&featureName=${encodeURIComponent(featureName)}`;
    const { data } = await axiosInstance().get(
      `/plantFeatureAuth/isFeatureAccessibleByPlantId${queryString}`
    );
    return data.data;
  } catch (err) {
    console.error('ERROR | getRemarks | ', err);
  }
};

export {
  getPlantFeatureAuthDataForCSM,
  updatePlantFeatureAuth,
  getPlantFeatureAuthDataByPlantId,
  isFeatureAccessibleByPlantId,
};
