import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { DateRange } from '@mui/x-date-pickers-pro';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { Stack, ThemeProvider, Typography } from '@mui/material';
import useIsTablet from '../utilities/customHooks/useIsTablet';
import useIsDesktop from '../utilities/customHooks/useIsDesktop';
import dpTheme from '../styles/theme/dpTheme';
import { PrimaryButton } from '../styles/global/components/dpButtons';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import { useState } from 'react';

const breakpoints = {
  mobile: '@media (max-width: 767px)',
  tablet: '@media (min-width: 768px) and (max-width: 1024px)',
  desktop: '@media (min-width: 1024px)',
};

const shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[] = [
  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('day')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today.endOf('day')];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('day')];
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = dayjs();
      const startOfLastMonth = today.subtract(1, 'month').startOf('month');
      const endOfLastMonth = today.subtract(1, 'month').endOf('month');
      return [startOfLastMonth, endOfLastMonth];
    },
  },

  {
    label: 'Reset',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('day'), today.endOf('day')];
    },
  },
];
interface ICustomDateRangePicker {
  dateRange: DateRange<Dayjs>;
  setDateRange: Function;
  handleCloseDialog: Function;
}
export default function CustomDateRangePicker({
  dateRange,
  setDateRange,
  handleCloseDialog,
}: ICustomDateRangePicker) {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Dayjs>>(dateRange);
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();
  const isDesktop = useIsDesktop();

  const handleCancel = () => {
    handleCloseDialog();
  };

  const handleApply = () => {
    if (selectedDateRange[1]) {
      selectedDateRange[1] = selectedDateRange[1].endOf('day');
    }
    setDateRange(selectedDateRange); // Apply the selected date range
    handleCloseDialog(); // Close the dialog after applying the date range
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDateRangePicker
          value={dateRange}
          onChange={newValue => setSelectedDateRange(newValue)}
          disableFuture={true}
          slotProps={{
            shortcuts: {
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          calendars={isTablet || isDesktop ? 2 : 1}
          sx={{
            overflowX: 'hidden', // Prevent horizontal overflow
            '.MuiPickersLayout-shortcuts': {
              gridRow: '1',
              gridColumn: '1/3',
              display: 'flex',
              ...(isTablet && { maxWidth: '620px !important' }), // Conditionally apply maxWidth for tablets
              ...(isDesktop && { maxWidth: '900px !important' }), // Conditionally apply maxWidth for desktops
              overflowX: 'auto', // Enable horizontal scrolling
              scrollbarWidth: 'thin', // Firefox scrollbar width
              scrollbarColor: 'transparent transparent', // Firefox scrollbar color
              msOverflowStyle: 'none', // Hide scrollbar in IE and Edge
            },
            '.MuiStack-root': {
              gridRow: '2',
              gridColumn: '1/3',
            },
            '.MuiPickersLayout-contentWrapper': {},
            '.MuiPickersLayout-shortcuts::-webkit-scrollbar': {
              display: 'none',
            },
            '.MuiListItem-root': {
              width: 'auto',
            },
            '.MuiPickersLayout-toolbar': {
              gridColumn: 1,
              gridRow: 2,
            },
            '.Mui-selected.MuiDateRangePickerDay-day': {
              backgroundColor: '#193458',
            },
            '.MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              backgroundColor: '#D8E7FD',
            },
            '.MuiDateRangePickerDay-hiddenDayFiller': {
              backgroundColor: 'white !important',
            },
            '.MuiDateRangeCalendar-monthContainer': {
              borderRight: 'none !important',
            },
            '.MuiDateRangeCalendar-root': {
              justifyContent: 'space-between',
            },
            '.MuiList-root': {
              paddingBottom: '0px',
            },
            '.MuiDateRangePickerToolbar-root': {
              padding: isDesktop ? '8px 16px' : '0px 16px 8px 16px',
            },
            '.MuiDateRangePickerToolbar-container': {
              alignItems: 'center',
            },
            '.MuiPickersToolbarText-root': {
              fontSize: '1.5rem',
              color: '#000000',
            },
            [breakpoints.tablet]: {
              '.MuiPickersLayout-shortcuts': {
                maxWidth: '600px !important',
              },
              '.MuiPickersLayout-contentWrapper': {
                gridRow: '3',
                gridColumn: '1',
                alignItems: 'center',
                justifyContent: 'center',
              },
            },

            [breakpoints.desktop]: {
              '.MuiPickersLayout-contentWrapper': {
                gridColumn: '1',
                gridRow: '3',
              },
            },

            [breakpoints.mobile]: {
              '.MuiPickersLayout-contentWrapper': {
                gridRow: '3',
                gridColumn: '1',
              },
              '.MuiPickersLayout-shortcuts': {
                maxWidth: '320px',
              },
            },
          }}
        />
        <Stack
          direction={isMobile ? 'column' : 'row'}
          gap={isMobile ? 2 : 4}
          justifyContent="flex-end"
        >
          <PrimaryButton
            type="submit"
            onClick={handleCancel}
            sx={{
              width: isMobile ? '100%' : '23%',
              backgroundColor: '#FFF',
              color: '#8B8B8B',
              fontWeight: 500,
              border: '1px solid #8B8B8B4D',
              '&:hover': {
                backgroundColor: '#FFF', // Keep the background color same as normal state
                color: '#8B8B8B', // Keep the text color same as normal state
                border: '1px solid #8B8B8B4D', // Keep the border color same as normal state
              },
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              CANCEL
            </Typography>
          </PrimaryButton>
          <PrimaryButton
            type="submit"
            onClick={handleApply}
            sx={{ backgroundColor: '#3271A9', color: '#FFFFFF', width: isMobile ? '100%' : '23%' }}
          >
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              APPLY
            </Typography>
          </PrimaryButton>
        </Stack>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
