import { Handle, Position } from 'reactflow';
import classes from '../../styles/LayoutVisualisationStyle.module.css';
import { Box, IconButton, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLayoutStore } from '../../../../store/LayoutStore2';
import { toast } from 'react-toastify';
import treeComponentImage from '../../../../assets/icons/ComponentIcon.svg';
import ConfigNodeDialog from './ConfigNodeDialogBox';
import { useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';

const ConfigNode = ({ id, data, isConnectable, sourcePosition, targetPosition }: any) => {
  // if (!data.configInfo?.displayNode) {
  //   return null; // Or return any fallback UI.
  // }

  const navigate = useNavigate();
  const { nodes, setNodes, resetStore } = useLayoutStore();
  const [openConfigBox, setOpenConfigBox] = useState(false);

  const [thisNode, setThisNode] = useState(nodes.find(node => node.id == id));

  useEffect(() => {
    setThisNode(nodes.find(node => node.id == id));
  }, []);

  const handleSettingsIconClick = (event: any) => {
    event.stopPropagation();
    setOpenConfigBox(true);
  };

  const handleTreeIconClick = () => {
    console.log(data.componentId);
    if (data.componentId && data.componentInside) {
      resetStore();
      navigate(`/layoutVisualisationConfig/${data.componentId}`);
    } else {
      toast.error('No layout diagram exists for this node');
    }
  };

  return (
    <div>
      <Handle
        type="target"
        position={targetPosition || Position.Left}
        isConnectable={isConnectable}
        className={classes.Node}
      />
      <Box
        p={1}
        border={1}
        borderRadius={1}
        sx={{
          // '&:hover': { background: 'rgba(0,0,0,0.1)' },
          background: data.configInfo?.displayNode ? 'rgba(0,0,0,0)' : 'red',
        }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Box>{data.label}</Box>
          <IconButton onClick={handleSettingsIconClick}>
            <SettingsIcon />
          </IconButton>
          {data.componentInside === true && (
            <IconButton onClick={handleTreeIconClick}>
              <img src={treeComponentImage} style={{ width: '18px', height: '18px' }} />
            </IconButton>
          )}
        </Stack>
      </Box>
      <Handle
        type="source"
        position={sourcePosition || Position.Right}
        id="b"
        isConnectable={isConnectable}
        className={classes.Node}
      />
      <ConfigNodeDialog
        node={thisNode}
        open={openConfigBox}
        onClose={() => {
          setOpenConfigBox(false);
        }}
        onSave={({ sourcePosition, targetPosition }: any) => {
          let nodeIndex = nodes.findIndex(n => n.id == id);
          if (nodeIndex > -1) {
            const updatedNodes = [...nodes];
            const node = updatedNodes[nodeIndex];
            node['targetPosition'] = targetPosition;
            node['sourcePosition'] = sourcePosition;
            setNodes(updatedNodes);
          }
        }}
      />
    </div>
  );
};

export default ConfigNode;
