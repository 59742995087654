//@ts-nocheck
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  Box,
  ListItem,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { PlantConfigStyle } from '../plantConfiguration/PlantConfigStyle';
import { PrimaryRoundedButton } from '../../styles/global/components/dpButtons';
import NextIcon from '@mui/icons-material/ArrowRightAlt';
import { savePlantChokePointConfig } from '../../services/PlantVisualisationConfig';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';
import { usePlantLayoutStore } from '../../store/PlantLayoutStore';

function EdgeSelection({
  selectedNodes,
  selectedSensors,
  edges,
  loadEdgeSelection,
  setLoadEdgeSelection,
}: any) {
  const [expanded, setExpanded] = useState('');
  const { plantId, nodesWithChokePoints, setNodesWithChokePoints } = usePlantLayoutStore();
  const navigate = useNavigate();

  useEffect(() => {
    const filteredNodesWithChokePoints = {};

    // Only include keys in nodesWithChokePoints that are present in selectedNodes
    selectedNodes.forEach(nodeId => {
      if (nodesWithChokePoints && nodesWithChokePoints.hasOwnProperty(nodeId)) {
        filteredNodesWithChokePoints[nodeId] = nodesWithChokePoints[nodeId];
      }
    });

    setNodesWithChokePoints(filteredNodesWithChokePoints);
  }, []);

  const handleChange = (nodeId: any) => (event, isExpanded) => {
    setExpanded(isExpanded ? nodeId : '');
  };

  const handleSelectChange = (event: any, nodeId) => {
    const tempNodesWithChokePoints = nodesWithChokePoints ? { ...nodesWithChokePoints } : {};
    const addition =
      tempNodesWithChokePoints[nodeId] &&
      tempNodesWithChokePoints[nodeId][event.target.name] &&
      tempNodesWithChokePoints[nodeId][event.target.name].length < event.target.value.length
        ? true
        : false;

    if (addition) {
      tempNodesWithChokePoints[nodeId][event.target.name] = [
        ...new Set([...tempNodesWithChokePoints[nodeId][event.target.name], ...event.target.value]),
      ];
    } else if (tempNodesWithChokePoints[nodeId]) {
      tempNodesWithChokePoints[nodeId][event.target.name] = event.target.value;
    } else if (!tempNodesWithChokePoints[nodeId]) {
      tempNodesWithChokePoints[nodeId] = {};
      tempNodesWithChokePoints[nodeId][event.target.name] = event.target.value;
    }
    setNodesWithChokePoints(tempNodesWithChokePoints);
  };

  const saveConfig = () => {
    const chokePointData = {
      plantId,
      nodes: nodesWithChokePoints,
    };
    savePlantChokePointConfig(chokePointData)
      .then(data => {
        toast.success('Choke Points updated successfully!');
      })
      .catch(err => {
        toast.error('Choke Points not updated!');
      });
  };

  return (
    <>
      <div
        style={{
          maxHeight: 'calc(100vh - 200px)',
          overflowY: 'auto',
          marginBottom: '1rem',
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center" marginBottom="10px">
          <ArrowBackIcon
            onClick={() => {
              setLoadEdgeSelection(!loadEdgeSelection);
            }}
            style={{ cursor: 'pointer' }}
          />
          <Typography variant="h6">Select Choke Points:</Typography>
        </Stack>
        {selectedNodes.map((nodeId: any) => (
          <Accordion key={nodeId} expanded={expanded === nodeId} onChange={handleChange(nodeId)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{nodeId}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List>
                <Typography variant="h6">
                  {selectedSensors[nodeId].length > 0 ? 'Select Edges:' : 'Select Sensors First!'}
                </Typography>
                {selectedSensors[nodeId].map((sensor: any) => (
                  <ListItem
                    key={sensor.sensorId}
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      paddingLeft: '0',
                    }}
                  >
                    <Typography>{sensor.sensorName}</Typography>
                    <Select
                      id={sensor.sensorId}
                      name={sensor.sensorId}
                      multiple
                      value={
                        nodesWithChokePoints &&
                        nodesWithChokePoints[nodeId] &&
                        nodesWithChokePoints[nodeId][sensor.sensorId]
                          ? nodesWithChokePoints[nodeId][sensor.sensorId]
                          : []
                      }
                      onChange={event => {
                        handleSelectChange(event, nodeId);
                      }}
                      renderValue={allSelectedValues => (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                          }}
                        >
                          {allSelectedValues.map((selectedValue: any) => (
                            <div key={selectedValue} style={PlantConfigStyle.selectedOptions}>
                              {selectedValue}
                            </div>
                          ))}
                        </div>
                      )}
                      style={{
                        minWidth: '12rem',
                        maxWidth: '18rem',
                      }}
                    >
                      {edges.map((edge: any) => (
                        <MenuItem key={edge.id} value={edge._id}>
                          {edge.id}
                        </MenuItem>
                      ))}
                    </Select>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <Box sx={{ display: 'flex', flexFlow: 'row nowrap', columnGap: '10px' }}>
        <PrimaryRoundedButton disabled={!nodesWithChokePoints} onClick={saveConfig}>
          Save
          <NextIcon style={{ justifySelf: 'flex-end' }} />
        </PrimaryRoundedButton>
        <PrimaryRoundedButton
          disabled={!nodesWithChokePoints}
          onClick={() => {
            navigate(`/DataInputConfigv2/${plantId}`);
          }}
        >
          Next
          <NextIcon style={{ justifySelf: 'flex-end' }} />
        </PrimaryRoundedButton>
      </Box>
    </>
  );
}

export default EdgeSelection;
