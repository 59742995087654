function filterGranularityOptionsBasedOnTimeRange(timeRangeInMins: number) {
  let allowedGranularity: string[] = [];
  const ONE_DAY = 1440;
  if (timeRangeInMins < 3 * ONE_DAY) {
    allowedGranularity = ['minutes', 'hours', 'days'];
  } else if (timeRangeInMins > 3 * ONE_DAY && timeRangeInMins <= 7 * ONE_DAY) {
    allowedGranularity = ['hours', 'days'];
  } else if (timeRangeInMins > 7 * ONE_DAY && timeRangeInMins <= 60 * ONE_DAY) {
    allowedGranularity = ['days', 'weeks'];
  } else if (timeRangeInMins > 60 * ONE_DAY) {
    allowedGranularity = ['weeks', 'months'];
  }
  return allowedGranularity;
}

const add530Hours = (
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined
) => {
  if (!startDate || !endDate) {
    return {
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  const offsetMilliseconds = 1000 * 60 * (5 * 60 + 30); // 5 hours 30 minutes in milliseconds
  const start = typeof startDate === 'string' ? new Date(startDate) : startDate;
  const end = typeof endDate === 'string' ? new Date(endDate) : endDate;

  return {
    startDate: new Date(start.getTime() + offsetMilliseconds).toISOString(),
    endDate: new Date(end.getTime() + offsetMilliseconds).toISOString(),
  };
};

const getAggregationTypeFromId = (id: string) => {
  if (id.includes('max')) return 'Maximum';
  else if (id.includes('min')) return 'Minimum';
  else if (id.includes('tws') || id.includes('hoursRun')) return 'Time weighted sum';
  else if (id.includes('cum')) return 'Cumulative';
  else if (id.includes('avg')) return 'Average';
  else return 'Raw';
};

export { filterGranularityOptionsBasedOnTimeRange, add530Hours, getAggregationTypeFromId };
