import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Button,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Box,
  IconButton,
  Stack,
} from '@mui/material';
import { getPlantsForUser } from '../../services/tickets';
import CloseIconSvg from '../../assets/icons/closeIcon.svg';

function WidgetSelectPopup({ open, onClose, onSelect }: any) {
  const [plants, setPlants] = useState<any[]>([]);
  const [selectedPlantId, setSelectedPlantId] = useState<any>('');
  const [widgetType, setWidgetType] = useState<string>('GeneralisedWidget');

  useEffect(() => {
    const fetchPlants = async () => {
      const response: any = await getPlantsForUser('null'); // Adjust this to your real function call
      setSelectedPlantId(response?.[0]?.plantId || '');
      setPlants(response);
    };
    fetchPlants();
  }, []);

  const handleWidgetTypeChange = (type: string) => {
    setWidgetType(type);
  };

  const handlePlantChange = (plant: any) => {
    setSelectedPlantId(plant);
  };

  const handleApply = () => {
    if (widgetType === 'GeneralisedWidget' && selectedPlantId) {
      if (selectedPlantId == 'ALL') {
        const plantIds = plants.map(p => p.plantId);
        onSelect('GeneralisedWidget', plantIds, 'All Plants');
      } else {
        const plant = plants.find(p => p.plantId === selectedPlantId);
        onSelect('GeneralisedWidget', plant ? [plant.plantId] : [], plant ? plant.plantName : '');
      }
    } else {
      onSelect('MapWidget', plants);
    }
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      PaperProps={{
        elevation: 0, // This removes the shadow from the Dialog
        style: { borderRadius: '12px' },
      }}
    >
      <DialogContent>
        <Stack direction="column" gap={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Box sx={{ fontSize: '18px', fontWeight: 550 }}>Select Widget - Custom Widget</Box>
            <IconButton aria-label="close" onClick={handleCancel} sx={{ marginLeft: '20px' }}>
              <img src={CloseIconSvg} style={{ width: '12px', height: '12px' }} />
            </IconButton>
          </Stack>
          <Box width="300px">
            {' '}
            {/* Adjust width as necessary */}
            <FormControl fullWidth>
              <InputLabel id="select-widget-type-label">Widget Type</InputLabel>
              <Select
                labelId="select-widget-type-label"
                value={widgetType}
                label="Widget Type"
                onChange={event => {
                  handleWidgetTypeChange(event.target.value);
                }}
              >
                <MenuItem value="GeneralisedWidget">GeneralisedWidget</MenuItem>
                <MenuItem value="MapWidget">MapWidget</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {widgetType === 'GeneralisedWidget' && (
            <Box width="300px">
              {' '}
              {/* Adjust width as necessary */}
              <FormControl fullWidth>
                <InputLabel id="select-plant-label">Plant Name</InputLabel>
                <Select
                  labelId="select-plant-label"
                  value={selectedPlantId}
                  label="Plant Name"
                  onChange={event => {
                    handlePlantChange(event.target.value);
                  }}
                >
                  {plants.map((plant: any) => (
                    <MenuItem key={plant.plantId} value={plant.plantId}>
                      {plant.plantName}
                    </MenuItem>
                  ))}
                  <MenuItem value="ALL">All Plants</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}

          <Stack direction="row" justifyContent="space-between" sx={{ width: '100%' }} gap={2}>
            <Button
              onClick={handleCancel}
              color="primary"
              variant="contained"
              sx={{
                background: '#fff',
                color: '#000',
                border: '1px solid #8B8B8B4D',
                '&:hover': {
                  backgroundColor: '#f4f4f4', // Light gray background on hover
                },
              }}
              fullWidth
              disableElevation
            >
              Cancel
            </Button>
            <Button
              onClick={handleApply}
              color="primary"
              variant="contained"
              fullWidth
              disableElevation
              sx={{ background: '#3271A9' }}
            >
              Apply
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

export default WidgetSelectPopup;
