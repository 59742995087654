// @ts-nocheck
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Fade,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import dpTheme from '../styles/theme/dpTheme';
import LayoutComponent from '../components/LayoutComponent';
import styles from '../styles/pages/Store.module.css';
import { Link, useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SearchIcon from '@mui/icons-material/Search';
import color from '../styles/color';
import { GridFilterListIcon } from '@mui/x-data-grid';
import { getAllStoresAssetData } from '../services/stores';
import { getStoreCategories } from '../services/Store';
import { getStoreItemsForClient } from '../services/Store';
import useIsMobile from '../utilities/customHooks/useIsMobile';
import { useTabs } from '../components/Tabs/Tab';
import { AllStoreTabs } from '../components/Tabs/StoreTabs';
import { AllAssetTabs } from '../components/Tabs/AssetTabs';
import WarningIcon from '@mui/icons-material/Warning';

interface IStoreCategory {
  value: string;
  label: string;
}

interface IStoreCategory {
  value: string;
  label: string;
}
interface StoreItemDetails {
  assetIds: string[];
  consumption: {
    time: string;
    value: string;
  };
  consumptionPerDay: number;
  expectedConsumption: {
    time: string;
    value: string;
  };
  id: string;
  isActive: boolean;
  isDeviationOverThreshold: boolean;
  itemId: string;
  lastConsumption: string;
  lastUpdated: any;
  name: string;
  stockAvailable: number;
  unit: string;
}

interface StoreDataParams {
  assetIds: string[];
  clientIds: string[];
  storeCategoriesName: string;
  storeId: string;
  storeItemDetails: StoreItemDetails[];
  _id: string;
}

const StoreDetails = () => {
  const [openC, setOpenC] = useState(false);
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [storeCategories, setStoreCategories] = useState<IStoreCategory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  let userid: string | null = localStorage.getItem('userid')!;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [searchQuery, setSearchQuery] = useState('');
  const [finalSearchQuery, setFinalSearchQuery] = useState('');

  const [storeItemsData, setStoreItemsData] = useState<StoreDataParams[]>([]);
  const [isLoadingStoreItems, setIsLoadingStoreItems] = useState<boolean>(true);
  const isMobile = useIsMobile();
  const [allStoreIds, setAllStoreIds] = useState<string>('');
  const [storeNameViaId, setStoreNameViaId] = useState<{
    [storeId: string]: string;
  }>({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const navigate = useNavigate();

  const {
    storesData,
    setStoresData,
    selectedStoreAssets,
    setSelectedStoreAssets,
    selectedStoreTab,
    setSelectedStoreTab,
    storeName,
    setStoreName,
    selectedAssetTab,
    setSelectedAssetTab,
    assetName,
    setAssetName,
  } = useTabs();

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenC(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryChange = (selectedCategory: string) => {
    setSelectedCategories(prevSelectedCategories => {
      const newSelection = prevSelectedCategories.includes(selectedCategory)
        ? prevSelectedCategories.filter(category => category !== selectedCategory)
        : [...prevSelectedCategories, selectedCategory];

      // Check if all categories are selected after the change
      setIsAllSelected(storeCategories.every(category => newSelection.includes(category.value)));

      return newSelection;
    });
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedCategories([]); // Unselect all
    } else {
      setSelectedCategories(storeCategories.map(category => category.value)); // Select all
    }
    setIsAllSelected(!isAllSelected);
  };

  const getStoresAndAssets = async () => {
    try {
      const data = await getAllStoresAssetData(userid!);
      setStoresData(data.data.stores);
      setAllStoreIds(data.data.storeIds);
      setStoreNameViaId(data.data.storeNameViaId);
    } catch (err: any) {
      throw new Error(err);
    } finally {
      setLoading(false);
      setIsLoadingStoreItems(true);
    }
  };

  const fetchStoreCategories = async () => {
    let tempStoreCategories = await getStoreCategories();
    setStoreCategories(tempStoreCategories);
    setSelectedCategories(tempStoreCategories?.map((category: any) => category?.value));
  };

  const getStoreItemsData = async (searchQuery: string = '', newSearch = false) => {
    setIsLoadingStoreItems(true);

    let storeIdParam = null;
    let assetIdParam = null;

    if (storeName === 'All Store') {
      storeIdParam = allStoreIds;
    } else {
      storeIdParam = selectedStoreTab._id;
    }

    if (assetName === 'All Asset') {
      assetIdParam = assetName;
    } else {
      assetIdParam = selectedAssetTab._id;
    }

    const data = await getStoreItemsForClient(
      storeIdParam,
      assetIdParam,
      '',
      newSearch ? 0 : page,
      rowsPerPage,
      searchQuery,
      selectedCategories
    );

    if (data) {
      setStoreItemsData(data.data.data);
      setTotalItems(data.data.totalItems);
      setIsLoadingStoreItems(false);
    }
  };

  const handleSearch = () => {
    setPage(0);
    setFinalSearchQuery(searchQuery);
    getStoreItemsData(searchQuery, true); // since new Search query is entered, flag is set to true
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleTableRowClick = (storeItem: any, storeItemDetail: any) => {
    if (storeItem.storeCategoriesName === 'Chemicals') {
      if (storeName === 'All Store' && assetName === 'All Asset') {
        navigate(
          `/ChemicalLogs?storeId=${storeItem.storeId}&assetId=${assetName}&chemicalId=${
            storeItemDetail.itemId
          }&chemicalName=${storeItemDetail.name}&chemicalUnit=${storeItemDetail.unit.toLowerCase()}`
        );
      } else if (storeName === 'All Store' && assetName !== 'All Asset') {
        navigate(
          `/ChemicalLogs?storeId=${storeItem.storeId}&assetId=${selectedAssetTab._id}&chemicalId=${
            storeItemDetail.itemId
          }&chemicalName=${storeItemDetail.name}&chemicalUnit=${storeItemDetail.unit.toLowerCase()}`
        );
      } else if (storeName !== 'All Store' && assetName === 'All Asset') {
        navigate(
          `/ChemicalLogs?storeId=${storeItem.storeId}&assetId=${assetName}&chemicalId=${
            storeItemDetail.itemId
          }&chemicalName=${storeItemDetail.name}&chemicalUnit=${storeItemDetail.unit.toLowerCase()}`
        );
      } else if (storeName !== 'All Store' && assetName !== 'All Asset') {
        navigate(
          `/ChemicalLogs?storeId=${storeItem.storeId}&assetId=${selectedAssetTab._id}&chemicalId=${
            storeItemDetail.itemId
          }&chemicalName=${storeItemDetail.name}&chemicalUnit=${storeItemDetail.unit.toLowerCase()}`
        );
      }
    }
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        await getStoresAndAssets();
        await fetchStoreCategories();
        setDataLoaded(true);
      } catch (error) {
        console.error('Error loading data:', error);
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    if (dataLoaded) {
      getStoreItemsData(finalSearchQuery);
      setSearchQuery(finalSearchQuery);
    }
  }, [storeName, assetName, page, rowsPerPage, selectedCategories]);

  useEffect(() => {
    setAssetName('All Asset');
    // setSearchQuery('');
    setPage(0);
  }, [storeName]);

  useEffect(() => {
    setPage(0);
  }, [assetName]);

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent factoryResetContainer={true}>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
          </Box>
        ) : (
          <Box className={styles.StoreConfigurationPage}>
            <Breadcrumbs aria-label="Stores Breadcrumb">
              <Link className={styles.NoUnderline} to="/StoreDetails">
                Inventory
                <ArrowForwardIcon fontSize="small" style={{ margin: '0px 10px', color: '#666' }} />
                List
              </Link>
            </Breadcrumbs>
            <AllStoreTabs
              storesData={storesData}
              selectedStoreAssets={selectedStoreAssets}
              setSelectedStoreAssets={setSelectedStoreAssets}
              selectedStoreTab={selectedStoreTab}
              setSelectedStoreTab={setSelectedStoreTab}
              storeName={storeName}
              setStoreName={setStoreName}
            />
            <AllAssetTabs
              selectedStoreAssets={selectedStoreAssets}
              setSelectedStoreAssets={setSelectedStoreAssets}
              selectedAssetTab={selectedAssetTab}
              setSelectedAssetTab={setSelectedAssetTab}
              assetName={assetName}
              setAssetName={setAssetName}
            />
            <Box className={styles.SearchSection}>
              <TextField
                placeholder="Search for Items"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                  className: styles.SearchField,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    fieldset: {
                      border: '1px solid #183650',
                    },
                    '&.Mui-focused fieldset': {
                      border: '1.6px solid #183650',
                    },
                  },
                }}
              />
              <Button
                id="fade-button"
                aria-controls={open ? 'fade-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{
                  border: `1px solid ${color.dpDarkBlue}99`,
                  color: '#000',
                  marginLeft: !isMobile ? '1.75%' : '2.5%',
                  marginRight: !isMobile ? '1.75%' : '2.5%',
                  minWidth: '54px',
                  padding: '16px',
                }}
              >
                <GridFilterListIcon fontSize="medium" />
              </Button>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '& .MuiPaper-root': {
                    border: '1px solid #00000066',
                    padding: 0,
                    boxShadow: '0px 2px 8px 0px #1A335626',
                  },
                }}
              >
                <MenuItem onClick={handleSelectAll} sx={{ padding: 0, paddingRight: '30px' }}>
                  <Checkbox checked={isAllSelected} />
                  <Typography variant="body1">All</Typography>
                </MenuItem>
                <Divider />
                {storeCategories?.map((category, index) => (
                  <React.Fragment key={category.value}>
                    <MenuItem
                      value={category.value}
                      onClick={() => handleCategoryChange(category.value)}
                      sx={{ padding: 0, paddingRight: '30px' }}
                    >
                      <Checkbox checked={selectedCategories.includes(category.value)} />
                      <Typography variant="body1">{category.label}</Typography>
                    </MenuItem>
                    {index < storeCategories.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </Menu>
              {isMobile ? (
                <Button
                  variant="outlined"
                  sx={{
                    color: '#183650 !important',
                    borderColor: '#183650 !important',
                    minWidth: '54px',
                    padding: '16px',
                  }}
                  onClick={handleSearch}
                >
                  <SearchIcon />
                </Button>
              ) : (
                <Button variant="outlined" className={styles.SearchButton} onClick={handleSearch}>
                  Search
                </Button>
              )}
            </Box>
            <TableContainer component={Paper} className={styles.StoresTable}>
              <Table aria-label="Stores Table">
                <TableHead>
                  <TableRow>
                    <TableCell className={styles.StoreTableHeaderCellBorderBottom}>Name</TableCell>
                    {storeName === 'All Store' && (
                      <TableCell size="medium" className={styles.StoreTableHeaderCell}>
                        Store Name
                      </TableCell>
                    )}
                    <TableCell className={styles.StoreTableHeaderCell}>Category</TableCell>
                    <TableCell className={styles.StoreTableHeaderCell}>Stock Available</TableCell>
                    <TableCell className={styles.StoreTableHeaderCell}>
                      Last Consumption
                      <Typography
                        style={{
                          fontSize: '10px',
                          color: '#999999',
                          fontWeight: 400,
                        }}
                        variant="caption"
                        display="inline"
                        gutterBottom
                      >
                        &nbsp;| {assetName === 'All Asset' ? 'In Store' : 'In Asset'}
                      </Typography>
                    </TableCell>
                    <Table>
                      {assetName === 'All Asset' && (
                        <TableHead>
                          <TableRow>
                            <TableCell
                              align="center"
                              colSpan={2}
                              className={styles.StoreTableHeaderCell}
                            >
                              Consumption
                              <Typography
                                style={{
                                  fontSize: '10px',
                                  color: '#999999',
                                  fontWeight: 400,
                                }}
                                noWrap
                                variant="caption"
                                display="inline"
                                gutterBottom
                              >
                                &nbsp;| In Store
                              </Typography>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      )}
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{ lineHeight: '2.2', fontWeight: 500 }}
                            align="center"
                            className={styles.StoreTableHeaderCell}
                          >
                            {assetName === 'All Asset' ? 'Actual' : 'Actual Consumption'}
                            <Typography
                              style={{
                                fontSize: '10px',
                                color: '#999999',
                                fontWeight: 400,
                              }}
                              variant="caption"
                              display="inline"
                              gutterBottom
                            >
                              &nbsp; {assetName === 'All Asset' ? '' : '| In Asset'}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{ lineHeight: '2.2', fontWeight: 500 }}
                            align="center"
                            className={styles.StoreTableHeaderCell}
                          >
                            {assetName === 'All Asset' ? 'Expected' : 'Expected Consumption'}
                            <Typography
                              style={{
                                fontSize: '10px',
                                color: '#999999',
                                fontWeight: 400,
                              }}
                              variant="caption"
                              display="inline"
                              gutterBottom
                            >
                              &nbsp; {assetName === 'All Asset' ? '' : '| In Asset'}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableRow>
                </TableHead>
                {isLoadingStoreItems ? (
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={6} sx={{ borderBottom: 'none' }}>
                        <Box textAlign="center">
                          <CircularProgress />
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className={styles.StoresTableBody}>
                    {storeItemsData?.map(storeItem => (
                      <React.Fragment key={storeItem?._id}>
                        {storeItem?.storeItemDetails?.map(storeItemDetail => (
                          <TableRow
                            onClick={() => handleTableRowClick(storeItem, storeItemDetail)}
                            sx={{
                              borderBottom: storeItem?.storeItemDetails ? 'none' : '',
                              background: storeItemDetail?.isDeviationOverThreshold
                                ? '#FEF5F3'
                                : '',
                            }}
                            key={storeItemDetail?.id}
                            className={styles.StoresTableRow}
                          >
                            <TableCell
                              className={styles.StoresTableRowCell}
                              style={{ whiteSpace: 'nowrap' }}
                            >
                              {storeItemDetail?.name}
                              {!storeItemDetail?.isActive && (
                                <WarningIcon
                                  fontSize="small"
                                  sx={{
                                    color: '#E64A23',
                                    marginLeft: '5px',
                                    marginBottom: '-4px',
                                  }}
                                />
                              )}
                            </TableCell>
                            {storeName === 'All Store' && (
                              <TableCell className={styles.StoresTableRowCell}>
                                {storeNameViaId[`${storeItem.storeId}`]}
                              </TableCell>
                            )}
                            <TableCell className={styles.StoresTableRowCell}>
                              {storeItem.storeCategoriesName}
                            </TableCell>
                            <TableCell className={styles.StoresTableRowCell}>
                              {storeItemDetail.stockAvailable ? (
                                <Typography
                                  textTransform="capitalize"
                                  className={styles.TypographyColor}
                                >
                                  {storeItemDetail.stockAvailable} {storeItemDetail?.unit}
                                </Typography>
                              ) : (
                                '-'
                              )}
                            </TableCell>
                            <TableCell className={styles.StoresTableRowCell}>
                              {storeItemDetail?.lastConsumption ? (
                                <>
                                  <Typography
                                    textTransform="capitalize"
                                    className={styles.TypographyColor}
                                  >
                                    {storeItemDetail?.lastConsumption} {storeItemDetail?.unit}
                                  </Typography>
                                  <Typography variant="caption" color="textSecondary">
                                    Last Updated {storeItemDetail?.lastUpdated}
                                  </Typography>
                                </>
                              ) : (
                                '-'
                              )}
                            </TableCell>
                            <TableRow
                              style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                              }}
                            >
                              <TableCell
                                align="left"
                                style={{ borderBottom: 'none', minWidth: '100px' }}
                              >
                                {storeItemDetail?.consumption?.value ? (
                                  <>
                                    <Typography
                                      textTransform="capitalize"
                                      noWrap
                                      className={styles.TypographyColor}
                                    >
                                      {storeItemDetail?.consumption?.value}
                                      {storeItemDetail?.unit === 'unit'
                                        ? ''
                                        : storeItemDetail?.unit}
                                    </Typography>
                                    <Typography noWrap variant="caption" color="textSecondary">
                                      {storeItemDetail?.consumption?.time}
                                    </Typography>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ borderBottom: 'none', minWidth: '100px' }}
                              >
                                {storeItemDetail?.expectedConsumption?.value ? (
                                  <>
                                    <Typography
                                      textTransform="capitalize"
                                      noWrap
                                      className={styles.TypographyColor}
                                    >
                                      {storeItemDetail?.expectedConsumption?.value}{' '}
                                      {storeItemDetail?.unit === 'unit'
                                        ? '-'
                                        : storeItemDetail?.unit}
                                    </Typography>
                                    <Typography noWrap variant="caption" color="textSecondary">
                                      {storeItemDetail?.expectedConsumption?.time}
                                    </Typography>
                                  </>
                                ) : (
                                  '-'
                                )}
                              </TableCell>
                            </TableRow>
                          </TableRow>
                        ))}
                      </React.Fragment>
                    ))}
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        )}
      </LayoutComponent>
    </ThemeProvider>
  );
};

export default StoreDetails;
