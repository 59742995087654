import { useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';

const ConfigNodeDialog = (props: any) => {
  const { node, open, onClose, onSave } = props;

  const [sourcePosition, setSourceHandlePosition] = useState(node?.sourcePosition || 'right');
  const [targetPosition, setTargetHandlePosition] = useState(node?.targetPosition || 'left');

  const handleSourceChange = (event: any) => {
    setSourceHandlePosition(event.target.value);
  };

  const handleTargetChange = (event: any) => {
    setTargetHandlePosition(event.target.value);
  };

  const handleSave = () => {
    onSave({ sourcePosition, targetPosition });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Node Configuration</DialogTitle>
      <DialogContent>
        <FormControl fullWidth margin="normal">
          <InputLabel>Source Position</InputLabel>
          <Select value={sourcePosition} onChange={handleSourceChange} label="Source Position">
            <MenuItem value="top">Top</MenuItem>
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="right">Right</MenuItem>
            <MenuItem value="bottom">Bottom</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Target Position</InputLabel>
          <Select value={targetPosition} onChange={handleTargetChange} label="Target Position">
            <MenuItem value="top">Top</MenuItem>
            <MenuItem value="left">Left</MenuItem>
            <MenuItem value="right">Right</MenuItem>
            <MenuItem value="bottom">Bottom</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigNodeDialog;
