//@ts-nocheck
import { useState } from 'react';
import LayoutComponent from '../components/LayoutComponent';
import DataBreakDetailsForSinglePlantTable from '../components/DataBreak/DataBreakDetailsForSinglePlantTable';
import SearchBox from '../core-components/theme/components/Searchbox/Searchbox';
import { ThemeProvider } from '@mui/material';
import Theme from '../core-components/theme/theme';
import TwoRowLayout from '../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';

export default function DataBreakDetailsForSinglePlant() {
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <ThemeProvider theme={Theme}>
      <LayoutComponent>
        <TwoRowLayout
          child1={
            <SearchBox
              onSearch={searchQuery => {
                setSearchQuery(searchQuery);
              }}
              onClear={() => {
                setSearchQuery('');
              }}
              placeholder="Search for PLC Name / Reason / Status"
              fullWidth
            />
          }
          child2={<DataBreakDetailsForSinglePlantTable searchQuery={searchQuery} />}
          containerProps={{ sx: { mt: 5 } }}
        />
      </LayoutComponent>
    </ThemeProvider>
  );
}
