export default function DetailIcon() {
  return (
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.98427 12.1686L3.98744 13.1797L11.5815 5.64523L11.5629 10.3523L12.9915 10.358L13.0196 3.21517L5.87682 3.18703L5.87119 4.61559L10.5783 4.63413L2.98427 12.1686Z"
        fill="#ACB3B9"
      />
    </svg>
  );
}
