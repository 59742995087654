import axiosInstance from '../constants/axiosInstance';

interface ISaveWorkspacePayload {
  name: string;
  description: string;
  assets: string[]; // asset Ids
}

const saveWorkspace = async (workspaceData: ISaveWorkspacePayload) => {
  try {
    const { data } = await axiosInstance().post('/newUsers/saveWorkspace', workspaceData);
    return data.data;
  } catch (error) {
    console.error('Error saving workspace:', error);
  }
};

const getWorkspaceDetails = async (workspaceId: string) => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/getWorkspaceDetails`, { workspaceId });
    return data.data;
  } catch (error) {
    console.error('Error fetching workspace Details:', error);
  }
};

const getWorkspaceList = async () => {
  try {
    const { data } = await axiosInstance().post(`/newUsers/workspaceList`);
    return data.data;
  } catch (error) {
    console.error('Error fetching workspace configuration list:', error);
  }
};

const deleteWorkspace = async (workspaceId: string) => {
  try {
    const response = await axiosInstance().post(`/newUsers/deleteWorkspace`, { workspaceId });
    return response.data;
  } catch (error) {
    console.error('Error deleting workspace:', error);
  }
};

export { getWorkspaceDetails, saveWorkspace, getWorkspaceList, deleteWorkspace };
