import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { Box, TextField } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ChatIcon from '@mui/icons-material/Chat';
import BuildIcon from '@mui/icons-material/Build';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckIcon from '@mui/icons-material/Check';
//@ts-ignore
import ModalImage from 'react-modal-image';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import moment from 'moment';
import SendIcon from '@mui/icons-material/Send';
import ReplayIcon from '@mui/icons-material/Replay';

import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { getTicketData, addCommentToTicket } from '../../services/tickets';
import { ticketInterface } from '../../Interfaces/ticketsv2Interfaces';

const styles = {
  ticketBox: {
    width: '100%',
    display: 'inline-block',
    boxSizing: 'border-box',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  timelineDot: {
    backgroundColor: 'black',
    width: '16px',
    height: '16px',
    display: 'grid',
    placeItems: 'center',
  },
  icons: {
    width: 'inherit',
    height: 'inherit',
  },
  eventBox: {
    border: '1px solid #D4DCE5',
    background: '#fff',
  },
  timeFontInsideEventBox: {
    fontSize: '10px',
    color: '#4D4D4D',
  },
  commentBoxContainer: {
    width: '100%',
    display: 'flex',
    gap: '10px',
    fontSize: '12px',
  },
  sendIconButton: {
    width: '30px',
    height: '32px',
    display: 'grid',
    placeItems: 'center',
    border: '1px solid black',
  },
};

interface ticketComponentProps {
  ticketId: string;
  ticketIndex: number;
  plantId: string;
}

export default function TicketComponentv2(props: ticketComponentProps) {
  const { ticketId, ticketIndex, plantId } = props;
  const [ticketData, setTicketData] = useState<ticketInterface | null>(null);
  const [comment, setComment] = useState('');
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    getTicketData(ticketId, plantId).then(data => {
      console.log('ticketData : ', data);
      setTicketData(data);
    });
  }, [refresh]);

  const addCommentToTicketFunc = (comment: string) => {
    addCommentToTicket(ticketId, plantId, comment).then(data => {
      setRefresh(!refresh);
    });
  };

  if (ticketData === null) {
    return <div style={{ width: '100%' }}>loading ...</div>;
  } else if (ticketData)
    return (
      <Box my={1} sx={styles.ticketBox}>
        <Accordion
          elevation={0}
          defaultExpanded={ticketIndex === 0 ? true : false}
          style={{ width: '100%', fontFamily: 'Roboto' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              fontFamily: 'Roboto',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            {moment(ticketData?.createdAt.toString(), 'YYYY-MM-DDTHH:mm:ss.SSS').format(
              'MMM Do YY, h:mm a'
            )}
          </AccordionSummary>
          <AccordionDetails sx={{ background: '#F5F6F9' }}>
            <Timeline style={{ padding: '0' }}>
              {ticketData?.events?.map((item, index) => {
                console.log('item : ', item);
                // return <p>{item.message}</p>;
                return (
                  <TimelineItem style={{}}>
                    <TimelineOppositeContent
                      style={{ flex: '0', padding: 0 }}
                    ></TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot style={styles.timelineDot}>
                        {item.event === 'created' && <ScheduleIcon style={styles.icons} />}
                        {item.event === 'comment' && <ChatIcon style={styles.icons} />}
                        {item.event === 'attachment' && <AttachFileIcon style={styles.icons} />}
                        {item.event === 'closed' && <CheckIcon style={styles.icons} />}
                        {item.event === 'inProgress' && <BuildIcon style={styles.icons} />}
                        {item.event === 'escalated' && <PriorityHighIcon style={styles.icons} />}
                        {item.event === 'Overwritten' && <NoteAltIcon style={styles.icons} />}
                        {item.event === 'reOpened' && <ReplayIcon style={styles.icons} />}
                      </TimelineDot>
                      {index === ticketData.events.length - 1 ? null : <TimelineConnector />}
                    </TimelineSeparator>
                    <TimelineContent>
                      <Stack p={1} spacing={1} borderRadius={2} style={styles.eventBox}>
                        <div style={{ color: '#383D4A', fontSize: '12px' }}>{item.message}</div>
                        {item.event === 'comment' ? (
                          <div style={{ textAlign: 'center' }}>
                            <Box m={1} p={1} bgcolor="#F5F6F9" borderRadius={5}>
                              <div
                                style={{
                                  fontSize: 12,
                                  fontWeight: '500',
                                  color: '#193458',
                                }}
                              >
                                {item.comment}
                              </div>
                            </Box>
                          </div>
                        ) : null}
                        {item.event === 'attachment' ? (
                          <div style={{ textAlign: 'center' }}>
                            <Box m={1} p={1} bgcolor="#D3D3D3" borderRadius={5}>
                              <ModalImage small={item.src} large={item.src} alt={item.message} />
                            </Box>
                          </div>
                        ) : null}
                        <div style={styles.timeFontInsideEventBox}>
                          {moment(item.time?.toString(), 'YYYY-MM-DDTHH:mm:ss.SSS').format(
                            'MMM Do YY, h:mm a'
                          )}
                        </div>
                      </Stack>
                    </TimelineContent>
                  </TimelineItem>
                );
              })}
            </Timeline>
            <Box style={styles.commentBoxContainer}>
              <TextField
                fullWidth
                id="outlines-basic"
                label="Add a comment"
                variant="outlined"
                placeholder="Add a new comment"
                value={comment}
                margin="none"
                size="small"
                inputProps={{
                  disableUnderline: true,
                  style: { fontSize: 12 },
                }}
                InputLabelProps={{
                  style: { fontSize: 12 },
                }}
                onChange={event => {
                  setComment(event.target.value);
                }}
              />
              <Box
                borderRadius={1}
                style={styles.sendIconButton}
                onClick={() => {
                  addCommentToTicketFunc(comment);
                }}
              >
                <IconButton aria-label="add an alarm" style={{}}>
                  <SendIcon style={{ fontSize: '16px', color: 'black' }} />
                </IconButton>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  else return null;
}
