import { useCallback, useState } from 'react';
import './Map.css';
import { Divider, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect } from 'react';
import GeneralisedWidgetForPdfPage from '../components/GeneralisedWidgetComponents/GeneralisedWidgetForPdfPage';
import { useParams } from 'react-router-dom';
import waterQuantityIcon from '../constants/Images/waterQuantityIcon.svg';
import waterQualityIcon from '../constants/Images/waterQualityIcon.svg';
import safetyIcon from '../constants/Images/safetyIcon.svg';
import biologicalProcessIcon from '../constants/Images/biologicalProcessIcon.svg';
import energyIcon from '../constants/Images/energyIcon.svg';
import equipmentMaintenanceIcon from '../constants/Images/equipmentMaintenanceIcon.svg';

import digitalPaaniLogoV2 from '../constants/Images/digitalPaaniLogoV2.png';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import classes from '../styles/emailPage.module.css';
// import {
// dashboardPageInterface,
// timeFrame,
// } from "../Interfaces/dashboardPageInterfaces";
import ErrorBoundary from '../components/errorBoundary/errorBoundary';
// import { widgetInterface } from "../Interfaces/widgetInterfaces";
// import { getPageConfigDataV2 } from "../services/pageConfig";
import { getDashboardPageData } from '../services/dashboardPage';
import { calculateTimeFrame } from '../utilities/helpers';

interface paramsInterface {
  [plantId: string]: string;
  pageId: string;
  days: string;
  startDateUrl: string;
  endDateUrl: string;
}

let allIndexDetails: any = [];
let count = 0;

export default function EmailPage() {
  const {
    plantId,
    pageId = '',
    startDateUrl,
    endDateUrl,
    granularity,
  } = useParams<paramsInterface>();
  const [timeFrame, setTimeFrame] = useState<any>(null);
  const [pageData, setPageData] = useState<any | null>(null);
  const [pageWidgetCluster, setPageWidgetCluster] = useState<any>([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  //@ts-ignore
  const [refreshWidgets, setRefreshWidgets] = useState(false);
  //@ts-ignore
  const [controller, setController] = useState(new AbortController());

  const [indexDetails, setIndexDetails] = useState([]);

  const handleChildChange = useCallback(
    (data: any) => {
      allIndexDetails = [...allIndexDetails, data];
      if (allIndexDetails.length == count) {
        setIndexDetails(allIndexDetails);
      }
    },
    [indexDetails]
  );

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const dashboardPageData = await getDashboardPageData(pageId);
      setPageData(dashboardPageData);
      setPageWidgetCluster(dashboardPageData?.pageConfig?.data);

      let timeFrame: any;
      if (startDateUrl !== 'null' && endDateUrl !== 'null' && granularity !== 'null') {
        //Setting Timeframe
        let tf: any = getTimeFrameFromUrlParameters();
        if (tf != null) timeFrame = tf;
        setTimeFrame(timeFrame);
      } else {
        let defaultGranularitySettings = dashboardPageData?.pageConfig?.defaultGranularitySettings;
        let defaultTimeRange = dashboardPageData?.pageConfig?.defaultTimeRangeSettings;
        let timeFrame = calculateTimeFrame(defaultTimeRange);
        setTimeFrame({
          ...timeFrame,
          granularity: defaultGranularitySettings.unit.toLowerCase(),
        });
      }

      //all widget count
      dashboardPageData?.pageConfig?.data.forEach((widgetCluster: any) => {
        if (widgetCluster.showWidgetCluster) {
          widgetCluster?.widgets?.map((widget: any) => {
            if (widget.checked && widget.widgetType != 'heading') {
              count = count + 1;
            }
          });
        }
      });

      setLoading(false);
      setRefresh(!refresh);
    };

    fetchData();
  }, [plantId]);
  //@ts-ignore
  const getTimeFrameFromDays = (timeInDays: number) => {
    let endD: Date;
    let startD: Date;

    if (timeInDays > 0) {
      endD = new Date();
      startD = new Date(endD.valueOf() - timeInDays * 86400000);
    } else if (timeInDays == 0) {
      endD = new Date();
      startD = new Date();
      startD.setHours(0, 0, 0, 0);
    } else {
      let midnight = new Date();
      midnight.setHours(0, 0, 0, 0);
      endD = midnight;
      startD = new Date(midnight.valueOf() - timeInDays * -1 * 86400000);
    }

    return {
      startDate: startD,
      endDate: endD,
    };
  };

  const getTimeFrameFromUrlParameters = () => {
    let endD: Date;
    let startD: Date;

    if (endDateUrl == 'null' || startDateUrl == 'null' || granularity == 'null') return null;

    endD = new Date(decodeURIComponent(endDateUrl!));
    startD = new Date(decodeURIComponent(startDateUrl!));

    if (!isValidDate(endD) || !isValidDate(startD)) return null;

    return {
      startDate: startD,
      endDate: endD,
      granularity,
    };
  };

  const isValidDate = (d: any) => {
    return !isNaN(d.valueOf());
  };

  const getIconForWidgetCluster = (widgetClusterName: string) => {
    if (widgetClusterName == 'Water Quantity and Availability') return waterQuantityIcon;
    else if (widgetClusterName == 'Inlet and Outlet Water Quality') return waterQualityIcon;
    else if (widgetClusterName == 'Biological Process Health') return biologicalProcessIcon;
    else if (widgetClusterName == 'Safety') return safetyIcon;
    else if (widgetClusterName == 'Energy Consumption') return energyIcon;
    else if (widgetClusterName == 'Equipment Performance and Maintenance')
      return equipmentMaintenanceIcon;
    else return waterQuantityIcon;
  };

  return (
    <>
      {!loading && pageData ? (
        <div id="pdfView" style={{ background: '#F5F6F9', margin: 0, padding: 0 }}>
          <Box className={classes.emailPageHeader}>
            <img
              src={digitalPaaniLogoV2}
              style={{ width: '110px', maxHeight: '50px', marginLeft: '20px' }}
            ></img>
          </Box>

          <Box className={classes.emailPageBody} p={2}>
            <p className={classes.dashboardPageHeading}>
              {plantId === 'null'
                ? `Dashboard (${pageData.pageConfig.pageName})`
                : `${pageData?.pageConfig?.plantName} Analytics`}
            </p>

            <Box className={classes.analyticsContainer}>
              {pageWidgetCluster && pageWidgetCluster.length > 0
                ? pageWidgetCluster.map((widgetCluster: any) => {
                    const { clusterName, customClusterName } = widgetCluster;
                    if (widgetCluster.showWidgetCluster)
                      return (
                        <>
                          <Box className={classes.widgetClusterheader}>
                            <img
                              src={getIconForWidgetCluster(clusterName)}
                              className={classes.widgetClusterImg}
                              alt="widgetClusterImg"
                            ></img>
                            <p className={classes.widgetClusterHeading}>{customClusterName}</p>
                          </Box>
                          <Box className={classes.widgetClusterBodyForEmailPage} m={1}>
                            {widgetCluster.widgets.map((widget: any) => {
                              if (!widget.checked) return null;
                              else if (
                                widget.widgetType == 'graph' ||
                                widget.widgetType == 'tableD'
                              )
                                return (
                                  <Box className={classes.graphAndTableWidgetWrapperForEmailPage}>
                                    <ErrorBoundary>
                                      <GeneralisedWidgetForPdfPage
                                        widgetStructure={widget}
                                        controller={controller}
                                        timeFrame={timeFrame}
                                        refresh={refreshWidgets}
                                        plantId={plantId!}
                                        widgetId={widget.widgetId}
                                        handleChildChange={handleChildChange}
                                        background={widget.background}
                                      />
                                    </ErrorBoundary>
                                  </Box>
                                );
                              else if (
                                widget.widgetType == 'number' ||
                                widget.widgetType == 'tableC'
                              )
                                return (
                                  <Box className={classes.numberWidgetWrapperForEmailPage}>
                                    <ErrorBoundary>
                                      <GeneralisedWidgetForPdfPage
                                        widgetStructure={widget}
                                        controller={controller}
                                        timeFrame={timeFrame}
                                        refresh={refreshWidgets}
                                        plantId={plantId!}
                                        widgetId={widget.widgetId}
                                        handleChildChange={handleChildChange}
                                        background={widget.background}
                                      />
                                    </ErrorBoundary>
                                  </Box>
                                );
                              else return null;
                            })}
                          </Box>
                        </>
                      );
                    else {
                      return null;
                    }
                  })
                : ''}
              {pageData?.pageConfig?.showEmbeddedLinks == true ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  {pageData?.pageConfig?.embeddedLinks?.map((embeddedLink: any) => {
                    return (
                      <Box m={1} p={1}>
                        <p className={classes.embeddedLinksHeading}>{embeddedLink.name}</p>
                        <iframe
                          title={embeddedLink.name}
                          width="100%"
                          height="500"
                          src={embeddedLink.link}
                          style={{ border: 'none' }}
                          allowFullScreen={true}
                        ></iframe>
                      </Box>
                    );
                  })}
                </Grid>
              ) : null}
            </Box>
            <Divider style={{ marginTop: '30px' }} />
            <Box className={classes.indexContainer}>
              <p style={{ fontSize: '25px', width: '100%', textAlign: 'center' }}>Index</p>
              <TableContainer style={{}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Widget Name</TableCell>
                      <TableCell align="right">Widget Description</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {indexDetails.length > 0
                      ? indexDetails.map((widget: any) => {
                          if (!widget.checked) return null;
                          return (
                            <TableRow
                              // key={}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell component="th" scope="row">
                                {widget.widgetNickName ? widget.widgetNickName : widget.widgetName}
                              </TableCell>
                              <TableCell align="right">{widget.widgetDescription}</TableCell>
                            </TableRow>
                          );
                        })
                      : ''}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </div>
      ) : null}
      {/* </LayoutComponent> */}
    </>
  );
}
