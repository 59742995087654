import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from '@mui/material';

interface EditFilterPopupProps {
  open: boolean;
  onClose: () => void;
  editMapFilter: Function;
  filterConfig: {
    name: string;
    description: string;
  };
}

const EditFilterPopup: React.FC<EditFilterPopupProps> = ({
  open,
  onClose,
  editMapFilter,
  filterConfig,
}) => {
  const [filterName, setFilterName] = useState<string>(filterConfig?.name || '');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [filterDescription, setFilterDescription] = useState<string>(
    filterConfig?.description || ''
  );
  const [errorMessageForDescription, setErrorMessageForDescription] = useState<string>('');

  useEffect(() => {
    console.log('filterConfig : ', filterConfig);
    // if (!open) {
    //   setFilterName('');
    //   setErrorMessage('');
    // }
    setFilterName(filterConfig?.name || '');
    setFilterDescription(filterConfig?.description || '');
  }, [filterConfig]);

  const handleEditClick = () => {
    if (filterName.trim() !== '') {
      if (filterDescription.length < 1) {
        setErrorMessageForDescription('Filter description can not be empty!');
      } else {
        editMapFilter('name', filterName);
        editMapFilter('description', filterDescription);
        setFilterName('');
        setErrorMessage('');
        onClose();
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Map Filter</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Filter Name"
          type="text"
          fullWidth
          value={filterName}
          disabled={filterName == 'Plant Score' || filterName == 'Connectivity' ? true : false}
          onChange={e => {
            setFilterName(e.target.value);
            setErrorMessage('');
          }}
        />
        {errorMessage && (
          <Typography color="error" variant="body2">
            {errorMessage}
          </Typography>
        )}
        <TextField
          autoFocus
          margin="dense"
          label="Filter Description"
          type="text"
          fullWidth
          value={filterDescription}
          onChange={e => {
            setFilterDescription(e.target.value);
            setErrorMessage('');
          }}
        />
        {errorMessageForDescription && (
          <Typography color="error" variant="body2">
            {errorMessageForDescription}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleEditClick} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFilterPopup;
