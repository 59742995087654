import axiosInstance from "../constants/axiosInstance";

interface ParameterInterface {
  solutionId: string;
  solutionName: string;
  utilizedAmount: number;
  remarkId: string;
  remarkName: string;
  endTime: string;
  startTime: string;
}
const utilizeSolution = async ({
  solutionId,
  solutionName,
  utilizedAmount,
  remarkId,
  remarkName,
  startTime,
  endTime,
}: ParameterInterface) => {
  try {
    const { data } = await axiosInstance().post(`/store/utilizeSolution`, {
      solutionId,
      solutionName,
      utilizedAmount,
      remarkId,
      remarkName,
      startTime,
      endTime,
    });
    console.log("data", data.data);
    return data.data;
  } catch (err) {
    console.error("ERROR | UtilizeSolution | ", err);
  }
};

interface IFinishSolutionProps {
  solutionId: string;
  solutionName: string;
  remarkId: string;
  remarkName: string;
  startTime: string;
  endTime: string;
}

const finishSolution = async ({
  solutionId,
  solutionName,
  remarkId,
  remarkName,
  startTime,
  endTime,
}: IFinishSolutionProps) => {
  try {
    const { data } = await axiosInstance().post(
      `/store/finishSolution`,
      {
        solutionId,
        solutionName,
        remarkId,
        remarkName,
        startTime,
        endTime,
      }
    );
    console.log("data", data.data);
    return data.data;
  } catch (err) {
    console.error("ERROR | finishSolution | ", err);
  }
};

export { utilizeSolution, finishSolution };
