import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const publicKey =
  "BJ6WgUli1Q9pzqvXVy3e9PaL_kdqlj5B99UOlebygiJyrxSebJZXTaL-A8chYNN0aJzZ5o3sGBTJ843H3SMbscM";

const firebaseConfig = {
  apiKey: "AIzaSyBT0rvun8fD0pszteAnOZNfnmpjbsk7vUc",
  authDomain: "ecoinno-8a5b8.firebaseapp.com",
  projectId: "ecoinno-8a5b8",
  storageBucket: "ecoinno-8a5b8.appspot.com",
  messagingSenderId: "233064054328",
  appId: "1:233064054328:web:391289b33510a38bc170cd",
  measurementId: "G-5BKKTSW0KC",
};

const fbapp = initializeApp(firebaseConfig);

const messaging = getMessaging(fbapp);

export const getTokenfnc = async (setTokenFound) => {
  let currentToken = "";
  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token.", error);
  }
  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
