import { useEffect, useState } from 'react';
import { Checkbox, IconButton, Stack, Chip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getCurrentConnectivityStatusForAllPlants } from '../../services/dataBreak/dataBreak';
import ON_ICON from '../../assets/icons/ONIcon.svg';
import OFF_ICON from '../../assets/icons/OFFIcon.svg';
import { useNavigate } from 'react-router-dom';
import DateTimeForTableCell from '../../core-components/theme/components/DateTime/DateTimeForTableCell';
import DataGrid from '../../core-components/theme/components/DataGrid/DataGrid';
import { Box } from '@mui/material';
import { updateDataBreakAlertStatusForPlant } from '../../services/plant';
import { toast } from 'react-toastify';

interface dataBreakTableProps {
  searchQuery: string;
}

const DataBreakGrid = (props: dataBreakTableProps) => {
  const [connectivityData, setConnectivityData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  const navigate = useNavigate();

  const { searchQuery } = props;

  const columns = [
    { field: 'plantName', headerName: 'Plant Name', width: 150 },
    {
      field: 'lastUpdated',
      headerName: 'Last Updated',
      width: 200,
      height: 400,
      rowHeight: 400,
      renderCell: (params: any) => {
        return (
          // <span style={{ display: 'flex', flexDirection: 'column' }}>
          <DateTimeForTableCell date={params.row.lastUpdated as string} dateStringFormat="" />
          //   <span>abcasd</span>
          //   <span>bcd</span>
          // </span>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params: any) => (
        <Stack direction="row" alignItems="center" gap={2}>
          {params.value === 'Online' ? <img src={ON_ICON} /> : <img src={OFF_ICON} />}
          <span>{params.value}</span>
        </Stack>
      ),
    },
    {
      field: 'reason',
      headerName: 'Reason',
      width: 500,
      renderCell: (params: any) => (
        <Stack direction="row" gap={2} alignItems="center">
          <Box>{params.row.reason}</Box>
          {params.row.dataRecoveryHeaders?.map((header: string) => (
            <Chip variant="outlined" label={header} />
          ))}
        </Stack>
      ),
    },
    {
      field: 'alert',
      headerName: 'Alerts',
      width: 100,
      renderCell: (params: any) => (
        <Checkbox
          defaultChecked={params.value}
          onChange={(event: any) => {
            updateDataBreakAlertStatusForPlant(params.row.plantId, event.target.checked).then(
              () => {
                toast(`Updated data break alert status for ${params.row.plantName}`);
              }
            );
          }}
          color="Primary"
        />
      ),
    },
    {
      field: 'details',
      headerName: 'Details',
      width: 100,
      renderCell: (params: any) => (
        <IconButton
          color="Primary"
          onClick={() => {
            navigate(`/databreakdetails/${params.row.plantId}`);
          }}
        >
          <OpenInNewIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    getCurrentConnectivityStatusForAllPlants().then((data: any) => {
      setConnectivityData(data);
      setFilteredData(data);
    });
  }, []);

  useEffect(() => {
    if (searchQuery.length > 0) {
      let tempData = filteredData.filter(data => {
        console.log(data.plantName);
        return data?.plantName?.toLowerCase().includes(searchQuery.toLowerCase());
      });
      setFilteredData(tempData);
    } else {
      setFilteredData(connectivityData);
    }
  }, [searchQuery]);

  return (
    <DataGrid rows={filteredData} columns={columns} loading={false} onRowsScrollEnd={() => {}} />
  );
};

export default DataBreakGrid;
