import axiosInstance from "../constants/axiosInstance";

const getManpowerData = async () => {
  try {
    const { data } = await axiosInstance().get("/users/getManPowerData");
    return data.plantInfo;
  } catch (error) {
    console.log("error | getManPowerData ", error);
  }
};

export { getManpowerData };
