import { create } from "zustand";
import { ITokenBody } from "../Interfaces/tokenInterface";

type LoginEntryStore = {
  selectedLoginEntry: ITokenBody | null;
  setSelectedLoginEntry: (loginEntry: ITokenBody | null) => void;
};

const useLoginEntryStore = create<LoginEntryStore>((set) => ({
  selectedLoginEntry: null,
  setSelectedLoginEntry: (loginEntry: ITokenBody | null) =>
    set({ selectedLoginEntry: loginEntry }),
}));

export default useLoginEntryStore;
