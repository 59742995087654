import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useUserStore from "../store/UserStore";
import { isJwtExpired } from "../utils/jwt.utils";

const Home = () => {
  const navigate = useNavigate();
  const landingPageRoute = useUserStore().landingPage;
  useEffect(() => {
    // const landingPageRoute = localStorage.getItem("landingPage");
    const token = localStorage.getItem("token");
    if (landingPageRoute && token && !isJwtExpired(token)) {
      navigate(`${landingPageRoute}`, { replace: true });
    } else {
      navigate("/Login", { replace: true });
    }
  }, []);

  return <></>;
};

export default Home;
