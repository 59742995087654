import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@mui/material';
import EditNoteIcon from '@mui/icons-material/EditNote';
import DeleteIcon from '@mui/icons-material/Delete';
import { sendDeleteRequest } from '../../services/alertTaskConfig';
import DeleteConfirmation from '../../globalComponents/dialogs/DeleteConfirmation';
import { DataGrid, GridColDef, GridRowParams } from '@mui/x-data-grid';
import TypographyWithHover from '../Common/TypographyWithHover';
import AlertIcon from '../../assets/icons/AlertIcon.svg';
import Switch from '@mui/material/Switch';
import { toggleAlertStatus, updateSingleAlert } from '../../services/alertTaskConfig';

import {
  AlertStyles,
  DialogPositiveButtonStyle,
  DialogNegativeButtonStyle,
  DeletedRowStyle,
} from './AlertsStyles';
import moment from 'moment';
import AlertConditionContainer from '../AlertComponents/AlertConditionContainer';
import MessageDialog from './MessageDialog';
import AlertForm from './AlertForm';
import useUserStore from '../../store/UserStore';
import chipColor from '../../utils/priorityColor';
// interface IProps {
//   setLoading: any;
//   sensorsData: any;
//   setUpdatedSensorData: Function;
//   processing: boolean;
//   setProcessing: Function;
//   showProcessingResult: boolean;
//   setShowProcessingResult: Function;
//   activeCount: number;
//   inActiveCount: number;
//   deletedCount: number;
// }

function AlertsDataGrid({
  alertsData,
  setAlertsForPlant,
  processing,
  setProcessing,
  showProcessingResult,
  setShowProcessingResult,
  plantSensorList,
  configVarList,
  boolSensorTagList,
  equipments,
  userList,
  assignRole,
  escaltionOptions,
  requestNextPage,
  showAddAlertModal,
  setRequestNextPage,
  setGotoNextPage,
  setShowAddAlertModal,
  refresh,
  alertType,
}: any) {
  const [alertDataList, setAlertDataList] = useState<any>([]);
  const [displayMessage, setDisplayMessage] = useState('');
  const [alteredAlertDataList, setAlteredAlertDataList] = useState<any>([]);
  const [selectedAlertId, setSelectedAlertId] = useState('');
  const [selectedAlert, setSelectedAlert] = useState<any>({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteSelectedAlertId, setDeleteSelectedAlertId] = useState('');
  const [selectedAlertIndex, setSelectedAlertIndex] = useState<Number>();
  const [selectedAlertHeading, setSelectedAlertHeading] = useState('');
  const [selectedAlertStatus, setSelectedAlertStatus] = useState(false);
  const [showToggleAlertModal, setShowToggleAlertModal] = useState(false);
  const [showLogicBuilder, setShowLogicBuilder] = useState(false);
  const [edited, setEdited] = useState(false);
  const [logicType, setLogicType] = useState('');
  const [messageType, setMessageType] = useState('');
  const [showMessageDialog, setShowMessageDialog] = useState(false);
  const [showAlertForm, setShowAlertForm] = useState(false);
  //@ts-ignore
  const [listOfChangedAlerts, setListOfChangedAlerts] = useState<any>([]);
  //@ts-ignore
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  useEffect(() => {}, [alteredAlertDataList, edited]);

  async function toggleAlert(alertId: string, status: boolean, history: any) {
    let newDataList = alteredAlertDataList;
    //update status in alteredAlertDataList
    newDataList.map((alert: any) => {
      if (alert.id === alertId) {
        alert.isActive = status;
      }
    });
    toggleAlertStatus(alertId, history, status, alertType).then(data => {
      if (data?.status === 'success') {
        setShowToggleAlertModal(false);
        setProcessing(false);
        setShowProcessingResult(false);
        setAlteredAlertDataList(newDataList);
        setAlertDataList(newDataList);
      } else {
        setShowToggleAlertModal(false);
        setProcessing(false);
        setShowProcessingResult(false);
      }
    });
  }

  useEffect(() => {}, [requestNextPage]);

  // function checkAndMoveToNextPage() {
  //   setGotoNextPage(true);
  // }

  // function checkIfMovePossible() {
  //   setRequestNextPage(false);

  //   let changedArray: any = [];
  //   for (let i = 0; i < alteredAlertDataList.length; i++) {
  //     let found = false;
  //     for (let j = 0; j < alertDataList.length; j++) {
  //       if (deepCompare(alteredAlertDataList[i], alertDataList[j])) {
  //         found = true;
  //         break;
  //       }
  //     }
  //     if (!found) {
  //       changedArray.push(alteredAlertDataList[i].heading.eng);
  //     }
  //   }

  //   for (let i = 0; i < alertDataList.length; i++) {
  //     let found = false;
  //     for (let j = 0; j < alteredAlertDataList.length; j++) {
  //       if (deepCompare(alertDataList[i], alteredAlertDataList[j])) {
  //         found = true;
  //         break;
  //       }
  //     }
  //     if (!found) {
  //       changedArray.push(alertDataList[i].heading.eng);
  //     }
  //   }

  //   if (changedArray.length > 0) {
  //     setListOfChangedAlerts(changedArray);
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // function closeWarningDialog() {
  //   setShowWarningDialog(false);
  // }

  async function saveSingleAlertFromAlertId(alertId: string) {
    let newDataList = alteredAlertDataList;
    let alertData = newDataList.filter((alert: any) => alert.id === alertId);
    let typeId = alertData[0]['plantId'];
    if (alertType === 'workspaceAlerts') {
      typeId = alertData[0]['workspaceId'];
    }
    updateSingleAlert(typeId, alertId, alertData[0], alertType).then(data => {
      if (data?.data?.status === 'success') {
        setDisplayMessage(data?.data?.message);
        setAlertDataList(newDataList);
        setMessageType('success');
        setShowMessageDialog(true);
        setProcessing(false);
        refresh();
        // setShowToggleAlertModal(false);
        // setProcessing(false);
        // setShowProcessingResult(true);
        // setAlteredAlertDataList(newDataList);
        // setAlertDataList(newDataList);
      } else {
        // setShowToggleAlertModal(false);
        setMessageType('error');
        setDisplayMessage(data?.data?.message);
        setAlteredAlertDataList(alertDataList);
        setShowMessageDialog(true);
        setProcessing(false);

        //restore alertDataList
      }
    });
  }

  async function saveAlert(alertData: any) {
    let typeId = alertData['plantId'];
    if (alertType === 'workspaceAlerts') {
      typeId = alertData['workspaceId'];
    }
    updateSingleAlert(typeId, alertData['id'], alertData, alertType).then(data => {
      if (data?.data?.status === 'success') {
        setDisplayMessage(data?.data?.message);
        setAlertDataList(alteredAlertDataList);
        setMessageType('success');
        setShowAlertForm(false);
        setShowMessageDialog(true);
        setProcessing(false);
        refresh();
        // setShowToggleAlertModal(false);
        // setProcessing(false);
        // setShowProcessingResult(true);
        // setAlteredAlertDataList(newDataList);
        // setAlertDataList(newDataList);
      } else {
        // setShowToggleAlertModal(false);

        setMessageType('error');
        setDisplayMessage(data?.data?.message);
        setAlteredAlertDataList(alertDataList);
        setShowMessageDialog(true);
        setProcessing(false);
        setShowAlertForm(false);
        //restore alertDataList
      }
    });
  }

  const handleDeleteTask = async (alertId: string) => {
    try {
      const result = await sendDeleteRequest(alertId);

      // Ensure result is properly logged and checked
      if (result && result.message) {
        setMessageType('success');
        setDisplayMessage(result.message);
      } else {
        setMessageType('success');
        setDisplayMessage('Task deletion succeeded but no message received.');
      }

      setShowMessageDialog(true);
    } catch (error) {
      setMessageType('error');
      setDisplayMessage('An error occurred while deleting the task.');
      setShowMessageDialog(true);
    }
  };

  function closeMessageDialog() {
    setShowMessageDialog(false);
    setShowLogicBuilder(false);
  }
  function closeForm() {
    setShowAlertForm(false);
  }

  useEffect(() => {
    setAlertDataList(JSON.parse(JSON.stringify(alertsData)));
    setAlteredAlertDataList(JSON.parse(JSON.stringify(alertsData)));
  }, [alertsData]);

  const updateCondition = (
    alertIndex: number,
    conditionSetIndex: number,
    statementIndex: number,
    updateKey: string,
    updateValue: any,
    conditionType: string
  ) => {
    let updatedAlerts = alteredAlertDataList;
    updatedAlerts[alertIndex][conditionType][conditionSetIndex][statementIndex][updateKey] =
      updateValue;
    if (updateKey === 'thresholdType') {
      updatedAlerts[alertIndex][conditionType][conditionSetIndex][statementIndex]['threshold'] = 0;
    }
    setAlteredAlertDataList(updatedAlerts);
    // setAlertDataList(updatedAlerts);
    // setAlertsForPlant(updatedAlerts);
    setEdited(!edited);
    setProcessing(false);
  };

  const deleteAlertStatement = (
    alertIndex: number,
    conditionSetIndex: number,
    statementIndex: number,
    conditionType: string
  ) => {
    let updatedAlerts = alteredAlertDataList;
    updatedAlerts[alertIndex][conditionType][conditionSetIndex].splice(statementIndex, 1);

    setAlteredAlertDataList(updatedAlerts);
    // setAlertDataList(updatedAlerts);
    // setAlertsForPlant(updatedAlerts);
    setEdited(!edited);
  };
  function deleteConditionSet(
    alertIndex: number,
    conditionSetIndex: number,
    conditionType: string
  ) {
    let updatedAlerts = alteredAlertDataList;
    updatedAlerts[alertIndex][conditionType].splice(conditionSetIndex, 1);

    setAlteredAlertDataList(updatedAlerts);
    // setAlertDataList(updatedAlerts);
    // setAlertsForPlant(updatedAlerts);
    setEdited(!edited);
  }
  const addAlertStatement = (
    alertIndex: number,
    conditionSetIndex: number,
    conditionType: string
  ) => {
    let updatedAlerts = alteredAlertDataList;
    updatedAlerts[alertIndex][conditionType][conditionSetIndex].push({
      operator: '',
      sensorTag: '',
      threshold: null,
      thresholdType: 'numeric',
    });
    setAlteredAlertDataList(updatedAlerts);
    // setAlertDataList(updatedAlerts);
    // setAlertsForPlant(updatedAlerts);
    setEdited(!edited);
  };
  const addConditionSet = (alertIndex: number, conditionType: string) => {
    let updatedAlerts = alteredAlertDataList;
    updatedAlerts[alertIndex][conditionType].push([]);
    setAlteredAlertDataList(updatedAlerts);
    // setAlertDataList(updatedAlerts);
    // setAlertsForPlant(updatedAlerts);
    setEdited(!edited);
  };

  const columns: GridColDef[] = [
    {
      field: 'Heading',
      headerName: 'Alert Title',
      width: 350,
      renderCell: (params: any) => {
        // return <Typography>{params?.row?.heading?.eng}</Typography>;
        return <TypographyWithHover value={params?.row?.heading?.eng} />;
      },
    },
    {
      field: 'priority',
      headerName: 'Priority',
      width: 120,
      renderCell: (params: any) => {
        const isPriority = params?.row?.priority;
        const { color, backgroundColor } = chipColor(isPriority);
        return <Chip label={isPriority} sx={{ color, backgroundColor }} />;
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 130,
      renderCell: (params: any) => {
        const isActive = params?.row?.isActive;
        const isDeleted = params?.row?.isDeleted;
        let status = 'In-Active';
        if (isActive) {
          status = 'Active';
        }
        if (isDeleted) {
          status = 'Deleted';
        }
        return (
          <div>
            <span
              style={
                isDeleted === true
                  ? AlertStyles.DeletedAlert
                  : isActive === true
                    ? AlertStyles.ActiveAlert
                    : AlertStyles.InActiveAlert
              }
            >
              {status}
            </span>
          </div>
        );
      },
    },
    {
      field: 'updateSensorStatus',
      headerName: 'Update Status',
      width: 180,
      renderCell: (params: any) => {
        return (
          <Switch
            checked={params?.row?.isActive || null}
            onChange={() => {
              setSelectedAlertId(params.id);
              setSelectedAlertHeading(params?.row?.heading?.eng);
              setSelectedAlertStatus(params?.row?.isActive);
              setShowToggleAlertModal(true);
            }}
            sx={{}}
          />
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 130,
      renderCell: (params: any) => {
        const isEmergency = params?.row?.emergency;
        let type = 'Normal';
        if (isEmergency) {
          type = 'Emergency';
        }

        return (
          <div>
            <span style={isEmergency === true ? AlertStyles.DeletedAlert : AlertStyles.ActiveAlert}>
              {type}
            </span>
          </div>
        );
      },
    },
    {
      field: 'Primary User',
      headerName: 'Primary User',
      width: 200,
      renderCell: (params: any) => {
        // return <Typography>{params?.row?.heading?.eng}</Typography>;
        let name = '...';
        if (params?.row?.primaryUser?.name) {
          name = params?.row?.primaryUser?.name;
        }
        return <TypographyWithHover value={name} />;
      },
    },
    {
      field: 'CC Users',
      headerName: 'CC Users',
      width: 350,
      renderCell: (params: any) => {
        // return <Typography>{params?.row?.heading?.eng}</Typography>;
        let ccUserNames = '';
        if (params?.row?.ccUsers?.length > 0) {
          params?.row?.ccUsers?.map((user: any, index: number) => {
            if (index === params?.row?.ccUsers?.length - 1) {
              ccUserNames += user?.name;
            } else {
              ccUserNames += user?.name + ', ';
            }
          });
          if (ccUserNames === '') {
            ccUserNames = '...';
          }
        }
        return <TypographyWithHover value={ccUserNames} />;
      },
    },
    {
      field: 'Due time from posting',
      headerName: 'Due time from posting',
      width: 200,
      renderCell: (params: any) => {
        let dueTime = '...';
        if (params?.row?.dueTimeFromPosting) {
          dueTime = params?.row?.dueTimeFromPosting;
        }
        return <TypographyWithHover value={dueTime} />;
      },
    },
    {
      field: 'Observation Frequency',
      headerName: 'Observation Frequency',
      width: 200,
      renderCell: (params: any) => {
        let observationFrequency = '...';
        if (params?.row?.observationFreq) {
          observationFrequency = params?.row?.observationFreq;
        }
        return <TypographyWithHover value={observationFrequency} />;
      },
    },
    {
      field: 'Observation Logic',
      headerName: 'Observation Logic',
      width: 180,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              setSelectedAlertId(params.id);
              setLogicType('observationConditions');
              setShowLogicBuilder(true);
              setSelectedAlert(params?.row);
              const index = alteredAlertDataList.findIndex((obj: any) => obj.id === params.id);

              setSelectedAlertIndex(index);
            }}
            sx={{}}
          >
            View
          </Button>
        );
      },
    },

    {
      field: 'Resolution Frequency',
      headerName: 'Resolution Frequency',
      width: 200,
      renderCell: (params: any) => {
        let resolutionFrequency = '...';
        if (params?.row?.resolutionFreq) {
          resolutionFrequency = params?.row?.resolutionFreq;
        }
        return <TypographyWithHover value={resolutionFrequency} />;
      },
    },
    {
      field: 'Resolution Logic',
      headerName: 'Resolution Logic',
      width: 180,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              setSelectedAlertId(params.id);
              setLogicType('resolutionConditions');
              setShowLogicBuilder(true);
              setSelectedAlert(params?.row);
              const index = alteredAlertDataList.findIndex((obj: any) => obj.id === params.id);

              setSelectedAlertIndex(index);
            }}
            sx={{}}
          >
            View
          </Button>
        );
      },
    },
    {
      field: 'View / Edit',
      headerName: 'View / Edit',
      width: 180,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              setSelectedAlertId(params.id);
              setSelectedAlert(params?.row);
              const index = alteredAlertDataList.findIndex((obj: any) => obj.id === params.id);

              setSelectedAlertIndex(index);
              setShowAlertForm(true);
            }}
            sx={{}}
          >
            <EditNoteIcon />
          </Button>
        );
      },
    },
    {
      field: 'Delete',
      headerName: 'Delete Task',
      width: 180,
      renderCell: (params: any) => {
        return (
          <Button
            onClick={() => {
              setShowDeleteModal(true);
              setDeleteSelectedAlertId(params.id);
            }}
            sx={{
              '& .MuiSvgIcon-root': {
                transition: 'color 0.3s ease',
              },
              '&:hover .MuiSvgIcon-root': {
                color: 'red',
              },
            }}
          >
            <DeleteIcon />
          </Button>
        );
      },
    },
  ];

  // function handleRequestNewPage() {
  //   setShowWarningDialog(true);
  // }

  return (
    <>
      <DeleteConfirmation
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        alertMessage={'You are deleting the Alert Task'}
        processing={processing}
        setProcessing={setProcessing}
        onConfirmAction={() => {
          handleDeleteTask(deleteSelectedAlertId);
          setProcessing(false);
          refresh();
        }}
      />

      <Box m={1} p={1}>
        <div style={{ height: 420 }}>
          <DataGrid
            rows={alteredAlertDataList}
            columns={columns}
            getRowClassName={(params: GridRowParams) => {
              const { isDeleted = false } = params.row;
              if (isDeleted) {
                return `${DeletedRowStyle}`;
              }
              return '';
            }}
          />
        </div>
      </Box>
      <Dialog
        open={showToggleAlertModal}
        onClose={() => {
          setShowToggleAlertModal(false);
          // setShowProcessingResult(false);
        }}
      >
        {processing && (
          <DialogContent>
            <Box sx={AlertStyles.ProcessingStyle}>
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
        {!processing && !showProcessingResult && (
          <>
            <DialogContent>
              <Box sx={AlertStyles.DialogStyles}>
                <img src={AlertIcon} alt="Alert Icon" style={AlertStyles.IconStyles} />
                <Typography variant="h5" sx={AlertStyles.DialogTitleStyle}>
                  Please Confirm !
                </Typography>
                <Typography>
                  You are about to
                  {selectedAlertStatus ? (
                    <span style={AlertStyles.InActiveColor}> In-Activate </span>
                  ) : (
                    <span style={AlertStyles.ActiveColor}> Activate </span>
                  )}
                  the Alert titled :- " {selectedAlertHeading} "
                </Typography>
              </Box>
            </DialogContent>
            <DialogActions>
              <DialogNegativeButtonStyle
                onClick={() => {
                  setShowToggleAlertModal(false);
                }}
              >
                Cancel
              </DialogNegativeButtonStyle>
              <DialogPositiveButtonStyle
                onClick={async () => {
                  setProcessing(true);
                  const actionDetails = {
                    // updatedBy: localStorage.getItem("userid"),
                    updatedBy: useUserStore().userId,
                    updatedAt: moment().utc().add(5.5, 'hours').toDate(),
                    type: 'Status update',
                    previousStatus: selectedAlertStatus,
                  };
                  await toggleAlert(selectedAlertId, !selectedAlertStatus, actionDetails);
                }}
              >
                Confirm
              </DialogPositiveButtonStyle>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        open={showLogicBuilder}
        onClose={() => {
          setShowLogicBuilder(false);
        }}
        fullWidth
        maxWidth="md"
      >
        {processing && (
          <DialogContent>
            <Box sx={AlertStyles.ProcessingStyle}>
              <CircularProgress />
            </Box>
          </DialogContent>
        )}
        {!processing && !showProcessingResult && (
          <>
            <DialogContent>
              <AlertConditionContainer
                conditionSet={selectedAlert[logicType]}
                plantSensorList={plantSensorList}
                configVarList={configVarList}
                alertIndex={selectedAlertIndex}
                updateCondition={updateCondition}
                deleteAlertStatement={deleteAlertStatement}
                deleteConditionSet={deleteConditionSet}
                addAlertStatement={addAlertStatement}
                addConditionSet={addConditionSet}
                boolSensorTagList={boolSensorTagList}
                conditionType={logicType}
              />
            </DialogContent>
            <DialogActions>
              <DialogPositiveButtonStyle
                onClick={() => {
                  setShowLogicBuilder(false);
                }}
              >
                Cancel
              </DialogPositiveButtonStyle>
              <DialogPositiveButtonStyle
                onClick={async () => {
                  setProcessing(true);
                  await saveSingleAlertFromAlertId(selectedAlertId);
                }}
              >
                Save
              </DialogPositiveButtonStyle>
            </DialogActions>
          </>
        )}
      </Dialog>
      <AlertForm
        showAlertForm={showAlertForm}
        closeMethod={closeForm}
        alertData={selectedAlert}
        alertIndex={selectedAlertIndex}
        equipments={equipments}
        userList={userList}
        assignRole={assignRole}
        escaltionOptions={escaltionOptions}
        sensorList={plantSensorList}
        configVarList={configVarList}
        boolSensorTagList={boolSensorTagList}
        saveAlert={saveAlert}
      />
      <MessageDialog
        message={displayMessage}
        type={messageType}
        closeMethod={closeMessageDialog}
        showMessageDialog={showMessageDialog}
      />

      {/* {requestNextPage && checkIfMovePossible() ? (
        <WarningDialog
          message={
            "You have some unsaved changes in the following alerts. Do you wish to continue ?"
          }
          closeMethod={closeWarningDialog}
          showWarningDialog={requestNextPage}
          warningsList={listOfChangedAlerts}
          negativeText={"continue"}
          positiveText={"cancel"}
          negativeMethod={checkAndMoveToNextPage}
        />
      ) : (
        <></>
      )} */}

      {/* {requestNextPage ? handleRequestNewPage() : <></>} */}
    </>
  );
}

export default AlertsDataGrid;
