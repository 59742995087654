import { FC, useEffect } from 'react';
import { Button, Grid } from '@mui/material';

interface InitialSetupForPlantImagePopup {
  setSetupType: any;
}

const InitialSetupForPlantImagePopup: FC<InitialSetupForPlantImagePopup> = ({ setSetupType }) => {
  useEffect(() => {}, []);

  return (
    <div style={{ display: 'flex', margin: 'auto', alignContent: 'center', width: '500px' }}>
      <Grid style={{}} container spacing={2}>
        {/* Column 1 */}
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            borderRight: '1px solid #E0E0E0',
            // paddingRight: "16px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  color: '#183650',
                  borderColor: '#183650',
                  margin: '30px 20px 30px 0px',
                }}
                onClick={() => {
                  setSetupType('Upload-Image');
                }}
              >
                Upload Picture
              </Button>
            </Grid>
          </Grid>
        </Grid>

        {/* Column 2 */}
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            borderRight: 'none',
            // paddingLeft: "16px",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <Button
                variant="outlined"
                style={{
                  color: '#183650',
                  borderColor: '#183650',
                  margin: '30px 20px 30px 0px',
                }}
                onClick={() => {
                  setSetupType('Choose-Image');
                }}
              >
                Select picture
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default InitialSetupForPlantImagePopup;
