//@ts-nocheck piyush when in list pr and remove customtree from here.
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import { animated, useSpring } from '@react-spring/web';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import { TreeItem2 } from '@mui/x-tree-view/TreeItem2';
import CustomTreeHeader from './Header/Header';
import { Primary } from '../../color-palette/colors';
import { TreeViewBaseItem } from '@mui/x-tree-view/models';

interface CustomTreeViewBaseItem extends TreeViewBaseItem {
  itemId: string;
}
function getParentNode(items: any[], id: string): CustomTreeViewBaseItem | undefined {
  for (const item of items) {
    if (item.children) {
      if (item.children.some((child: { itemId: string }) => child.itemId === id)) {
        // The current item is the parent of the supplied id
        return item;
      } else {
        // Recursively call the function for the children of the current item
        const parentNode = getParentNode(item.children, id);
        if (parentNode) {
          return parentNode;
        }
      }
    }
  }

  // No parent found
  return undefined;
}

function getAllParentIds(items: CustomTreeViewBaseItem[], id: string): string[] {
  const parentIds: string[] = [];
  let parent = getParentNode(items, id);
  while (parent) {
    parentIds.push(parent.itemId);
    parent = getParentNode(items, parent.itemId);
  }
  return parentIds;
}

function getSelectedIdsAndChildrenIds(items: any[], selectedIds: string[]) {
  const selectedIdIncludingChildrenIds = new Set([...selectedIds]);

  for (const item of items) {
    if (selectedIds.includes(item.itemId)) {
      // Add the current item's id to the result array
      selectedIdIncludingChildrenIds.add(item.itemId);

      // Recursively call the function for the children of the current item
      if (item.children) {
        const childrenIds = item.children.map((child: { itemId: any }) => child.itemId);
        const childrenSelectedIds = getSelectedIdsAndChildrenIds(item.children, childrenIds);
        childrenSelectedIds.forEach(selectedId => selectedIdIncludingChildrenIds.add(selectedId));
      }
    } else if (item.children) {
      // walk the children to see if selections lay in there also
      const childrenSelectedIds = getSelectedIdsAndChildrenIds(item.children, selectedIds);
      childrenSelectedIds.forEach(selectedId => selectedIdIncludingChildrenIds.add(selectedId));
    }
  }

  return [...Array.from(selectedIdIncludingChildrenIds)];
}

function determineIdsToSet(items: any[], newIds: string[], currentIds: string[]) {
  const isDeselectingNode = currentIds.length > newIds.length;
  if (isDeselectingNode) {
    const removed = currentIds.filter(id => !newIds.includes(id))[0];

    const parentIdsToRemove = getAllParentIds(items, removed);

    const childIdsToRemove = getSelectedIdsAndChildrenIds(items, [removed]);

    const newIdsWithParentsAndChildrenRemoved = newIds.filter(
      id => !parentIdsToRemove.includes(id) && !childIdsToRemove.includes(id)
    );

    return newIdsWithParentsAndChildrenRemoved;
  }

  const added = newIds.filter(id => !currentIds.includes(id))[0];
  const idsToSet = getSelectedIdsAndChildrenIds(items, newIds);
  let parent = getParentNode(items, added);
  while (parent) {
    const childIds = parent.children?.map(node => node.itemId) ?? [];
    const allChildrenSelected = childIds.every(id => idsToSet.includes(id));
    if (allChildrenSelected) {
      idsToSet.push(parent.itemId);
      parent = getParentNode(items, parent.itemId);
    } else {
      break;
    }
  }
  return idsToSet;
}

interface CustomTreeComponentProps {
  items: any;
  filter?: string;
  show?: boolean;
  text?: string;
  multiSelect?: boolean;
  checkboxSelection?: boolean;
  heading?: string;
  CustomTreeItem?: React.ElementType;
  handleTreeSelected?: (val: any) => void;
  getItemlabel?: (item: any) => string;
  getItemId?: (item: any) => string;
  selectedIds?: string[];
  isFeatureList?: boolean;
  isWorkspaceList?: boolean;
}

function getItemDescendantsIds(item: any) {
  const ids: string[] = [];
  item.children?.forEach((child: { itemId: string }) => {
    ids.push(child.itemId);
    ids.push(...getItemDescendantsIds(child));
  });
  return ids;
}

const CustomTreeComponent: React.FC<CustomTreeComponentProps> = ({
  items,
  heading,
  show = false,
  text = '',
  filter = '',
  multiSelect = false,
  checkboxSelection = false,
  CustomTreeItem,
  handleTreeSelected,
  selectedIds,
  getItemId,
  getItemlabel,
}) => {
  const [selected, setSelectedItems] = useState<string[]>(selectedIds);
  const handleSelectedItemsChange = (event: React.SyntheticEvent, newSelectedItems: string[]) => {
    const newSelectedItemsByWorkspace = {};
    setSelectedItems(newSelectedItems);
    // Select / unselect the children of the toggled item
    const itemsToSelect: string[] = [];
    const itemsToUnSelect: { [id: string]: boolean } = {};
    Object.entries(toggledItemRef.current).forEach(([itemId, isSelected]) => {
      const item = apiRef.current!.getItem(itemId);
      if (isSelected) {
        itemsToSelect.push(...getItemDescendantsIds(item));
      } else {
        getItemDescendantsIds(item).forEach(descendantId => {
          itemsToUnSelect[descendantId] = true;
        });
      }
    });
    const newSelectedItemsWithChildren = Array.from(
      new Set([...newSelectedItems, ...itemsToSelect].filter(id => !itemsToUnSelect[id]))
    );
    setSelectedItemsByWorkspace(newSelectedItemsByWorkspace);
    handleTreeSelected(newSelectedItemsWithChildren);
    setSelectedItems(newSelectedItemsWithChildren);
    toggledItemRef.current = {};
  };
  const handleItemSelectionToggle = (
    event: React.SyntheticEvent,
    id: string,
    isSelected: boolean
  ) => {
    toggledItemRef.current[id] = isSelected;
  };
  return (
    <>
      <Box
        sx={{
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '50vh',
          border: `1px solid ${Primary[100]}`,
          borderRadius: '8px',
        }}
      >
        {show ? <CustomTreeHeader heading={heading} filter={filter} text={text} /> : null}
        <RichTreeView
          items={items}
          aria-label="file explorer"
          multiSelect={multiSelect}
          checkboxSelection={checkboxSelection}
          selectedItems={selectedIds}
          sx={{
            height: 'fit-content',
            flexGrow: 1,
            maxWidth: '100vw',
            overflowY: 'auto',
          }}
          slots={{ item: props => <CustomTreeItem {...props} treeId={uuidv4()} /> || TreeItem2 }}
          onSelectedItemsChange={handleSelectedItemsChange}
          getItemLabel={getItemlabel}
          getItemId={getItemId}
        />
      </Box>
    </>
  );
};

export default CustomTreeComponent;
