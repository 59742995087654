import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';

function UniversalSearchBar({
  open,
  onClose,
  searchQuery,
  setSearchQuery,
  onSearchPressed,
  placeHolders,
}: any) {
  const [currentPlaceholder, setCurrentPlaceHolder] = useState('Search Here');

  const handleKeyPress = (event: any) => {
    console.log('searchQuery : ', searchQuery, event.key);
    if (event.key === 'Enter' && searchQuery != '') {
      onSearchPressed();
      onClose();
    }
  };

  useEffect(() => {
    console.log('placeHolders : ', placeHolders);
    if (placeHolders?.length > 0)
      setCurrentPlaceHolder(placeHolders[Math.floor(Math.random() * placeHolders.length)]);
  }, [open, placeHolders]);

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: '100%', borderRadius: '25px' } }}
    >
      <DialogContent sx={{ padding: 0 }}>
        <TextField
          fullWidth
          autoFocus
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder={currentPlaceholder}
          onKeyDown={handleKeyPress}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon
                  onClick={() => {
                    if (searchQuery != '') onSearchPressed();
                  }}
                />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              // targeting the OutlinedInput within TextField
              borderRadius: '25px', // Apply border radius here
            },
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove the border
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove the border on hover
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: 'none', // Remove the border on focus
            },
            '& .MuiInputBase-root': {
              // targets the root of InputBase which is inside TextField
              backgroundColor: '#e0e0e0', // change the background color
              // For filled variant, use '& .MuiFilledInput-root' instead
            },
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

export default UniversalSearchBar;
