import { PaletteRange, PaletteRangeSemantic } from './colors.interface';

export const SemanticError = {
    50: '#FFCFCC',
    100: '#FF6961',
    500: '#F00C00',
    600: '#C50A00',
    900: '#820700',
} satisfies PaletteRangeSemantic;

export const SemanticSuccess = {
    50: '#CAF2CA',
    100: '#77DD77',
    500: '#30C030',
    600: '#269726',
    900: '#1C6E1C',    
} satisfies PaletteRangeSemantic;

export const SemanticWarning = {
    50: '#FFF0C8',
    100: '#FFE294',
    500: '#FFCC3D',
    600: '#FFB400',
    900: '#FFA200',
} satisfies PaletteRangeSemantic;

export const SemanticInfo = {
    50: '#CAE6FC',
    100: '#3DA3F5',
    500: '#2196F3',
    600: '#3271A9',
    900: '#05375F',
} satisfies PaletteRangeSemantic;

export const Neutrals = {
    50: '#F4F5F5',
    100: '#E1E3E5',
    200: '#C8CDD0',
    300: '#ACB3B9',
    400: '#8F98A0',
    500: '#727D86', // Default
    600: '#5D666E',
    700: '#484F54',
    800: '#373C41',
    900: '#2B2F33'
} satisfies PaletteRange;

export const Primary = {
    50: '#F2F7FB',
    100: '#DCE9F5',
    200: '#BBD5EB',
    300: '#88B5DC',
    400: '#5695CC',
    500: '#2F6A9E',
    600: '#275883',
    700: '#204769',
    800: '#1A3A56',
    900: '#183650', // Main
} satisfies PaletteRange;

export const Secondary = {
    50: "#F3FAFA",
    100: '#DEF3F3',
    200: '#BFE7E7',
    300: '#94D6D7',
    400: '#5EC2C4',
    500: '#379496',
    600: '#2E7B7C',
    700: '#256364',
    800: '#1D4D4D',
    900: "#173D3D",
} satisfies PaletteRange;

export const GreenHighlight = {
    50:  '#F9FEEF',
    100: '#EFFCD5',
    200: '#E0F9AD',
    300: '#C4F462',
    400: '#B1F131',
    500: '#84C00D',
    600: '#6E9F0B',
    700: '#588009',
    800: '#446307',
    900: "#364F05",
} satisfies PaletteRange;

export const Premium = {
    50:  '#F2EDFC',
    100: '#E8E0FA',
    200: '#DDD2F9',
    300: '#C9B8F5',
    400: '#BCA6F2',
    500: '#AB8FEF',
    600: '#9A79EC',
    700: '#8A65E9',
    800: '#6636E2',
    900: "#451AB2",
} satisfies PaletteRange;

export const Sludge = {
    50:  '#F8EDE2',
    100: '#F3E0CE',
    200: '#EACCAE',
    300: '#E5BF99',
    400: '#DBA670',
    500: '#D6995C',
    600: '#CC7F33',
    700: '#B8742E',
    800: '#8F5A24',
    900: "#66401A",
} satisfies PaletteRange;

export const ButterCup = {
    50:  '#FEF9EE',
    100: '#FDEFD2',
    200: '#FCDEA3',
    300: '#FBD487',
    400: '#F8BD47',
    500: '#F7AD19',
    600: '#D99408',
    700: '#BE8107',
    800: '#A36F06',
    900: '#7A5304',
} satisfies PaletteRange;

export const Christine = {
    50:  '#FEF6EE',
    100: '#FDE8D2',
    200: '#FBCFA4',
    300: '#F9C188',
    400: '#F6A049',
    500: '#F27F0C',
    600: '#D7710A',
    700: '#BC6309',
    800: '#A15508',
    900: "#793F06",
} satisfies PaletteRange;

export const Greeny = {
    50:  '#E5F6F1',
    100: '#C0E8DC',
    200: '#9BDAC7',
    300: '#74CBB1',
    400: '#56C0A0',
    500: '#3DA385',
    600: '#348D72',
    700: '#2E7C65',
    800: '#235D4C',
    900: '#1A4639',
} satisfies PaletteRange;

export const Hibiscus = {
    50:  '#FFDCEE',
    100: '#F9AFD4',
    200: '#EF86B9',
    300: '#E25D9D',
    400: '#D54186',
    500: '#BC286D',
    600: '#A31E5C',
    700: '#901A50',
    800: '#6D133C',
    900: "#520E2D",
} satisfies PaletteRange;

export const PremiumAddUp = {
    50:  '#FBF0FD',
    100: '#F4D7FA',
    200: '#EAB1F5',
    300: '#D183E1',
    400: '#B35EC4',
    500: '#8B309D',
    600: '#762387',
    700: '#621871',
    800: '#4E0F5B',
    900: '#40094B',
} satisfies PaletteRange;

export const SmithApple = {
    50:  '#F2FBEE',
    100: '#D8F4CD',
    200: '#CFF1C1',
    300: '#C0ECAC',
    400: '#B3E99B',
    500: '#A6E58A',
    600: '#6DBD49',
    700: '#449220',
    800: '#316917',
    900: "#275412",
} satisfies PaletteRange;