//@ts-nocheck
import { useState, useEffect } from 'react';
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath } from 'reactflow';
import { Paper, Box, Stack } from '@mui/material';
import Select from 'react-select';
import EditIcon from '@mui/icons-material/Edit';

import { getSensorListBasedOnFilters } from '../../../services/sensors';
import { useLayoutStore } from '../../../store/LayoutStore2';
import ColorPicker from '../../../globalComponents/colorPicker/ColorPicker';
import makeAnimated from 'react-select/animated';
import Draggable from 'react-draggable';

const edgeDetailStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: '#CEE3F5',
  border: '1px solid #fff',
  cursor: 'pointer',
  borderRadius: '103px',
  fontSize: '10px',
  fontWeight: 600,
  padding: '13px 36px 13px 36px',
};

// const apiEdgeTypesList = [
//   { _id: 1, label: "Clear Water" },
//   { _id: 2, label: "Dirty Water" },
//   { _id: 3, label: "Sludge" },
//   // ... other edge types
// ];

const DraggablePaper = (props: any) => (
  <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const customStyles = {
  menu: (provided: any) => ({
    ...provided,
    maxHeight: '200px', // Set the max height of the dropdown menu
    overflowY: 'scroll', // Add scroll if the content exceeds the max height
  }),
};

export default function DisplayEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  target,
  sourcePosition,
  targetPosition,
  markerEnd,
  data,
}: any) {
  //   const [edgePath, labelX, labelY] = getStraightPath({
  //     sourceX,
  //     sourceY,
  //     targetX,
  //     targetY,
  //   });

  // const [edgePath, labelX, labelY] = getBezierPath({
  //   sourceX,
  //   sourceY,
  //   sourcePosition,
  //   targetX,
  //   targetY,
  //   targetPosition,
  // });

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  //   const { edges, setEdges: setStoredEdges } = useLayoutStore();
  const [open, setOpen] = useState(false);
  //   const [selectedEdgeType, setSelectedEdgeType] = useState<any>("");
  const [sensorList, setSensorList] = useState<any[]>([]);
  const [edgeColor, setEdgeColor] = useState<string>(data?.properties?.color || '#000000'); // Default color
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [width, setWidth] = useState<number | undefined>(data?.properties?.width || 2);

  const animatedComponents = makeAnimated();

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  //   const handleColorChange = (newColor: string) => {
  //     setEdgeColor(newColor);
  //     const edgeIndex = edges.findIndex(e => e.id === id);
  //     if (edgeIndex !== -1) {
  //       const updatedEdges = [...edges];
  //       const edge = { ...updatedEdges[edgeIndex] };

  //       edge.data = {
  //         ...edge.data,
  //         properties: { ...edge.data.properties, color: newColor },
  //       };
  //       updatedEdges[edgeIndex] = edge;
  //       setStoredEdges(updatedEdges);
  //     }
  //   };

  //   const handleWidthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const newWidth = event.target.valueAsNumber; // Use valueAsNumber to ensure it's a number
  //     setWidth(newWidth);

  //     const edgeIndex = edges.findIndex(e => e.id === id);
  //     if (edgeIndex !== -1) {
  //       const updatedEdges = [...edges];
  //       const edge = { ...updatedEdges[edgeIndex] };

  //       edge.data = {
  //         ...edge.data,
  //         properties: { ...edge.data.properties, width: newWidth },
  //       };
  //       updatedEdges[edgeIndex] = edge;
  //       setStoredEdges(updatedEdges);
  //     }
  //   };

  function calculateLabelPosition() {
    let offsetX = 0;
    let offsetY = 0;

    // Check the direction of the edge
    const isLeftToRight = sourceX < targetX;

    // Split the edgePath into an array by spaces and commas
    const pathData = edgePath.replace(/M|C/g, '').split(/[ ,]/).filter(Boolean);

    // Check if pathData has the expected number of elements
    if (pathData.length >= 6) {
      // Adjust offset based on direction
      offsetX = isLeftToRight ? 0 : -40; // Adjust 20px to the right or left
      // offsetY = midY - labelY; // Center vertically
      offsetY = isLeftToRight ? 40 : 0; // Center vertically
    } else {
      console.log('Path data format unexpected or too short:', pathData);
    }

    // Adjust for parallel edges if needed
    // ... your logic for parallel edges ...

    // Return the adjusted position
    return { x: labelX + offsetX, y: labelY + offsetY };
  }

  const { x, y } = calculateLabelPosition();

  const getSensorValueInCorrectFormat = (sensor: any) => {
    const sensorValue =
      `${!isNaN(sensor?.value) && sensor?.value != null ? parseFloat(sensor?.value) : 'NA'} ${sensor?.unit?.value || ''}` ||
      0;

    return sensorValue;
  };

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        markerEnd={markerEnd}
        style={{ stroke: edgeColor, strokeWidth: width }}
        connectionLineType="smoothstep"
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            //transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            transform: `translate(-50%, -50%) translate(${x}px, ${y}px)`,
            pointerEvents: 'all',
          }}
        >
          {' '}
          {data.sensors?.length > 0 ? (
            <div onClick={handleOpenDialog} style={edgeDetailStyle}>
              <Stack direction="row" gap={2}>
                {data.sensors?.map((sensor: any, index: Number) => {
                  return (
                    <Stack direction="row" gap={1}>
                      <Box>{getSensorValueInCorrectFormat(sensor)}</Box>
                      <Box ml={1}>{data.sensors?.length - 1 != index ? '|' : null}</Box>
                    </Stack>
                  );
                })}
              </Stack>
            </div>
          ) : null}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
