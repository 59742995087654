import { useEffect, useState } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { postUsedInventory } from "../services/inventory";
import { InventoryQuestion } from "../Interfaces/inventory"; 

interface IProps {
  row: InventoryQuestion;
  showOnlyLowInventory: boolean;
  plantId: string | undefined;
}

export default function InventoryTableRow({row, showOnlyLowInventory, plantId} : IProps) {
  const [InventoryDataTally, setInventoryDataTally] = useState<any>({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    postUsedInventory(row, plantId)
      .then((data) => {
        setInventoryDataTally(data);
        setLoading(false);
      });
  }, [row]);
  if (loading) {
    return (
      <>
        <TableRow key={row.title} style={{ background: "#FFF" }}>
          <TableCell component="th" scope="row">
            {row.title}
          </TableCell>
          <TableCell align="right">loading</TableCell>
          <TableCell align="right">loading</TableCell>
          <TableCell align="right">loading</TableCell>
        </TableRow>
      </>
    );
  } else {
    if (
      (showOnlyLowInventory &&
        InventoryDataTally.today < row.criticalLowerLevel) ||
      !showOnlyLowInventory
    ) {
      return (
        <>
          <TableRow
            key={row.title}
            style={{
              backgroundColor:
                InventoryDataTally.today < row.criticalLowerLevel
                  ? "#EFDADA"
                  : "#FFF",
            }}
          >
            <TableCell component="th" scope="row">
              {row.title}
            </TableCell>
            <TableCell align="right">
              {InventoryDataTally["yesterday"]}
            </TableCell>
            <TableCell align="right">{InventoryDataTally["today"]}</TableCell>
            <TableCell align="right">{InventoryDataTally["tally"]}</TableCell>
          </TableRow>
        </>
      );
    } else {
      return <></>;
    }
  }
}
