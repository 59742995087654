import { memo, useState } from 'react';

import { useEffect } from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import TableCWidget from './subComponents/tableCWidget';
import GeneralisedGraphWidget from './subComponents/generalisedGraphWidget';

import { widgetDataInterface } from '../../Interfaces/widgetInterfaces';
import { timeFrame } from '../../Interfaces/dashboardPageInterfaces';
import { isAbsoluteURL, isRelativeURLWithoutSlash } from '../../utilities/helpers';
import { Box, Stack } from '@mui/material';
import { getAiWidgetData } from '../../services/dashboard/widget';
import UniversalSearchBar from './subComponents/UniversalSearchBar';
// import BuildIcon from '@mui/icons-material/Build';
import ConstructionIcon from '@mui/icons-material/Construction';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const placeHolders = [
  'Ex : Show me all tank level sensors for equilization tank',
  'Ex : Show me all the flowmeters attached with  twt tank',
  'Ex : Show me all the sensors in Equilisation Tank in table format',
  'Ex : Show me all the trip sensors of mbbr in table format',
];

interface standaloneGeneralisedWidgetProps {
  timeFrame: timeFrame | null;
  plantId: string;
  openAiSearchBar: boolean;
  setOpenAiSearchBar: React.Dispatch<React.SetStateAction<boolean>>;
  setShowAiWidget: React.Dispatch<React.SetStateAction<boolean>>;
}

const AIWidget = memo((props: standaloneGeneralisedWidgetProps) => {
  const add530HoursInTimeFrame = (timeFrame: timeFrame | null) => {
    if (!timeFrame) return { startDate: new Date(), endDate: new Date() };
    const newTimeFrame = {
      startDate: new Date(timeFrame?.startDate?.getTime() + 1000 * 60 * 60 * 5.5),
      endDate: new Date(timeFrame?.endDate?.getTime() + 1000 * 60 * 60 * 5.5),
    };

    return newTimeFrame;
  };

  const { timeFrame, plantId, openAiSearchBar, setOpenAiSearchBar, setShowAiWidget } = props;
  const [widgetData, setWidgetData] = useState<widgetDataInterface | null>(null);
  // const [refresh2, setRefresh2] = useState(false);
  const [loading, setLoading] = useState(false);
  //@ts-ignore
  const [isError, setIsError] = useState(false);
  const [displayMode, setDisplayMode] = useState('idle');
  const [searchQuery, setSearchQuery] = useState('');
  const [refreshWidgetData, setRefreshWidgetData] = useState(false);

  useEffect(() => {
    const { startDate, endDate } = add530HoursInTimeFrame(timeFrame);
    setIsError(false);
    if (displayMode === 'displayInfo' && searchQuery != '') {
      setLoading(true);
      setShowAiWidget(true);
      console.log('search2 : ', searchQuery);
      getAiWidgetData(searchQuery, plantId, { startDate, endDate }).then(data => {
        setWidgetData(data);
        setLoading(false);
      });
    }
  }, [refreshWidgetData]);

  useEffect(() => {
    console.log('searchQuery : ', searchQuery);
  }, [searchQuery]);

  let linkOnWidget = widgetData?.moreInfo || '';
  let showLinkIcon = true;

  if (linkOnWidget === 'link') {
    showLinkIcon = false;
  } else {
    if (!isAbsoluteURL(linkOnWidget)) {
      const location = window.location.origin;
      if (isRelativeURLWithoutSlash(linkOnWidget)) {
        linkOnWidget = `${location}/${linkOnWidget}`;
      } else {
        linkOnWidget = `${location}${linkOnWidget}`;
      }
    }
  }

  const setExpandedViewPopup = () => {};

  if (displayMode === 'idle')
    return (
      <>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'grid',
            placeItems: 'center',
            background: '#fff',
            color: '#999',
          }}
          borderRadius={4}
          onClick={() => {
            setOpenAiSearchBar(true);
          }}
        >
          Ai Widget
        </Box>
        <UniversalSearchBar
          open={openAiSearchBar}
          onClose={() => {
            setOpenAiSearchBar(false);
          }}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onSearchPressed={() => {
            setDisplayMode('displayInfo');
            setRefreshWidgetData(!refreshWidgetData);
          }}
          placeHolders={placeHolders}
        />
      </>
    );
  else if (loading) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'grid',
          placeItems: 'center',
          background: '#fff',
          color: '#999',
        }}
        borderRadius={4}
        onClick={() => {
          setOpenAiSearchBar(true);
        }}
      >
        <Stack direction="column" gap={5} justifyContent="center" alignItems="center">
          <Stack direction="row" gap={2}>
            <Box>Building your custom ai widget...</Box>
            {/* <BuildIcon sx={{ fontSize: '18px' }} /> */}
            <ConstructionIcon sx={{ fontSize: '18px' }} />
          </Stack>
          <Box sx={{ fontSize: '13px', textAlign: 'center' }}>{searchQuery}</Box>
        </Stack>
      </Box>
    );
  } else {
    return (
      <>
        {widgetData?.widgetType === 'graph' && widgetData !== null ? (
          <GeneralisedGraphWidget
            widgetStructure={{}}
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            isLoading={loading}
            headerType="normal"
            enableZoom={false}
            viewEveryNthTick={2}
            ticksColor="light"
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
            type={'standalone'}
          />
        ) : null}

        {widgetData?.widgetType === 'tableC' && widgetData !== null ? (
          <TableCWidget
            widgetStructure={{}}
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            isLoading={loading}
            headerType="normal"
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
          />
        ) : null}
        <UniversalSearchBar
          open={openAiSearchBar}
          onClose={() => {
            setOpenAiSearchBar(false);
          }}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onSearchPressed={() => {
            setDisplayMode('displayInfo');
            setRefreshWidgetData(!refreshWidgetData);
          }}
          placeHolders={placeHolders}
        />
      </>
    );
  }
});

export default AIWidget;
