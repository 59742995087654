import axiosInstance from "../constants/axiosInstance";

const checkedInUsers = async () => {
  try {
    const { data } = await axiosInstance().get("/users/checkedInUsers");
    return data?.user;
  } catch (err) {
    console.error("ERROR | checkedInUsers | ", err);
  }
};

const checkOutForOG = async (checkOutData: any) => {
  try {
    const { data } = await axiosInstance().post(
      "/users/checkOutForOG",
      checkOutData
    );
    return data;
  } catch (err) {
    console.error("ERROR | checkOutForOG | ", err);
  }
};

const checkOutForRO = async (checkOutData: any) => {
  try {
    const { data } = await axiosInstance().post(
      "/users/checkOutForRO",
      checkOutData
    );
    return data;
  } catch (err) {
    console.error("ERROR | checkOutForRO | ", err);
  }
};

export { checkedInUsers, checkOutForOG, checkOutForRO };
