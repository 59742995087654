import axiosInstance from "../constants/axiosInstance";

const getPlantNamesForRO = async () => {
  try {
    const { data } = await axiosInstance().get("/users/getPlantNamesForRO");
    return data?.Plants;
  } catch (err) {
    console.error("ERROR | roleforTasks | ", err);
  }
};

const checkinUpdateForROService = async (
  checkInPlant: any,
  checkInStatus: boolean,
  location: any,
  deviceId: string
) => {
  try {
    const { data } = await axiosInstance().post("/users/checkinUpdateForRO", {
      checkInPlant: checkInPlant,
      checkInStatus: checkInStatus,
      location: location,
      deviceId: deviceId,
    });
    return data;
  } catch (err) {
    console.error("ERROR | updateOneTimeTasks | ", err);
  }
};

const onDutyUpdateService = async (onDuty: boolean) => {
  try {
    const { data } = await axiosInstance().post("/users/onDutyUpdate", {
      onDuty: onDuty,
    });
    return data;
  } catch (err) {
    console.error("ERROR | updateOneTimeTasks | ", err);
  }
};

export { getPlantNamesForRO, checkinUpdateForROService, onDutyUpdateService };
