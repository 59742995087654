import Typography from '@mui/material/Typography';
import { Box, Button, Dialog } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, Dispatch, SetStateAction } from 'react';
import TicketComponentv2 from '../TicketComponentv2/TicketComponentv2';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import { BlackButton } from '../../styles/global/components/dpButtons';
import { markTicketResolved, openTheAlert } from '../../services/tickets';
import { alertInterface } from '../../Interfaces/ticketsv2Interfaces';

const styles = {
  ticketPopupBox: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '70vh',
    overflowY: 'hidden',
    bgcolor: 'background.paper',
    border: '1px solid #D4DCE5',
    boxShadow: 24,
    p: 8,
    zIndex: 100,
  },
  popupBoxHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '10px',
    flex: '0 0 auto',
  },
  ticketId: {
    fontWeight: 'bold',
    color: '#193458',
    fontSize: '15px',
  },
  tagContainer: {},
  headerTagChip: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: 12,
    width: 'fit-content',
  },
  actionButton: {
    background: '#053F5C',
    color: '#FFFFFF',
    fontSize: '9px',
    marginLeft: 'auto',
  },
  ticketPopupBoxBody: {
    display: 'flex',
    flexDirection: { xs: 'column', sm: 'row' },
    pt: 1,
    flex: '1 1 auto',
    gap: '20px',
    overflowY: 'hidden',
  },
  historyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0',
    background: '#F5F6F9',
    overflowY: 'scroll',
    height: 'inherit',
  },
};

interface ticketPopupBoxInterface {
  plants: {
    plantName: string;
    plantId: string;
  }[];
  alert: alertInterface;
  ticketIds: string[];
  ticketopen: boolean;
  setOpenTicketPopup: Dispatch<SetStateAction<boolean>>;
  setRefreshStateOfIssueTrackerComponent: Dispatch<SetStateAction<boolean>>;
  setRefreshStateOfTicketsPage: Dispatch<SetStateAction<boolean>>;
}

export default function TicketPopupBox(props: ticketPopupBoxInterface) {
  const {
    alert,
    ticketIds,
    plants,
    ticketopen,
    setOpenTicketPopup,
    setRefreshStateOfIssueTrackerComponent,
    setRefreshStateOfTicketsPage,
  } = props;

  const [open, setOpen] = useState(false);
  const [modalBoxPurpose, setModalBoxPurpose] = useState('resolveConfirmation');
  const handleClose = () => {
    setOpenTicketPopup(false);
    setOpen(false);
  };
  const markIssueResolved = () => {
    markTicketResolved(alert._id, 'user').then(msg => {
      toast(msg);
      setOpen(false);
      setOpenTicketPopup(false);
      setRefreshStateOfIssueTrackerComponent(refresh => !refresh);
      setRefreshStateOfTicketsPage(refresh => !refresh);
    });
  };

  const reOpenIssue = () => {
    console.log('inside reOpen issue');
    openTheAlert(alert._id).then(msg => {
      toast(msg);
      setOpen(false);
      setOpenTicketPopup(false);
      setRefreshStateOfIssueTrackerComponent(refresh => !refresh);
      setRefreshStateOfTicketsPage(refresh => !refresh);
    });
  };

  return (
    <>
      <Dialog open={ticketopen} onClose={handleClose}>
        <Box sx={{ ...styles.ticketPopupBox }}>
          <Box sx={{ ...styles.popupBoxHeader }}>
            <CloseIcon
              style={{ color: '#797E85' }}
              sx={{
                cursor: 'pointer',
                ':hover': {
                  color: 'black !important',
                },
              }}
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setOpenTicketPopup(false);
              }}
            />
            <p style={styles.ticketId}>Ticket #{alert._id}</p>
            <Box sx={styles.tagContainer}>
              {alert.headers?.map((tag: string) => {
                return (
                  <Box
                    m={1}
                    px={1}
                    py={1}
                    bgcolor="#F07373"
                    borderRadius={10}
                    sx={styles.headerTagChip}
                  >
                    {tag}
                  </Box>
                );
              })}
            </Box>
            {alert.isOpen ? (
              <Button
                variant="contained"
                style={styles.actionButton}
                onClick={() => {
                  setModalBoxPurpose('resolveConfirmation');
                  setOpen(true);
                }}
              >
                Resolve
              </Button>
            ) : (
              <Button
                variant="contained"
                style={styles.actionButton}
                onClick={() => {
                  setModalBoxPurpose('reOpenConfirmation');
                  setOpen(true);
                }}
              >
                ReOpen
              </Button>
            )}
          </Box>
          <Divider sx={{ mt: 2 }} />
          <Box sx={styles.ticketPopupBoxBody}>
            <Box
              className="ticketInfoContainer"
              style={{
                flex: '1.5 1 0',
                fontSize: '13px',
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
              }}
            >
              <Box style={{ color: '#4D4D4D', fontSize: '11px' }}>
                Ticket Created{' '}
                {moment(alert.lastTicket?.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSS').format(
                  'DD/MM/YYYY hh:mm a'
                )}
              </Box>
              <Stack direction="column" spacing={1}>
                <span style={{ color: '#61657E' }}>
                  {alert.hasOwnProperty('plantId') ? 'Plant' : 'Plants'}
                </span>
                <span>
                  {alert.hasOwnProperty('plantId')
                    ? plants?.filter(plant => plant.plantId === alert.plantId)[0]['plantName']
                    : alert.plantNames}
                </span>
              </Stack>
              <Stack direction="column" spacing={1}>
                <span style={{ color: '#61657E' }}>Problem</span>
                <span>{alert.heading.eng}</span>
              </Stack>
              <Stack direction="column" spacing={1}>
                <span style={{ color: '#61657E' }}>Description</span>
                <span>{alert.description.eng}</span>
              </Stack>
              <Stack justifyContent="space-between" direction="row" style={{ width: '100%' }}>
                <Stack direction="column" spacing={1}>
                  <div style={{ color: '#61657E' }}>Equipment</div>
                  <div>{alert.equipments?.nickName ?? alert.equipments?.id}</div>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <div style={{ color: '#61657E' }}>Category</div>
                  <div>
                    {alert.issueType?.map((issue, index) => {
                      return (
                        <span>{issue + (index !== alert.issueType.length - 1 ? ',' : '')}</span>
                      );
                    })}
                  </div>
                </Stack>
              </Stack>
            </Box>
            <Box className="historyContainer" mx={1} sx={styles.historyContainer}>
              <Box mx={2} my={1}>
                <span
                  style={{
                    color: '#193458',
                    fontSize: '14px',
                    fontFamily: 'Roboto',
                    fontWeight: 500,
                  }}
                >
                  History
                </span>
              </Box>
              <Box mx={2} my={1}>
                <span style={{ color: '#61657E', fontSize: '12px', width: '100%' }}>
                  Most Recent
                </span>
              </Box>
              <Box style={{ width: '100%' }}>
                {ticketIds.map((ticketId, index) => {
                  return (
                    <TicketComponentv2
                      ticketId={ticketId.toString()}
                      ticketIndex={index}
                      plantId={alert.plantId}
                    />
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
      <ModalBox
        open={open}
        handleClose={handleClose}
        modalBoxPurpose={modalBoxPurpose}
        markIssueResolved={markIssueResolved}
        reOpenIssue={reOpenIssue}
      />
    </>
  );
}

//// ModalBox component .............................................................
const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  overflowY: 'scroll',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 10,
  zIndex: 100,
};

interface modalBoxProps {
  open: boolean;
  handleClose: () => void;
  modalBoxPurpose: string;
  markIssueResolved: Function;
  reOpenIssue: Function;
}

function ModalBox(props: modalBoxProps) {
  const { open, handleClose, modalBoxPurpose, markIssueResolved, reOpenIssue } = props;
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {modalBoxPurpose === 'reOpenConfirmation'
            ? 'Please confirm you want to reopen the ticket'
            : ' Please confirm resolution for ticket, this step cannot be undone!'}
        </Typography>
        <Box m={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <BlackButton
            variant="contained"
            onClick={e => {
              e.stopPropagation();
              if (modalBoxPurpose === 'reOpenConfirmation') reOpenIssue();
              else markIssueResolved();
            }}
          >
            {modalBoxPurpose === 'reOpenConfirmation' ? 'Reopen' : 'Mark Resolved'}
          </BlackButton>
        </Box>
      </Box>
    </Modal>
  );
}
////.......................................................................................
