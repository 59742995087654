import React, { useState } from 'react';
import { IconButton, Stack, Typography, Box } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import DateRangeIcon from '@mui/icons-material/DateRange';
// import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import dayjs, { Dayjs } from 'dayjs';
import playTvIcon from '../../../assets/icons/playTvIcon.svg';
import pauseTvIcon from '../../../assets/icons/pauseTvIcon.svg';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Popover from '@mui/material/Popover';
import {
  DialogPositiveButtonStyle,
  DialogNegativeButtonStyle,
} from '../../../styles/global/components/dpButtons';
import { MultiSectionDigitalClock } from '@mui/x-date-pickers/MultiSectionDigitalClock';

const LayoutDateTimeSelector = (props: any) => {
  const [openDateTimePicker, setOpenDateTimePicker] = useState(false);
  const [dateTime, setDateTime] = useState<Dayjs>(dayjs());
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const { setTimeFrame, setSSESwitchedOn, sseSwitchedOn } = props;

  const handleDateTimeChange = (newDateTime: Dayjs | null) => {
    if (newDateTime) {
      setDateTime(newDateTime);
    }
  };

  const handleApplyClick = () => {
    const endTime = dateTime.add(5.5, 'hour');
    const startTime = endTime.subtract(10, 'minute');
    setTimeFrame({
      startTime: startTime.toDate(),
      endTime: endTime.toDate(),
    });
    setSSESwitchedOn(false);
    setAnchorEl(null);
    setOpenDateTimePicker(false);
  };

  const handleCancel = () => {
    setAnchorEl(null);
    setOpenDateTimePicker(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        flexDirection: 'row',
        justifyContent: { xs: 'space-between', md: 'flex-start' },
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          justifyItems="center"
          columnGap="5px"
          onClick={event => {
            setAnchorEl(event.currentTarget);
            setOpenDateTimePicker(true);
          }}
          sx={{
            border: '1px solid #C4C4C4',
            borderRadius: '5px',
            cursor: 'pointer',
            padding: '15px',
            color: '#999999',
          }}
        >
          <DateRangeIcon fontSize="small" sx={{ color: '#3EA4A8' }} />
          <Typography color="dark" sx={{ fontSize: { xs: '11px', md: '12px' } }}>
            {dayjs(dateTime).format('Do MMM YYYY')}
          </Typography>
          <ArrowDropDownIcon fontSize="small" />
          <Typography variant="h3" sx={{ fontSize: '14px' }}>
            |
          </Typography>
          <WatchLaterIcon fontSize="small" sx={{ marginLeft: '5px', color: '#3EA4A8' }} />
          <Typography color="dark" sx={{ fontSize: { xs: '11px', md: '12px' } }}>
            {dayjs(dateTime).format('hh:mm A')}
          </Typography>
          <ArrowDropDownIcon fontSize="small" />
        </Stack>
        <Box>
          {/* <MobileDateTimePicker
                open={openDateTimePicker}
                onClose={() => setOpenDateTimePicker(false)}
                value={dateTime}
                views={['year', 'month', 'day', 'hours', 'minutes']}
                // disableFuture={true}
                onChange={dateTime => {
                  // console.log("dateTime", dateTime);
                  handleDateTimeChange(dateTime);
                }}
                slots={{
                  textField: () => <TextField sx={{ display: 'none' }} />,
                }}
              /> */}

          <Popover
            id={openDateTimePicker ? 'simple-popover' : undefined}
            open={openDateTimePicker}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
              setOpenDateTimePicker(false);
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Stack>
              <Stack direction="row" sx={{ height: '300px' }}>
                <DateCalendar
                  value={dateTime}
                  onChange={dateTime => {
                    // console.log("dateTime", dateTime);
                    handleDateTimeChange(dateTime);
                  }}
                  disableFuture={true}
                />
                {/* <DigitalClock
                  value={dateTime}
                  onChange={dateTime => {
                    // console.log("dateTime", dateTime);
                    handleDateTimeChange(dateTime);
                  }}
                  timeStep={1}
                  sx={{ maxWidth: '100px', height: '300px', paddingTop: '50px' }}
                /> */}
                <MultiSectionDigitalClock
                  value={dateTime}
                  onChange={dateTime => {
                    // console.log("dateTime", dateTime);
                    handleDateTimeChange(dateTime);
                  }}
                  timeSteps={{ hours: 1, minutes: 2 }}
                  sx={{ maxWidth: '150px', height: '300px', paddingTop: '50px' }}
                />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                m={1}
              >
                <DialogNegativeButtonStyle onClick={handleCancel}>
                  <Typography variant="h6">Cancel</Typography>
                </DialogNegativeButtonStyle>
                <DialogPositiveButtonStyle onClick={handleApplyClick}>
                  <Typography variant="h6">Apply</Typography>
                </DialogPositiveButtonStyle>
              </Stack>
          </Stack>
          </Popover>
        </Box>
      </LocalizationProvider>
      <Box
        p={1}
        sx={{
          display: 'grid',
          placeItems: 'center',
          border: '1px solid #C4C4C4',
          width: 50,
          height: 50,
          borderRadius: '4px',
        }}
      >
        {sseSwitchedOn ? (
          <img src={playTvIcon} style={{ width: 26, height: 24, color: '#CEE3F5' }} />
        ) : (
          <IconButton
            onClick={() => {
              setDateTime(dayjs());
              setSSESwitchedOn(true);
            }}
            sx={{
              fontSize: '40px',
              color: 'red',
              '&:hover': {
                backgroundColor: 'rgba(255, 0, 0, 0.1)', // Change hover color
              },
            }}
          >
            <img src={pauseTvIcon} style={{ width: 26, height: 24, color: '#CEE3F5' }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default LayoutDateTimeSelector;
