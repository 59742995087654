import axiosInstance from "../constants/axiosInstance";

const updateTimeFrameForRefId = async (
  startDate: Date | string,
  endDate: Date | string,
  refId: string,
  forWhichComponent: string
) => {
  try {
    const resp = await axiosInstance().post(
      `/commonApis/updateTimeFrameForRefId`,
      {
        startTime: startDate,
        endTime: endDate,
        refId: refId,
        for: forWhichComponent,
      }
    );
    return resp.data?.data;
  } catch (err) {
    console.log(err);
  }
};

export { updateTimeFrameForRefId };
