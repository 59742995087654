import { MIN_ALLOWED_SENSOR_VALUE, MAX_ALLOWED_SENSOR_VALUE } from '../../../constants/constants';

const RANGE_OBJ = {
  warningRange: {
    min: MIN_ALLOWED_SENSOR_VALUE,
    max: MAX_ALLOWED_SENSOR_VALUE,
  },
  goodRange: {
    min: MIN_ALLOWED_SENSOR_VALUE,
    max: MAX_ALLOWED_SENSOR_VALUE,
  },
  dangerRange: {
    min: MIN_ALLOWED_SENSOR_VALUE,
    max: MAX_ALLOWED_SENSOR_VALUE,
  },
};

export { RANGE_OBJ };
