export const filterItemsByCriteria = (items: any[], query: string, filterTypes: string | any[]) => {
  if (query.trim().length === 0) return items;
  const lowerCaseQuery = query.toLowerCase();
  const filterTypesArray = Array.isArray(filterTypes) ? filterTypes : [filterTypes];

  const matchesQuery = (item: { name: string; description?: string }) =>
    item.name.toLowerCase().includes(lowerCaseQuery) ||
    (item.description && item.description.toLowerCase().includes(lowerCaseQuery));

  const matchesFilterType = (item: { type: string }) =>
    !filterTypesArray || filterTypesArray.length === 0 || filterTypesArray.includes(item.type);

  const filterRecursively = (items: any[]) => {
    return items
      .map(item => {
        const newItem = { ...item };
        if (newItem.children) {
          newItem.children = filterRecursively(newItem.children);
        }
        return newItem;
      })
      .filter(
        item =>
          (matchesFilterType(item) && matchesQuery(item)) ||
          (item.children && item.children.length > 0)
      );
  };

  return filterRecursively(items);
};
