//@ts-nocheck
import React, { useState } from 'react';
import clsx from 'clsx';
import { animated, useSpring } from '@react-spring/web';
import { styled } from '@mui/material/styles';
import { TransitionProps } from '@mui/material/transitions';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { treeItemClasses } from '@mui/x-tree-view/TreeItem';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {
  unstable_useTreeItem2 as useTreeItem2,
  UseTreeItem2Parameters,
} from '@mui/x-tree-view/useTreeItem2';
import {
  TreeItem2Checkbox,
  TreeItem2Content,
  TreeItem2IconContainer,
  TreeItem2Label,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { Autocomplete, Chip, Stack, TextField, Tooltip, useMediaQuery } from '@mui/material';
import { Neutrals, Primary } from '../../../../core-components/theme/color-palette/colors';
import { getIconFromFileType } from '../../../../utils/getIcon.utils';
import CustomIconButton from '../../../../core-components/theme/components/Button/Iconbutton';
import DialogBox from '../../../../core-components/theme/components/DialogBox/DialogBox';
import Button from '../../../../core-components/theme/components/Button/Button';
import CheckboxList from '../../../../core-components/theme/components/List/List';
import { useRoleStore } from '../../user2/store/useWorkspaceStore';
import { useQueryClient } from '@tanstack/react-query';
import { handleRoleAdd } from './helpers/helper';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

const StyledTreeItemRoot = styled(TreeItem2Root)(({ theme }) => ({
  color: theme.palette.mode === 'light' ? theme.palette.grey[800] : theme.palette.grey[400],
  position: 'relative',
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: theme.spacing(3),
  },
})) as unknown as typeof TreeItem2Root;

const CustomTreeItemContent = styled(TreeItem2Content)(({ theme }) => ({
  width: '100%',
  flexDirection: 'row',
  borderRadius: theme.spacing(1),
  padding: theme.spacing(1),
  height: '52px',
  paddingRight: theme.spacing(1),
  fontWeight: 500,
  backgroundColor: 'transparent',
  [`& .${treeItemClasses.iconContainer}`]: {
    marginRight: theme.spacing(2),
  },
  [`&.Mui-expanded `]: {
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: '16px',
      top: '44px',
      height: 'calc(100% - 48px)',
      width: '1.5px',
    },
  },
  '&:hover': {
    backgroundColor: Primary[50],
    borderRadius: '0px',
  },
}));

const AnimatedCollapse = animated(Collapse);

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(0,${props.in ? 0 : 20}px,0)`,
    },
  });

  return <AnimatedCollapse style={style} {...props} />;
}

const StyledTreeItemLabelText = styled(Typography)({
  color: 'inherit',
  ml: 1,
  fontSize: '14px',
  textOverflow: 'ellipsis',
  fontWeight: 400,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}) as unknown as typeof Typography;

interface CustomLabelProps {
  children: string;
  icon?: React.ElementType;
  expandable?: boolean;
}

function CustomLabel({ icon: Icon, expandable, children = '', ...other }: CustomLabelProps) {
  //@ts-ignore
  // const [name] = children.split('-');
  return (
    <TreeItem2Label
      {...other}
      sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '0.5em',
      }}
    >
      <Tooltip title={children}>
        <Stack
          sx={{ textAlign: 'center', display: 'flex', alignItems: 'center', overflow: 'hidden' }}
          spacing={2}
          direction="row"
        >
          {Icon && (
            <Box
              component={Icon}
              className="labelIcon"
              color="inherit"
              sx={{ mr: 1, width: '16px', height: '16px' }}
            />
          )}
          <StyledTreeItemLabelText
            variant="h4"
            noWrap
            gap={1}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <Typography color={Primary[900]}>{children}</Typography>
          </StyledTreeItemLabelText>
        </Stack>
      </Tooltip>
    </TreeItem2Label>
  );
}

const isExpandable = (reactChildren: React.ReactNode) => {
  if (Array.isArray(reactChildren)) {
    return reactChildren.length > 0 && reactChildren.some(isExpandable);
  }
  return Boolean(reactChildren);
};

interface CustomTreeItemProps
  extends Omit<UseTreeItem2Parameters, 'rootRef'>,
    Omit<React.HTMLAttributes<HTMLLIElement>, 'onFocus'> {}

const CustomUserTreeItem = React.forwardRef(function CustomTreeItem(
  props: CustomTreeItemProps,
  ref: React.Ref<HTMLLIElement>
) {
  const { id, itemId, label, disabled, children, ...other } = props;
  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getCheckboxProps,
    getLabelProps,
    getGroupTransitionProps,
    status,
    publicAPI,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  const item = publicAPI.getItem(itemId);
  const item2 = publicAPI.getItemTree();
  const roles = publicAPI.getItem(itemId)?.roles;
  const type = Object.keys(item)?.includes('roles');

  const [open, setOpen] = useState(false);
  const [openAssets, setOpenAssets] = useState(false);
  const [autoComplete, setAutoComplete] = useState<any[]>(
    roles?.filter((role: { selected: any }) => role.selected)
  ); // To hold selected roles

  // const [autoComplete,SetAutoComplete] = useState([])
  const handleOpen = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(true);
  };
  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(false);
  };
  const handleOpenAsset = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenAssets(true);
  };
  const handleCloseAsset = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpenAssets(false);
  };

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const handleDelete = (roleToDelete: any) => {
    setAutoComplete(prev => prev.filter(role => role._id !== roleToDelete._id));
    // removeItemId(treeId, roleToDelete.itemId); // Remove itemId from Zustand store
  };

  // Add roles to dependency array
  const setWorkspaceAssetRoles = useRoleStore((state: any) => state.setWorkspaceAssetRoles);
  const queryClient = useQueryClient();
  const handleSaveRoles = () => {
    const selectedRoleItemIds = autoComplete.map(role => role.itemId);
    const workspaceData = handleRoleAdd(item2, selectedRoleItemIds);
    setWorkspaceAssetRoles(workspaceData);
    setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['workspacePermissionTree'] });
    }, 0);
  };

  const expandable = isExpandable(children);
  let icon = getIconFromFileType(item.type);
  return (
    // @ts-ignore
    <TreeItem2Provider itemId={itemId}>
      <StyledTreeItemRoot {...getRootProps(other)}>
        <CustomTreeItemContent
          sx={{
            marginBottom: '2px',
          }}
          {...getContentProps({
            className: clsx('content', {
              'Mui-expanded': status.expanded,
              'Mui-selected': status.selected,
              'Mui-focused': status.focused,
              'Mui-disabled': status.disabled,
            }),
          })}
        >
          <TreeItem2Checkbox {...getCheckboxProps()} />
          <CustomLabel
            icon={icon}
            expandable={expandable && status.expanded}
            {...getLabelProps()}
            data={item}
          />
          {roles && (
            <Box sx={{ display: 'flex', gap: '8px', marginLeft: 'auto' }}>
              <Chip
                variant="outlined"
                label={`Assets Roles: ${autoComplete.length}`}
                onClick={handleOpenAsset}
              />
            </Box>
          )}
          <DialogBox
            open={openAssets}
            onClick={e => e.stopPropagation()}
            handleClose={handleCloseAsset}
            title="Assets"
          >
            <CheckboxList items={autoComplete} showCheckbox={false} showAvatar={false} />
          </DialogBox>
          {type && <CustomIconButton onClick={handleOpen} icon={AddIcon} />}
          <DialogBox
            open={open}
            onClick={e => e.stopPropagation()}
            handleClose={(_, reason) => {
              // Prevent dialog from closing on select events
              if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
                setOpen(false);
              }
            }}
            title="Add role"
          >
            {/* <Autocomplete
              multiple
              options={roles}
              getOptionLabel={item => item.name}
              value={autoComplete}
              onChange={(event, newValue) => {
                // setAutoComplete(newValue);
                console.log({ autoComplete });
                // Update Zustand with the itemIds from the newly selected roles
                const newSelectedItemIds = newValue.map(role => role.itemId);
                newValue.forEach(role => {
                  if (!selectedItemIds[treeId].includes(role.itemId)) {
                    addItemId(treeId, role.itemId); // Add only if not present
                  }
                });

                // Optionally handle removal logic here as well
                selectedItemIds.forEach(itemId => {
                  if (!newSelectedItemIds[treeId].includes(itemId)) {
                    removeItemId(treeId, itemId); // Remove items that are no longer selected
                  }
                });
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option._id}
                    label={option.name}
                    {...getTagProps({ index })}
                    onDelete={() => handleDelete(option)} // Add delete icon to the chip
                    deleteIcon={<CloseIcon />} // Use the CloseIcon from MUI for the delete icon
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Roles"
                  placeholder="Add Role"
                  variant="outlined"
                  fullWidth
                />
              )}
            /> */}
            <Autocomplete
              multiple
              options={roles} // list of roles
              getOptionLabel={role => role.name}
              value={autoComplete} // Bind the local state to the value
              onChange={(event, newValue) => {
                // Update the local autocomplete state with the selected roles
                setAutoComplete(newValue);

                // Extract itemIds of the new selected roles
                const newSelectedItemIds = newValue.map(role => role.itemId);

                // Add itemIds for newly selected roles to Zustand (if not already present)
                // newValue.forEach(role => {
                //   if (!selectedItemIds[treeId]?.includes(role.itemId)) {
                //     addItemId(treeId, role.itemId); // Add new selected role itemId to Zustand
                //   }
                // });

                // Remove itemIds for deselected roles from Zustand
                // autoComplete.forEach(role => {
                //   if (!newSelectedItemIds.includes(role.itemId)) {
                //     removeItemId(treeId, role.itemId); // Remove deselected role itemId from Zustand
                //   }
                // });
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option._id}
                    label={option.name}
                    {...getTagProps({ index })}
                    onDelete={() => handleDelete(option)} // Add delete icon to the chip
                    deleteIcon={<CloseIcon />} // Use the CloseIcon from MUI for the delete icon
                  />
                ))
              }
              renderInput={params => (
                <TextField
                  {...params}
                  label="Roles"
                  placeholder="Add Role"
                  variant="outlined"
                  fullWidth
                />
              )}
            />

            <Box mt={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                sx={{
                  border: `1px solid ${Neutrals[500]}`,
                  borderRadius: '4px',
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  backgroundColor: 'white',
                  color: `${Neutrals[500]}`,
                  flex: isMobile ? 1 : 0,
                  boxShadow: 'none',
                  ':hover': { background: 'none', boxShadow: 'none' },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  flex: isMobile ? 1 : 0,
                  borderRadius: '4px',
                  backgroundColor: `${Primary[500]}`,
                  boxShadow: 'none',
                  ':hover': { boxShadow: 'none' },
                }}
                onClick={() => handleSaveRoles(item)}
              >
                Save
              </Button>
            </Box>
          </DialogBox>
          <TreeItem2IconContainer {...getIconContainerProps()} sx={{ paddingRight: '12px' }}>
            <TreeItem2Icon status={status} />
          </TreeItem2IconContainer>
        </CustomTreeItemContent>
        {children && <TransitionComponent {...getGroupTransitionProps()} />}
      </StyledTreeItemRoot>
    </TreeItem2Provider>
  );
});

export default CustomUserTreeItem;
