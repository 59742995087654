import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import classes from "./SolutionLogsPopup.module.css";
import LineChart from "../GeneralisedWidgetComponents/subComponents/lineChartComponent";
import moment from "moment";
import dpTheme from "../../styles/theme/dpTheme";
import { ThemeProvider } from "@emotion/react";
import PopupWithHeaderAndCloseButton from "../GeneralPopupComponents/PopupWithHeaderAndCloseButton";
import SolutionLogsTableComponent from "./SubComponents/SolutionLogsTableComponent";
import axiosInstance from "../../constants/axiosInstance";
import {
  SolutionInterface,
  SolutionUsageLog,
} from "../../Interfaces/SolutionInterfaces";

interface PropsInterface {
  solutionId: string;
  closePopup: Function;
}

export default function SolutionLogsPopup(props: PropsInterface) {
  const { solutionId, closePopup } = props;
  const [solution, setSolution] = useState<SolutionInterface | null>(null);
  const [loading, setLoading] = useState(false);

  const getSolution = async (solutionId: string) => {
    console.log("inside more solutions");
    setLoading(true);
    let { data } = await axiosInstance().get(
      `/store/getSolution?solutionId=${solutionId}`
    );
    setSolution(data.data);
    setLoading(false);
    console.log("data : ", data.data);
    // setTimeout(() => {
    //   setLoading(false);
    //   setSolution(apiResponse);
    // }, 3000);
  };

  useEffect(() => {
    getSolution(solutionId);
  }, []);

  const convertSolutionLogsDataIntoChartData = (
    solution: SolutionInterface
  ) => {
    let data = {
      labels: [] as string[],
      datasets: [
        {
          data: [] as (string | number | null)[],
          label: `Solution Consumed`,
          id: "",
          borderColor: "",
          backgroundColor: "rgb(255,255,255)",
          pointStyle: "circle",
          pointRadius: 3.5,
          fill: false,
        },
      ],
    };

    solution!.solutionUsage!.forEach((solutionUsageLog: SolutionUsageLog) => {
      let time = moment(
        solutionUsageLog.startTime,
        "YYYY-MM-DDTHH:mm:ssZ"
      ).format("DD-MMM HH:mm");
      data.labels.push(time);
      data.datasets[0].data.push(solutionUsageLog.amount + "");
    });

    console.log("data : ", data);

    return data;
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <PopupWithHeaderAndCloseButton
        closePopup={closePopup}
        headingText={solution ? `${solution.solutionName} Logs` : `Heading`}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems="center"
          justifyContent="space-around"
          gap={5}
          sx={{ marginTop: "20px" }}
        >
          <Box className={classes.chartContainer}>
            {loading && (
              <Box className={classes.loader}>
                <CircularProgress size="30px" color="inherit" />
              </Box>
            )}
            {solution && !loading && (
              <LineChart
                data={convertSolutionLogsDataIntoChartData(solution)}
                enableZoom={false}
                viewEveryNthTick={1}
                ticksColor={"#333"}
                resetZoom={false}
              />
            )}
          </Box>
          <Box className={classes.tableContainer}>
            {loading && (
              <Box className={classes.loader}>
                <CircularProgress size="30px" color="inherit" />
              </Box>
            )}
            {solution && !loading && (
              <SolutionLogsTableComponent solution={solution} />
            )}
          </Box>
        </Stack>
      </PopupWithHeaderAndCloseButton>
    </ThemeProvider>
  );
}
