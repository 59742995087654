import CommitIcon from '@mui/icons-material/Commit';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  ListItemIcon,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { PlantConfigStyle } from '.././PlantConfigStyle';
import React from 'react';
interface IProps {
  parentEquipmentCategory: string;
  parentEquipmentIndex: number;
  parentSubEquipmentCategories: string[];
  handleSelectSubEquipmentChange: Function;
  subEquipments: any;
  outputOptions: any;
}
// function checkIfNodeCanBeRendered(equipment: any) {
//   let returnValue = false;
//   if (equipment.hasOwnProperty('displayNode')) {
//     returnValue = equipment.displayNode;
//   }
//   return returnValue;
// }
export default function SubOutputSelector({
  parentEquipmentCategory,
  parentEquipmentIndex,
  parentSubEquipmentCategories,
  handleSelectSubEquipmentChange,
  subEquipments,
  outputOptions,
}: IProps) {
  return (
    <>
      <TableRow>
        <TableCell colSpan={2}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              //   aria-controls={`accordion-content-${subEquipment.id}`}
              //   id={`accordion-header-${subEquipment.id}`}
            >
              <Typography>Sub Equipments</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {parentSubEquipmentCategories.map((subEquipmentCategory: any, index: number) => {
                return (
                  <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h5">{subEquipmentCategory}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <TableContainer component={Paper} sx={{ width: '100%' }}>
                        <Table aria-label="Equipment Output Selection table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Equipment Name</TableCell>
                              <TableCell align="center">Output</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {subEquipments[subEquipmentCategory].map(
                              (equipment: any, index: number) => (
                                // checkIfNodeCanBeRendered(equipment) &&
                                <React.Fragment key={equipment.id}>
                                  <TableRow
                                    key={equipment.id}
                                    sx={{
                                      '&:last-child td, &:last-child th': {
                                        border: 0,
                                      },
                                    }}
                                  >
                                    <TableCell align="center">{equipment.id}</TableCell>
                                    <TableCell align="center">
                                      <FormControl>
                                        <Select
                                          id={equipment.id}
                                          multiple
                                          value={equipment?.Output || []}
                                          onChange={event => {
                                            handleSelectSubEquipmentChange(
                                              event,
                                              parentEquipmentCategory,
                                              parentEquipmentIndex,
                                              subEquipmentCategory,
                                              index
                                            );
                                          }}
                                          renderValue={allSelectedValues => (
                                            <div
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                              }}
                                            >
                                              {allSelectedValues.map((selectedValue: any) => (
                                                <div style={PlantConfigStyle.selectedOptions}>
                                                  {selectedValue}
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                          style={{
                                            minWidth: '12rem',
                                            maxWidth: '18rem',
                                          }}
                                        >
                                          {outputOptions.map((option: any) => {
                                            if (option.showInSelection) {
                                              if (option.isSubEquipment) {
                                                // Return a different component for isSubEquipment true
                                                return (
                                                  <MenuItem key={option.id} value={option.id}>
                                                    {option.parentEquipmentId}
                                                    <ListItemIcon>
                                                      <CommitIcon />
                                                    </ListItemIcon>
                                                    {option.id}
                                                  </MenuItem>
                                                );
                                              } else {
                                                // Return another component for isSubEquipment false
                                                return (
                                                  <MenuItem key={option.id} value={option.id}>
                                                    {option.id}
                                                  </MenuItem>
                                                );
                                              }
                                            } else {
                                              // If showInSelection is false, do not render anything for this option
                                              return null;
                                            }
                                          })}
                                        </Select>
                                      </FormControl>
                                    </TableCell>
                                  </TableRow>
                                </React.Fragment>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </AccordionDetails>
          </Accordion>
        </TableCell>
      </TableRow>
    </>
  );
}
