import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
//@ts-ignore
import Map from "mapmyindia-react";
import { Box } from "@mui/material";

import { manpowerStatusOfPlant } from "../../Interfaces/manpowerAvailability";

export default function ManpowerDataOfPlantRowComponent(props: {
  row: manpowerStatusOfPlant;
}) {
  const { row } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        {/* <TableCell align="right">{row.name}</TableCell> */}
        <TableCell align="right">{row.openAlerts}</TableCell>
        <TableCell
          align="right"
          style={
            row.manningStatus
              ? { color: "rgb(0,100,0)" }
              : { color: "rgb(150,0,0)" }
          }
        >
          {row.manningStatus ? "Manned" : "Not Manned"}
        </TableCell>
        {/* <TableCell align="right">{row.protein}</TableCell> */}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>name</TableCell>
                    <TableCell>role</TableCell>
                    <TableCell align="right">check In</TableCell>
                    <TableCell align="right">check Out</TableCell>
                    <TableCell align="right">location</TableCell>
                    <TableCell align="right">verification</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.operatorName}>
                      <TableCell component="th" scope="row">
                        {historyRow.operatorName}
                      </TableCell>
                      <TableCell>{historyRow.operatorRole}</TableCell>
                      <TableCell align="right">
                        {historyRow.checkInTime}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.checkOutTime}
                      </TableCell>
                      <TableCell align="right">
                        {historyRow.distFromPlant}
                      </TableCell>
                      <TableCell align="right" style={{}}>
                        <a href={historyRow.verification} target="_blank" style={{ textDecoration: "none" }}>Verify</a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
    // </React.Fragment>
  );
}
