//@ts-nocheck
import { useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  List,
  ListItem,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PrimaryRoundedButton } from "../../styles/global/components/dpButtons";
import NextIcon from "@mui/icons-material/ArrowRightAlt";
import EdgeSelection from "./EdgeSelection";
import { usePlantLayoutStore } from "../../store/PlantLayoutStore";

function NodeSelection() {
  const [expanded, setExpanded] = useState("");
  // const [selectedNodes, setSelectedNodes] = useState<any>(null);
  // const [selectedSensors, setSelectedSensors] = useState<any>(null);
  const [loadEdgeSelection, setLoadEdgeSelection] = useState(false);
  const {
    nodes,
    edges,
    selectedNodes,
    setSelectedNodes,
    selectedSensors,
    setSelectedSensors,
  } = usePlantLayoutStore();

  const handleChange = (nodeId) => (event, isExpanded) => {
    setExpanded(isExpanded ? nodeId : "");
  };

  const handleNodeSelection = (event) => {
    const nodeId = event.target.value;
    const checked = event.target.checked;
    let tempSelectedNodes = selectedNodes ? [...selectedNodes] : [];
    if (checked) {
      tempSelectedNodes.push(nodeId);
    } else {
      tempSelectedNodes = tempSelectedNodes.filter(
        (node: any) => node !== nodeId
      );
    }
    setSelectedNodes(tempSelectedNodes);
  };

  const handleSensorSelection = (event, nodeId, sensorName) => {
    const sensorId = event.target.value;
    const checked = event.target.checked;
    const tempSelectedSensors = selectedSensors ? { ...selectedSensors } : {};
    if (checked) {
      if (tempSelectedSensors[nodeId]) {
        tempSelectedSensors[nodeId].push({ sensorId, sensorName });
      } else {
        tempSelectedSensors[nodeId] = [{ sensorId, sensorName }];
      }
    } else {
      tempSelectedSensors[nodeId] = tempSelectedSensors[nodeId].filter(
        (sensor: any) => sensor.sensorId !== sensorId
      );
    }
    setSelectedSensors(tempSelectedSensors);
  };

  const synchronizeSelectedSensors = () => {
    const updatedSelectedSensors = { ...selectedSensors };

    // Add missing keys with empty arrays
    selectedNodes.forEach((nodeId) => {
      if (!updatedSelectedSensors.hasOwnProperty(nodeId)) {
        updatedSelectedSensors[nodeId] = [];
      }
    });

    // Remove keys not present in selectedNodes
    Object.keys(updatedSelectedSensors).forEach((nodeId) => {
      if (!selectedNodes.includes(nodeId)) {
        delete updatedSelectedSensors[nodeId];
      }
    });

    setSelectedSensors(updatedSelectedSensors);
  };

  const onNextClick = () => {
    console.log(selectedNodes, "*****************");
    synchronizeSelectedSensors();
    setLoadEdgeSelection(true);
  };

  return (
    <>
      {!loadEdgeSelection && (
        <div>
          <Typography variant="h6" style={{ marginBottom: "1rem" }}>
            Select Nodes:
          </Typography>
          <div
            style={{
              maxHeight: "calc(100vh - 200px)",
              overflowY: "auto",
              marginBottom: "1rem",
            }}
          >
            {nodes.map((node: any) => (
              <Accordion
                key={node.id}
                expanded={expanded === node.id}
                onChange={handleChange(node.id)}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedNodes.includes(node.id)}
                        value={node.id}
                        onChange={handleNodeSelection}
                      />
                    }
                    label={node.id}
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    <Typography variant="h6">Select Sensors:</Typography>
                    {node.data.configInfo.sensors.map((sensor: any) => (
                      <ListItem key={sensor.sensorId}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                selectedSensors &&
                                selectedSensors[node.id] &&
                                selectedSensors[node.id].find(
                                  (sensorDetail) =>
                                    sensorDetail.sensorId === sensor.sensorId
                                )
                                  ? true
                                  : false
                              }
                              value={sensor.sensorId}
                              onChange={(event) => {
                                handleSensorSelection(
                                  event,
                                  node.id,
                                  sensor.sensorName
                                );
                              }}
                            />
                          }
                          label={sensor.sensorName}
                        />
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <PrimaryRoundedButton
            disabled={!selectedNodes || selectedNodes.length === 0}
            onClick={onNextClick}
          >
            Next
            <NextIcon style={{ justifySelf: "flex-end" }} />
          </PrimaryRoundedButton>
        </div>
      )}
      {loadEdgeSelection && (
        <EdgeSelection
          edges={edges}
          selectedNodes={selectedNodes}
          selectedSensors={selectedSensors}
          loadEdgeSelection={loadEdgeSelection}
          setLoadEdgeSelection={setLoadEdgeSelection}
        />
      )}
    </>
  );
}

export default NodeSelection;
