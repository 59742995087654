import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl,
  TextField,
  Button,
  Typography,
  Stack,
  Box,
  IconButton,
  Checkbox,
} from "@mui/material";
import { updatePlantFeatureAuth } from "../../services/plantFeatureAuth";
import { toast } from "react-toastify";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CloseIconSvg from "../../assets/icons/closeIcon.svg";

function PlantAIConfigDialogBox(props: any) {
  const { open, onClose, featureAuthData, setRefetchData } = props;
  const [plan, setPlan] = useState(
    featureAuthData?.featureList?.[0]?.plantLevelSettings?.paidPlan
      ? "paid"
      : "free"
  );
  const [gptVersion, setGptVersion] = useState(
    featureAuthData?.featureList?.[0]?.plantLevelSettings?.gptModel ?? "gpt-4"
  );
  const [dataPoints, setDataPoints] = useState<number>(3600);
  const [requestAllowed, setRequestAllowed] = useState<number>(
    featureAuthData?.featureList?.[0]?.creditsAvailable
  );

  const [access, setAccess] = useState(false);

  useEffect(() => {
    console.log("insdie console log");
    setPlan(
      featureAuthData?.featureList?.[0]?.featureSettings?.plantLevelSettings
        ?.paidPlan
        ? "paid"
        : "free"
    );
    setGptVersion(
      featureAuthData?.featureList?.[0]?.featureSettings?.plantLevelSettings
        ?.gptModel ?? "gpt-4"
    );
    setRequestAllowed(featureAuthData?.featureList?.[0]?.creditsAvailable);
    setAccess(featureAuthData?.featureList?.[0]?.access ?? false);

    console.log("featureAuthData:", featureAuthData);
  }, [featureAuthData]);

  const handlePlanChange = (event: any) => {
    setPlan(event.target.value);
  };

  const handleGptVersionChange = (event: any) => {
    setGptVersion(event.target.value);
  };

  const handleSave = () => {
    // Save logic here
    console.log({
      plan,
      gptVersion,
      dataPoints,
      requestAllowed,
    });
    const updateObj = {
      $set: {
        "featureList.$[elm].creditsAvailable": requestAllowed,
        "featureList.$[elm].access": access,
        "featureList.$[elm].featureSettings.plantLevelSettings.paidPlan":
          plan === "paid" ? true : false,
        "featureList.$[elm].featureSettings.plantLevelSettings.gptModel":
          gptVersion,
      },
    };
    const options = {
      arrayFilters: [{ "elm.featureName": "AI analysis tool" }],
    };

    updatePlantFeatureAuth(
      featureAuthData.plantId._id,
      updateObj,
      options
    ).then((data) => {
      toast("Settings modified successfully");
      setRefetchData((lastValue: any) => !lastValue);
    });
    onClose(); // Close dialog after saving
  };

  return (
    <Dialog
      open={open && featureAuthData != null}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "12px", boxSizing: "border-box" } }}
    >
      <DialogTitle
        sx={{
          padding: "0px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: "10px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            justifyContent="space-between"
            sx={{ flexGrow: 50 }}
          >
            <Box sx={{ fontSize: "13px", lineHeight: "18px", fontWeight: 400 }}>
              {featureAuthData?.plantId?.plantName}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Box
                sx={{ fontSize: "14px", lineHeight: "24px", fontWeight: 500 }}
              >
                Feature Access
              </Box>
              <Checkbox
                checked={access}
                // value={access}
                onChange={(event) => {
                  setAccess(event.target.checked);
                }}
                icon={
                  <CheckBoxOutlineBlankOutlinedIcon
                    sx={{ width: "18px", height: "18px" }}
                  />
                }
                checkedIcon={
                  <CheckBoxOutlinedIcon
                    sx={{ width: "18px", height: "18px" }}
                  />
                }
              />
            </Box>
          </Stack>
          <Box
            sx={{
              flexGrow: 1,
              display: "grid",
              placeItems: { xs: "end normal", md: "center" },
            }}
          >
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
              sx={{ alignSelf: "flex-start" }}
            >
              <img
                src={CloseIconSvg}
                style={{ width: "12px", height: "12px" }}
              />
            </IconButton>
          </Box>
        </Stack>
      </DialogTitle>
      <hr style={{ width: "100%" }} />
      <DialogContent>
        {/* <Stack direction="column" gap={1} sx={{ width: "100%" }}> */}
        <FormControl
          component="fieldset"
          sx={{ width: "100%" }}
          disabled={!access}
        >
          <Stack
            direction={{ sm: "column", md: "row" }}
            justifyContent="center"
            gap={{ xs: 1, md: 5 }}
            sx={{ width: "100%" }}
          >
            <Box
              sx={{
                fontSize: "14px",
                width: { xs: "95%", md: "45%" },
              }}
            >
              <Typography variant="subtitle1" sx={{ fontSize: "inherit" }}>
                Plan
              </Typography>
              <RadioGroup
                row
                name="plan"
                value={plan}
                onChange={handlePlanChange}
                sx={{
                  fontSize: "inherit",
                  //   display: "flex",
                  gap: { xs: "2px", sm: "20px", md: "1px" },
                }}
              >
                <FormControlLabel
                  value="paid"
                  control={<Radio />}
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{ fontSize: "inherit" }}
                    >
                      Paid
                    </Typography>
                  }
                  sx={{ width: "125px" }}
                />
                <FormControlLabel
                  value="free"
                  control={<Radio />}
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{ fontSize: "inherit" }}
                    >
                      Free
                    </Typography>
                  }
                  sx={{ width: "110px" }}
                />
              </RadioGroup>
            </Box>
            <Box
              sx={{
                fontSize: "14px",
                width: { xs: "95%", md: "45%" },
              }}
            >
              <Typography variant="subtitle1" sx={{ fontSize: "inherit" }}>
                ChatGPT Version
              </Typography>
              <RadioGroup
                row
                name="gptVersion"
                value={gptVersion}
                onChange={handleGptVersionChange}
                sx={{
                  fontSize: "inherit",
                  //   display: "flex",
                  gap: { xs: "2px", sm: "20px", md: "1px" },
                }}
              >
                <FormControlLabel
                  value="gpt-3.5-turbo"
                  control={<Radio />}
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{ fontSize: "inherit" }}
                    >
                      ChatGPT 3.5
                    </Typography>
                  }
                  sx={{ width: "125px" }}
                />
                <FormControlLabel
                  value="gpt-4"
                  control={<Radio />}
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{ fontSize: "inherit" }}
                    >
                      ChatGPT 4
                    </Typography>
                  }
                  sx={{ width: "110px" }}
                />
              </RadioGroup>
            </Box>
          </Stack>
        </FormControl>
        <FormControl
          component="fieldset"
          sx={{ width: "100%", marginTop: "20px" }}
          disabled={!access}
        >
          <Stack
            direction={{ sm: "column", md: "row" }}
            justifyContent="center"
            alignItems="center"
            gap={{ xs: 2, md: 5 }}
            sx={{ width: "100%" }}
          >
            <TextField
              fullWidth
              type="number"
              label="Number of allowed data points"
              value={dataPoints}
              onChange={(e) => setDataPoints(parseInt(e.target.value))}
              // margin="normal"
              disabled
              inputProps={{ style: { fontSize: 14 } }}
              // sx={{ width: { xs: "100%", md: "45%" } }}
            />
            <TextField
              fullWidth
              type="number"
              label="Number of request allowed"
              value={requestAllowed}
              onChange={(e) => setRequestAllowed(parseInt(e.target.value))}
              // margin="normal"
              disabled={!access}
              inputProps={{ style: { fontSize: 14 } }}
              // sx={{ width: { xs: "100%", md: "45%" } }}
            />
          </Stack>
        </FormControl>
        <FormControl
          component="fieldset"
          sx={{ width: "100%", marginTop: "30px" }}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems="center"
            sx={{ width: "100%" }}
            // px={3}
            gap={{ xs: 2, md: 5 }}
          >
            <Button
              variant="outlined"
              onClick={onClose}
              fullWidth
              sx={{
                // flexGrow: 1,
                textTransform: "none",
                fontSize: "14px",
                // width: { xs: "100%", md: "45%" },
                height: "42px",
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={handleSave}
              color="primary"
              fullWidth
              sx={{
                // flexGrow: 1,
                textTransform: "none",
                fontSize: "14px",
                // width: { xs: "100%", md: "45%" },
                height: "42px",
                background: "#3271A9",
              }}
            >
              SAVE
            </Button>
          </Stack>
        </FormControl>
        {/* </Stack> */}
      </DialogContent>
      {/* <DialogActions> */}

      {/* </DialogActions> */}
    </Dialog>
  );
}

export default PlantAIConfigDialogBox;
