import React, { useState } from "react";
import { Dialog, Button } from "@mui/material";
import { ChromePicker, ColorResult } from "react-color";

interface ColorPickerProps {
  initialColor: string;
  open: boolean;
  onClose: () => void;
  onColorChange: (color: string) => void;
}

function ColorPicker({
  initialColor,
  open,
  onClose,
  onColorChange,
}: ColorPickerProps) {
  const [color, setColor] = useState(initialColor);

  const handleColorChange = (newColor: ColorResult) => {
    setColor(newColor.hex);
  };
  //@ts-ignore
  const handleColorInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newColor = event.target.value;
    setColor(newColor);
  };

  const handleColorPickerClose = () => {
    onColorChange(color);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <ChromePicker color={color} onChange={handleColorChange} />
      <Button
        onClick={handleColorPickerClose}
        variant="contained"
        color="primary"
      >
        Save
      </Button>
    </Dialog>
  );
}

export default ColorPicker;
