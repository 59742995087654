import { useState } from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
// import Paper from '@mui/material/Paper';
import { Chart } from "chart.js";
import TableDWidget from "./subComponents/tableDWidget";
import TableCWidget from "./subComponents/tableCWidget";
import GeneralisedGraphWidget from "./subComponents/generalisedGraphWidget";
import zoomPlugin from "chartjs-plugin-zoom";
import { widgetDataInterface } from "../../Interfaces/widgetInterfaces";
// import { widgetStructureInterface } from "../../Interfaces/widgetInterfaces";
// import { timeFrame } from "../../Interfaces/dashboardPageInterfaces";
Chart.register(zoomPlugin);

const styles = {
  popupContainerForExGenWidget: {
    position: "fixed" as "fixed",
    background: "#00000050",
    width: "100%",
    height: "100vh",
    top: "0",
    left: "0",
    zIndex: "1500",
  },
  popupBoxForExGenWIdget: {
    position: "relative" as "relative",
    width: "90%",
    margin: "0 auto",
    height: "70vh",
    maxHeight: "70vh",
    marginTop: "calc(100vh - 85vh - 20px)",
    borderRadius: "4px",
    overflow: "auto",
    overflowY: "scroll" as "scroll",
  },
  widgetcontainer: {
    width: "100%",
    height: "100%",
  },
};

// interface generalisedExpandedWidgetProps {
//   widgetDataForGenWidget: widgetDataInterface | null;
//   widgetStructure: widgetStructureInterface;
//   timeFrame: timeFrame | null;
//   refresh: boolean;
//   setIsOpen: Dispatch<SetStateAction<boolean>>;
//   showLinkIcon?: boolean;
//   linkOnWidget?: string;
// }

export default function GeneralisedExpandedWidget(props: any) {
  const {
    widgetDataForGenWidget,
    // widgetStructure,
    refresh,
    setIsOpen,
    showLinkIcon,
    linkOnWidget,
  } = props;
  const [widgetData, setWidgetData] = useState<widgetDataInterface | null>(
    null
  );
  //@ts-ignore
  const [loading, setLoading] = useState(false);
  async function getWidgetData() {
    setWidgetData(widgetDataForGenWidget);
  }
  useEffect(() => {
    // call the api
    getWidgetData();
  }, [refresh]);

  return (
    <div style={styles.popupContainerForExGenWidget}>
      <div style={styles.popupBoxForExGenWIdget}>
        <Box sx={styles.widgetcontainer}>
          {widgetData !== null && widgetData.widgetType === "graph" ? (
            <GeneralisedGraphWidget
              widgetStructure={widgetData}
              widgetData={widgetDataForGenWidget}
              setExpandedViewPopup={setIsOpen}
              isLoading={loading}
              headerType="expanded"
              enableZoom={true}
              viewEveryNthTick={1}
              ticksColor="dark"
              showLinkIcon={showLinkIcon}
              linkOnWidget={linkOnWidget}
            />
          ) : null}

          {widgetData !== null && widgetData.widgetType === "tableC" ? (
            <TableCWidget
              widgetStructure={widgetData}
              widgetData={widgetData}
              setExpandedViewPopup={setIsOpen}
              isLoading={loading}
              headerType="expanded"
              showLinkIcon={showLinkIcon}
              linkOnWidget={linkOnWidget}
            />
          ) : null}
          {widgetData !== null && widgetData.widgetType === "tableD" ? (
            <TableDWidget
              widgetStructure={widgetData}
              widgetData={widgetData}
              setExpandedViewPopup={setIsOpen}
              isLoading={loading}
              headerType="expanded"
              showLinkIcon={showLinkIcon}
              linkOnWidget={linkOnWidget}
            />
          ) : null}
        </Box>
      </div>
    </div>
  );
}
