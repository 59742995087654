import { Box, Dialog } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import {
  DialogNegativeButtonStyle,
  DialogPositiveButtonStyle,
} from "./AlertsStyles";
import TaskCardView from "./TaskCardView";
import { useEffect, useState } from "react";

function AlertForm({
  showAlertForm,
  closeMethod,
  alertData,
  alertIndex,
  equipments,
  userList,
  assignRole,
  escaltionOptions,
  sensorList,
  configVarList,
  boolSensorTagList,
  saveAlert,
}: any) {
  const [alertDataClone, setAlertDataClone] = useState<any>();
  const [edited, setEdited] = useState(false);
  useEffect(() => {
    setAlertDataClone(alertData);
  }, [alertData]);
  useEffect(() => {}, [edited]);
  const handleCheckedCritical = (value: boolean) => {
    alertDataClone["emergency"] = value;
    setAlertDataClone(alertDataClone);

    setEdited(!edited);
  };
  const updateDirectValue = (key: string, value: any) => {
    alertDataClone[key] = value;
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };
  const UpdateTask = (section: string, valuefor: string, value: string) => {
    if (alertDataClone[section]) {
      alertDataClone[section][valuefor] = value;
    } else {
      let objProperty = {
        [valuefor]: value,
      };
      alertDataClone[section] = objProperty;
    }
    setAlertDataClone(alertDataClone);

    setEdited(!edited);
  };

  const updateCCUserArrayVals = (newValue: any) => {
    alertDataClone["ccUsers"] = newValue;
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };
  const updatePrimaryUser = (userId: string) => {
    let newPrimaryUser;
    for (const user of userList) {
      if (user["_id"] === userId) {
        newPrimaryUser = user;
      }
    }
    alertDataClone["primaryUser"] = newPrimaryUser;
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };

  const UpdateEscaltionLevel = (
    escalation: string,
    key: string,
    value: string
  ) => {
    alertDataClone["escalations"][escalation][key] = value;
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };

  const addnewSubtask = () => {
    alertDataClone["subTasks"].push({
      name: "Enter Subtask",
      namehindi: "सबटास्क दर्ज करें",
    });
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };
  const deleteSubTask = (indexofSubTask: number) => {
    alertDataClone["subTasks"].splice(indexofSubTask, 1);
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };

  const UpdateSubTask = (subTaskIndex: number, key: string, value: string) => {
    alertDataClone["subTasks"][subTaskIndex][key] = value;
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };
  const updateCondition = (
    alertIndex: number,
    conditionSetIndex: number,
    statementIndex: number,
    updateKey: string,
    updateValue: any,
    conditionType: string
  ) => {
    alertDataClone[conditionType][conditionSetIndex][statementIndex][
      updateKey
    ] = updateValue;
    if (updateKey === "thresholdType") {
      alertDataClone[conditionType][conditionSetIndex][statementIndex][
        "threshold"
      ] = 0;
    }
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };

  const deleteAlertStatement = (
    alertIndex: number,
    conditionSetIndex: number,
    statementIndex: number,
    conditionType: string
  ) => {
    alertDataClone[conditionType][conditionSetIndex].splice(statementIndex, 1);
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };
  function deleteConditionSet(
    alertIndex: number,
    conditionSetIndex: number,
    conditionType: string
  ) {
    alertDataClone[conditionType].splice(conditionSetIndex, 1);
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  }
  const addAlertStatement = (
    alertIndex: number,
    conditionSetIndex: number,
    conditionType: string
  ) => {
    alertDataClone[conditionType][conditionSetIndex].push({
      operator: "",
      sensorTag: "",
      threshold: null,
      thresholdType: "numeric",
    });
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };
  const addConditionSet = (alertIndex: number, conditionType: string) => {
    alertDataClone[conditionType].push([]);
    setAlertDataClone(alertDataClone);
    setEdited(!edited);
  };
  return (
    <Dialog
      open={showAlertForm}
      fullWidth
      maxWidth="md"
      onClose={() => {
        closeMethod();
        // setShowProcessingResult(false);
      }}
    >
      <>
        <DialogContent>
          <Box>
            <TaskCardView
              task={alertDataClone}
              indexTask={alertIndex}
              equipments={equipments}
              userList={userList}
              assignRole={assignRole}
              escaltionOptions={escaltionOptions}
              handleCheckedCritical={handleCheckedCritical}
              updateDirectValue={updateDirectValue}
              UpdateTask={UpdateTask}
              updatePrimaryUser={updatePrimaryUser}
              updateCCUserArrayVals={updateCCUserArrayVals}
              UpdateEscaltionLevel={UpdateEscaltionLevel}
              addnewSubtask={addnewSubtask}
              deleteSubTask={deleteSubTask}
              UpdateSubTask={UpdateSubTask}
              sensorList={sensorList}
              configVarList={configVarList}
              updateCondition={updateCondition}
              deleteAlertStatement={deleteAlertStatement}
              deleteConditionSet={deleteConditionSet}
              addAlertStatement={addAlertStatement}
              addConditionSet={addConditionSet}
              boolSensorTagList={boolSensorTagList}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <DialogNegativeButtonStyle
            onClick={() => {
              closeMethod();
            }}
          >
            Cancel
          </DialogNegativeButtonStyle>
          <DialogPositiveButtonStyle
            onClick={async () => {
              saveAlert(alertDataClone);
            }}
          >
            Confirm
          </DialogPositiveButtonStyle>
        </DialogActions>
      </>
    </Dialog>
  );
}

export default AlertForm;
