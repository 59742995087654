//@ts-nocheck
import { useEffect, useState } from "react";
import { Button, Box, TextField } from "@mui/material";
import "./styles.css";
import Autocomplete from "@mui/lab/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import axiosInstance from "../../constants/axiosInstance";
// import { WorkForceInterface } from "../../Interfaces/workForceInterface";
import Switch from "@mui/material/Switch";
import { plantInterfaceForGetAllPlantsApi } from "../../Interfaces/plantInterfaces";
import {
  userRoleBasedData,
  userData,
  newUserInterface,
} from "../../Interfaces/userInterface";
import {
  getAllUsersForRole,
  getAllUsersForRoleBasedOnConfigurorId,
  getUser,
  updateUser,
} from "../../services/users";
import { getAllPlants } from "../../services/plant";

interface addUserPopup {
  userId: string;
  forWho: string;
  edit: boolean;
  handleClose: Function;
  refresh: Function;
}
interface plantInfoInterface {
  id: string;
  plantName: string;
}

export default function AddWorkForcePopup(props: addUserPopup) {
  const { handleClose, refresh, edit, userId, forWho } = props;
  const [newUserData, setnewUserData] = useState<userData | newUserInterface>({
    forWho: forWho,
    plantId: [],
    name: "",
    number: "",
    email: "",
  });
  const [refreshs, setRefreshs] = useState<boolean>(false);
  const [options, setOptionData] = useState<userRoleBasedData[] | []>([]);
  const [emailreq, setEmailReq] = useState<boolean>(true);
  const [userStatus, setUserStatus] = useState<string>("active");

  const [plants, setPlants] = useState<plantInfoInterface[] | []>([]);
  const [existingPlants, setExistingPlants] = useState<
    plantInfoInterface[] | []
  >([]);

  const roles = [
    "Operator",
    "Rotating Operator",
    "Maintenance In-Charge",
    "Site Supervisor",
    "Service Head",
    "Procurer",
    "Building Level Client",
    "Senior Client",
  ];

  let newUserDataCopy: userData;
  let plantsCopy: plantInfoInterface[];

  useEffect(() => {
    console.log("edit value : ", edit);
    console.log("hey i am here");

    fetchPlants();
    if (edit) {
      fetchUserInfo();
    }
    if (forWho === "Client") {
      fetchClients();
    } else {
      fetchserviceProvider();
    }
  }, []);

  const fetchPlants = () => {
    getAllPlants().then((plants: plantInterfaceForGetAllPlantsApi[]) => {
      console.log("data-getallplants:", plants);
      let plantInfo: plantInfoInterface[] = plants.map((plant) => {
        return {
          id: plant.id,
          plantName: plant.plantName,
        };
      });
      setPlants(plantInfo);
      // setRefreshs(!refreshs);
      plantsCopy = plantInfo;
    });
  };

  const fetchClients = () => {
    let userId: string = localStorage.getItem("userId") || "";
    getAllUsersForRoleBasedOnConfigurorId("Client", userId).then(
      (users: userRoleBasedData[]) => {
        console.log("client", users);
        setOptionData(users);
      }
    );
  };

  // Do not touch this function unless you want to rewrite whole page
  const fncExistingPlants = () => {
    console.log("in fncExistingPlants");

    console.log(newUserData);
    console.log(plants);
    let plantInfo: plantInfoInterface[] = [];
    if (newUserData.plantId!.length > 0) {
      plantInfo =
        plants.filter((plantOption) => {
          let match = newUserData.plantId.find((id) => id === plantOption.id);
          // plantName.push(match);
          if (match) return true;
          else return false;
        }) || [];
    } else {
      if (edit) {
        plantInfo =
          plants?.filter((plantOption) => {
            console.log(plantOption.id);
            const match = newUserData?.plantId.find(
              (id) => id === plantOption.id
            );
            // plantName.push(match);
            return match!;
          }) || [];
      }
    }
    console.log("plantName", plantInfo);
    return plantInfo;
  };

  const fetchserviceProvider = () => {
    getAllUsersForRole("Service Provider").then(
      (users: userRoleBasedData[]) => {
        console.log("Service Provider", users);
        setOptionData(users);
      }
    );
  };

  const fetchUserInfo = () => {
    getUser(userId)
      .then((user: userData) => {
        console.log("user data : ", user);
        setnewUserData(user);
        newUserDataCopy = user;
        if (user.role === "Operator") setEmailReq(false);
        // setRefreshs(!refreshs);
      })
      .catch();
  };

  const handleChanges = (name: string, value: any) => {
    console.log("handleChanges value:", value);

    let data = newUserData;
    if (name === "role") {
      data[name] = value;
      if (value === "Operator") {
        setEmailReq(false);
      } else {
        setEmailReq(true);
      }
    } else if (name === "plantId") {
      let plantIds: string[] = value.map((plant: plantInfoInterface) => {
        return plant.id;
      });

      data[name] = plantIds;
    } else if (name === "userStatus") {
      // console.log("User status : " ,  value );
      setUserStatus(value);
      if (value) data[name] = "active";
      else data[name] = "inactive";
    } else {
      data[name] = value;
    }

    setnewUserData(data);
    fncExistingPlants();
    setRefreshs(!refreshs);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log("newUserData : ", newUserData);
    console.log("edit : ", edit);
    if (edit) {
      updateUser(newUserData)
        .then((data) => {
          if (data.data.type === "error") {
            toast.error(data.data.message);
          } else if (data.data.type === "success") {
            toast.success(data.data.message);
          } else {
            toast(data.data.message);
          }
          refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axiosInstance()
        .post("/users/createWorkForce", newUserData)
        .then(({ data }) => {
          toast(data.message);
          refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const returnSelected = (
    options: userRoleBasedData[],
    user: userData | newUserInterface
  ) => {
    let client = {};
    let choices = options.filter((option) => {
      return option.id === user.forid;
    });
    console.log("choice", choices);
    if (choices[0]) client = choices[0];
    return client!;
  };

  return (
    <div className="popup-box">
      <div className="box">
        <span
          className="close-icon"
          onClick={() => {
            handleClose();
          }}
        >
          x
        </span>
        <form onSubmit={handleSubmit}>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newUserData.name}
              label="Name"
              onChange={(e) => {
                handleChanges("name", e.target.value);
              }}
            />
          </Box>
          <Box m={1} p={1}>
            <TextField
              fullWidth
              required
              value={newUserData.number}
              label="Number"
              onChange={(e) => {
                handleChanges("number", e.target.value);
              }}
            />
          </Box>
          <Box m={1} p={1}>
            {emailreq ? (
              <TextField
                required
                fullWidth
                value={newUserData.email}
                label="Email"
                onChange={(e) => {
                  handleChanges("email", e.target.value);
                }}
              />
            ) : (
              <TextField
                fullWidth
                value={newUserData.email || null}
                label="Email"
                onChange={(e) => {
                  handleChanges("email", e.target.value);
                }}
              />
            )}
          </Box>

          <Box m={1} p={1}>
            <Autocomplete
              id="tags-standard-1"
              options={options}
              getOptionLabel={(option: userRoleBasedData) => option.name || ""}
              // defaultValue={returnSelected(options, newUserData) || null}
              value={returnSelected(options, newUserData) || null}
              onChange={(event, newValue) => {
                let id = "";

                console.log(newValue);
                if (newValue && newValue.id) {
                  id = newValue.id;
                }

                handleChanges("forid", id);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label={forWho}
                />
              )}
            />
          </Box>

          <Box m={1} p={1}>
            <Autocomplete
              id="tags-standard-2"
              options={roles}
              value={newUserData.role || null}
              onChange={(event, newValue) => {
                handleChanges("role", newValue);
              }}
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  variant="standard"
                  label="Role"
                />
              )}
            />
          </Box>

          <Box m={1} p={1}>
            <Autocomplete
              multiple
              id="tags-standard-3"
              options={plants}
              getOptionLabel={(option: plantInfoInterface) =>
                option?.plantName || ""
              }
              // value={existingPlants}
              value={fncExistingPlants()}
              onChange={(event, newValue) => {
                handleChanges("plantId", newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Plants" />
              )}
            />
          </Box>

          {newUserData.userStatus ? (
            <Box m={1} p={1}>
              User Status :{" "}
              <Switch
                checked={newUserData.userStatus === "active" ? true : false}
                onChange={(event) => {
                  handleChanges("userStatus", event.target.checked);
                }}
              />
            </Box>
          ) : (
            <Box m={1} p={1}>
              User Status :{" "}
              <Switch
                checked={true}
                onChange={(event) => {
                  handleChanges("userStatus", event.target.checked);
                }}
              />
            </Box>
          )}

          <Box m={1} p={1}>
            <Button variant="contained" type="submit">
              {edit ? <>Update</> : <>Create User</>}
            </Button>
          </Box>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
