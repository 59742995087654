//@ts-nocheck
import React from "react";
import { useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import axiosInstance from "../../constants/axiosInstance";

import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import Autocomplete from "@mui/material/Autocomplete";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "@mui/material/Modal";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import { toast } from "react-toastify";
import TableRowsIcon from "@mui/icons-material/TableRows";
import PinIcon from "@mui/icons-material/Pin";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import MetricConfigComponent from "../metricConfigComponent/metricConfigComponent";
import { pageConfigDataInterface } from "../../Interfaces/dashboardPageInterfaces";
import { widgetInterface } from "../../Interfaces/widgetInterfaces";
import {
  lastMovedWidgetInterface,
  metricDiagnosisDataInterface,
} from "../../Interfaces/pageConfigPageInterfaces";

const styles = {
  widgetConfigComponentAccordion: {
    margin: "10px",
    maxHeight: "500px",
    overflowY: "scroll",
    background: "#F0F0F0",
    borderRadius: "10px",
  },
  widgetConfigSummaryContainer: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    width: "100%",
  },
  widgetConfigSummaryActionButtonContainer: {
    display: "flex",
    gap: "10px",
    marginLeft: "auto",
    color: "#444",
  },
  textBoxStyle: {
    width: "300px",
  },

  metricDiagnosisLoading: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#eee",
    width: "400px",
    minHeight: "100px",
    borderRadius: "5px",
  },
  metricDiagnosisNotOk: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#FFCCCB",
    width: "400px",
    minHeight: "100px",
    borderRadius: "5px",
  },
  metricDiagnosisOk: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "#addfad",
    width: "400px",
    minHeight: "100px",
    borderRadius: "5px",
  },
};

interface propsInterface {
  pageData: pageConfigDataInterface;
  setPageData: React.Dispatch<
    React.SetStateAction<pageConfigDataInterface | null>
  >;
  widgetCluster: string;
  widget: widgetInterface;
  widgetIndex: number;
  moveWidgetPosition: (
    widgetCluster: string,
    widgetIndex: number,
    action: string
  ) => void;
  lastMovedWidget: lastMovedWidgetInterface;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function WidgetConfigComponent(props: propsInterface) {
  const {
    pageData,
    setPageData,
    widgetCluster,
    widget,
    widgetIndex,
    moveWidgetPosition,
    lastMovedWidget,
    refresh,
    setRefresh,
  } = props;
  const valueTypes = [
    "raw",
    "avg",
    "min",
    "max",
    "timeWeightedSum",
    "cumulative",
  ];
  const [search, setSearch] = useState("");
  const [openDiagnosisBox, setOpenDiagnosisBox] = useState(false);
  const [diagnosing, setDiagnosing] = useState(false);
  const [metricDiagnosisData, setMetricDiagnosisData] =
    useState<metricDiagnosisDataInterface | null>(null);

  // useEffect(() => {
  //   console.log("widget name ", widget.widgetName);
  // }, []);

  const changeWidgetProperty = (
    widgetCluster: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    console.log(widgetCluster, widgetIndex, "******************");
    const temp: pageConfigDataInterface = pageData;
    temp.data[widgetCluster].widgets[widgetIndex][property] = value;
    setPageData(temp);
    setRefresh(!refresh);
  };

  const changeAdvancedWidgetProperty = (
    widgetCluster: string,
    widgetIndex: number,
    property: string,
    value: any
  ) => {
    const temp: pageConfigDataInterface = pageData;
    temp.data[widgetCluster].widgets[widgetIndex]["advancedWidgetSettings"][
      property
    ] = value;
    setPageData(temp);
    setRefresh(!refresh);
  };

  const deleteCustomWidget = (widgetCluster: string, widgetIndex: number) => {
    const widgetToBeDeleted = pageData.data[widgetCluster].widgets[widgetIndex];
    let widgetName = widgetToBeDeleted.widgetName;
    pageData.data[widgetCluster].widgets.splice(widgetIndex, 1);

    setRefresh(!refresh);
    toast(`${widgetName} deleted Successfully!`);
  };

  const initiateMetricDiagnosis = (metricId: string) => {
    setOpenDiagnosisBox(true);
    setDiagnosing(true);
    axiosInstance()
      .get(`/dashboard/getMetricDiagnosis?sensorId=${metricId}`)
      .then(({ data }) => {
        console.log("metric diagnostic data : ", data);
        setMetricDiagnosisData(data.data);
        setDiagnosing(false);
      })
      .catch((err) => {});
  };

  return (
    <>
      <Accordion
        sx={styles.widgetConfigComponentAccordion}
        elevation={0}
        TransitionProps={{ mountOnEnter: true, timeout: 500 }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div style={styles.widgetConfigSummaryContainer}>
            <Checkbox
              checked={widget.checked}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                e.stopPropagation();
              }}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                changeWidgetProperty(
                  widgetCluster,
                  widgetIndex,
                  "checked",
                  e.target.checked
                );
              }}
            />
            <span>
              {widget.widgetOrigin == "master"
                ? widget.widgetName
                : widget.widgetNickName}
            </span>
            <span style={styles.widgetConfigSummaryActionButtonContainer}>
              <ArrowCircleUpIcon
                fontSize="medium"
                onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                  e.stopPropagation();
                  moveWidgetPosition(widgetCluster, widgetIndex, "up");
                }}
                style={
                  widget.widgetName == lastMovedWidget.widgetName &&
                  lastMovedWidget.direction == "up"
                    ? { color: "#018749" }
                    : {}
                }
              />
              <ArrowCircleDownIcon
                fontSize="medium"
                onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
                  e.stopPropagation();
                  moveWidgetPosition(widgetCluster, widgetIndex, "down");
                }}
                style={
                  widget.widgetName == lastMovedWidget.widgetName &&
                  lastMovedWidget.direction == "down"
                    ? { color: "#de1738" }
                    : {}
                }
              />

              {widget.widgetOrigin == "custom" && (
                <DeleteIcon
                  fontSize="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    deleteCustomWidget(widgetCluster, widgetIndex);
                  }}
                />
              )}
            </span>
            <span style={{}}>
              {(widget.widgetType == "tableD" ||
                widget.widgetType == "tableC") && (
                <TableRowsIcon fontSize="medium" />
              )}
              {widget.widgetType == "graph" && (
                <AssessmentIcon fontSize="medium" />
              )}
              {widget.widgetType == "number" && <PinIcon fontSize="medium" />}
            </span>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <FormLabel component="legend">
                * select that sensors that you want to track
              </FormLabel>
              <TextField
                type="text"
                value={search}
                variant="outlined"
                placeholder="filter sensor list"
                label="filter"
                inputProps={{
                  maxLength: 50,
                  step: "0.01",
                }}
                style={{ width: "300px", marginTop: "10px" }}
                onChange={(
                  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => {
                  setSearch(e.target.value);
                }}
              />
              {Object.keys(widget.metrics).map((metric, metricIndex) => {
                let metricName = widget.metrics[metric].nickName
                  ? widget.metrics[metric].nickName
                  : metric;
                if (metricName?.toLowerCase().includes(search.toLowerCase()))
                  return (
                    <MetricConfigComponent
                      key={metricName + metricIndex}
                      pageData={pageData}
                      setPageData={setPageData}
                      widgetCluster={widgetCluster}
                      widget={widget}
                      widgetIndex={widgetIndex}
                      metric={metric}
                      initiateMetricDiagnosis={initiateMetricDiagnosis}
                      refresh={refresh}
                      setRefresh={setRefresh}
                    />
                  ); //// metric config component
              })}
            </Grid>
            <Grid item direction="column" xs={12} sm={4} md={4} lg={4}>
              <Box m={1} p={1}>
                <TextField
                  type="text"
                  defaultValue={
                    widget.widgetNickName ? widget.widgetNickName : ""
                  }
                  variant="outlined"
                  label="Nick Name"
                  multiline
                  maxRows={1}
                  inputProps={{
                    maxLength: 50,
                    step: "0.01",
                  }}
                  style={{ width: "300px" }}
                  onBlur={(e) => {
                    changeWidgetProperty(
                      widgetCluster,
                      widgetIndex,
                      "widgetNickName",
                      e.target.value
                    );
                  }}
                />
              </Box>
              <Box m={1} p={1}>
                <TextField
                  type="text"
                  defaultValue={widget.widgetDescription}
                  variant="outlined"
                  label="description"
                  placeholder="widget description"
                  multiline
                  rows={4}
                  style={{ width: "300px" }}
                  onBlur={(e) => {
                    changeWidgetProperty(
                      widgetCluster,
                      widgetIndex,
                      "widgetDescription",
                      e.target.value
                    );
                  }}
                />
              </Box>
              <Box m={1} p={1}>
                {/* <FormLabel style={{marginTop : "20px"}} component="legend">* Add a link to this widget</FormLabel> */}
                <TextField
                  type="text"
                  defaultValue={widget.moreInfo}
                  variant="outlined"
                  label="link"
                  placeholder="add a link for see more button"
                  multiline
                  inputProps={{
                    step: "0.01",
                    rows: 1,
                  }}
                  style={{ width: "300px" }}
                  onBlur={(e) => {
                    changeWidgetProperty(
                      widgetCluster,
                      widgetIndex,
                      "moreInfo",
                      e.target.value
                    );
                  }}
                />
              </Box>
              <Box m={1} p={1} style={{ width: "300px" }}>
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    // expandIcon={<ExpandMoreIcon />}
                    expandIcon={<SettingsIcon fontSize="small" />}
                  >
                    Advanced Widget Settings
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      type="number"
                      defaultValue={widget.minimumGranularity}
                      variant="outlined"
                      label="Minimum Granularity In Mins"
                      placeholder=""
                      fullWidth
                      InputProps={{ inputProps: { min: 1 } }}
                      onBlur={(
                        e: React.FocusEvent<
                          HTMLInputElement | HTMLTextAreaElement,
                          Element
                        >
                      ) => {
                        changeWidgetProperty(
                          widgetCluster,
                          widgetIndex,
                          "minimumGranularity",
                          e.target.value
                        );
                      }}
                    />
                    <Box m={1}>
                      <FormLabel
                        style={{ marginBottom: "10px" }}
                        component="legend"
                      >
                        <Checkbox
                          checked={
                            widget.advancedWidgetSettings?.applyCustomSettings
                          }
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            changeAdvancedWidgetProperty(
                              widgetCluster,
                              widgetIndex,
                              "applyCustomSettings",
                              e.target.checked
                            );
                          }}
                        />
                        Apply Custom Settings
                      </FormLabel>
                    </Box>
                    <TextField
                      type="number"
                      defaultValue={
                        widget.advancedWidgetSettings?.customGranularityInMins
                      }
                      variant="outlined"
                      label="Custom Granularity In Mins"
                      placeholder=""
                      fullWidth
                      InputProps={{ inputProps: { min: 1 } }}
                      onBlur={(
                        e: React.FocusEvent<
                          HTMLInputElement | HTMLTextAreaElement,
                          Element
                        >
                      ) => {
                        changeAdvancedWidgetProperty(
                          widgetCluster,
                          widgetIndex,
                          "customGranularityInMins",
                          e.target.value
                        );
                      }}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <Box m={1}>
                <FormLabel style={{ marginBottom: "10px" }} component="legend">
                  <Checkbox
                    checked={widget.allowMetricLevelCustomisation}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      changeWidgetProperty(
                        widgetCluster,
                        widgetIndex,
                        "allowMetricLevelCustomisation",
                        e.target.checked
                      );
                    }}
                  />
                  Respect Metric Level Customisation
                </FormLabel>
              </Box>
              <Box m={1}>
                <FormLabel style={{ marginBottom: "10px" }} component="legend">
                  * select the type of value you want to see
                </FormLabel>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={
                    widget.allowedValueTypes
                      ? widget.allowedValueTypes
                      : valueTypes
                  }
                  // getOptionLabel={(option) => option.title}
                  // defaultValue={ [valueTypes[0]] }
                  filterSelectedOptions
                  value={widget.valueTypes}
                  onChange={(e, newValue) => {
                    changeWidgetProperty(
                      widgetCluster,
                      widgetIndex,
                      "valueTypes",
                      newValue
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="value types you wanna see"
                      placeholder=""
                    />
                  )}
                />
              </Box>
              <Box m={1}>
                <FormControl fullWidth>
                  <FormLabel
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    component="legend"
                  >
                    * Select the type of Widget you want to see
                  </FormLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={widget.widgetType}
                    onChange={(e) => {
                      changeWidgetProperty(
                        widgetCluster,
                        widgetIndex,
                        "widgetType",
                        e.target.value
                      );
                    }}
                  >
                    <MenuItem value={"graph"}>graph</MenuItem>
                    <MenuItem value={"number"}>Number</MenuItem>
                    <MenuItem value={"tableD"}>
                      Table(developer version)
                    </MenuItem>
                    <MenuItem value={"tableC"}>Table</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              {widget.widgetType === "graph" ? (
                <Box m={1}>
                  <FormControl fullWidth>
                    <FormLabel
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      component="legend"
                    >
                      * Select the type of graph you want to see
                    </FormLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={widget.graphType}
                      onChange={(e: SelectChangeEvent) => {
                        changeWidgetProperty(
                          widgetCluster,
                          widgetIndex,
                          "graphType",
                          e.target.value
                        );
                      }}
                    >
                      <MenuItem value={"bar"}>Bar</MenuItem>
                      <MenuItem value={"line"}>Line</MenuItem>
                      <MenuItem value={"scatter"}>Scatter</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Modal
        open={openDiagnosisBox}
        onClose={() => {
          setOpenDiagnosisBox(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {diagnosing ? (
          <Box p={1} sx={styles.metricDiagnosisLoading}>
            <p style={{ textAlign: "center" }}>running sensor diagnosis.... </p>
          </Box>
        ) : (
          <Box
            p={1}
            sx={
              metricDiagnosisData && metricDiagnosisData?.problems?.length > 0
                ? styles.metricDiagnosisNotOk
                : styles.metricDiagnosisOk
            }
          >
            <Box>
              <h3>Sensor Diagnosis completed</h3>
              <p>
                sensor exists in Db :{" "}
                {metricDiagnosisData?.sensorExists ? "YES" : "No"}
              </p>
              <p>sensorId : {metricDiagnosisData?.sensorId}</p>
              <p>created on :{metricDiagnosisData?.dateCreated}</p>
              <p>sensorType : {metricDiagnosisData?.sensorType}</p>
              <p>last observed Value : {metricDiagnosisData?.lastValue}</p>
              <p>last Observed time : {metricDiagnosisData?.lastValueTime}</p>
              <p>
                problem Diagnosis -
                {metricDiagnosisData &&
                metricDiagnosisData?.problems?.length > 0 ? (
                  metricDiagnosisData?.problems?.map(
                    (problem: string, index: number) => {
                      return <p>{index + 1 + ". " + problem}</p>;
                    }
                  )
                ) : (
                  <span>No problems found, sensor Ok</span>
                )}
              </p>
            </Box>
          </Box>
        )}
      </Modal>
    </>
  );
}
