import {
  AppBar,
  ThemeProvider,
  Typography,
  Paper,
  Stack,
  CircularProgress,
} from "@mui/material";
import dpTheme from "../../styles/theme/dpTheme";
import {
  DialogNegativeButtonStyle,
  PrimaryButton,
} from "../../styles/global/components/dpButtons";
import {
  saveDashboardData,
  saveWidgetData,
} from "../../services/dashboardPage";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const BottomBarStyles = {
  AppBarStyles: {
    top: "auto",
    left: "50%",
    transform: "translateX(-50%)", // Center the bar horizontally
    bottom: 10,
    background: "#F0FCFF",
    zIndex: 9999,
    width: "85%",
    height: "56px",
    borderRadius: "5px",
    justifyContent: "center",
  },
  ButtonStyles: { width: "140px", height: "36px", borderRadius: "5px" },
};

// interface BottomBarProps {
//   isBottomBarOpen: boolean;
//   setIsBottomBarOpen: Function;
//   data: any;
// }

export default function BottomBar({
  isBottomBarOpen,
  setIsBottomBarOpen,
  data,
  parent,
  dashboardSaveData,
  setIsEditDialogOpen,
}: any) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("parent2 : ", parent);
  }, []);

  const handleOnSaveClick = async () => {
    setLoading(true);
    console.log("parent3 : ", parent);
    if (parent === "widget") {
      const data2 = data[data.length - 1];

      const resp = await saveWidgetData(data2);
      if (resp.updateStatus === "Success") {
        toast.success("Saved Widget Successfully !");
      } else {
        toast.error("Error in saving !");
      }
    } else {
      const resp = await saveDashboardData(dashboardSaveData);
      if (resp.updateStatus === "Success") {
        toast.success("Saved Dashboard Successfully !");
      } else {
        toast.error("Error in saving !");
      }
    }
    setLoading(false);
    setIsBottomBarOpen(false);
  };

  const handleSaveAsClick = async () => {
    setIsEditDialogOpen(true);
    setIsBottomBarOpen(false);
    setLoading(false);
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <Paper elevation={1}>
        <AppBar position="fixed" sx={BottomBarStyles.AppBarStyles}>
          <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            mt={0}
            mb={0}
            ml={3}
            mr={3}
          >
            <Typography variant="h5" color="secondary">
              Would you like to save the current view ?
            </Typography>
            <Stack direction="row" columnGap={2}>
              <DialogNegativeButtonStyle
                onClick={() => {
                  setIsBottomBarOpen(false);
                }}
                sx={BottomBarStyles.ButtonStyles}
              >
                <Typography variant="subtitle2">Cancel</Typography>
              </DialogNegativeButtonStyle>

              <PrimaryButton
                sx={BottomBarStyles.ButtonStyles}
                onClick={handleOnSaveClick}
              >
                <Typography variant="subtitle2">Save</Typography>
                {loading ? (
                  <CircularProgress
                    style={{ marginLeft: "5px", color: "white" }}
                    size={20}
                  />
                ) : null}
              </PrimaryButton>
              <PrimaryButton
                sx={BottomBarStyles.ButtonStyles}
                onClick={handleSaveAsClick}
              >
                <Typography variant="subtitle2">Save As</Typography>
              </PrimaryButton>
            </Stack>
          </Stack>
        </AppBar>
      </Paper>
    </ThemeProvider>
  );
}
