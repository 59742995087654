//@ts-nocheck
import { useState, useEffect } from 'react';
import {
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  ThemeProvider,
  Typography,
  Tooltip,
  DialogContentText,
  Select,
  FormControl,
  InputLabel,
  Stack,
  MenuItem,
  FormHelperText,
  Button,
} from '@mui/material';
import dpTheme from '../../styles/theme/dpTheme';
import {
  DialogPositiveButtonStyle,
  DialogNegativeButtonStyle,
  SecondaryButton,
  DialogSuccessButtonStyle,
} from '../../styles/global/components/dpButtons';
import FailedPopup from './FailedPopup';
import CloseIcon from '@mui/icons-material/Close';
import ConfirmationDialog from './ConfirmationDialog';
import { RELATIVE_TIME_LIST } from '../../constants/constants';
import Android12Switch from '../../styles/global/components/dpSwitch';
import classes from './BiDirectional.module.css';
import {
  initiateControl,
  checkOngoingControlForSensor,
  terminateControl,
} from '../../services/BiDirectional';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { usePlantLayoutStore } from '../../store/PlantLayoutStore';
import moment from 'moment';
interface IProps {
  showControlPrompt: boolean;
  setShowControlPrompt: Function;
  existingStatus: boolean;
  equipmentName: string;
  sensorId: string;
}

const ControlPrompt = ({
  showControlPrompt,
  setShowControlPrompt,
  existingStatus,
  equipmentName,
  sensorId,
}: IProps) => {
  const [input, setInput] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<string>('30 Mins');
  const [errorText, setErrorText] = useState('');
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [isRecontrolState, setIsRecontrolState] = useState<boolean>(false);
  const [controlId, setControlId] = useState('');
  const [ongoingControlId, setOngoingControlId] = useState('');
  const [showFailedPopup, setShowFailedPopup] = useState<boolean>(false);
  const [failedPopupMsg, setFailedPopupMsg] = useState<string>('');
  // const { plantId } = useParams();
  const { assetId } = useParams();
  const plantId = assetId;

  console.log('assetId : ', assetId, plantId);
  const userId: string = localStorage.getItem('userid')!;
  const expiryMins: string[] = Object.keys(RELATIVE_TIME_LIST);
  const { setBiDirectionalInput } = usePlantLayoutStore();

  useEffect(() => {
    if (showControlPrompt) {
      setInput(existingStatus);
      console.log('sensor id', sensorId);
      checkOngoingControlForSensor(sensorId).then(data => {
        console.log('ongoing control for sensor', data);
        if (data.ongoing) {
          console.log('ongoing control id', data.ongoing[0]);
          setOngoingControlId(data.ongoing[0]._id);
          setIsRecontrolState(true);
          let controlStartTime = Date(data.ongoing[0].created_at);
          console.log('control start time', controlStartTime);
          let controlMins = data.ongoing[0].expiryMins;
          console.log('control mins', controlMins);
          let controlEndTime = moment(data.ongoing[0].created_at);
          controlEndTime.add(controlMins, 'm');
          controlEndTime.subtract(5.5, 'h');
          console.log('control end time', controlEndTime.toDate());
          let currentTime = moment(Date.now());
          console.log('current time', currentTime.toDate());
          let cTimeLeft = controlEndTime.diff(currentTime, 'm');
          setTimeLeft(cTimeLeft);
        } else {
          setIsRecontrolState(false);
          setOngoingControlId('');
        }
      });
    }

    return () => {};
  }, [showControlPrompt]);

  //substract minute from time left every minute
  useEffect(() => {
    if (isRecontrolState) {
      const timer = setTimeout(() => {
        setTimeLeft(prevTimeLeft => prevTimeLeft - 1);
      }, 60000);
      return () => clearTimeout(timer);
    }
  }, [timeLeft]);

  const handleChange = (value: string) => {
    setSelectedTime(value);
    setErrorText('');
  };
  const handleClose = () => {
    setShowControlPrompt(false);
  };

  const handleClick = () => {
    setInput(prevInput => !prevInput);
  };

  const handleSubmit = async () => {
    if (existingStatus !== input) {
      if (!selectedTime) {
        setErrorText('Please select a Relative Time before proceeding');
      } else {
        setBiDirectionalInput(input);
        const data = await initiateControl(
          sensorId,
          userId,
          equipmentName,
          +RELATIVE_TIME_LIST[selectedTime],
          input ? 'ON' : 'OFF',
          plantId!
        );
        setControlId(data.controlId);
        if (data.controlId && data.acknowledge === true) {
          setShowConfirmationDialog(true);
          setShowControlPrompt(false);
        } else {
          toast.error('Request failed');
          setFailedPopupMsg(data.message);
          setShowFailedPopup(true);
          setShowControlPrompt(false);
        }
      }
    }
  };
  const terminateEvent = async () => {
    const data = terminateControl(ongoingControlId, userId);
    console.log('terminate data', data);
    handleClose();
  };

  return (
    <>
      <ThemeProvider theme={dpTheme}>
        <Dialog open={showControlPrompt} maxWidth={'xl'} onClose={handleClose}>
          <DialogTitle mt={2} mr={3} ml={3} className={classes.dialogTitle}>
            <Typography variant={'h3'}>{equipmentName} Control</Typography>
            <Button onClick={handleClose}>
              <CloseIcon />
            </Button>
          </DialogTitle>
          <DialogContent>
            <DialogContentText className={classes.displayContent}>
              <Tooltip title={input ? 'Status : ON' : 'Status : OFF'}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant={'h5'}>OFF</Typography>
                  <Android12Switch checked={input} color="primary" onChange={handleClick} />

                  <Typography variant={'h5'}>ON</Typography>
                </Stack>
              </Tooltip>
              {expiryMins && (
                <FormControl required sx={{ minWidth: 320 }}>
                  <InputLabel id="demo-simple-select-helper-label">Relative Time</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={selectedTime || '30 Mins'}
                    label="Relative Time"
                    onChange={e => {
                      handleChange(e.target.value);
                    }}
                  >
                    {expiryMins &&
                      expiryMins.map(time => {
                        return <MenuItem value={time}>{time}</MenuItem>;
                      })}
                  </Select>
                  <FormHelperText sx={{ color: 'red' }}>{errorText}</FormHelperText>
                </FormControl>
              )}
            </DialogContentText>
            {isRecontrolState && (
              <Typography mt={3} color="secondary" variant="subtitle2">
                In {timeLeft} minutes, the original state will be restored automatically.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <DialogNegativeButtonStyle>
              <Typography variant={'body1'} onClick={handleClose}>
                Cancel
              </Typography>
            </DialogNegativeButtonStyle>
            {isRecontrolState && (
              <DialogPositiveButtonStyle sx={{ backgroundColor: '#80858C', color: 'white' }}>
                <Typography variant={'subtitle1'} onClick={terminateEvent}>
                  Restore now
                </Typography>
              </DialogPositiveButtonStyle>
            )}

            <DialogPositiveButtonStyle disabled={existingStatus === input} onClick={handleSubmit}>
              <Typography variant={'body1'}>Apply</Typography>
            </DialogPositiveButtonStyle>
          </DialogActions>
        </Dialog>
        {showConfirmationDialog && (
          <ConfirmationDialog
            showConfirmationDialog={showConfirmationDialog}
            setShowConfirmationDialog={setShowConfirmationDialog}
            input={input}
            relativeTime={selectedTime}
            equipmentName={equipmentName}
            userId={userId}
            controlId={controlId}
          />
        )}
        {showFailedPopup && (
          <FailedPopup
            showFailedPopup={showFailedPopup}
            setShowFailedPopup={setShowFailedPopup}
            message={failedPopupMsg}
          />
        )}
      </ThemeProvider>
    </>
  );
};

export default ControlPrompt;
