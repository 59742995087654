import { FC, useEffect, useState } from 'react';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { updateImageData, uploadPlantImage } from '../../services/plants';

interface UploadImagePopup {
  plantId: string;
  presetImage: any;
  handleClose: Function;
}

const UploadImagePopup: FC<UploadImagePopup> = ({ plantId, presetImage, handleClose }) => {
  const [imageName, setImageName] = useState<any>('');
  const [imageControlId, setImageControlId] = useState<any>('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string>('');
  const [preSetUrl, setPreSetUrl] = useState<string>('');
  const [ImageForEdit, setImageForEdit] = useState<Boolean>(false);
  const [preSetImageId, setPreSetImageId] = useState<any>({});
  useEffect(() => {
    if (presetImage.hasOwnProperty('imageName')) {
      setImageName(presetImage.imageName);
      setImageControlId(presetImage.imageControlId);
      setImagePreview(presetImage.url);
      setPreSetUrl(presetImage.url);
      setPreSetImageId(presetImage._id);
      setImageForEdit(true);
    }
  }, [presetImage]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setImageForEdit(false);
    const file = e.target.files ? e.target.files[0] : null;
    if (
      file &&
      (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/svg+xml')
    ) {
      setImageFile(file);
      setImagePreview(URL.createObjectURL(file));
      setImageName('');
      setImageControlId('');
    }
  };

  const handleImageUpload = async () => {
    if (imageFile && imageName && imageName.trim() !== '') {
      const formData = new FormData();

      formData.append('upload', imageFile, imageName);
      try {
        let imageUploadResponse = await uploadPlantImage(
          plantId,
          imageName,
          imageControlId,
          formData
        );
        let responseData = imageUploadResponse.data.data;
        if (responseData.status === 'success') {
          toast.success(responseData.message);
          handleClose();
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        console.error('There was an error uploading the image:', error);
      }

      // Upload image
    } else {
      // Show error message
      toast.error('Please select image and provide the name of the image.');
    }
  };

  const handleImageUpdate = async () => {
    if (imageName && imageName.trim() !== '' && imageControlId && imageControlId.trim() !== '') {
      try {
        let imageUpdate = await updateImageData(
          preSetImageId,
          imageName,
          imageControlId,
          preSetUrl
        );
        let responseData = imageUpdate.data;
        if (responseData.status === 'success') {
          toast.success(responseData.message);
          handleClose();
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        console.error('There was an error uploading the image:', error);
      }
    }
  };

  useEffect(() => {}, []);

  return (
    <div
      style={{
        display: 'flex',
        marginTop: '50px',
        alignContent: 'center',
        width: '500px',
      }}
    >
      <Stack sx={{ width: 'inherit' }}>
        <Grid style={{}} container spacing={2}>
          {/* Column 1 */}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              borderRight: '1px solid #E0E0E0',

              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item sx={{ marginRight: '20px' }}>
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: '100%', maxHeight: '200px' }}
                  />
                )}
              </Grid>
              <Grid item sx={{ marginRight: '20px' }}>
                <Button
                  variant="outlined"
                  component="label"
                  style={{ color: '#183650', borderColor: '#183650' }}
                >
                  {imageFile ? 'Change Picture' : 'Select Picture'}
                  <input
                    type="file"
                    hidden
                    onChange={handleImageChange}
                    accept="image/jpeg,image/png,image/svg+xml"
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Column 2 */}
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              borderRight: 'none',

              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Grid container direction="column" alignItems="center" spacing={2}>
              <Grid item>
                <TextField
                  label="Image Name"
                  variant="outlined"
                  value={imageName}
                  onChange={e => {
                    setImageName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Image Control Id"
                  variant="outlined"
                  value={imageControlId}
                  onChange={e => {
                    setImageControlId(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: 'inherit',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {ImageForEdit && (
            <>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#183650',
                  // borderColor: '#183650',
                  margin: '30px 20px 30px 0px',
                }}
                onClick={() => {
                  handleImageUpdate();
                }}
              >
                Update Image Data
              </Button>
            </>
          )}
          {!ImageForEdit && (
            <Button
              variant="contained"
              style={{
                backgroundColor: '#183650',
                // borderColor: '#183650',
                margin: '30px 20px 30px 0px',
              }}
              onClick={() => {
                handleImageUpload();
              }}
            >
              Upload Picture
            </Button>
          )}
        </Box>
      </Stack>
    </div>
  );
};

export default UploadImagePopup;
