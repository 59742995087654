//@ts-nocheck
import { Accordion, Autocomplete, Box, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ASSIGN_ROLE, ISSUE_TYPES } from "../../constants/constants";
import { PrimaryButton } from "../../styles/global/components/dpButtons";

// CSS Styles
const styles = {
  taskTitle: {
    font: "Montserrat Bold",
    fontSize: 22,
  },
  problemStatement: {
    font: "Montserrat Bold",
    fontSize: 20,
  },
  problemStatementText: {
    font: "Montserrat",
    fontSize: 16,
  },
  TextFieldStyle: {
    margin: "0.6rem 0",
  },
};

// Function to generate the title element with delete button
const TaskTitle = ({ section, subsection, indexTask, deleteTask }) => (
  <Box m={1} p={1}>
    <div style={styles.taskTitle}>
      Task
      <span style={{ float: "right" }}>
        <PrimaryButton
          onClick={() => {
            console.log("********", section, subsection, indexTask);
            // debugger;
            deleteTask(section, subsection, indexTask);
          }}
        >
          <DeleteIcon />
        </PrimaryButton>
      </span>
    </div>
  </Box>
);

// Function to generate the problem statement element
const ProblemStatement = ({
  headingEng,
  headingHindi,
  onChangeEng,
  onChangeHindi,
}) => (
  <Box m={1} p={1}>
    <div style={styles.problemStatement}>
      <div>Problem Statement</div>
      <div style={styles.problemStatementText}>
        <TextField
          fullWidth
          required
          value={headingEng}
          label="Problem Statement in English"
          onChange={onChangeEng}
          style={styles.TextFieldStyle}
        />
      </div>

      <div style={styles.problemStatementText}>
        <TextField
          fullWidth
          required
          value={headingHindi}
          label="Problem Statement in Hindi"
          onChange={onChangeHindi}
          style={styles.TextFieldStyle}
        />
      </div>
    </div>
  </Box>
);

const ProblemDescription = ({
  descriptionEng,
  descriptionHindi,
  userValue,
  issueTypeValue,
  onChangeEng,
  onChangeHindi,
  onChangeToUserValue,
  onChangeIssueValue,
}) => (
  <Box m={1} p={1}>
    <div style={styles.problemStatement}>
      <div>Problem Description</div>
      <div style={styles.problemStatementText}>
        <Accordion
          TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        >
          <TextField
            fullWidth
            required
            value={descriptionEng}
            label="Problem Description in English"
            onChange={onChangeEng}
            style={styles.TextFieldStyle}
          />
        </Accordion>
      </div>
      <div style={styles.problemStatementText}>
        <Accordion
          TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}
        >
          <TextField
            fullWidth
            required
            value={descriptionHindi}
            label="Problem Description in Hindi"
            onChange={onChangeHindi}
            style={styles.TextFieldStyle}
          />
        </Accordion>
      </div>
    </div>
    <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
      <Box>
        <Autocomplete
          id="tags-standard"
          options={ASSIGN_ROLE}
          value={userValue}
          onChange={onChangeToUserValue}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              variant="standard"
              label="For Role"
              style={styles.TextFieldStyle}
            />
          )}
        />
      </Box>
    </Accordion>
    <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
      <Box>
        <Autocomplete
          multiple
          id="tags-standard"
          options={ISSUE_TYPES}
          value={issueTypeValue}
          onChange={onChangeIssueValue}
          renderInput={(params) => (
            <TextField
              required
              {...params}
              variant="standard"
              label="Issue Type"
              style={styles.TextFieldStyle}
            />
          )}
        />
      </Box>
    </Accordion>
  </Box>
);

// Component to render the task section
const TaskSection = ({
  section,
  subsection,
  indexTask,
  task,
  deleteTask,
  updateTask,
  updateDirectValue,
}) => (
  <div>
    <TaskTitle
      section={section}
      subsection={subsection}
      indexTask={indexTask}
      deleteTask={deleteTask}
    />
    <ProblemStatement
      headingEng={task.heading.eng}
      headingHindi={task.heading.hindi}
      onChangeEng={(e) =>
        updateTask(
          section,
          subsection,
          indexTask,
          "heading",
          "eng",
          e.target.value
        )
      }
      onChangeHindi={(e) =>
        updateTask(
          section,
          subsection,
          indexTask,
          "heading",
          "hindi",
          e.target.value
        )
      }
    />
    <ProblemDescription
      descriptionEng={task.description.eng}
      descriptionHindi={task.description.hind}
      userValue={task["toUser"]}
      issueTypeValue={task["issueType"]}
      onChangeEng={(e) =>
        updateTask(
          section,
          subsection,
          indexTask,
          "description",
          "eng",
          e.target.value
        )
      }
      onChangeHindi={(e) =>
        updateTask(
          section,
          subsection,
          indexTask,
          "description",
          "hind",
          e.target.value
        )
      }
      onChangeToUserValue={(e, newValue) =>
        updateDirectValue(section, subsection, indexTask, "toUser", newValue)
      }
      onChangeIssueValue={(e, newValue) =>
        updateDirectValue(section, subsection, indexTask, "issueType", newValue)
      }
    />
  </div>
);

export default TaskSection;
