import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { PLANT_CONFIG_DATA } from '../../../constants/constants';
import { Autocomplete } from '@mui/material';
import { styledBoxWithShadow } from '../../../styles/global/components/dpBox';
import { PlantConfigStyle } from '.././PlantConfigStyle';
import DeleteConfirmation from '../../../globalComponents/dialogs/DeleteConfirmation';
import { useState } from 'react';
import DiagramSelectComponent from '.././subComponents/DiagramSelectComponent';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SensorTagArray from '../subComponents/SensorTagArray';

interface IProps {
  valveData: any;
  RemoveElement: Function;
  updateValue: Function;
  updateSensorList: Function;
  updateSensorTagValue: Function;
  handleNickNameChange: Function;
  handleMultiSelect: Function;
  valveChoice: any;
  handleShowOnDiagramCheckForSubequipment: Function;
  handleBiDirectionalCheckForSubequipment: Function;
}

function ValveConfig({
  valveData,
  RemoveElement,
  updateSensorList,
  updateValue,
  valveChoice,
  handleNickNameChange,
  updateSensorTagValue,
  handleMultiSelect,
  handleShowOnDiagramCheckForSubequipment,
  handleBiDirectionalCheckForSubequipment,
}: IProps) {
  const [equipmentIndex, setEquipmentIndex] = useState<Number | null>(null);
  const [equipmentName, setEquipmentName] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [equipment, setEquipment] = useState('');
  function checkIfBidirectionalControlPossible(sensors: any) {
    let onOffSensor = sensors.find((sensor: any) => sensor.sensorName === 'ON/OFF Status');
    if (onOffSensor) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <>
      <Box m={1} p={2}>
        {valveData?.map((valve: any, index: number) => {
          return (
            <Accordion TransitionProps={{ unmountOnExit: true }}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.6rem',
                  }}
                >
                  <Typography variant="h4">
                    {valve.name} {valve.count}
                  </Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Box m={1} p={1} sx={styledBoxWithShadow}>
                  <Grid item>
                    <Grid container>
                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Box m={1} p={1}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">Choose Sensors</FormLabel>
                            <FormGroup>
                              {PLANT_CONFIG_DATA['Valves'][valve.name] != null
                                ? PLANT_CONFIG_DATA['Valves'][valve.name]['sensors'].map(
                                    (sensor: string) => {
                                      return (
                                        <>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                checked={valve['sensors'].find(
                                                  (availableSensor: any) =>
                                                    availableSensor['sensorName'] === sensor
                                                )}
                                                onChange={() => {
                                                  updateSensorList('Valves', index, sensor);
                                                }}
                                                name={sensor}
                                              />
                                            }
                                            label={sensor}
                                          />
                                        </>
                                      );
                                    }
                                  )
                                : null}
                            </FormGroup>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Box m={1} p={1}>
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={valveChoice}
                            value={valve['In']}
                            onChange={(eveny, newValue) => {
                              handleMultiSelect('Valves', index, 'In', newValue);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Choose Input"
                                placeholder="Input"
                                style={PlantConfigStyle.equipmentTextField}
                              />
                            )}
                          />
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            options={valveChoice}
                            value={valve['Out']}
                            onChange={(eveny, newValue) => {
                              handleMultiSelect('Valves', index, 'Out', newValue);
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                variant="standard"
                                label="Choose Output"
                                placeholder="Output"
                                style={PlantConfigStyle.equipmentTextField}
                              />
                            )}
                          />
                          <DiagramSelectComponent
                            category="Valves"
                            index={index}
                            updateValue={updateValue}
                            layoutDiagram={valve['layoutDiagram']}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Box m={1} p={1}>
                          <TextField
                            fullWidth
                            id={'model+MR' + valve['count']}
                            label="Valve NickName"
                            value={valve['nickName']}
                            onChange={e =>
                              handleNickNameChange({
                                category: 'Valves',
                                index,
                                value: e.target.value,
                              })
                            }
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'age' + valve['count']}
                            label="Age in months"
                            value={valve['age']}
                            type="Number"
                            onChange={e =>
                              updateValue({
                                category: 'Valves',
                                index,
                                valuefor: 'age',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 1000,
                              step: '1',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>

                        <Box m={1}>
                          <TextField
                            fullWidth
                            id={'tagName' + valve['count']}
                            label="Tag Name"
                            value={valve['tagName']}
                            type="text"
                            onChange={e =>
                              updateValue({
                                category: 'Valves',
                                index,
                                valuefor: 'tagName',
                                value: e.target.value,
                              })
                            }
                            inputProps={{
                              maxLength: 1000,
                              step: '1',
                            }}
                            style={PlantConfigStyle.equipmentTextField}
                          />
                        </Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={valve.displayNode}
                              // checked={check}
                              onChange={() => {
                                handleShowOnDiagramCheckForSubequipment(
                                  'Valves',
                                  index,
                                  !valve.displayNode
                                );
                              }}
                              name={'Show on diagram'}
                            />
                          }
                          label={'Show on diagram'}
                        />
                        {checkIfBidirectionalControlPossible(valve['sensors']) && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={valve.bidDirectionalControl}
                                // checked={check}
                                onChange={() => {
                                  handleBiDirectionalCheckForSubequipment(
                                    'Valves',
                                    index,
                                    !valve.bidDirectionalControl
                                  );
                                }}
                                name={'Bi-Directional Control'}
                              />
                            }
                            label={'Bi-Directional Control'}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} sm={4} md={4} lg={4}>
                        <SensorTagArray
                          categoryName="Valves"
                          formHelperText={{
                            name: valve.name,
                            count: valve.count,
                          }}
                          sensorsTagData={valve['sensors']}
                          componentIndex={index}
                          updateSensorTagValue={updateSensorTagValue}
                          updateValue={updateValue}
                          hmiData={valve['hmiButtons']}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <div style={PlantConfigStyle.configFormWrapper}>
                    <Button
                      variant="contained"
                      style={PlantConfigStyle.deleteButton}
                      onClick={() => {
                        setShowDeleteModal(true);
                        setEquipmentIndex(index);
                        setEquipmentName(`${valve.name} ${valve.count}`);
                        setEquipment('Valves');
                      }}
                    >
                      <DeleteIcon style={PlantConfigStyle.deleteIcon} />
                    </Button>
                  </div>
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <DeleteConfirmation
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          alertMessage={`You are deleting the ${equipmentName}`}
          processing={processing}
          setProcessing={setProcessing}
          onConfirmAction={() => {
            RemoveElement(equipment, equipmentIndex);
            setProcessing(false);
            setShowDeleteModal(false);
          }}
        />
      </Box>
    </>
  );
}

export default ValveConfig;
