import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import Stack from "@mui/material/Stack";

interface PropsInterface {
  solution: any;
}

export default function SolutionLogsTableComponent(props: PropsInterface) {
  const { solution } = props;
  //@ts-ignore
  const solutionConsumedCalculator = (
    flowRate: number,
    startTime: string,
    endTime: string
  ) => {
    return (Math.random() * 100).toFixed(2);
  };

  return (
    <TableContainer>
      <Table sx={{}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Amount Of Solution Used</TableCell>
            <TableCell align="left">Time Of Use</TableCell>
            <TableCell align="right">Operator Name</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {solution?.solutionUsage.map((log: any, index: number) => (
            <TableRow
              key={index}
              hover={true}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
              onClick={() => {}}
            >
              <TableCell component="th" scope="row">
                {log.amount}
              </TableCell>
              <TableCell align="left">
                <Stack direction="column" alignItems="center">
                  <span>
                    {moment(log.startTime, "YYYY-MM-DDTHH:mm:ssZ").format(
                      "DD MMM YYYY"
                    )}
                  </span>
                  <span>
                    {moment(log.startTime, "YYYY-MM-DDTHH:mm:ssZ").format(
                      "HH:mm a"
                    )}
                  </span>
                </Stack>
              </TableCell>
              <TableCell align="right">{log.operatorName}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
