import { useEffect } from 'react';
interface RefreshPageProps {
  expireTime: number;
  setExpireTime: React.Dispatch<React.SetStateAction<number>>;
  checkboxChecked: boolean;
  setCheckboxChecked: React.Dispatch<React.SetStateAction<boolean>>;
  customRefreshInterval: number;
  setCustomRefreshInterval: React.Dispatch<React.SetStateAction<number>>;
  enableCustomRefreshOnly: boolean;
  setEnableCustomRefreshOnly: React.Dispatch<React.SetStateAction<boolean>>;
}
const RefreshPageOnly: React.FC<RefreshPageProps> = ({
  expireTime,
  setExpireTime,
  checkboxChecked,
  setCheckboxChecked,
  customRefreshInterval,
  setCustomRefreshInterval,
  enableCustomRefreshOnly,
  setEnableCustomRefreshOnly,
}: RefreshPageProps) => {
  const checkForInactivity = () => {
    const currentTime = Date.now();
    if (checkboxChecked && expireTime < currentTime) {
      localStorage.setItem('enableCustomRefreshOnly', 'true');
      window.location.reload();
    }
  };

  const updateExpireTime = () => {
    localStorage.setItem('enableCustomRefreshOnly', 'false');
    setEnableCustomRefreshOnly(false);
    setExpireTime(Date.now() + 1000);
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (enableCustomRefreshOnly) {
      if (customRefreshInterval >= 60 && customRefreshInterval <= 600) {
        interval = setInterval(() => {
          checkForInactivity();
        }, customRefreshInterval * 1000);
      }
    } else {
      let totalTime = 300 + customRefreshInterval;
      if (totalTime >= 360 && totalTime <= 900) {
        interval = setInterval(() => {
          checkForInactivity();
        }, totalTime * 1000);
      }
    }
    return () => clearInterval(interval);
  }, [expireTime, checkboxChecked, customRefreshInterval]);

  useEffect(() => {
    window.addEventListener('click', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);

    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
    };
  }, []);
  return null;
};

export default RefreshPageOnly;
