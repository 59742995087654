// import * as React from 'react';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
// import { Paper } from '@mui/material';

export default function Calendar({ value, handleCalendarChange }: any) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar value={value} onChange={handleCalendarChange} disableFuture={true}/>
    </LocalizationProvider>
  );
}
