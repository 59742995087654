import {
  Box,
  Checkbox,
  TextField,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Autocomplete from "@mui/lab/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Question } from "../../Interfaces/DIForm";
import { TYPE_OPTIONS } from "../../constants/constants";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./DIConfig.module.css";
import DISensorConfig from "./DISensorConfig";
import DIConfigForExpectedValue from "./DIConfigForExpectedValue";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import { INVENTORY_CATEGORIES_WITH_UNITS } from "../../constants/constants";

interface IProps {
  formType: string;
  dataBlock: Question;
  updateFunction: Function;
  category: string;
  index: number;
  handleIsExpectedValue: Function;
}

export default function DataInputConfigBlock({
  formType,
  dataBlock,
  updateFunction,
  category,
  index,
  handleIsExpectedValue,
}: IProps) {
  const [refresh, setRefresh] = useState(false);
  // function to handle changes to the question object where toChange = property of the object
  const handleChanges = (toChange: string, value: any) => {
    let data = dataBlock;
    console.log(value);
    if (toChange === "type") {
      let newDataBlock: Question = {
        title: "",
        min: 0,
        max: 0,
        checked: false,
        type: "",
        range: false,
        sensorTypeAbr: "",
        sensorType: "",
        sensorTag: "",
        sensorNickName: "",
        currentValue: 0,
        previousValue: 0,
        prevTimeDataStored: "",
        time: "",
        sensorId: "",
        options: [],
        criticalLowerLevel: 0,
      };
      newDataBlock.type = value;
      newDataBlock.title = data.title;
      newDataBlock.checked = data.checked;
      if (value === "number" || value === "integer") {
        newDataBlock.range = false;
        newDataBlock.min = 0;
        newDataBlock.max = 0;
      } else if (value === "dropDown") {
        newDataBlock.options = ["New Option"];
      }
      console.log(newDataBlock);
      updateFunction(newDataBlock, category, index);
    } else {
      data[toChange] = value;
      updateFunction(data, category, index);
    }
  };

  const handleSensorChanges = (index: number, property: string, value: any) => {
    dataBlock["sensors"][index][property] = value;
  };

  const handleExpectedValueChange = (
    index: number,
    property: string,
    value: any
  ) => {
    console.log("asd : ", dataBlock["expectedValue"][index]);
    console.log("property : ", property);
    dataBlock["expectedValue"][index][property] = value;
    setRefresh(!refresh);
  };

  const addNewOption = () => {
    let data = dataBlock;
    data.options.push("New Option");
    updateFunction(data, category, index);
  };

  const deleteOption = (indexO: number) => {
    let data = dataBlock;
    data.options.splice(indexO, 1);
    updateFunction(data, category, index);
  };

  const handleOptionChanges = (value: string, indexO: number) => {
    let data = dataBlock;
    data.options[indexO] = value;
    updateFunction(data, category, index);
  };
  return (
    <Box m={1} sx={{ width: "100%" }}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row" justifyContent="flex-start">
            <Checkbox
              checked={dataBlock.checked}
              onChange={(e) => {
                handleChanges("checked", e.target.checked);
              }}
              sx={{ marginRight: "auto" }}
            />
            <p>{dataBlock.title}</p>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Box
            sx={{
              padding: "15px 25px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <>
              <DISensorConfig
                dataBlock={dataBlock}
                handleChanges={handleChanges}
                handleSensorChanges={handleSensorChanges}
              />

              {formType !== "inventoryDIForm" && (
                <Autocomplete
                  id="tags-standard-1"
                  options={TYPE_OPTIONS}
                  value={dataBlock.type}
                  onChange={(event, newValue) => {
                    handleChanges("type", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      variant="standard"
                      label={"Input Type"}
                    />
                  )}
                  disabled={true}
                />
              )}

              {formType === "inventoryDIForm" &&
                INVENTORY_CATEGORIES_WITH_UNITS.includes(category) && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                    <Select
                      fullWidth
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      value={dataBlock.unit}
                      onChange={(e) => {
                        handleChanges("unit", e.target.value);
                        handleExpectedValueChange(
                          0,
                          "unit",
                          `${e.target.value}/min`
                        );
                      }}
                      label="Unit"
                    >
                      <MenuItem value={"g"}>kg</MenuItem>
                      <MenuItem value={"l"}>l</MenuItem>
                    </Select>
                  </FormControl>
                )}
              {dataBlock.expectedValue && (
                <DIConfigForExpectedValue
                  dataBlock={dataBlock}
                  handleExpectedValueChange={handleExpectedValueChange}
                />
              )}
              {dataBlock.type === "dropDown" && (
                <Box m={1} p={1}>
                  <div style={styles["form-dropdown"]}>
                    Options
                    <span style={{ float: "right" }}>
                      <Button
                        onClick={addNewOption}
                        style={{ background: "#519BD0" }}
                      >
                        <AddIcon />
                      </Button>
                    </span>
                  </div>
                  {dataBlock.options.map((optionText, indexo) => {
                    return (
                      <Box m={1} p={1}>
                        <div style={styles["form-dropdown"]}>
                          <TextField
                            required
                            value={optionText}
                            label="Option Title"
                            onChange={(e) => {
                              handleOptionChanges(e.target.value, indexo);
                            }}
                          />
                          <DeleteIcon
                            onClick={() => {
                              deleteOption(indexo);
                            }}
                            style={{ background: "#519BD0" }}
                          />
                        </div>
                      </Box>
                    );
                  })}
                </Box>
              )}
              {dataBlock.type === "number" ||
                (dataBlock.type === "integer" && (
                  <>
                    {formType === "inventoryDIForm" && (
                      <Box>
                        <TextField
                          required
                          fullWidth
                          type="number"
                          value={dataBlock.criticalLowerLevel ?? 0}
                          label="critical level"
                          onChange={(e) => {
                            handleChanges("criticalLowerLevel", e.target.value);
                          }}
                        />
                      </Box>
                    )}
                    <Box>
                      Range
                      <Checkbox
                        checked={dataBlock.range}
                        onChange={(e) => {
                          handleChanges("range", e.target.checked);
                        }}
                      />
                    </Box>
                    {dataBlock.range ? (
                      <Box sx={styles["datablock-range"]}>
                        <TextField
                          required
                          value={dataBlock.min}
                          type="number"
                          label="Minimum Value"
                          onChange={(e) => {
                            handleChanges("min", e.target.value);
                          }}
                        />
                        <TextField
                          required
                          type="number"
                          value={dataBlock.max}
                          label="Maximum Value"
                          onChange={(e) => {
                            handleChanges("max", e.target.value);
                          }}
                        />
                      </Box>
                    ) : null}
                  </>
                ))}
            </>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
