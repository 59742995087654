import { styled } from "@mui/system";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const SensorStyles = {
  AccordianSummary: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0.6rem",
  },

  SensorStatus: {
    display: "flex",
    alignSelf: "flex-end",
    fontSize: "0.9rem",
  },

  ActiveSensor: {
    padding: "0.4rem 1rem",
    borderRadius: "1rem",
    color: "#038298",
    backgroundColor: "#E6FCFF",
    marginRight: "1rem",
  },

  InActiveSensor: {
    padding: "0.4rem 1rem",
    borderRadius: "1rem",
    color: "#DE350B",
    backgroundColor: "#FFEBE6",
    marginRight: "1rem",
  },

  DeletedSensor: {
    padding: "0.4rem 1rem",
    borderRadius: "1rem",
    color: "#FFEBE6",
    backgroundColor: "#DE350B",
    marginRight: "1rem",
  },

  ActiveColor: {
    color: "#038298",
  },

  InActiveColor: {
    color: "#DE350B",
  },

  DialogStyles: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },

  DialogTitleStyle: {
    color: "black",
    marginBottom: "0.2rem",
  },

  IconStyles: {
    marginBottom: "0.8rem",
  },

  DialogListStyle: {
    padding: "0 1.5rem",
  },

  ProcessingStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  SuccessStyle: {
    color: "#2DAD6F",
    margin: 0,
  },
};

const useStyles = makeStyles({
  deletedRowStyle: {
    backgroundColor: "#dddddd",
    pointerEvents: "none",
    "&:hover": {
      backgroundColor: "#dddddd",
    },
  },
});

const DialogPositiveButtonStyle = styled(Button)({
  backgroundColor: "#DE350B",
  color: "white",
  margin: "0 1.2rem 1.2rem 0",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#DE350B",
  },
  "&:disabled": {
    backgroundColor: "#cccccc",
    color: "#666666",
  },
});

const DialogNegativeButtonStyle = styled(Button)({
  border: "0.1rem solid #BABFCB",
  margin: "0 1.2rem 1.2rem 0",
  textTransform: "capitalize",
});

const DialogSuccessButtonStyle = styled(Button)({
  backgroundColor: "#ABF5D1",
  color: "#2DAD6F",
  margin: "0 1.2rem 1.2rem 0",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#ABF5D1",
  },
});

export {
  SensorStyles,
  useStyles,
  DialogPositiveButtonStyle,
  DialogNegativeButtonStyle,
  DialogSuccessButtonStyle,
};
