import { dia, util } from '@joint/plus';

const POWER_FLAG = 'POWER';

// Pump metrics
const r = 25;
const d = 8;
const l = (3 * r) / 4;

// @ts-nocheck
export class Pump extends dia.Element {
  // @ts-ignore
  defaults() {
    return {
      ...super.defaults,
      type: 'Pump',
      size: {
        width: 80,
        height: 80,
      },
      power: 0,
      attrs: {
        root: {
          magnetSelector: 'body',
        },
        body: {
          rx: 'calc(w / 2)',
          ry: 'calc(h / 2)',
          cx: 'calc(w / 2)',
          cy: 'calc(h / 2)',
          stroke: 'gray',
          strokeWidth: 2,
          fill: 'lightgray',
        },
        label: {
          text: 'Pump',
          textAnchor: 'middle',
          textVerticalAnchor: 'top',
          x: 'calc(0.5*w)',
          y: 'calc(h+10)',
          fontSize: 14,
          fontFamily: 'sans-serif',
          fill: '#350100',
        },
        rotorGroup: {
          transform: 'translate(calc(w/2),calc(h/2))',
          event: 'element:power:click',
          cursor: 'pointer',
        },
        rotorFrame: {
          r: 30,
          fill: '#eee',
          stroke: '#666',
          strokeWidth: 2,
        },
        rotorBackground: {
          r: 24,
          fill: '#777',
          stroke: '#222',
          strokeWidth: 1,
          style: {
            transition: 'fill 0.5s ease-in-out',
          },
        },
        rotor: {
          // d: `M ${a} ${a} ${b} ${r} -${b} ${r} -${a} ${a} -${r} ${b} -${r} -${b} -${a} -${a} -${b} -${r} ${b} -${r} ${a} -${a} ${r} -${b} ${r} ${b} Z`,
          d: `M 0 0 V ${r} l ${-d} ${-l} Z M 0 0 V ${-r} l ${d} ${l} Z M 0 0 H ${r} l ${-l} ${d} Z M 0 0 H ${-r} l ${l} ${-d} Z`,
          stroke: '#222',
          strokeWidth: 3,
          fill: '#bbb',
        },
      },
      ports: {
        groups: {
          pipes: {
            position: {
              name: 'line',
              args: {
                start: { x: 'calc(w / 2)', y: 'calc(h - 5)' },
                end: { x: 'calc(w / 2)', y: -5 },
              },
            },
            markup: util.svg`
                                <rect @selector="pipeBody" />
                                <rect @selector="pipeEnd" />
                            `,
            size: { width: 80, height: 30 },
            attrs: {
              portRoot: {
                magnetSelector: 'pipeEnd',
              },
              pipeBody: {
                width: 'calc(w)',
                height: 'calc(h)',
                y: 'calc(h / -2)',
                fill: {
                  type: 'linearGradient',
                  stops: [
                    { offset: '0%', color: 'gray' },
                    { offset: '30%', color: 'white' },
                    { offset: '70%', color: 'white' },
                    { offset: '100%', color: 'gray' },
                  ],
                  attrs: {
                    x1: '0%',
                    y1: '0%',
                    x2: '0%',
                    y2: '100%',
                  },
                },
              },
              pipeEnd: {
                width: 10,
                height: 'calc(h+6)',
                y: 'calc(h / -2 - 3)',
                stroke: 'gray',
                strokeWidth: 3,
                fill: 'white',
              },
            },
          },
        },
        items: [
          {
            id: 'left',
            group: 'pipes',
            z: 1,
            attrs: {
              pipeBody: {
                x: 'calc(-1 * w)',
              },
              pipeEnd: {
                x: 'calc(-1 * w)',
              },
            },
          },
          {
            id: 'right',
            group: 'pipes',
            z: 0,
            attrs: {
              pipeEnd: {
                x: 'calc(w - 10)',
              },
            },
          },
        ],
      },
    };
  }

  preinitialize() {
    this.markup = util.svg/* xml */ `
                <ellipse @selector="body" />
                <g @selector="rotorGroup">
                    <circle @selector="rotorFrame" />
                    <circle @selector="rotorBackground" />
                    <path @selector="rotor" />
                </g>
                <text @selector="label" />
            `;
  }

  get power() {
    return this.get('power') || 0;
  }

  set power(value) {
    this.set('power', value);
  }
}

export const PumpView = dia.ElementView.extend({
  presentationAttributes: dia.ElementView.addPresentationAttributes({
    power: [POWER_FLAG],
  }),

  initFlag: [dia.ElementView.Flags.RENDER, POWER_FLAG],

  powerAnimation: null,

  // @ts-ignore
  confirmUpdate(...args) {
    // @ts-ignore
    let flags = dia.ElementView.prototype.confirmUpdate.call(this, ...args);
    if (this.hasFlag(flags, POWER_FLAG)) {
      this.togglePower();
      flags = this.removeFlag(flags, POWER_FLAG);
    }
    return flags;
  },

  getSpinAnimation() {
    let { spinAnimation } = this;
    if (spinAnimation) return spinAnimation;
    const rotorEl = this.findNode('rotor');
    // It's important to use start and end frames to make it work in Safari.
    const keyframes = { transform: ['rotate(0deg)', 'rotate(360deg)'] };
    spinAnimation = rotorEl.animate(keyframes, {
      fill: 'forwards',
      duration: 1000,
      iterations: Infinity,
    });
    this.spinAnimation = spinAnimation;
    return spinAnimation;
  },

  changeColour() {
    let { rotorBackground } = this;
    if (!rotorBackground) {
      rotorBackground = this.rotorBackground = this.findNode('rotorBackground');
    }
    const color = this.model.power ? '#00ff00' : '#ff0000';
    rotorBackground.animate({ fill: [color] }, { fill: 'forwards', duration: 5000 });
    console.log('changeColour');
  },

  togglePower() {
    const { model } = this;
    this.getSpinAnimation().playbackRate = model.power;
    this.changeColour();
  },
});
