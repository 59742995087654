import { useState, useEffect } from 'react';
import { Box, ThemeProvider, Tabs, Tab, Link, Typography, Breadcrumbs } from '@mui/material';
import {
  StoreOptionsInterface,
  StoreCategoryInterface,
  StoreListInterface,
  IPlants,
} from '../Interfaces/InventoryOptionsInterface';
import dpTheme from '../styles/theme/dpTheme';
import { InventoryOptionButton } from '../styles/pages/InventoryOptionsStyle';
import classes from '../styles/pages/InventoryOptions.module.css';
import UpdateInventoryDialog from '../components/InventoryOptions/UpdateInventoryDialog';
import { useNavigate } from 'react-router-dom';
import LayoutComponent from '../components/LayoutComponent';
import { getStoreDetails, getStoreList } from '../services/stores';
import { getPlantsForUser } from '../services/tickets';
// import { InfoBox } from "../styles/global/components/TextInfoBoxes";
import CallMadeIcon from '@mui/icons-material/CallMade';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function InventoryOptions() {
  const [selectedInventoryOption, setSelectedInventoryOption] =
    useState<StoreCategoryInterface | null>(null);
  const [storeList, setStoreList] = useState<StoreListInterface[]>([]);
  const [selectedStore, setSelectedStore] = useState('');
  const [plantsList, setPlantsList] = useState<IPlants[]>();
  const [storeDetails, setStoreDetails] = useState<StoreOptionsInterface>();

  const [refresh, setRefresh] = useState(false);
  //@ts-ignore
  const [showErrorInfoBox, setShowErrorInfoBox] = useState(false);

  const userId = localStorage?.getItem('userId');

  const queryParams = new URLSearchParams(location.search);
  const plantIdParam = queryParams.get('plantId');

  useEffect(() => {
    fetchStoreList();
    fetchPlantsForUser();
  }, []);

  // useEffect(() => {
  //   if (selectedStore) fetchStoreDetails();
  // }, []);

  useEffect(() => {
    if (selectedStore && plantsList) fetchStoreDetails();
  }, [refresh, selectedStore, plantsList]);

  const fetchPlantsForUser = async () => {
    let tempPlantsList = await getPlantsForUser(userId!);
    console.log(tempPlantsList, 'tempPlantsList');
    if (plantIdParam) {
      tempPlantsList = tempPlantsList.filter((plant: any) => plant.plantId === plantIdParam);
    }
    setPlantsList(tempPlantsList);
  };

  const handleSelectedStoreChange = (event: React.SyntheticEvent, newStore: string) => {
    setSelectedStore(newStore);
  };
  const fetchStoreList = async () => {
    let tempStoreList = await getStoreList(userId!);
    if (plantIdParam) {
      tempStoreList = tempStoreList.filter((store: any) => {
        if (store.assets.includes(plantIdParam)) {
          store.assets = [plantIdParam];
          return store;
        }
      });
    }
    setStoreList(tempStoreList);
    setSelectedStore(tempStoreList[0]._id);
  };

  const fetchStoreDetails = async () => {
    let storeDetails: StoreOptionsInterface = await getStoreDetails(selectedStore);
    let filteredStoreDetails = filterStoreItemsForUser(storeDetails);
    setStoreDetails(filteredStoreDetails);
    checkIfCSMError(storeDetails);
  };

  const filterStoreItemsForUser = (storeDetails: StoreOptionsInterface) => {
    if (!storeDetails || !storeDetails.data) return storeDetails;

    const updatedStoreDetails = {
      ...storeDetails,
      data: storeDetails.data.map(storeCategory => {
        if (storeCategory.storeCategoryDisplayName === 'Solution') {
          return storeCategory; // Don't modify 'Solution' category
        }

        const hasUpdatableItems = storeCategory.storeItemDetails.some((storeItem: any) => {
          const userPlantIds = plantsList?.map(plant => plant?.plantId);
          return storeItem?.assetIds?.some((assetId: string) => userPlantIds?.includes(assetId));
        });

        return {
          ...storeCategory,
          disableOption: !hasUpdatableItems, // Update disableOption based on presence of updatable items
        };
      }),
    };

    return updatedStoreDetails;
  };

  const checkIfCSMError = (data: any) => {
    if (data?.data?.length === 0) setShowErrorInfoBox(true);
  };

  const [isInventoryOptionSelected, setIsInventoryOptionSelected] = useState<boolean>(false);
  const navigate = useNavigate();

  const openDialogForInventoryOption = (store: StoreCategoryInterface) => {
    if (store.storeCategoryName === 'Solution' && plantIdParam) {
      navigate(`/Solution?plantId=${plantIdParam}`);
    } else if (store.storeCategoryName === 'Solution') {
      navigate(`/Solution`);
    } else {
      setIsInventoryOptionSelected(true);
      setSelectedInventoryOption(store);
    }
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit">
      <Typography key="3" color="text.primary">
        Inventory
      </Typography>
    </Link>,
    <Typography key="3" color="text.primary">
      Maintain
    </Typography>,
  ];

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent factoryResetContainer>
        <Box className={classes.InventoryOptionsWrapper}>
          <Breadcrumbs separator={<ArrowForwardIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>

          {/* {showErrorInfoBox ? (
            <InfoBox currentStatus="failure" mb={5}>
              Ask Your csm to configure Inventory for you !
            </InfoBox>
          ) : (
            <InfoBox currentStatus="info" mb={5}>
              If certain Store Categories are disabled ask your csm to enable
              them for you !
            </InfoBox>
          )} */}
          <Tabs
            value={selectedStore}
            onChange={handleSelectedStoreChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto stores"
            sx={{ maxWidth: '90vw' }}
          >
            {storeList?.map((store: any) => (
              <Tab value={store._id} label={store.name} />
            ))}
          </Tabs>

          <div className={classes.InventoryOptions}>
            {storeDetails?.data?.map(storeCategory => (
              <InventoryOptionButton
                fullWidth
                theme={dpTheme}
                textColor={storeCategory?.color?.textColor}
                backgroundColor={storeCategory?.color?.backgroundColor}
                disabled={storeDetails?.isDisabled || storeCategory?.disableOption}
                onClick={() => {
                  openDialogForInventoryOption(storeCategory);
                }}
              >
                {storeCategory?.storeCategoryDisplayName.toUpperCase()}
                <CallMadeIcon sx={{ paddingLeft: '10px' }} fontSize="medium" />
              </InventoryOptionButton>
            ))}
          </div>
        </Box>
        {selectedInventoryOption && (
          <UpdateInventoryDialog
            isInventoryOptionSelected={isInventoryOptionSelected}
            setIsInventoryOptionSelected={setIsInventoryOptionSelected}
            selectedInventoryOptionName={selectedInventoryOption!.storeCategoryName!}
            setRefresh={setRefresh}
            selectedInventoryItemDetails={selectedInventoryOption!.storeItemDetails}
            storeId={selectedStore}
            plantsList={plantsList!}
            inputType={selectedInventoryOption.inputType}
            plantIdParam={plantIdParam}
            clientId={storeList[0]?.clients[0]}
          />
        )}
      </LayoutComponent>
    </ThemeProvider>
  );
}
