import { Badge, styled } from "@mui/material";

interface StyledBadgeProps {
  badgeContent: number | string;
  isSelected: boolean;
  classes?: {
    badge?: string;
  };
}

export const StyledBadge = styled((props: StyledBadgeProps) => (
  <Badge {...props} max={999} />
))(({ theme, isSelected }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: isSelected ? "#193458" : "#EDF0F4",
    color: isSelected ? "#fff" : "#193458",
    top: "50%",
    right: "-10px",
    transform: "translateY(-50%)",
    minWidth: "20px",
    minHeight: "20px",

    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
  },
}));
