import { Stack, Checkbox, TextField, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import {
  IInflatedDashboadPage,
  IValidRangeSettings,
} from '../../Interfaces/newDashboardInterfaces/inflatedDashbaordPage.interfaces';
interface IProps {
  widgetIndex: number;
  widgetClusterIndex: number;
  pageData: IInflatedDashboadPage['pageConfig'];
  setPageData: Function;
  areMultipleSensorSelected: boolean;
  setRefreshStandAloneWidget: Function;
}
function ValidRangeSettings(props: IProps) {
  const {
    widgetIndex,
    widgetClusterIndex,
    pageData,
    setPageData,
    areMultipleSensorSelected,
    setRefreshStandAloneWidget,
  } = props;
  const [validRangeSettings, setValidRangeSettings] = useState<IValidRangeSettings>({
    applyMax: false,
    applyMin: false,
    max: null,
    min: null,
  });

  useEffect(() => {
    if (
      pageData.data[widgetClusterIndex]['widgets'][widgetIndex].hasOwnProperty('validRangeSettings')
    ) {
      let validRangeSettingsObject = //@ts-ignore
        pageData.data[widgetClusterIndex]['widgets'][widgetIndex]['validRangeSettings'];

      setValidRangeSettings(validRangeSettingsObject);
    }
  }, []);

  useEffect(() => {
    if (areMultipleSensorSelected) {
      const tempValidRange = {
        ...validRangeSettings,
        applyMin: false,
        applyMax: false,
      };
      setValidRangeSettings(tempValidRange);
      changeValidRangeSettings(tempValidRange);
    }
  }, [areMultipleSensorSelected]);

  const changeValidRangeSettings = (tempValidRange: IValidRangeSettings) => {
    setPageData((prevPageData: IInflatedDashboadPage['pageConfig']) => {
      const updatedPageData = { ...prevPageData };
      //@ts-ignore
      updatedPageData.data[widgetClusterIndex].widgets[widgetIndex].validRangeSettings =
        tempValidRange;
      return updatedPageData;
    });
    setRefreshStandAloneWidget((prevState: any) => !prevState);
  };

  const handleCheckBoxChange = (key: string, e: any) => {
    const tempValidRange = {
      ...validRangeSettings,
      [key]: e.target.checked,
    };
    setValidRangeSettings(tempValidRange);
    changeValidRangeSettings(tempValidRange);
  };

  const handleInputChange = (key: string, e: any) => {
    try {
      const tempValidRange = {
        ...validRangeSettings,
        [key]: e.target.value === '' ? null : parseFloat(e.target.value),
      };
      setValidRangeSettings(tempValidRange);
      changeValidRangeSettings(tempValidRange);
    } catch (err) {
      // Set Error State
    }
  };
  return (
    <>
      {areMultipleSensorSelected === false ? (
        <></>
      ) : (
        <Typography color="error">Please select only one sensor</Typography>
      )}
      <Stack direction="row" gap={2} alignItems="center">
        <Checkbox
          value={pageData}
          checked={validRangeSettings?.applyMin}
          onChange={(e: any) => handleCheckBoxChange('applyMin', e)}
          sx={{
            color: '#3DA3F5',
            '&.Mui-checked': {
              color: '#3DA3F5',
            },
          }}
          disabled={areMultipleSensorSelected}
        />
        <TextField
          type="number"
          label={<Typography>Min</Typography>}
          value={validRangeSettings.min ?? ''}
          disabled={areMultipleSensorSelected}
          onChange={e => {
            handleInputChange('min', e);
          }}
        />
        <Checkbox
          checked={validRangeSettings.applyMax}
          onChange={(e: any) => {
            handleCheckBoxChange('applyMax', e);
          }}
          disabled={areMultipleSensorSelected}
          sx={{
            color: '#3DA3F5',
            '&.Mui-checked': {
              color: '#3DA3F5',
            },
          }}
        />
        <TextField
          type="number"
          label={<Typography>Max</Typography>}
          value={validRangeSettings.max ?? ''}
          disabled={areMultipleSensorSelected}
          onChange={e => handleInputChange('max', e)}
        />
      </Stack>
    </>
  );
}

export default ValidRangeSettings;
