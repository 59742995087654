import axiosInstance from "../constants/axiosInstance";

const initiateControl = async (
  sensorId: string,
  userId: string,
  equipmentId: string,
  expiryMins: number,
  command: "ON" | "OFF",
  plantId: string
) => {
  try {
    const { data } = await axiosInstance().post(
      `/biDirectionalControl/initiateBiDirectionalEvent`,
      {
        sensorId,
        userId,
        plantId,
        equipmentId,
        expiryMins,
        command,
      }
    );
    return data?.data;
  } catch (err) {
    console.error(`ERROR | initiateControl`, err);
    return;
  }
};

const acknowledgeControl = async (controlId: string, userId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/biDirectionalControl/biDirectionalAcknowledgement`,
      { userId, controlId }
    );
    return data.message;
  } catch (err) {
    console.error(`ERROR | acknowledgeControl`, err);
  }
};

const checkBiDirectionalLoadingStatus = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().get(
      `/biDirectionalControl/checkIfBiDirectionalLoadingIsComplete?plantId=${plantId}`
    );
    return data?.data?.loading;
  } catch (err) {
    console.error(`ERROR | acknowledgeControl`, err);
  }
};

const getBidirectionalSensorsForPlant = async (plantId: String) => {
  try {
    const { data } = await axiosInstance().get(
      `/biDirectionalControl/sensorsWithBidirectionalControl?plantId=${plantId}`
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | getBidirectionalSensorsForPlant | ", err);
  }
};

const checkOngoingControlForSensor = async (sensorId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/biDirectionalControl/checkIfControlIsOngoing`,
      { sensorId }
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | checkIfControlIsOngoing | ", err);
  }
};

const checkIfPlantIsInBidirectionalControl = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/biDirectionalControl/checkIfPlantIsInAutoMode`,
      { plantId }
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | checkIfPlantIsInBidirectionalControl | ", err);
  }
};

const terminateControl = async (controlId: string, userId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/biDirectionalControl/terminateOngoingBidirectionalCommand`,
      { controlId, userId }
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | terminateControl | ", err);
  }
};
const getBiDirectionalHistoriesForPlant = async (plantId: string) => {
  try {
    const { data } = await axiosInstance().post(
      `/biDirectionalControl/biDirectionalHistoriesByPlantId`,
      {
        plantId,
      }
    );
    return data?.data;
  } catch (err) {
    console.error("ERROR | getBiDirectionalHistoriesForPlant | ", err);
  }
};

export {
  initiateControl,
  acknowledgeControl,
  checkBiDirectionalLoadingStatus,
  getBidirectionalSensorsForPlant,
  checkOngoingControlForSensor,
  checkIfPlantIsInBidirectionalControl,
  terminateControl,
  getBiDirectionalHistoriesForPlant,
};
