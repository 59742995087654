import { useEffect, useState } from "react";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchSensorHistory } from "../../../services/sensors";
import HistoryTiles from "./historyTiles";

function SensorHistory(props: { setShowSensorHistory: any; sensorId: any, showSensorHistory: boolean }) {
    const { setShowSensorHistory, showSensorHistory, sensorId } = props;
    const [loading, setLoading] = useState(true);
    const [sensorsHistoryData, setSensorsHistoryData] = useState([]);

    useEffect(() => {
      setLoading(true);
      fetchSensorHistory(sensorId).then((data) => {
        setLoading(false);
        setSensorsHistoryData(data);
      });
    }, [sensorId]);
  
    return (
      <Dialog  onClose={() => { setShowSensorHistory(false) }} open={showSensorHistory}>
        {
          loading && 
          <DialogContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '20rem', height: '20rem' }}>
            <CircularProgress />
          </DialogContent>
        }
        {
          !loading && 
          <>
            <DialogTitle>History</DialogTitle>
            <DialogTitle sx={{ padding: '0 24px', fontSize: '16px' }}>Sensor ID: {sensorId}</DialogTitle>
            <DialogContent>
              {sensorsHistoryData.map((data: any, index) => {
                return (
                  <HistoryTiles
                    key={data?._id}
                    sensorHistory={data}
                    sensorIndex={index}
                  />
                );
              })}
            </DialogContent>
          </>
        }
      </Dialog>
    );
}

export default SensorHistory;