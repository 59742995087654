import ReactFlow, {
  ConnectionLineType,
  MiniMap,
  Background,
  Controls,
  MarkerType,
} from "reactflow";
//@ts-ignore
import dagre from "dagre";
import EquipmentNode from "./CustomNodes/EquipmentNode/EquimentNode";
import "reactflow/dist/style.css";
import classes from "./styles/PlantVisualisationStyle.module.css";

const CurrentPlantDiagram = ({ nodes, edges }: any) => {
  const nodeTypes = {
    equipmentNode: EquipmentNode,
  };

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 500;
  const nodeHeight = 500;

  dagreGraph.setGraph({ rankdir: "LR" });
  nodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, {
      width: nodeWidth + 100,
      height: nodeHeight + 100,
    });
  });
  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });
  dagre.layout(dagreGraph);
  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = "left";
    node.sourcePosition = "right";
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  edges.forEach((edge: any) => {
    if (edge["sensorDataAvailability"]) {
      if (edge["predictedStatus"] === "ON") {
        edge["animated"] = true;
        edge["style"] = { stroke: "#36A2C7", strokeWidth: 4 };
        edge["markerEnd"] = {
          type: MarkerType.ArrowClosed,
          width: 10,
          height: 10,
          color: "#36A2C7",
        };
      } else {
        edge["style"] = {
          stroke: "rgba(0, 0, 0, 0.2)",
          strokeDasharray: "10,10",
          strokeWidth: 4,
        };
        edge["markerEnd"] = {
          type: MarkerType.ArrowClosed,
          width: 10,
          height: 10,
          color: "rgba(0, 0, 0, 0.2)",
        };
      }
    } else {
      edge["style"] = { stroke: "black", strokeWidth: 4 };
      edge["markerEnd"] = {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: "black",
      };
    }
    edge["type"] = "smoothstep";
  });

  return (
    <div className={classes.PlantLayout}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        connectionLineType={ConnectionLineType.SmoothStep}
        minZoom={0}
        maxZoom={4}
        fitView
      >
        <MiniMap />
        <Controls />
        <Background />
      </ReactFlow>
    </div>
  );
};

export default CurrentPlantDiagram;
