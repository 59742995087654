import axiosInstance from "../constants/axiosInstance";

const getAllManualAlertsForPlant = async (id: string | undefined) => {
    try {
        const { data } = await axiosInstance().get(`/alerts/getAllManualAlertsForPlant?plantId=${id}`);
        return data.data;
    } catch (err) {
        console.error("ERROR | getAllManualAlertsForPlant | ", err);
    }
}

const updateManualAlerts = async (
    id: string | undefined,
    fixedTasks:any
  ) => {
    try {
      const { data } = await axiosInstance().post(`/alerts/updateManualAlerts?plantId=${id}`, fixedTasks)
      return data;
    } catch (err) {
      console.error("ERROR | updateManualAlerts | ", err);
    }
  };

export {
    getAllManualAlertsForPlant,
    updateManualAlerts
}
