//@ts-nocheck
import { Autocomplete, Box, Checkbox, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ASSIGN_ROLE, ALERT_TYPES } from "../../constants/constants";
import { PrimaryButton } from "../../styles/global/components/dpButtons";

const styles = {
  sectionTitle: {
    fontFamily: "Montserrat Bold",
    fontSize: 20,
  },
  subSectionTitle: {
    fontFamily: "Montserrat Bold",
    fontSize: 16,
  },
  label: {
    fontFamily: "Montserrat",
    fontSize: 16,
  },
  addButton: {
    background: "#519BD0",
  },
  deleteButton: {
    background: "#519BD0",
  },
  TextFieldStyle: {
    margin: "0.6rem 0"
  }
};

function AlertTaskForm({
  task,
  section,
  subsection,
  indexTask,
  addNewSubTask,
  deleteSubTask,
  updateSubTask,
  updateDirectValue,
}) {
  const handleAutoCallingChange = (event) => {
    updateDirectValue(
      section,
      subsection,
      indexTask,
      "autoCalling",
      event.target.checked
    );
  };

  const handleTypeChange = (event, newValue) => {
    updateDirectValue(section, subsection, indexTask, "type", newValue);
  };

  const handleConditionChange = (event) => {
    updateDirectValue(
      section,
      subsection,
      indexTask,
      "condition",
      event.target.value
    );
  };

  const handleGroupChange = (event) => {
    updateDirectValue(
      section,
      subsection,
      indexTask,
      "group",
      event.target.value
    );
  };

  const handlePriorityChange = (event) => {
    updateDirectValue(
      section,
      subsection,
      indexTask,
      "priority",
      event.target.value
    );
  };

  const handleVideoUrlChange = (event) => {
    updateDirectValue(
      section,
      subsection,
      indexTask,
      "videoUrl",
      event.target.value
    );
  };

  const handleToUserChange = (event, newValue) => {
    updateDirectValue(section, subsection, indexTask, "toUser", newValue);
  };

  return (
    <>
      <Box m={1} p={1}>
        <div style={styles.sectionTitle}>
          SubTasks
          <span style={{ float: "right" }}>
            <PrimaryButton
              onClick={() => addNewSubTask(section, subsection, indexTask)}
            >
              <AddIcon />
              Add New SubTask
            </PrimaryButton>
          </span>
        </div>
      </Box>
      {task["subTasks"].map((subTask, subTaskIndex) => {
        if (typeof task["condition"] !== "string") {
          task["condition"] = JSON.stringify(task["condition"]);
        }
        return (
          <Box key={subTaskIndex} m={1} p={1}>
            <div style={styles.subSectionTitle}>
              SubTask {subTaskIndex + 1}
              <span style={{ float: "right" }}>
                <PrimaryButton
                  onClick={() =>
                    deleteSubTask(section, subsection, indexTask, subTaskIndex)
                  }
                >
                  Delete SubTask
                </PrimaryButton>
              </span>
            </div>
            {["eng", "hindi"].map((lang) => (
              <div key={lang} style={styles.label}>
                  <TextField
                    fullWidth
                    required
                    value={
                      lang == "hindi" ? subTask[`name${lang}`] : subTask[`name`]
                    }
                    label={`Description ${lang}`}
                    onChange={(e) => {
                      updateSubTask(
                        section,
                        subsection,
                        indexTask,
                        subTaskIndex,
                        `${lang == "hindi" ? `name${lang}` : `name`}`,
                        e.target.value
                      );
                    }}
                    style={styles.TextFieldStyle}
                  />
              </div>
            ))}
          </Box>
        );
      })}

      <Box m={1} p={1}>
        <Box m={1} p={1}>
          <div style={styles.title}>
            <Checkbox
              checked={task.autoCalling}
              onChange={handleAutoCallingChange}
            />
            Auto Calling
          </div>
          <div>
            <Autocomplete
              id="tags-standard"
              options={ALERT_TYPES}
              value={task.type}
              onChange={handleTypeChange}
              renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Alert Type"
                    style={styles.TextFieldStyle}
                  />
              )}
            />
          </div>
        </Box>
        <Box m={1} p={1}>
          <TextField
            fullWidth
            required
            multiline
            value={task.condition}
            label="Alert Condition"
            onChange={handleConditionChange}
            style={styles.TextFieldStyle}
          />
        </Box>
        <Box m={1} p={1}>
          <TextField
            fullWidth
            required
            value={task.group}
            label="Alert Group"
            onChange={handleGroupChange}
            style={styles.TextFieldStyle}
          />
        </Box>
        <Box m={1} p={1}>
          <TextField
            fullWidth
            required
            value={task.priority}
            label="Priority"
            type="number"
            onChange={handlePriorityChange}
            style={styles.TextFieldStyle}
          />
        </Box>
        <Box m={1} p={1}>
          <TextField
            fullWidth
            required
            value={task.videoUrl}
            label="Video Url"
            onChange={handleVideoUrlChange}
            style={styles.TextFieldStyle}
          />
        </Box>
        <Box m={1} p={1}>
          <Autocomplete
            id="tags-standard"
            options={ASSIGN_ROLE}
            value={task.toUser}
            onChange={handleToUserChange}
            renderInput={(params) => (
              <TextField
                required
                {...params}
                variant="standard"
                label="For Role"
                style={styles.TextFieldStyle}
              />
            )}
          />
        </Box>
      </Box>
    </>
  );
}

export default AlertTaskForm;
