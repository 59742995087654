import { useEffect } from "react";
import LayoutComponent from "../components/LayoutComponent";
import { getAllUsersForRole, deleteUser } from "../services/users";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { DataGrid, GridCellParams } from "@mui/x-data-grid";
import { userRoleBasedData } from "../Interfaces/userInterface";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddUserPopup from "../components/AddUserPopup/AddUserPopup";
import { ToastContainer, toast } from "react-toastify";
import dpTheme from "../styles/theme/dpTheme";
import { PrimaryButton } from "../styles/global/components/dpButtons";
import { Classes } from "../styles/heading+addButton";


export default function ServiceProvider() {
  const [data, setData] = useState<userRoleBasedData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [edit, setEdit] = useState<boolean>(false);
  const [addUser, setAddUser] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>("");

  useEffect(() => {
    fetchsP();
  }, []);

  const fetchsP = () => {
    setEdit(false);
    setAddUser(false);
    getAllUsersForRole("Service Provider").then((users) => {
      console.log("users", users);
      setData(users);
      setLoading(false);
    });
  };

  const DeleteUser = (id: string, count: number) => {
    console.log(count);
    if (count > 0) {
      toast("Cannot Delete user with Plants Assigned");
    } else {
      deleteUser(id).then((response) => {
        fetchsP();
      });
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Service Provider Name",
      width: 350,
    },
    {
      field: "email",
      headerName: "Service Provider Email",
      width: 350,
    },
    {
      field: "count",
      headerName: "Plants",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params: GridCellParams) => {
        return (
          <>
            <Button
              onClick={() => {
                setUserId(params.row.id);
                setEdit(true);
                setAddUser(true);
              }}
            >
              <EditIcon />
            </Button>
            <Button
              onClick={() => {
                DeleteUser(params.row.id, params.row.count);
              }}
            >
              <DeleteIcon />
            </Button>
          </>
        );
      },
      width: 300,
    },
  ];

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent>
        <Box m={1} p={1}>
          <div style={Classes.body}>
            <Typography variant="h1">Service Providers</Typography>
            <span style={{ float: "right" }}>
              <PrimaryButton
                onClick={() => {
                  setAddUser(true);
                }}
              >
                <AddIcon />
                Add
              </PrimaryButton>
            </span>
          </div>
        </Box>
        <Box m={1} p={1}>
          <div style={{ height: 420 }}>
            <DataGrid rows={data} columns={columns} loading={loading} />
          </div>
        </Box>
        {addUser ? (
          <AddUserPopup
            refresh={fetchsP}
            edit={edit}
            handleClose={() => {
              setAddUser(false);
              setEdit(false);
            }}
            userId={userId}
            role="Service Provider"
          />
        ) : null}
        <ToastContainer />
      </LayoutComponent>
    </ThemeProvider>
  );
}
