import { memo, useState } from 'react';

import { useEffect } from 'react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import TableDWidget from './subComponents/tableDWidget';
import TableCWidget from './subComponents/tableCWidget';
import NumberWidget from './subComponents/numberWidget';
import GeneralisedGraphWidget from './subComponents/generalisedGraphWidget';
import axios from 'axios';

import { widgetDataInterface } from '../../Interfaces/widgetInterfaces';
import { timeFrame } from '../../Interfaces/dashboardPageInterfaces';
import { isAbsoluteURL, isRelativeURLWithoutSlash } from '../../utilities/helpers';
import { getWidgetData } from '../../services/dashboardPage';
import { Box } from '@mui/material';
import { add530Hours } from '../../utils/dashboard.utils';

SwiperCore.use([Navigation, Pagination, Autoplay]);

interface standaloneGeneralisedWidgetProps {
  widgetStructure?: any;
  timeFrame: timeFrame | null;
  refresh: boolean;
  plantId: string;
}

const StandaloneGeneralisedWidget = memo((props: standaloneGeneralisedWidgetProps) => {
  const { widgetStructure, timeFrame, plantId, refresh } = props;
  const [widgetData, setWidgetData] = useState<widgetDataInterface | null>(null);
  // const [refresh2, setRefresh2] = useState(false);
  const [loading, setLoading] = useState(false);
  //@ts-ignore
  const [isError, setIsError] = useState(false);
  useEffect(() => {
    const { startDate, endDate } = add530Hours(timeFrame?.startDate, timeFrame?.endDate);
    setIsError(false);
    setLoading(true);

    getWidgetData({ plantId, widgetStructure, startDate, endDate })
      .then((widgetData: any) => {
        setWidgetData(widgetData);
        setLoading(false);
      })
      .catch(err => {
        if (axios.isCancel(err)) {
        } else {
          setIsError(true);
        }
      });
  }, [widgetStructure, refresh]);

  let linkOnWidget = widgetData?.moreInfo || '';
  let showLinkIcon = true;

  if (linkOnWidget === 'link') {
    showLinkIcon = false;
  } else {
    if (!isAbsoluteURL(linkOnWidget)) {
      const location = window.location.origin;
      if (isRelativeURLWithoutSlash(linkOnWidget)) {
        linkOnWidget = `${location}/${linkOnWidget}`;
      } else {
        linkOnWidget = `${location}${linkOnWidget}`;
      }
    }
  }

  const setExpandedViewPopup = () => {};

  return (
    <>
      {widgetStructure?.widgetType === 'graph' && widgetData !== null ? (
        <GeneralisedGraphWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          setExpandedViewPopup={setExpandedViewPopup}
          isLoading={loading}
          headerType="normal"
          enableZoom={false}
          viewEveryNthTick={2}
          ticksColor="light"
          showLinkIcon={showLinkIcon}
          linkOnWidget={linkOnWidget}
          type={'standalone'}
        />
      ) : null}

      {widgetStructure.widgetType === 'tableC' && widgetData !== null ? (
        <TableCWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          setExpandedViewPopup={setExpandedViewPopup}
          isLoading={loading}
          headerType="normal"
          showLinkIcon={showLinkIcon}
          linkOnWidget={linkOnWidget}
        />
      ) : null}

      {widgetStructure.widgetType === 'tableD' && widgetData !== null ? (
        <TableDWidget
          widgetStructure={widgetStructure}
          widgetData={widgetData}
          setExpandedViewPopup={setExpandedViewPopup}
          isLoading={loading}
          headerType="normal"
          showLinkIcon={showLinkIcon}
          linkOnWidget={linkOnWidget}
        />
      ) : null}

      {widgetStructure.widgetType === 'number' && widgetData !== null ? (
        <Box sx={{ width: '230px' }}>
          <NumberWidget
            widgetStructure={widgetStructure}
            widgetData={widgetData}
            isLoading={loading}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
            background={widgetData.background || '#F27F0C'}
          />
        </Box>
      ) : null}
    </>
  );
});

export default StandaloneGeneralisedWidget;
