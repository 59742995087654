import ReactFlow, { ConnectionLineType, MiniMap, Controls } from "reactflow";
//@ts-ignore
import dagre from "dagre";
import "reactflow/dist/style.css";
import { Typography } from "@mui/material";
import { usePlantLayoutStore } from "../../store/PlantLayoutStore";
import EquipmentEdge from "./CustomEdges/EquipmentEdge";

const PlantLayoutDiagram = () => {
  const { nodes, edges } = usePlantLayoutStore();

  const edgeTypes = {
    equipmentEdge: EquipmentEdge,
  };

  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 200;
  const nodeHeight = 100;

  dagreGraph.setGraph({ rankdir: "LR" });
  nodes.forEach((node: any) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });
  edges.forEach((edge: any) => {
    dagreGraph.setEdge(edge.source, edge.target);
    edge["type"] = "equipmentEdge";
  });
  dagre.layout(dagreGraph);
  nodes.forEach((node: any) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = "left";
    node.sourcePosition = "right";

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x - nodeWidth / 2,
      y: nodeWithPosition.y - nodeHeight / 2,
    };

    return node;
  });

  return (
    <div style={{ textAlign: "center" }}>
      <Typography variant="h6">Plant Layout Diagram</Typography>
      <div
        style={{
          height: "600px",
          margin: "0 auto",
          textAlign: "center",
          border: "1px solid black",
          borderRadius: "5px",
          padding: "20px",
        }}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          edgeTypes={edgeTypes}
          connectionLineType={ConnectionLineType.Step}
          fitView
        >
          <MiniMap />
          <Controls />
        </ReactFlow>
      </div>
    </div>
  );
};

export default PlantLayoutDiagram;
