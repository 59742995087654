import {
  Box,
  MenuItem,
  Select,
  Button,
  TextField,
  ListSubheader,
  Tooltip
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

import DeleteConfirmation from "../../globalComponents/dialogs/DeleteConfirmation";
import { useState } from "react";
export default function AlertConditionComponent(props: {
  condition: any;
  conditionSetIndex: any;
  plantSensorList: any;
  configVarList: any;
  updateCondition: Function;
  alertIndex: any;
  deleteAlertStatement: Function;
  addAlertStatement: any;
  boolSensorTagList: string[];
  conditionType: string;
}) {
  const {
    condition,
    conditionSetIndex,
    plantSensorList,
    configVarList,
    updateCondition,
    alertIndex,
    deleteAlertStatement,
    addAlertStatement,
    boolSensorTagList,
    conditionType,
  } = props;
  console.log(configVarList);
  const operatorList = ["LT", "GT", "LTET", "GTET", "EQ", "NEQ"];
  const boolOperatorList = ["EQ", "NEQ"];
  const thresholdType = ["numeric", "time", "Plant Config", "boolean"];

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [processing, setProcessing] = useState(false);

  // function getTagValue(lhs: string) {
  //   if (lhs.includes("_")) {
  //     console.log(lhs.split("_")[0]);
  //     return lhs.split("_")[0];
  //   }
  // }

  const isBoolSensor = (sensorTag: any) => {
    return boolSensorTagList.includes(sensorTag);
  };

  return (
    <Box>
      {condition.map((statement: any, statementIndex: number) => {
        return (
          <Box
            style={{
              marginTop: "15px",
              marginBottom: "15px",
              justifyContent: "space-evenly",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <Tooltip title={statement["sensorTag"]}>
              <Select
                id={"sensorTag " + statementIndex}
                value={statement["sensorTag"]}
                key={statement["sensorTag"] + statementIndex + "variable"}
                onChange={(e) => {
                  if (isBoolSensor(e.target.value)) {
                    updateCondition(
                      alertIndex,
                      conditionSetIndex,
                      statementIndex,
                      "thresholdType",
                      "boolean",
                      conditionType
                    );
                    updateCondition(
                      alertIndex,
                      conditionSetIndex,
                      statementIndex,
                      "operator",
                      "EQ",
                      conditionType
                    );
                  } else {
                    updateCondition(
                      alertIndex,
                      conditionSetIndex,
                      statementIndex,
                      "thresholdType",
                      "numeric",
                      conditionType
                    );
                  }

                  updateCondition(
                    alertIndex,
                    conditionSetIndex,
                    statementIndex,
                    "sensorTag",
                    e.target.value,
                    conditionType
                  );
                }}
                style={{ width: "175px", marginLeft: "30px" }}
                label="Sensor Tag"
              >
                <ListSubheader style={{ backgroundColor: "#fff" }}>
                  Real Sensors
                </ListSubheader>
                {plantSensorList["Real Sensors"].map((sensor: any) => {
                  return (
                    <MenuItem
                      value={sensor["sensorTag"]}
                      style={
                        isBoolSensor(sensor.sensorTag)
                          ? { background: "#519BD0" }
                          : {}
                      }
                    >
                      {sensor["sensorTag"]}
                    </MenuItem>
                  );
                })}
                <ListSubheader style={{ backgroundColor: "#fff" }}>
                  Virtual Sensors
                </ListSubheader>
                {plantSensorList["Virtual Sensors"].map((sensor: any) => {
                  return (
                    <MenuItem
                      value={sensor["sensorTag"]}
                      style={
                        isBoolSensor(sensor.sensorTag)
                          ? { background: "#519BD0" }
                          : {}
                      }
                    >
                      {sensor["sensorTag"]}
                    </MenuItem>
                  );
                })}
                <ListSubheader style={{ backgroundColor: "#fff" }}>
                  Data Input Sensors
                </ListSubheader>
                {plantSensorList["Data Input Sensors"].map((sensor: any) => {
                  return (
                    <MenuItem
                      value={sensor["sensorTag"]}
                      style={
                        isBoolSensor(sensor.sensorTag)
                          ? { background: "#519BD0" }
                          : {}
                      }
                    >
                      {sensor["sensorTag"]}
                    </MenuItem>
                  );
                })}
              </Select>
              </Tooltip>
              {isBoolSensor(statement["sensorTag"]) ? (
                <Select
                  id={"operator " + statementIndex}
                  value={statement["operator"]}
                  key={statement["operator"] + statementIndex}
                  onChange={(e) =>
                    updateCondition(
                      alertIndex,
                      conditionSetIndex,
                      statementIndex,
                      "operator",
                      e.target.value,
                      conditionType
                    )
                  }
                  style={{ width: "75px", marginLeft: "30px" }}
                  label="Logical Operator"
                >
                  {boolOperatorList.map((operator) => {
                    return <MenuItem value={operator}>{operator}</MenuItem>;
                  })}
                </Select>
              ) : (
                <Select
                  id={"operator " + statementIndex}
                  value={statement["operator"]}
                  key={statement["operator"] + statementIndex}
                  onChange={(e) =>
                    updateCondition(
                      alertIndex,
                      conditionSetIndex,
                      statementIndex,
                      "operator",
                      e.target.value,
                      conditionType
                    )
                  }
                  style={{ width: "75px", marginLeft: "30px" }}
                  label="Logical Operator"
                >
                  {operatorList.map((operator) => {
                    return <MenuItem value={operator}>{operator}</MenuItem>;
                  })}
                </Select>
              )}

              {isBoolSensor(statement["sensorTag"]) ? (
                <Select
                  id={"inputType" + statementIndex}
                  value="boolean"
                  disabled={isBoolSensor(statement["sensorTag"])}
                  key={statement["thresholdType"] + statementIndex}
                  onChange={(e) =>
                    updateCondition(
                      alertIndex,
                      conditionSetIndex,
                      statementIndex,
                      "thresholdType",
                      e.target.value,
                      conditionType
                    )
                  }
                  style={{ width: "125px", marginLeft: "30px" }}
                  label="Threshold Type"
                >
                  <MenuItem value="boolean">{"boolean"}</MenuItem>;
                </Select>
              ) : (
                <Select
                  id={"inputType" + statementIndex}
                  value={statement["thresholdType"]}
                  disabled={isBoolSensor(statement["sensorTag"])}
                  key={statement["thresholdType"] + statementIndex}
                  onChange={(e) =>
                    updateCondition(
                      alertIndex,
                      conditionSetIndex,
                      statementIndex,
                      "thresholdType",
                      e.target.value,
                      conditionType
                    )
                  }
                  style={{ width: "125px", marginLeft: "30px" }}
                  label="Threshold Type"
                >
                  {thresholdType.map((type: string) => {
                    if (type !== "boolean") {
                      return <MenuItem value={type}>{type}</MenuItem>;
                    } else {
                      return <></>;
                    }
                  })}
                </Select>
              )}
              {statement["thresholdType"] === "Plant Config" && (
                <Select
                  id={"inputType" + statementIndex}
                  value={statement["threshold"]}
                  key={statement["threshold"] + statementIndex}
                  onChange={(e) =>
                    updateCondition(
                      alertIndex,
                      conditionSetIndex,
                      statementIndex,
                      "threshold",
                      e.target.value,
                      conditionType
                    )
                  }
                  style={{ width: "125px", marginLeft: "30px" }}
                  label="Threshold"
                >
                  {configVarList.varList.map(
                    (variable: string, index: number) => {
                      return (
                        <MenuItem key={index} value={variable}>
                          {variable}
                        </MenuItem>
                      );
                    }
                  )}
                </Select>
              )}
              {statement["thresholdType"] === "numeric" && (
                <>
                  <TextField
                    id="outlined-basic"
                    label="Threshold"
                    variant="outlined"
                    value={statement["threshold"]}
                    required
                    type="number"
                    style={{ width: "175px", marginLeft: "30px" }}
                    onChange={(e) => {
                      updateCondition(
                        alertIndex,
                        conditionSetIndex,
                        statementIndex,
                        "threshold",
                        e.target.value,
                        conditionType
                      );
                    }}
                  />
                </>
              )}
              {statement["thresholdType"] === "boolean" && (
                <Select
                  id={"inputType" + statementIndex}
                  value={statement["threshold"]}
                  onChange={(e) =>
                    updateCondition(
                      alertIndex,
                      conditionSetIndex,
                      statementIndex,
                      "threshold",
                      e.target.value,
                      conditionType
                    )
                  }
                  style={{ width: "125px", marginLeft: "30px" }}
                  label="Threshold"
                >
                  <MenuItem value={0}>{0}</MenuItem>
                  <MenuItem value={1}>{1}</MenuItem>
                </Select>
              )}

              {statement["thresholdType"] === "time" && (
                <>
                  <TextField
                    id="outlined-basic"
                    label="Threshold"
                    variant="outlined"
                    value={statement["threshold"]}
                    required
                    type="number"
                    style={{ width: "175px", marginLeft: "30px" }}
                    onChange={(e) => {
                      updateCondition(
                        alertIndex,
                        conditionSetIndex,
                        statementIndex,
                        "threshold",
                        e.target.value,
                        conditionType
                      );
                    }}
                  />

                  <TextField
                    id="outlined-basic"
                    label="Comparison Time"
                    variant="outlined"
                    value={statement["comparisonTime"]}
                    key={statement["comparisonTime"] + statementIndex}
                    required
                    helperText="DD:HH:MM:SS"
                    onChange={(e) =>
                      updateCondition(
                        alertIndex,
                        conditionSetIndex,
                        statementIndex,
                        "comparisonTime",
                        e.target.value,
                        conditionType
                      )
                    }
                  />
                </>
              )}
              <Button
                onClick={(e) => {
                  setShowDeleteModal(true);
                }}
              >
                <DeleteIcon />
              </Button>
            </Box>
            <Box
              style={{
                display: "block",
                textAlign: "center",
              }}
            >
              {statementIndex <= condition.length - 2 ? (
                <p>__________AND__________</p>
              ) : (
                <></>
              )}
            </Box>
            <DeleteConfirmation
              showDeleteModal={showDeleteModal}
              setShowDeleteModal={setShowDeleteModal}
              alertMessage={"You are deleting a condition."}
              processing={processing}
              setProcessing={setProcessing}
              onConfirmAction={() => {
                deleteAlertStatement(
                  alertIndex,
                  conditionSetIndex,
                  statementIndex,
                  conditionType
                );
                setProcessing(false);
              }}
            />
          </Box>
        );
      })}
      <Box
        style={{
          display: "block",
          textAlign: "center",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        {condition.length > 0 ? (
          <>
            <Button
              style={{ background: "#519BD0", color: "#fff" }}
              onClick={(e) =>
                addAlertStatement(alertIndex, conditionSetIndex, conditionType)
              }
            >
              AND
            </Button>
          </>
        ) : (
          <>
            <Button
              style={{ background: "#519BD0", color: "#fff" }}
              onClick={(e) =>
                addAlertStatement(alertIndex, conditionSetIndex, conditionType)
              }
            >
              ADD
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
}
