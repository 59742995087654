// useHistoryTracker.ts
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useHistoryStore from "../../store/useHistoryStore";

const useHistoryTracker = (): void => {
  const location = useLocation();
  const { addToHistory } = useHistoryStore();

  useEffect(() => {
    addToHistory(location.pathname);
  }, [location, addToHistory]);
};

export default useHistoryTracker;
