import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';

import OptionsMenu from './OptionsMenu';
import TwoRowLayout from '../../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import SearchBox from '../../../../core-components/theme/components/Searchbox/Searchbox';
import { Neutrals } from '../../../../core-components/theme/color-palette/colors';
import UserNameIcon from '../../../../core-components/assets/icons/UsernameIcon';
import LayoutComponent from '../../../../components/LayoutComponent';
import GroupIcon from '../../../../core-components/assets/icons/GroupIcon';
import Table from '../../../../core-components/theme/components/Table/Table';
import { getUserGroupList, deleteUserGroup } from '../../../../services/userGroup/userGroup';
import Button from '../../../../core-components/theme/components/Button/Button';
const MAX_VISIBLE_ITEMS = 3;

const UserGroupListPage = () => {
  // const [workspacerowsdata, setWorkspaceRowsData] = useState<any>([]);
  // const [userGroupList, setuserGroupList] = useState<any>([]);
  const [filterQuery, setfilterQuery] = useState('');
  const navigate = useNavigate();
  const handleSearch = (query: string) => {
    setfilterQuery(query.trim());
    // setWorkspaceRowsData(filteredRows);
  };
  const handleClear = () => {
    setfilterQuery('');
    // setWorkspaceRowsData(workspacerowsdata);
  };

  const handleEditUserGroup = (id: string) => {
    navigate(`/editUserGroup/${id}`, { state: { id } });
  };

  const { data: userGroupData, status: userGroupDataStatus } = useQuery({
    queryKey: ['userGroupList'],
    queryFn: getUserGroupList,
    refetchOnWindowFocus: false,
  });
  function filterUserGroup() {
    const filteredRows = userGroupData?.filter((row: any) =>
      row.name.toLowerCase().includes(filterQuery.toLowerCase())
    );
    return filteredRows;
  }

  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (id: any) => deleteUserGroup(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userGroupList'] });
      toast.success(`User Group deleted`);
    },
    onError: (error: any) => {
      toast.error(`Problem in deleting User Group`);
      console.error('Failed to delete Usergroup:', error);
    },
  });

  const handleDeleteUserGroup = (id: string) => {
    mutation.mutate(id);
  };

  useEffect(() => {
    if (userGroupDataStatus === 'success') {
      // setWorkspaceRowsData(workspaceData);
      // setuserGroupList(userGroupData);
    }
  }, [userGroupData, userGroupDataStatus]);
  const columns = [
    {
      field: 'name',
      headerName: 'Group Name',
      renderCell: (value: 'string') => {
        return (
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
            <GroupIcon />
            <Typography noWrap variant="h4" ml={2}>
              {value}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'userIds',
      headerName: 'User Name',
      renderCell: (value: any[]) => {
        const userGroupData = value.slice(0, MAX_VISIBLE_ITEMS);
        const hasMore = value.length > MAX_VISIBLE_ITEMS;
        return (
          <Stack
            direction="row"
            sx={{
              display: 'flex',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            {userGroupData.map((userGroup: any) => (
              <>
                <UserNameIcon />
                <Typography variant="h4" ml={2} mr={2} noWrap>
                  {userGroup.name}
                </Typography>
              </>
            ))}
            {hasMore && (
              <Tooltip title={value.slice(MAX_VISIBLE_ITEMS).map(item => item.name + ', ')}>
                <Typography
                  noWrap
                  variant="body2"
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '100%',
                    ml: 1,
                  }}
                >
                  ...
                </Typography>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{ pl: 4, pr: 4 }}
        child1={
          <Box sx={{ width: '100%' }}>
            <Button
              sx={{ mb: 2 }}
              onClick={() => navigate('/createUserGroup')}
              endIcon={<AddIcon />}
            >
              Create
            </Button>
            <SearchBox
              placeholder="Search Group"
              sx={{
                width: '100%',
                flexGrow: 1,
                height: '52px',
                borderColor: `${Neutrals[500]}`,
                borderRadius: '4px',
              }}
              onSearch={handleSearch}
              onClear={handleClear}
            />
          </Box>
        }
        child2={
          <Box>
            <Table
              rows={filterUserGroup() || []}
              columns={columns}
              renderOptionsCell={row => (
                <OptionsMenu
                  row={row}
                  handleDeleteUserGroup={handleDeleteUserGroup}
                  handleEditUserGroup={handleEditUserGroup}
                />
              )}
            />
          </Box>
        }
      />
    </LayoutComponent>
  );
};

export default UserGroupListPage;
