import { useRef, useState } from 'react';
import { TextField, IconButton, Stack, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { isNameProperlyFormatted } from '../../utils/name.utils';
import { toast } from 'react-toastify';
import useOutsideClickDetector from '../../utilities/customHooks/useOutsideClickDetector';
import { delay } from '../../utils/time.utils';

interface PropsInterface {
  text: string;
  fontSize?: number;
  color?: string;
  fontWeight?: number;
  handleValueChange: Function;
  errorText?: string;
  placeHolder?: string;
  allowEmptyField?: boolean;
  fullWidth?: boolean;
  minWidth?: string | number;
  maxWidth?: string | number;
  editIconProps?: {
    fontSize: number;
    color?: string;
  };
}

export default function EditableHeading(props: PropsInterface) {
  const {
    text,
    handleValueChange,
    fontSize,
    fontWeight,
    color,
    errorText = 'Field can not be left empty !',
    placeHolder = 'Placeholder',
    allowEmptyField = false,
    fullWidth = false,
    minWidth = '300px',
    maxWidth = '300px',
    editIconProps,
  } = props;
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const textFieldRef = useRef<any>(null);

  const styles = {
    textStyle: {
      fontSize: fontSize ?? 30,
      fontWeight: fontWeight ?? 400,
      color: color ?? 'black',
    },
  };

  useOutsideClickDetector(textFieldRef, async () => {
    await delay(500);
    setAllowEdit(false);
  });

  const handleBlur = (e: any) => {
    const newValue = e.target.value;
    if (allowEmptyField || isNameProperlyFormatted(newValue)) {
      handleValueChange(newValue);
    } else {
      toast.error(errorText);
      if (textFieldRef.current) {
        textFieldRef.current.value = text;
        textFieldRef.current.focus();
      }
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={2}
      sx={{
        minWidth: minWidth,
        maxWidth: fullWidth ? '100%' : maxWidth,
      }}
    >
      {allowEdit ? (
        <TextField
          fullWidth={fullWidth}
          type="text"
          defaultValue={text || ''}
          inputRef={textFieldRef}
          variant="standard"
          placeholder={placeHolder}
          multiline
          maxRows={2}
          disabled={false}
          inputProps={{
            maxLength: 200,
            style: styles.textStyle,
            cursor: 'caret',
          }}
          onBlur={handleBlur}
          sx={{
            maxWidth: fullWidth ? '100%' : maxWidth,
            minWidth: minWidth,
          }}
        />
      ) : (
        <Box
          sx={{
            ...styles.textStyle,
            minWidth: minWidth,
            maxWidth: fullWidth ? '100%' : maxWidth,
          }}
        >
          {text || placeHolder}
        </Box>
      )}
      <IconButton
        onClick={() => {
          setAllowEdit(true);
          setTimeout(() => textFieldRef.current?.focus(), 100);
        }}
      >
        <EditIcon sx={{ fontSize: editIconProps?.fontSize ?? '100%' }} />
      </IconButton>
    </Stack>
  );
}
