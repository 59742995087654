import axiosInstance from "../constants/axiosInstance";

const createSolution = async (form: any) => {
  try {
    const { data } = await axiosInstance().post(
      `/store/createSolution`,
      form
    );
    console.log("data", data);
    return data;
  } catch (err) {
    console.error("ERROR | createSolution  | ", err);
    throw err;
  }
};

export default createSolution;
