import { useState } from 'react';
import { FormControl, TextField, CircularProgress } from '@mui/material';
import loginStyles from '../styles/login.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LayoutComponent from '../components/LayoutComponent';
import { PrimaryRoundedButton } from '../styles/global/components/dpButtons';
import { resetPassword } from '../services/users';

export default function Login() {
  const classes = loginStyles;
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const handleSubmit = () => {
    let isValidInput = validateInput();
    if (isValidInput) {
      setLoading(true);
      resetPassword(newPassword)
        .then(data => {
          toast('Passord Changed Successfully');
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    }
  };

  const validateInput = () => {
    let areBothInputEqual = false;
    let isPasswordValid = false;
    let isNewPasswordValid = false;
    if (newPassword.length === 0) {
      setNewPasswordError('Field cannot be left blank');
      isNewPasswordValid = false;
    } else {
      setNewPasswordError('');
      isNewPasswordValid = true;
    }

    if (password.length === 0) {
      setPasswordError('Field cannot be left blank');
      isPasswordValid = false;
    } else {
      setPasswordError('');
      isPasswordValid = true;
    }

    if (password !== newPassword) {
      setPasswordError('Passwords do not match');
      setNewPasswordError('Passwords do not match');
      areBothInputEqual = false;
    } else {
      areBothInputEqual = true;
    }

    if (isPasswordValid && isNewPasswordValid && areBothInputEqual) return true;
    else return false;
  };

  return (
    <LayoutComponent>
      <div className={classes.container}>
        <h2 className={classes.heading}>Reset Password</h2>
        <FormControl fullWidth={true} className={classes.form}>
          <div className={classes.inputContainer}>
            <TextField
              className={classes.input}
              required
              id="password"
              label="Password"
              variant="outlined"
              type="password"
              InputProps={{ style: { fontFamily: 'Montserrat' } }}
              error={passwordError.length > 0 ? true : false}
              //Remove helperText if error not wanted as subtext and call toast("Error") instead in validateInput
              helperText={passwordError.length > 0 ? passwordError : null}
              value={password}
              onChange={e => {
                setPassword(e.target.value);
              }}
            />
          </div>
          <div className={classes.inputContainer}>
            <TextField
              className={classes.input}
              required
              value={newPassword}
              type="password"
              error={newPasswordError.length > 0 ? true : false}
              //Remove helperText if error not wanted as subtext and call toast("Error") instead in validateInput
              helperText={newPasswordError.length > 0 ? newPasswordError : null}
              onChange={e => {
                setNewPassword(e.target.value);
              }}
              id="newPassword"
              label="Reset Password"
              variant="outlined"
            />
          </div>
          <div className={classes.inputContainer}>
            <PrimaryRoundedButton
              className={classes.submitButton}
              onClick={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              Reset
            </PrimaryRoundedButton>
          </div>
        </FormControl>
        <ToastContainer />
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : null}
      </div>
    </LayoutComponent>
  );
}
