//@ts-nocheck
import { useEffect, useState, useRef } from "react";
import axiosInstance from "../../../constants/axiosInstance";
import Webcam from "react-webcam";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Autocomplete from "@mui/lab/Autocomplete";
import { Button, Box, TextField, Grid } from "@mui/material";

let imgsrc: any = "";
const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "environment",
};

export default function UploadImagePopup(props) {
  const history = useNavigate();
  const { handleClose, taskId, refresh, role, id } = props;
  const [checkedInUser, setCheckedInUser] = useState([]);
  const webCamRef = useRef();
  const [userSelected, setUserSelected] = useState({});
  const [imageCaptured, setImageCaptured] = useState(false);
  const [uploadedFile, setUploadedFile] = useState();

  const handleValueChange = (e) => {
    setUserSelected(e.target.value);
    console.log(e.target.value);
  };

  function DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  const handleSubmit = async (e) => {
    let data = new FormData();
    let uploadData: any = await DataURIToBlob(imgsrc);

    data.append(
      "upload",
      uploadData,
      "operatorImage.jpeg"
    );

    if (uploadedFile) uploadData = uploadedFile;

    if (role === "Operator Group") {
      if (typeof userSelected != 'object' || Object.keys(userSelected).length === 0) {
        toast("Please Select a User!!!");
      } else {
        axiosInstance()
          .post(`/tasks/addImage?id=${userSelected.id}&taskId=${taskId}`, data)
          .then(({ data }) => {
            refresh();
          });
      }
    } else {
      axiosInstance()
        .post(`/tasks/addImage?id=${id}&taskId=${taskId}`, data)
        .then(({ data }) => {
          refresh();
        });
    }
  };

  useEffect(() => {
    axiosInstance()
      .get("/users/checkedInUsers")
      .then(({ data }) => {
        console.log(data);
        setCheckedInUser(data.user);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const capture = () => {
    if (imageCaptured) {
      setImageCaptured(false);
    } else {
      imgsrc = webCamRef.current.getScreenshot();
      console.log(imgsrc);
      setImageCaptured(true);
    }
  };

  const uploadImage = (file: any) => {
    imgsrc = URL.createObjectURL(file);
    setImageCaptured(true);
    setUploadedFile(file);
  };

  return (
    <div className="popup-box">
      <div className="box">
        <span className="close-icon" onClick={handleClose}>
          x
        </span>
        <div>Upload Image</div>
        <Grid container>
          <Grid item xs={12} sm={9} md={9} lg={9}>
            {imageCaptured ? (
              <img
                src={imgsrc}
                alt="cameraIcon"
                height={720}
                width={1280}
                style={{
                  paddingTop: "43.75px",
                  paddingBottom: "43.75px",
                  width: "200px",
                  height: "112.5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            ) : (
              <>
                <Webcam
                  audio={false}
                  height={720}
                  ref={webCamRef}
                  screenshotFormat="image/jpeg"
                  width={1280}
                  videoConstraints={videoConstraints}
                  style={{
                    width: "200px",
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={3} md={3} lg={3}>
            {role === "Operator Group" ? (
              <Box p={1}>
                <Autocomplete
                  id="tags-standard-1"
                  fullWidth
                  options={checkedInUser}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  value={userSelected}
                  onChange={(event, newValue) => {
                    setUserSelected(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      variant="standard"
                      label="Select Operator"
                    />
                  )}
                />
              </Box>
            ) : null}

            <Box p={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={capture}
                style={{ fontSize: "11px" }}
              >
                {imageCaptured ? <>Reclick Photo</> : <>Click Photo</>}
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  document.getElementById("ImageInput").click();
                }}
                style={{ fontSize: "11px" }}
              >
                Upload Photo
                <input
                  type="file"
                  id="ImageInput"
                  onChange={(event) => {
                    uploadImage(event.target.files[0]);
                  }}
                  onClick={(event) => (event.target.value = null)}
                  hidden
                />
              </Button>
            </Box>
            <Box p={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                disabled={!imageCaptured}
                style={{ fontSize: "11px" }}
              >
                Submit Photo
              </Button>
            </Box>
          </Grid>
        </Grid>
        <div>
          {/* <select onChange={handleValueChange}>
            <option value=" "> </option>
                {checkedInUser.map(user=>{
                    return(
                        <option value={user}>{user}</option>
                    )
                })
                }
          </select> */}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
