import axiosInstance from '../../constants/axiosInstance';

const saveUserGroup = async (userGroup: any) => {
  try {
    let { data } = await axiosInstance().post(`/newUsers/saveUserGroup`, userGroup);
    return data?.data;
  } catch (err) {
    console.error('ERROR | saveUserGroup | ', err);
    throw err;
  }
};

const getUserGroupDetails = async (userGroupId: string) => {
  try {
    let { data } = await axiosInstance().post(`/newUsers/getUserGroupDetails`, userGroupId);
    return data?.data;
  } catch (err) {
    console.error('ERROR | getUserGroupDetails | ', err);
    throw err;
  }
};

const getUserGroupList = async () => {
  try {
    let { data } = await axiosInstance().post(`/newUsers/userGroupList`);
    return data?.data;
  } catch (err) {
    console.error('ERROR | getUserGroupList | ', err);
    throw err;
  }
};

const deleteUserGroup = async (userGroupId: string) => {
  try {
    let { data } = await axiosInstance().post(`/newUsers/deleteUserGroup/`, { userGroupId });
    return data?.data;
  } catch (err) {
    console.error('ERROR | deleteUserGroup | ', err);
    throw err;
  }
};

const getUserList = async (payload: any) => {
  try {
    let { data } = await axiosInstance().post(`/newUsers/getUserList`, payload);
    return data?.data;
  } catch (err) {
    console.error('ERROR | getUserList | ', err);
    throw err;
  }
};

const getWorkspaceList = async (payload: any) => {
  try {
    let { data } = await axiosInstance().post(`/newUsers/getWorkspaceList`, payload);
    return data?.data;
  } catch (err) {
    console.error('ERROR | getWorkspaceList | ', err);
    throw err;
  }
};

const getFeatureList = async (payload: any) => {
  try {
    let { data } = await axiosInstance().post(`/newUsers/getFeatureList`, payload);
    return data?.data;
  } catch (err) {
    console.error('ERROR | getFeatureList | ', err);
    throw err;
  }
};

export {
  saveUserGroup,
  getUserGroupList,
  getUserGroupDetails,
  deleteUserGroup,
  getUserList,
  getWorkspaceList,
  getFeatureList,
};
