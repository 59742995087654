import { useEffect, useRef, useState } from 'react';
import { dia, shapes } from '@joint/plus';
// @ts-ignore
import { EventSourcePolyfill } from 'event-source-polyfill';
import { Pump, PumpView } from './elements/pumpNormal/pumpNormal.element';
import { LiquidTankClorination } from './elements/tankClorination/tankClorination.element';
import { Zone } from './elements/zone/zone.element';
import {
  PumpSubmersible,
  PumpSubmersibleView,
} from './elements/pumpSubmersible/pumpSubmersible.element';
import { ControlValve, ControlValveView } from './elements/controlValve/controlValve.element';
import { Pipe, PipeView, Join } from './elements/pipe/pipe.element';
import { DosingTank } from './elements/dosingTank/dosingTank.element';
import { OverHeadTank, Panel, PanelView } from './elements/tankOverHead/tankOverHead.element';
import { BoreWell, Panel2, Panel2View } from './elements/borewell/borewell.element';
import { Control } from './control/control.element';
import { usePlantLayoutStore } from '../../store/PlantLayoutStore';
import { getTimeBasedLayoutDataForAssetId } from '../../services/sseApis';

import './welspun.css';

const LIQUID_COLOR = '#00A2FF';
const MAX_LIQUID_COLOR = '#00A2FF';
const MIN_LIQUID_COLOR = '#FFD23F';

function WelspunLayoutDemo(props: any) {
  const paperRef = useRef<HTMLDivElement>(null);
  const backendApi = import.meta.env.VITE_APP_ENDPOINT;
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const nodesRef = useRef([]);

  const { sseSwitchedOn, timeFrame } = props;

  nodesRef.current = nodes;

  const { setBiSensorId, setBiSensorStatus, setShowControlPrompt, setEquipmentNickName } =
    usePlantLayoutStore();

  useEffect(() => {
    pumpSubmersible1.addTo(graph);
    inletValve.addTo(graph);
    bypassValve.addTo(graph);
    // clorinationTank.addTo(graph);
    clorinationJoint.addTo(graph);
    chlorinePump.addTo(graph);
    submersiblePumpJoin.addTo(graph);
    chlorineDosingTank.addTo(graph);
    rainWaterResovoir.addTo(graph);
    overHeadTank.addTo(graph);
    panel1.addTo(graph);
    panelBorewell.addTo(graph);
    overHeadTank.embed(panel1);
    outletValve.addTo(graph);
    supplyToVillage.addTo(graph);
    boreWell.addTo(graph);
    borewellPressureTransmitter.addTo(graph);
    chlorineAnalyser.addTo(graph);
    borewellPump1ToBorewellJoin.addTo(graph);
    borewellJoinInletValve.addTo(graph);
    inletValveBypassValve.addTo(graph);
    chlorineDosingTanktochlorinePump.addTo(graph);
    chlorinePumptoClorinationTank.addTo(graph);
    bypassValveToRainWaterResovoir.addTo(graph);
    inletValveTochlorineTank.addTo(graph);
    clorinationTankToOverHeadTank.addTo(graph);
    overHeadTankToOutletValve.addTo(graph);
    outletValveToSupplyToVillage.addTo(graph);
    borewellPressureTransmitterLink.addTo(graph);
    turbiditySensorLink.addTo(graph);
    chlorineAnalyserLink.addTo(graph);
    flowmetreLink.addTo(graph);
    turbiditySensor.addTo(graph);
    flowmetre.addTo(graph);
    submersiblePumpRPMSensor.addTo(graph);
    RPMsLink.addTo(graph);
    paperRef.current!.appendChild(paper.el);

    //////////////////////// Button Subscriptions  //////////////////////

    pumpSubmersible1.listenTo(pumpSubmersible1.findView(paper), 'cell:pointerclick ', (e, a, k) => {
      if (e.target.className === 'remoteControlButtonClick') {
        let nodeId = 'Pump10';
        let sensorId = '6641cfe2c09cc51adba6f3e6';

        setEquipmentNickName('Borewell Pump');
        setBiSensorId(sensorId);
        let foundSensor = findSensorBasedOnNodeIdAndSensorId(nodeId, sensorId);
        if (foundSensor) {
          let biSensorStatus = parseInt(foundSensor.value) ? true : false;
          setBiSensorStatus(biSensorStatus);
        }
        setShowControlPrompt(true);
      }
    });

    chlorinePump.listenTo(chlorinePump.findView(paper), 'cell:pointerclick ', (e, a, k) => {
      if (e.target.className === 'remoteControlButtonClick') {
        let nodeId = 'Pump9';
        let sensorId = '6641cfe2c09cc51adba6f3e3';
        setEquipmentNickName('Chlorine Pump');
        setBiSensorId(sensorId);
        let foundSensor = findSensorBasedOnNodeIdAndSensorId(nodeId, sensorId);
        if (foundSensor) {
          let biSensorStatus = parseInt(foundSensor.value) ? true : false;
          setBiSensorStatus(biSensorStatus);
        }
        setShowControlPrompt(true);
      }
    });

    inletValve.listenTo(inletValve.findView(paper), 'cell:pointerclick ', (e, a, k) => {
      if (e.target.className === 'remoteControlButtonClick') {
        let nodeId = 'Actuator Valve1';
        let sensorId = '6641cfe2c09cc51adba6f3f1';
        setEquipmentNickName('Inlet Valve');
        setBiSensorId(sensorId);
        let foundSensor = findSensorBasedOnNodeIdAndSensorId(nodeId, sensorId);
        if (foundSensor) {
          let biSensorStatus = parseInt(foundSensor.value) ? true : false;
          setBiSensorStatus(biSensorStatus);
        }
        setShowControlPrompt(true);
      }
    });

    bypassValve.listenTo(bypassValve.findView(paper), 'cell:pointerclick ', (e, a, k) => {
      if (e.target.className === 'remoteControlButtonClick') {
        let nodeId = 'Actuator Valve2';
        let sensorId = '6641cfe2c09cc51adba6f3ee';
        setEquipmentNickName('Bypass Valve');
        setBiSensorId(sensorId);
        let foundSensor = findSensorBasedOnNodeIdAndSensorId(nodeId, sensorId);
        if (foundSensor) {
          let biSensorStatus = parseInt(foundSensor.value) ? true : false;
          setBiSensorStatus(biSensorStatus);
        }
        setShowControlPrompt(true);
      }
    });

    outletValve.listenTo(outletValve.findView(paper), 'cell:pointerclick', (e, a, k) => {
      if (e.target.className === 'remoteControlButtonClick') {
        let nodeId = 'Actuator Valve3';
        let sensorId = '6641cfe2c09cc51adba6f3ee';
        setEquipmentNickName('Outlet Valve');
        setBiSensorId(sensorId);
        let foundSensor = findSensorBasedOnNodeIdAndSensorId(nodeId, sensorId);
        if (foundSensor) {
          let biSensorStatus = parseInt(foundSensor.value) ? true : false;
          setBiSensorStatus(biSensorStatus);
        }
        setShowControlPrompt(true);
      }
    });

    addControls(paper);
  }, []);

  // Save Graphs

  graph.on('change:position', cell => {
    savePositions(graph);
  });

  function savePositions(graph: any) {
    const cells = graph.getCells();
    const positions = cells.map((cell: any) => ({
      customId: cell.attr('customId'),
      position: cell.get('position'),
    }));
    localStorage.setItem('shapePositions', JSON.stringify(positions));
  }

  function updatePipeStatus(pipe: any) {
    let foundEdge: any = edges.find((e: any) => {
      return (
        e.source == pipe.attributes?.attrs?.sourceId && e.target == pipe.attributes?.attrs?.targetId
      );
    });

    if (foundEdge && foundEdge.predictedStatus == 'ON') {
      let cellView = pipe.findView(paper);
      // let cellView = paper.findViewByModel(borewellPump1ToBorewellJoin);
      if (!cellView) return;
      // @ts-ignore
      cellView.model.setFlow(1);
    } else if (foundEdge && foundEdge.predictedStatus == 'OFF') {
      let cellView = pipe.findView(paper);
      if (!cellView) return;
      // @ts-ignore
      cellView.model.setFlow(0);
    }
  }

  // function updatePipeStatusForMultiplePipes(pipe: any, pipes: any[]) {
  //   let allPipesOn = true;

  //   for (let i = 0; i < pipes.length; i++) {
  //     const currentPipe = pipes[i];
  //     const foundEdge: any = edges.find((e: any) => {
  //       return (
  //         e.source == currentPipe.attributes?.attrs?.sourceId &&
  //         e.target == currentPipe.attributes?.attrs?.targetId
  //       );
  //     });

  //     if (!foundEdge || foundEdge.predictedStatus !== 'ON') {
  //       allPipesOn = false;
  //       break;
  //     }
  //   }

  //   let cellView = pipe.findView(paper);
  //   if (!cellView) return;

  //   if (allPipesOn) {
  //     // @ts-ignore
  //     cellView.model.setFlow(1);
  //   } else {
  //     // @ts-ignore
  //     cellView.model.setFlow(0);
  //   }
  // }

  function updatePipeStatusByMultipleOnOffSensor(pipe: any, sensorsInfo: any[]) {
    let allSensorsOn = true;

    for (let i = 0; i < sensorsInfo.length; i++) {
      const currentSensorInfo = sensorsInfo[i];
      let equipmentId = currentSensorInfo.equipmentId;
      let sensorTag = currentSensorInfo.sensorTag;
      let foundSensor = findSensorBasedOnNodeIdAndSensorTag(equipmentId, sensorTag);

      if (!foundSensor || foundSensor?.value < 1) {
        allSensorsOn = false;
        break;
      }
    }

    let cellView = pipe.findView(paper);
    if (!cellView) return;

    if (allSensorsOn) {
      // @ts-ignore
      cellView.model.setFlow(1);
    } else {
      // @ts-ignore
      cellView.model.setFlow(0);
    }
  }

  function updatePumpStatus(pump: any) {
    let foundNode: any = nodes.find((node: any) => node.id == pump.attributes?.attrs?.equipmentId);
    if (!foundNode) return;
    let sensors = foundNode?.data?.configInfo?.sensors;
    let onoffsensor = sensors?.find((sensor: any) => {
      return sensor?.sensorTag?.includes('ONOFF_');
    });

    if (onoffsensor && onoffsensor?.value > 0) {
      let cellView = pump.findView(paper);
      // let cellView = paper.findViewByModel(borewellPump1ToBorewellJoin);
      if (!cellView) return;
      // @ts-ignore
      cellView.model.power = 1;
    } else {
      let cellView = pump.findView(paper);
      if (!cellView) return;
      // @ts-ignore
      cellView.model.power = 0;
    }
  }

  function findSensorBasedOnNodeIdAndSensorId(nodeId: string, sensorId: string) {
    let foundNode: any = nodesRef.current?.find((node: any) => node.id == nodeId);
    let sensors: any = foundNode?.data?.configInfo?.sensors;
    let foundSensor = sensors?.find((sensor: any) => {
      return sensor._id == sensorId;
    });
    if (foundSensor) return foundSensor;
    else return null;
  }

  function findSensorBasedOnNodeIdAndSensorTag(nodeId: string, sensorTag: string) {
    let foundNode: any = nodesRef.current?.find((node: any) => node.id == nodeId);
    let sensors: any = foundNode?.data?.configInfo?.sensors;
    let foundSensor = sensors?.find((sensor: any) => {
      return sensor.sensorTag == sensorTag;
    });
    if (foundSensor) return foundSensor;
    else return null;
  }

  function updateKnobStatus(knob: any, equipmentId: string, sensorTag: string) {
    let foundNode: any = nodes.find((node: any) => node.id == equipmentId);
    if (!foundNode) return;
    let sensors = foundNode?.data?.configInfo?.sensors;
    let foundSensor = sensors?.find((sensor: any) => {
      return sensor?.sensorTag == sensorTag;
    });

    if (foundSensor && foundSensor?.value) {
      let cellView = knob.findView(paper);
      // let cellView = paper.findViewByModel(borewellPump1ToBorewellJoin);
      if (!cellView) return;
      // @ts-ignore
      cellView.model.transition('value', foundSensor?.value);
    } else {
      let cellView = knob.findView(paper);
      if (!cellView) return;
      // @ts-ignore
      cellView.model.transition('value', foundSensor?.value);
    }
  }

  function updateKnobStatusFromEdgeSensor(knob: any, edgeId: string, sensorTag: string) {
    let foundEdge: any = edges.find((edge: any) => edge.id == edgeId);
    if (!foundEdge) return;
    let sensors = foundEdge?.data?.sensors;
    let foundSensor = sensors?.find((sensor: any) => {
      return sensor?.sensorTag == sensorTag;
    });

    if (foundSensor && foundSensor?.value > 0) {
      let cellView = knob.findView(paper);
      // let cellView = paper.findViewByModel(borewellPump1ToBorewellJoin);
      if (!cellView) return;
      // @ts-ignore

      cellView.model.transition('value', foundSensor.value);
    } else {
      let cellView = knob.findView(paper);
      if (!cellView) return;
      // @ts-ignore

      cellView.model.transition('value', foundSensor.value);
    }
  }

  function updatePanel(panelItem: any, equipmentId: string, sensorTag: string) {
    let foundNode: any = nodes.find((node: any) => node.id == equipmentId);
    if (!foundNode) return;
    let sensors = foundNode?.data?.configInfo?.sensors;
    let foundSensor = sensors?.find((sensor: any) => {
      return sensor?.sensorTag == sensorTag;
    });
    if (foundSensor && foundSensor?.value) {
      // let cellView = paper.findViewByModel(borewellPump1ToBorewellJoin);

      // panelItem.set('level', (foundSensor.value / 4.3) * 100);
      panelItem.set('level', foundSensor.value);
      // console.log('level', (foundSensor.value / 4.3) * 100);
      // console.log('level', foundSensor.value)
    } else {
      panelItem.set('level', 0);
    }
  }

  function updatePanel2(panelItem: any, equipmentId: string, sensorTag: string) {
    let foundNode: any = nodes.find((node: any) => node.id == equipmentId);
    if (!foundNode) return;
    let sensors = foundNode?.data?.configInfo?.sensors;
    let foundSensor = sensors?.find((sensor: any) => {
      return sensor?.sensorTag == sensorTag;
    });
    if (foundSensor && foundSensor?.value) {
      // let cellView = paper.findViewByModel(borewellPump1ToBorewellJoin);
      let level = foundSensor.value * -1;
      panelItem.set({ level: level, color: '#ff1' });
    } else {
      panelItem.set({ level: 0, color: '#ff1' });
    }
  }

  function updateControlValveStatus(valve: any, equipmentId: string) {
    let foundNode: any = nodes.find((node: any) => node.id == equipmentId);
    if (!foundNode) return;
    let sensors = foundNode?.data?.configInfo?.sensors;
    let onoffsensor = sensors?.find((sensor: any) => {
      return sensor?.sensorTag?.includes('ONOFF_');
    });

    if (onoffsensor && onoffsensor?.value > 0) {
      let cellView = valve.findView(paper);
      // let cellView = paper.findViewByModel(borewellPump1ToBorewellJoin);
      if (!cellView) return;
      // @ts-ignore
      cellView.model.set('open', 1);
    } else {
      let cellView = valve.findView(paper);
      if (!cellView) return;
      // @ts-ignore
      cellView.model.set('open', 0);
    }
  }

  //@ts-ignore
  useEffect(() => {
    if (sseSwitchedOn) {
      // Initialize SSE
      setNodes([]);
      setEdges([]);
      const events = new EventSourcePolyfill(
        `${backendApi}/sseRoutes/getLayoutDiagramEvents?assetId=${assetId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
          heartbeatTimeout: 9000000,
          //@ts-ignore
          mode: 'cors',
        }
      );

      events.onmessage = (event: any) => {
        const plantLayoutData = JSON.parse(event.data);
        setNodes(plantLayoutData.nodes);
        setEdges(plantLayoutData.edges);
      };

      return () => {
        events.close();
        setNodes([]);
        setEdges([]);
        console.log('SSE connection closed');
      };
    } else {
      // Fetch data from API
      getTimeBasedLayoutDataForAssetId(assetId, timeFrame).then((plantLayoutData: any) => {
        setNodes(plantLayoutData.nodes);
        setEdges(plantLayoutData.edges);
      });
    }
  }, [timeFrame, sseSwitchedOn]);

  useEffect(() => {
    updatePipeStatus(chlorineDosingTanktochlorinePump);
    updatePipeStatus(chlorinePumptoClorinationTank);
    updatePipeStatus(overHeadTankToOutletValve);
    updatePipeStatus(outletValveToSupplyToVillage);

    updatePipeStatusByMultipleOnOffSensor(borewellPump1ToBorewellJoin, [
      { equipmentId: 'Pump10', sensorTag: 'ONOFF_RTUdd1_1:WEL_ALA' },
    ]);

    updatePipeStatusByMultipleOnOffSensor(borewellJoinInletValve, [
      { equipmentId: 'Pump10', sensorTag: 'ONOFF_RTUdd1_1:WEL_ALA' },
    ]);

    updatePipeStatusByMultipleOnOffSensor(inletValveBypassValve, [
      { equipmentId: 'Pump10', sensorTag: 'ONOFF_RTUdd1_1:WEL_ALA' },
    ]);

    updatePipeStatusByMultipleOnOffSensor(inletValveTochlorineTank, [
      { equipmentId: 'Pump10', sensorTag: 'ONOFF_RTUdd1_1:WEL_ALA' },
      { equipmentId: 'Actuator Valve1', sensorTag: 'ONOFF_AVdd1_1:WEL_ALA' },
    ]);

    updatePipeStatusByMultipleOnOffSensor(clorinationTankToOverHeadTank, [
      { equipmentId: 'Pump10', sensorTag: 'ONOFF_RTUdd1_1:WEL_ALA' },
      { equipmentId: 'Actuator Valve1', sensorTag: 'ONOFF_AVdd1_1:WEL_ALA' },
    ]);

    updatePipeStatusByMultipleOnOffSensor(bypassValveToRainWaterResovoir, [
      { equipmentId: 'Pump10', sensorTag: 'ONOFF_RTUdd1_1:WEL_ALA' },
      { equipmentId: 'Actuator Valve2', sensorTag: 'ONOFF_AVdd1_3:WEL_ALA' },
    ]);

    updatePumpStatus(pumpSubmersible1);
    updatePumpStatus(chlorinePump);

    updateKnobStatus(borewellPressureTransmitter, 'Pump10', 'PTI_INLETdd1_1:WEL_ALA');
    updateKnobStatus(chlorineAnalyser, 'Custom Tank4', 'RCA_CSdd1_1:WEL_ALA');
    updateKnobStatus(chlorineAnalyser, 'Custom Tank4', 'RCA_CSdd1_1:WEL_ALA');
    updateKnobStatus(flowmetre, 'Actuator Valve3', 'FMF_OUTdd1_1:WEL_ALA');
    updateKnobStatus(submersiblePumpRPMSensor, 'Pump10', 'RPMV_ss_1:WEL_ALA');
    updateKnobStatusFromEdgeSensor(turbiditySensor, 'e4', 'TUR_CSdd1_1');

    updatePanel(overHeadTank, 'Custom Tank1', 'LT_OHT_PER:WEL_ALA');
    updatePanel2(panelBorewell, 'Custom Tank2', 'LT_BORE:WEL_ALA');

    updateControlValveStatus(inletValve, 'Actuator Valve1');
    updateControlValveStatus(bypassValve, 'Actuator Valve2');
    updateControlValveStatus(outletValve, 'Actuator Valve3');
  }, [edges, nodes]);

  return (
    <div className="welspun canvas">
      <div ref={paperRef} className="paper-container"></div>
    </div>
  );
}

export default WelspunLayoutDemo;

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const assetId = '6641c6d9c09cc51adba6f3c3';

const namespace = {
  ...shapes,
  Pump,
  PumpView,
  LiquidTankClorination,
  Zone,
  PumpSubmersible,
  PumpSubmersibleView,
  ControlValve,
  ControlValveView,
  Pipe,
  PipeView,
  Join,
  OverHeadTank,
  Panel,
  PanelView,
  Panel2,
  Panel2View,
  BoreWell,
};

const graph = new dia.Graph({}, { cellNamespace: namespace });

const paper = new dia.Paper({
  model: graph,
  async: true,
  autoResizePaper: true,
  scrollWhileDragging: true,
  sorting: dia.Paper.sorting.APPROX,
  background: { color: '#F3F7F6' },
  border: { color: '#000' },
  interactive: {
    linkMove: false,
    stopDelegation: false,
  },
  cellViewNamespace: namespace,
  defaultAnchor: {
    name: 'perpendicular',
  },
});

paper.setDimensions(1300, 700);

// Add Different Equipments

const pumpSubmersible1 = new PumpSubmersible({
  position: { x: 210, y: 519 },
  angle: -270,
  power: 0,
  attrs: {
    label: {
      text: 'Borewell Pump',
      x: -50,
      y: 150,
      transform: 'rotate(270)',
    },
    // @ts-ignore
    customId: 'borewellpump',
    // @ts-ignore
    equipmentId: 'Pump10',
  },
});

const inletValve = new ControlValve({
  position: { x: 330, y: 202 },
  attrs: {
    label: {
      text: 'Inlet Valve',
    },
    // @ts-ignore
    customId: 'inletValve',
  },
});

const bypassValve = new ControlValve({
  position: { x: 501, y: 286 },
  attrs: {
    label: {
      text: 'Bypass Valve',
    },
    // @ts-ignore
    customId: 'bypassValve',
  },
});

// const clorinationTank = new LiquidTankClorination({
//   position: { x: 495, y: 10 },
//   attrs: {
//     label: {
//       // chlorine pump
//       text: 'Chlorination Tank',
//     },
//     // @ts-ignore
//     customId: 'clorinationTank',
//   },
// });

const clorinationJoint = new Join({
  position: { x: 495, y: 100 },
  attrs: {
    label: {
      text: 'Clorination Joint',
    },
    // @ts-ignore
    customId: 'clorinationJoint',
  },
});

const chlorinePump = new Pump({
  position: { x: 269, y: 7 },
  size: { width: 80, height: 80 },
  power: 0,
  attrs: {
    label: {
      text: 'Chlorine Pump',
    },
    // @ts-ignore
    customId: 'chlorinePump',
    // @ts-ignore
    equipmentId: 'Pump9',
  },
});

const submersiblePumpJoin = new Join({
  position: { x: 245, y: 301 },
  attrs: {
    label: {
      text: 'Submersible Pump Join',
    },
    // @ts-ignore
    customId: 'submersiblePumpJoin',
  },
});

const chlorineDosingTank = new DosingTank({
  position: { x: 34, y: 17 },
  attrs: {
    label: {
      text: 'Chlorine Dosing Tank',
    },
    // @ts-ignore
    customId: 'chlorineDosingTank',
  },
});

const rainWaterResovoir = new Zone({
  position: { x: 827, y: 585 },
  attrs: {
    label: {
      text: 'Rain Water Reservoir',
    },
    // @ts-ignore
    customId: 'rainWaterResovoir',
  },
});

const overHeadTank = new OverHeadTank({
  position: { x: 828, y: 11 },
  attrs: {
    label: {
      text: 'Over Head Tank',
    },
    // @ts-ignore
    customId: 'overHeadTank',
  },
});

const panel1 = new Panel({
  position: { x: 840, y: 61 },
});

panel1.listenTo(overHeadTank, 'change:level', (_, level) => {
  const color = level > 80 ? MAX_LIQUID_COLOR : level < 20 ? MIN_LIQUID_COLOR : LIQUID_COLOR;
  panel1.set({ level, color });
});

const panelBorewell = new Panel2({
  position: { x: 349, y: 555 },
  angle: 180,
  attrs: {
    label: {
      text: 'Borewell Panel',
    },
    // @ts-ignore
    customId: 'panelBorewell',
  },
});

const outletValve = new ControlValve({
  position: { x: 1120, y: 309 },
  attrs: {
    label: {
      text: 'Outlet Valve',
    },
    // @ts-ignore
    customId: 'outletValve',
  },
});

const supplyToVillage = new Zone({
  position: { x: 1166, y: 566 },
  angle: -270,
  attrs: {
    label: {
      text: 'Supply to Village',
    },
    // @ts-ignore
    customId: 'supplyToVillage',
  },
});

const boreWell = new BoreWell({
  position: { x: 159, y: 500 },
  size: { width: 100, height: 100 },
  z: -2,
  attrs: {
    // @ts-ignore
    customId: 'boreWell',
  },
});

const PRESSURE_COLOR = '#1446A0';

const borewellPressureTransmitter = new shapes.chart.Knob({
  position: { x: 91, y: 232 },
  size: { width: 120, height: 120 },
  min: 0,
  max: 1.8,
  step: 0.1,
  value: 0,
  fill: PRESSURE_COLOR,
  // Historically, the chart shapes are defined without camel-cased attributes
  // @ts-ignore
  attrs: {
    root: {
      'font-family': 'sans-serif',
    },
  },
  serieDefaults: {
    startAngle: 90,
    label: 'Ⓟ bar',
  },
  sliceDefaults: {
    value: 1,
    legendLabel: '{value:.1f}',
    onClickEffect: { type: 'enlarge' },
  }
});

const chlorineAnalyser = new shapes.chart.Knob({
  position: { x: 635, y: 129 },
  size: { width: 120, height: 120 },
  min: 0,
  max: 2,
  step: 1,
  value: 0,
  fill: PRESSURE_COLOR,
  // Historically, the chart shapes are defined without camel-cased attributes
  // @ts-ignore
  attrs: {
    root: {
      'font-family': 'sans-serif',
    },
  },
  serieDefaults: {
    startAngle: 90,
    label: 'RCA (PPM)',
  },
  sliceDefaults: {
    value: 1,
    legendLabel: '{value:.1f}',
    onClickEffect: { type: 'enlarge' },
  },
});

const turbiditySensor = new shapes.chart.Knob({
  position: { x: 120, y: 119 },
  size: { width: 120, height: 120 },
  min: 0,
  max: 30,
  step: 1,
  value: 0,
  fill: PRESSURE_COLOR,
  // Historically, the chart shapes are defined without camel-cased attributes
  // @ts-ignore
  attrs: {
    root: {
      'font-family': 'sans-serif',
    },
    customId: 'turbiditySensor',
  },
  serieDefaults: {
    startAngle: 180,
    label: 'Turbidity NTU',
  },
  sliceDefaults: {
    value: 1,
    legendLabel: '{value:.1f}',
    onClickEffect: { type: 'enlarge' },
  },
});

const flowmetre = new shapes.chart.Knob({
  position: { x: 1113, y: 64 },
  size: { width: 120, height: 120 },
  min: 0,
  max: 60,
  step: 1,
  value: 0,
  fill: PRESSURE_COLOR,
  // Historically, the chart shapes are defined without camel-cased attributes
  // @ts-ignore
  attrs: {
    root: {
      'font-family': 'sans-serif',
    },
    customId: 'flowmetre',
  },
  serieDefaults: {
    startAngle: 90,
    label: 'Flow kl/hr',
  },
  sliceDefaults: {
    value: 1,
    legendLabel: '{value:.1f}',
    onClickEffect: { type: 'enlarge' },
  },
});

const submersiblePumpRPMSensor = new shapes.chart.Knob({
  position: { x: 373, y: 381 },
  size: { width: 120, height: 120 },
  min: 0,
  max: 3000,
  step: 100,
  value: 0,
  fill: PRESSURE_COLOR,
  // Historically, the chart shapes are defined without camel-cased attributes
  // @ts-ignore
  attrs: {
    root: {
      'font-family': 'sans-serif',
    },
    customId: 'submersiblePumpRPMSensor',
  },
  serieDefaults: {
    startAngle: 90,
    label: 'RPM',
  },
  sliceDefaults: {
    value: 1,
    legendLabel: '{value:.1f}',
    onClickEffect: { type: 'enlarge' },
  },
});

// Connect Equipments

const borewellPump1ToBorewellJoin = new Pipe({
  source: {
    id: pumpSubmersible1.id,
    port: 'left',
    anchor: { name: 'left', args: { rotate: true } },
  },
  target: { id: submersiblePumpJoin.id, port: 'bottom', anchor: { name: 'bottom' } },
  attrs: {
    line: {
      stroke: '#fff',
    },
    // @ts-ignore
    sourceId: 'Pump10',
    // @ts-ignore
    targetId: 'Actuator Valve1',
  },
});

//////-----------------------------------------------------------------------------------
// borewell pump to submersible pump join  =
// "source": "Pump10",
// "target": "Actuator Valve1",

const borewellJoinInletValve = new Pipe({
  source: { id: submersiblePumpJoin.id, port: 'top', anchor: { name: 'top' } },
  target: { id: inletValve.id, port: 'left', anchor: { name: 'left' } },
  attrs: {
    line: {
      stroke: '#fff',
    },
    // @ts-ignore
    sourceId: 'Pump10',
    // @ts-ignore
    targetId: 'Actuator Valve1',
  },
});

const inletValveBypassValve = new Pipe({
  source: { id: submersiblePumpJoin.id, port: 'right', anchor: { name: 'right' } },
  target: { id: bypassValve.id, port: 'left', anchor: { name: 'left' } },
  attrs: {
    line: {
      stroke: '#fff',
    },
    // @ts-ignore
    sourceId: 'Pump10',
    // @ts-ignore
    targetId: 'Actuator Valve2',
  },
});

const chlorineDosingTanktochlorinePump = new Pipe({
  source: { id: chlorineDosingTank.id, port: 'right', anchor: { name: 'right' } },
  target: { id: chlorinePump.id, port: 'left', anchor: { name: 'left' } },
  attrs: {
    line: {
      stroke: '#fff',
    },
    // @ts-ignore
    sourceId: 'Pump9',
    // @ts-ignore
    targetId: 'Custom Tank4',
  },
});

const chlorinePumptoClorinationTank = new Pipe({
  source: { id: chlorinePump.id, port: 'right', anchor: { name: 'right' } },
  target: { id: clorinationJoint.id, port: 'top', anchor: { name: 'top' } },
  attrs: {
    line: {
      stroke: '#fff',
    },
    // @ts-ignore
    sourceId: 'Pump9',
    // @ts-ignore
    targetId: 'Custom Tank4',
  },
});

const bypassValveToRainWaterResovoir = new Pipe({
  source: { id: bypassValve.id, port: 'right', anchor: { name: 'right' } },
  target: {
    id: rainWaterResovoir.id,
    port: 'left',
    anchor: { name: 'left' },
    attrs: {
      line: {
        stroke: '#fff',
      },
      // @ts-ignore
      sourceId: 'Pump10',
      // @ts-ignore
      targetId: 'Actuator Valve2',
    },
  },
});

const inletValveTochlorineTank = new Pipe({
  source: { id: inletValve.id, port: 'right', anchor: { name: 'right' } },
  target: { id: clorinationJoint.id, port: 'left', anchor: { name: 'left'} },
  attrs: {
    line: {
      stroke: '#fff',
    },
    // @ts-ignore
    sourceId: 'Actuator Valve1',
    // @ts-ignore
    targetId: 'Custom Tank4',
  },
});

const clorinationTankToOverHeadTank = new Pipe({
  source: { id: clorinationJoint.id, port: 'right', anchor: { name: 'right' } },
  target: { id: overHeadTank.id, port: 'left', anchor: { name: 'left' } },
  attrs: {
    line: {
      stroke: '#fff',
    },
    // @ts-ignore
    sourceId: 'Custom Tank4',
    // @ts-ignore
    targetId: 'Custom Tank1',
  },
});

const overHeadTankToOutletValve = new Pipe({
  source: { id: overHeadTank.id, port: 'right', anchor: { name: 'right' } },
  target: { id: outletValve.id, port: 'left', anchor: { name: 'left' } },
  attrs: {
    line: {
      stroke: '#fff',
    },
    // @ts-ignore
    sourceId: 'Custom Tank1',
    // @ts-ignore
    targetId: 'Actuator Valve3',
  },
});

const outletValveToSupplyToVillage = new Pipe({
  source: { id: outletValve.id, port: 'right', anchor: { name: 'right' } },
  target: {
    id: supplyToVillage.id,
    port: 'left',
    anchor: { name: 'left', rotate: true, args: { dy: -40, dx: 20 } },
  },
  attrs: {
    line: {
      stroke: '#fff',
    },
    // @ts-ignore
    sourceId: 'Custom Tank1',
    // @ts-ignore
    targetId: 'Actuator Valve3',
  },
});

const turbiditySensorLink = new shapes.standard.Link({
  source: { id: turbiditySensor.id, anchor: { name: 'bottom' } },
  target: { id: inletValveBypassValve.id },
  z: -1,
  attrs: {
    line: {
      strokeDasharray: '5 5',
      stroke: 'green',
    },
  },
});

const flowmetreLink = new shapes.standard.Link({
  source: { id: flowmetre.id, anchor: { name: 'bottom' } },
  target: { id: outletValveToSupplyToVillage.id },
  z: -1,
  attrs: {
    line: {
      strokeDasharray: '5 5',
      targetMarker: {
        type: 'circle',
        r: 12,
        fill: 'green',
        stroke: 'green',
        'stroke-width': 2,
      },
      stroke: 'green',
    },
  },
});

const borewellPressureTransmitterLink = new shapes.standard.Link({
  source: { id: borewellPressureTransmitter.id, anchor: { name: 'bottom' } },
  target: { id: borewellPump1ToBorewellJoin.id },
  z: -1,
  attrs: {
    line: {
      strokeDasharray: '5 5',
      targetMarker: {
        type: 'circle',
        r: 12,
        fill: '#eee',
        stroke: '#666',
        'stroke-width': 2,
      },
      stroke: 'green',
    },
  },
});

const chlorineAnalyserLink = new shapes.standard.Link({
  source: { id: chlorineAnalyser.id, anchor: { name: 'bottom' } },
  target: { id: clorinationTankToOverHeadTank.id },
  z: -1,
  attrs: {
    line: {
      strokeDasharray: '5 5',
      targetMarker: {
        type: 'circle',
        r: 12,
        fill: '#eee',
        stroke: '#666',
        'stroke-width': 2,
      },
      stroke: '#aaa',
    },
  },
});

const RPMsLink = new shapes.standard.Link({
  source: { id: submersiblePumpRPMSensor.id, anchor: { name: 'bottom' } },
  target: { id: pumpSubmersible1.id },
  z: -1,
  attrs: {
    line: {
      strokeDasharray: '5 5',
      targetMarker: {
        fill: '#eee',
        stroke: '#666',
        'stroke-width': 2,
      },
      stroke: '#aaa',
    },
  },
});

// @ts-ignore
function addControls(paper) {
  const graph = paper.model;
  // @ts-ignore
  graph.getElements().forEach(cell => {
    switch (cell.get('type')) {
      case 'Pump':
        if (cell.attributes.attrs.customId === 'borewellpump') {
          Control.add(cell.findView(paper), 'root', 'selection');
        }
        break;
      case 'ControlValve':
        Control.add(cell.findView(paper), 'root', 'selection');
        break;
    }
  });
}
