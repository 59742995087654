import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarContext from '../context/SidebarContext';
// import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AppBar from '@mui/material/AppBar';
import clsx from 'clsx';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoreIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import CheckIcon from '@mui/icons-material/Check';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import digitalPaaniLogoV2 from '../constants/Images/digitalPaaniLogoV2.png';
import styles from '../styles/headerStyles.module.css';
import UserSelectPopup from './UserSelectPopup/UserSelectPopup';
import InfoIcon from '@mui/icons-material/Info';
import NotificationPopup from './NotificationPopup/NotificationPopup';
import { getNotificationUnreadCount } from '../services/notification';
import useAuth from '../utilities/useAuth';
import useUserStore from '../store/UserStore';
import localStorageStore from '../store/localStorageStore';
import { useQueryClient } from '@tanstack/react-query';

interface propsInterface {
  plantId: string;
}

export default function Header(props: propsInterface) {
  const { plantId } = props;
  // type pageParams = {
  //   id: string;
  // };

  // const landingPage = localStorage.getItem("landingPage");
  const landingPage = useUserStore().landingPage;

  // const { id } = useParams<pageParams>();
  let navigate = useNavigate();
  //classes for styling
  // const classes = useStyles();
  const classes = styles;
  const profileMenuId = 'account-profile-menu';
  const mobileMenuId = 'account-profile-menu-mobile';
  const notificationsMenuId = 'account-notifications';
  // const allowAction = localStorage.getItem("allowAction");
  const allowAction = useUserStore().allowAction;
  //anchors for dropdown menu
  const [anchorEl1, setAnchorEl1] = React.useState<null | HTMLElement>(null);
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenu1Open = Boolean(anchorEl1);
  const isMenu2Open = Boolean(anchorEl2);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { role } = useAuth();
  const userName = useUserStore().userName;

  //Context for Sidebar
  const { open, setOpen } = useContext(SidebarContext);
  const [checkoutPopup, setCheckoutPopup] = useState(false);

  const [notificationCount, setNotificationCount] = useState({
    all: 0,
    critical: 0,
    normal: 0,
  });
  useEffect(() => {
    let intervalId: any;
    async function fetchData() {
      await fetchUnreadNotificationCount();

      intervalId = setInterval(async () => {
        await fetchUnreadNotificationCount();
      }, 60000);
    }

    if (localStorage.getItem('token') !== '') {
      // console.log("localStorage.getItem", localStorage.getItem("token"));
      fetchData();
    }
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const fetchUnreadNotificationCount = async () => {
    if (plantId) {
      getNotificationUnreadCount(plantId!).then(data => {
        if (data.all) {
          setNotificationCount(data);
        }
      });
    }
  };

  const handleLogoClick = () => {
    const OperatorCheckedIn = localStorage.getItem('OperatorCheckedIn');
    const isOperator = OperatorCheckedIn;

    if (isOperator) {
      navigate('/task-list');
    } else {
      navigate(landingPage! || '/');
    }
  };

  //onClick handle Profile Menu
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl1(event.currentTarget);
    setMobileMoreAnchorEl(null);
  };

  //onClick Mobile Menu Open
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  //onClose Notifications menu
  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    // console.log("notification menu open");
    setAnchorEl2(event.currentTarget);
    setMobileMoreAnchorEl(null);
  };

  //onClose mobile Menu
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  //onClose Profile Menu
  const handleMenu1Close = () => {
    setAnchorEl1(null);
  };

  //onClose Notifications Menu
  const handleMenu2Close = () => {
    setAnchorEl2(null);
    // console.log("notification menu close");
  };
  const queryClient = useQueryClient();
  //handle logout
  const handleLogout = () => {
    queryClient.clear();
    localStorageStore.logout();
    navigate('/Login');
  };

  //handle checkout
  const handleCheckout = () => {};

  //Profile Dropdown
  const profileMenu = (
    <Menu
      anchorEl={anchorEl1}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenu1Open}
      onClose={handleMenu1Close}
    >
      <MenuItem
        onClick={e => {
          e.preventDefault();
          handleMenu1Close();
          navigate('/profile');
        }}
      >
        <PersonIcon className={classes.icont} />
        &nbsp; Profile
      </MenuItem>
      {/* {role !== "Operator Group" ? (
        <MenuItem
          onClick={(e) => {
            e.preventDefault();
            handleMenu1Close();
            navigate("/resetPassword");
          }}
        >
          <VpnKeyIcon className={classes.icont} />
          &nbsp; Reset Password
        </MenuItem>
      ) : null} */}
      <MenuItem
        onClick={e => {
          e.preventDefault();
          handleMenu1Close();
          handleLogout();
        }}
      >
        <ExitToAppIcon className={classes.icont} />
        &nbsp; Logout
      </MenuItem>
      {role === 'Operator Group' ? (
        <MenuItem
          onClick={e => {
            e.preventDefault();
            handleMenu1Close();
            navigate('/checkIn');
          }}
        >
          <CheckCircleIcon className={classes.icont} />
          &nbsp; Checkin
        </MenuItem>
      ) : null}

      {allowAction === true && role === 'Operator Group' ? (
        <>
          <MenuItem
            onClick={e => {
              e.preventDefault();
              handleMenu1Close();
              setCheckoutPopup(true);
              handleCheckout();
            }}
          >
            <CheckIcon className={classes.icont} />
            &nbsp; Checkout
          </MenuItem>
        </>
      ) : null}

      {role === 'Rotating Operator' ? (
        <>
          <MenuItem
            onClick={e => {
              e.preventDefault();
              handleMenu1Close();
              // setCheckoutPopup(true);
              handleCheckout();
              navigate('/checkInForRO');
            }}
          >
            <CheckIcon className={classes.icont} />
            &nbsp; Attendance
          </MenuItem>
        </>
      ) : null}
      <MenuItem
        onClick={e => {
          e.preventDefault();
          handleMenu1Close();
          navigate('/aboutPage');
        }}
      >
        <InfoIcon className={classes.icont} />
        &nbsp; About
      </MenuItem>
    </Menu>
  );

  //Notifications Dropdown

  //Mobile Dropdown
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleNotificationMenuOpen}>
        <IconButton
          aria-label="show new notifications"
          aria-controls={notificationsMenuId}
          color="inherit"
          aria-haspopup="true"
        >
          <Badge color="secondary">
            <NotificationsIcon className={classes.icont} />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls={profileMenuId}
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle className={classes.icont} />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        // className={clsx(classes.header, {
        //   [classes.appBarShift]: open,
        // })}
        className={`${classes.header} ${open ? classes.appBarShift : ''}`}
        sx={{ zIndex: 1201 }}
        position="fixed"
      >
        <Toolbar className={classes.header}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => {
              setOpen(true);
            }}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={digitalPaaniLogoV2}
            className={classes.logo}
            alt="Digital Paani Logo"
            // onClick={() => {

            //   navigate(landingPage! || '/');
            // }}
            onClick={handleLogoClick}
          />
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              aria-label="show new notifications"
              aria-controls={notificationsMenuId}
              color="inherit"
              aria-haspopup="true"
              onClick={handleNotificationMenuOpen}
            >
              <Badge
                max={99}
                badgeContent={notificationCount?.['all'] === 0 ? null : notificationCount?.['all']}
                color="error"
              >
                <NotificationsIcon className={classes.icon} />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={profileMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle className={classes.icon} />
              <div style={{ fontSize: '15px', marginLeft: '10px' }}>{userName}</div>
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {profileMenu}
      <NotificationPopup
        isMenu2Open={isMenu2Open}
        handleMenu2Close={handleMenu2Close}
        plantId={plantId}
        notificationCount={notificationCount}
        fetchUnreadNotificationCount={fetchUnreadNotificationCount}
      />
      {renderMobileMenu}
      {checkoutPopup ? (
        <>
          <UserSelectPopup
            type="CheckOut"
            handleClose={() => {
              setCheckoutPopup(false);
            }}
          />
        </>
      ) : null}
    </div>
  );
}

Header.defaultProps = {};
