import axiosInstance from "../constants/axiosInstance";

const getInventory = async (id: any) => {
  try {
    const { data } = await axiosInstance().get(`/dashboard/inventory?id=${id}`);
    return data?.data;
  } catch (err) {
    console.error("ERROR | getInventory | ", err);
  }
};

const postUsedInventory = async (row: any, plantId: string | undefined) => {
  try {
    const { data } = await axiosInstance().post(`/dashboard/usedInventory`, {
      sensorId: row["sensorId"],
      prevTimeDataStored: row["prevTimeDataStored"],
      time: row["time"],
      plantId: plantId,
    });
    return data?.data;
  } catch (err) {
    console.error("ERROR | postUsedInventory | ", err);
  }
};

export { getInventory, postUsedInventory };
