import { 
    SemanticError,
    SemanticSuccess,
    SemanticWarning,
    SemanticInfo,
    Primary,
    Secondary,
    GreenHighlight,
    Premium,
    Sludge,
    ButterCup,
    Christine,
    Greeny,
    Hibiscus,
    PremiumAddUp,
    SmithApple,
    Neutrals
} from "./colors";

const PrimaryColor = {
    main: Primary[900],
    light: Primary[900],
    dark: Primary[900],
    contrastText: Primary[50],
}

const SecondaryColor = {
    main: Secondary[500],
    light: Secondary[200],
    dark: Secondary[900],
    contrastText: Secondary[50],
}

const SemanticErrorColor = {
    main: SemanticError[500],
    light: SemanticError[100],
    dark: SemanticError[900],
    contrastText: SemanticError[50],
}

const SemanticSuccessColor = {
    main: SemanticSuccess[500],
    light: SemanticSuccess[100],
    dark: SemanticSuccess[900],
    contrastText: SemanticSuccess[50],
}

const SemanticWarningColor = {
    main: SemanticWarning[500],
    light: SemanticWarning[100],
    dark: SemanticWarning[900],
    contrastText: SemanticWarning[50],
}

const SemanticInfoColor = {
    main: SemanticInfo[500],
    light: SemanticInfo[100],
    dark: SemanticInfo[900],
    contrastText: SemanticInfo[50],
}

const GreenHighlightColor = {
    main: GreenHighlight[500],
    light: GreenHighlight[200],
    dark: GreenHighlight[900],
    contrastText: GreenHighlight[50],
}

const PremiumColor = {
    main: Premium[700],
    light: Premium[200],
    dark: Premium[900],
    contrastText: Premium[50],
}

const SludgeColor = {
    main: Sludge[700],
    light: Sludge[200],
    dark: Sludge[900],
    contrastText: Sludge[50],
}

const ButterCupColor = {
    main: ButterCup[500],
    light: ButterCup[200],
    dark: ButterCup[900],
    contrastText: ButterCup[50],
}

const ChristineColor = {
    main: Christine[500],
    light: Christine[200],
    dark: Christine[900],
    contrastText: Christine[50],
}

const GreenyColor = {
    main: Greeny[500],
    light: Greeny[200],
    dark: Greeny[900],
    contrastText: Greeny[50],
}

const HibiscusColor = {
    main: Hibiscus[500],
    light: Hibiscus[200],
    dark: Hibiscus[900],
    contrastText: Hibiscus[50],
}

const PremiumAddUpColor = {
    main: PremiumAddUp[500],
    light: PremiumAddUp[200],
    dark: PremiumAddUp[900],
    contrastText: PremiumAddUp[50],
}

const SmithAppleColor = {
    main: SmithApple[500],
    light: SmithApple[200],
    dark: SmithApple[900],
    contrastText: SmithApple[50]
}

const NeutralsColor = {
    50: Neutrals[50],
    100: Neutrals[100],
    200: Neutrals[200],
    300: Neutrals[300],
    400: Neutrals[400],
    500: Neutrals[500],
    600: Neutrals[600],
    700: Neutrals[700],
    800: Neutrals[800],
    900: Neutrals[900]
}

export {
    PrimaryColor,
    SecondaryColor,
    SemanticErrorColor,
    SemanticSuccessColor,
    SemanticWarningColor,
    SemanticInfoColor,
    GreenHighlightColor,
    PremiumColor,
    SludgeColor,
    ButterCupColor,
    ChristineColor,
    GreenyColor,
    HibiscusColor,
    PremiumAddUpColor,
    SmithAppleColor,
    NeutralsColor
}