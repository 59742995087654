import { memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Popover,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import RefreshIcon from '../../assets/icons/RefreshIcon.svg';
import DownloadPDFIcon from '../../assets/icons/DownloadPDFIcon.svg';
import ManageDashboard from '../../assets/icons/ManageDashboardIcon.svg';
import EditPageConfigIcon from '../../assets/icons/EditPageConfigIcon.svg';
import ClockSettingsIcon from '../../assets/icons/ClockSettings.svg';
import DPInputSelect from './DPInputSelect';
import classes from './DashboardComponents.module.css';
import { DashboardComponentStyle as styles } from './DashboardComponentStyle';
import SingleDateAndTimePicker from './SingleDateAndTimePicker';
// import AssistantIcon from '@mui/icons-material/Assistant';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
// import DeviceHubIcon from '@mui/icons-material/DeviceHub';
// import { DashboardClusterInterface } from "../../Interfaces/dashboardPageInterfaces";

import { GRANULARITY, TIME_RANGE } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { formatDate, formatTime } from '../../utilities/helpers';
import dpTheme from '../../styles/theme/dpTheme';
import {
  getPdfForDashboardPage,
  getPdfForUnifiedDashboardPage,
} from '../../services/dashboardPage';
import saveAs from 'file-saver';
import useIsMobile from '../../utilities/customHooks/useIsMobile';
import { add530Hours } from '../../utils/dashboard.utils';
import { updateTimeFrameForRefId } from '../../services/commonApis';

// interface IProps {
//   heading: string;
//   dashboardClusters: DashboardClusterInterface[];
//   openDateAndTimeDialog: Function;
//   refreshSelectedPageData: Function;
//   handleDownLoadPdfButtonClicked: Function;
//   plantId: string | undefined;
//   handlePageMenuChange: Function;
//   dashboardPageData: any;
//   setTimeFrame: any;
// }

const DashboardToolbar = memo(
  ({
    heading,
    dashboardClusters,
    openDateAndTimeDialog,
    refreshSelectedPageData,
    plantId,
    handlePageMenuChange,
    setTimeFrame,
    selectedGranularity,
    setSelectedGranularity,
    selectedRelativeTimeRange,
    setSelectedTimeRange,
    timeFrame,
    selectedDashboardPage,
    setSelectedDashboardPage,
    setIsBottomBarOpen,
    dashboardDateAndTime,
    dashboardSaveData,
    setDashboardSaveData,
    setParent,
    newDashboardPage,
    setNewDashboardPage,
    setOpenAiSearchBar,
    navigationLinks,
    mode,
  }: any) => {
    // const isMobile = useIsMobile();
    const startDate = new Date(new Date().valueOf() - 24 * 60 * 60 * 1000);
    const endDate = new Date();
    const startTime = formatTime(new Date(new Date().valueOf() - 24 * 60 * 60 * 1000));
    const endTime = formatTime(new Date());
    const [loadingForPdf, setLoadingForPdf] = useState(false);
    const [popOverAnchorEl, setPopOverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [openCalendarFrom, setOpenCalendarFrom] = useState(false);
    const [openCalendarTo, setOpenCalendarTo] = useState(false);
    const [openTimeFrom, setOpenTimeFrom] = useState(false);
    const [openTimeTo, setOpenTimeTo] = useState(false);
    const [granularityItems, setGranularityItems] = useState(GRANULARITY);

    const updateGranularityItems = (startTimestamp: any, endTimestamp: any) => {
      const timeDifferenceHours = (endTimestamp - startTimestamp) / (60 * 60 * 1000); // Calculate time difference in hours

      let newGranularityItems = GRANULARITY;

      if (timeDifferenceHours <= 6) {
        // Include "Minutes" if the difference is less than or equal to 6 hours
        newGranularityItems = [
          { label: 'Minutes', value: 'minutes' },
          { label: 'Hours', value: 'hours' },
          { label: 'Days', value: 'days' },
        ];
      } else {
        // Calculate date difference in days
        const dateDiff = Math.ceil((endTimestamp - startTimestamp) / (24 * 60 * 60 * 1000));

        if (dateDiff <= 7) {
          newGranularityItems = [
            { label: 'Hours', value: 'hours' },
            { label: 'Days', value: 'days' },
          ];
        } else if (dateDiff > 7 && dateDiff <= 60) {
          newGranularityItems = [
            { label: 'Days', value: 'days' },
            { label: 'Weeks', value: 'weeks' },
          ];
        } else if (dateDiff > 60) {
          newGranularityItems = [
            { label: 'Weeks', value: 'weeks' },
            { label: 'Months', value: 'months' },
          ];
        }
      }

      const isSelectedGranularityPresent = newGranularityItems.filter(
        obj => obj.label == selectedGranularity
      );

      if (isSelectedGranularityPresent.length !== 1) {
        setSelectedGranularity(newGranularityItems[0].label);
        setGranularityItems(newGranularityItems);

        return {
          granularity: newGranularityItems[0].value.toLowerCase(),
          granularityLabel: newGranularityItems[0].label,
        };
      }

      setGranularityItems(newGranularityItems);

      return {
        granularity: selectedGranularity.toLowerCase(),
        granularityLabel: selectedGranularity,
      };
    };

    useEffect(() => {
      console.log('dashboardSaveData2 : ', dashboardSaveData);
      if (timeFrame !== null) {
        updateGranularityItems(timeFrame?.startDate, timeFrame?.endDate);
      }
    }, [timeFrame]);

    const handleDownLoadPdfButtonClicked = (event: any) => {
      setPopOverAnchorEl(event.currentTarget);
    };

    const pageSelection = useCallback(
      (selectedPage: any) => {
        setSelectedDashboardPage(selectedPage);
        handlePageMenuChange(selectedPage);
      },
      [selectedDashboardPage]
    );

    const granularitySelection = (selectedGranularity: any) => {
      setSelectedGranularity(selectedGranularity.label);
      const timeFrame2 = {
        ...timeFrame,
        granularity: selectedGranularity.value.toLowerCase(),
        granularityLabel: selectedGranularity.label,
      };
      setTimeFrame(timeFrame2);
      const updatedDashboard = {
        ...dashboardSaveData,
        selectedGranularity: {
          unit: selectedGranularity.value.toLowerCase(),
          unitMultiplier: 1,
        },
      };
      setDashboardSaveData(updatedDashboard);
      setNewDashboardPage({
        ...newDashboardPage,
        changes: {
          ...newDashboardPage.changes,
          dashboard: updatedDashboard,
        },
      });
      setParent('dashboard');
      setIsBottomBarOpen(true);
    };

    const timeRangeSelection = useCallback(
      (selectedTimeRange: any) => {
        setSelectedTimeRange(selectedTimeRange.label);
        setParent('dashboard');
        setIsBottomBarOpen(true);
        if (selectedTimeRange.label !== 'Custom') {
          const dateRange = selectedTimeRange.getDateRange();
          const startTimestamp = dateRange[0];
          const endTimestamp = dateRange[1];
          let selectedGranularity: any = updateGranularityItems(startTimestamp.$d, endTimestamp.$d);
          const timeFrame2 = {
            ...timeFrame,
            startDate: startTimestamp.$d,
            endDate: endTimestamp.$d,
            ...selectedGranularity,
          };

          const updatedDashboard = {
            ...dashboardSaveData,
            selectedTimeRange: {
              unit: selectedTimeRange.unit,
              unitMultiplier: selectedTimeRange.value,
              timeRangeType: 'relative',
              startTime: timeFrame2.startDate,
              endTime: timeFrame2.endDate,
            },
            selectedGranularity: {
              unit: selectedGranularity?.granularity,
              unitMultiplier: 1,
            },
          };

          setTimeFrame(timeFrame2);
          setDashboardSaveData(updatedDashboard);
          const { startDate: startDate530Ahead, endDate: endDate530Ahead } = add530Hours(
            timeFrame2.startDate,
            timeFrame2.endDate
          );
          updateTimeFrameForRefId(
            startDate530Ahead,
            endDate530Ahead,
            updatedDashboard.dashboardPageId,
            'dashboardPage'
          ).then(() => {});
        }
      },
      [selectedRelativeTimeRange]
    );

    const saveFile = async (base64str: string) => {
      let binary = atob(base64str.replace(/\s/g, ''));
      let len = binary.length;
      let buffer = new ArrayBuffer(len);
      let view = new Uint8Array(buffer);
      for (let i = 0; i < len; i++) {
        view[i] = binary.charCodeAt(i);
      }
      const blob = new Blob([view], { type: 'application/pdf' });
      saveAs(blob, 'plantAnalytics.pdf');
    };

    const downloadPdf = async (printFormat: string) => {
      setPopOverAnchorEl(null);

      setLoadingForPdf(true);
      if (mode === 'unifiedDashboard') {
        await getPdfForUnifiedDashboardPage(
          selectedDashboardPage?.pageId || null,
          timeFrame,
          printFormat
        )
          .then(async data => {
            let base64str = data;

            await saveFile(base64str);
            setLoadingForPdf(false);
          })
          .catch(err => {
            setLoadingForPdf(false);
          });
      } else {
        await getPdfForDashboardPage(
          plantId!,
          selectedDashboardPage?.pageId || null,
          timeFrame,
          printFormat
        )
          .then(async data => {
            let base64str = data;

            await saveFile(base64str);
            setLoadingForPdf(false);
          })
          .catch(err => {
            setLoadingForPdf(false);
          });
      }
    };

    const navigate = useNavigate();

    return (
      <ThemeProvider theme={dpTheme}>
        <Box sx={{ margin: '0px', padding: '0px' }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography sx={styles.headingStyle}>{heading}</Typography>
            <Stack direction="row" alignItems="center" spacing={3}>
              {
                <Box
                  sx={{
                    display: 'grid',
                    placeItems: 'center',
                    // width: '46px',
                    height: '46px',
                    cursor: 'pointer',
                  }}
                  className={classes.AIButton}
                  px={3}
                  borderRadius={1}
                  onClick={() => {
                    setOpenAiSearchBar(true);
                  }}
                >
                  {useIsMobile() ? (
                    <AutoAwesomeIcon />
                  ) : (
                    <Stack direction="row" alignItems="center" gap={1}>
                      <Box>AI Widget</Box>
                      <AutoAwesomeIcon />
                    </Stack>
                  )}
                </Box>
              }
              <img
                className={classes.dpIconButton}
                src={RefreshIcon}
                alt="Refresh"
                onClick={() => {
                  refreshSelectedPageData();
                }}
              />
              <>
                <img
                  className={classes.dpIconButton}
                  src={DownloadPDFIcon}
                  alt="Download"
                  onClick={e => {
                    handleDownLoadPdfButtonClicked(e);
                  }}
                />
                {loadingForPdf ? (
                  <CircularProgress style={{ marginLeft: '5px', color: 'black' }} size={20} />
                ) : null}
              </>
              <Popover
                open={Boolean(popOverAnchorEl)}
                anchorEl={popOverAnchorEl}
                onClose={() => {
                  setPopOverAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Button
                    onClick={() => {
                      downloadPdf('fullPage');
                    }}
                  >
                    Single Page Format
                  </Button>
                  <hr></hr>
                  <Button
                    onClick={() => {
                      downloadPdf('a4');
                    }}
                  >
                    A4 Page Format
                  </Button>
                </Box>
              </Popover>
              <img
                className={classes.dpIconButton}
                src={ManageDashboard}
                alt="Manage Dashboard"
                onClick={() => {
                  navigate(navigationLinks.dashboardPageList);
                }}
              />
              <img
                className={classes.dpIconButton}
                src={EditPageConfigIcon}
                alt="Edit Page Config"
                onClick={() => {
                  navigate(navigationLinks.pageConfig);
                }}
              />
              {!useIsMobile({ breakpointValue: 'sm' }) &&
                useIsMobile({ breakpointValue: 'md' }) && (
                  <DPInputSelect
                    labelId="PageSelectionLabel"
                    id="PageSelection"
                    label="Page"
                    inputSelectOnChange={pageSelection}
                    labelClassName={classes.DPInputSelectLabel}
                    className={classes.DPInputSelectContainer}
                    size={2}
                    menuItems={dashboardClusters}
                    selectedOption={selectedDashboardPage?.label}
                    defaultSelectedOption="xyz"
                    selectedId={selectedDashboardPage?.pageId}
                    setIsBottomBarOpen={setIsBottomBarOpen}
                  />
                )}
            </Stack>
          </Stack>
          <Stack sx={styles.ToolbarSection2}>
            {(useIsMobile({ breakpointValue: 'sm' }) ||
              !useIsMobile({ breakpointValue: 'md' })) && (
              <Box className={classes.DPBoxHighlight}>
                <DPInputSelect
                  labelId="PageSelectionLabel"
                  id="PageSelection"
                  label="Page"
                  inputSelectOnChange={pageSelection}
                  // labelClassName={classes.DPInputSelectLabel}
                  className={classes.DPInputSelectContainer}
                  size={2}
                  menuItems={dashboardClusters}
                  selectedOption={selectedDashboardPage?.label}
                  defaultSelectedOption="xyz"
                  selectedId={selectedDashboardPage?.pageId}
                  setIsBottomBarOpen={setIsBottomBarOpen}
                />
              </Box>
            )}
            <Stack direction="row" className={classes.DPBoxHighlight} spacing={2}>
              <DPInputSelect
                labelId="Granularity"
                id="GranularitySelection"
                label="Granularity"
                inputSelectOnChange={granularitySelection}
                className={classes.DPInputSelectContainer}
                size={1}
                menuItems={granularityItems}
                selectedOption={selectedGranularity}
                setIsBottomBarOpen={setIsBottomBarOpen}
              />
              <DPInputSelect
                labelId="DaySelectionLabel"
                id="DaySelection"
                label=""
                inputSelectOnChange={timeRangeSelection}
                className={classes.DPInputSelectContainer}
                size={1}
                menuItems={TIME_RANGE.filter(timeRange => timeRange.unit == 'days')}
                selectedOption={selectedRelativeTimeRange}
                setIsBottomBarOpen={setIsBottomBarOpen}
              />
              <Stack direction="row" spacing={2} sx={{ height: '100%' }}>
                {!useIsMobile({ breakpointValue: 'sm' }) && (
                  <>
                    <SingleDateAndTimePicker
                      label="From"
                      date={
                        timeFrame && timeFrame.startDate
                          ? formatDate(timeFrame.startDate)
                          : formatDate(startDate)
                      }
                      time={
                        timeFrame && timeFrame.startDate
                          ? formatTime(timeFrame.startDate)
                          : startTime
                      }
                      openCalendar={openCalendarFrom}
                      setOpenCalendar={setOpenCalendarFrom}
                      openTime={openTimeFrom}
                      setOpenTime={setOpenTimeFrom}
                      calendarParent={'from'}
                      timeFrame={timeFrame}
                      setTimeFrame={setTimeFrame}
                      setIsBottomBarOpen={setIsBottomBarOpen}
                      dashboardDateAndTime={dashboardDateAndTime}
                      dashboardSaveData={dashboardSaveData}
                      setDashboardSaveData={setDashboardSaveData}
                      setParent={setParent}
                      newDashboardPage={newDashboardPage}
                      setNewDashboardPage={setNewDashboardPage}
                      setSelectedTimeRange={setSelectedTimeRange}
                      updateGranularityItems={updateGranularityItems}
                    />
                    <SingleDateAndTimePicker
                      label="To"
                      date={
                        timeFrame && timeFrame.endDate
                          ? formatDate(timeFrame.endDate)
                          : formatDate(endDate)
                      }
                      time={
                        timeFrame && timeFrame.endDate ? formatTime(timeFrame.endDate) : endTime
                      }
                      openCalendar={openCalendarTo}
                      setOpenCalendar={setOpenCalendarTo}
                      openTime={openTimeTo}
                      setOpenTime={setOpenTimeTo}
                      calendarParent={'to'}
                      timeFrame={timeFrame}
                      setTimeFrame={setTimeFrame}
                      setIsBottomBarOpen={setIsBottomBarOpen}
                      dashboardDateAndTime={dashboardDateAndTime}
                      dashboardSaveData={dashboardSaveData}
                      setDashboardSaveData={setDashboardSaveData}
                      setParent={setParent}
                      newDashboardPage={newDashboardPage}
                      setNewDashboardPage={setNewDashboardPage}
                      setSelectedTimeRange={setSelectedTimeRange}
                      updateGranularityItems={updateGranularityItems}
                    />
                  </>
                )}
              </Stack>
              <Box
                sx={styles.clockSettingStyle}
                onClick={() => {
                  openDateAndTimeDialog();
                }}
              >
                <img src={ClockSettingsIcon} alt="Clock Settings" />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </ThemeProvider>
    );
  }
);

export default DashboardToolbar;
