import {
  TextField,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function DISensorConfig(props: any) {
  const { dataBlock, handleChanges, handleSensorChanges } = props;

  if (!dataBlock?.sensors)
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Sensors
        </AccordionSummary>
        <AccordionDetails
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          <TextField
            fullWidth
            value={dataBlock?.sensorTag}
            label="Auto generated sensor Tag"
            disabled={true}
            onChange={(e) => {
              handleChanges("title", e.target.value);
            }}
          />
          <TextField
            fullWidth
            required
            value={dataBlock?.sensorNickName}
            label="sensor Nick Name"
            onChange={(e) => {
              handleChanges("sensorNickName", e.target.value);
            }}
          />
        </AccordionDetails>
      </Accordion>
    );
  else
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Sensors
        </AccordionSummary>
        <AccordionDetails
          sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
        >
          {dataBlock.sensors.map((sensor: any, index: number) => {
            return (
              <Box
                m={1}
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <p>{sensor.sensorTypeAbr} : </p>
                <TextField
                  fullWidth
                  defaultValue={sensor.sensorTag}
                  label="Auto generated sensor Tag"
                  disabled={true}
                  onBlur={(e) => {
                    handleSensorChanges(index, "sensorTag", e.target.value);
                  }}
                />
                <TextField
                  fullWidth
                  required
                  defaultValue={sensor.sensorNickName}
                  label="sensor Nick Name"
                  onBlur={(e) => {
                    handleSensorChanges(
                      index,
                      "sensorNickName",
                      e.target.value
                    );
                  }}
                />
              </Box>
            );
          })}
        </AccordionDetails>
      </Accordion>
    );
}
