import { create } from 'zustand';

// Define the store with Zustand
export const useRoleStore = create(set => ({
  workspaceAssetRoles: [],

  // Action to set workspaceAssetRoles
  setWorkspaceAssetRoles: (roles: any) => set({ workspaceAssetRoles: roles }),

  // Optional: Action to reset roles (if needed)
  resetWorkspaceAssetRoles: () => set({ workspaceAssetRoles: [] }),
}));
