import { Box, MenuItem, Paper, Stack, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import expandMoreIcon from "../../../constants/Images/expandMoreIcon.svg";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import Skeleton from "@mui/material/Skeleton";
import {
  widgetDataInterface,
  widgetStructureInterface,
} from "../../../Interfaces/widgetInterfaces";
import ChartIcon from "../../../assets/icons/ChartIcon.svg";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import linkIcon from "../../../constants/Images/linkIcon.svg";
import ExpandIcon from "../../../assets/icons/ExpandIcon.svg";
import DownloadIcon from "../../../assets/icons/DownloadCSV.svg";
import { Dispatch, SetStateAction, useState } from "react";
import { handleDownloadCSV } from "../../../utilities/helpers";
import ClockIcon from "@mui/icons-material/WatchLater";

const styles = {
  graphWidgetLayout: {
    width: "inherit",
    // maxWidth : "700px",
    height: "inherit",
    overflowY: "hidden",
    background: "white",

    display: "grid",
    gridTemplateRows: "50px 1fr",
    borderRadius: "16px",
    position: "relative",
    boxSizing: "border-box",
  },
  widgetHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "10px",
    padding: "5px",
    color: "#193458",
    fontFace: "Roboto",
    fontWeight: "500",
    overflow: "hidden",
    fontSize: "clamp(16px,1.1vw,25px)",
    height: "30px",
  },
  tableViewDev: {
    overflowY: "scroll",
    width: "auto",
    height: "auto",
    borderCollapse: "separate",
    borderSpacing: "10px 10px",
  },
  showIconStyle: {
    visibility: "visible",
  },
  hideIconStyle: {
    visibility: "hidden",
  },
  truncateTextStyle: {
    display: 'inline-block',
    maxWidth: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1rem',
    cursor: 'pointer'
  },
  textStyle: {
    fontSize: '1rem',
    cursor: 'pointer'
  }
};

interface genWidgetHeaderPropInterface {
  setExpandedViewPopup: Dispatch<SetStateAction<boolean>>;
  widgetData: widgetStructureInterface | widgetDataInterface;
  showLinkIcon?: boolean;
  linkOnWidget?: string;
}

// interface genTableDpropInterface {
//   widgetData: widgetDataInterface | null;
//   widgetStructure: widgetStructureInterface;
//   setExpandedViewPopup: Dispatch<SetStateAction<boolean>>;
//   isLoading: boolean;
//   headerType: "normal" | "expanded";
//   showLinkIcon: boolean | undefined;
//   linkOnWidget: string | undefined;
// }

export default function TableDWidget(props: any) {
  const {
    // widgetStructure,
    widgetData,
    setExpandedViewPopup,
    isLoading,
    headerType,
    showLinkIcon,
    linkOnWidget,
    // type,
    handleEditClick,
    showClock,
    setShowClock,
    customTimeRangeSettings,
    handleClockClick,
    customGranularity,
  } = props;

  const [showIcon, setShowIcon] = useState(false);

  if (isLoading) {
    return (
      <Box p={1.5} sx={{ ...styles.graphWidgetLayout }}>
        {headerType === "normal" ? (
          <GeneralisedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
          />
        ) : (
          <GeneralisedExpandedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
          />
        )}

        <Box
          style={{
            display: "grid",
            placeItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Skeleton
            variant="rectangular"
            style={{ width: "inherit", height: "inherit" }}
          />
        </Box>
      </Box>
    );
  } else if (widgetData)
    return (
      <Box
        p={1.5}
        sx={{ ...styles.graphWidgetLayout }}
        onMouseEnter={() => setShowIcon(true)}
        onMouseLeave={() => setShowIcon(false)}
      >
        {headerType === "normal" ? (
          <GeneralisedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
            showIcon={showIcon}
            handleEditClick={handleEditClick}
            showClock={showClock}
            setShowClock={setShowClock}
            customTimeRangeSettings={customTimeRangeSettings}
            handleClockClick={handleClockClick}
            customGranularity={customGranularity}
          />
        ) : (
          <GeneralisedExpandedWidgetHeader
            widgetData={widgetData}
            setExpandedViewPopup={setExpandedViewPopup}
            showLinkIcon={showLinkIcon}
            linkOnWidget={linkOnWidget}
          />
        )}

        <Box sx={{ ...styles.tableViewDev }} p={0.5}>
          <TableContainer component={Paper} className="tableViewDev">
            <Table aria-label="simple table">
              <TableHead>
                <TableRow style={{ background: "#F5F6F9" }}>
                  <TableCell>Time</TableCell>
                  {widgetData?.metricsData?.datasets?.map((metric: any) => {
                    return <TableCell>{metric.label}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {widgetData?.metricsData?.labels?.map(
                  (element: any, index: any) => {
                    return (
                      <TableRow>
                        <TableCell>{element}</TableCell>
                        {widgetData?.metricsData?.datasets?.map(
                          (metric: any) => {
                            return (
                              <TableCell align="center">
                                {metric.data[index]}
                              </TableCell>
                            );
                          }
                        )}
                      </TableRow>
                    );
                  }
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    );
  else return null;
}

const TooltipIcon = ({ title, src, style, onClick }: any) => (
  <Tooltip title={title}>
    <img src={src} style={{ ...style, cursor: "pointer" }} onClick={onClick} />
  </Tooltip>
);
//@ts-ignore
const MenuOption = ({ label, onClick }: any) => (
  <MenuItem onClick={onClick}>{label}</MenuItem>
);

function GeneralisedWidgetHeader(props: any) {
  const {
    setExpandedViewPopup,
    widgetData,
    showLinkIcon,
    linkOnWidget,
    showIcon,
    handleEditClick,
    showClock,
    customGranularity,
    customTimeRangeSettings,
    handleClockClick,
  } = props;
  //@ts-ignore
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const navigateToLink = () => (window.location.href = linkOnWidget!);
  //@ts-ignore
  const closeMenu = () => setAnchorEl(null);

  const capitalizeFirstLetter = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  const formatTimeRange = () => {
    if (
      customTimeRangeSettings?.startTime &&
      customTimeRangeSettings?.endTime
    ) {
      const startTime = new Date(customTimeRangeSettings.startTime);
      const endTime = new Date(customTimeRangeSettings.endTime);
      return `${formatDateTime(startTime)} - ${formatDateTime(
        endTime
      )} | ${capitalizeFirstLetter(customGranularity?.unit || "")}`;
    }
    return "";
  };

  const formatDateTime = (date: Date) => {
    const dateString = date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "short",
    });
    const timeString = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${dateString} ${timeString}`;
  };

  return (
    <Stack>
      <Box className="widgetHeader" sx={{ ...styles.widgetHeader }}>
        <Stack direction="row" spacing={0.6}>
          {
            !showIcon && 
            <Typography variant="h6" sx={styles.textStyle}>
              {widgetData.widgetNickName || widgetData.widgetName}
            </Typography>    
          }  
          {showIcon && (
            <>
              <Tooltip title={widgetData.widgetNickName || widgetData.widgetName}>
                <Typography variant="h6" sx={styles.truncateTextStyle}>
                  {widgetData.widgetNickName || widgetData.widgetName}
                </Typography>
              </Tooltip> 
              <Tooltip
                sx={styles.showIconStyle}
                title={widgetData.widgetDescription}
              >
                <InfoOutlinedIcon
                  fontSize="small"
                  style={{
                    width: "16px",
                    height: "16px",
                    cursor: "pointer",
                    color: "#80858CD9",
                    alignSelf: "center"
                  }}
                />
              </Tooltip>
            </>
          )}
        </Stack>
        {
          showIcon && 
          <Stack
            direction="row"
            spacing={2}
            sx={showIcon ? styles.showIconStyle : styles.hideIconStyle}
          >
            <TooltipIcon
              title="Download CSV"
              src={DownloadIcon}
              style={{ width: "14px", height: "14px" }}
              onClick={() => handleDownloadCSV(widgetData?.metricsData)}
            />
            <TooltipIcon
              title="Expanded View"
              src={ExpandIcon}
              style={{ width: "14px", height: "14px" }}
              onClick={() => setExpandedViewPopup(true)}
            />
            <TooltipIcon
              title="Choose chart"
              src={ChartIcon}
              style={{ width: "14px", height: "14px" }}
              onClick={(event: any) => setAnchorEl(event.currentTarget)}
            />

            {showLinkIcon && (
              <TooltipIcon
                title="Click to know more"
                src={linkIcon}
                style={{ width: "14px", height: "14px" }}
                onClick={navigateToLink}
              />
            )}

            <Tooltip title="Edit Widget">
              <ModeEditOutlineIcon
                fontSize="small"
                sx={{
                  width: "16px",
                  height: "16px",
                  cursor: "pointer",
                  color: "#80858CD9",
                }}
                onClick={handleEditClick}
              />
            </Tooltip>
          </Stack>
        }
      </Box>

      {showClock == "on" && (
        <Stack direction="row" spacing={1}>
          <ClockIcon
            sx={{
              width: "18px",
              height: "18px",
              cursor: "pointer",
              color: "#5cb85c	",
              marginBottom: "8px"
            }}
            onClick={() => handleClockClick("disabled")}
          />
          <span style={{ fontSize: "13px", color: "#7D8188" }}>
            {formatTimeRange()}
          </span>
        </Stack>
      )}
      {showClock === "disabled" && (
        <Stack direction="row" spacing={1}>
          <ClockIcon
            sx={{
              width: "18px",
              height: "18px",
              cursor: "pointer",
            }}
            color="disabled"
            onClick={() => handleClockClick("on")}
          />
          <span style={{ fontSize: "13px", color: "#7D8188" }}>
            {formatTimeRange()}
          </span>
        </Stack>
      )}
    </Stack>
  );
}

function GeneralisedExpandedWidgetHeader(props: genWidgetHeaderPropInterface) {
  const { setExpandedViewPopup, widgetData, showLinkIcon, linkOnWidget } =
    props;

  const clickedOnlinkIcon = () => {
    window.location.href = linkOnWidget!;
  };
  return (
    <Box className="widgetHeader" sx={{ ...styles.widgetHeader }}>
      <div style={{ marginRight: "auto" }}>
        {widgetData.widgetNickName
          ? widgetData.widgetNickName
          : widgetData.widgetName}
      </div>
      <Stack direction='row' alignItems='center' spacing={1}>
        {showLinkIcon && (
          <Tooltip title="Click to know more" style={{ padding: "0" }}>
            <img
              src={linkIcon}
              style={{ width: "18px", height: "18px" }}
              onClick={() => {
                clickedOnlinkIcon();
              }}
            ></img>
          </Tooltip>
        )}
        <Tooltip title={widgetData.widgetDescription}>
          <InfoOutlinedIcon fontSize="small" style={{ color: "#98A6B8" }} />
        </Tooltip>
        <Tooltip title="Close window">
          <CloseFullscreenIcon
            sx={{ fontSize: "16px" }}
            onClick={() => {
              setExpandedViewPopup(false);
            }}
          />
        </Tooltip>
      </Stack>
    </Box>
  );
}
