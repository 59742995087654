import { styled } from "@mui/system";
import { Box } from "@mui/material";
import dpTheme from "../../theme/dpTheme";

const getColorFromStatus = (status: string): string => {
  if (status === "success") return dpTheme.palette.success.main;
  else if (status === "failure") return dpTheme.palette.error.light;
  else return dpTheme.palette.info.light;
};

interface IProps {
  currentStatus: "success" | "failure" | "info";
}

const InfoBox = styled(Box)(({ currentStatus }: IProps) => ({
  background: getColorFromStatus(currentStatus),
  border: "1px solid ",
  color:
    currentStatus === "success"
      ? dpTheme.palette.success.dark
      : dpTheme.palette.error.dark,
  borderColor:
    currentStatus === "success"
      ? dpTheme.palette.success.dark
      : dpTheme.palette.error.dark,
  borderRadius: "5px",
  padding: "5px 50px 5px 50px",
  fontSize: "15px",
  fontWeight: "500",
  textAlign: "center",
}));

export { InfoBox };
