// @ts-nocheck
import {
  Autocomplete,
  Avatar,
  Box,
  Collapse,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import LayoutComponent from '../../../../components/LayoutComponent';
import SearchBox from '../../../../core-components/theme/components/Searchbox/Searchbox';
import TwoRowLayout from '../../../../core-components/theme/layouts/TwoLayout/TwoLayoutComponent';
import { Neutrals, Primary } from '../../../../core-components/theme/color-palette/colors';
import CustomUserTreeItem from './CustomUserTree';
import CustomTreeComponent from '../../../../core-components/theme/components/Tree/CustomTree';
import { getWorkspaceItemlabel } from '../../../workspace-management/helpers/customtreehelpers';
import fakeUserData from './fakeUser';
import { useEffect, useRef, useState } from 'react';
import useIsMobile from '../../../../utilities/customHooks/useIsMobile';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import CustomIconButton from '../../../../core-components/theme/components/Button/Iconbutton';
import { ExpandLess, ExpandMore, Height } from '@mui/icons-material';
import useIsTablet from '../../../../utilities/customHooks/useIsTablet';
import { GridSearchIcon } from '@mui/x-data-grid';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import DirectionsIcon from '@mui/icons-material/Directions';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  generatePassword,
  resizeImage,
  validateEmail,
  validateName,
  validatePassword,
  validatePhone,
} from './userHelperFunction';
import { useMutation, useQuery } from '@tanstack/react-query';
import { updateProfilePicture } from '../../../../services/profile';
import Button from '../../../../core-components/theme/components/Button/Button';
import DialogBox from '../../../../core-components/theme/components/DialogBox/DialogBox';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  buildWorkspacePermissionTree,
  getAdministrativeRoles,
  saveUser,
} from '../../../../services/newUsers';
import { useRoleStore } from '../../user2/store/useWorkspaceStore';
import { getUserGroupsForUser } from '../../../../services/role';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const styles = {
  refreshIconStyle: {
    borderLeft: '1px solid #0000003B',
    borderRadius: '0px',
    padding: '1rem',
    background: '#ffffff',
    marginLeft: '1rem',
  },
  uploadIconStyle: {
    border: '1px solid #1836501A',
    borderRadius: '4px',
    padding: '1rem',
    marginBottom: '1rem',
    height: '57px',
  },
  profileIconStyle: {
    width: '56px',
    height: '56px',
    fontSize: '1rem',
    background: Primary[900],
    fontFamily: 'Roboto, sans-serif',
    fontWeight: 400,
    marginBottom: '1rem',
    cursor: 'pointer',
  },
  chipStyle: {
    background: '#ffffff',
    border: '1px solid #BDBDBD',
    borderRadius: '100px',
  },
};

const UserCreatePage = () => {
  const isMobile = useIsMobile({ breakpointValue: 'sm' });
  const isTablet = useIsTablet();
  const [open, setOpen] = useState(true);
  const [showPassword, setShowPassword] = useState(true);
  const [roles, setRoles] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [workspacePermissionTree, setWorkspacePermissionTree] = useState();
  const [workspacePermissionTreeStatus, setWorkspacePermissionTreeStatus] = useState(false);
  const { workspaceAssetRoles } = useRoleStore(); // Get the roles from Zustand store
  const [workspacePermissionTreePayload, setWorkspacePermissionTreePayload] = useState({
    userGroupIds: [],
    workspaceAssetRoles: [],
  });
  useEffect(() => {
    setWorkspacePermissionTreePayload(prev => ({
      ...prev,
      workspaceAssetRoles: workspaceAssetRoles, // Update roles here
    }));
  }, [workspaceAssetRoles]);
  console.log(workspaceAssetRoles);
  const [formData, setFormData] = useState({
    name: '',
    password: '',
    email: '',
    userGroups: [],
    administrativeRoles: [],
    phone: '',
    profilePic: '',
  });
  const [loadImage, setLoadImage] = useState(false);
  const [openDialogueBox, setOpenDialogueBox] = useState(false);

  function handleOpen() {
    setOpenDialogueBox(true);
  }

  function handleClose() {
    setOpenDialogueBox(false);
  }

  const handleToggle = () => {
    setOpen(!open);
  };

  function handleFormData(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  }
  const [nameError, setNameError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailError, setEmailError] = useState('');
  // useQuery for fetching workspace permission tree
  const {
    data: workspacePermissionTreeResponse,
    status: workspacePermissionTreeResponseStatus,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['workspacePermissionTree'],
    queryFn: () => buildWorkspacePermissionTree(workspacePermissionTreePayload),
    refetchOnWindowFocus: false,
    onError: err => {
      console.error('Failed to fetch workspace permission tree:', err);
    },
    onSuccess: () => {
      const resetWorkspaceAssetRoles = useRoleStore(state => state.resetWorkspaceAssetRoles);
      resetWorkspaceAssetRoles();
    },
  });
  const {
    data: userGroupsResponse,
    status: userGroupsStatus,
    refetch: refetchUserGroups,
  } = useQuery({
    queryKey: ['userGroupsForUser'],
    queryFn: () => getUserGroupsForUser(),
    refetchOnWindowFocus: false,
    onError: err => {
      console.error('Failed to fetch wuserGroups for user', err);
    },
  });
  const navigate = useNavigate();
  const {
    data: administrativeRole,
    status: administrativeRoleStatus,
    refetch: refetchAdministrative,
  } = useQuery({
    queryKey: ['administrativeRole'],
    queryFn: () => getAdministrativeRoles(),
    refetchOnWindowFocus: false,
    onError: err => {
      console.error('Failed to fetch workspace permission tree:', err);
    },
  });
  const handleUserSave = () => {
    formData.userGroups = formData.userGroups.map(userGroup => userGroup._id);
    formData.administrativeRoles = formData.administrativeRoles.map(
      administrativeRole => administrativeRole._id
    );
    formData.workspaceAssetRoles = workspaceAssetRoles;
    console.log('called', formData);
    mutation.mutate(formData);
  };
  const mutation = useMutation({
    mutationFn: (formData: FormData) => saveUser(formData),
    onSuccess: data => {
      // Check if the response indicates success or failure
      toast.success(`User created`);
      navigate('/userList'); // Redirect to another page
    },
    onError: error => {
      toast.error(`Problem in saving role`);
      console.error('Error saving role:', error);
    },
  });
  // Update state based on query response
  useEffect(() => {
    if (workspacePermissionTreeResponseStatus === 'success') {
      setWorkspacePermissionTree(workspacePermissionTreeResponse);
    } else if (
      workspacePermissionTreeResponseStatus === 'error' ||
      workspacePermissionTreeResponseStatus === 'idle'
    ) {
      // setUserFetched(false);
    }
  }, [workspacePermissionTreeResponseStatus, workspacePermissionTreeResponse]);
  useEffect(() => {
    if (administrativeRoleStatus === 'success') {
      setRoles(administrativeRole);
      // setWorkspacePermissionTree(workspacePermissionTreeResponse);
    }
    // else if (administrativeRoleStatus === 'error') {
    //   setUserFetched(false);
    // }
  }, [administrativeRoleStatus]);

  useEffect(() => {
    if (userGroupsStatus === 'success') {
      setUserGroups(userGroupsResponse);
    }
    // else if (administrativeRoleStatus === 'error') {
    //   setUserFetched(false);
    // }
  }, [userGroupsResponse]);
  useEffect(() => {
    refetch();
    refetchAdministrative();
    refetchUserGroups();
  }, []);
  function handleUserGroupChange(event, value) {
    setFormData(prevState => ({
      ...prevState,
      userGroups: [...value],
    }));
    setWorkspacePermissionTreePayload(prevState => ({
      ...prevState,
      userGroupIds: [...value.map(group => group._id)],
    }));
  }
  useEffect(() => {
    refetch();
  }, [formData?.userGroups]);
  function handleAdministrativeChange(event, value) {
    setFormData(prevState => ({
      ...prevState,
      administrativeRoles: value,
    }));
  }

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const resizedImage = await resizeImage(file);
      if (resizedImage) {
        /*
          Will pass blob when save API is created
          setFormData({
            ...formData,
            profilePic: resizedImage 
          })
        */
        const reader = new FileReader();
        reader.onloadend = () => {
          setFormData({
            ...formData,
            profilePic: reader.result, //passing image url for now
          });
          event.target.value = '';
        };
        reader.readAsDataURL(file);
        // uploadImage(resizedImage); //need to add this line while saving formData do this when save API is created
      }
    }
  };

  const uploadImage = async (resizedImage: Blob) => {
    const formData = new FormData();
    formData.append('upload', resizedImage, 'profilePic');
    try {
      await updateProfilePicture(formData)
        .then((data: any) => {
          if (data.status === 200) {
            toast.success('Profile picture updated successfully');
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } catch (error) {
      console.error('There was an error uploading the image:', error);
    }
  };

  const handleDelete = () => {
    setFormData({
      ...formData,
      profilePic: '',
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setLoadImage(true);
      }
    });

    const avatar = document.getElementById('lazy-avatar');
    if (avatar) {
      observer.observe(avatar);
    }

    return () => {
      if (avatar) {
        observer.unobserve(avatar);
      }
    };
  }, []);

  return (
    <LayoutComponent factoryResetContainer={true}>
      <TwoRowLayout
        style={{ pl: 4, pr: 4 }}
        child1={
          <Stack direction="column" width="100%">
            <Box
              sx={{
                mt: 2,
                mb: 2,
                backgroundColor: `${Primary[50]}`,
                borderRadius: 3,
                padding: isMobile ? '16px 24px' : '24px',
                border: '1px solid #bdbdbd',
              }}
            >
              <Grid container direction="column" borderRadius="12px">
                <Grid
                  container
                  onClick={handleToggle}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {isMobile && (
                    <Typography
                      variant="body"
                      letterSpacing="0.15px"
                      color="#666"
                      lineHeight="24px"
                    >
                      User Creation Details
                    </Typography>
                  )}
                  {isMobile && (
                    <IconButton onClick={handleToggle}>
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                  )}
                </Grid>
                {isMobile && open && (
                  <Divider variant="fullWidth" sx={{ mt: 1, mb: '24px', color: '#bdbdbd' }} />
                )}
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <Grid
                    container
                    display="flex"
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="space-between"
                    alignItems="flex-start"
                    flexWrap="nowrap"
                    gap={4}
                    mb={-2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={isMobile ? 5 : 7}
                      width={isMobile ? '100%' : undefined}
                      display="flex"
                      gap={4}
                    >
                      <Avatar
                        id="lazy-avatar"
                        style={styles.profileIconStyle}
                        src={formData.profilePic}
                        onClick={handleOpen}
                      >
                        {formData.name
                          .split(' ')
                          .map(name => name[0])
                          .join('')}
                      </Avatar>
                      <CustomIconButton
                        icon={FileUploadIcon}
                        onClick={handleButtonClick}
                        sx={styles.uploadIconStyle}
                      />
                      <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                      />
                      <Dialog
                        open={openDialogueBox}
                        onClose={handleClose}
                        sx={{
                          '.MuiDialog-paper': {
                            borderRadius: '24px',
                          },
                        }}
                      >
                        <Stack direction="column">
                          <Box display="flex" justifyContent="flex-end" mr={2} mt={2}>
                            <IconButton onClick={handleClose}>
                              <CloseIcon fontSize="small" sx={{ color: '#000000' }} />
                            </IconButton>
                          </Box>
                          <Box pl={8} pr={8} pb={3} position="relative">
                            <Avatar
                              id="lazy-avatar"
                              style={{
                                ...styles.profileIconStyle,
                                height: '200px',
                                width: '200px',
                                fontSize: '3rem',
                              }}
                              src={formData.profilePic}
                              onClick={handleOpen}
                            >
                              {formData.name
                                .split(' ')
                                .map(name => name[0])
                                .join('')}
                            </Avatar>
                            <IconButton
                              onClick={handleDelete}
                              sx={{
                                color: 'white',
                                position: 'absolute',
                                bottom: 0,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                '&:hover': {
                                  backgroundColor:
                                    formData.profilePic !== '' ? '#ffffff' : '#000000',
                                },
                                marginBottom: '2rem',
                              }}
                            >
                              <DeleteIcon
                                sx={{ color: formData.profilePic !== '' ? '#000000' : '#ffffff' }}
                                fontSize="small"
                              />
                            </IconButton>
                          </Box>
                        </Stack>
                      </Dialog>
                      <TextField
                        label="Full Name"
                        placeholder="Full Name"
                        required
                        value={formData.name}
                        name="name"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        onBlur={() => validateName(formData, setNameError)}
                        error={Boolean(nameError)}
                        helperText={nameError}
                        inputProps={{
                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = e.target.value
                              .replace(/[^a-zA-Z0-9\s]/g, '')
                              .replace(/^\s/, '')
                              .replace(/\s{2,}/g, ' ');
                          },
                        }}
                        sx={{
                          '.MuiFormHelperText-root': {
                            margin: '0px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      mt={isMobile ? -4 : 0}
                      xs={12}
                      sm={5}
                      width={isMobile ? '100%' : undefined}
                    >
                      <TextField
                        label="Email"
                        name="email"
                        required
                        placeholder="email"
                        value={formData.email}
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        sx={{
                          paddingBottom: isMobile ? '1rem' : '0.5rem',
                        }}
                        sx={{
                          '.MuiFormHelperText-root': {
                            margin: '0px',
                          },
                        }}
                        onBlur={() => validateEmail(formData, setEmailError)}
                        error={Boolean(emailError)}
                        helperText={emailError}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={5}
                      width={isMobile ? '100%' : undefined}
                      sx={{
                        '.MuiOutlinedInput-root': {
                          padding: '0px',
                        },
                      }}
                    >
                      <TextField
                        label="Password"
                        name="password"
                        autoComplete="new-password"
                        type={showPassword ? 'password' : 'text'}
                        placeholder="Password"
                        value={formData.password}
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                              <IconButton
                                sx={styles.refreshIconStyle}
                                onClick={() =>
                                  setFormData({ ...formData, password: generatePassword() })
                                }
                              >
                                <AutorenewIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          marginTop: isMobile ? '-1rem' : '',
                          '.MuiOutlinedInput-root': {
                            paddingRight: '0px !impportant',
                          },
                        }}
                        onBlur={() => validatePassword(formData, setPasswordError)}
                        error={Boolean(passwordError)}
                        helperText={passwordError}
                        inputProps={{
                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                          },
                        }}
                        sx={{
                          '.MuiFormHelperText-root': {
                            margin: '0px',
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    justifyContent="space-around"
                    alignItems="flex-start"
                    gap={4}
                    mt={isMobile ? 6 : 2}
                    flexWrap="nowrap"
                  >
                    <Grid item xs={12} sm={4} width={isMobile ? '100%' : undefined}>
                      <TextField
                        label="Phone Number"
                        placeholder="Phone Number"
                        value={formData.phone}
                        name="phone"
                        onChange={handleFormData}
                        variant="outlined"
                        fullWidth
                        onBlur={() => validatePhone(formData, setPhoneError)}
                        error={Boolean(phoneError)}
                        helperText={phoneError}
                        inputProps={{
                          maxLength: 10,
                          onInput: (e: React.ChangeEvent<HTMLInputElement>) => {
                            e.target.value = e.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                          },
                        }}
                        sx={{
                          '.MuiFormHelperText-root': {
                            margin: '0px',
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} width={isMobile ? '100%' : undefined}>
                      <Autocomplete
                        name="userGroup"
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={userGroups}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="User Group"
                            placeholder={formData.userGroups.length > 0 ? '' : 'User Group'}
                          />
                        )}
                        sx={{
                          '.MuiChip-root': styles.chipStyle,
                          '.MuiOutlinedInput-root': {
                            padding: isTablet && formData.userGroups.length > 2 ? '7px' : '9px',
                          },
                        }}
                        onChange={handleUserGroupChange}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4} width={isMobile ? '100%' : undefined}>
                      <Autocomplete
                        name="administrativeRole"
                        multiple
                        limitTags={1}
                        id="multiple-limit-tags"
                        options={roles}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label="Administrative Role"
                            placeholder={
                              formData.administrativeRoles.length > 0 ? '' : 'Administrative Role'
                            }
                          />
                        )}
                        sx={{
                          '.MuiChip-root': styles.chipStyle,
                          '.MuiOutlinedInput-root': {
                            padding:
                              isTablet && formData.administrativeRoles.length > 2 ? '7px' : '9px',
                          },
                        }}
                        onChange={handleAdministrativeChange}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                      />
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Box>
          </Stack>
        }
        child2={
          <Box p={0} borderRadius="8px">
            <CustomTreeComponent
              show={true}
              heading="Workspace / Asset"
              getItemId={(item: any) => item.itemId}
              getItemlabel={getWorkspaceItemlabel}
              CustomTreeItem={CustomUserTreeItem}
              items={workspacePermissionTree || []}
            />
            <Box
              gap={6}
              pt={4}
              sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
              pb={2}
            >
              <Button
                variant="contained"
                sx={{
                  border: `1px solid ${Neutrals[500]}`,
                  borderRadius: '4px',
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  backgroundColor: 'white',
                  color: `${Neutrals[500]}`,
                  flex: isMobile ? 1 : 0,
                  boxShadow: 'none',
                  ':hover': { background: 'none', boxShadow: 'none' },
                }}
                onClick={() => console.log('save')}
              >
                Cancel
              </Button>
              <Button
                disabled={formData.name.length === 0}
                variant="contained"
                sx={{
                  padding: isMobile ? '10px 40px' : '10px 70px',
                  flex: isMobile ? 1 : 0,
                  borderRadius: '4px',
                  backgroundColor: `${Primary[500]}`,
                  boxShadow: 'none',
                  ':hover': { boxShadow: 'none' },
                }}
                onClick={handleUserSave}
              >
                Save
              </Button>
            </Box>
          </Box>
        }
      />
    </LayoutComponent>
  );
};
export default UserCreatePage;
