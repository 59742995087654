import { useState, FC, useEffect } from "react";
import {
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

import { getListOfClientsForTheCSM } from "../../services/users";
import useWorkspaceStore from "../../store/workspaceStore";
import { useNavigate } from "react-router-dom";

interface AddWorkspacePopupProps {
  open: boolean;
  handleClose: () => void;
}

const AddWorkspacePopup: FC<AddWorkspacePopupProps> = ({
  open,
  handleClose,
}) => {
  const [workspaceName, setWorkspaceName] = useState<string>("");
  const [selectedClientId, setSelectedClientId] = useState<string>("");
  const [clientList, setClientList] = useState<any[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const { setNewWorkspace } = useWorkspaceStore();
  const navigate = useNavigate();

  useEffect(() => {
    getListOfClientsForTheCSM().then((data: any) => {
      setClientList(data);
      if (data.length > 0) {
        setSelectedClientId(data[0].id);
      }
    });
  }, []);

  const handleCloseAndCleanUp = () => {
    setWorkspaceName("");
    handleClose();
  };

  const handleCreate = () => {
    if (!workspaceName || !selectedClientId) {
      setShowError(true);
      return;
    }
    setNewWorkspace(workspaceName, selectedClientId);
    navigate("/workspaceConfig/1234");
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleCloseAndCleanUp}>
      <DialogTitle>Add New Workspace</DialogTitle>
      <DialogContent>
        <Stack direction="column" gap={3} m={1}>
          <TextField
            label="Workspace Name"
            value={workspaceName}
            onChange={(e) => setWorkspaceName(e.target.value)}
            fullWidth
            error={showError && !workspaceName}
            helperText={
              showError && !workspaceName ? "Workspace Name is required" : ""
            }
          />
          <FormControl fullWidth error={showError && !selectedClientId}>
            <InputLabel id="client-select-label">Client</InputLabel>
            <Select
              label="Client"
              value={selectedClientId}
              onChange={(event) =>
                setSelectedClientId(event.target.value as string)
              }
              fullWidth
              error={showError && !selectedClientId}
            >
              {clientList.map((client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseAndCleanUp}>Cancel</Button>
        <Button onClick={handleCreate}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWorkspacePopup;
