//@ts-nocheck
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import LayoutComponent from '../components/LayoutComponent';
import { Box, Button, ThemeProvider, Typography } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TaskCardView from '../components/manualAlertConfig/TaskCardView';
import dpTheme from '../styles/theme/dpTheme';
import { styledPageHeading } from '../styles/global/components/dpHeaders';
import { getEquipments } from '../services/fixedTaskConfig';
import {
  getAllUserListForPlantId,
  getEscalationLevels,
  getPlantConfigVarsForPlantById,
  getRolesForTasks,
} from '../services/alertTaskConfig';
import { getFullSensorListInCategories } from '../services/alertTaskConfig';
import { getAllManualAlertsForPlant, updateManualAlerts } from '../services/manualAlertConfig';
import BottomNavigationComponent from '../components/BottomNavigationComponent/BottomNavigationComponent';
import { PrimaryInfoButton } from '../styles/global/components/dpButtons';
import { InfoBox } from '../styles/global/components/TextInfoBoxes';

export default function ManualAlertConfig() {
  type pageParams = {
    id: string;
  };

  const { id } = useParams<pageParams>();

  const [loading, setLoading] = useState(true);
  const [fixedTasks, setFixedTasks] = useState<any>([]);
  const [sensorList, setSensorList] = useState<any>({
    'Real Sensors': [],
    'Virtual Sensors': [],
  });
  const [configVarList, setConfigVarList] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);
  const [refresh, setRefresh] = useState(false);
  const [escaltionOptions, setEscalationOptions] = useState<any>([]);
  const [assignRole, setAssignRole] = useState<any>([]);
  const navigate = useNavigate();
  const [equipments, setEquipments] = useState<any>([]);
  const [reloadState, setReloadState] = useState(false);

  const issueTypes = [
    'Process',
    'Electrical',
    'Mechnical',
    'HouseKeeping',
    'Admin',
    'System',
    'Lab',
  ];

  useEffect(() => {
    fetchRoles();
    fetchEscalations();
    getEquipments(id).then((data: any) => {
      console.log('equiments:', data);
      setEquipments(data);
      console.log('equiments:', data);
    });

    getAllManualAlertsForPlant(id).then(data => {
      console.log(data);
      setFixedTasks(data);
      setLoading(false);
    });

    getFullSensorListInCategories(id).then(data => {
      setSensorList(data);
      setLoading(false);
    });

    getPlantConfigVarsForPlantById(id).then(data => {
      console.log(data);
      setConfigVarList(data);
    });

    getAllUserListForPlantId(id).then(data => {
      console.log(data);
      setUserList(data);
      setLoading(false);
    });
  }, [reloadState]);

  const fetchRoles = () => {
    getRolesForTasks().then(data => {
      console.timeLog(data);
      setAssignRole(data);
    });
  };
  const fetchEscalations = () => {
    getEscalationLevels(id).then(data => {
      setEscalationOptions(Object.keys(data));
      setLoading(false);
    });
  };

  const alertTypes = ['Sensor', 'Data Input', 'Mixed'];

  const addnewSubtask = (indexofTask: number) => {
    let oldTasks = fixedTasks;
    oldTasks[indexofTask]['subTasks'].push({
      name: 'Enter Subtask',
      namehindi: 'सबटास्क दर्ज करें',
    });
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const updateCondition = (
    alertIndex: number,
    conditionSetIndex: number,
    statementIndex: number,
    updateKey: string,
    updateValue: any
  ) => {
    let updatedAlerts = fixedTasks;
    updatedAlerts[alertIndex]['condition'][conditionSetIndex][statementIndex][updateKey] =
      updateValue;
    if (updateKey === 'thresholdType') {
      updatedAlerts[alertIndex]['condition'][conditionSetIndex][statementIndex]['threshold'] = 0;
    }
    console.log(updatedAlerts);
    setFixedTasks(updatedAlerts);
    setRefresh(!refresh);
  };

  const deleteAlertStatement = (
    alertIndex: number,
    conditionSetIndex: number,
    statementIndex: number
  ) => {
    console.log('delete');
    console.log(alertIndex);
    console.log(conditionSetIndex);
    console.log(statementIndex);
    let updatedAlerts = fixedTasks;
    updatedAlerts[alertIndex]['condition'][conditionSetIndex].splice(statementIndex, 1);
    console.log(updatedAlerts);
    setFixedTasks(updatedAlerts);
    setRefresh(!refresh);
  };
  const addAlertStatement = (alertIndex: number, conditionSetIndex: number) => {
    console.log('add');
    console.log(alertIndex);
    console.log(conditionSetIndex);
    let updatedAlerts = fixedTasks;
    updatedAlerts[alertIndex]['condition'][conditionSetIndex].push({
      operator: '',
      sensorTag: '',
      threshold: null,
      thresholdType: 'numeric',
    });
    console.log(updatedAlerts);
    setFixedTasks(updatedAlerts);
    setRefresh(!refresh);
  };
  const addConditionSet = (alertIndex: number) => {
    let updatedAlerts = fixedTasks;
    updatedAlerts[alertIndex]['condition'].push([]);
    console.log(updatedAlerts);
    setFixedTasks(updatedAlerts);
    setRefresh(!refresh);
  };
  const handleChecked = (indexofTask: number, value: boolean) => {
    console.log(value);
    let oldTasks = fixedTasks;
    oldTasks[indexofTask]['checked'] = value;
    console.log(oldTasks);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const handleCheckedCritical = (indexofTask: number, value: boolean) => {
    console.log(value);
    let oldTasks = fixedTasks;
    oldTasks[indexofTask]['critical'] = value;
    console.log(oldTasks);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const delteSubTask = (indexofTask: number, indexofSubTask: number) => {
    let oldTasks = fixedTasks;
    oldTasks[indexofTask]['subTasks']?.splice(indexofSubTask, 1);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const UpdateTask = (index: number, section: string, valuefor: string, value: string) => {
    let oldTasks = fixedTasks;
    if (oldTasks[index][section]) {
      oldTasks[index][section][valuefor] = value;
    } else {
      let objProperty = {
        [valuefor]: value,
      };
      oldTasks[index][section] = objProperty;
    }
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };
  const updateCCUserArrayVals = (index: number, userId: any) => {
    let oldTasks = fixedTasks;
    let oldCCUserList = oldTasks[index]['ccUsers'];
    let newCCUser;
    let editIndex = userId.length - 1;
    console.log(userId);
    for (const user of userList) {
      if (user['_id'] === userId[editIndex]) {
        newCCUser = user;
      }
    }
    console.log('found CC user');
    console.log(newCCUser);
    let newCCUserList = [];
    if (oldCCUserList && oldCCUserList.length >= 1) {
      console.log('in list available case');
      newCCUserList = oldCCUserList;
      if (oldCCUserList.indexOf(newCCUser) === -1) {
        console.log('in user not found case ');
        newCCUserList.push(newCCUser);
      } else {
        console.log('in user found case ie. delete user');
        newCCUserList.splice(oldCCUserList.indexOf(newCCUser, 1));
      }
    } else {
      console.log('in list un-available case');
      newCCUserList.push(newCCUser);
    }
    oldTasks[index]['ccUsers'] = newCCUserList;
    console.log(oldTasks);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };
  const updatePrimaryUser = (index: number, userId: string) => {
    let oldTasks = fixedTasks;
    let newPrimaryUser;
    for (const user of userList) {
      if (user['_id'] === userId) {
        newPrimaryUser = user;
      }
    }
    oldTasks[index]['primaryUser'] = newPrimaryUser;
    console.log(oldTasks);
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };
  const handleSubmit = async () => {
    console.log('inside handle submit');
    let nextButton = document.getElementById('next_btn') as HTMLButtonElement;
    nextButton.disabled = true;
    updateManualAlerts(id, fixedTasks)
      .then(() => {
        console.log('inside then');
        navigate(`/formulaConfiguration/${id}`);
      })
      .catch(err => {
        console.log('error in updating tasks : ', err);
      });

    console.log('getting out of handle submit');
  };

  const UpdateSubTask = (taskindex: number, subTaskIndex: number, key: string, value: string) => {
    let oldTasks = fixedTasks;
    oldTasks[taskindex]['subTasks'][subTaskIndex][key] = value;
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const UpdateEscaltionLevel = (
    taskindex: number,
    escalation: string,
    key: string,
    value: string
  ) => {
    let oldTasks = fixedTasks;
    oldTasks[taskindex]['escalations'][escalation][key] = value;
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  const updateDirectValue = (taskindex: number, key: string, value: any) => {
    let oldTasks = fixedTasks;
    oldTasks[taskindex][key] = value;
    setFixedTasks(oldTasks);
    setRefresh(!refresh);
  };

  return (
    <ThemeProvider theme={dpTheme}>
      <LayoutComponent plantId={id}>
        <Typography variant="h1" style={styledPageHeading}>
          Manually Created Tickets
        </Typography>
        {loading ? null : (
          <>
            <Typography variant="h3" style={{ padding: '15px  0' }}>
              Open Issues
            </Typography>
            {fixedTasks?.length > 0 ? (
              fixedTasks?.map((task: any, indexTask: number) => {
                if (task?.isOpen)
                  return (
                    <TaskCardView
                      task={task}
                      indexTask={indexTask}
                      alertTypes={alertTypes}
                      equipments={equipments}
                      issueTypes={issueTypes}
                      userList={userList}
                      assignRole={assignRole}
                      escaltionOptions={escaltionOptions}
                      handleChecked={handleChecked}
                      handleCheckedCritical={handleCheckedCritical}
                      updateDirectValue={updateDirectValue}
                      UpdateTask={UpdateTask}
                      updatePrimaryUser={updatePrimaryUser}
                      updateCCUserArrayVals={updateCCUserArrayVals}
                      UpdateEscaltionLevel={UpdateEscaltionLevel}
                      addnewSubtask={addnewSubtask}
                      delteSubTask={delteSubTask}
                      UpdateSubTask={UpdateSubTask}
                      refreshStateOfParent={reloadState}
                      setRefreshStateOfParent={setReloadState}
                    />
                  );
              })
            ) : (
              <InfoBox currentStatus="info">No Open Issues</InfoBox>
            )}

            <Typography variant="h3" style={{ padding: '50px 0 0' }}>
              Verify closed Issues
            </Typography>

            {fixedTasks?.length > 0 ? (
              fixedTasks?.map((task: any, indexTask: number) => {
                if (!task?.isOpen && !task?.verifiedClosed)
                  return (
                    <TaskCardView
                      task={task}
                      indexTask={indexTask}
                      alertTypes={alertTypes}
                      equipments={equipments}
                      issueTypes={issueTypes}
                      userList={userList}
                      assignRole={assignRole}
                      escaltionOptions={escaltionOptions}
                      handleChecked={handleChecked}
                      handleCheckedCritical={handleCheckedCritical}
                      updateDirectValue={updateDirectValue}
                      UpdateTask={UpdateTask}
                      updatePrimaryUser={updatePrimaryUser}
                      updateCCUserArrayVals={updateCCUserArrayVals}
                      UpdateEscaltionLevel={UpdateEscaltionLevel}
                      addnewSubtask={addnewSubtask}
                      delteSubTask={delteSubTask}
                      UpdateSubTask={UpdateSubTask}
                      sensorList={sensorList}
                      configVarList={configVarList}
                      updateCondition={updateCondition}
                      deleteAlertStatement={deleteAlertStatement}
                      addAlertStatement={addAlertStatement}
                      addConditionSet={addConditionSet}
                      refreshStateOfParent={reloadState}
                      setRefreshStateOfParent={setReloadState}
                    />
                  );
              })
            ) : (
              <InfoBox currentStatus="info">No Verified Closed Issues</InfoBox>
            )}

            <Box m={1} p={1}>
              <PrimaryInfoButton id="next_btn" onClick={handleSubmit}>
                Next
              </PrimaryInfoButton>
            </Box>
            <Box
              m={4}
              p={3}
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <BottomNavigationComponent plantId={id!} currentPage="Manual Alert" />
            </Box>
          </>
        )}
        <ToastContainer />
      </LayoutComponent>
    </ThemeProvider>
  );
}
