//@ts-nocheck

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CurrentPlantDiagram from '../components/PlantVisualisation/CurrentPlantDiagram';
import { usePlantLayoutStore } from '../store/PlantLayoutStore';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { fetchPlantLayoutData } from '../services/PlantVisualisationConfig';
import { getBidirectionalSensorsForPlant } from '../services/BiDirectional';
import { Typography, Box } from '@mui/material';
import { markPersistentNotifAsRead, fetchAllUnreadPersistentNotif } from '../services/notification';
import classes from '../styles/dashboardPage.module.css';
import { checkBiDirectionalLoadingStatus } from '../services/BiDirectional';
import { InfoBox } from '../styles/global/components/TextInfoBoxes';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import LinearProgress from '@mui/material/LinearProgress';

function PlantVisualisation() {
  const backendApi = import.meta.env.VITE_APP_ENDPOINT;
  const navigate = useNavigate();
  // const { plantId } = usePlantLayoutStore();
  const { plantId } = useParams<string>();

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const [showDiagram, setShowDiagram] = useState(false);
  const { setBidirectionalSensorsForPlant } = usePlantLayoutStore();

  const [listening, setListening] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    //@ts-ignore
    getBidirectionalSensorsForPlant(plantId).then(biSensorIds => {
      setBidirectionalSensorsForPlant(biSensorIds);
      setShowDiagram(true);
    });
  }, []);

  useEffect(() => {
    try {
      if (plantId) {
        if (!listening) {
          // https://testingapi.digitalpaani.com
          //http://localhost:4000
          const events = new EventSourcePolyfill(
            `${backendApi}/sseRoutes/getPlantLayoutEvents?plantId=${plantId}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.token}`,
              },
              heartbeatTimeout: 9000000,
              mode: 'cors',
            }
          );

          events.onmessage = event => {
            const plantLayoutData = JSON.parse(event.data);
            setNodes(plantLayoutData.nodes);
            setEdges(plantLayoutData.edges);
          };

          // fetchPlantLayoutData(plantId).then((plantLayoutData) => {
          //   setNodes(plantLayoutData.nodes);
          //   setEdges(plantLayoutData.edges);
          // });

          setRefresh(!refresh);
        }
      } else {
        navigate('/404');
      }
    } catch (err) {}
  }, []);

  return (
    <>
      <div
        style={{
          padding: '0',
          marginLeft: '10px',
          textAlign: 'center',
        }}
      >
        <CurrentPlantDiagram nodes={nodes} edges={edges} />
      </div>
    </>
  );
}

export default PlantVisualisation;
