import { Box, Paper } from "@mui/material";
import { useNavigate } from "react-router";
import { Tooltip } from "@mui/material";
import overDueIcon from "../../constants/Images/overDue.png";
import criticalIcon from "../../constants/Images/critical.png";
import clockIcon from "../../constants/Images/clockIcon.svg";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import moment from "moment";

const styles = {
  taskContainerBox: {
    fontFamily: "Roboto",
    overflow: "hidden",
    borderRadius: "10px",
  },

  taskContentContainer: {
    display: "grid",
    gridTemplateColumns: "90% 10%",
    width: "85%",
  },
  taskContentLeftPart: {},
  taskHeading: {
    fontSize: "clamp(15px, 0.9vw  ,22px)",
    color: "#383D4A",
    fontFamily: "inherit",
  },
  taskCardTimeContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "clamp(14px, 0.9vw  ,21px)",
    color: "#4D4D4D",
  },
  clockIcon: {
    width: "14px",
    height: "14px",
    marginRight: "5px",
  },
  taskStatusIcon: {
    width: "20px",
    height: "20px",
  },
  taskContentRightPart: {
    display: "flex",
    flexDirection: "column",
  },
};

export default function TaskCardLightVersion(props: any) {
  const navigate = useNavigate();

  const { task, language, taskStatus } = props;

  const getBorderColor = (taskType: string) => {
    if (taskType == "Fixed Task") return "#36A2C7";
    else if (taskType == "Alert Task") return "#E96C7C";
    else return "#F27F0C";
  };

  return (
    <>
      <Box m={2}>
        <Paper elevation={10} style={styles.taskContainerBox}>
          <Box
            borderLeft={10}
            borderColor={getBorderColor(task.taskType)}
            style={{ display: "grid", placeItems: "center" }}
          >
            <Box
              onClick={() => {
                navigate(`/expanded-view/${task.taskId}`);
              }}
              style={styles.taskContentContainer}
              p={1}
              pt={3}
              pb={3}
            >
              <Box sx={styles.taskContentLeftPart}>
                <Box style={styles.taskHeading}>
                  {language === "English" ? (
                    <>{task.heading.eng}</>
                  ) : (
                    <>{task.heading.hindi}</>
                  )}
                </Box>
                <Box p={0.25}>
                  <div style={styles.taskCardTimeContainer}>
                    <img src={clockIcon} style={styles.clockIcon}></img>
                    {moment(task.overDueTime, "DD:MM:yyyy HH:mm:ss").format(
                      "LLL"
                    )}
                  </div>
                </Box>
              </Box>

              <Box p={0.25} sx={styles.taskContentRightPart}>
                {taskStatus == "completedAfterOverDue" ||
                taskStatus == "overDue" ? (
                  <Tooltip
                    title="Task is completed after overdue"
                    aria-label="add"
                  >
                    <img src={overDueIcon} style={styles.taskStatusIcon}></img>
                  </Tooltip>
                ) : null}
                {task.critical ? (
                  <Tooltip title="critical" aria-label="add">
                    <img src={criticalIcon} style={styles.taskStatusIcon}></img>
                  </Tooltip>
                ) : null}
                {taskStatus.includes("completed") ? (
                  <Tooltip title="Task is completed" aria-label="add">
                    <DoneAllIcon
                      // onClick={ (e)=>{ actionType ="Uncomplete Task"; openPopupBox(task,task.toUser) }}
                      style={{ ...styles.taskStatusIcon, color: "#70B500" }}
                    />
                  </Tooltip>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
}
